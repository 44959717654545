import { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";

import { RunMetadataResponse } from "../../../../../api/core/controlPlane.types";
import Box from "../../../../../components/Box";
import Button2 from "../../../../../components/Button2";
import Flex from "../../../../../components/Flex";
import Text from "../../../../../components/Text";
import useRunDetails from "../../../../../contexts/apps/hooks/useRunDetails";
import { formatBytes } from "../../../../../utils/format";
import { rem } from "../../../../../utils/tools";
import { DOWNLOAD_OUTPUT_HEIGHT } from "../utils/constants";
import {
  checkIsOutputBelowRenderThreshold,
  checkIsRunInputFormatGZIP,
  checkIsRunOutputFormatJson,
} from "../utils/runDataChecks";

type DownloadRunOutputProps = {
  appId: string;
  runMetadata: RunMetadataResponse;
  isVehicleVisualizationView?: boolean;
};

const DownloadRunOutput = ({
  appId,
  isVehicleVisualizationView,
  runMetadata,
}: DownloadRunOutputProps) => {
  const theme = useTheme();
  const refDownloadButton = useRef<any>();
  const isOutputGzipped = checkIsRunInputFormatGZIP(runMetadata);

  const [downloadOutputURLInitiated, setDownloadOutputURLInitiated] =
    useState(false);

  const {
    loadGzippedRunOutput,
    loadRunDetailsWithOutputUrl,
    runDetailsWithOutputUrl,
    runDetailsWithOutputUrlError,
  } = useRunDetails();

  // initiate download when URL ready
  useEffect(() => {
    if (
      runDetailsWithOutputUrl &&
      !runDetailsWithOutputUrlError &&
      downloadOutputURLInitiated
    ) {
      if (!isOutputGzipped) {
        // write download link to button (a tag) and initiate user click
        if (refDownloadButton.current) {
          refDownloadButton.current.setAttribute(
            "download",
            `${runMetadata.id}-input`
          );
          refDownloadButton.current.setAttribute(
            "href",
            runDetailsWithOutputUrl?.output.url
          );
          refDownloadButton.current.click();
        }

        setDownloadOutputURLInitiated(false);
      } else {
        loadGzippedRunOutput(
          runDetailsWithOutputUrl?.output.url,
          runMetadata.id
        );
        setDownloadOutputURLInitiated(false);
      }
    }
  }, [
    downloadOutputURLInitiated,
    isOutputGzipped,
    loadGzippedRunOutput,
    runDetailsWithOutputUrl,
    runDetailsWithOutputUrlError,
    runMetadata.id,
  ]);

  // get run input URL when button is clicked
  useEffect(() => {
    if (downloadOutputURLInitiated) {
      loadRunDetailsWithOutputUrl(appId, runMetadata.id);
    }
  }, [
    appId,
    downloadOutputURLInitiated,
    loadRunDetailsWithOutputUrl,
    runMetadata.id,
  ]);

  const handleDownloadOutput = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    if (!downloadOutputURLInitiated) {
      e.preventDefault();
      e.stopPropagation();

      setDownloadOutputURLInitiated(true);
    }
  };

  const outputFileSize = runMetadata?.metadata?.output_size;
  const isFormatJson = checkIsRunOutputFormatJson(runMetadata);
  const isLargeFile = !checkIsOutputBelowRenderThreshold(runMetadata);

  return (
    <Box
      pt={5}
      {...(!isLargeFile &&
        isVehicleVisualizationView && {
          height: DOWNLOAD_OUTPUT_HEIGHT,
          flexShrink: 0,
          flexGrow: 0,
        })}
    >
      {isLargeFile && (
        <Text styleName="body-2">The run output is too large to render.</Text>
      )}
      {!isFormatJson && !isLargeFile && (
        <Text styleName="body-2">
          The run output is not in JSON format and must be downloaded to view.
        </Text>
      )}

      <Flex
        mt={isLargeFile || !isFormatJson ? 3 : 0}
        pl={isLargeFile || !isFormatJson ? 0 : 5}
        pb={isLargeFile || !isFormatJson ? 0 : 5}
        alignItems="center"
        hasBorderBottom={!isLargeFile && isFormatJson}
      >
        <Button2
          innerRef={refDownloadButton}
          isDisabled={!outputFileSize}
          label="Download Output"
          type="outline"
          isLoading={downloadOutputURLInitiated}
          onClick={handleDownloadOutput}
          href="#"
          styles={{
            minWidth: rem(116),
          }}
        />
        <Text
          ml={3}
          styleName="code-bold"
          styles={{
            fontSize: theme.ui2Typography.fontSizeMeta1,
            color: theme.color.gray600,
          }}
        >
          ({formatBytes(outputFileSize)})
        </Text>
      </Flex>

      {isLargeFile && (
        <Box maxWidth={rem(380)}>
          <Text
            mt={2}
            styleName="body-3"
            styles={{ color: theme.color.gray600 }}
          >
            Note that if the file is very large it may take a moment for the
            download to initiate.
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default DownloadRunOutput;
