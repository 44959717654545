import { AuthFlowInputValue } from "../hooks/useAuthFlow";

import { parseLosslessJsonAsText, ValidJSON } from "./parseInputOutput";

export const HTML_TAG_REGEX = /<.+?>/g;

export const areInputsValid = (
  values: string[],
  invalidationRegex: RegExp
): boolean => {
  return !values.some((value) => invalidationRegex.test(value));
};

// generic set state wrapping function for auth flow inputs
export const handleInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setValue: React.Dispatch<React.SetStateAction<AuthFlowInputValue>>
) => {
  if (!setValue) return;
  setValue(e.target.value);
  return;
};

// if state is being set with an input’s onFocus() event,
// if that input is in focus when the user clicks on a link elsewhere,
// a state-triggered re-render will occur and the click will not fire
// this function ensures the click does not get blocked by the re-render
export const handleOnFocusStateBlur = (
  e: React.FocusEvent<HTMLInputElement>,
  setFocusedFunction: React.Dispatch<React.SetStateAction<any>>,
  focusedFunctionValue: any
) => {
  if (e.relatedTarget instanceof HTMLAnchorElement) {
    return e.relatedTarget.click();
  }
  setFocusedFunction(focusedFunctionValue);
};

// return input in format required for editor
// { json: <json> | undefined, text: "text" | undefined }
type InputFormattedForEditorReturn = {
  json: ValidJSON | undefined;
  text: string | undefined;
};
export const getDataStructuredForEditor = (
  input: any
): InputFormattedForEditorReturn => {
  if (input === undefined || input === null) {
    return {
      json: undefined,
      text: "",
    };
  }

  if (typeof input === "string") {
    return {
      json: undefined,
      text: parseLosslessJsonAsText(`${input}`),
    };
  }

  if (typeof input === "number") {
    return {
      json: undefined,
      text: parseLosslessJsonAsText(input),
    };
  }

  // sometimes input will already be in correct format
  if (
    typeof input === "object" &&
    !Array.isArray(input) &&
    (input.hasOwnProperty("json") || input.hasOwnProperty("text"))
  ) {
    return input;
  }

  return {
    json: undefined,
    text: parseLosslessJsonAsText(input),
  };
};

export const parseInputTypeNumberDisplay = (val: number | string): string => {
  return val.toString().replace(/(^0+)?(\d+)/, "$1$2");
};
