import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

import Flex from "../../components/Flex";
import {
  BANNER_HEIGHT,
  MAIN_NAV_SIDEBAR_WIDTH,
  ORG_HEADER_HEIGHT,
} from "../../utils/constants";
import { filterProps } from "../../utils/filterProps";
import { rem } from "../../utils/tools";

import { getMainViewHeight } from "./utils/getMainViewHeight";
import { MainNavLinkProps, StyledSidebarProps } from "./MainNav.types";

export const StyledSidebar = styled(Flex)<StyledSidebarProps>`
  flex-shrink: 0;
  width: calc(
    100% - ${({ theme }) => theme.spacing.s1} -
      ${({ theme }) => theme.spacing.s1}
  );
  height: ${(props) =>
    getMainViewHeight({
      hasBanner: props.hasBanner,
      type: "main-nav",
    })};
  padding-bottom: 0;
  position: fixed;
  border-bottom-width: ${({ theme }) => theme.border.width};
  border-bottom-style: ${({ theme }) => theme.border.style};
  border-color: ${({ theme }) => theme.color.gray200};
  background-color: ${({ theme }) => theme.color.white};
  z-index: 10000;

  /* shrink icons slightly to make room */
  /* @temporary-icon-shrink */
  .icon-main-nav {
    width: ${rem(36)};
    height: ${rem(36)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: ${MAIN_NAV_SIDEBAR_WIDTH};
    height: ${(props) =>
      getMainViewHeight({
        breakpoint: "sm",
        hasBanner: props.hasBanner,
        type: "main-nav",
      })};
    position: fixed;
    border-right-width: ${({ theme }) => theme.border.width};
    border-right-style: ${({ theme }) => theme.border.style};
    border-top-right-radius: 0;
    border-bottom: 0;

    ${(props) =>
      props.hasBanner &&
      `
        top: ${`calc(${ORG_HEADER_HEIGHT} + ${BANNER_HEIGHT})`};
      `}

    /* @temporary-icon-shrink restore */
    .icon-main-nav {
      width: ${rem(42)};
      height: ${rem(42)};
    }
  }
`;

export const StyledMainNav = styled.nav`
  display: block;
`;

export const MainNavList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const MainNavItem = styled.li`
  display: flex;
  position: relative;
  margin-top: 0;
  margin-left: ${({ theme }) => theme.spacing.s2};
  &:first-of-type {
    margin-left: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    margin-top: ${({ theme }) => theme.spacing.s2};
    margin-left: 0;

    &:first-of-type {
      margin-top: 0;
      margin-left: 0;
    }
  }
`;

const mainNavLinkSharedStyles = (props: any) => css`
  box-sizing: border-box;
  display: block;
  /* @temporary-icon-shrink */
  width: ${rem(36)};
  height: ${rem(36)};
  line-height: 1;
  text-decoration: none;
  color: ${props.isactive === "true"
    ? props.theme.color.purple
    : props.theme.color.gray800};
  border-width: ${props.theme.border.widthThick};
  border-style: ${props.theme.border.style};
  border-color: transparent;
  border-radius: ${rem(7)};
  background-color: ${props.isactive === "true"
    ? props.theme.color.gray100
    : "transparent"};

  /*
    position SVG correctly, transparent 2px border applied
    to parent <a> as a placeholder for focus-visible styles
  */
  .icon-main-nav {
    margin: ${rem(-2)};
  }

  &:hover,
  &:focus-visible {
    color: ${props.theme.color.purple};

    /* change fill color of SVG (unless already active) */
    ${props.isactive !== "true" &&
    `
    .icon-main-nav {
        fill: ${props.theme.color.gray700};
      }
    `}
  }
  &:focus-visible {
    outline: none;
    border-color: ${props.isactive === "true"
      ? props.theme.color.purple
      : props.theme.color.gray600};
  }

  @media (min-width: ${props.theme.breakpoint.sm}) {
    /* @temporary-icon-shrink restore */
    width: ${rem(42)};
    height: ${rem(42)};

    /* show nav label on icon hover */
    ${MainNavLabel} {
      opacity: 0;
      transition: opacity 150ms ease-out;
    }

    &:hover,
    &:focus-visible {
      ${MainNavLabel} {
        /* reset relevant hidden styles */
        width: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        clip: auto;
        clip-path: none;

        /* override default styles */
        margin-left: ${props.theme.spacing.s1};
        padding-top: ${props.theme.spacing.s1};
        padding-right: ${rem(6)};
        padding-bottom: ${props.theme.spacing.s1};
        padding-left: ${rem(6)};
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        font-family: ${props.theme.ui2Typography.fontFamilyMeta2Bold};
        font-size: ${props.theme.ui2Typography.fontSizeMeta2Bold};
        font-weight: ${props.theme.ui2Typography.fontWeightMeta2Bold};
        letter-spacing: 0.0313em;
        line-height: ${props.theme.ui2Typography.lineHeightMeta2Bold};
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        color: ${props.theme.color.white};
        border-radius: ${props.theme.border.radiusSmall};
        background-color: ${props.isactive === "true"
          ? props.theme.color.purple
          : props.theme.color.gray800};
        z-index: 1111;
        opacity: 1;
      }
    }
  }
`;

/*
  Note: the custom props passed here need special treatment
  because of how they are processed by the Link component.
  They are lowercase and string, whereas the rest of the
  props are camelCase and boolean.
*/
export const MainNavLink = styled(Link, {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<MainNavLinkProps>`
  ${mainNavLinkSharedStyles}
`;

export const mainNavExtraLinkStyles = (props: any) => css`
  margin-top: ${props.theme.spacing.s3};
  margin-left: 0;
  position: relative;
  cursor: pointer;

  @media (min-width: ${props.theme.breakpoint.sm}) {
    margin-top: 0;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const BottomNavLinks = styled(Flex)`
  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    position: absolute;
    bottom: 0;
  }
`;

export const MainNavDocsItem = styled.li`
  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: ${MAIN_NAV_SIDEBAR_WIDTH} !important;
    display: flex;
    justify-content: center;
    padding-bottom: ${({ theme }) => theme.spacing.s3} !important;
    border-bottom-width: ${({ theme }) => theme.border.width};
    border-bottom-style: ${({ theme }) => theme.border.style};
    border-bottom-color: ${({ theme }) => theme.color.gray200};
    border-radius: 0;
  }
`;
export const MainNavDocsLink = styled.a`
  ${mainNavLinkSharedStyles}
  ${mainNavExtraLinkStyles}
`;

export const MainNavLogoutLink = styled.a`
  ${mainNavLinkSharedStyles}
  ${mainNavExtraLinkStyles}
  margin-right: calc(${({ theme }) => theme.spacing.s4} * -1);
  margin-left: ${({ theme }) => theme.spacing.s1};
  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    margin-left: auto !important;
    margin-right: auto !important;
  }
`;

// this is hidden visually, for accessibility only
export const MainNavLabel = styled.span`
  display: inline-flex;
  align-items: center;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
`;
