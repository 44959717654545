import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { InputSets as InputSetsType } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import StandardError from "../../../../components/StandardError";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import { getFilteredEntities } from "../../../../utils/entities/getFilteredEntities";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../../App/App.types";
import LoadingListView from "../../../App/components/LoadingListView";
import { experimentsTooltips } from "../../data/microcopy";
import { renderExperimentsList } from "../../utils/renderExperimentsList";

const pageTitle = "Input Sets";

const InputSets = ({ app }: AppPageProps) => {
  const [{ id: accountId }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const { pathname } = useLocation();
  const theme = useTheme();
  const [{ roles }] = useUser();
  const [filterText, setFilterText] = useState("");

  const { inputSets, inputSetsError, loadInputSets, setInputSets } =
    useExperiments();

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load input sets if not in context already
  useEffect(() => {
    if (!inputSets && !inputSetsError) {
      loadInputSets(app.id);
    }
  }, [app.id, inputSets, inputSetsError, loadInputSets]);

  if (inputSetsError) {
    return <StandardError errorMessage={inputSetsError} />;
  }

  const canUserCreateAndEditInputSets = userHasAccessToAction(
    roles,
    ActionGroups.InputSetOperator,
    {}
  );

  const filteredInputSets = getFilteredEntities(filterText, inputSets);
  const renderInputSets = () => {
    return renderExperimentsList({
      accountId,
      app,
      canUserCreateAndEdit: canUserCreateAndEditInputSets,
      experiments: filteredInputSets as InputSetsType,
      theme,
      kind: "input-set",
      urlEditOnClickTrackEventCategory: "InputSets",
      urlEditOnClickTrackEventProperties: {
        view: "Input Sets",
        action: "Link Clicked",
        meta: {
          type: "Input Set Edit",
        },
      },
      urlOnClickTrackEventCategory: "InputSets",
      urlOnClickTrackEventProperties: {
        view: "Input Sets",
        action: "Link Clicked",
        meta: {
          type: "Input Set Detail",
        },
      },
    });
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: experimentsTooltips.inputSets.content,
          tooltipExtraLinkLabel: experimentsTooltips.inputSets.extraLinkLabel,
          tooltipExtraLinkUrl: experimentsTooltips.inputSets.extraLinkUrl,
        }}
        configActionButton={{
          label: "New input set",
          url: `${pathname}/new`,
          onClick: () =>
            trackEvent("InputSets", {
              view: "Input Sets",
              action: "Create Input Set Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditInputSets,
        }}
        configFilter={{
          inputText: filterText,
          testId: "filter-input-sets",
          setInputText: setFilterText,
        }}
        refreshButton={{
          emptyItems: null,
          itemsLoading: !inputSets,
          loadItems: () => loadInputSets(app.id),
          setItems: setInputSets,
        }}
      />

      <PlanNotice {...{ app }} type="custom-apps" />

      {!inputSets ? <LoadingListView /> : renderInputSets()}
    </>
  );
};

export default InputSets;
