import styled from "@emotion/styled";

import Flex from "../../components/Flex";
import { rem } from "../../utils/tools";

import {
  GetBannerBackgroundColorParams,
  StyledBannerProps,
} from "./Banner.types";

const getBannerBackgroundColor = ({
  type,
  theme,
}: GetBannerBackgroundColorParams) => {
  switch (type) {
    case "completed":
      return theme.color.sky100;
    default:
      return theme.color.orange100;
  }
};

export const StyledBanner = styled(Flex)<StyledBannerProps>`
  width: 100%;
  height: ${({ theme }) => theme.spacing.s10};
  flex-shrink: 0;
  position: sticky;
  left: 0;
  top: 0;
  background-color: ${(props) =>
    getBannerBackgroundColor({ theme: props.theme, type: props.type })};
  box-shadow: 0 ${rem(1)} 0 0 ${({ theme }) => theme.color.gray200T};
  z-index: 11112;

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    height: ${({ theme }) => theme.spacing.s8};
  }
`;
