import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { useUser } from "../../AuthProvider";
import { AvatarApp } from "../../avatars";
import Layout from "../../components/Layout";
import Loading from "../../components/Loading";
import { useAppCollection } from "../../contexts/apps/App.context";
import { userHasAccessToAction } from "../../utils/rbac_utils";
import { ActionGroups } from "../../utils/rbac_utils/types";
import { getAvatarColor } from "../Apps/utils/renderAppsList";

import { getAppPages } from "./utils/pages";
import AppRoutes from "./App.routes";

const App = () => {
  const [{ features, id: accountId, roles }] = useUser();
  const params = useParams() as { id: string };
  const theme = useTheme();
  const [displayPages, setDisplayPages] = useState(true);

  const {
    activeAppNavSections,
    setActiveAppNavSections,
    app,
    appLoadError,
    loadApp,
  } = useAppCollection();

  // load app data
  useEffect(() => {
    if ((!app && !appLoadError) || (app && params.id && params.id !== app.id)) {
      loadApp(params.id);
    }
  }, [app, appLoadError, loadApp, params.id]);

  if (!params.id) return <Redirect to="/apps" />;
  if (!app)
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;

  const canUserRun = userHasAccessToAction(
    roles,
    ActionGroups.RunOperator,
    app
  );

  const appPages =
    displayPages &&
    getAppPages({
      appId: params.id,
      appType: app.type || "custom",
      accountId,
      canUserRun,
      features,
    });

  return (
    <Layout
      activeAppNavSections={activeAppNavSections}
      sectionTitle={app?.name}
      sectionIcon={
        <AvatarApp
          size={32}
          avatarColor={getAvatarColor({
            appType: app?.type,
            theme,
          })}
        />
      }
      sectionPages={appPages}
      setActiveAppNavSections={setActiveAppNavSections}
    >
      <AppRoutes {...{ app, displayPages, setDisplayPages }} />
    </Layout>
  );
};

export default App;
