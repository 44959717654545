import * as React from "react";

import BaseAvatar, { AvatarProps } from "./BaseAvatar";

const AvatarFail = ({ size }: AvatarProps) => {
  return (
    <BaseAvatar {...{ size }} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.1151 6.88991C17.635 7.4098 17.635 8.2527 17.1151 8.77259L13.8852 12.0025L17.1151 15.2324C17.635 15.7523 17.635 16.5952 17.1151 17.1151C16.5952 17.635 15.7523 17.635 15.2324 17.1151L12.0025 13.8852L8.77259 17.1151C8.2527 17.635 7.4098 17.635 6.88991 17.1151C6.37003 16.5952 6.37003 15.7523 6.88991 15.2324L10.1198 12.0025L6.88991 8.77259C6.37003 8.2527 6.37003 7.4098 6.88991 6.88991C7.4098 6.37003 8.2527 6.37003 8.77259 6.88991L12.0025 10.1198L15.2324 6.88991C15.7523 6.37003 16.5952 6.37003 17.1151 6.88991Z"
        fill="#FB515F"
      />
    </BaseAvatar>
  );
};

export default AvatarFail;
