import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import InputCheckbox from "../../../components/InputCheckbox";
import Select from "../../Select";
import {
  NewUserQuestionOption,
  RenderQuestionOptionsParams,
} from "../NewUserQuestions.types";

export const renderQuestionCheckboxOptions = ({
  handleUserInput,
  options,
  pageIndex,
  questionIndex,
}: RenderQuestionOptionsParams) => {
  return (
    <Flex flexWrap="wrap">
      {options.map((option, optionIndex) => {
        return (
          <Box key={`${option.id}-${optionIndex}`} width="50%">
            <InputCheckbox
              key={option.id}
              id={option.id}
              label={option.label}
              value={option.id}
              checked={option.selected}
              handleChange={() => {
                handleUserInput(pageIndex, questionIndex, optionIndex);
                return;
              }}
            />
          </Box>
        );
      })}
    </Flex>
  );
};

export const renderQuestionDropdownOptions = ({
  handleUserInput,
  options,
  orientation,
  pageIndex,
  questionIndex,
}: RenderQuestionOptionsParams) => {
  return (
    <Select
      {...{ orientation }}
      placeholder="Select option"
      options={options}
      getOptionValue={(selectedOption: NewUserQuestionOption) => {
        return selectedOption.id;
      }}
      onChange={(selectedOption: NewUserQuestionOption) => {
        const selectedOptionIndex = options.findIndex(
          (option) => option.id === selectedOption.id
        );
        if (selectedOptionIndex !== -1) {
          handleUserInput(pageIndex, questionIndex, selectedOptionIndex);
        }
        return;
      }}
    />
  );
};
