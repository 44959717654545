import React from "react";
import { useTheme } from "@emotion/react";

import { IntegrationsRunProfiles } from "../../../api/core/controlPlane.types";
import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import Loading from "../../../components/Loading";
import PreviewRow2 from "../../../components/PreviewRow2";
import RowDetail from "../../../components/RowDetail";
import Select from "../../../components/Select";
import StandardError from "../../../components/StandardError";
import Tag from "../../../components/Tag";
import Text from "../../../components/Text";
import { useIntegrations } from "../../../contexts/integrations/Integrations.context";
import { rem } from "../../../utils/tools";
import { getIntegrationAvatar } from "../../Integrations/helpers/avatars";
import { availableIntegrationConfig } from "../../Integrations/Integrations.config";
import { IntegrationOption } from "../../Integrations/Integrations.types";

import { getIntegrationConfigByType } from "./utils";

interface IntegrationProfilesSelectProps {
  pendingIntegrations: IntegrationsRunProfiles;
  onAdd: (option: IntegrationOption | undefined) => void;
  onRemove: (integrationId: string) => void;
}

const IntegrationProfileSelect = (props: IntegrationProfilesSelectProps) => {
  const theme = useTheme();
  const { pendingIntegrations, onAdd, onRemove } = props;
  const [integrationSelected, setIntegrationSelected] =
    React.useState<IntegrationOption>();

  const { getAllIntegrations, integrationsLoading, integrationsError } =
    useIntegrations();

  const integrationOptions = React.useMemo(() => {
    const installedIntegrations = getAllIntegrations();

    const selectedTypes = new Set<string>();
    for (const integration of pendingIntegrations) {
      selectedTypes.add(integration.type);
    }
    const measureSelected = !!pendingIntegrations.find(
      (integration) => integration.category === "measure"
    );

    return installedIntegrations.reduce(
      (options: IntegrationOption[], installedIntegration) => {
        if (
          selectedTypes.has(installedIntegration.type) ||
          (installedIntegration.category === "measure" && measureSelected)
        ) {
          return options;
        }
        const selectedIntegrationConfig = getIntegrationConfigByType(
          installedIntegration.type
        );
        options.push({
          id: installedIntegration.id,
          name: installedIntegration.name,
          type: installedIntegration.type,
          class: installedIntegration.class,
          category: installedIntegration?.category,
          description: installedIntegration.description,
          isEarlyAccess: !!selectedIntegrationConfig?.isEarlyAccess,
          avatar: getIntegrationAvatar(installedIntegration.type, 20),
        });
        return options;
      },
      []
    );
  }, [getAllIntegrations, pendingIntegrations]);

  const addIntegration = (integrationSelected?: IntegrationOption) => {
    setIntegrationSelected(undefined);
    onAdd(integrationSelected);
  };

  return (
    <RowDetail
      property="Integrations"
      render={
        integrationsError ? (
          <StandardError errorMessage={integrationsError} />
        ) : integrationsLoading ? (
          <Loading type="full-screen" dotColor={theme.color.orange500} />
        ) : (
          <Box maxWidth={rem(408)}>
            {pendingIntegrations.map((selectedIntegration) => {
              const selectedIntegrationConfig =
                availableIntegrationConfig[selectedIntegration.type] ||
                undefined;

              return (
                <Box mt={-3} key={selectedIntegration.id}>
                  <PreviewRow2
                    key={selectedIntegration.id}
                    hasNoBorder
                    icon={
                      <Box mt={1}>
                        {getIntegrationAvatar(selectedIntegration.type, 36)}
                      </Box>
                    }
                    tag={
                      selectedIntegrationConfig?.isEarlyAccess ? (
                        <Tag ml={1} mb={-1} type="early-access" />
                      ) : undefined
                    }
                    name={selectedIntegration.name || ""}
                    id={
                      selectedIntegration.type
                        ? selectedIntegration.type.charAt(0).toUpperCase() +
                          selectedIntegration.type.slice(1)
                        : ""
                    }
                    urlActionLabel="Remove"
                    urlActionOnClick={() => onRemove(selectedIntegration.id)}
                  />
                </Box>
              );
            })}

            {!!integrationOptions.length || !!pendingIntegrations.length ? (
              <Box>
                <Flex width="100%">
                  <Select
                    type="integrations"
                    placeholder="Select integration"
                    options={integrationOptions}
                    getOptionValue={(integrationSelected: IntegrationOption) =>
                      integrationSelected.id
                    }
                    value={!!integrationSelected && integrationSelected}
                    onChange={(selection: IntegrationOption) => {
                      setIntegrationSelected(selection);
                      addIntegration(selection);
                    }}
                  />
                </Flex>
                {!!integrationOptions.length ||
                  (!!pendingIntegrations.length && (
                    <Text
                      mt={2}
                      ml={1}
                      styleName="body-3"
                      styles={{ color: theme.color.gray600 }}
                    >
                      Note that only one integration per category can be added
                      for each run profile.
                    </Text>
                  ))}
              </Box>
            ) : (
              <Text styleName="body-2" styles={{ color: theme.color.gray500 }}>
                No integrations available
              </Text>
            )}
          </Box>
        )
      }
    />
  );
};
export default IntegrationProfileSelect;
