import React, { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { startCase } from "lodash";

import { trackEvent } from "../../../../analytics";
import { useUser } from "../../../../AuthProvider";
import { AvatarExperimentSwitchback } from "../../../../avatars";
import Box from "../../../../components/Box";
import Flex from "../../../../components/Flex";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import Tag from "../../../../components/Tag";
import Text from "../../../../components/Text";
import {
  TEST_ACTION_CANCEL,
  TEST_ACTION_COMPLETE,
  TEST_ACTION_START,
} from "../../../../config/experiments";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import useManageEntity from "../../../../hooks/useManageEntity";
import { formatDate } from "../../../../utils/format";
import { getAccUrl } from "../../../../utils/navigation";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { rem } from "../../../../utils/tools";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { DEV_INT_ID } from "../../../App/subpages/RunDetails/utils/constants";
import { Table2HeaderObj } from "../../../App/subpages/RunHistory/RunHistory.types";
import { getRunHistoryTableHeaders } from "../../../App/subpages/RunHistory/utils/getRunHistoryTableHeaders";
import { getStatisticsHeaders } from "../../../App/subpages/RunHistory/utils/getStatisticsHeaders";
import ExperimentGroupedSummaries from "../../components/ExperimentGroupedSummaries";
import ExperimentRuns from "../../components/ExperimentRuns";
import { StartStopModalType } from "../../types/Experiment.types";
import { shouldDisplayExperimentActions } from "../../utils/experimentStatus";
import { getExperimentStartCriteria } from "../../utils/getExperimentStartCriteria";
import { renderActionContent } from "../../utils/renderActions";

import PlanSummaryTable from "./components/PlanSummaryTable";
import StartStopSwitchbackConfirmModal from "./components/StartStopSwitchbackConfirmModal";
import { tooltipContentSwitchback } from "./data/tooltipContentSwitchback";
import useSwitchbackTest from "./hooks/useSwitchbackTest";

const SwitchbackTest = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const [{ id: accId, roles }] = useUser();
  const { pathname } = useLocation();
  const { returnPathList } = useReturnPaths();
  const params = useParams() as { id: string; field?: string };
  const theme = useTheme();

  const {
    displayConfirmModal,
    handleSwitchbackTestStart,
    handleSwitchbackTestStop,
    modalActionsError,
    setDisplayConfirmModal,
    setModalActionsError,
  } = useSwitchbackTest(app.id, params.id);

  const {
    entity: switchbackTest,
    entityLoadError: switchbackTestError,
    entityLoadRunsError: switchbackTestRunsError,
    entityRuns: switchbackTestRuns,
    entityRunsNextPageToken: switchbackTestRunsNextPageToken,
    loadEntity: loadSwitchbackTest,
    loadEntityRuns: loadSwitchbackTestRuns,
  } = useManageEntity("experiments/switchback");

  // page display
  useEffect(() => {
    if (switchbackTest) {
      setMetaTitle(switchbackTest.name);
    }
  }, [setMetaTitle, switchbackTest]);

  // load switchback test and runs
  useEffect(() => {
    if (
      !switchbackTest &&
      !switchbackTestRuns &&
      !switchbackTestError &&
      !switchbackTestRunsError
    ) {
      loadSwitchbackTest(app.id, params.id);
      loadSwitchbackTestRuns(app.id, params.id);
    }
  }, [
    app.id,
    loadSwitchbackTest,
    loadSwitchbackTestRuns,
    params.id,
    switchbackTest,
    switchbackTestError,
    switchbackTestRuns,
    switchbackTestRunsError,
  ]);

  const loadMoreRuns = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    loadSwitchbackTestRuns(
      app.id,
      switchbackTest?.id,
      switchbackTestRunsNextPageToken,
      true
    );
  };

  const handleStartStopClick = (actionType: StartStopModalType) => {
    trackEvent("SwitchbackTests", {
      view: "Switchback Test",
      action: "Action Button Clicked",
      meta: {
        type: actionType,
      },
    });

    setModalActionsError(null);
    setDisplayConfirmModal(actionType);
  };

  const handleStartStopModalActions = async (
    actionType: StartStopModalType
  ) => {
    if (!switchbackTest) return;

    if (actionType === TEST_ACTION_START) {
      await handleSwitchbackTestStart();
      await loadSwitchbackTest(app.id, switchbackTest.id);
      return;
    }

    if (
      actionType === TEST_ACTION_COMPLETE ||
      actionType === TEST_ACTION_CANCEL
    ) {
      await handleSwitchbackTestStop(actionType);
      await loadSwitchbackTest(app.id, switchbackTest.id);
      return;
    }
  };

  const statisticsHeaders = getStatisticsHeaders(switchbackTestRuns, theme);

  const runHistoryHeaders: Table2HeaderObj[] = useMemo(() => {
    if (!switchbackTestRuns) {
      return [];
    }
    return getRunHistoryTableHeaders({
      accId,
      appId: app.id,
      isLinkTargetBlank: true,
      runHistory: switchbackTestRuns,
      showPlanUnitIndex: true,
      statsHeaders: statisticsHeaders,
      theme,
    });
  }, [accId, app.id, statisticsHeaders, switchbackTestRuns, theme]);

  if (switchbackTestError) {
    return <StandardError errorMessage={switchbackTestError} />;
  }
  if (!switchbackTest) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const switchbackTestStartCriteria = getExperimentStartCriteria(
    switchbackTest,
    "switchback"
  );

  const canUserCreateAndEditSwitchback = userHasAccessToAction(
    roles,
    ActionGroups.ExperimentOperator,
    {}
  );

  return (
    <>
      <Header
        configPageTitle={{
          label: switchbackTest.name,
          ancestorIcon: <AvatarExperimentSwitchback size={24} />,
          ancestorLabel: "Switchback Tests",
          ancestorUrl: returnPathList,
        }}
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("SwitchbackTests", {
              view: "Switchback Test",
              action: "Link Clicked",
              meta: {
                type: "Switchback Test Edit",
              },
            }),
          isActionAllowed: canUserCreateAndEditSwitchback,
        }}
        secondaryButton={{
          label: "New switchback test",
          url: `${returnPathList}/new`,
          isActionAllowed: canUserCreateAndEditSwitchback,
        }}
      />

      <RowDetail
        hasNoBorder
        property="Name"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text styleName="body-2">{switchbackTest.name}</Text>
          </Box>
        }
      />

      <RowDetail
        property="Status"
        tooltipCopy={tooltipContentSwitchback.status.content}
        render={
          <Flex>
            <Text styleName="body-2">{startCase(switchbackTest.status)}</Text>
          </Flex>
        }
      />

      <RowDetail
        property="ID"
        tooltipCopy={tooltipContentSwitchback.id.content}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={switchbackTest?.id}
              contentToCopy={switchbackTest?.id}
            />
          </Flex>
        }
      />

      {switchbackTest?.description && (
        <RowDetail
          property="Description"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">{switchbackTest.description}</Text>
            </Box>
          }
        />
      )}

      <RowDetail
        property="Baseline Instance"
        tooltipCopy={tooltipContentSwitchback.baselineInstance.content}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={switchbackTest.comparison.baseline_instance_id}
              contentToCopy={switchbackTest.comparison.baseline_instance_id}
              {...(switchbackTest.comparison.baseline_instance_id !==
                DEV_INT_ID && {
                url: getAccUrl(
                  accId,
                  `/app/${app.id}/instance/${switchbackTest.comparison.baseline_instance_id}`
                ),
              })}
            />
          </Flex>
        }
      />

      <RowDetail
        property="Candidate Instance"
        tooltipCopy={tooltipContentSwitchback.candidateInstance.content}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={switchbackTest.comparison.candidate_instance_id}
              contentToCopy={switchbackTest.comparison.candidate_instance_id}
              {...(switchbackTest.comparison.candidate_instance_id !==
                DEV_INT_ID && {
                url: getAccUrl(
                  accId,
                  `/app/${app.id}/instance/${switchbackTest.comparison.candidate_instance_id}`
                ),
              })}
            />
          </Flex>
        }
      />

      {switchbackTestStartCriteria && (
        <RowDetail
          property="Start Criteria"
          tooltipCopy={tooltipContentSwitchback.startCriteria.content}
          render={
            <Flex alignItems="baseline">
              <Text styleName="body-2">{switchbackTestStartCriteria.type}</Text>
              <Text
                ml={2}
                styleName="body-2-bold"
                styles={{ color: theme.color.gray800 }}
              >
                {switchbackTestStartCriteria.formattedValue}
              </Text>
            </Flex>
          }
        />
      )}

      <RowDetail
        property="Created"
        render={
          <Text
            as="time"
            styleName="body-2"
            dateTime={switchbackTest.created_at}
            title={switchbackTest.created_at}
          >
            {formatDate(switchbackTest.created_at, true)}
          </Text>
        }
      />

      {switchbackTest.completed_at && (
        <RowDetail
          property="Completed"
          render={
            <Text
              as="time"
              styleName="body-2"
              dateTime={switchbackTest.completed_at}
              title={switchbackTest.completed_at}
            >
              {formatDate(switchbackTest.completed_at, true)}
            </Text>
          }
        />
      )}

      {shouldDisplayExperimentActions(switchbackTest.status, roles) && (
        <Box
          mt={6}
          pt={7}
          ml={[0, -6]}
          mr={[0, -7]}
          pr={[0, 7]}
          pl={[0, 6]}
          hasBorderTop
        >
          <Text
            as="h2"
            styleName="header-1"
            styles={{ color: theme.color.gray800 }}
          >
            Actions
          </Text>

          <Box mt={4}>
            {renderActionContent({
              handleStartStopClick,
              status: switchbackTest.status,
            })}
          </Box>
        </Box>
      )}

      <PlanSummaryTable plan={switchbackTest.plan} />

      {!!switchbackTest.grouped_distributional_summaries?.length && (
        <ExperimentGroupedSummaries
          kind="switchback"
          experiment={switchbackTest}
        />
      )}

      {switchbackTestRuns &&
        !switchbackTestRunsError &&
        !!switchbackTestRuns?.length && (
          <ExperimentRuns
            kind="switchback"
            experimentRuns={switchbackTestRuns}
            runHistoryHeaders={runHistoryHeaders}
            statisticsHeaders={statisticsHeaders}
            displayLoadMore={!!switchbackTestRunsNextPageToken}
            loadMoreHandler={loadMoreRuns}
          />
        )}
      {displayConfirmModal && (
        <StartStopSwitchbackConfirmModal
          error={modalActionsError}
          handleStartStopModalActions={handleStartStopModalActions}
          hasStartCriteria={!!switchbackTestStartCriteria}
          modalType={displayConfirmModal}
          setDisplayConfirmModal={setDisplayConfirmModal}
          testName={switchbackTest.name}
          testBaselineInstances={switchbackTest.comparison.baseline_instance_id}
          testCandidateInstances={
            switchbackTest.comparison.candidate_instance_id
          }
        />
      )}
    </>
  );
};

export default SwitchbackTest;
