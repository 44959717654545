import { Link } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";

import { ConversationsDatum } from "../../../api/core/controlPlane.types";
import Flex from "../../../components/Flex";
import Tag from "../../../components/Tag";
import Text from "../../../components/Text";
import { getAccUrl } from "../../../utils/navigation";
import {
  GetConversationHeadersParams,
  GetStatusBackgroundColorParams,
  GetStatusTextColorParams,
} from "../Webhooks.types";

export const getStatusBackgroundColor = ({
  status,
  theme,
}: GetStatusBackgroundColorParams): string => {
  switch (status) {
    case "failed":
      return theme.color.red200;
    case "succeeded":
      return theme.color.green200;
    default:
      return theme.color.gray100;
  }
};

export const getStatusTextColor = ({
  status,
  theme,
}: GetStatusBackgroundColorParams): string => {
  switch (status) {
    case "failed":
      return theme.color.red700;
    case "succeeded":
      return theme.color.green700;
    default:
      return theme.color.gray100;
  }
};

export const getHTTPStatusTextColor = ({
  status,
  theme,
}: GetStatusTextColorParams): string => {
  switch (status) {
    case "failed":
      return theme.color.red900;
    case "succeeded":
      return theme.color.green900;
    default:
      return theme.color.gray100;
  }
};

export const getConversationHeaders = ({
  theme,
  accId,
  webhookId,
  isLinkTargetBlank,
}: GetConversationHeadersParams): ColumnDef<ConversationsDatum, any>[] => {
  return [
    {
      id: "conversation-id",
      accessorKey: "id",
      header: "Conversation ID",
      cell: (props) => {
        const conversationId = props.getValue();
        return (
          <Flex>
            <Link
              to={getAccUrl(
                accId,
                `apps/webhook/${webhookId}/conversation/${conversationId}`
              )}
              id={webhookId}
              target={isLinkTargetBlank ? "_blank" : undefined}
            >
              {conversationId}
            </Link>
          </Flex>
        );
      },
    },
    {
      id: "status",
      accessorKey: "status",
      header: "Status",
      cell: (props) => {
        const cellValue = props.getValue();
        return (
          <Text
            styles={{
              color: getStatusTextColor({ status: cellValue, theme }),
            }}
            as="span"
            styleName="body-3"
          >
            {cellValue}
          </Text>
        );
      },
    },
    {
      id: "http-status",
      accessorKey: "http_status",
      header: "HTTP Response",
      cell: (props) => {
        const cellValue = props.getValue();
        const backgroundColor = getStatusBackgroundColor({
          status: props.row.original.status,
          theme,
        });
        const textColor = getHTTPStatusTextColor({
          status: props.row.original.status,
          theme,
        });
        return cellValue ? (
          <Tag
            label={String(cellValue)}
            textColor={textColor}
            backgroundColor={backgroundColor}
          />
        ) : (
          <Text
            as="span"
            styleName="body-3"
            styles={{ color: theme.color.gray500 }}
          >
            None
          </Text>
        );
      },
    },
    {
      id: "created-at",
      accessorKey: "created_at",
      header: "Created",
      cell: (props) => {
        const cellValue = props.getValue();
        return (
          <time
            dateTime={cellValue}
            title={DateTime.fromISO(cellValue).toFormat(
              "yyyy-MM-dd 'T'HH:mm:ssZZZ (ZZZZ)"
            )}
          >
            {DateTime.fromISO(props.getValue()).toRelative()}
          </time>
        );
      },
    },
  ];
};
