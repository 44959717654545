export const EC_DEFAULT = "6c9500mb870s" as const;
export const EC_8C_16GB_12H = "8c16gb12h" as const;
export const EC_16C_60GB_12H = "16c60gb12h" as const;

export const LOGS_TAB_ID = "log-output" as const;
export const LOGS_LIVE_TAB_ID = "live" as const;

export const RUN_FORMAT_JSON = "json" as const;
export const RUN_FORMAT_TEXT = "text" as const;
export const RUN_FORMAT_CSV = "csv" as const;
export const RUN_FORMAT_CSV_ARCHIVE = "csv-archive" as const;
export const RUN_FORMAT_UNKNOWN = "unknown" as const;

export const RUN_STATUS_V2_CANCELED = "canceled" as const;
export const RUN_STATUS_V2_FAILED = "failed" as const;
export const RUN_STATUS_V2_QUEUED = "queued" as const;
export const RUN_STATUS_V2_RUNNING = "running" as const;
export const RUN_STATUS_V2_SUCCEEDED = "succeeded" as const;

export const RUN_TYPE_STANDARD = "standard" as const;
export const RUN_TYPE_ENSEMBLE = "ensemble" as const;
export const RUN_TYPE_ENSEMBLE_CHILD = "ensemble-child" as const;

// ensemble

export const ENSEMBLE_RULE_OBJECTIVE_MAXIMIZE = "maximize" as const;
export const ENSEMBLE_RULE_OBJECTIVE_MINIMIZE = "minimize" as const;
export const ENSEMBLE_RULE_TOLERANCE_TYPE_ABSOLUTE = "absolute" as const;
export const ENSEMBLE_RULE_TOLERANCE_TYPE_RELATIVE = "relative" as const;

export const DEFAULT_ENSEMBLE_RUN_GROUP_ID_LABEL = "run-group";
export const DEFAULT_ENSEMBLE_RULE_ID_LABEL = "rule";
export const DEFAULT_ENSEMBLE_RULE_OBJECTIVE = ENSEMBLE_RULE_OBJECTIVE_MINIMIZE;
export const DEFAULT_ENSEMBLE_RULE_TOLERANCE_TYPE =
  ENSEMBLE_RULE_TOLERANCE_TYPE_ABSOLUTE;
export const DEFAULT_ENSEMBLE_RULE_TOLERANCE_VALUE = 0;

// run details view

export const RUN_DETAILS_TAB_ANALYSIS = "analysis" as const;
export const RUN_DETAILS_TAB_DETAILS = "details" as const;
export const RUN_DETAILS_TAB_ENSEMBLE = "ensemble" as const;
export const RUN_DETAILS_TAB_INPUT = "input" as const;
export const RUN_DETAILS_TAB_LOGS = "logs" as const;
export const RUN_DETAILS_TAB_RESULT = "result" as const;

export const RUN_RESULT_TAB_VEHICLES = "vehicles" as const;
export const RUN_RESULT_TAB_OUTPUT = "output" as const;
