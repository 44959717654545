import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const ORDER_FULFILLMENT_PARTNER_ID = "nextmv";
export const ORDER_FULFILLMENT_APP_ID = "order.fulfill.gosdk";
export const ORDER_FULFILLMENT_SUBSCRIPTION_ID = `${ORDER_FULFILLMENT_PARTNER_ID}-${ORDER_FULFILLMENT_APP_ID}`;
export const ORDER_FULFILLMENT_ICON_URL = "/images/logos/highs-icon.png";
export const ORDER_FULFILLMENT_NAME = "HiGHS Order Fulfillment";
export const ORDER_FULFILLMENT_TEMPLATE_NAME = "order-fulfillment-gosdk";
export const ORDER_FULFILLMENT_SHORT_DESC =
  "Solves a MIP-based Order Fulfillment model with the Nextmv SDK.";
export const ORDER_FULFILLMENT_SUMMARY =
  "Solve an order fulfillment problem with the low-code Nextmv Order Fulfillment app. Define item specifications, distribution centers,  carrier capacities, and carrier costs per distribution center and weight tier and then run the app to assign items to distribution centers and carriers.";
export const ORDER_FULFILLMENT_DOCS_LINK =
  "https://www.nextmv.io/docs/community-apps/order-fulfillment";
export const ORDER_FULFILLMENT_CODE_REPO_LINK =
  "https://github.com/nextmv-io/community-apps/tree/develop/go-highs-orderfulfillment";
export const ORDER_FULFILLMENT_MODEL_TYPE = ["Fulfillment"];
export const ORDER_FULFILLMENT_LANGUAGE = ["Go"];
export const ORDER_FULFILLMENT_PACKAGES = ["MIP"];
export const ORDER_FULFILLMENT_SOLVER = ["HiGHS"];

export const ORDER_FULFILLMENT_CONSTRAINTS: MarketplaceAppConstraint[] = [
  {
    id: "carton_volume",
    name: "carton_volume",
    property: "carton_volume",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/order-fulfillment#input",
    summary: "Volume of a carton.",
    type: "float",
  },
  {
    id: "items",
    name: "items",
    property: "items",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/order-fulfillment#input",
    summary: "Items which need to be fulfilled.",
    type: "array",
  },
  {
    id: "distribution_centers",
    name: "distribution_centers",
    property: "distribution_centers",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/order-fulfillment#input",
    summary: "Available distribution centers.",
    type: "array",
  },
  {
    id: "carrier_capacities",
    name: "carrier_capacities",
    property: "carrier_capacities",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/order-fulfillment#input",
    summary:
      "The capacities of each distribution center and carrier combination.",
    type: "map",
  },
  {
    id: "carrier_delivery_costs",
    name: "carrier_capacities",
    property: "carrier_capacities",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/order-fulfillment#input",
    summary:
      "The cost of each carrier and distribution center combination by weight tier.",
    type: "map",
  },
  {
    id: "quantity",
    name: "quantity",
    property: "quantity",
    docsUrl: "https://www.nextmv.io/docs/community-apps/order-fulfillment#item",
    summary: "The quantity needed of each item.",
    type: "float",
  },
  {
    id: "unit_volume",
    name: "unit_volume",
    property: "unit_volume",
    docsUrl: "https://www.nextmv.io/docs/community-apps/order-fulfillment#item",
    summary: "The volume per unit of item.",
    type: "float",
  },
  {
    id: "unit_weight",
    name: "unit_weight",
    property: "unit_weight",
    docsUrl: "https://www.nextmv.io/docs/community-apps/order-fulfillment#item",
    summary: "The weight per unit of item.",
    type: "float",
  },
  {
    id: "handling_cost",
    name: "handling_cost",
    property: "handling_cost",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/order-fulfillment#distribution-center",
    summary: "The weight per unit of item.",
    type: "float",
  },
  {
    id: "inventory",
    name: "inventory",
    property: "inventory",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/order-fulfillment#distribution-center",
    summary: "The inventory of each item available at the distribution center.",
    type: "map",
  },
];
