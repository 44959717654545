import { rem } from "../../../utils/tools";
import { getInputFontStyles } from "../../Input/utils/getInputSizeStyles";
import { GetSelectTextStyles } from "../Select.types";

const sharedStyles = {
  position: "relative",
  textRendering: "optimizeLegibility",
  WebkitFontSmoothing: "antialiased",
};

export const getSelectTextStyles = ({
  size,
  theme,
  type,
}: GetSelectTextStyles) => {
  const inputFontStyles = getInputFontStyles({ size, theme });

  switch (type) {
    case "input":
      return {
        ...sharedStyles,
        ...inputFontStyles,
      };
    case "no-options":
      return {
        ...sharedStyles,
        ...inputFontStyles,
        textAlign: "left",
      };
    case "option":
      return {
        ...sharedStyles,
        ...inputFontStyles,
        cursor: "pointer",
      };
    case "placeholder":
      return {
        ...sharedStyles,
        ...inputFontStyles,
      };
    case "value":
      return {
        ...sharedStyles,
        ...inputFontStyles,
        marginRight: rem(-4),
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      };
    default:
      break;
  }
};
