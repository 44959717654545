import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconSocialYouTube = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path d="M15.3337 7.06804C15.3666 5.95767 15.158 4.8556 14.727 3.86417C14.4346 3.45736 14.0288 3.18283 13.5803 3.08841C11.7254 2.89256 9.8627 2.81228 8.00034 2.84793C6.14477 2.81066 4.28882 2.88835 2.44034 3.08066C2.07489 3.15801 1.73669 3.35748 1.46701 3.65472C0.86701 4.29859 0.800343 5.40017 0.733677 6.33108C0.636951 8.00482 0.636951 9.68423 0.733677 11.358C0.752964 11.8819 0.820006 12.402 0.933677 12.9095C1.01406 13.3013 1.17669 13.6638 1.40701 13.9645C1.67852 14.2775 2.0246 14.4883 2.40034 14.5696C3.83762 14.776 5.28582 14.8616 6.73368 14.8256C9.06701 14.8644 11.1137 14.8256 13.5337 14.6084C13.9186 14.5321 14.2745 14.321 14.5537 14.0033C14.7403 13.786 14.8797 13.5201 14.9603 13.2275C15.1988 12.3762 15.3159 11.4853 15.307 10.59C15.3337 10.1556 15.3337 7.5335 15.3337 7.06804ZM6.49368 11.0554V6.2535L10.4403 8.6661C9.33368 9.3798 7.87368 10.1866 6.49368 11.0554Z" />
    </BaseIcon>
  );
};

export default IconSocialYouTube;
