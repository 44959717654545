import React from "react";

import { useUser } from "../../AuthProvider";
import Box from "../../components/Box";
import Button2 from "../../components/Button2";
import Flex from "../../components/Flex";
import { rem } from "../../utils/tools";
import { getBannerType } from "../Banner/utils/getBannerType";
import ButtonRefresh from "../ButtonRefresh";
import Input from "../Input";

import TitleItem from "./components/TitleItem";
import { StyledHeader } from "./Header.styled";
import { HeaderButton, HeaderProps } from "./Header.types";

const Header = ({
  configActionButton,
  configFilter,
  configPageTitle,
  isActionButtonDisabled,
  isActionButtonLoading,
  isExpanded,
  isSecondaryButtonDisabled,
  isSecondaryButtonLoading,
  refreshButton,
  secondaryButton,
  topNavExtra,
}: HeaderProps) => {
  const [user] = useUser();
  const bannerType = getBannerType(user);

  const getButtonData = (headerButton: HeaderButton) => {
    const filteredOptions = Array.isArray(headerButton)
      ? headerButton.filter((option) => option?.isActionAllowed)
      : [headerButton];

    if (!filteredOptions.length) return null;

    return filteredOptions;
  };

  const renderActionButton = (actionButton: HeaderButton) => {
    const buttonData = getButtonData(actionButton);

    if (!buttonData || !buttonData?.length) return;

    const commonActionButtonProps = {
      "data-testid": "header-button-one",
      ml: [
        hasSecondaryButton || refreshButton ? 2 : 0,
        hasSecondaryButton || refreshButton ? 2 : 0,
        hasSecondaryButton || refreshButton ? 2 : 0,
        2,
      ],
      isDisabled: isActionButtonDisabled,
      isLoading: isActionButtonLoading,
    };

    if (buttonData.length > 1) {
      return (
        <Button2 {...commonActionButtonProps} multiSelectOptions={buttonData} />
      );
    } else {
      const buttonDatum = buttonData[0];
      if (!buttonDatum) return;

      return (
        <Button2
          {...commonActionButtonProps}
          label={buttonDatum.label}
          to={!buttonDatum.url || isActionButtonDisabled ? "" : buttonDatum.url}
          onClick={buttonDatum?.onClick}
        />
      );
    }
  };

  const renderSecondaryButton = (secondaryButton: HeaderButton) => {
    const buttonData = getButtonData(secondaryButton);

    if (!buttonData || !buttonData?.length) return;

    const commonSecondaryButtonProps = {
      "data-testid": "header-button-two",
      ml: [
        configFilter ? 2 : 0,
        configFilter ? 2 : 0,
        isExpanded ? 2 : configFilter ? 2 : 0,
        2,
      ],
      type: "outline",
      isDisabled: isSecondaryButtonDisabled,
      isLoading: isSecondaryButtonLoading,
    };

    if (buttonData.length > 1) {
      return (
        <Button2
          {...commonSecondaryButtonProps}
          multiSelectOptions={buttonData}
        />
      );
    } else {
      const buttonDatum = buttonData[0];
      if (!buttonDatum) return;
      return (
        <Button2
          {...commonSecondaryButtonProps}
          onClick={buttonDatum?.onClick}
          label={buttonDatum.label}
          to={!buttonDatum.url || isActionButtonDisabled ? "" : buttonDatum.url}
        />
      );
    }
  };

  const hasConfigActionButton =
    !!configActionButton &&
    (Array.isArray(configActionButton)
      ? !configActionButton.every((option) => !option?.isActionAllowed)
      : configActionButton.isActionAllowed);
  const hasSecondaryButton =
    !!secondaryButton &&
    (Array.isArray(secondaryButton)
      ? !secondaryButton.every((option) => !option?.isActionAllowed)
      : secondaryButton.isActionAllowed);

  const hasAncestor = !!(
    configPageTitle?.ancestorLabel && configPageTitle?.ancestorUrl
  );
  const hasGrandParent = !!(
    configPageTitle?.grandParentLabel && configPageTitle?.grandParentUrl
  );
  const hasParent = !!(
    configPageTitle?.parentLabel && configPageTitle?.parentUrl
  );

  return (
    <StyledHeader
      {...{ isExpanded }}
      hasBanner={!!bannerType}
      hasNavExtra={!!topNavExtra}
      flexGrow={1}
      flexDirection={["column", "column", isExpanded ? "row" : "column", "row"]}
      alignItems={[
        "flex-start",
        "flex-start",
        isExpanded ? "center" : "flex-start",
        "center",
      ]}
      mt={[isExpanded ? 9 : 0, 0]}
      mr={[0, 0, isExpanded ? -4 : 0, isExpanded ? -7 : 0]}
      pt={[
        4,
        4,
        isExpanded ? (topNavExtra ? 2 : 5) : 4,
        isExpanded ? 5 : rem(14),
      ]}
      pb={[4, 5, isExpanded ? 5 : rem(13)]}
      pl={[0, 0, isExpanded ? 6 : 0]}
      pr={[0, 0, isExpanded ? 4 : 0, isExpanded ? 7 : 0]}
    >
      <Flex
        alignItems={["flex-start", "flex-start", "flex-start", "center"]}
        flexDirection={["column", "column", "column", "row"]}
        flexShrink={0}
      >
        {configPageTitle && (
          <Flex
            mt={[0, 0, 0, isExpanded ? rem(-1) : 0]}
            flexDirection={["column", "column", "row"]}
            alignItems={["flex-start", "flex-start", "center"]}
          >
            {hasAncestor && (
              <TitleItem
                icon={configPageTitle?.ancestorIcon}
                isFirst
                label={configPageTitle.ancestorLabel!}
                url={configPageTitle.ancestorUrl!}
              />
            )}

            {hasGrandParent && (
              <TitleItem
                label={configPageTitle.grandParentLabel!}
                isFirst={!hasAncestor}
                url={configPageTitle.grandParentUrl!}
              />
            )}

            {hasParent && (
              <TitleItem
                label={configPageTitle.parentLabel!}
                isFirst={!hasAncestor && !hasGrandParent}
                shouldTitleShorten={
                  !!(
                    configPageTitle.parentLabel || configPageTitle.ancestorLabel
                  )
                }
                url={configPageTitle.parentUrl!}
              />
            )}

            <TitleItem
              icon={configPageTitle.icon}
              label={configPageTitle.label!}
              url={configPageTitle.parentUrl!}
              isEarlyAccess={configPageTitle?.isEarlyAccess}
              isFirst={!hasAncestor && !hasGrandParent && !hasParent}
              isLast
              testId="header-section-title"
              tooltipContent={configPageTitle.tooltipContent}
              tooltipExtraLinkLabel={configPageTitle.tooltipExtraLinkLabel}
              tooltipExtraLinkUrl={configPageTitle.tooltipExtraLinkUrl}
            />
          </Flex>
        )}

        {topNavExtra && (
          <Box
            flexShrink={0}
            mt={[3, 2, isExpanded ? 0 : 2, 0]}
            ml={[0, 0, isExpanded ? 8 : 0, 7]}
          >
            {topNavExtra}
          </Box>
        )}
      </Flex>

      {(configFilter ||
        hasConfigActionButton ||
        hasSecondaryButton ||
        refreshButton) && (
        <Flex
          width={[
            "100%",
            "100%",
            isExpanded ? (configFilter ? "100%" : "auto") : "100%",
            configFilter ? "100%" : "auto",
          ]}
          flexDirection={["column", "row"]}
          mt={[3, 3, isExpanded ? (topNavExtra ? 3 : 0) : 3, 0]}
          ml={[0, 0, isExpanded ? "auto" : 0, "auto"]}
          pl={[
            0,
            0,
            isExpanded ? (configFilter ? 4 : 0) : 0,
            configFilter ? 4 : 0,
          ]}
          pt={rem(2)}
          justifyContent="space-between"
        >
          {configFilter && (
            <Box width="100%" maxWidth={["none", rem(320), rem(320), rem(240)]}>
              <Input
                data-testid={
                  configFilter.testId ? configFilter.testId : undefined
                }
                htmlType="text"
                size="small"
                placeholder="Filter"
                value={configFilter.inputText}
                onChange={(e: { target: { value: string } }) => {
                  return configFilter.setInputText(e.target.value);
                }}
              />
            </Box>
          )}

          {(hasConfigActionButton || hasSecondaryButton || refreshButton) && (
            <Flex
              flexShrink={0}
              mt={[configFilter ? 2 : 0, 0]}
              ml={[
                refreshButton ? -2 : 0,
                configFilter ? 2 : 0,
                configFilter ? 2 : 0,
                2,
              ]}
            >
              {refreshButton && (
                <ButtonRefresh
                  emptyItems={refreshButton.emptyItems}
                  itemsLoading={refreshButton.itemsLoading}
                  loadItems={refreshButton.loadItems}
                  setItems={refreshButton.setItems}
                />
              )}

              {hasSecondaryButton && renderSecondaryButton(secondaryButton)}
              {hasConfigActionButton && renderActionButton(configActionButton)}
            </Flex>
          )}
        </Flex>
      )}
    </StyledHeader>
  );
};

export default Header;
