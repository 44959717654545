import { TabLinks } from "../../../../../components/Tabs/Tabs.types";
import { PanelViewType } from "../RunDetails.types";

type GetResultTabs = {
  hasOutput: boolean;
  panelView: PanelViewType;
  setPanelView: React.Dispatch<React.SetStateAction<PanelViewType>>;
  hideVehicles?: boolean;
};

export const getResultTabs = ({
  hasOutput,
  hideVehicles,
  panelView,
  setPanelView,
}: GetResultTabs): TabLinks => {
  return [
    ...(!hideVehicles
      ? [
          {
            id: "tab-vehicles",
            label: "Vehicles",
            isActive: panelView === "vehicles",
            onClick: (e: {
              preventDefault: () => void;
              stopPropagation: () => void;
            }) => {
              e.preventDefault();
              e.stopPropagation();
              return setPanelView("vehicles");
            },
          },
        ]
      : []),
    {
      id: "tab-output",
      label: "Output",
      isActive: panelView === "output",
      isDisabled: !hasOutput,
      onClick: (e: {
        preventDefault: () => void;
        stopPropagation: () => void;
      }) => {
        e.preventDefault();
        e.stopPropagation();
        return setPanelView("output");
      },
    },
  ];
};
