import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import Flex from "../../components/Flex";
import { IconDecision } from "../../icons";
import { filterProps } from "../../utils/filterProps";
import { formatNumber, rem } from "../../utils/tools";
import Text from "../Text";

type BasicStyledSystem = number | string | any[];
type DecisionAmountType = number | string;

const AdjustLabel = styled("span", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})`
  transform: translateY(${rem(1)});
`;

type DecisionCountProps = {
  amount: DecisionAmountType;
  isDetailed?: boolean;
  isLarge?: boolean;
  isSmall?: boolean;
  label?: string;
  m?: BasicStyledSystem;
  mt?: BasicStyledSystem;
  mr?: BasicStyledSystem;
  mb?: BasicStyledSystem;
  ml?: BasicStyledSystem;
  title?: string;
};

const DecisionCount = ({
  amount,
  isDetailed,
  isLarge,
  isSmall,
  label,
  m,
  mt,
  mr,
  mb,
  ml,
}: DecisionCountProps) => {
  const theme = useTheme();

  return (
    <Flex {...{ m, mt, mr, mb, ml }} alignItems={"center"}>
      <IconDecision
        iconColor={theme.color.gray800}
        iconSize={isSmall ? 16 : 24}
      />

      <Flex alignItems={"flex-end"} flexDirection={"row"}>
        <Text
          as="strong"
          styleName="body-3-bold"
          ml={isSmall ? rem(2) : 1}
          styles={{ color: theme.color.gray800 }}
        >
          {typeof amount === "number"
            ? isDetailed
              ? amount.toLocaleString()
              : formatNumber(amount)
            : amount}
        </Text>
        {label && (
          <AdjustLabel>
            <Text as="span" ml={1} styleName="body-2">
              {label}
            </Text>
          </AdjustLabel>
        )}
      </Flex>
    </Flex>
  );
};

export default DecisionCount;
