import * as React from "react";

import BaseAvatar, { AvatarProps } from "./BaseAvatar";

const AvatarExperimentShadow = ({ size }: AvatarProps) => {
  return (
    <BaseAvatar size={size || 24} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11.6184 6.10431C11.8538 5.96523 12.1462 5.96523 12.3815 6.10431L18.2482 9.57097C18.4765 9.70591 18.6166 9.95142 18.6166 10.2167C18.6166 10.4819 18.4765 10.7274 18.2482 10.8624L12.3815 14.329C12.1462 14.4681 11.8538 14.4681 11.6184 14.329L5.75175 10.8624C5.5234 10.7274 5.3833 10.4819 5.3833 10.2167C5.3833 9.95142 5.5234 9.70591 5.75175 9.57097L11.6184 6.10431ZM7.60756 10.2167L12 12.8122L16.3924 10.2167L12 7.62115L7.60756 10.2167ZM5.4876 13.8684C5.69832 13.5118 6.15824 13.3936 6.51484 13.6043L12 16.8455L17.4851 13.6043C17.8417 13.3936 18.3016 13.5118 18.5123 13.8684C18.723 14.2251 18.6048 14.685 18.2482 14.8957L12.3815 18.3624C12.1462 18.5014 11.8538 18.5014 11.6184 18.3624L5.75175 14.8957C5.39514 14.685 5.27688 14.2251 5.4876 13.8684Z"
        fill="#AF7AA1"
      />
    </BaseAvatar>
  );
};

export default AvatarExperimentShadow;
