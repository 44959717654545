import styled from "@emotion/styled";
import { space } from "styled-system";

import { filterProps } from "../../utils/filterProps";
import { styleLinks } from "../../utils/linkStyles";
import { rem } from "../../utils/tools";

import { StyledLabelProps } from "./InputRadio.types";

export const StyledInputRadioLabel = styled("label", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledLabelProps>`
  ${space}

  display: block;
  padding-left: ${(props) =>
    props.isSmall ? props.theme.spacing.s4 : props.theme.spacing.s5};
  position: relative;
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody2};
  font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody2};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody2Bold};
  line-height: ${({ theme }) => theme.ui2Typography.lineHeightBody2};
  letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingBody2};
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  color: ${(props) =>
    props.isDisabled
      ? props.theme.color.gray500
      : props.isSelected
      ? props.theme.color.gray800
      : props.theme.color.gray700};

  ${(props) =>
    props.isSmall &&
    `
      font-family: ${props.theme.ui2Typography.fontFamilyBody3};
      font-size: ${props.theme.ui2Typography.fontSizeBody3};
      font-weight: ${props.theme.ui2Typography.fontWeightBody3Bold};
      line-height: ${props.theme.ui2Typography.lineHeightBody3};
      letter-spacing: ${props.theme.ui2Typography.letterSpacingBody3};
    `}

  ${({ theme }) => styleLinks(theme)}

  &::before {
    content: "";
    box-sizing: border-box;
    display: block;
    width: ${(props) =>
      props.isSmall ? props.theme.spacing.s3 : props.theme.spacing.s4};
    height: ${(props) =>
      props.isSmall ? props.theme.spacing.s3 : props.theme.spacing.s4};
    position: absolute;
    left: 0;
    top: ${(props) => (props.isSmall ? rem(1) : rem(3))};
    border-width: ${(props) =>
      props.isSelected
        ? props.isSmall
          ? props.theme.border.width
          : props.theme.border.widthThick
        : props.theme.border.width};
    border-style: ${({ theme }) => theme.border.style};
    border-color: ${(props) =>
      props.isSelected
        ? props.isDisabled
          ? props.theme.color.gray400
          : props.theme.color.gray700
        : props.theme.color.gray300};
    border-radius: ${({ theme }) => theme.spacing.s2};
    z-index: 1;

    /* eye roll */
    ${(props) => !props.isSmall && `transform: translateY(${rem(-0.5)});`}
  }

  ${(props) =>
    !!props.isSelected &&
    `
    &::after {
      content: "";
      box-sizing: border-box;
      display: block;
      width: ${props.isSmall ? rem(6) : props.theme.spacing.s2};
      height: ${props.isSmall ? rem(6) : props.theme.spacing.s2};
      position: absolute;
      left: ${props.isSmall ? rem(3) : rem(4)};
      top: ${props.isSmall ? rem(4) : rem(7)};
      border-radius: ${props.theme.spacing.s2};
      background-color: ${
        props.isDisabled ? props.theme.color.gray400 : props.theme.color.gray700
      };
      z-index: 2;

      /* eye roll */
      ${!props.isSmall && `transform: translateY(${rem(-0.5)});`}
    }
  `}
`;

export const StyledInputRadio = styled("input", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})`
  position: absolute;
  opacity: 0;
`;
