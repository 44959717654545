import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const AMPL_FACILITY_LOCATION_PARTNER_ID = "ampl";
export const AMPL_FACILITY_LOCATION_APP_ID = "fac.loc.ampl";
export const AMPL_FACILITY_LOCATION_SUBSCRIPTION_ID = `${AMPL_FACILITY_LOCATION_PARTNER_ID}-${AMPL_FACILITY_LOCATION_APP_ID}`;
export const AMPL_FACILITY_LOCATION_ICON_URL = "/images/logos/ampl-icon.svg";
export const AMPL_FACILITY_LOCATION_NAME = "AMPL Facility Location";
export const AMPL_FACILITY_LOCATION_TEMPLATE_NAME = "fac-loc-ampl";
export const AMPL_FACILITY_LOCATION_SHORT_DESC =
  "Solves a facility location problem using AMPL.";
export const AMPL_FACILITY_LOCATION_SUMMARY =
  "Solve a facility location problem with an AMPL app. Define facilities, customers and their demands, costs, capacities, and scenarios along with their probabilities and then run the app to decide which facilities to open up.";
export const AMPL_FACILITY_LOCATION_DOCS_LINK = "";
export const AMPL_FACILITY_LOCATION_CODE_REPO_LINK =
  "https://github.com/nextmv-io/community-apps/tree/develop/python-ampl-facilitylocation";
export const AMPL_FACILITY_LOCATION_MODEL_TYPE = ["Facility Location"];
export const AMPL_FACILITY_LOCATION_LANGUAGE = ["Python"];
export const AMPL_FACILITY_LOCATION_PACKAGES = ["AMPL"];
export const AMPL_FACILITY_LOCATION_SOLVER = ["HiGHS"];

export const AMPL_FACILITY_LOCATION_CONSTRAINTS: MarketplaceAppConstraint[] = [
  {
    id: "FACILITIES",
    name: "FACILITIES",
    property: "FACILITIES",
    docsUrl: "",
    summary: "Names of possible facilities to open.",
    type: "array",
  },
  {
    id: "CUSTOMERS",
    name: "CUSTOMERS",
    property: "CUSTOMERS",
    docsUrl: "",
    summary: "Names of customers.",
    type: "array",
  },
  {
    id: "SCENARIOS",
    name: "SCENARIOS",
    property: "SCENARIOS",
    docsUrl: "https://www.nextmv.io/docs/community-apps/knapsack/#item",
    summary: "Names of scenarios.",
    type: "array",
  },
  {
    id: "prob",
    name: "prob",
    property: "prob",
    docsUrl: "",
    summary: "Probabilities of each scenario occurring.",
    type: "object | float",
  },
  {
    id: "fixed_cost",
    name: "fixed_cost",
    property: "fixed_cost",
    docsUrl: "",
    summary: "Fixed cost to open each facility.",
    type: "object | float",
  },
  {
    id: "facility_capacity",
    name: "facility_capacity",
    property: "facility_capacity",
    docsUrl: "",
    summary: "Capacity of each facility.",
    type: "object | float",
  },
  {
    id: "variable_cost",
    name: "variable_cost",
    property: "variable_cost",
    docsUrl: "",
    summary: "The associated cost given the customer demand scenarios.",
    type: "object | float",
  },
  {
    id: "customer_demand",
    name: "customer_demand",
    property: "customer_demand",
    docsUrl: "",
    summary: "The customer demand scenarios.",
    type: "float",
  },
];
