import { isObject } from "lodash";

import { getDataStructuredForEditor } from "../../../../../utils/inputHelpers";
import { FUTUREparseTextAsJson } from "../../../../../utils/parseInputOutput";
import { hasShiftSchedulingSchemaTopLevel } from "../../../utils/schemaCheckShiftScheduling";

export type RunInputVizType = "routing" | "scheduling" | "custom";
export const getRunInputVizType = (inputState: {
  [key: string]: any;
}): RunInputVizType => {
  const formattedInput = getDataStructuredForEditor(inputState?.input);
  const inputForVizTest = FUTUREparseTextAsJson(formattedInput.text);

  if (!inputForVizTest || !isObject(inputForVizTest)) return "custom";

  // special case because of legacy routing setup
  if (inputState?.markerCoords?.length) {
    return "routing";
  }

  if (hasShiftSchedulingSchemaTopLevel(inputForVizTest)) {
    return "scheduling";
  }

  return "custom";
};
