import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { AcceptanceTests as AcceptanceTestsType } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import StandardError from "../../../../components/StandardError";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import { getFilteredEntities } from "../../../../utils/entities/getFilteredEntities";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../../App/App.types";
import LoadingListView from "../../../App/components/LoadingListView";
import { experimentsTooltips } from "../../data/microcopy";
import { renderExperimentsList } from "../../utils/renderExperimentsList";

const pageTitle = "Acceptance Tests";

const AcceptanceTests = ({ app }: AppPageProps) => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [filterText, setFilterText] = useState("");

  const {
    acceptanceTests,
    acceptanceTestsError,
    loadAcceptanceTests,
    setAcceptanceTests,
  } = useExperiments();

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load acceptance tests
  useEffect(() => {
    if (!acceptanceTests && !acceptanceTestsError) {
      loadAcceptanceTests(app.id);
    }
  }, [acceptanceTests, acceptanceTestsError, app.id, loadAcceptanceTests]);

  if (acceptanceTestsError) {
    return <StandardError errorMessage={acceptanceTestsError} />;
  }

  const canUserCreateAndEditAcceptance = userHasAccessToAction(
    roles,
    ActionGroups.ExperimentOperator,
    {}
  );

  const filteredAcceptanceTests = getFilteredEntities(
    filterText,
    acceptanceTests
  );
  const renderAcceptanceTests = () => {
    return renderExperimentsList({
      accountId,
      app,
      canUserCreateAndEdit: canUserCreateAndEditAcceptance,
      experiments: filteredAcceptanceTests as AcceptanceTestsType,
      theme,
      kind: "acceptance",
      urlOnClickTrackEventCategory: "AcceptanceTests",
      urlOnClickTrackEventProperties: {
        view: "Acceptance Tests",
        action: "Link Clicked",
        meta: {
          type: "Acceptance Test Detail",
        },
      },
    });
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: experimentsTooltips.acceptance.content,
          tooltipExtraLinkLabel: experimentsTooltips.acceptance.extraLinkLabel,
          tooltipExtraLinkUrl: experimentsTooltips.acceptance.extraLinkUrl,
        }}
        configActionButton={{
          label: "New acceptance test",
          url: `${pathname}/new`,
          onClick: () =>
            trackEvent("AcceptanceTests", {
              view: "Acceptance Tests",
              action: "Create Acceptance Test Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditAcceptance,
        }}
        configFilter={{
          inputText: filterText,
          testId: "filter-acceptance-tests",
          setInputText: setFilterText,
        }}
        refreshButton={{
          emptyItems: null,
          itemsLoading: !acceptanceTests,
          loadItems: () => loadAcceptanceTests(app.id),
          setItems: setAcceptanceTests,
        }}
      />

      <PlanNotice {...{ app }} type="custom-apps" />

      {!acceptanceTests ? <LoadingListView /> : renderAcceptanceTests()}
    </>
  );
};

export default AcceptanceTests;
