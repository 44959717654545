import React from "react";
import { useLocation } from "react-router-dom";

import { RunMetadataResponse } from "../../../../../api/core/controlPlane.types";
import { API_BASE } from "../../../../../api/core/core.config";
import { useUser } from "../../../../../AuthProvider";
import Flex from "../../../../../components/Flex";
import InputEditor from "../../../../../components/InputEditor";
import OutputEditor from "../../../../../components/OutputEditor";
import useStreamlitTokenRefresh from "../../../../../hooks/useStreamlitTokenRefresh";
import { HEADER_HEIGHT } from "../../../../../utils/constants";
import { rem } from "../../../../../utils/tools";

import DownloadRunInput from "./DownloadRunInput";
import DownloadRunOutput from "./DownloadRunOutput";

type RunDetailsCustomViewProps = {
  appId: string;
  runMetadata: RunMetadataResponse;
  type: "input" | "result";
  runAsString?: string | null;
  runInputAsString?: string | null;
};

const RunDetailsCustomView = ({
  appId,
  runAsString,
  runInputAsString,
  runMetadata,
  type,
}: RunDetailsCustomViewProps) => {
  const [{ features = {}, id: accountId }] = useUser();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const resultViz = searchParams.get("result_viz");
  const encodedApiUrl = encodeURIComponent(API_BASE);
  const token = useStreamlitTokenRefresh({ streamlitSubdomain: resultViz });
  return (
    <Flex flexDirection="column" height={`calc(100% - ${HEADER_HEIGHT})`}>
      <Flex
        minHeight={["50vh", 0]}
        maxWidth={rem(860)}
        mb={6}
        flexDirection="column"
        flexGrow={1}
        hasBorderRight
        hasBorderBottom
        hasBorderLeft
      >
        {type === "input" && (
          <>
            <DownloadRunInput {...{ appId, runMetadata }} />
            <InputEditor isReadOnly input={runInputAsString} />
          </>
        )}
        {type === "result" && (
          <>
            <DownloadRunOutput {...{ appId, runMetadata }} />
            <OutputEditor value={runAsString || ""} />
            {token && features.streamlit && resultViz && (
              <iframe
                title="streamlit"
                src={`https://${resultViz}.streamlit.app?token=${token}&account=${accountId}&app_id=${appId}&run_id=${runMetadata.id}&url=${encodedApiUrl}&embedded=true`}
                style={{
                  width: "100%",
                  height: "1800px",
                  paddingTop: "40px",
                }}
              />
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default RunDetailsCustomView;
