import React, { useState } from "react";

import { getRunInput, getRunInputURL } from "../../../api/core/controlPlane";
import {
  EntityErrorMessage,
  RunInput,
  RunInputWithURL,
  RunInputWithURLResponse,
} from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";
import useDownloadAndUntar from "../../../utils/fileHandling/useDownloadAndUntar";
import {
  FUTUREparseTextAsJson,
  ValidJSON,
} from "../../../utils/parseInputOutput";

const useRunInput = () => {
  const [runInput, setRunInput] = useState<RunInput>(null);
  const [runInputAsString, setRunInputAsString] = useState<RunInput>(null);
  const [runInputError, setRunInputError] = useState<EntityErrorMessage>(null);
  const [runInputWithURL, setRunInputWithURL] = useState<RunInputWithURL>(null);
  const [runInputWithURLError, setRunInputWithURLError] =
    useState<EntityErrorMessage>(null);

  const downloadAndUntar = useDownloadAndUntar();

  const [{ id: accountId }] = useUser();

  // TODO: convert to useManageEntity
  // run input is returned from control plane as text()
  // and then parsed to JSON for setRunInput
  // setRunInputAsString is used for lossless parsing
  const loadRunInput = React.useCallback(
    async (
      applicationId: string,
      runId: string
    ): Promise<ValidJSON | undefined> => {
      try {
        const runInputRes = await getRunInput(accountId || "")(
          applicationId,
          runId
        );
        const runInput = runInputRes;

        setRunInputAsString(runInput);
        setRunInput(FUTUREparseTextAsJson(runInput));

        setRunInputError(null);
        return runInput;
      } catch (e: any) {
        console.error(e);
        setRunInputError(e.toString());
      }
    },
    [accountId]
  );

  const loadRunInputWithURL = React.useCallback(
    async (applicationId: string, runId: string) => {
      try {
        const runInputRes = await getRunInputURL(accountId || "")(
          applicationId,
          runId
        );
        const runInput = runInputRes as RunInputWithURLResponse;
        setRunInputWithURL(runInput);
        setRunInputWithURLError(null);
        return runInput;
      } catch (e: any) {
        console.error(e);
        setRunInputWithURLError(e.toString());
      }
    },
    [accountId]
  );

  const loadGzippedRunInput = React.useCallback(
    async (downloadUrl, runId) => {
      try {
        await downloadAndUntar(downloadUrl, runId);
      } catch (e: any) {
        console.error(e);
        setRunInputWithURLError(e.toString());
      }
    },
    [downloadAndUntar]
  );

  return {
    loadGzippedRunInput,
    loadRunInput,
    loadRunInputWithURL,
    runInput,
    runInputAsString,
    runInputError,
    runInputWithURL,
    runInputWithURLError,
  };
};

export default useRunInput;
