import styled from "@emotion/styled";

import Flex from "../../components/Flex";
import { rem } from "../../utils/tools";

export const StyledMetaLinkFlex = styled(Flex)`
  margin-top: ${({ theme }) => theme.spacing.s8};
  margin-bottom: ${({ theme }) => theme.spacing["-s8"]};

  @media (min-height: ${rem(960)}) {
    margin-top: ${({ theme }) => theme.spacing["-s9"]};
    margin-bottom: 0;
  }
`;
