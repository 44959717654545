import React from "react";
import { useTheme } from "@emotion/react";

import Flex from "../../../components/Flex";
import { PlanDetails } from "../../../pages/Team/Team.types";
import { rem } from "../../../utils/tools";
import Tag from "../../Tag";
import Text from "../../Text";

const SelfCheckoutPlanSummary = ({ planInfo }: { planInfo: PlanDetails }) => {
  const theme = useTheme();

  return (
    <Flex mt={2} justifyContent="space-between">
      <Flex alignItems="center">
        <Text
          as="h3"
          styleName="marketing-header-3"
          styles={{
            fontSize: rem(22),
            lineHeight: rem(25),
            color: theme.color.gray800,
          }}
        >
          {planInfo.name}
        </Text>

        <Tag
          mt={rem(2)}
          ml={2}
          label="14-day free trial"
          textColor={theme.color.sky700}
          backgroundColor={theme.color.sky100}
        />
      </Flex>

      <Text
        mt={rem(2)}
        as="h4"
        styleName="header-1"
        styles={{
          fontSize: rem(21),
          lineHeight: rem(25),
          color: theme.color.gray800,
        }}
      >
        {planInfo.price}
        <Text as="span" styleName="body-1">
          {planInfo.priceCadence}
        </Text>
      </Text>
    </Flex>
  );
};

export default SelfCheckoutPlanSummary;
