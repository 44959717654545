import React, { useMemo } from "react";

import { getDataStructuredForEditor } from "../../utils/inputHelpers";
import Editor from "../Editor/Editor.component";

type OutputEditorProps = {
  value: string;
};

const OutputEditor = ({ value }: OutputEditorProps) => {
  const formattedOutput = useMemo(() => {
    return getDataStructuredForEditor(value);
  }, [value]);

  return <Editor readOnly={true} content={formattedOutput} />;
};

export default OutputEditor;
