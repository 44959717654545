import React from "react";
import { useTheme } from "@emotion/react";

import Flex from "../../../components/Flex";
import GoogleButton from "../../../components/GoogleButton";
import Text from "../../../components/Text";
import { rem } from "../../../utils/tools";

type GoogleAuthenticateProps = {
  buttonLabel: string;
  buttonName: string;
  buttonOnClick: (e: any) => void;
};

const GoogleAuthenticate = ({
  buttonLabel,
  buttonName,
  buttonOnClick,
}: GoogleAuthenticateProps) => {
  const theme = useTheme();

  return (
    <Flex
      hasBorderTop
      mt={[9, 9, 10]}
      flexDirection="column"
      alignItems="flex-start"
    >
      <Text
        as="span"
        mt={rem(-11)}
        pr={2}
        pb={4}
        styleName="body-3"
        styles={{
          display: "block",
          color: theme.color.gray500,
          backgroundColor: theme.color.white,
        }}
      >
        Or
      </Text>

      <GoogleButton
        label={buttonLabel}
        name={buttonName}
        onClick={buttonOnClick}
      />
    </Flex>
  );
};

export default GoogleAuthenticate;
