import * as React from "react";

import BaseAvatar, { AvatarProps } from "./BaseAvatar";

const AvatarEnsembleDef = ({ size }: AvatarProps) => {
  return (
    <BaseAvatar size={size || 24} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM9.5 6.75H7.5C7.08579 6.75 6.75 7.08579 6.75 7.5V9.5C6.75 9.91421 7.08579 10.25 7.5 10.25H9.5C9.91421 10.25 10.25 9.91421 10.25 9.5V7.5C10.25 7.08579 9.91421 6.75 9.5 6.75ZM7.5 5.5C6.39543 5.5 5.5 6.39543 5.5 7.5V9.5C5.5 10.6046 6.39543 11.5 7.5 11.5H9.5C10.6046 11.5 11.5 10.6046 11.5 9.5V7.5C11.5 6.39543 10.6046 5.5 9.5 5.5H7.5ZM9.5 13.75H7.5C7.08579 13.75 6.75 14.0858 6.75 14.5V16.5C6.75 16.9142 7.08579 17.25 7.5 17.25H9.5C9.91421 17.25 10.25 16.9142 10.25 16.5V14.5C10.25 14.0858 9.91421 13.75 9.5 13.75ZM7.5 12.5C6.39543 12.5 5.5 13.3954 5.5 14.5V16.5C5.5 17.6046 6.39543 18.5 7.5 18.5H9.5C10.6046 18.5 11.5 17.6046 11.5 16.5V14.5C11.5 13.3954 10.6046 12.5 9.5 12.5H7.5ZM14.5 6.75H16.5C16.9142 6.75 17.25 7.08579 17.25 7.5V9.5C17.25 9.91421 16.9142 10.25 16.5 10.25H14.5C14.0858 10.25 13.75 9.91421 13.75 9.5V7.5C13.75 7.08579 14.0858 6.75 14.5 6.75ZM12.5 7.5C12.5 6.39543 13.3954 5.5 14.5 5.5H16.5C17.6046 5.5 18.5 6.39543 18.5 7.5V9.5C18.5 10.6046 17.6046 11.5 16.5 11.5H14.5C13.3954 11.5 12.5 10.6046 12.5 9.5V7.5ZM16.5 13.75H14.5C14.0858 13.75 13.75 14.0858 13.75 14.5V16.5C13.75 16.9142 14.0858 17.25 14.5 17.25H16.5C16.9142 17.25 17.25 16.9142 17.25 16.5V14.5C17.25 14.0858 16.9142 13.75 16.5 13.75ZM14.5 12.5C13.3954 12.5 12.5 13.3954 12.5 14.5V16.5C12.5 17.6046 13.3954 18.5 14.5 18.5H16.5C17.6046 18.5 18.5 17.6046 18.5 16.5V14.5C18.5 13.3954 17.6046 12.5 16.5 12.5H14.5Z"
        fill="#F46F3E"
      />
    </BaseAvatar>
  );
};

export default AvatarEnsembleDef;
