import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Amplify, Auth, Hub } from "aws-amplify";
import { isEmpty } from "lodash";

import { getUser, getUserOrgs, getUserWithAccount } from "./api/core/dataPlane";
import {
  User,
  UserOrganizations,
  UserPlanData,
  UserWithOrgs,
} from "./api/core/dataPlane.types";
import { getToken, verifySignInTokenOK } from "./api/utils/cognito";
import Loading from "./components/Loading";
import useGetOrgId from "./hooks/useGetOrgId";
import NotFound from "./pages/NotFound";
import isUnauthenticatedRoute from "./utils/isUnauthenticatedRoute";
import { identifyEvent } from "./analytics";
import premiumFlags from "./featuresConfig";
import { compareOrgs, useLocalStorage } from "./utils";

export enum UserActionKind {
  UPDATE_TOS,
  UPDATE,
  UPDATE_PLAN_INFO,
  SIGN_OUT,
  ADD_FLAGS_FROM_CONFIG,
  UPDATE_ORGS,
}

interface UPDATE {
  type: UserActionKind.UPDATE;
  payload: UserWithOrgs | User;
}

interface UPDATE_PLAN_INFO {
  type: UserActionKind.UPDATE_PLAN_INFO;
  payload: UserPlanData;
}

interface ADD_FLAGS_FROM_CONFIG {
  type: UserActionKind.ADD_FLAGS_FROM_CONFIG;
}

interface UPDATE_ORGS {
  type: UserActionKind.UPDATE_ORGS;
  payload: UserOrganizations;
}

interface SIGN_OUT {
  type: UserActionKind.SIGN_OUT;
}

export type UserActions =
  | UPDATE
  | UPDATE_PLAN_INFO
  | SIGN_OUT
  | ADD_FLAGS_FROM_CONFIG
  | UPDATE_ORGS;

export const userReducer = (
  state: UserWithOrgs | undefined,
  action: UserActions
): UserWithOrgs | undefined => {
  switch (action.type) {
    case UserActionKind.UPDATE:
      return { ...state, ...action.payload };
    case UserActionKind.UPDATE_PLAN_INFO:
      return {
        ...state,
        planInfo: {
          ...state?.planInfo,
          ...action.payload,
        },
      };
    case UserActionKind.UPDATE_ORGS:
      return {
        ...state,
        organizations: action.payload,
      };
    case UserActionKind.SIGN_OUT:
      return undefined;
    case UserActionKind.ADD_FLAGS_FROM_CONFIG:
      return { ...state, features: { ...state?.features, ...premiumFlags } };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export const UserContext = React.createContext<
  [UserWithOrgs, React.Dispatch<UserActions>]
>([{}, () => {}]);

const overrideFromEnv = (envVar: string, prodVal: string) =>
  process.env[envVar] || prodVal;

export const REDIRECT_URL = overrideFromEnv(
  "REACT_APP_REDIRECT_URL",
  "https://cloud.nextmv.io"
);

const USER_POOL_REGION = overrideFromEnv(
  "REACT_APP_USER_POOL_REGION",
  "us-east-2"
);
const USER_POOL_ID = overrideFromEnv(
  "REACT_APP_USER_POOL_ID",
  "us-east-2_1jHS2b9HU"
);
const USER_POOL_WEB_CLIENT_ID = overrideFromEnv(
  "REACT_APP_USER_POOL_WEB_CLIENT_ID",
  "3ve7fprpl2ti01jteuiedgdmub"
);
const COGNITO_AUTH_DOMAIN = overrideFromEnv(
  "REACT_APP_COGNITO_AUTH_DOMAIN",
  "auth.cloud.nextmv.io"
);

const cookieStorage = process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN
  ? {
      domain: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN,
      path: "/",
      sameSite: "strict",
      secure: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN !== "localhost",
      expires: 1,
    }
  : undefined;

Amplify.configure({
  Auth: {
    cookieStorage: cookieStorage,
    region: USER_POOL_REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: COGNITO_AUTH_DOMAIN,
      scope: ["email", "profile", "openid"],
      redirectSignIn: REDIRECT_URL,
      redirectSignOut: REDIRECT_URL,
      responseType: "code",
    },
  },
});

type AuthProviderProps = {
  children: React.ReactNode;
  LoggedOutRoutes: React.FunctionComponent;
  onSignOut: () => void;
};

const getLoggedInUserData = async (): Promise<UserWithOrgs | undefined> => {
  const token = await getToken();
  if (!token) return;

  try {
    const orgContextUser = await getUser(token);
    if (isEmpty(orgContextUser)) {
      throw new Error("Invalid User");
    }

    const userOrgs = await getUserOrgs(token);
    userOrgs.sort(compareOrgs);

    return { ...orgContextUser, organizations: userOrgs };
  } catch (err) {
    console.error(err);
    return;
  }
};

const orgInUserOrgs = (orgs: UserOrganizations, orgId: string) => {
  return orgs.some(({ id, pending_invite }) => id === orgId && !pending_invite);
};

export const AuthProvider = ({
  children,
  LoggedOutRoutes,
  onSignOut,
}: AuthProviderProps) => {
  const [user, userDispatch] = React.useReducer(userReducer, undefined);
  const [cognitoLoaded, setCognitoLoaded] = React.useState(false);
  const [, setGse] = useLocalStorage("gse", false);
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [orgAccess, setOrgAccess] = useState(true);
  const orgId = useGetOrgId();
  const history = useHistory();

  useEffect(() => {
    const getUserInfoForOrg = async (userResp: UserWithOrgs) => {
      if (orgInUserOrgs(userResp?.organizations || [], orgId)) {
        const newOrgUser = await getUserWithAccount(orgId);
        if (isEmpty(newOrgUser)) {
          setOrgAccess(false);
          setLoading(false);
          return;
        }
        userDispatch({
          type: UserActionKind.UPDATE,
          payload: { ...newOrgUser, organizations: userResp.organizations },
        });
        setOrgAccess(true);
        setLoading(false);
      } else {
        setOrgAccess(false);
        setLoading(false);
      }
    };

    const loadUserData = async () => {
      const ok = await verifySignInTokenOK();
      if (!ok) {
        setGse(true);
        await cognitoSignOut();
        return;
      }

      const userResp = await getLoggedInUserData();
      if (userResp && !isEmpty(userResp)) {
        setGse(false);
        identifyEvent(userResp);
        if (orgId && orgId !== userResp.id && orgId !== "") {
          await getUserInfoForOrg(userResp);
          return;
        }
        userDispatch({ type: UserActionKind.UPDATE, payload: userResp });
        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    const cognitoSignOut = async () => {
      try {
        const token = await getToken();
        if (token) {
          await Auth.signOut();
          return;
        }
      } catch (e) {
        console.error(e);
        return;
      }
      return;
    };

    if (!cognitoLoaded) {
      Hub.listen("auth", ({ payload: { event, data } }) => {
        switch (event) {
          case "customOAuthState":
            const fromPath: string = data || "/";
            history.push(fromPath);
            break;
          case "signIn":
          case "cognitoHostedUI":
            loadUserData();
            break;
          case "signOut":
            onSignOut();
            userDispatch({ type: UserActionKind.SIGN_OUT });
            history.replace("/");
            setLoading(false);
            break;
          case "signIn_failure":
          case "cognitoHostedUI_failure":
            console.error("Sign in failure", data);
            break;
        }
      });
      setCognitoLoaded(true);
      return;
    }

    if (!orgId) {
      setOrgAccess(true);
    }

    if (isUnauthenticatedRoute(window.location.pathname) && !user) {
      setLoading(false);
      cognitoSignOut();
    } else if (user && orgId && orgId !== user.id && orgId !== "") {
      setOrgAccess(true);
      setLoading(true);
      try {
        getUserInfoForOrg(user);
      } catch (error) {
        setOrgAccess(false);
        setLoading(false);
      }
    } else if (!user) {
      setLoading(true);
      setOrgAccess(true);
      loadUserData();
    }
  }, [cognitoLoaded, onSignOut, orgId, user, history, setGse]);

  if (
    loading ||
    (!!user && !!orgId && user.id !== orgId) ||
    !window.location.pathname.includes(orgId) //this will be false when orgId "" and when is truthy and in path
  ) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  if (!orgAccess) {
    return <NotFound />;
  }

  if (!user) {
    return <LoggedOutRoutes />;
  }

  const value: [UserWithOrgs, React.Dispatch<UserActions>] = [
    user as UserWithOrgs,
    userDispatch,
  ];

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
