import { useState } from "react";

import { trackEvent } from "../../../../../analytics";
import { AppResponse } from "../../../../../api/core/controlPlane.types";
import { InstanceSelectInstanceIds } from "../../../../../components/InstanceSelect/InstanceSelect.types";
import {
  HandleUnitInputsChangeParams,
  SwitchbackRandomPlanUnitInputs,
} from "../NewSwitchbackTest.types";

const useNewSwitchbackTest = (app: AppResponse) => {
  const [pendingBaselineInstanceIds, setPendingBaselineInstanceIds] =
    useState<InstanceSelectInstanceIds>([""]);
  const [pendingCandidateInstanceIds, setPendingCandidateInstanceIds] =
    useState<InstanceSelectInstanceIds>([""]);

  const [unitInputs, setUnitInputs] = useState<SwitchbackRandomPlanUnitInputs>({
    units: 8,
    unit_duration_minutes: 180,
  });

  const [
    isSwitchbackStartTimeDatePickerActive,
    setIsSwitchbackStartTimeDatePickerActive,
  ] = useState(false);
  const [switchbackStartTime, setSwitchbackStartTime] = useState("");

  const handleUnitInputsChange = ({
    target: { name, value },
  }: HandleUnitInputsChangeParams) => {
    setUnitInputs({
      ...unitInputs,
      [name]: parseInt(value),
    });
  };

  const handleSwitchbackStartTimeChange = (e: {
    target: { value: string };
  }) => {
    setSwitchbackStartTime(e.target.value);
    return;
  };
  const handleSwitchbackStartTimeFocus = () => {
    setIsSwitchbackStartTimeDatePickerActive(true);
    return;
  };
  const handleSwitchbackStartTimeClear = () => {
    setIsSwitchbackStartTimeDatePickerActive(false);
    setSwitchbackStartTime("");
    return;
  };
  const handleSwitchbackStartTimeClose = () => {
    setIsSwitchbackStartTimeDatePickerActive(false);
    trackEvent("SwitchbackTests", {
      view: "Create Switchback Test",
      action: "Field Entered",
      meta: {
        field: "start-criteria-time",
      },
    });
    return;
  };
  const handleSwitchbackStartTimeSelect = () => {
    setIsSwitchbackStartTimeDatePickerActive(false);
    trackEvent("SwitchbackTests", {
      view: "Create Switchback Test",
      action: "Field Entered",
      meta: {
        field: "start-criteria-time",
      },
    });
    return;
  };

  return {
    handleSwitchbackStartTimeChange,
    handleSwitchbackStartTimeClear,
    handleSwitchbackStartTimeClose,
    handleSwitchbackStartTimeSelect,
    handleSwitchbackStartTimeFocus,
    handleUnitInputsChange,
    isSwitchbackStartTimeDatePickerActive,
    pendingBaselineInstanceIds,
    pendingCandidateInstanceIds,
    setPendingBaselineInstanceIds,
    setPendingCandidateInstanceIds,
    setSwitchbackStartTime,
    switchbackStartTime,
    unitInputs,
  };
};

export default useNewSwitchbackTest;
