import {
  IconMainNavApps,
  IconMainNavMarketplace,
  IconMainNavRocket,
  IconMainNavTeam,
} from "../../../icons";
import { RenderMainNavIconParams } from "../MainNav.types";

export const renderMainNavIcon = ({
  id,
  isActiveSection,
  theme,
}: RenderMainNavIconParams) => {
  switch (id) {
    case "dashboard":
      return (
        <IconMainNavRocket
          className="icon-main-nav"
          iconColor={isActiveSection ? theme.color.purple : theme.color.gray500}
          aria-hidden="true"
        />
      );
    case "apps":
      return (
        <IconMainNavApps
          className="icon-main-nav"
          iconColor={isActiveSection ? theme.color.purple : theme.color.gray500}
          aria-hidden="true"
        />
      );
    case "marketplace":
      return (
        <IconMainNavMarketplace
          className="icon-main-nav"
          iconColor={isActiveSection ? theme.color.purple : theme.color.gray500}
          aria-hidden="true"
        />
      );
    case "team":
      return (
        <IconMainNavTeam
          className="icon-main-nav"
          iconColor={isActiveSection ? theme.color.purple : theme.color.gray500}
          aria-hidden="true"
        />
      );
    default:
      return <></>;
  }
};
