import * as React from "react";
import { components } from "react-select";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { filterProps } from "../../../utils/filterProps";
import Flex from "../../Flex";
import Text from "../../Text";

// get SingleValue base component from library
const { SingleValue } = components;

type StyledExecutionClassSpanProps = {
  isPremium: boolean;
};
export const StyledExecutionClassSpan = styled("span", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledExecutionClassSpanProps>`
  color: ${(props) =>
    props.isPremium ? props.theme.color.green800 : props.theme.color.gray700};
`;

const CustomSingleValueExecutionClass = (props: any) => {
  const theme = useTheme();

  // get data to build option, this is the object from
  // the data array for this particular option
  const { data } = props;

  return (
    <SingleValue {...props}>
      <Flex alignItems="baseline">
        <StyledExecutionClassSpan isPremium={data.isPremium}>
          {data.id}
        </StyledExecutionClassSpan>

        {data?.isDefault && (
          <Text
            ml={1}
            styleName="body-3"
            styles={{ color: theme.color.gray600 }}
          >
            (default)
          </Text>
        )}
      </Flex>
    </SingleValue>
  );
};

export default CustomSingleValueExecutionClass;
