import { spacing } from "../tokens/space";
import { rem } from "../utils/tools";

// style constants
export const HEADER_HEIGHT = rem(63);
export const HEADER_HEIGHT_MD_NAV_EXTRA = rem(97);
export const MAIN_NAV_MOBILE_HEIGHT = spacing.s9;
export const MAIN_NAV_SIDEBAR_WIDTH = rem(68);
export const SECTION_NAV_SIDEBAR_WIDTH = rem(272);

export const BANNER_HEIGHT_MOBILE = spacing.s10;
export const BANNER_HEIGHT = spacing.s8;

export const SITE_TOP_BORDER_RADIUS = rem(7);
export const ORG_HEADER_HEIGHT = spacing.s8;
export const ORG_HEADER_HEIGHT_NUM = 40;
export const ORG_SWITCHER_WIDTH = rem(270);
export const ORG_SWITCHER_MAX_HEIGHT = rem(480);
export const PROFILE_MENU_WIDTH = rem(196);

export const TEXT_MEASURE_MAX_WIDTH = rem(720);

// contact link
export const NEXTMV_CONTACT_LINK = "https://www.nextmv.io/contact";

// apps
export const DATA_RENDER_STYLE_LIMIT_BYTES: number = 300000;
export const SHADOW_TEST_DEFAULT_MAX_RUNS: number = 300;
export const SWITCHBACK_TEST_MAX_PLAN_UNIT: number = 1000;
export const SWITCHBACK_TEST_MIN_PLAN_UNIT: number = 1;
export const SWITCHBACK_TEST_MAX_PLAN_UNIT_DURATION_MINUTES: number = 10080;
export const SWITCHBACK_TEST_MIN_PLAN_UNIT_DURATION_MINUTES: number = 1;
export const ENTITY_ID_CHAR_COUNT_MAX = 40;
export const ENTITY_ID_CHAR_COUNT_MIN = 2;
export const ENTITY_NAME_CHAR_COUNT_MAX = 64;
export const ENTITY_NAME_CHAR_COUNT_MIN = 3;
export const LATEST_INSTANCE_ID = "latest";

// port from DSC lib

// hide visually only, screen readers have access
export const styleHideVisually = `
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
`;

// undo styleHideVisually
export const styleHideVisuallyReset = `
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  position: inherit;
  overflow: visible;
  clip: auto;
  clip-path: none;
`;

/*
  hide scrollbar completely,
  see: https://stackoverflow.com/a/49278385/1241287
*/
export const hideScrollbar = () => {
  return `
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  `;
};

/*
  custom scrollbar styles
  these styles are mainly used for inner elements scrolling
*/
export const styleScrollbarY = (theme: any) => {
  return `
    /* firefox */
    scrollbar-width: thin;
    /* thumb track */
    scrollbar-color: ${theme.color.gray400} transparent;

    /* chrome, edge */
    ::-webkit-scrollbar {
      width: ${rem(12)};
      background-color: transparent;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
      background-repeat: repeat;
      background-size: 100% auto;
    }
    ::-webkit-scrollbar-track {
      border: ${rem(3)} solid transparent;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      border: ${rem(3)} solid transparent;
      border-radius: ${rem(6)};
      box-shadow: inset 0 0 0 3px ${theme.color.gray400};
    }
  `;
};
export const styleScrollbarX = (theme: any) => {
  return `
    /* firefox */
    scrollbar-width: thin;
    /* thumb track */
    scrollbar-color: ${theme.color.gray400T} transparent;

    /* chrome, edge */
    ::-webkit-scrollbar {
      height: ${rem(12)};
      background-color: transparent;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
      background-repeat: repeat;
      background-size: 100% auto;
    }
    ::-webkit-scrollbar-track {
      border: ${rem(3)} solid transparent;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      border: ${rem(3)} solid transparent;
      border-radius: ${rem(6)};
      box-shadow: inset 0 0 0 3px ${theme.color.gray400T};
    }
  `;
};

// base style for icon components in icons directory
export const svgIconStyle = ({
  activeColor,
  focusColor,
  hoverColor,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
}) => {
  return `
  flex-shrink: 0;
  ${
    !!hoverColor &&
    `
    &:hover {
      fill: ${hoverColor};
    }`
  }
  ${
    !!focusColor &&
    `
    &:focus {
      fill: ${focusColor};
    }`
  }
  ${
    !!activeColor &&
    `
    &:active {
      fill: ${activeColor};
    }`
  }
  `;
};
