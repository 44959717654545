import styled from "@emotion/styled";

import Box from "../../components/Box";

import { GetPanelColorStylesParams, StyledPanelProps } from "./Panel.types";

const getPanelColorStyles = ({ preset, theme }: GetPanelColorStylesParams) => {
  switch (preset) {
    case "config":
      return `
        border-color: ${theme.color.purple200};
        background-color: ${theme.color.purple100};
      `;
    case "instance":
      return `
        border-color: #ffe299;  
        background-color: #fff6d7;
      `;
    case "rules":
      return `
        border-color: ${theme.color.gray200};
        background-color: ${theme.color.gray100};
      `;
    default:
      return `
        background-color: transparent;
      `;
  }
};

export const StyledPanel = styled(Box)<StyledPanelProps>`
  padding: ${({ theme }) => theme.spacing.s3};
  border-width: ${({ theme }) => theme.border.width};
  border-style: ${({ theme }) => theme.border.style};
  border-radius: ${({ theme }) => theme.border.radius};

  ${(props) =>
    getPanelColorStyles({ preset: props.preset, theme: props.theme })}
`;
