export const parseEnsembleRuleStatsPath = (path: string): string => {
  if (!path) return "";

  const pathArray = path.split(".");

  const pathLead = pathArray[0];
  if (!pathLead || pathLead === "$") return path;

  return ["$", ...pathArray].join(".");
};
