import React, { useState } from "react";
import { useTheme } from "@emotion/react";

import Box from "../../../../../components/Box";
import Button2 from "../../../../../components/Button2";
import Flex from "../../../../../components/Flex";
import Tag from "../../../../../components/Tag";
import Text from "../../../../../components/Text";
import {
  IconCheck,
  IconCopy,
  IconEyeOpen,
  IconMenuDots,
  IconProfileAPIKey,
} from "../../../../../icons";
import { rem } from "../../../../../utils/tools";
import {
  StyledMiniModal,
  StyledMiniModalActionItem,
  StyledMiniModalActionLink,
} from "../../../Team.styled";
import { ShowAPIControl } from "../APIKeys.styles";
import { APIKeyProps, ModalType } from "../APIKeys.types";

const APIKey = ({
  keyData,
  handleOptionsMenuState,
  displayOptionsMenu,
  isWebhookSecret,
  setDisplayConfirmModal,
}: APIKeyProps) => {
  const theme = useTheme();
  const { api_key: apiKey, description, disabled, name } = keyData;

  const [isApiKeyHidden, setIsApiKeyHidden] = useState(true);
  const [copyMessage, setCopyMessage] = useState("");

  // copy content of textarea to clipboard
  // switch to ref and data attribute if multiple API keys
  const copyAPIKey = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigator.clipboard.writeText(apiKey);

    // display toast message
    setCopyMessage("Copied!");

    // remove message after 3 seconds
    const timer = setTimeout(() => {
      setCopyMessage("");
    }, 3000);

    return () => clearTimeout(timer);
  };

  const toggleAPIKeyDisplay = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    return setIsApiKeyHidden(!isApiKeyHidden);
  };

  const teamName = name || "";
  const apiKeyTitle = teamName;

  return (
    <Flex width="100%" justifyContent="space-between">
      <Flex>
        {!isWebhookSecret && (
          <IconProfileAPIKey
            iconColor={disabled ? theme.color.gray400 : "#7B47B2"}
          />
        )}

        <Box mt={isWebhookSecret ? -2 : rem(-2)} ml={isWebhookSecret ? -1 : 3}>
          <Box maxWidth={rem(460)}>
            <Flex>
              <Text
                as="h4"
                styleName="body-1-bold"
                styles={{
                  color: disabled ? theme.color.gray600 : theme.color.gray800,
                  textRendering: "optimizeLegibility",
                  WebkitFontSmoothing: "antialiased",
                }}
              >
                {apiKeyTitle}
              </Text>
              {disabled && (
                <Tag
                  mt={rem(2)}
                  ml={rem(6)}
                  size="small"
                  textColor={theme.color.gray700}
                  backgroundColor={theme.color.gray200}
                  label="disabled"
                />
              )}
            </Flex>
            {description && (
              <Text
                mt={disabled ? rem(-1) : rem(1)}
                styleName="body-3"
                styles={{
                  color: disabled ? theme.color.gray600 : theme.color.gray700,
                }}
              >
                {description}
              </Text>
            )}
          </Box>

          <Flex flexDirection="column" alignItems="flex-start" mt={2}>
            <Flex alignItems="center">
              <Text
                data-testid="user-api-key"
                styleName="code"
                styles={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: rem(6),
                  paddingRight: theme.spacing.s3,
                  paddingBottom: rem(6),
                  paddingLeft: theme.spacing.s3,
                  lineHeight: 1.25,
                  letterSpacing: ".036em",
                  color: disabled
                    ? theme.color.gray500
                    : isWebhookSecret
                    ? theme.color.sky800
                    : theme.color.purple800,
                  borderRadius: theme.border.radiusSmall,
                  backgroundColor: disabled
                    ? theme.color.gray100
                    : isWebhookSecret
                    ? theme.color.sky100
                    : theme.color.purple100,
                  wordBreak: "break-all",
                }}
              >
                {isApiKeyHidden ? "•".repeat(apiKey.length) : apiKey}
              </Text>

              <Button2
                data-testid="control-copy-api-key"
                ml={[2, 2, 3]}
                icon={
                  copyMessage ? (
                    <IconCheck iconSize={20} iconColor={theme.color.gray500} />
                  ) : (
                    <IconCopy iconColor={theme.color.gray500} />
                  )
                }
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  copyAPIKey(e)
                }
                type="outline-quiet"
                title="Copy API key to clipboard"
                aria-label="Copy API key to clipboard"
                styles={{
                  width: theme.spacing.s7,
                  boxShadow: `inset 0 0 0 ${rem(1.5)} ${theme.color.gray400}`,
                  "&:hover": {
                    boxShadow: `inset 0 0 0 ${rem(1.5)} ${theme.color.gray500}`,
                  },
                }}
              />
            </Flex>

            <Flex alignItems="center" mt={2}>
              {copyMessage ? (
                <Text
                  ml={1}
                  styleName="meta-1"
                  styles={{
                    color: disabled ? theme.color.gray500 : theme.color.gray700,
                  }}
                >
                  {copyMessage}
                </Text>
              ) : (
                <ShowAPIControl
                  data-testid="control-api-key-visibility"
                  onClick={(e) => toggleAPIKeyDisplay(e)}
                >
                  <IconEyeOpen
                    iconColor={
                      disabled
                        ? theme.color.gray400
                        : isApiKeyHidden
                        ? theme.color.gray500
                        : theme.color.gray700
                    }
                  />
                  <Text
                    data-testid="control-api-key-visibility-label"
                    ml={1}
                    styleName="meta-1"
                    styles={{
                      color: disabled
                        ? theme.color.gray500
                        : isApiKeyHidden
                        ? theme.color.gray600
                        : theme.color.gray800,
                    }}
                  >
                    {`${isApiKeyHidden ? "Show" : "Hide"} ${
                      isWebhookSecret ? "Secret" : "key"
                    }`}
                  </Text>
                </ShowAPIControl>
              )}
            </Flex>
          </Flex>
        </Box>
      </Flex>

      {!isWebhookSecret && (
        <Box relative>
          <Button2
            ml={3}
            type="outline-quiet"
            icon={<IconMenuDots iconSize={24} />}
            styles={{
              width: `calc(${theme.spacing.s7} + ${theme.spacing.s1})`,
              boxShadow: `inset 0 0 0 ${rem(1.5)} ${theme.color.gray400}`,
              svg: {
                fill: theme.color.gray600,
              },
              "&:hover": {
                boxShadow: `inset 0 0 0 ${rem(1.5)} ${theme.color.gray500}`,
                svg: {
                  fill: theme.color.gray800,
                },
              },
            }}
            onClick={(e: any) => handleOptionsMenuState({ e, apiKey })}
          />
          {displayOptionsMenu && (
            <StyledMiniModal>
              <StyledMiniModalActionItem>
                <StyledMiniModalActionLink
                  onClick={(e) =>
                    setDisplayConfirmModal(e, {
                      modalType: disabled
                        ? ModalType.Enable
                        : ModalType.Disable,
                      key: keyData,
                    })
                  }
                >
                  <span>{disabled ? "Enable" : "Disable"}</span>
                </StyledMiniModalActionLink>
              </StyledMiniModalActionItem>
              <StyledMiniModalActionItem>
                <StyledMiniModalActionLink
                  onClick={(e) =>
                    setDisplayConfirmModal(e, {
                      modalType: ModalType.Delete,
                      key: keyData,
                    })
                  }
                  textColorHover={theme.color.red700}
                >
                  <span>Delete</span>
                </StyledMiniModalActionLink>
              </StyledMiniModalActionItem>
            </StyledMiniModal>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default APIKey;
