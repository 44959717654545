import { Link } from "react-router-dom";

import { trackEvent } from "../../../../../analytics";
import {
  AppResponse,
  EnsembleDefsData,
} from "../../../../../api/core/controlPlane.types";
import { AvatarEnsembleDef } from "../../../../../avatars";
import Box from "../../../../../components/Box";
import PreviewRow2 from "../../../../../components/PreviewRow2";
import Text from "../../../../../components/Text";
import { getAccUrl } from "../../../../../utils/navigation";
import { rem } from "../../../../../utils/tools";

type RenderEnsembleDefsListParams = {
  app: AppResponse;
  ensembleDefinitions: EnsembleDefsData;
  canUserCreateAndEditEnsembleDefs: boolean;
  accountId?: string | null;
  isMini?: boolean;
  limit?: number;
};

export const renderEnsembleDefsList = ({
  accountId,
  app,
  ensembleDefinitions,
  canUserCreateAndEditEnsembleDefs,
  isMini,
  limit,
}: RenderEnsembleDefsListParams) => {
  if (!ensembleDefinitions) return;

  if (!ensembleDefinitions.length) {
    return (
      <Text
        testId="ensemble-definitions-not-found-message"
        mt={isMini ? 3 : 5}
        styleName={isMini ? "body-3" : "body-2"}
        hasLink
      >
        No ensemble definitions found.{" "}
        {isMini && canUserCreateAndEditEnsembleDefs && (
          <Link to={getAccUrl(accountId, `/app/${app.id}/runs/ensembles/new`)}>
            Create new ensemble definition
          </Link>
        )}
      </Text>
    );
  }

  const returnedEnsembleDefs = limit
    ? ensembleDefinitions.slice(0, limit)
    : ensembleDefinitions;

  return returnedEnsembleDefs.map((ensembleDefinition, index) => {
    return (
      <PreviewRow2
        {...{ isMini }}
        key={`${ensembleDefinition.id}-${index}`}
        hasNoBorder={index === 0}
        icon={
          <Box mt={rem(1)}>
            <AvatarEnsembleDef size={isMini ? 20 : 24} />
          </Box>
        }
        name={ensembleDefinition.name}
        id={ensembleDefinition.id}
        lastUpdated={ensembleDefinition?.updated_at}
        url={getAccUrl(
          accountId,
          `/app/${app.id}/runs/ensemble/${ensembleDefinition.id}`
        )}
        urlOnClick={() =>
          trackEvent("EnsembleDefs", {
            view: "Ensemble Definitions",
            action: "Link Clicked",
            meta: {
              type: "Ensemble Def Detail",
            },
          })
        }
        {...(!isMini && {
          description: ensembleDefinition?.description,
        })}
        {...(!isMini &&
          canUserCreateAndEditEnsembleDefs && {
            urlEdit: getAccUrl(
              accountId,
              `/app/${app.id}/runs/ensemble/${ensembleDefinition.id}/edit?source=list`
            ),
            urlEditOnClick: () =>
              trackEvent("EnsembleDefs", {
                view: "Ensemble Definitions",
                action: "Link Clicked",
                meta: {
                  type: "Ensemble Def Edit",
                },
              }),
          })}
      />
    );
  });
};
