import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { isEmpty, snakeCase } from "lodash";

import { trackEvent } from "../../../analytics";
import {
  BatchExperiment,
  BatchExperimentGroupedSummary,
  BatchExperimentResponse,
  ShadowTestResponse,
  SwitchbackTestResponse,
} from "../../../api/core/controlPlane.types";
import Box from "../../../components/Box";
import Button2 from "../../../components/Button2";
import Details from "../../../components/Details";
import Flex from "../../../components/Flex";
import Notification from "../../../components/Notification";
import Select from "../../../components/Select";
import Table2 from "../../../components/Table2";
import Text from "../../../components/Text";
import Tooltip from "../../../components/Tooltip";
import { ORG_HEADER_HEIGHT_NUM } from "../../../utils/constants";
import { sortAlpha, sortByConfig } from "../../../utils/sort";
import { rem } from "../../../utils/tools";
import { getTableLayoutSchemaPercentiles } from "../../App/subpages/RunHistory/utils/tableLayoutSchemas";
import { tooltipContentDurationSummaries } from "../data/summaryTable";
import { SummaryViewOption } from "../Experiments.types";
import {
  collectViewOptions,
  defaultSummaryViewOption,
} from "../utils/collectViewOptions";
import { getExperimentStartCriteria } from "../utils/getExperimentStartCriteria";
import {
  getFilteredSummaries,
  getGroupedSummaryTables,
  GroupedSummaryTables,
  IndicatorsState,
  initIndicatorsState,
  mapAvailableIndicatorsToStats,
  StatsByIndicator,
} from "../utils/getGroupedSummaryTables";
import {
  formatGroupedSummaryTableName,
  groupedSummaryOrderConfig,
} from "../utils/groupedSummaryTable";
import { renderResultsNote } from "../utils/renderActions";

import ChartBoxPlot from "./ChartBoxPlot";

type ExperimentGroupedSummariesProps = {
  experiment:
    | BatchExperimentResponse
    | ShadowTestResponse
    | SwitchbackTestResponse;
  kind: "batch" | "shadow" | "switchback";
  isChartDisabled?: boolean;
};

type GetIsIndicatorDetailsOpenParams = {
  focusIndicator: string | null;
  indicator: string;
  index: number;
};

const getIsIndicatorDetailsOpen = ({
  focusIndicator,
  indicator,
  index,
}: GetIsIndicatorDetailsOpenParams) => {
  if (focusIndicator) {
    return focusIndicator === indicator;
  }
  return index === 0 ? true : false;
};

const ExperimentGroupedSummaries = ({
  experiment,
  isChartDisabled = false,
  kind,
}: ExperimentGroupedSummariesProps) => {
  const theme = useTheme();
  const params = useParams() as { id: string };
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const focusMetricField = searchParams.get("field");

  const [summaryView, setSummaryView] = useState<SummaryViewOption>(
    defaultSummaryViewOption
  );
  const [viewOptions, setViewOptions] = useState<SummaryViewOption[]>([]);
  const [orderedIndicators, setOrderedIndicators] = useState<string[]>([]);
  const [indicatorToStats, setIndicatorToStats] = useState<StatsByIndicator>(
    {}
  );
  const [filteredSummaries, setFilteredSummaries] = useState<
    BatchExperimentGroupedSummary[]
  >([]);
  const [groupedSummaryTables, setGroupedSummaryTables] =
    useState<GroupedSummaryTables>({});
  const [indicatorsState, setIndicatorsState] = useState<IndicatorsState>({});
  const [indicatorsPercentileDisplay, setIndicatorsPercentileDisplay] =
    useState<IndicatorsState>({});

  const hasExperimentGroupedSummaries =
    experiment?.grouped_distributional_summaries &&
    !!experiment.grouped_distributional_summaries.length;

  const isBatch = kind === "batch";

  let startCriteria = undefined;
  if (!isBatch) {
    startCriteria = getExperimentStartCriteria(
      experiment as ShadowTestResponse | SwitchbackTestResponse,
      kind
    );
  }

  const handleSummaryViewChange = (selected: SummaryViewOption) => {
    // do nothing if same value is re-selected
    if (selected.value === summaryView.value) return;

    const updatedFilteredSummaries = getFilteredSummaries(
      experiment.grouped_distributional_summaries!,
      selected.keys
    );

    // get summary data for new "group by" for each indicator
    // that was open when the switch was made so we preserve
    // the user experience state after the "group by" switch
    const newGroupedSummariesState = Object.keys(indicatorsState).reduce(
      (newGroupedSummaries, indicator) => {
        if (indicatorsState[indicator]) {
          newGroupedSummaries[indicator] = getGroupedSummaryTables(
            theme,
            updatedFilteredSummaries!,
            indicatorToStats,
            indicator
          );
        }
        return newGroupedSummaries;
      },
      {} as GroupedSummaryTables
    );

    // update state with new "group by" data
    setSummaryView(selected);
    setFilteredSummaries(updatedFilteredSummaries);
    setGroupedSummaryTables(newGroupedSummariesState as GroupedSummaryTables);
  };

  const groupByOptions = useMemo(() => {
    return collectViewOptions(experiment as BatchExperiment);
  }, [experiment]);

  const focusIndicator = useMemo(() => {
    // if clicked from acceptance test to batch experiment view
    if (isBatch && focusMetricField) {
      return focusMetricField.replace(/-/g, ".");
    }
    return null;
  }, [focusMetricField, isBatch]);

  const activeView = useMemo(() => {
    return !viewOptions.length
      ? (experiment as BatchExperimentResponse)?.instance_ids &&
        (experiment as BatchExperimentResponse).instance_ids.length === 1
        ? groupByOptions.find((option) => option.value === "inputID") ||
          groupByOptions[0]
        : defaultSummaryViewOption
      : summaryView;
  }, [experiment, groupByOptions, summaryView, viewOptions.length]);

  // auto-scroll focused metric field into view
  useEffect(() => {
    if (focusMetricField && window.scrollY === 0) {
      const focusElement = document.querySelector(`#${focusMetricField}`);
      const focusElementTopY = focusElement?.getBoundingClientRect().top;

      focusElementTopY &&
        !focusElement.classList.contains("has-scrolled") &&
        window.scrollTo({
          top: focusElementTopY - ORG_HEADER_HEIGHT_NUM,
          behavior: "smooth",
        });

      // mark with classname so auto-scroll happens only once
      focusElement && focusElement.classList.add("has-scrolled");
    }
  });

  // only fill in view options on first load
  useEffect(() => {
    if (hasExperimentGroupedSummaries && isBatch && !viewOptions.length) {
      let initialOption = defaultSummaryViewOption;

      // something went wrong if this happens (I think)
      if (groupByOptions.length === 0) return;

      // if batch experiment with only one instance,
      // default "group by" view to inputID (if it exists)
      if (
        (experiment as BatchExperimentResponse)?.instance_ids &&
        (experiment as BatchExperimentResponse).instance_ids.length === 1
      ) {
        initialOption =
          groupByOptions.find((option) => option.value === "inputID") ||
          groupByOptions[0];
      } else {
        initialOption = groupByOptions[0];
      }

      setSummaryView(initialOption);
      setViewOptions(groupByOptions);
    }
  }, [
    experiment,
    groupByOptions,
    hasExperimentGroupedSummaries,
    isBatch,
    viewOptions.length,
  ]);

  // refresh table data as new data comes in
  // TODO: optimize
  useEffect(() => {
    const updatedFilteredSummaries = getFilteredSummaries(
      experiment.grouped_distributional_summaries!,
      activeView.keys
    );
    // get summary data for each indicator that is open
    const newGroupedSummariesState = Object.keys(indicatorsState).reduce(
      (newGroupedSummaries, indicator) => {
        if (indicatorsState[indicator]) {
          newGroupedSummaries[indicator] = getGroupedSummaryTables(
            theme,
            updatedFilteredSummaries!,
            indicatorToStats,
            indicator
          );
        }
        return newGroupedSummaries;
      },
      {} as GroupedSummaryTables
    );

    setGroupedSummaryTables(newGroupedSummariesState as GroupedSummaryTables);
  }, [
    activeView.keys,
    experiment.grouped_distributional_summaries,
    indicatorToStats,
    indicatorsState,
    theme,
  ]);

  // load default data with initial load
  useEffect(() => {
    if (hasExperimentGroupedSummaries && isEmpty(indicatorsState)) {
      const updatedFilteredSummaries = getFilteredSummaries(
        experiment.grouped_distributional_summaries!,
        activeView.keys
      );

      const updatedIndicatorsState = initIndicatorsState(
        updatedFilteredSummaries
      );
      const updatedIndicatorToStats = mapAvailableIndicatorsToStats(
        updatedFilteredSummaries
      );
      const orderedIndicators = Object.keys(updatedIndicatorsState)
        .sort((a, b) => sortAlpha(a, b))
        .sort((a, b) => sortByConfig(a, b, groupedSummaryOrderConfig));

      const openedIndicator = focusIndicator || orderedIndicators[0];

      const initialGroupedSummaryTables = getGroupedSummaryTables(
        theme,
        updatedFilteredSummaries,
        updatedIndicatorToStats,
        openedIndicator
      );

      setFilteredSummaries(updatedFilteredSummaries);
      setOrderedIndicators(orderedIndicators);
      setIndicatorToStats(updatedIndicatorToStats);
      setIndicatorsState({
        ...updatedIndicatorsState,
        [openedIndicator]: true,
      });
      setIndicatorsPercentileDisplay({
        ...updatedIndicatorsState,
      });
      setGroupedSummaryTables({
        [openedIndicator]: initialGroupedSummaryTables,
      } as GroupedSummaryTables);
    }
  }, [
    activeView.keys,
    experiment.grouped_distributional_summaries,
    focusIndicator,
    hasExperimentGroupedSummaries,
    indicatorsState,
    theme,
  ]);

  const handlePercentilesTableDisplay = (indicator: string) => {
    const indicatorPercentileDisplay = indicatorsPercentileDisplay[indicator];
    trackEvent("Experiments", {
      action: "Percentile Table Display Toggled",
      view: "Experiment Grouped Summaries",
      meta: {
        action: indicatorPercentileDisplay ? "collapsed" : "expanded",
      },
    });

    // toggle display
    setIndicatorsPercentileDisplay((prevState) => {
      return {
        ...prevState,
        [indicator]: !prevState[indicator],
      };
    });

    return;
  };

  const handleDetailsToggle = (indicator: string) => {
    const indicatorState = indicatorsState[indicator];

    trackEvent("Experiments", {
      action: "Grouped Summary Details Toggled",
      view: "Experiment Grouped Summaries",
      meta: {
        action: indicatorState ? "collapsed" : "expanded",
      },
    });

    // toggle boolean for display indicator details
    setIndicatorsState((prevState) => {
      return {
        ...prevState,
        [indicator]: !prevState[indicator],
      };
    });

    setGroupedSummaryTables((prevState) => {
      return {
        ...prevState,
        [indicator]: indicatorState
          ? // is currently open, but is now being closed
            // remove grouped summary data from state
            undefined
          : // is currently closed, but is now being open
            // fill grouped summary data in state
            getGroupedSummaryTables(
              theme,
              filteredSummaries || [],
              indicatorToStats,
              indicator
            ),
      } as GroupedSummaryTables;
    });

    return;
  };

  const renderTooltipForDurationFields = (indicator: string) => {
    if (
      [
        "metadata.duration",
        "result.elapsed",
        "result.duration",
        "run.time",
        "run.duration",
      ].includes(indicator)
    ) {
      return (
        <Tooltip
          ml={1}
          {...(tooltipContentDurationSummaries[indicator].link && {
            extraLinkLabel: "More",
            extraLinkUrl: tooltipContentDurationSummaries[indicator].link,
          })}
        >
          {tooltipContentDurationSummaries[indicator].content}
        </Tooltip>
      );
    }
    return null;
  };

  const renderPercentilesTable = (indicator: string) => {
    return (
      <>
        <Flex mt={4} mb={3} alignItems="center">
          <Text
            as="h3"
            styleName="body-1-bold"
            styles={{ color: theme.color.gray800 }}
          >
            {formatGroupedSummaryTableName(indicator)} Distribution
          </Text>
          <Tooltip ml={1}>
            If an experiment was run with an instance, the percentile values are
            shown both for the instance and its version even though the values
            are the same.
          </Tooltip>
        </Flex>

        <Table2
          hasBorder
          useMinWidth
          headers={groupedSummaryTables[indicator].percentileHeaders}
          data={groupedSummaryTables[indicator].percentiles || []}
          layoutSchema={getTableLayoutSchemaPercentiles(
            groupedSummaryTables[indicator].percentileHeaders.length
          )}
          fileNameCSV={`${snakeCase(
            formatGroupedSummaryTableName(indicator)
          )}_${params.id}`}
          showCSVLink
        />
      </>
    );
  };

  return (
    <Box
      mt={6}
      pt={7}
      ml={[0, -6]}
      mr={[0, -7]}
      pr={[0, 7]}
      pl={[0, 6]}
      pb={hasExperimentGroupedSummaries ? 0 : 8}
      hasBorderTop
    >
      {hasExperimentGroupedSummaries && (
        <Flex justifyContent="space-between" alignItems="center">
          <Text
            as="h2"
            styleName="header-1"
            styles={{ color: theme.color.gray800 }}
          >
            {isBatch ? "Results" : "Summaries"}
          </Text>
          {!isBatch &&
            renderResultsNote({
              hasResults: !!hasExperimentGroupedSummaries,
              startCriteria: startCriteria,
              status: experiment.status,
              theme,
            })}
          {isBatch && !!viewOptions.length && (
            <Flex width={rem(420)} alignItems="center" justifyContent="end">
              <Text
                width={rem(240)}
                styleName="label"
                styles={{
                  texAlign: "right",
                }}
              >
                Group By
              </Text>
              <Select
                testId="select-experiment-grouped-summary-view"
                ml={1}
                value={summaryView}
                options={viewOptions}
                onChange={(selected: SummaryViewOption) =>
                  handleSummaryViewChange(selected)
                }
              />
            </Flex>
          )}
        </Flex>
      )}

      {orderedIndicators.map((indicator, index) => {
        const focusIndicator =
          focusMetricField && focusMetricField.replace(/-/g, ".");
        const htmlId = indicator.replace(/\./g, "-");

        return (
          <Details
            usePointerEventsNone
            key={indicator}
            htmlId={htmlId}
            testId={htmlId}
            mt={index === 0 ? 6 : 0}
            hasBorderTop
            size="large"
            controlPosition="left"
            isOpen={getIsIndicatorDetailsOpen({
              focusIndicator,
              indicator,
              index,
            })}
            isFocusedFade={focusIndicator && focusIndicator === indicator}
            onClick={() => handleDetailsToggle(indicator)}
            summary={
              <Flex alignItems="center">
                <Text
                  as="h3"
                  styleName="header-2"
                  styles={{ color: theme.color.gray800 }}
                >
                  {formatGroupedSummaryTableName(indicator)}
                </Text>
                {renderTooltipForDurationFields(indicator)}
              </Flex>
            }
          >
            {indicatorsState[indicator] && groupedSummaryTables[indicator] && (
              <Flex pl={7} pb={6} flexDirection="column">
                {groupedSummaryTables[indicator].countWarning && (
                  <Notification
                    mt={1}
                    mb={9}
                    type="notice"
                    message={`Some runs had missing values for ${formatGroupedSummaryTableName(
                      indicator
                    ).toLowerCase()}. For example because a run failed, or a specific version did not include ${formatGroupedSummaryTableName(
                      indicator
                    ).toLowerCase()} in the statistics return. Be careful when interpreting the results.`}
                  />
                )}

                <Table2
                  canSort
                  isWide
                  headers={groupedSummaryTables[indicator].headers}
                  data={groupedSummaryTables[indicator].data || []}
                  fileNameCSV={`${snakeCase(
                    formatGroupedSummaryTableName(indicator)
                  )}_${params.id}`}
                  showCSVLink
                />

                {!isChartDisabled && groupedSummaryTables[indicator].charts && (
                  <Flex mt={6} relative width="100%" flexDirection="column">
                    <Text
                      as="h3"
                      mb={3}
                      styleName="body-1-bold"
                      styles={{ color: theme.color.gray800 }}
                    >
                      Box Plot of {formatGroupedSummaryTableName(indicator)} by
                      Instance &amp; Version
                    </Text>

                    <ChartBoxPlot
                      yAxisLabel="Number"
                      data={groupedSummaryTables[indicator].charts}
                      indicator={indicator}
                    />
                  </Flex>
                )}

                {groupedSummaryTables[indicator].percentiles && (
                  <>
                    <Flex mt={6}>
                      <Button2
                        testId={`button-control-percentiles-${htmlId}`}
                        type="outline-quiet"
                        label={
                          indicatorsPercentileDisplay[indicator]
                            ? "Hide Percentiles"
                            : "Show Percentiles"
                        }
                        onClick={() => handlePercentilesTableDisplay(indicator)}
                      />
                    </Flex>
                    {indicatorsPercentileDisplay[indicator] &&
                      renderPercentilesTable(indicator)}
                  </>
                )}
              </Flex>
            )}
          </Details>
        );
      })}
    </Box>
  );
};

export default ExperimentGroupedSummaries;
