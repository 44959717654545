import { css } from "@emotion/react";

import { rem } from "../../../utils/tools";
import {
  GetInputPaddingHorizontalValueParams,
  GetInputPaddingParams,
  InputSize,
} from "../Input.types";

const getIconPaddingSpace = (size?: InputSize) => {
  switch (size) {
    case "large":
      return rem(36);
    case "small":
      return rem(32);
    default:
      return rem(34);
  }
};

const getInputPaddingHorizontalValue = ({
  side,
  size,
  theme,
}: GetInputPaddingHorizontalValueParams) => {
  switch (size) {
    case "large":
      return side === "right" ? theme.spacing.s2 : theme.spacing.s3;
    case "small":
      return theme.spacing.s2;
    default:
      return side === "right" ? theme.spacing.s2 : theme.spacing.s3;
  }
};

export const getInputPaddingLeftValue = ({
  hasIcon,
  size,
  theme,
}: GetInputPaddingParams) => {
  return hasIcon
    ? getIconPaddingSpace(size)
    : getInputPaddingHorizontalValue({ size, theme, side: "left" });
};

export const getInputPaddingLeftStyle = ({
  hasIcon,
  size,
  theme,
}: GetInputPaddingParams) => {
  const paddingLeftValue = getInputPaddingLeftValue({ hasIcon, size, theme });

  return css`
    padding-left: ${paddingLeftValue};
  `;
};

export const getInputPaddingRightValue = ({
  hasIcon,
  size,
  theme,
}: GetInputPaddingParams) => {
  return hasIcon
    ? getIconPaddingSpace(size)
    : getInputPaddingHorizontalValue({ size, theme, side: "right" });
};

export const getInputPaddingRightStyle = ({
  hasIcon,
  size,
  theme,
}: GetInputPaddingParams) => {
  const paddingRightValue = getInputPaddingRightValue({ hasIcon, size, theme });

  return css`
    padding-right: ${paddingRightValue};
  `;
};
