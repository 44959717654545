import React, { useEffect } from "react";

import Box from "../../../../components/Box";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import Swag from "../../../../components/Swag";
import Text from "../../../../components/Text";
import marketplaceAppsApiSpec from "../../../../spec/open-api-spec-marketplace-apps.json";
import { rem } from "../../../../utils/tools";
import { StyledMarketplaceAppSwagBox } from "../../MarketplaceApp.styled";

const pageTitle = "API Reference";

const MarketplaceAPIReference = () => {
  const [, setMetaTitle] = useMetaTitle();

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  const modifiedAppsApiSpec = JSON.parse(
    JSON.stringify(marketplaceAppsApiSpec)
      .replace(/{application_id}/gm, "<YOUR SUBSCRIPTION APP ID>")
      .replace(/application_id/gm, "<YOUR SUBSCRIPTION APP ID>")
  );

  return (
    <>
      <Header configPageTitle={{ label: pageTitle }} />

      <Box maxWidth={rem(720)} mt={[5, 5, 6]}>
        <Text styleName="body-1" hasLink>
          The REST API endpoints shown become available when you create a new
          subscription app from to a Marketplace app (clone the app). Your
          subscription app&#8217;s ID will replace the placeholder below.
        </Text>
      </Box>

      <StyledMarketplaceAppSwagBox mt={6}>
        <Swag apiSpec={modifiedAppsApiSpec} noTracking={true} />
      </StyledMarketplaceAppSwagBox>
    </>
  );
};

export default MarketplaceAPIReference;
