import React from "react";
import { useTheme } from "@emotion/react";

import Box from "../../components/Box";
import Button2 from "../../components/Button2";
import Flex from "../../components/Flex";
import { rem } from "../../utils/tools";
import { BreakText, TruncateText } from "../PreviewRow2/PreviewRow2.styled";
import Text from "../Text";

import { StyledActionRowFlex } from "./ActionRow.styled";
import { ActionRowProps } from "./ActionRow.types";

const ActionRow = ({
  confirmSecondaryAction,
  description,
  hasNoBorder,
  isDisabled,
  primaryActionLabel,
  primaryActionOnClick,
  secondaryActionLabel,
  secondaryActionOnClick,
  title,
}: ActionRowProps) => {
  const theme = useTheme();
  const hasPrimaryAction = !!primaryActionLabel && !!primaryActionOnClick;
  const hasSecondaryAction = !!secondaryActionLabel && !!secondaryActionOnClick;

  return (
    <StyledActionRowFlex
      {...{ isDisabled }}
      width="100%"
      pt={3}
      pb={4}
      alignItems={["flex-start", "center"]}
      flexDirection={["column", "row"]}
      hasBorderTop={hasNoBorder ? undefined : true}
    >
      <Flex minHeight={rem(40)} alignItems="center">
        {hasPrimaryAction && (
          <Button2
            {...{ isDisabled }}
            label={primaryActionLabel}
            onClick={primaryActionOnClick}
            data-testid={"action-row-button-primary"}
          />
        )}

        <Box width="100%" ml={hasPrimaryAction ? 4 : 0}>
          {title && (
            <Text
              as="h3"
              styleName="body-1-bold"
              styles={{ color: theme.color.gray800 }}
            >
              <BreakText>{title}</BreakText>
            </Text>
          )}
          {description && (
            <Box maxWidth={[rem(180), rem(360), rem(560)]}>
              <Text styleName="body-3" styles={{ color: theme.color.gray600 }}>
                <TruncateText>{description}</TruncateText>
              </Text>
            </Box>
          )}
        </Box>
      </Flex>

      <Flex ml={[0, "auto"]} alignItems="center">
        {confirmSecondaryAction}

        {hasSecondaryAction && !confirmSecondaryAction && (
          <Button2
            {...{ isDisabled }}
            type="outline"
            label={secondaryActionLabel}
            onClick={secondaryActionOnClick}
            data-testid={"action-row-button-secondary"}
          />
        )}
      </Flex>
    </StyledActionRowFlex>
  );
};

export default ActionRow;
