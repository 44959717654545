import { isObject } from "lodash";

import { RunDetails } from "../../../../../api/core/controlPlane.types";
import { AppRunGenre } from "../../../App.types";
import {
  isValidCustomerHSDOutput,
  isValidNextrouteOutput,
  isValidRouterOutput,
  isValidRoutingLegacyOutput,
} from "../../../utils/schemaCheckRouting";
import { isValidAssignedShifts } from "../../../utils/schemaCheckShiftScheduling";

const getOutputSchema = (solution: { [key: string]: any }): AppRunGenre => {
  if (!isObject(solution)) return "custom";

  // store-based: router or custom
  if (solution?.store) {
    if (isValidCustomerHSDOutput(solution.store)) {
      return "customer-hsd";
    }

    if (!isValidRouterOutput(solution.store)) {
      return "custom";
    }
    return "router";
  }

  // routing legacy
  if (solution?.unassigned && solution?.vehicles) {
    if (!isValidRoutingLegacyOutput(solution)) {
      return "custom";
    }
    return "routing-legacy";
  }

  // nextroute or custom that uses vehicles/unplanned
  if (solution?.unplanned && solution?.vehicles) {
    if (!isValidNextrouteOutput(solution)) {
      return "custom";
    }
    return "nextroute";
  }

  // shift-scheduling
  if (
    solution.hasOwnProperty("assigned_shifts") &&
    solution.hasOwnProperty("number_assigned_workers")
  ) {
    if (typeof solution.number_assigned_workers !== "number") {
      return "custom";
    }
    if (!isValidAssignedShifts(solution?.assigned_shifts)) {
      return "custom";
    }
    return "shift-scheduling";
  }

  return "custom";
};

export const getRunGenre = (run: RunDetails): AppRunGenre => {
  if (!run) return;

  const solutions = run?.output?.solutions;

  // Routing Legacy app will not have a solutions property
  // all other known Nextmv schemas have top-level solutions
  // property with array value

  if (!solutions && !run?.output?.state) return "custom";
  if (solutions && !Array.isArray(solutions)) return "custom";
  if (solutions && !solutions.length) return "custom";

  let solution = undefined;

  if (solutions) {
    solution = solutions[solutions.length - 1];
  } else {
    solution = run.output.state;
  }

  return getOutputSchema(solution);
};
