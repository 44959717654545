import React from "react";
import { useTheme } from "@emotion/react";

import { useUser } from "../../../AuthProvider";
import Bin from "../../../components/Bin";
import Box from "../../../components/Box";
import Button2 from "../../../components/Button2";
import Flex from "../../../components/Flex";
import Text from "../../../components/Text";
import { getAccUrl } from "../../../utils/navigation";
import { rem } from "../../../utils/tools";

const CustomAppBanner = () => {
  const [{ id: accountId }] = useUser();
  const theme = useTheme();

  return (
    <Bin
      mt={4}
      styles={{
        backgroundImage: `linear-gradient(.25turn, ${theme.color.white}, ${theme.color.gray100})`,
        boxShadow: `inset 0 0 0 ${theme.border.widthMediumShadowOnly} rgba(0,0,10,0.1)`,
        borderRadius: theme.border.radiusLarge,
      }}
    >
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        pr={2}
      >
        <Box>
          <Text
            styleName="body-1"
            styles={{
              color: "transparent",
              backgroundClip: "text",
              backgroundImage: `linear-gradient(.25turn, ${theme.color.purple600}, ${theme.color.orange700})`,
            }}
          >
            Have custom code? Run your model with a custom app &rarr;
          </Text>
          <Text
            mt={rem(2)}
            styleName="meta-1"
            styles={{ color: theme.color.gray600 }}
          >
            (Python, Java, AMPL, Gurobi, HiGHS, Pyomo, OR-Tools, etc.)
          </Text>
        </Box>
        <Button2
          label="Create custom app"
          to={getAccUrl(accountId, "/apps/new")}
        />
      </Flex>
    </Bin>
  );
};

export default CustomAppBanner;
