import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const ORTOOLS_SIMPLE_ROUTING_PARTNER_ID = "nextmv";
export const ORTOOLS_SIMPLE_ROUTING_APP_ID = "routing.ortools";
export const ORTOOLS_SIMPLE_ROUTING_SUBSCRIPTION_ID = `${ORTOOLS_SIMPLE_ROUTING_PARTNER_ID}-${ORTOOLS_SIMPLE_ROUTING_APP_ID}`;
export const ORTOOLS_SIMPLE_ROUTING_ICON_URL =
  "/images/logos/or-tools-icon.svg";
export const ORTOOLS_SIMPLE_ROUTING_NAME = "OR-Tools Routing";
export const ORTOOLS_SIMPLE_ROUTING_TEMPLATE_NAME = "routing-ortools";
export const ORTOOLS_SIMPLE_ROUTING_SHORT_DESC =
  "Solve an unconstrained vehicle routing problem.";
export const ORTOOLS_SIMPLE_ROUTING_SUMMARY =
  "Solve an unconstrained vehicle routing problem with the low-code OR-Tools routing app. Define a distance matrix, number of vehicles, and the depot index and then run the app to generate vehicle routes.";
export const ORTOOLS_SIMPLE_ROUTING_DOCS_LINK =
  "https://www.nextmv.io/docs/community-apps/routing-without-constraints";
export const ORTOOLS_SIMPLE_ROUTING_CODE_REPO_LINK =
  "https://github.com/nextmv-io/community-apps/tree/develop/python-ortools-routing";
export const ORTOOLS_SIMPLE_ROUTING_MODEL_TYPE = ["Routing"];
export const ORTOOLS_SIMPLE_ROUTING_LANGUAGE = ["Python"];
export const ORTOOLS_SIMPLE_ROUTING_PACKAGES = ["OR-Tools"];
export const ORTOOLS_SIMPLE_ROUTING_SOLVER = ["SCIP"];

export const ORTOOLS_SIMPLE_ROUTING_CONSTRAINTS: MarketplaceAppConstraint[] = [
  {
    id: "distance_matrix",
    name: "distance_matrix",
    property: "distance_matrix",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/routing-without-constraints#input",
    summary: "Full matrix of distances from each stop to each stop.",
    type: "array of array of integer",
  },
  {
    id: "num_vehicles",
    name: "num_vehicles",
    property: "num_vehicles",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/routing-without-constraints#input",
    summary: "Number of available vehicles.",
    type: "integer",
  },
  {
    id: "depot",
    name: "depot",
    property: "depot",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/routing-without-constraints#input",
    summary:
      "The index of the depot in the stops used to generate the distance_matrix.",
    type: "integer",
  },
];
