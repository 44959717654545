import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { useUser } from "../../../../AuthProvider";
import { AvatarWebhook } from "../../../../avatars";
import Box from "../../../../components/Box";
import Flex from "../../../../components/Flex";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import Tag from "../../../../components/Tag";
import Text from "../../../../components/Text";
import { ENDPOINT_WEBHOOKS_CONVERSATIONS } from "../../../../config/endpoints";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import useManageWebhook from "../../../../hooks/useManageWebhook";
import { formatMilliseconds } from "../../../../utils/format";
import { getAccUrl } from "../../../../utils/navigation";
import { rem } from "../../../../utils/tools";
import { isNumber } from "../../../../utils/typeCheck";
import ConversationEditorSection from "../../components/ConversationEditorSection";
import { conversationTooltips } from "../../data/microcopy";
import {
  getHTTPStatusTextColor,
  getStatusBackgroundColor,
  getStatusTextColor,
} from "../../utils/conversationsTable";

const Conversation = () => {
  const [{ id: accountId }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const params = useParams() as {
    id: string;
    webhookId: string;
    field?: string;
  };

  const webhookId = params.webhookId;

  const {
    loadWebhookEntity: loadConversation,
    webhookEntity: conversation,
    webhookEntityLoadError: conversationError,
  } = useManageWebhook(ENDPOINT_WEBHOOKS_CONVERSATIONS);

  // page display
  useEffect(() => {
    if (params.id) {
      setMetaTitle(params.id);
    }
  }, [params.id, setMetaTitle]);

  useEffect(() => {
    if (params.id && params.webhookId) {
      loadConversation(params.webhookId, params.id);
    }
  }, [loadConversation, params.id, params.webhookId]);

  if (conversationError) {
    return <StandardError errorMessage={conversationError} />;
  }
  if (!conversation) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  return (
    <>
      <Header
        configPageTitle={{
          ancestorLabel: "Webhooks",
          ancestorIcon: <AvatarWebhook size={24} />,
          ancestorUrl: getAccUrl(accountId, `/apps/webhooks`),
          parentLabel: webhookId,
          parentUrl: getAccUrl(accountId, `/apps/webhook/${webhookId}`),
          label: params?.id,
        }}
      />

      <RowDetail
        property="ID"
        secondaryLabel="Read-only"
        testId="conversation-row-id"
        tooltipCopy={conversationTooltips.id.content}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={conversation.id}
              contentToCopy={conversation.id}
            />
          </Flex>
        }
      />

      {conversation.status && (
        <RowDetail
          property="Status"
          testId="conversation-row-status"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text
                styleName="body-2"
                styles={{
                  color: getStatusTextColor({
                    status: conversation.status,
                    theme,
                  }),
                }}
              >
                {conversation.status}
              </Text>
            </Box>
          }
        />
      )}
      <RowDetail
        property="HTTP Response"
        testId="conversation-row-response"
        render={
          conversation.http_status ? (
            <Tag
              label={String(conversation.http_status)}
              backgroundColor={getStatusBackgroundColor({
                status: conversation.status,
                theme,
              })}
              textColor={getHTTPStatusTextColor({
                status: conversation.status,
                theme,
              })}
            ></Tag>
          ) : (
            <Text
              as="span"
              styleName="body-3"
              styles={{ color: theme.color.gray500 }}
            >
              None
            </Text>
          )
        }
      />
      {conversation.response_duration && (
        <RowDetail
          property="Response Duration"
          testId="conversation-row-response-duration"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              {conversation?.response_duration ? (
                <Text styleName="body-2">
                  {isNumber(conversation?.response_duration)
                    ? formatMilliseconds(conversation?.response_duration, true)
                    : ""}
                </Text>
              ) : (
                <Text
                  as="span"
                  styleName="body-3"
                  styles={{ color: theme.color.gray500 }}
                >
                  None
                </Text>
              )}
            </Box>
          }
        />
      )}
      {conversation.created_at && (
        <RowDetail
          property="Created At"
          testId="conversation-row-created-at"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text
                as="time"
                styleName="body-2"
                dateTime={conversation.created_at}
                title={conversation.created_at}
              >
                {DateTime.fromISO(conversation.created_at).toFormat(
                  "yyyy-MM-dd · h:mm:ss a ZZZZ"
                )}
              </Text>
            </Box>
          }
        />
      )}
      {conversation.error && (
        <RowDetail
          property="Error"
          testId="conversation-row-"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text
                styleName="body-2"
                styles={{
                  color: theme.color.red600,
                }}
              >
                {conversation.error}
              </Text>
            </Box>
          }
        />
      )}

      <ConversationEditorSection title="Body" conversation={conversation} />
    </>
  );
};

export default Conversation;
