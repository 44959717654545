import React from "react";

import { RunMetadataResponse } from "../../../../../api/core/controlPlane.types";
import Flex from "../../../../../components/Flex";
import { HEADER_HEIGHT } from "../../../../../utils/constants";
import { rem } from "../../../../../utils/tools";

import DownloadRunInput from "./DownloadRunInput";
import DownloadRunOutput from "./DownloadRunOutput";

type RunDetailsLargeFileProps = {
  appId: string;
  runMetadata: RunMetadataResponse;
  type: "input" | "output";
};

const RunDetailsDownloadView = ({
  appId,
  runMetadata,
  type,
}: RunDetailsLargeFileProps) => {
  return (
    <Flex flexDirection="column" height={`calc(100% - ${HEADER_HEIGHT})`}>
      <Flex
        minHeight={["50vh", 0]}
        maxWidth={rem(860)}
        mb={6}
        flexDirection="column"
        flexGrow={1}
      >
        {type === "input" && <DownloadRunInput {...{ appId, runMetadata }} />}
        {type === "output" && <DownloadRunOutput {...{ appId, runMetadata }} />}
      </Flex>
    </Flex>
  );
};

export default RunDetailsDownloadView;
