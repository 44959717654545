import { rem } from "../utils/tools";

export const widthThin = rem(0.5);
export const width = rem(1);
export const widthMediumShadowOnly = rem(1.5);
export const widthThick = rem(2);
export const widthDouble = rem(3);
export const style = "solid";
export const styleDouble = "double";
export const radius = rem(4);
export const radiusXSmall = rem(2);
export const radiusSmall = rem(3);
export const radiusLarge = rem(5);
