import { useState } from "react";
import { useLocation } from "react-router-dom";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Auth, Hub } from "aws-amplify";

import { trackEvent } from "../analytics";
import { EntityErrorMessage } from "../api/core/controlPlane.types";
import {
  AmplifyAuthError,
  AmplifyAuthErrorContext,
  getErrorMessageByCode,
} from "../services/amplify";
import { getPasswordRules } from "../services/password";
import { useIsNewUser } from "../utils";
import { getSSODomainId } from "../utils/getSSODomainId";
import { areInputsValid, HTML_TAG_REGEX } from "../utils/inputHelpers";
import {
  getFromUrl,
  LocationState,
  navigateToAttemptedPage,
} from "../utils/navigation";

export type AuthFlowInputValue = string;

const useAuthFlow = () => {
  const { state } = useLocation<LocationState>();
  const [, setIsNewUser] = useIsNewUser();

  const [company, setCompany] = useState<AuthFlowInputValue>("");
  const [email, setEmail] = useState<AuthFlowInputValue>("");
  const [isSubscribedNewsletter, setIsSubscribedNewsletter] = useState(false);
  const [password, setPassword] = useState<AuthFlowInputValue>("");
  const [passwordConfirm, setPasswordConfirm] =
    useState<AuthFlowInputValue>("");
  const [nameFirst, setNameFirst] = useState<AuthFlowInputValue>("");
  const [nameLast, setNameLast] = useState<AuthFlowInputValue>("");

  const [hasEmail, setHasEmail] = useState(false);
  const [hasResetPassword, setHasResetPassword] = useState(false);
  const [hasSentPasswordResetEmail, setHasSentPasswordResetEmail] =
    useState(false);
  const [hasSentVerificationEmail, setHasSentVerificationEmail] =
    useState(false);
  const [hasSignedUp, setHasSignedUp] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isPasswordConfirmFocused, setIsPasswordConfirmFocused] =
    useState(false);
  const [isVerificationCodeValid, setIsVerificationCodeValid] = useState<
    boolean | null
  >(null);

  const [error, setError] = useState<EntityErrorMessage>(null);
  const [errorExpiredCode, setErrorExpiredCode] =
    useState<EntityErrorMessage>(null);
  const [errorUserNotVerified, setErrorUserNotVerified] =
    useState<EntityErrorMessage>(null);

  // set error wrapper (internal to this hook only)
  const handleSetError = (
    error: AmplifyAuthError,
    view?: AmplifyAuthErrorContext
  ) => {
    const errorMessage = getErrorMessageByCode(error, view);

    // special UI handling for expired code error
    if (
      error.code === "ExpiredCodeException" ||
      error.code === "CodeMismatchException"
    ) {
      return setErrorExpiredCode(errorMessage);
    }
    if (error.code === "UserNotConfirmedException") {
      return setErrorUserNotVerified(errorMessage);
    }

    if (errorMessage) {
      return setError(errorMessage);
    }
    return setError(error.toString());
  };

  // login

  const logInEmailPassword = async () => {
    setError("");
    setIsProcessing(true);

    trackEvent("AuthFlow", {
      view: "Login Password",
      action: "Log In Button Clicked",
    });

    try {
      await Auth.signIn({
        username: email,
        password: password,
      });

      navigateToAttemptedPage(state);
    } catch (error: any) {
      handleSetError(error, "login");
      setIsProcessing(false);
    }
  };

  const logInGoogle = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    const fromUrl: string = getFromUrl(state);

    // run federatedSignIn as callback after segment call otherwise
    // segment will error due to URL changing before call is completed
    trackEvent(
      "AuthFlow",
      {
        view: "Login Password",
        action: "Log In With Google Button Clicked",
      },
      () => {
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Google,
          customState: fromUrl,
        });
      }
    );
  };

  const ssoLogin = (domain: string) => {
    // run federatedSignIn as callback after segment call otherwise
    // segment will error due to URL changing before call is completed
    trackEvent(
      "AuthFlow",
      {
        view: "Login Identify",
        action: "SSO Login",
      },
      () => {
        Auth.federatedSignIn({
          customProvider: domain,
        });
      }
    );
  };

  const checkVerificationCode = async (
    username: string,
    verificationCode: string
  ) => {
    setIsProcessing(true);

    try {
      await Auth.confirmSignUp(username, verificationCode);

      setIsVerificationCodeValid(true);

      Hub.dispatch("customAuthChannel", {
        event: "confirmSignUp",
        data: {},
      });

      // @NO-CHANGE used by backend and Hubspot
      trackEvent("Verify Email Button Clicked", {
        email: username,
      });
    } catch (error: any) {
      handleSetError(error, "verify");
      setIsVerificationCodeValid(false);
    } finally {
      setIsProcessing(false);
    }
  };

  const sendVerificationEmail = async (email: string) => {
    setIsProcessing(true);

    try {
      await Auth.resendSignUp(email);
      setHasSentVerificationEmail(true);
    } catch (error: any) {
      handleSetError(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const sendPasswordResetEmail = async (email: string) => {
    setIsProcessing(true);

    try {
      await Auth.forgotPassword(email);

      trackEvent("AuthFlow", {
        view: "Login Forgot Password",
        action: "Reset Password Button Clicked",
      });
    } catch (error) {
      // IMPORTANT! suppress error because it would communicate
      // if there is no account for the given email
      return;
    } finally {
      setHasSentPasswordResetEmail(true);
      setIsProcessing(false);
    }
  };

  const canResetPassword = (): boolean => {
    const areAllPasswordRulesValid = getPasswordRules(password).every(
      (rule) => rule.isValid
    );
    return areAllPasswordRulesValid && password === passwordConfirm;
  };

  const resetPassword = async (
    usernameToken?: string | null,
    verificationCode?: string | null
  ) => {
    setError("");

    if (usernameToken && verificationCode) {
      setIsProcessing(true);
      const decodedUsernameToken = decodeURIComponent(usernameToken);
      try {
        await Auth.forgotPasswordSubmit(
          decodedUsernameToken,
          verificationCode,
          password
        );

        trackEvent("AuthFlow", {
          view: "Login Reset Password",
          action: "Password Reset",
        });

        setHasResetPassword(true);
      } catch (error: any) {
        handleSetError(error, "reset-password");
      } finally {
        setIsProcessing(false);
      }
    } else {
      // force expired error code message
      handleSetError(
        {
          code: "ExpiredCodeException",
          name: "ExpiredCodeException",
          message: "",
        },
        "reset-password"
      );
    }
  };

  // sign up

  const canSignUp = (): boolean => {
    const requiredFieldsFilledOut =
      nameFirst && nameLast && company && email && password;
    const areAllPasswordRulesValid = getPasswordRules(password).every(
      (rule) => rule.isValid
    );
    return Boolean(requiredFieldsFilledOut) && areAllPasswordRulesValid;
  };

  const signUpEmailPassword = async () => {
    const ssoDomain = await getSSODomainId(email);
    if (ssoDomain) {
      ssoLogin(ssoDomain);
      return;
    }
    // regex for any HTML tag, very basic by design to
    // avoid blocking valid user information
    if (!areInputsValid([nameFirst, nameLast, company], HTML_TAG_REGEX)) {
      setError(
        "You have entered invalid information for your name or company name. Please update one or more of these fields and try again."
      );
    } else {
      setIsProcessing(true);
      setError("");

      trackEvent("AuthFlow", {
        view: "Sign Up",
        action: "Sign Up Button Clicked",
      });

      try {
        await Auth.signUp({
          username: email,
          password,
          attributes: {
            name: `${nameFirst} ${nameLast}`,
            "custom:company": company,
            "custom:first_name": nameFirst,
            "custom:last_name": nameLast,
          },
        });

        trackEvent("Sign Up Form Submission", {
          data: {
            firstName: nameFirst,
            lastName: nameLast,
            company,
            email,
            hosted_sign_up: false,
          },
        });

        setIsNewUser(true);
        setHasSignedUp(true);
      } catch (error: any) {
        handleSetError(error);
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const signUpGoogle = (): void => {
    // run federatedSignIn as callback after segment call otherwise
    // segment will error due to URL changing before call is completed
    trackEvent(
      "AuthFlow",
      {
        view: "Sign Up",
        action: "Sign Up With Google Button Clicked",
      },
      () => {
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Google,
        });
      }
    );

    setIsNewUser(true);
  };

  return {
    canResetPassword,
    canSignUp,
    checkVerificationCode,
    company,
    email,
    error,
    errorExpiredCode,
    errorUserNotVerified,
    password,
    passwordConfirm,
    hasEmail,
    hasResetPassword,
    hasSentPasswordResetEmail,
    hasSentVerificationEmail,
    hasSignedUp,
    isPasswordFocused,
    isPasswordConfirmFocused,
    isProcessing,
    isSubscribedNewsletter,
    isVerificationCodeValid,
    logInGoogle,
    logInEmailPassword,
    nameFirst,
    nameLast,
    resetPassword,
    sendVerificationEmail,
    sendPasswordResetEmail,
    setCompany,
    setEmail,
    setErrorExpiredCode,
    setErrorUserNotVerified,
    setHasEmail,
    setIsPasswordFocused,
    setIsPasswordConfirmFocused,
    setIsProcessing,
    setIsSubscribedNewsletter,
    setNameFirst,
    setNameLast,
    setPassword,
    setPasswordConfirm,
    signUpEmailPassword,
    signUpGoogle,
    ssoLogin,
    state,
  };
};

export default useAuthFlow;
