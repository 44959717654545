import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { AvatarAppInput } from "../../../../avatars";
import Box from "../../../../components/Box";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { appTooltipCopy } from "../../../Apps/data/microcopy";
import { AppPageProps } from "../../App.types";
import useReturnPaths from "../../hooks/useReturnPaths";

const EditAppInput = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const params = useParams() as { id: string };
  const theme = useTheme();
  const { returnPath, returnPathList } = useReturnPaths();

  const [isProcessing, setIsProcessing] = useState(false);

  const { loadAppInputs } = useAppCollection();

  const {
    editEntity: editAppInput,
    entity: appInput,
    entityLoadError: appInputLoadError,
    entityEditError: appInputEditError,
    loadEntity: loadAppInput,
    isEntityEdited: isAppInputSaved,
  } = useManageEntity("inputs");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "inputs", true);

  // load app input data
  useEffect(() => {
    if (!appInput && !appInputLoadError) {
      loadAppInput(app.id, params.id);
    }
  }, [app.id, appInput, appInputLoadError, loadAppInput, params.id]);

  // page display
  useEffect(() => {
    if (appInput) {
      setMetaTitle(`Edit ${appInput.name}`);
    }
  }, [appInput, setMetaTitle]);

  // pre-fill pending version with original values
  useEffect(() => {
    if (appInput && !pendingStandardInputs.id) {
      updateStandardInputs([
        { key: "name", value: appInput.name },
        { key: "id", value: appInput.id },
        { key: "description", value: appInput.description },
      ]);
    }
  }, [appInput, pendingStandardInputs.id, updateStandardInputs]);

  useEffect(() => {
    if (appInputEditError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, appInputEditError]);

  const handleAppInputSave = async (e: {
    preventDefault: any;
    stopPropagation: any;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);

    const payload = { ...pendingStandardInputs };

    await editAppInput(app.id, appInput!.id, payload);
  };

  const handleCancel = () => {
    trackEvent("AppInputs", {
      view: "Edit App Input",
      action: "Edit App Input Canceled",
    });
    return;
  };

  const handleAppInputPostDelete = () => {
    trackEvent("AppInputs", {
      view: "Edit App Input",
      action: "App Input Deleted",
    });

    loadAppInputs({ applicationId: app.id });
  };

  if (appInputLoadError) {
    return <StandardError errorMessage={appInputLoadError} />;
  }
  if (!pendingStandardInputs.id || !appInput) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }
  if (isAppInputSaved) {
    trackEvent("AppInputs", {
      view: "Edit App Input",
      action: "Edit App Input Saved",
    });

    loadAppInput(app.id, params.id);
    loadAppInputs({ applicationId: app.id });

    return <Redirect to={returnPath} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name || !!standardInputsErrors.name;

  return (
    <>
      <Header
        configPageTitle={{
          label: `Edit ${appInput.name}`,
          ancestorIcon: <AvatarAppInput />,
          ancestorLabel: "Inputs",
          ancestorUrl: returnPathList,
        }}
      />

      <form>
        <RowDetail
          hasNoBorderTop
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Input name",
                  testId: "edit-app-input-name-input",
                  type: "name",
                  trackEventCategory: "AppInputs",
                  trackEventProperties: {
                    view: "Edit App Input",
                    action: "Field Changed",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          secondaryLabel="Read-only"
          tooltipCopy={appTooltipCopy.appInputIdEdit}
          render={
            <Box flexGrow={1} maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                htmlType="text"
                isDisabled
                placeholder="Input ID"
                value={appInput.id}
                readOnly
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Input description",
                  testId: "edit-app-input-description-input",
                  type: "description",
                  trackEventCategory: "AppInputs",
                  trackEventProperties: {
                    view: "Edit App Input",
                    action: "Field Changed",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <Footer
          app={app}
          endpoint="inputs"
          entityId={appInput.id}
          error={appInputEditError}
          handleCancel={handleCancel}
          handleMainAction={handleAppInputSave}
          handlePostDelete={handleAppInputPostDelete}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="edit"
        />
      </form>
    </>
  );
};

export default EditAppInput;
