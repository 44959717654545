import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { AvatarWebhook } from "../../../../avatars";
import Box from "../../../../components/Box";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import { ENDPOINT_WEBHOOKS } from "../../../../config/endpoints";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useWebhooks } from "../../../../contexts/webhooks/Webhooks.context";
import useManageWebhook from "../../../../hooks/useManageWebhook";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { handleInputChange } from "../../../../utils/inputHelpers";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { webhookTooltips } from "../../data/microcopy";

const EditWebhook = () => {
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { returnPath, returnPathList } = useReturnPaths();
  const params = useParams() as {
    id: string;
    field?: string;
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const [endpointUrl, setEndpointUrl] = useState("");

  const { loadWebhooks } = useWebhooks();

  const {
    editWebhookEntity: editWebhook,
    webhookEntity: webhook,
    webhookEntityLoadError: webhookLoadError,
    webhookEntityEditError: webhookEditError,
    loadWebhookEntity: loadWebhook,
    isWebhookEntityEdited: isWebhookSaved,
  } = useManageWebhook("webhooks");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    updateStandardInputs,
  } = useStandardInputs(undefined, "webhooks", true);

  // load webhook
  useEffect(() => {
    if (!webhook && !webhookLoadError) {
      loadWebhook(params.id);
    }
  }, [webhook, webhookLoadError, loadWebhook, params.id]);

  // page display
  useEffect(() => {
    if (webhook) {
      setMetaTitle(`Edit ${webhook.id}`);
    }
  }, [webhook, setMetaTitle]);

  // pre-fill pending  webhook with original values
  useEffect(() => {
    if (webhook && !pendingStandardInputs.id) {
      updateStandardInputs([
        { key: "id", value: webhook.id },
        { key: "description", value: webhook.description },
      ]);
      setEndpointUrl(webhook.endpoint_url);
    }
  }, [webhook, pendingStandardInputs.id, updateStandardInputs]);

  // disable loading state if edit webhook error
  useEffect(() => {
    if (webhookEditError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, webhookEditError]);

  const handleWebhookSave = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);

    const payload = {
      ...webhook,
      ...pendingStandardInputs,
      endpoint_url: endpointUrl,
    };

    await editWebhook(webhook!.id, "webhooks", payload);
  };

  const handleCancel = () => {
    trackEvent("Webhooks", {
      view: "Edit Webhook",
      action: "Edit Webhook Canceled",
    });
    return;
  };

  const handleWebhookPostDelete = () => {
    trackEvent("Webhooks", {
      view: "Edit Webhook",
      action: "Webhook Deleted",
    });

    loadWebhooks({
      shouldPaginate: true,
    });
  };

  if (webhookLoadError) {
    return <StandardError errorMessage={webhookLoadError} />;
  }
  if (!pendingStandardInputs.id || !webhook) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }
  if (isWebhookSaved) {
    trackEvent("Webhooks", {
      view: "Edit Webhook",
      action: "Webhook Saved",
    });

    loadWebhook(params.id);
    loadWebhooks({
      shouldPaginate: true,
    });

    return <Redirect to={returnPath} />;
  }

  const isActionButtonDisabled = !endpointUrl;

  return (
    <>
      <Header
        configPageTitle={{
          label: `Edit ${webhook.id}`,
          ancestorIcon: <AvatarWebhook size={24} />,
          ancestorLabel: "Webhooks",
          ancestorUrl: returnPathList,
        }}
      />

      <form>
        <RowDetail
          property="ID"
          testId="webhook-row-id"
          secondaryLabel="Read-only"
          tooltipCopy={webhookTooltips.idEdit.content}
          render={
            <Box flexGrow={1} maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                htmlType="text"
                isDisabled
                placeholder="Webhook ID"
                value={webhook.id}
                readOnly
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Webhook description",
                  testId: "edit-webhook-description-input",
                  type: "description",
                  trackEventCategory: "Webhooks",
                  trackEventProperties: {
                    view: "Edit Webhook",
                    action: "Field Changed",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="Endpoint URL"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                placeholder="Webhook Endpoint URL"
                testId="edit-webhook-url-input"
                name="endpointUrl"
                onBlur={() => {
                  trackEvent("Webhooks", {
                    view: "Edit Webhook",
                    action: "Field Changed",
                    meta: {
                      field: "endpointUrl",
                    },
                  });
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e, setEndpointUrl)
                }
                value={endpointUrl}
              />
            </Box>
          }
        />

        <Footer
          webhookEndpoint={ENDPOINT_WEBHOOKS}
          entityId={webhook.id}
          error={webhookEditError}
          isWebhookEntity
          handleCancel={handleCancel}
          handleMainAction={handleWebhookSave}
          handlePostDelete={handleWebhookPostDelete}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="edit"
        />
      </form>
    </>
  );
};

export default EditWebhook;
