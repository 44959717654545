import React from "react";
import { useTheme } from "@emotion/react";

import Box from "../../../components/Box";
import Text from "../../../components/Text";

type ConversationDetailsSectionProps = {
  children: React.ReactNode;
  title: string;
};

const ConversationDetailsSection = ({
  children,
  title,
}: ConversationDetailsSectionProps) => {
  const theme = useTheme();

  return (
    <Box
      mt={[6, 7]}
      pt={[5, 7]}
      ml={[0, -6]}
      mr={[0, -7]}
      pr={[0, 7]}
      pl={[0, 6]}
      hasBorderTop
    >
      <Text
        as="h2"
        styleName="header-1"
        styles={{ color: theme.color.gray800 }}
      >
        {title}
      </Text>

      <Box mt={4}>{children}</Box>
    </Box>
  );
};

export default ConversationDetailsSection;
