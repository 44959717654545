import { trackEvent } from "../../../analytics";
import { TrackEvents } from "../../../analytics/functions";
import {
  WebhookResponse,
  WebhooksListData,
} from "../../../api/core/controlPlane.types";
import { AvatarWebhook } from "../../../avatars";
import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import PreviewRow2 from "../../../components/PreviewRow2";
import Tag from "../../../components/Tag";
import Text from "../../../components/Text";
import { getAccUrl } from "../../../utils/navigation";
import { rem } from "../../../utils/tools";

type Webhook = WebhookResponse;

type TrackEventCategories = "Webhooks";

type TrackEventProperties = TrackEvents["Webhooks"];

type RenderWebhooksListParams = {
  canUserCreateAndEdit: boolean;
  theme: any;
  webhooks: WebhooksListData;
  accountId?: string | null;
  isMini?: boolean;
  limit?: number;
  urlEdit?: string;
  urlEditOnClickTrackEventCategory?: TrackEventCategories;
  urlEditOnClickTrackEventProperties?: TrackEventProperties;
  urlOnClickTrackEventCategory?: TrackEventCategories;
  urlOnClickTrackEventProperties?: TrackEventProperties;
};

type RenderPreviewRowMetaParams = {
  webhook: Webhook;
};

const renderMetaExtra1 = ({
  webhook,
}: RenderPreviewRowMetaParams): React.ReactNode | undefined => {
  let eventTypes: string[] = webhook.event_types || [];

  return (
    !!eventTypes.length && (
      <Flex alignItems="flex-start">
        <Text mt={rem(2)} styleName="body-3">
          Events
        </Text>
        <Flex alignItems="flex-start" flexWrap="wrap">
          {eventTypes.map((eventType, index) => (
            <Tag
              key={`${eventType}-${index}`}
              mt={rem(1)}
              ml={1}
              label={eventType}
            />
          ))}
        </Flex>
      </Flex>
    )
  );
};

export const renderWebhooksList = ({
  accountId,
  canUserCreateAndEdit,
  webhooks,
  isMini,
  limit,
  urlOnClickTrackEventCategory,
  urlOnClickTrackEventProperties,
}: RenderWebhooksListParams) => {
  if (!webhooks) return;

  if (!webhooks.length) {
    return (
      <Text
        testId={"webhook-not-found-message"}
        mt={isMini ? 3 : 5}
        styleName={isMini ? "body-3" : "body-2"}
      >
        No Webhooks found.
      </Text>
    );
  }

  const returnedWebhooks = limit ? webhooks.slice(0, limit) : webhooks;

  return returnedWebhooks.map((webhook, index) => {
    return (
      <PreviewRow2
        key={`${webhook.id}-${index}`}
        hasNoBorder={index === 0 ? true : false}
        icon={
          <Box mt={rem(2)}>
            <AvatarWebhook size={isMini ? 20 : 24} />
          </Box>
        }
        metaExtra1={renderMetaExtra1({ webhook })}
        name={webhook.id}
        id={webhook.endpoint_url}
        description={webhook?.description}
        lastUpdated={webhook?.updated_at}
        url={getAccUrl(accountId, `/apps/webhook/${webhook.id}`)}
        {...(urlOnClickTrackEventCategory &&
          urlOnClickTrackEventProperties && {
            urlOnClick: () =>
              trackEvent(
                urlOnClickTrackEventCategory,
                urlOnClickTrackEventProperties
              ),
          })}
        {...(!isMini &&
          canUserCreateAndEdit && {
            urlEdit: getAccUrl(
              accountId,
              `/apps/webhook/${webhook.id}/edit?source=list`
            ),
            urlEditOnClick: () =>
              trackEvent("Webhooks", {
                view: "Webhooks",
                action: "Link Clicked",
                meta: {
                  type: "Webhook Edit",
                },
              }),
          })}
      />
    );
  });
};
