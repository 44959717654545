import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const AMPL_PRICE_OPTIMIZATION_PARTNER_ID = "ampl";
export const AMPL_PRICE_OPTIMIZATION_APP_ID = "price.opt.ampl";
export const AMPL_PRICE_OPTIMIZATION_SUBSCRIPTION_ID = `${AMPL_PRICE_OPTIMIZATION_PARTNER_ID}-${AMPL_PRICE_OPTIMIZATION_APP_ID}`;
export const AMPL_PRICE_OPTIMIZATION_ICON_URL = "/images/logos/ampl-icon.svg";
export const AMPL_PRICE_OPTIMIZATION_NAME = "AMPL Price Optimization";
export const AMPL_PRICE_OPTIMIZATION_TEMPLATE_NAME = "price-opt-ampl";
export const AMPL_PRICE_OPTIMIZATION_SHORT_DESC =
  "Solves a price optimization Mixed Integer Programming problem using AMPL.";
export const AMPL_PRICE_OPTIMIZATION_SUMMARY =
  "Solve a price optimization problem with an AMPL app. Define regions, available items to allocate, price and allocation ranges, and regression coefficients predicting demand by price and then run the app to decide how many items to allocate to each region and how to price them.";
export const AMPL_PRICE_OPTIMIZATION_DOCS_LINK = "";
export const AMPL_PRICE_OPTIMIZATION_CODE_REPO_LINK =
  "https://github.com/nextmv-io/community-apps/tree/develop/python-ampl-priceoptimization";
export const AMPL_PRICE_OPTIMIZATION_MODEL_TYPE = ["Pricing"];
export const AMPL_PRICE_OPTIMIZATION_LANGUAGE = ["Python"];
export const AMPL_PRICE_OPTIMIZATION_PACKAGES = ["AMPL"];
export const AMPL_PRICE_OPTIMIZATION_SOLVER = ["HiGHS"];

export const AMPL_PRICE_OPTIMIZATION_CONSTRAINTS: MarketplaceAppConstraint[] = [
  {
    id: "regions",
    name: "regions",
    property: "regions",
    docsUrl: "",
    summary: "Regions to allocate items to.",
    type: "array",
  },
  {
    id: "total_amount_of_supply",
    name: "total_amount_of_supply",
    property: "total_amount_of_supply",
    docsUrl: "",
    summary: "Total amount of supply to allocate.",
    type: "integer",
  },
  {
    id: "cost_per_wasted_product",
    name: "cost_per_wasted_product",
    property: "cost_per_wasted_product",
    docsUrl: "",
    summary: "Cost per wasted product.",
    type: "integer",
  },
  {
    id: "peak",
    name: "peak",
    property: "peak",
    docsUrl: "",
    summary: "Indicates peak demand time for the regression model.",
    type: "integer (0 or 1)",
  },
  {
    id: "transport_costs",
    name: "transport_costs",
    property: "transport_costs",
    docsUrl: "",
    summary: "Cost to transport an item to each region.",
    type: "array",
  },
  {
    id: "year",
    name: "year",
    property: "year",
    docsUrl: "",
    summary: "Year for the regression model.",
    type: "integer",
  },
  {
    id: "minimum_product_price",
    name: "minimum_product_price",
    property: "minimum_product_price",
    docsUrl: "",
    summary: "Minimum price to set the item to.",
    type: "float",
  },
  {
    id: "maximum_product_price",
    name: "maximum_product_price",
    property: "maximum_product_price",
    docsUrl: "",
    summary: "Maximum price to set the item to.",
    type: "float",
  },
  {
    id: "minimum_product_allocations",
    name: "minimum_product_allocations",
    property: "minimum_product_allocations",
    docsUrl: "",
    summary: "Minimum amount to allocate to each region.",
    type: "array",
  },
  {
    id: "maximum_product_allocations",
    name: "maximum_product_allocations",
    property: "maximum_product_allocations",
    docsUrl: "",
    summary: "Maximum amount to allocate to each region.",
    type: "array",
  },
  {
    id: "coefficients.region",
    name: "coefficients.region",
    property: "coefficients.region",
    docsUrl: "",
    summary: "Region coefficients of the regression model predicting demand.",
    type: "array",
  },
  {
    id: "coefficients.price",
    name: "coefficients.price",
    property: "coefficients.price",
    docsUrl: "",
    summary: "Price coefficient of the regression model predicting demand.",
    type: "float",
  },
  {
    id: "coefficients.intercept",
    name: "coefficients.intercept",
    property: "coefficients.intercept",
    docsUrl: "",
    summary: "Intercept of the regression model predicting demand.",
    type: "float",
  },
  {
    id: "coefficients.year_index",
    name: "coefficients.year_index",
    property: "coefficients.year_index",
    docsUrl: "",
    summary:
      "Coefficient of year index in the regression model predicting demand.",
    type: "float",
  },
  {
    id: "coefficients.peak",
    name: "coefficients.peak",
    property: "coefficients.peak",
    docsUrl: "",
    summary: "Coefficient of peak in the regression model predicting demand.",
    type: "float",
  },
];
