import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Box from "../../components/Box";
import Flex from "../../components/Flex";
import { PROFILE_MENU_WIDTH, styleHideVisually } from "../../utils/constants";
import { filterProps } from "../../utils/filterProps";
import { rem } from "../../utils/tools";

import {
  StyledProfileMenuLinkProps,
  StyledProfileMenuProps,
} from "./ProfileMenu.types";

export const StyledScrimTransparent = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: transparent;
  z-index: 9998;
`;

export const StyledProfileMenu = styled("div", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledProfileMenuProps>`
  box-sizing: border-box;
  width: ${PROFILE_MENU_WIDTH};
  position: absolute;
  right: ${({ theme }) => theme.spacing.s4};
  top: calc(
    ${({ theme }) => theme.spacing.s7} + ${({ theme }) => theme.spacing.s1}
  );
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 0 0 ${rem(1)} ${({ theme }) => theme.color.gray200T},
    0 ${rem(2)} ${rem(24)} ${rem(-4)} ${({ theme }) => theme.color.gray200T},
    0 ${rem(2)} ${rem(11)} ${rem(-4)} ${({ theme }) => theme.color.gray300T};
  z-index: 9999;
  /* visually hide org switcher */
  ${(props) => !props.isActive && styleHideVisually}
`;

export const StyledAccountArea = styled(Box)`
  border-top-left-radius: ${({ theme }) => theme.border.radius};
  border-top-right-radius: ${({ theme }) => theme.border.radius};
  background-color: ${({ theme }) => theme.color.gray100};
`;

export const StyledBox = styled(Box)`
  border-bottom-right-radius: ${({ theme }) => theme.border.radiusLarge};
  border-bottom-left-radius: ${({ theme }) => theme.border.radiusLarge};
`;

export const StyledLogoutFlex = styled(Flex)`
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    span {
      color: ${({ theme }) => theme.color.gray700};
    }
    svg {
      fill: ${({ theme }) => theme.color.gray700};
    }
  }
`;

export const StyledProfileMenuList = styled.ul`
  list-style: none;
`;
export const StyledProfileMenuItem = styled.li`
  margin-top: ${({ theme }) => theme.spacing.s2};
`;

const profileMenuLinkSharedStyles = (props: any) => css`
  font-family: ${props.theme.ui2Typography.fontFamilyBody3};
  font-size: ${props.theme.ui2Typography.fontSizeBody3};
  font-style: ${props.theme.ui2Typography.fontStyleBody3};
  font-weight: ${props.isactive
    ? props.theme.ui2Typography.fontWeightBody3Bold
    : props.theme.ui2Typography.fontWeightBody3};
  line-height: ${props.theme.ui2Typography.lineHeightBody3};
  letter-spacing: ${props.theme.ui2Typography.letterSpacingBody3};
  text-transform: ${props.theme.ui2Typography.textTransformBody3};
  text-decoration: none;
  color: ${props.isactive
    ? props.theme.color.gray800
    : props.theme.color.gray700};
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  &:hover,
  &:focus,
  &:active {
    color: ${props.theme.color.gray800};
  }
`;

export const StyledProfileMenuLink = styled(Link, {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledProfileMenuLinkProps>`
  ${profileMenuLinkSharedStyles}
`;
export const StyledProfileMenuA = styled("a", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledProfileMenuLinkProps>`
  ${profileMenuLinkSharedStyles}
`;
