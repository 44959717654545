import React, { useEffect, useRef } from "react";

import { User } from "../api/core/dataPlane.types";

import { AcceptanceTestsTracking } from "./types/acceptanceTests.types";
import { AppsTracking } from "./types/apps.types";
import { AuthFlowTracking } from "./types/authFlow.types";
import { BillingTracking } from "./types/billing.types";
import { ConfigTracking } from "./types/config.types";
import { DashboardTracking } from "./types/dashboard.types";
import { EnsembleDefsTracking } from "./types/ensembles.types";
import { ExperimentsTracking } from "./types/experiments.types";
import { GeneralTracking } from "./types/general.types";
import { AppInputsTracking } from "./types/inputs.types";
import { InputSetsTracking } from "./types/inputSets.types";
import { InstancesTracking } from "./types/instances.types";
import { IntegrationsTracking } from "./types/integrations.types";
import { Events, LinkTrackerProps } from "./types/legacy.types";
import { MarketplaceTracking } from "./types/marketplace.types";
import { RunHistoryTracking } from "./types/runHistory.types";
import { ScenarioTracking } from "./types/scenarioTests.types";
import { ShadowTestsTracking } from "./types/shadowTests.types";
import { SwitchbackTestsTracking } from "./types/switchbackTest.types";
import { TeamTracking } from "./types/team.types";
import { VersionsTracking } from "./types/versions.types";
import { WebhooksTracking } from "./types/webhooks.types";

declare global {
  interface Window {
    analytics: any;
  }
}

const sendAnalytics = (sendEvent: () => any) => {
  const sendAndCleanup = () => {
    sendEvent();
    window.removeEventListener("segmentReady", sendAndCleanup);
  };
  if (window.analytics) {
    sendEvent();
  } else {
    window.addEventListener("segmentReady", sendAndCleanup);
  }
};

export const identifyEvent = (user: User) => {
  sendAnalytics(() =>
    window.analytics.identify(user.id, {
      email: user.email,
      tosAccepted: user.tosAccepted,
    })
  );
};

export const pageEvent = (pageName: string) => {
  sendAnalytics(() => window.analytics.page(pageName));
};

export type TrackEvents = Events &
  AuthFlowTracking &
  GeneralTracking &
  AppsTracking &
  AppInputsTracking &
  EnsembleDefsTracking &
  AcceptanceTestsTracking &
  DashboardTracking &
  ExperimentsTracking &
  InputSetsTracking &
  InstancesTracking &
  ScenarioTracking &
  ShadowTestsTracking &
  SwitchbackTestsTracking &
  VersionsTracking &
  MarketplaceTracking &
  TeamTracking &
  IntegrationsTracking &
  RunHistoryTracking &
  ConfigTracking &
  BillingTracking &
  WebhooksTracking;

export const trackEvent = <T extends keyof TrackEvents>(
  eventName: T,
  properties?: TrackEvents[T],
  callback?: () => void
) => {
  sendAnalytics(() => {
    if (callback) {
      let callbackCalled = false;
      window.analytics.track(eventName, properties, {}, () => {
        callbackCalled = true;
        callback();
      });
      setTimeout(() => {
        if (!callbackCalled) {
          callback();
        }
      }, 250);
    } else {
      window.analytics.track(eventName, properties);
    }
  });
};

export const LinkTracker = <T extends Element, U extends keyof Events>({
  children,
  eventName,
  properties,
}: LinkTrackerProps<T, U>) => {
  const ref = useRef<T>(null);
  useEffect(() => {
    if (ref.current) {
      trackLink(ref.current, eventName, properties);
    }
  }, [eventName, properties]);

  return <>{children(ref)}</>;
};

export const trackLink = <T extends keyof Events>(
  el: Element,
  eventName: T,
  properties?: Events[T]
) => {
  sendAnalytics(() => window.analytics.trackLink(el, eventName, properties));
};
