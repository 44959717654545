import { RunsData } from "../../../../../api/core/controlPlane.types";

const getTableLayoutSchemaRunHistory = (isGroupEdit: boolean) => [
  ...(isGroupEdit ? [{ width: 16 }] : []),
  { width: isGroupEdit ? 260 : 220 }, // run ID
  { width: 200 }, // name
  { width: 120 }, // type
  { width: 120 }, // status
  { width: 220 }, // date
  { width: 220 }, // user email
  { width: 180 }, // app ID
  { width: 180 }, // instance ID
  { width: 180 }, // version ID
  { width: 180 }, // execution class
];

export const getTableLayoutSchemaAppRunHistory = (
  runs: RunsData,
  isGroupEdit = false
) => {
  const hasInputId = runs && runs.some((run) => !!run.input_id);
  const tableLayoutSchemaRunHistory =
    getTableLayoutSchemaRunHistory(isGroupEdit);
  return hasInputId
    ? tableLayoutSchemaRunHistory.splice(1, 0, { width: 220 })
    : tableLayoutSchemaRunHistory;
};

// NOTE this seems fragile and not the best setup
//      but no time for refactoring at the moment
export const getTableLayoutSchemaExperimentRunHistory = (runs: RunsData) => {
  const hasInputId = runs && runs.some((run) => !!run.input_id);
  const isScenarioTest = runs && runs.some((run) => !!run.scenario_id);
  const statisticsIndicatorsColumnCount = runs
    ? runs.reduce((acc, curr) => {
        const currCount = curr.statistics?.indicators?.length;
        return currCount && currCount > acc ? currCount : acc;
      }, 0)
    : 0;

  // remove name from layout schema, does not exist yet for experiment
  let layoutSchema = getTableLayoutSchemaRunHistory(false);
  if (hasInputId) {
    layoutSchema.splice(1, 1, { width: 220 });
  } else {
    layoutSchema.splice(1, 1);
  }

  if (isScenarioTest) {
    layoutSchema.splice(
      hasInputId ? 3 : 2,
      4,
      ...[
        { width: 200 }, // scenario id
        { width: 200 }, // input set id
        { width: 130 }, // repetition
        { width: 200 }, // option set
      ]
    );
  }

  for (let index = 0; index < statisticsIndicatorsColumnCount; index++) {
    layoutSchema.push({ width: 120 });
  }

  return layoutSchema;
};

export const getTableLayoutSchemaPercentiles = (percentileColCount: number) => {
  const percentileValueColumnLayout = Array(percentileColCount - 1).fill({
    width: 200,
  });
  return [{ width: 100 }, ...percentileValueColumnLayout];
};
