import { useTheme } from "@emotion/react";

import Box from "../../../../../../components/Box";
import Flex from "../../../../../../components/Flex";
import { MainViewType } from "../../../../../../pages/App/subpages/RunDetails/RunDetails.types";
import { formatGeoCoordsForDisplay } from "../../../../../../utils/positionHelpers";
import { rem } from "../../../../../../utils/tools";
import Text from "../../../../../Text";
import {
  DepotInfoStop,
  MarkerPoint,
  RouteSetByID,
} from "../../../../Map.types";
import { formatNumberForDisplay } from "../../utils/mapMarkers";

import getVehicleDisplayData, {
  getVehicleDisplayCheck,
} from "./utils/depotPopupContent";

export type DepotPopupContentProps = {
  point: MarkerPoint;
  activeTab: MainViewType;
  renderPopupDepotList: (
    stopKind: string,
    depotInfo: DepotInfoStop[]
  ) => false | JSX.Element;
  routesById?: RouteSetByID;
};

export default function DepotPopupContent({
  point,
  activeTab,
  renderPopupDepotList,
  routesById,
}: DepotPopupContentProps) {
  const theme = useTheme();

  // depotInfo is an optional property. Even though we check
  // if it exists in the parent component, we still need to do
  // so here to keep TS happy.
  if (!point.depotInfo) {
    return <></>;
  }

  const vehicleDisplayData = getVehicleDisplayData(
    activeTab,
    point,
    routesById
  );

  const vehicleDisplayCheck = getVehicleDisplayCheck(vehicleDisplayData);

  const renderVehicleStat = (
    vehicleId: string,
    vehicleStat: number = 0,
    vehicleColor: string = theme.color.gray600
  ) => (
    <Flex key={vehicleId} justifyContent="space-between">
      <Text styleName="meta-1-bold" styles={{ color: vehicleColor }}>
        {vehicleId}
      </Text>
      <Text styleName="meta-1-bold" styles={{ color: vehicleColor }}>
        {formatNumberForDisplay(vehicleStat)}
      </Text>
    </Flex>
  );

  const renderVehicleStatHeader = (label: string, testId: string) => {
    return (
      <Text testId={testId} pt={2} pb={rem(3)} styleName="meta-2">
        {label}
      </Text>
    );
  };
  const renderVehicleStats = () => {
    if (
      !vehicleDisplayCheck.hasTotalRouteDistance &&
      !vehicleDisplayCheck.hasTotalServiceTime &&
      !vehicleDisplayCheck.hasTotalTime &&
      !vehicleDisplayCheck.hasTotalTravelTime &&
      !vehicleDisplayCheck.hasTotalWaitTime
    ) {
      return <></>;
    }
    return (
      <Box mt={2} hasBorderTop>
        {vehicleDisplayCheck.hasTotalTime && (
          <>
            {renderVehicleStatHeader("Total time", "depot-popup-total-time")}
            {vehicleDisplayData.map((vehicleData) =>
              renderVehicleStat(
                vehicleData.vehicleId,
                vehicleData.vehicleTotalTime,
                vehicleData.vehicleTextColor
              )
            )}
          </>
        )}

        {vehicleDisplayCheck.hasTotalTravelTime && (
          <>
            {renderVehicleStatHeader(
              "Total travel time",
              "depot-popup-total-travel-time"
            )}
            {vehicleDisplayData.map((vehicleData) =>
              renderVehicleStat(
                vehicleData.vehicleId,
                vehicleData.vehicleTotalTravelTime,
                vehicleData.vehicleTextColor
              )
            )}
          </>
        )}

        {vehicleDisplayCheck.hasTotalServiceTime && (
          <>
            {renderVehicleStatHeader(
              "Total service time",
              "depot-popup-total-service-time"
            )}
            {vehicleDisplayData.map((vehicleData) =>
              renderVehicleStat(
                vehicleData.vehicleId,
                vehicleData.vehicleTotalServiceTime,
                vehicleData.vehicleTextColor
              )
            )}
          </>
        )}

        {vehicleDisplayCheck.hasTotalWaitTime && (
          <>
            {renderVehicleStatHeader(
              "Total wait time",
              "depot-popup-total-wait-time"
            )}
            {vehicleDisplayData.map((vehicleData) =>
              renderVehicleStat(
                vehicleData.vehicleId,
                vehicleData.vehicleTotalWaitTime,
                vehicleData.vehicleTextColor
              )
            )}
          </>
        )}

        {vehicleDisplayCheck.hasTotalRouteDistance && (
          <>
            {renderVehicleStatHeader(
              "Total route distance",
              "depot-popup-total-route-distance"
            )}
            {vehicleDisplayData.map((vehicleData) =>
              renderVehicleStat(
                vehicleData.vehicleId,
                vehicleData.vehicleTotalRouteDistance,
                vehicleData.vehicleTextColor
              )
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Text
        as="h4"
        styleName="meta-1-bold"
        styles={{ color: theme.color.purple }}
      >
        Depot
      </Text>

      <Text
        pt={rem(2)}
        styleName="meta-1-bold"
        styles={{ color: theme.color.gray500 }}
      >
        {formatGeoCoordsForDisplay(point.position)}
      </Text>

      <Flex justifyContent="space-between">
        <Box>{renderPopupDepotList("start", point.depotInfo)}</Box>
        <Box>{renderPopupDepotList("end", point.depotInfo)}</Box>
      </Flex>

      {activeTab === "result" && renderVehicleStats()}
    </Box>
  );
}
