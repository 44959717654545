import { DateTime } from "luxon";

import { roundNumber } from "../utils/tools";

type PriceDecimalPlace = 0 | 2;

export const formatPrice = (
  price?: number,
  decimalPlaces: PriceDecimalPlace = 2
) => {
  return (
    price !== undefined &&
    price !== null &&
    price.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: decimalPlaces,
    })
  );
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// https://stackoverflow.com/a/18650828/1241287
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 bytes";
  if (!bytes || typeof bytes !== "number") return "";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["bytes", "kb", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// https://stackoverflow.com/a/21294619/1241287
export const formatMilliseconds = (ms: number, msOnly?: boolean) => {
  if (msOnly) {
    return `${roundNumber(ms % 60000, 3)} ms`;
  }

  const min = Math.floor(ms / 60000);
  const sec = roundNumber((ms % 60000) / 1000, 3);

  return `${min} min ${sec} sec`;
};

export const formatPlural = (str: string, count: number, suffix?: string) => {
  if (count === 1) return str;
  let thisSuffix = suffix || "s";
  return `${str}${thisSuffix}`;
};

export const formatDate = (
  date: string,
  includeTimezone = false,
  isShort = false
) => {
  if (isShort) {
    return DateTime.fromISO(date).toFormat("yyyy-MM-dd");
  }

  return `${DateTime.fromISO(date)
    .toFormat("yyyy-MM-dd · h:mm:ss a")
    .toLowerCase()} ${
    includeTimezone ? DateTime.fromISO(date).toFormat("ZZZZ") : ""
  }`;
};

// port from DSC lib

type ReformatTimeParams = {
  time: string | null;
  inputDateType: "rfc3339" | "iso";
  outputDateFormat: "rfc3339" | string;
};
export const reformatTime = ({
  time,
  inputDateType,
  outputDateFormat,
}: ReformatTimeParams): string => {
  if (!time) return "";

  const trimmedTime = time.trim();

  if (inputDateType === "iso") {
    if (outputDateFormat === "rfc3339") {
      return DateTime.fromISO(time).toFormat("yyyy-MM-dd'T'HH:mm:ssZZ");
    }
    return DateTime.fromISO(time).toFormat(outputDateFormat);
  }
  if (inputDateType === "rfc3339") {
    if (/Z$/g.test(trimmedTime)) {
      return DateTime.fromFormat(
        trimmedTime,
        "yyyy-MM-dd'T'HH:mm:ss'Z'"
      ).toFormat(outputDateFormat);
    }
    if (/(-|\+)\d{2}:[0|3|4][0|5]$/g.test(trimmedTime)) {
      return DateTime.fromFormat(
        trimmedTime,
        "yyyy-MM-dd'T'HH:mm:ssZZ"
      ).toFormat(outputDateFormat);
    }
    return "Invalid format";
  }
  return trimmedTime;
};

export const formatPercentage = (
  numerator: number,
  denominator: number
): string => {
  if (typeof numerator !== "number" || typeof denominator !== "number") {
    return "";
  }
  if (isNaN(numerator / denominator)) {
    return "";
  }
  return `${Math.round((numerator / denominator) * 100)}%`;
};
