import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { trackEvent } from "../../../../analytics";
import { UserOrganization } from "../../../../api/core/dataPlane.types";
import { useUser } from "../../../../AuthProvider";
import Box from "../../../../components/Box";
import Button2 from "../../../../components/Button2";
import Flex from "../../../../components/Flex";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import Tag from "../../../../components/Tag";
import Text from "../../../../components/Text";
import useTeam from "../../../../hooks/useTeam";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { rem } from "../../../../utils/tools";
import { teamTooltipCopy } from "../../data/microcopy";
import { TeamPageProps } from "../../Team.types";
import {
  getRootOrg,
  isCurrentUserRoot,
} from "../Members/utils/isCurrentUserRoot";

import ConfirmLeaveTeamModal from "./components/ConfirmModal";
import { ExitModalFuncParams, LeaveTeamFuncParams } from "./Details.types";

const pageTitle = "Details";

const Details = ({ team }: TeamPageProps) => {
  const [{ organizations, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();

  const { isLeaveTeamProcessing, leaveTeam, leaveTeamError } = useTeam();
  const [leaveTeamConfirm, setLeaveTeamConfirm] = useState<UserOrganization>();

  const userHasAccessToEdit = userHasAccessToAction(
    roles,
    ActionGroups.OrgAdmin,
    {}
  );

  const isUserRoot = isCurrentUserRoot(organizations, roles);

  const { pathname } = useLocation();

  const rootOrg = getRootOrg(organizations);

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  if (!team) return <Redirect to="/teams" />;

  const handleLeaveTeamConfirmModal = ({ e, team }: LeaveTeamFuncParams) => {
    e.preventDefault();
    setLeaveTeamConfirm(team);
  };

  const handleLeaveTeam = async ({ e, team }: LeaveTeamFuncParams) => {
    e.preventDefault();
    await leaveTeam(team.id, rootOrg?.id || "");

    setLeaveTeamConfirm(undefined);
    return;
  };

  const exitConfirmLeaveTeam = ({ e }: ExitModalFuncParams) => {
    e.preventDefault();
    setLeaveTeamConfirm(undefined);
    return;
  };

  if (leaveTeamError) return <StandardError errorMessage={leaveTeamError} />;

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
        }}
        configActionButton={{
          label: "Edit",
          url: `${pathname}/details/edit`,
          onClick: () =>
            trackEvent("Team", {
              view: "Details",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: userHasAccessToEdit,
        }}
      />

      <RowDetail
        hasNoBorder
        property="Name"
        render={
          <Box maxWidth={rem(680)}>
            <Text styleName="body-2" data-testid="team-name-text">
              {team.name}
            </Text>
          </Box>
        }
      />

      <RowDetail
        property="ID"
        tooltipCopy={teamTooltipCopy.id(isUserRoot)}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={team.id}
              contentToCopy={team.id}
            />
          </Flex>
        }
      />

      {!isUserRoot && (
        <Flex
          mt={3}
          ml={[-4, -4, -6]}
          mr={[-4, -4, -7]}
          pt={4}
          pr={[4, 4, 7]}
          pl={[4, 4, 6]}
        >
          <Button2
            size="large"
            label="Leave team"
            onClick={(e: any) => handleLeaveTeamConfirmModal({ e, team })}
          />
        </Flex>
      )}

      {leaveTeamConfirm && (
        <ConfirmLeaveTeamModal
          {...{ leaveTeamProcessing: isLeaveTeamProcessing }}
          leaveTeam={handleLeaveTeam}
          exitModal={exitConfirmLeaveTeam}
          team={leaveTeamConfirm}
        />
      )}
    </>
  );
};

export default Details;
