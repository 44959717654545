import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { trackEvent } from "../../../../analytics";
import { AvatarInputSet } from "../../../../avatars";
import Box from "../../../../components/Box";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import Text from "../../../../components/Text";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { rem } from "../../../../utils/tools";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { inputSetTooltips } from "../../data/microcopy";
import { trackInputSetFieldChanges } from "../../utils/inputSets";

export const EditInputSetDetails = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const params = useParams() as { id: string };
  const theme = useTheme();
  const { returnPath, returnPathList } = useReturnPaths();

  const [isProcessing, setIsProcessing] = useState(false);

  const { loadInputSets } = useExperiments();

  const {
    editEntity: editInputSet,
    entity: inputSet,
    entityLoadError: inputSetLoadError,
    entityEditError: inputSetEditError,
    loadEntity: loadInputSet,
    isEntityEdited: isInputSetSaved,
  } = useManageEntity("experiments/inputsets");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "experiments/inputsets", true);

  // load inputset data
  useEffect(() => {
    if (!inputSet && !inputSetLoadError) {
      loadInputSet(app.id, params.id);
    }
  }, [app.id, inputSet, inputSetLoadError, loadInputSet, params.id]);

  // page display
  useEffect(() => {
    if (inputSet) {
      setMetaTitle(`Edit ${inputSet.name}`);
    }
  }, [inputSet, setMetaTitle]);

  // pre-fill pending inputset with original data
  useEffect(() => {
    if (inputSet && !pendingStandardInputs.id) {
      updateStandardInputs([
        { key: "name", value: inputSet.name },
        { key: "id", value: inputSet.id },
        { key: "description", value: inputSet.description },
      ]);
    }
  }, [inputSet, pendingStandardInputs.id, updateStandardInputs]);

  useEffect(() => {
    if (inputSetEditError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, inputSetEditError]);

  const handleInputSetSave = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);

    const payload = { ...pendingStandardInputs };

    trackInputSetFieldChanges(inputSet!, pendingStandardInputs);

    await editInputSet(app.id, inputSet!.id, payload);
  };

  const handleCancel = () => {
    trackEvent("InputSets", {
      view: "Edit Input Set",
      action: "Edit Input Set Canceled",
    });
    return;
  };

  const handleInputSetPostDelete = () => {
    trackEvent("InputSets", {
      view: "Edit Input Set",
      action: "Input Set Deleted",
    });

    loadInputSets(app.id);
  };

  if (inputSetLoadError) {
    return <StandardError errorMessage={inputSetLoadError} />;
  }
  if (!pendingStandardInputs.id || !inputSet) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }
  if (isInputSetSaved) {
    trackEvent("InputSets", {
      view: "Edit Input Set",
      action: "Edit Input Set Saved",
    });

    loadInputSet(app.id, params.id);
    loadInputSets(app.id);

    return <Redirect to={returnPath} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name || !!standardInputsErrors.name;

  return (
    <>
      <Header
        configPageTitle={{
          label: `Edit ${inputSet.name}`,
          ancestorIcon: <AvatarInputSet size={24} />,
          ancestorLabel: "Input Sets",
          ancestorUrl: returnPathList,
        }}
      />

      <form>
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Input set name",
                  testId: "edit-input-set-name-input",
                  type: "name",
                  trackEventCategory: "InputSets",
                  trackEventProperties: {
                    view: "Edit Input Set",
                    action: "Field Changed",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />
        <RowDetail
          property="ID"
          secondaryLabel="Read-only"
          tooltipCopy={inputSetTooltips.idEditView.content}
          render={
            <Box flexGrow={1} maxWidth={rem(408)}>
              <Input
                htmlType="text"
                isDisabled
                placeholder="Input set ID"
                value={inputSet.id}
                readOnly
              />
            </Box>
          }
        />
        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Input set description",
                  testId: "edit-input-set-description-input",
                  type: "description",
                  trackEventCategory: "InputSets",
                  trackEventProperties: {
                    view: "Edit Input Set",
                    action: "Field Changed",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />
        {inputSet.created_at && (
          <RowDetail
            property="Created"
            secondaryLabel="Read-only"
            render={
              <Text
                as="time"
                styleName="body-2"
                dateTime={inputSet.created_at}
                title={inputSet.created_at}
              >
                {DateTime.fromISO(inputSet.created_at).toFormat(
                  "yyyy-MM-dd · h:mm a"
                )}
              </Text>
            }
          />
        )}
        {inputSet.updated_at && (
          <RowDetail
            property="Last Updated"
            secondaryLabel="Read-only"
            render={
              <Text
                as="time"
                styleName="body-2"
                dateTime={inputSet.updated_at}
                title={inputSet.updated_at}
              >
                {DateTime.fromISO(inputSet.updated_at).toFormat(
                  "yyyy-MM-dd · h:mm a"
                )}
              </Text>
            }
          />
        )}
        {!!inputSet.input_ids.length && (
          <RowDetail
            property="Input Ids"
            secondaryLabel="Read-only"
            render={inputSet.input_ids.map((inputId) => (
              <Box maxWidth={rem(480)} mb={1} key={inputId}>
                <Text styleName="body-2">{inputId}</Text>
              </Box>
            ))}
          />
        )}

        <Footer
          app={app}
          endpoint="experiments/inputsets"
          entityId={inputSet.id}
          error={inputSetEditError}
          handleCancel={handleCancel}
          handleMainAction={handleInputSetSave}
          handlePostDelete={handleInputSetPostDelete}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="edit"
        />
      </form>
    </>
  );
};

export default EditInputSetDetails;
