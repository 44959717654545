import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { space } from "styled-system";

import {
  styleHideVisually,
  styleHideVisuallyReset,
} from "../../utils/constants";
import { filterProps } from "../../utils/filterProps";
import { renderExtraStyles } from "../../utils/renderExtraStyles";
import { rem } from "../../utils/tools";

import { MULTI_SELECT_CONTROL_BUTTON_WIDTH } from "./utils/constants";
import {
  getButtonSizeStyles,
  getButtonTypeStyles,
  getDisabledButtonStyles,
} from "./utils/getButtonStyles";
import {
  StyledButtonProps,
  StyledLabelSpacerProps,
  StyledMultiSelectOptionsProps,
} from "./Button2.types";

export const sharedButtonStyles = (props: any) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: ${props.theme.spacing.s7};
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  cursor: ${props.isDisabled ? "not-allowed" : "pointer"};
  border: 0;
  border-radius: ${props.theme.border.radius};

  &:focus-visible {
    outline-width: ${rem(1.5)};
    outline-style: solid;
    outline-color: ${props.theme.color.orange700};
  }

  ${props.isMultiSelect && `position: relative;`}
`;

export const StyledButtonPrimary = styled("button", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledButtonProps>`
  ${space}
  ${sharedButtonStyles}
  ${(props) =>
    getButtonSizeStyles({
      hasIcon: props.hasIcon,
      hasIcon2: props.hasIcon2,
      isMultiSelect: props.isMultiSelect,
      size: props.size,
      theme: props.theme,
    })}
    ${(props) =>
    getButtonTypeStyles({
      isMultiSelect: props.isMultiSelect,
      theme: props.theme,
      type: props.styledType,
    })}
  ${(props) =>
    getDisabledButtonStyles({
      isDisabled: props.isDisabled,
      theme: props.theme,
      type: props.styledType,
    })}
  ${(props) =>
    props.styles &&
    renderExtraStyles({
      styles: props.styles,
    })}
`;

export const StyledButtonOutline = styled("button", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledButtonProps>`
  ${space}
  ${sharedButtonStyles}
  ${(props) =>
    getButtonSizeStyles({
      hasIcon: props.hasIcon,
      hasIcon2: props.hasIcon2,
      isMultiSelect: props.isMultiSelect,
      size: props.size,
      theme: props.theme,
    })}
  ${(props) =>
    getButtonTypeStyles({
      isMultiSelect: props.isMultiSelect,
      theme: props.theme,
      type: props.styledType,
    })}

  ${(props) =>
    getDisabledButtonStyles({
      isDisabled: props.isDisabled,
      theme: props.theme,
      type: props.styledType,
    })}

  ${(props) =>
    props.styles &&
    renderExtraStyles({
      styles: props.styles,
    })}
`;

export const StyledButtonText = styled("button", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledButtonProps>`
  ${space}
  ${sharedButtonStyles}
  ${(props) =>
    getButtonSizeStyles({
      hasIcon: props.hasIcon,
      hasIcon2: props.hasIcon2,
      isMultiSelect: props.isMultiSelect,
      size: props.size,
      theme: props.theme,
    })}
    ${(props) =>
    getButtonTypeStyles({
      isMultiSelect: props.isMultiSelect,
      theme: props.theme,
      type: props.styledType,
    })}

  ${(props) =>
    getDisabledButtonStyles({
      isDisabled: props.isDisabled,
      theme: props.theme,
      type: props.styledType,
    })}
      
  ${(props) =>
    props.styles &&
    renderExtraStyles({
      styles: props.styles,
    })}
`;

export const StyledLabelSpacer = styled("span", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledLabelSpacerProps>`
  margin-right: ${(props) => (props.icon2 ? props.theme.spacing.s1 : 0)};
  margin-left: ${(props) => (props.icon ? props.theme.spacing.s1 : 0)};
`;

export const StyledMultiSelectOptions = styled("ul", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledMultiSelectOptionsProps>`
  ${styleHideVisually};
  ${(props) => props.isActive && styleHideVisuallyReset};

  /* styles must be here due to cascade */
  width: calc(
    100% - ${rem(MULTI_SELECT_CONTROL_BUTTON_WIDTH)} -
      ${({ theme }) => theme.border.widthMediumShadowOnly}
  );
  position: absolute;
  right: ${rem(MULTI_SELECT_CONTROL_BUTTON_WIDTH)};
  top: 0;
  margin-right: ${({ theme }) => theme.border.widthMediumShadowOnly};
  z-index: 1;

  a,
  button {
    box-sizing: border-box;
    position: relative;
  }
  a:hover,
  button:hover {
    z-index: 1;
  }
  a:first-of-type,
  button:first-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  a + a,
  button + button {
    margin-top: calc(${({ theme }) => theme.border.widthMediumShadowOnly} * -1);
    border-radius: 0;
  }
  a:last-of-type,
  button:last-of-type {
    border-bottom-right-radius: ${({ theme }) => theme.border.radius};
    border-bottom-left-radius: ${({ theme }) => theme.border.radius};
  }

  ${(props) =>
    props.type === "outline" &&
    `
      a + a,
      button + button {
        box-shadow: inset ${rem(1.5)} 0 0 0 ${props.theme.color.orange400},
          inset ${rem(-1.5)} 0 0 0 ${props.theme.color.orange400};
      }
      a:last-of-type,
      button:last-of-type {
        box-shadow: inset ${rem(1.5)} 0 0 0 ${props.theme.color.orange400},
          inset ${rem(-1.5)} 0 0 0 ${props.theme.color.orange400},
          inset 0 ${rem(-1.5)} 0 0 ${props.theme.color.orange400};
      }
    `}
`;
