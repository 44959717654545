import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconHeaderProfile = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      iconSize={iconSize || 32}
      viewBox={[0, 0, 32, 32]}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7818 25.2884C20.8804 26.6791 18.5361 27.5 16 27.5C13.4635 27.5 11.1188 26.6788 9.2173 25.2878C9.38045 23.2641 10.0202 21.8454 10.9785 20.9011C12.0985 19.7973 13.773 19.2236 15.9995 19.2236C18.226 19.2236 19.9005 19.7973 21.0206 20.9011C21.9789 21.8455 22.6188 23.2644 22.7818 25.2884ZM24.1468 24.1166C23.844 22.3297 23.1511 20.8947 22.0735 19.8327C21.2138 18.9856 20.1488 18.4147 18.9285 18.0828C20.4857 17.1065 21.521 15.3749 21.521 13.4015C21.521 10.3522 19.0491 7.88025 15.9998 7.88025C12.9505 7.88025 10.4785 10.3522 10.4785 13.4015C10.4785 15.3748 11.5138 17.1064 13.0708 18.0827C11.8504 18.4145 10.7853 18.9855 9.92556 19.8327C8.8481 20.8946 8.15532 22.3293 7.8524 24.1158C5.78064 22.036 4.5 19.1676 4.5 16C4.5 9.64873 9.64873 4.5 16 4.5C22.3513 4.5 27.5 9.64873 27.5 16C27.5 19.168 26.219 22.0367 24.1468 24.1166ZM29 16C29 23.1797 23.1797 29 16 29C8.8203 29 3 23.1797 3 16C3 8.8203 8.8203 3 16 3C23.1797 3 29 8.8203 29 16ZM11.9785 13.4015C11.9785 11.1806 13.7789 9.38025 15.9998 9.38025C18.2206 9.38025 20.021 11.1806 20.021 13.4015C20.021 15.6224 18.2206 17.4227 15.9998 17.4227C13.7789 17.4227 11.9785 15.6224 11.9785 13.4015Z"
      />
    </BaseIcon>
  );
};

export default IconHeaderProfile;
