import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { AppInstancesData } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import StandardError from "../../../../components/StandardError";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import { getFilteredEntities } from "../../../../utils/entities/getFilteredEntities";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../App.types";
import LoadingListView from "../../components/LoadingListView";
import { appTooltips } from "../../data/appTooltips";

import { addAutoInstance } from "./utils/addAutoInstance";
import { renderInstancesList } from "./utils/renderInstancesList";

const pageTitle = "Instances";

const Instances = ({ app }: AppPageProps) => {
  const [{ id: accountId, roles, features }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const { pathname } = useLocation();
  const theme = useTheme();

  const [filterText, setFilterText] = useState("");

  const {
    additionalInstancesLoading,
    loadInstances,
    setAdditionalInstancesLoading,
    setInstances,
    instances,
    instancesError,
    instancesNextPageToken,
  } = useAppCollection();

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load instances if not in context already
  useEffect(() => {
    if (!instances && !instancesError) {
      loadInstances({
        applicationId: app.id,
        shouldPaginate: true,
      });
    }
  }, [app.id, instances, instancesError, loadInstances]);

  if (instancesError) {
    return <StandardError errorMessage={instancesError} />;
  }

  const canUserCreateAndEditInstances = userHasAccessToAction(
    roles,
    ActionGroups.AppInstanceOperator,
    { locked: false }
  );

  const hasInstances = !!instances?.length;

  const modifiedInstances = !hasInstances
    ? addAutoInstance(app, instances)
    : instances;
  const filteredInstances = getFilteredEntities(filterText, modifiedInstances);
  const renderInstances = () => {
    return (
      <div data-testid="instances-list">
        {renderInstancesList({
          accountId,
          app,
          canUserCreateAndEditInstances,
          instances: filteredInstances as AppInstancesData,
          theme,
          userRoles: roles,
          features: features,
        })}
      </div>
    );
  };

  const handleLoadMore = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setAdditionalInstancesLoading(true);
    loadInstances({
      applicationId: app.id,
      nextPageToken: instancesNextPageToken,
      shouldAppend: true,
      shouldPaginate: true,
    });
    return;
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: appTooltips.instances.content,
          tooltipExtraLinkLabel: appTooltips.instances.extraLinkLabel,
          tooltipExtraLinkUrl: appTooltips.instances.extraLinkUrl,
        }}
        configActionButton={{
          label: "New instance",
          url: `${pathname}/new`,
          onClick: () =>
            trackEvent("Apps", {
              view: "App Instances",
              action: "New App Instance Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditInstances,
        }}
        configFilter={{
          inputText: filterText,
          testId: "filter-instances",
          setInputText: setFilterText,
        }}
        refreshButton={{
          emptyItems: null,
          itemsLoading: !instances,
          loadItems: () =>
            loadInstances({
              applicationId: app.id,
              nextPageToken: instancesNextPageToken,
              shouldAppend: false,
              shouldPaginate: true,
            }),
          setItems: setInstances,
        }}
      />

      <PlanNotice {...{ app }} type="custom-apps" />

      {!instances ? <LoadingListView /> : renderInstances()}

      {!!instances?.length && (
        <Footer
          app={app}
          endpoint="instances"
          error={instancesError}
          handleMainAction={handleLoadMore}
          isActionButtonDisabled={!instancesNextPageToken}
          isActionButtonLoading={additionalInstancesLoading}
          view="list"
        />
      )}
    </>
  );
};

export default Instances;
