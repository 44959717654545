import { startCase } from "lodash";

import { OptionSetDefinitionsByScenario } from "../../../components/ScenarioDefinitions/ScenarioDefinitions.types";
import Text from "../../../components/Text";

const formatScenarioConfigOptionHeaderLabel = (str: string) => {
  return startCase(str);
};

export const getScenarioConfigOptionsUnique = (
  scenarioDefinitions?: OptionSetDefinitionsByScenario
) => {
  if (!scenarioDefinitions) return [];

  const configOptionLabels = Object.keys(scenarioDefinitions).flatMap(
    (scenario) => {
      return Object.keys(scenarioDefinitions[scenario]).flatMap((optionSet) => {
        const optionSetConfigOptions =
          scenarioDefinitions[scenario][optionSet].options;
        if (!optionSetConfigOptions) {
          return [];
        }

        return Object.keys(optionSetConfigOptions);
      });
    }
  );

  // unique values only
  return Array.from(new Set(configOptionLabels));
};

export const getScenarioConfigOptionsHeaders = (
  scenarioDefinitions: OptionSetDefinitionsByScenario,
  theme: any
) => {
  const uniqueOptionLabels =
    getScenarioConfigOptionsUnique(scenarioDefinitions);

  return uniqueOptionLabels.map((label) => {
    return {
      id: label,
      accessorKey: label,
      value: formatScenarioConfigOptionHeaderLabel(label),
      header: formatScenarioConfigOptionHeaderLabel(label),
      label: formatScenarioConfigOptionHeaderLabel(label),
      cell: (props: any) => {
        return (
          <Text
            as="span"
            styleName="code"
            styles={{
              display: "block",
              fontSize: theme.ui2Typography.fontSizeMeta1,
              textAlign: "right",
              textRendering: "auto",
              WebkitFontSmoothing: "auto",
            }}
          >
            {props.getValue()}
          </Text>
        );
      },
    };
  });
};
