import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import RowDetail from "../../../components/RowDetail";
import Select from "../../../components/Select";
import { INPUT_SET_MAX_RUN_IDS } from "../../../config/experiments";
import { rem } from "../../../utils/tools";
import { runDateOptions } from "../../Experiments/components/utils";
import { InputSetDateRangeOption } from "../Experiments.types";

interface InputSetsDateRangeSelectProps {
  pendingDateRange: InputSetDateRangeOption | undefined;
  onAdd: (option: InputSetDateRangeOption) => void;
}

const InputSetsDateRangeSelect = (props: InputSetsDateRangeSelectProps) => {
  const { pendingDateRange, onAdd } = props;

  const addDateRange = (dateRangeSelected: InputSetDateRangeOption) => {
    onAdd(dateRangeSelected);
  };
  return (
    <RowDetail
      property="Date Range"
      secondaryLabel={`Time period to search for runs (max ${INPUT_SET_MAX_RUN_IDS})`}
      render={
        <Box maxWidth={rem(408)}>
          <Box>
            <Flex width="100%">
              <Select
                testId="new-input-set-date-range-select"
                placeholder="Select date range"
                options={runDateOptions}
                getOptionValue={(
                  inputSetDateSelected: InputSetDateRangeOption
                ) => inputSetDateSelected.label}
                value={pendingDateRange}
                onChange={(selection: InputSetDateRangeOption) => {
                  addDateRange(selection);
                }}
              />
            </Flex>
          </Box>
        </Box>
      }
    />
  );
};
export default InputSetsDateRangeSelect;
