import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import {
  EntityErrorMessage,
  RunDetails,
  RunDetailsNestedOutputValue,
  RunMetadataResponse,
} from "../../../../../api/core/controlPlane.types";
import { useUser } from "../../../../../AuthProvider";
import Box from "../../../../../components/Box";
import Button2 from "../../../../../components/Button2";
import ControlPanel from "../../../../../components/ControlPanel";
import Flex from "../../../../../components/Flex";
import Notification from "../../../../../components/Notification";
import RowDetail from "../../../../../components/RowDetail";
import Tag from "../../../../../components/Tag";
import Text from "../../../../../components/Text";
import {
  RUN_STATUS_V2_QUEUED,
  RUN_STATUS_V2_RUNNING,
} from "../../../../../config/apps";
import { MEASURE_ENTITY_ROW } from "../../../../../config/general";
import { formatBytes, formatMilliseconds } from "../../../../../utils/format";
import { getAccUrl } from "../../../../../utils/navigation";
import { rem } from "../../../../../utils/tools";
import { isNumber } from "../../../../../utils/typeCheck";
import { maskUserEmail } from "../../../../../utils/user";
import { appTooltipCopy } from "../../../../Apps/data/microcopy";
import ExecutionClass from "../../../components/ExecutionClass";
import { getStatusTextColor } from "../../RunDetails/utils/getStatusTextColor";
import {
  configMessage,
  configRunInstanceMessage,
  configRunMessage,
  objectiveMessage,
  statisticsMessage,
} from "../data/microcopy";
import {
  StyledRunDetailsSidebar,
  StyledRunViewContainer,
} from "../RunDetails.styled";
import { DEV_INT_ID } from "../utils/constants";
import { getObjectiveData } from "../utils/getObjectiveData";
import { getIsRunResolved } from "../utils/runDataChecks";
import {
  checkIsRunOutputBlockValid,
  getRunOutputBlockTableData,
  getRunOutputBlockTableHeaders,
  renderRunOutputBlock,
} from "../utils/runOutputBlocks";

type RunDetailsDetailProps = {
  cancelRunInQueue: (applicationId: string, runId: string) => Promise<void>;
  isExpanded: boolean;
  isOutputBelowRenderThreshold: boolean;
  isProcessing: boolean;
  runCancelError: EntityErrorMessage;
  runDetails: RunDetails;
  runMetadata: RunMetadataResponse;
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  isSubscriptionApp?: boolean;
};

const RunDetailsDetail = ({
  cancelRunInQueue,
  isExpanded,
  isOutputBelowRenderThreshold,
  isProcessing,
  isSubscriptionApp,
  runCancelError,
  runDetails,
  runMetadata,
  setIsProcessing,
}: RunDetailsDetailProps) => {
  const [{ id: accId }] = useUser();
  const theme = useTheme();

  const isRunResolved = getIsRunResolved(runMetadata);

  const runOptions =
    runMetadata && runMetadata.metadata.options?.request_options;
  const runInstanceOptions =
    runMetadata && runMetadata.metadata.options?.active_options;
  const options = runDetails && runDetails.output?.options;
  const statistics =
    (runDetails && runDetails.output?.statistics) ||
    runMetadata.metadata.statistics;
  const objective = runDetails && getObjectiveData(runDetails.output);

  // validate props for table data
  const isRunOptionsValid = checkIsRunOutputBlockValid(runOptions);
  const isRunInstanceOptionsValid =
    checkIsRunOutputBlockValid(runInstanceOptions);
  const isOptionsValid = checkIsRunOutputBlockValid(options);
  const isStatisticsValid = checkIsRunOutputBlockValid(statistics);
  const isObjectiveValid = checkIsRunOutputBlockValid(objective);

  // remove series_data, shown in Analysis tab
  const modStatistics =
    statistics &&
    isStatisticsValid &&
    Object.keys(statistics).reduce(
      (newStatisticsObj, topLevelStatisticsKey) => {
        if (topLevelStatisticsKey !== "series_data") {
          newStatisticsObj[topLevelStatisticsKey] =
            statistics[topLevelStatisticsKey];
        }
        return newStatisticsObj;
      },
      {} as RunDetailsNestedOutputValue
    );

  const handleCancelRun = async () => {
    setIsProcessing(true);
    await cancelRunInQueue(runMetadata.metadata.application_id, runMetadata.id);
  };

  const hasSidebarContent =
    isRunOptionsValid ||
    isRunInstanceOptionsValid ||
    isOptionsValid ||
    isStatisticsValid ||
    isObjectiveValid;

  const renderCancelAction = () => {
    if (
      !(
        runMetadata.metadata.status_v2 === RUN_STATUS_V2_QUEUED ||
        runMetadata.metadata.status_v2 === RUN_STATUS_V2_RUNNING
      )
    ) {
      return null;
    }

    if (runCancelError) {
      setIsProcessing(false);

      return (
        <Notification
          mt={-1}
          mr={4}
          mb={-1}
          ml={4}
          size="small"
          type="error"
          message={runCancelError}
        />
      );
    }

    return (
      <Button2
        mt={-1}
        mb={-1}
        ml={4}
        type="outline-quiet"
        size="small"
        htmlType="button"
        label="Cancel run"
        isLoading={isProcessing}
        onClick={handleCancelRun}
        styles={{
          minWidth: rem(81),
        }}
      />
    );
  };

  const extraStylesControlPanel = {
    minHeight: rem(56),
    boxSizing: "border-box",
    borderTop: 0,
    borderBottomWidth: theme.border.width,
    borderBottomStyle: theme.border.style,
    borderBottomColor: theme.color.gray200,
  };

  return (
    <StyledRunViewContainer {...{ isExpanded }}>
      <Flex
        flexDirection={["column", "column", "column", "row"]}
        minHeight="100%"
      >
        <Box
          width={[1 / 1, 1 / 1, 1 / 1, hasSidebarContent ? 1 / 2 : 1 / 1]}
          {...(hasSidebarContent && {
            shrink: 0,
            maxWidth: ["none", "none", "none", rem(624)],
          })}
        >
          {runMetadata.name && (
            <RowDetail
              hasNoBorder
              property="Name"
              render={
                <Box maxWidth={MEASURE_ENTITY_ROW}>
                  <Text styleName="body-2">{runMetadata.name}</Text>
                </Box>
              }
            />
          )}

          <RowDetail
            {...(!runMetadata.name && {
              hasNoBorder: true,
            })}
            testId="run-details-id"
            property="ID"
            tooltipCopy={appTooltipCopy.runId}
            render={
              <Flex>
                <Tag
                  testId="run-details-id-value"
                  ml={rem(-6)}
                  type="id"
                  label={runMetadata.id}
                  contentToCopy={runMetadata.id}
                />
              </Flex>
            }
          />

          {runMetadata.description && (
            <RowDetail
              property="Description"
              render={
                <Box maxWidth={MEASURE_ENTITY_ROW}>
                  <Text styleName="body-2">{runMetadata.description}</Text>
                </Box>
              }
            />
          )}

          {runMetadata?.user_email && (
            <RowDetail
              property="User"
              render={
                <Text styleName="body-2">
                  {maskUserEmail(runMetadata.user_email)}
                </Text>
              }
            />
          )}

          <RowDetail
            property="Status"
            render={
              <Flex alignItems="center">
                <Text
                  styleName="body-2-bold"
                  styles={{
                    color: getStatusTextColor({
                      status: runMetadata.metadata.status_v2,
                      theme,
                    }),
                  }}
                >
                  {runMetadata.metadata.status_v2}
                </Text>

                {renderCancelAction()}
              </Flex>
            }
          />

          <RowDetail
            testId="run-details-type"
            property="Type"
            render={
              <Flex>
                <Text styleName="body-2">
                  {runMetadata.metadata.run_type.type}
                </Text>
              </Flex>
            }
          />

          {runMetadata.metadata?.run_type?.definition_id && (
            <RowDetail
              testId="run-details-type"
              property="Ensemble Def. ID"
              render={
                <Flex>
                  <Tag
                    ml={rem(-6)}
                    type="id"
                    label={runMetadata.metadata.run_type.definition_id}
                    contentToCopy={runMetadata.metadata.run_type.definition_id}
                    url={getAccUrl(
                      accId,
                      `/app/${runMetadata.metadata.application_id}/runs/ensemble/${runMetadata.metadata.run_type.definition_id}`
                    )}
                  />
                </Flex>
              }
            />
          )}
          {runMetadata.metadata?.run_type?.reference_id && (
            <RowDetail
              testId="run-details-type"
              property="Ensemble def. run group reference ID"
              render={
                <Flex>
                  <Tag
                    ml={rem(-6)}
                    type="id"
                    label={runMetadata.metadata.run_type.reference_id}
                    contentToCopy={runMetadata.metadata.run_type.reference_id}
                  />
                </Flex>
              }
            />
          )}

          <RowDetail
            property="Instance ID"
            render={
              runMetadata.metadata.application_instance_id ? (
                <Flex>
                  <Tag
                    ml={rem(-6)}
                    type="id"
                    label={runMetadata.metadata.application_instance_id}
                    contentToCopy={runMetadata.metadata.application_instance_id}
                    {...(runMetadata.metadata.application_instance_id !==
                      DEV_INT_ID && {
                      url: getAccUrl(
                        accId,
                        `/app/${runMetadata.metadata.application_id}/instance/${runMetadata.metadata.application_instance_id}`
                      ),
                    })}
                  />
                </Flex>
              ) : (
                <Text
                  styleName="body-2"
                  styles={{ color: theme.color.gray600 }}
                >
                  None
                </Text>
              )
            }
          />
          <RowDetail
            property="Version ID"
            render={
              runMetadata.metadata.application_version_id ? (
                <Flex>
                  <Tag
                    ml={rem(-6)}
                    type="id"
                    label={runMetadata.metadata.application_version_id}
                    contentToCopy={runMetadata.metadata.application_version_id}
                    {...(!isSubscriptionApp && {
                      url: getAccUrl(
                        accId,
                        `/app/${runMetadata.metadata.application_id}/version/${runMetadata.metadata.application_version_id}`
                      ),
                    })}
                  />
                </Flex>
              ) : (
                <Text
                  styleName="body-2"
                  styles={{ color: theme.color.gray600 }}
                >
                  None
                </Text>
              )
            }
          />

          {runMetadata.metadata?.execution_class && (
            <RowDetail
              property="Execution class"
              render={
                <ExecutionClass
                  isAbbreviated
                  executionClass={runMetadata.metadata.execution_class}
                />
              }
            />
          )}

          {runMetadata.metadata.created_at && (
            <RowDetail
              property="Created at"
              render={
                <Box>
                  <Text styleName="body-2">
                    {
                      <time
                        dateTime={runMetadata.metadata.created_at}
                        title={runMetadata.metadata.created_at}
                      >
                        {DateTime.fromISO(
                          runMetadata.metadata.created_at
                        ).toFormat("yyyy-MM-dd · h:mm:ss a ZZZZ")}
                      </time>
                    }
                  </Text>
                </Box>
              }
            />
          )}

          <RowDetail
            property="Duration"
            render={
              <Text styleName="body-2">
                {isNumber(runMetadata.metadata.duration)
                  ? formatMilliseconds(runMetadata.metadata.duration)
                  : ""}
              </Text>
            }
          />

          <RowDetail
            property="Input size"
            render={
              <Text styleName="body-2">
                {formatBytes(runMetadata.metadata.input_size)}
              </Text>
            }
          />
          <RowDetail
            property="Output size"
            render={
              <Text styleName="body-2">
                {formatBytes(runMetadata?.metadata.output_size)}
              </Text>
            }
          />

          <RowDetail
            property="Error"
            render={
              runMetadata.metadata.error ? (
                <Text
                  styleName="body-2"
                  styles={{ color: theme.color.red600, whiteSpace: "pre-wrap" }}
                >
                  {runMetadata.metadata.error}
                </Text>
              ) : (
                <Text
                  styleName="body-2"
                  styles={{ color: theme.color.gray600 }}
                >
                  None
                </Text>
              )
            }
          />
        </Box>

        {hasSidebarContent && (
          <StyledRunDetailsSidebar
            width={[1 / 1, 1 / 1, 1 / 1, 1 / 2]}
            mt={[4, 6, 6, 0]}
            pt={rem(1)}
          >
            {isStatisticsValid && (
              <ControlPanel
                testId="run-details-statistics-row"
                headerTitle="Statistics"
                headerTooltipContent={statisticsMessage.tooltip.content}
                headerTooltipDirection="left"
                headerTooltipExtraLinkUrl={statisticsMessage.tooltip.moreUrl}
                hasNoBorder
                isOpen
                stylesDetails={extraStylesControlPanel}
              >
                {renderRunOutputBlock({
                  altMessage: !isRunResolved
                    ? statisticsMessage.pending
                    : statisticsMessage.largeOutput,
                  fileNameCSV: `${runMetadata.id}-statistics.csv`,
                  shouldDisplayAltMessage: !isRunResolved,
                  tableHeaders: getRunOutputBlockTableHeaders({
                    propertyId: "statistics-property",
                    propertyName: "Statistics Property",
                    theme,
                    valueId: "statistics-value",
                    valueName: "Value",
                  }),
                  tableData: getRunOutputBlockTableData({
                    propertyId: "statistics-property",
                    valueId: "statistics-value",
                    data: modStatistics,
                  }),
                  theme,
                })}
              </ControlPanel>
            )}

            {isRunOptionsValid && (
              <ControlPanel
                testId="run-details-run-configuration-row"
                headerTitle="Run-defined configuration"
                headerTooltipContent={configRunMessage.tooltip.content}
                headerTooltipDirection="left"
                stylesDetails={extraStylesControlPanel}
                isOpen={!isStatisticsValid ? true : false}
              >
                {renderRunOutputBlock({
                  fileNameCSV: `${runMetadata.id}-run-config-options.csv`,
                  tableHeaders: getRunOutputBlockTableHeaders({
                    propertyId: "config-option",
                    propertyName: "Config Option",
                    theme,
                    valueId: "config-option-value",
                    valueName: "Value",
                  }),
                  tableData: getRunOutputBlockTableData({
                    propertyId: "config-option",
                    valueId: "config-option-value",
                    data: runOptions,
                  }),
                  theme,
                })}
              </ControlPanel>
            )}

            {isRunInstanceOptionsValid && (
              <ControlPanel
                testId="run-details-run-instance-configuration-row"
                headerTitle="Instance + run-defined configuration"
                headerTooltipContent={configRunInstanceMessage.tooltip.content}
                headerTooltipDirection="left"
                stylesDetails={extraStylesControlPanel}
                isOpen={!isStatisticsValid && !isRunOptionsValid ? true : false}
              >
                {renderRunOutputBlock({
                  fileNameCSV: `${runMetadata.id}-run-instance-config-options.csv`,
                  tableHeaders: getRunOutputBlockTableHeaders({
                    propertyId: "config-option",
                    propertyName: "Config Option",
                    theme,
                    valueId: "config-option-value",
                    valueName: "Value",
                  }),
                  tableData: getRunOutputBlockTableData({
                    propertyId: "config-option",
                    valueId: "config-option-value",
                    data: runInstanceOptions,
                  }),
                  theme,
                })}
              </ControlPanel>
            )}

            {(isOptionsValid || !isOutputBelowRenderThreshold) && (
              <ControlPanel
                testId="run-details-configuration-row"
                headerTitle="Configuration"
                headerTooltipContent={configMessage.tooltip.content}
                headerTooltipDirection="left"
                headerTooltipExtraLinkUrl={configMessage.tooltip.moreUrl}
                stylesDetails={extraStylesControlPanel}
                isOpen={
                  !isStatisticsValid &&
                  !isRunOptionsValid &&
                  !isRunInstanceOptionsValid
                    ? true
                    : false
                }
              >
                {renderRunOutputBlock({
                  altMessage: !isRunResolved
                    ? configMessage.pending
                    : configMessage.largeOutput,
                  fileNameCSV: `${runMetadata.id}-config-options.csv`,
                  shouldDisplayAltMessage:
                    !isRunResolved || !isOutputBelowRenderThreshold,
                  tableHeaders: getRunOutputBlockTableHeaders({
                    propertyId: "config-option",
                    propertyName: "Config Option",
                    theme,
                    valueId: "config-option-value",
                    valueName: "Value",
                  }),
                  tableData: getRunOutputBlockTableData({
                    propertyId: "config-option",
                    valueId: "config-option-value",
                    data: options,
                  }),
                  theme,
                })}
              </ControlPanel>
            )}

            {(isObjectiveValid || !isOutputBelowRenderThreshold) && (
              <ControlPanel
                testId="run-details-objective-row"
                headerTitle="Objective"
                headerTooltipContent={objectiveMessage.tooltip.content}
                headerTooltipDirection="left"
                stylesDetails={extraStylesControlPanel}
                isOpen={
                  !isStatisticsValid &&
                  !isRunOptionsValid &&
                  !isRunInstanceOptionsValid &&
                  !isOptionsValid
                    ? true
                    : false
                }
              >
                {renderRunOutputBlock({
                  altMessage: !isRunResolved
                    ? objectiveMessage.pending
                    : objectiveMessage.largeOutput,
                  fileNameCSV: `${runMetadata.id}-objective.csv`,
                  shouldDisplayAltMessage:
                    !isRunResolved || !isOutputBelowRenderThreshold,
                  tableHeaders: getRunOutputBlockTableHeaders({
                    propertyId: "objective-property",
                    propertyName: "Objective",
                    theme,
                    valueId: "objective-value",
                    valueName: "Value",
                  }),
                  tableData: getRunOutputBlockTableData({
                    propertyId: "objective-property",
                    valueId: "objective-value",
                    data: objective,
                  }),
                  theme,
                })}
              </ControlPanel>
            )}
          </StyledRunDetailsSidebar>
        )}
      </Flex>
    </StyledRunViewContainer>
  );
};

export default RunDetailsDetail;
