import React from "react";
import { useTheme } from "@emotion/react";

import Button2 from "../../../../../components/Button2";
import Flex from "../../../../../components/Flex";
import { BreakText } from "../../../../../components/PreviewRow2/PreviewRow2.styled";
import Tag from "../../../../../components/Tag";
import Text from "../../../../../components/Text";
import { IconMenuDots } from "../../../../../icons";
import { capitalizeFirstLetter } from "../../../../../utils/format";
import { rem } from "../../../../../utils/tools";
import {
  StyledMiniModal,
  StyledMiniModalActionItem,
  StyledMiniModalActionLink,
} from "../../../Team.styled";
import { RenderTeamMemberRoleParams, TeamMemberProps } from "../Members.types";

const TeamMember = ({
  userCanEditRole,
  userCanRemoveMembers,
  isUserThisMember,
  displayOptionsMenu,
  displayTeamMemberOptionsMenu,
  handleTeamMemberRemovalConfirmModal,
  handleTeamMemberEditRoleModal,
  handleOptionsMenu,
  teamMember,
  hasNoBorder,
}: TeamMemberProps) => {
  const theme = useTheme();

  const transformRole = (role: string) => {
    if (role === "member") {
      return "Developer";
    }
    return capitalizeFirstLetter(
      teamMember.role === "root" ? "owner" : teamMember.role
    );
  };

  const renderTeamMemberRole = ({ teamMember }: RenderTeamMemberRoleParams) => {
    if (teamMember.pending_invite) {
      return `Pending - ${transformRole(teamMember.role)}`;
    }
    return transformRole(teamMember.role);
  };

  const isPending = teamMember.pending_invite;

  return (
    <Flex
      width="100%"
      minHeight={rem(57)}
      pt={3}
      pb={3}
      alignItems="center"
      // flexDirection={["column", "row"]}
      hasBorderTop={hasNoBorder ? undefined : true}
      data-testid={"team-member-row"}
    >
      <Flex
        alignItems={["flex-start", "flex-start", "center"]}
        flexDirection={["column", "column", "row"]}
      >
        <Flex width={rem(400)} alignItems="center">
          <Text
            as="h3"
            styleName={isPending ? "body-2-italic" : "body-2-bold"}
            styles={{
              color: isPending ? theme.color.gray600 : theme.color.gray800,
            }}
          >
            <BreakText>{teamMember.email}</BreakText>
          </Text>
          {isUserThisMember && (
            <Tag
              mt={rem(2)}
              ml={1}
              textColor={theme.color.gray800}
              backgroundColor={theme.color.gray200}
              label="you"
            />
          )}
        </Flex>
      </Flex>

      <Flex ml="auto" alignItems="center" relative>
        <Text
          styleName={isPending ? "body-2-italic" : "body-2"}
          styles={{ color: theme.color.gray600 }}
        >
          {renderTeamMemberRole({ teamMember })}
        </Text>

        {displayTeamMemberOptionsMenu && (
          <StyledMiniModal>
            {userCanEditRole && (
              <StyledMiniModalActionItem>
                <StyledMiniModalActionLink
                  onClick={(e) =>
                    handleTeamMemberEditRoleModal({ e, teamMember })
                  }
                >
                  <span data-testid="team-edit-role-button">Edit role</span>
                </StyledMiniModalActionLink>
              </StyledMiniModalActionItem>
            )}
            <StyledMiniModalActionItem>
              <StyledMiniModalActionLink
                onClick={(e) =>
                  handleTeamMemberRemovalConfirmModal({ e, teamMember })
                }
                textColorHover={theme.color.red700}
              >
                <span data-testid="team-remove-button">Remove</span>
              </StyledMiniModalActionLink>
            </StyledMiniModalActionItem>
          </StyledMiniModal>
        )}

        {userCanRemoveMembers && teamMember.role !== "root" && (
          <Button2
            ml={3}
            type="outline-quiet"
            icon={<IconMenuDots iconSize={24} />}
            styles={{
              width: `calc(${theme.spacing.s7} + ${theme.spacing.s1})`,
              boxShadow: `inset 0 0 0 ${rem(1.5)} ${theme.color.gray400}`,
              svg: {
                fill: theme.color.gray600,
              },
              "&:hover": {
                boxShadow: `inset 0 0 0 ${rem(1.5)} ${theme.color.gray500}`,
                svg: {
                  fill: theme.color.gray800,
                },
              },
            }}
            onClick={(e: any) => handleOptionsMenu({ e, teamMember })}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default TeamMember;
