import React, { useState } from "react";

import {
  cancelRun,
  createRunDetails,
  createRunUploadMetadata,
  getRunDetailsWithOutputUrl,
  getRunEnsembleAnalysis,
  getRunLogs,
  getRunLogsLive,
  getRunMetadata,
  updateRunDetails,
  uploadFileToUrl,
} from "../../../api/core/controlPlane";
import {
  CreateRunPayload,
  EnsembleRunAnalysis,
  EnsembleRunAnalysisResponse,
  EntityErrorMessage,
  RunDetailsWithOutputUrl,
  RunLogs,
  RunLogsLive,
  RunLogsLiveResponse,
  RunLogsResponse,
  RunMetadata,
} from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";
import useDownloadAndUntar from "../../../utils/fileHandling/useDownloadAndUntar";
import { FUTUREparseTextAsJson } from "../../../utils/parseInputOutput";
import {
  AnyFunction,
  RunDetailsAction,
  RunDetailsActionKind,
  RunDetailsState,
} from "../App.context.types";

const useRunDetails = () => {
  const [{ id: accountId }] = useUser();

  const [runAddError, setRunAddError] = useState<EntityErrorMessage>(null);
  const [isRunAdded, setIsRunAdded] = useState(false);

  const [runDetailsWithOutputUrl, setRunDetailsWithOutputUrl] =
    useState<RunDetailsWithOutputUrl>(null);
  const [runDetailsWithOutputUrlError, setRunDetailsWithOutputUrlError] =
    useState<EntityErrorMessage>(null);

  const [runMetadata, setRunMetadata] = useState<RunMetadata>(null);
  const [runMetadataError, setRunMetadataError] =
    useState<EntityErrorMessage>(null);

  const [runLogsLive, setRunLogsLive] = useState<RunLogsLive>(null);
  const [runLogsLiveError, setRunLogsLiveError] =
    useState<EntityErrorMessage>(null);

  const [runLogs, setRunLogs] = useState<RunLogs>(null);
  const [runLogsError, setRunLogsError] = useState<EntityErrorMessage>(null);

  const [runEnsembleAnalysis, setRunEnsembleAnalysis] =
    useState<EnsembleRunAnalysis>(null);
  const [runEnsembleAnalysisError, setRunEnsembleAnalysisError] =
    useState<EntityErrorMessage>(null);

  const [runCancelError, setRunCancelError] =
    useState<EntityErrorMessage>(null);
  const [isRunCanceled, setIsRunCanceled] = useState(false);
  const [isSelectedGroupRunsCanceled, setIsSelectedGroupRunsCanceled] =
    useState(false);

  const downloadAndUntar = useDownloadAndUntar();

  const runDetailsReducer = (
    state: RunDetailsState,
    action: RunDetailsAction
  ): RunDetailsState => {
    switch (action.type) {
      case RunDetailsActionKind.ADD_RUN_DETAILS:
        return {
          ...state,
          loading: false,
          error: "",
        };
      case RunDetailsActionKind.CREATE_TEMPORARY_RUN_UPLOAD_METADATA:
        return {
          ...state,
          loading: false,
          error: "",
        };
      case RunDetailsActionKind.LOADING_RUN_DETAILS:
        return {
          ...state,
          loading: true,
          error: "",
        };
      case RunDetailsActionKind.SET_ERROR_RUN_DETAILS:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case RunDetailsActionKind.UPDATE_ONE_RUN_DETAILS:
        return {
          ...state,
          loading: false,
          error: "",
        };
      case RunDetailsActionKind.UPLOAD_FILE_FOR_RUN:
        return {
          ...state,
          loading: false,
          error: "",
        };
      default:
        throw new Error("run details action not supported");
    }
  };

  const [{ error, loading }, runDetailsDispatch] = React.useReducer(
    runDetailsReducer,
    {
      error: "",
      loading: false,
    }
  );

  const wrapFetch = <Func extends AnyFunction>(
    fn: Func,
    action: RunDetailsActionKind
  ): ((...args: Parameters<Func>) => any) => {
    const wrappedFn = async (...args: Parameters<Func>) => {
      runDetailsDispatch({ type: RunDetailsActionKind.LOADING_RUN_DETAILS });
      try {
        const resJson = await fn(...args);
        runDetailsDispatch({
          type: action,
          payload: resJson,
        });
        return resJson;
      } catch (e: any) {
        runDetailsDispatch({
          type: RunDetailsActionKind.SET_ERROR_RUN_DETAILS,
          payload: e.message,
        });
      }
    };
    return wrappedFn;
  };

  type AddRunDetailsParams = {
    appId: string;
    payload: CreateRunPayload;
    shouldReturnRun: boolean;
    instanceId?: string;
  };
  const addRunDetails = React.useCallback(
    async ({
      appId,
      instanceId,
      payload,
      shouldReturnRun = false,
    }: AddRunDetailsParams): Promise<any> => {
      try {
        const run = await createRunDetails(accountId || "")(
          appId,
          payload,
          instanceId
        );
        setRunAddError(null);
        setIsRunAdded(true);

        return shouldReturnRun ? run : undefined;
      } catch (error: any) {
        console.error(error);
        setRunAddError(error.toString());
      }
    },
    [accountId]
  );

  const createTemporaryRunUploadMetadata = React.useMemo(
    () =>
      wrapFetch(
        createRunUploadMetadata(accountId || ""),
        RunDetailsActionKind.CREATE_TEMPORARY_RUN_UPLOAD_METADATA
      ),
    [accountId]
  );

  const uploadFile = React.useMemo(
    () => wrapFetch(uploadFileToUrl, RunDetailsActionKind.UPLOAD_FILE_FOR_RUN),
    []
  );

  const editRunDetails = React.useMemo(
    () =>
      wrapFetch(
        updateRunDetails(accountId || ""),
        RunDetailsActionKind.UPDATE_ONE_RUN_DETAILS
      ),
    [accountId]
  );

  const clearRunDetailsActionError = React.useCallback(() => {
    runDetailsDispatch({
      type: RunDetailsActionKind.SET_ERROR_RUN_DETAILS,
      payload: "",
    });
  }, []);

  const cancelRunInQueue = React.useCallback(
    async (applicationId: string, runId: string) => {
      try {
        await cancelRun(accountId || "")(applicationId, runId);

        setRunCancelError(null);
        setIsRunCanceled(true);
        return;
      } catch (e: any) {
        console.error(e);
        setRunCancelError(e.toString());
      }
    },
    [accountId]
  );

  const cancelGroupRuns = React.useCallback(
    async (applicationId: string, runIds: string[]) => {
      for (const runId of runIds) {
        try {
          await cancelRun(accountId || "")(applicationId, runId);
        } catch (e: any) {
          console.error(e);
          setRunCancelError(e.toString());
          break;
        }
      }
      setIsSelectedGroupRunsCanceled(true);
    },
    [accountId]
  );

  const loadRunDetailsWithOutputUrl = React.useCallback(
    async (applicationId: string, runId: string) => {
      try {
        const runDetailsRes = await getRunDetailsWithOutputUrl(accountId || "")(
          applicationId,
          runId
        );
        const runDetails = runDetailsRes as RunDetailsWithOutputUrl;

        setRunDetailsWithOutputUrl(runDetails);
        setRunDetailsWithOutputUrlError(null);
        return runDetails;
      } catch (e: any) {
        console.error(e);
        setRunDetailsWithOutputUrlError(e.toString());
      }
    },
    [accountId]
  );

  const loadRunMetadata = React.useCallback(
    async (applicationId: string, runId: string): Promise<any | undefined> => {
      try {
        const runMetadataRes = await getRunMetadata(accountId || "")(
          applicationId,
          runId
        );
        const returnedRunMetadata = runMetadataRes as RunMetadata;

        setRunMetadata(returnedRunMetadata);
        setRunMetadataError(null);
        return returnedRunMetadata;
      } catch (e: any) {
        console.error(e);
        setRunMetadataError(e.toString());
      }
    },
    [accountId]
  );

  const loadRunLogsLive = React.useCallback(
    async (
      applicationId: string,
      runId: string,
      timeSince?: string
    ): Promise<void> => {
      try {
        const runLogsLiveRes = await getRunLogsLive(accountId || "")(
          applicationId,
          runId,
          timeSince
        );
        const returnedRunLogsLive = runLogsLiveRes as RunLogsLiveResponse;

        setRunLogsLive((prevState) => {
          return {
            ...returnedRunLogsLive,
            items: [
              ...(prevState ? prevState.items : []),
              ...returnedRunLogsLive.items,
            ],
          };
        });
        setRunLogsLiveError(null);
      } catch (e: any) {
        console.error(e);
        setRunLogsLiveError(e.toString());
      }
    },
    [accountId]
  );

  const loadRunLogs = React.useCallback(
    async (applicationId: string, runId: string): Promise<void> => {
      try {
        const runLogsRes = await getRunLogs(accountId || "")(
          applicationId,
          runId
        );
        const returnedRunLogs = runLogsRes as RunLogsResponse;

        setRunLogs(returnedRunLogs);
        setRunLogsError(null);
      } catch (e: any) {
        console.error(e);
        setRunLogsError(e.toString());
      }
    },
    [accountId]
  );

  const loadRunEnsembleAnalysis = React.useCallback(
    async (applicationId: string, runId: string): Promise<void> => {
      try {
        const runEnsembleAnalysisRes = await getRunEnsembleAnalysis(
          accountId || ""
        )(applicationId, runId);
        const returnedRunEnsembleAnalysis = runEnsembleAnalysisRes;

        // forward looking set up to where we have a parser to
        // preserve potential big and long numbers
        setRunEnsembleAnalysis(
          FUTUREparseTextAsJson(
            returnedRunEnsembleAnalysis as unknown as string
          ) as EnsembleRunAnalysisResponse
        );
        setRunEnsembleAnalysisError(null);
      } catch (e: any) {
        console.error(e);
        setRunEnsembleAnalysisError(e.toString());
      }
    },
    [accountId]
  );

  const loadGzippedRunOutput = React.useCallback(
    async (downloadUrl, runId) => {
      try {
        await downloadAndUntar(downloadUrl, runId);
      } catch (e: any) {
        console.error(e);
        setRunDetailsWithOutputUrlError(e.toString());
      }
    },
    [downloadAndUntar]
  );

  return {
    addRunDetails,
    cancelGroupRuns,
    cancelRunInQueue,
    clearRunDetailsActionError,
    createTemporaryRunUploadMetadata,
    editRunDetails,
    isRunAdded,
    isRunCanceled,
    isSelectedGroupRunsCanceled,
    loadGzippedRunOutput,
    loadRunEnsembleAnalysis,
    loadRunLogs,
    loadRunLogsLive,
    loadRunMetadata,
    loadRunDetailsWithOutputUrl,
    runAddError,
    runCancelError,
    runDetailsActionLoading: loading,
    runDetailsActionError: error,
    runDetailsWithOutputUrl,
    runDetailsWithOutputUrlError,
    runEnsembleAnalysis,
    runEnsembleAnalysisError,
    runLogs,
    runLogsError,
    runLogsLive,
    runLogsLiveError,
    runMetadata,
    runMetadataError,
    setIsRunCanceled,
    setRunMetadataError,
    setIsSelectedGroupRunsCanceled,
    uploadFile,
  };
};

export default useRunDetails;
