import { rem } from "../utils/tools";

// default styles for text links
const codeInlineStyle = (theme: any) => `
  padding-top: ${rem(2)};
  padding-right: ${theme.spacing.s1};
  padding-bottom: ${rem(2)};
  padding-left: ${theme.spacing.s1};
  font-family: ${theme.ui2Typography.fontFamilyCodeBold};
  font-size: ${theme.ui2Typography.fontSizeCodeBold};
  font-style: ${theme.ui2Typography.fontStyleCodeBold};
  font-weight: ${theme.ui2Typography.fontWeightCodeBold};
  letter-spacing: ${theme.ui2Typography.letterSpacingCodeBold};
  text-transform: ${theme.ui2Typography.textTransformCodeBold};
  color: ${theme.color.gray800};
  border-radius: ${theme.border.radiusSmall};
  background-color: ${theme.color.gray200};
`;

// TODO: need to figure out how to import Theme type (see above)
export const styleCodeInline = (theme: any) => {
  return `
    code {
      ${codeInlineStyle(theme)}
    }
  `;
};

export const styleAsCodeInline = (theme: any) => {
  return `
    ${codeInlineStyle(theme)}
  `;
};
