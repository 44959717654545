import { ParsedFeatureFlags } from "../../../api/core/dataPlane.types";
import { PageNavigationItem } from "../../../components/MainNav/MainNav.types";

type GetAppsPagesParams = {
  userId: string;
  features?: ParsedFeatureFlags;
};

export const getAppsPages = ({
  features,
  userId,
}: GetAppsPagesParams): PageNavigationItem[] => [
  {
    id: "all-apps",
    label: "All",
    url: `/acc/${userId}/apps`,
  },
  {
    id: "custom-apps",
    label: "Custom",
    url: `/acc/${userId}/apps/custom`,
  },
  {
    id: "subscription-apps",
    label: "Subscription",
    url: `/acc/${userId}/apps/subscription`,
  },
  {
    id: "webhook-apps",
    label: "Webhooks",
    url: `/acc/${userId}/apps/webhooks`,
  },
];
