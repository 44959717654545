import React from "react";
import { useTheme } from "@emotion/react";

import Box from "../../components/Box";
import Flex from "../../components/Flex";
import Text from "../Text";

import { StyledBin, StyledTitleLink } from "./Bin.styles";
import { BinProps } from "./Bin.types";

const Bin = ({
  borderColor,
  children,
  isMini,
  onClick,
  styles,
  title,
  titleIcon,
  titleUrl,
  // styled-system props
  m,
  mt,
  mr,
  mb,
  ml,
  maxWidth,
  minWidth,
  width,
}: BinProps) => {
  const theme = useTheme();

  const renderTitleContent = () => {
    if (typeof title === "string") {
      return (
        <Flex mb={2} alignItems="center">
          {titleIcon && <Box>{titleIcon}</Box>}
          <Text
            as="h3"
            ml={titleIcon ? 2 : 0}
            styleName="header-1"
            styles={{
              color: theme.color.gray800,
            }}
          >
            {title}
          </Text>
        </Flex>
      );
    }
    return title;
  };

  const renderTitle = () => {
    if (titleUrl) {
      return (
        <StyledTitleLink to={titleUrl} {...{ onClick }}>
          {renderTitleContent()}
        </StyledTitleLink>
      );
    }

    return renderTitleContent();
  };

  return (
    <StyledBin
      {...{
        isMini,
        m,
        mt,
        mr,
        mb,
        ml,
        maxWidth,
        minWidth,
        styles,
        width,
      }}
      pt={isMini ? 2 : 5}
      pr={isMini ? 4 : 4}
      pb={isMini ? 3 : 6}
      pl={isMini ? 3 : 6}
      flexDirection="column"
      borderColor={borderColor || theme.color.gray200T}
    >
      {title && renderTitle()}

      {children}
    </StyledBin>
  );
};

export default Bin;
