import React from "react";
import { CaptionProps, useNavigation } from "react-day-picker";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import Flex from "../../../components/Flex";
import { IconChevronLeft, IconChevronRight } from "../../../icons";
import Text from "../../Text";
import { StyledNavButton } from "../DatePicker.styled";
import { navigateToMonth } from "../utils/monthNavigator";

const CustomCaptionRange = (props: CaptionProps) => {
  const theme = useTheme();
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  const { displayMonth, displayIndex } = props;
  const isPrevDisabled = !previousMonth;
  const isNextDisabled = !nextMonth;

  return (
    <Flex mb={2} pb={3} alignItems="center" hasBorderBottom>
      {displayIndex === 0 && (
        <StyledNavButton
          isDisabled={isPrevDisabled}
          aria-disabled={isPrevDisabled}
          disabled={isPrevDisabled}
          onClick={(e) =>
            navigateToMonth({ e, goToMonth, month: previousMonth })
          }
        >
          <IconChevronLeft iconSize={20} iconColor={theme.color.gray500T} />
        </StyledNavButton>
      )}

      <Text
        as="h4"
        mr={displayIndex === 1 ? 3 : 0}
        ml={displayIndex === 1 ? "auto" : 3}
        styleName="body-1-bold"
        styles={{ color: theme.color.gray800 }}
      >
        {DateTime.fromJSDate(displayMonth).toFormat("LLLL yyyy")}
      </Text>

      {displayIndex === 1 && (
        <StyledNavButton
          isDisabled={isNextDisabled}
          aria-disabled={isNextDisabled}
          disabled={isNextDisabled}
          onClick={(e) => navigateToMonth({ e, goToMonth, month: nextMonth })}
        >
          <IconChevronRight iconSize={20} iconColor={theme.color.gray500T} />
        </StyledNavButton>
      )}
    </Flex>
  );
};

export default CustomCaptionRange;
