import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import { trackEvent } from "../../../analytics";
import { RunProfile } from "../../../api/core/controlPlane.types";
import { AvatarRunProfile } from "../../../avatars";
import Box from "../../../components/Box";
import Header from "../../../components/Header";
import { useMetaTitle } from "../../../components/Layout";
import Loading from "../../../components/Loading";
import Notification from "../../../components/Notification";
import PreviewRow2 from "../../../components/PreviewRow2";
import Text from "../../../components/Text";
import { useRunProfiles } from "../../../contexts/runProfiles/RunProfiles.context";
import { rem } from "../../../utils/tools";
import RunProfileDeprecationNotice from "../components/RunProfileDeprecationNotice";
import { runProfileTooltipCopy } from "../data/microcopy";

const pageTitle = "Run Profiles";

const RunProfiles = () => {
  const [, setMetaTitle] = useMetaTitle();
  const { path } = useRouteMatch();
  const {
    getFilteredProfiles,
    fetchRunProfiles,
    runProfilesError,
    runProfilesLoading,
  } = useRunProfiles();

  const [filterText, setFilterText] = useState("");
  const runProfiles = getFilteredProfiles(filterText);
  const [retried, setRetried] = useState(false);

  useEffect(() => {
    if (getFilteredProfiles("").length === 0 && !retried) {
      fetchRunProfiles();
      setRetried(true);
    }
  }, [fetchRunProfiles, getFilteredProfiles, retried]);

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  return (
    <>
      {runProfilesError ? (
        <Notification
          mt={6}
          type="error"
          message={`Error: ${runProfilesError}`}
          hasContactExtra
        />
      ) : runProfilesLoading ? (
        <Loading type="full-screen" />
      ) : (
        <>
          <RunProfileDeprecationNotice />

          <Header
            configActionButton={{
              label: "Create run profile",
              url: "/config/run-profiles/new",
              onClick: () =>
                trackEvent("Config", {
                  view: "Run Profiles",
                  action: "Create Run Profile Button Clicked",
                }),
              isActionAllowed: true,
            }}
            configPageTitle={{ label: pageTitle }}
            configFilter={
              !runProfiles.length && !filterText
                ? undefined
                : {
                    inputText: filterText,
                    testId: "filter-run-profiles",
                    setInputText: setFilterText,
                  }
            }
          />

          <Box data-testid="run-profiles-container" mt={rem(-1)}>
            {runProfiles.length || filterText ? (
              runProfiles.map((runProfile: RunProfile) => (
                <PreviewRow2
                  key={runProfile.id}
                  icon={
                    <Box mt={1}>
                      <AvatarRunProfile size={36} />
                    </Box>
                  }
                  name={runProfile.name}
                  id={runProfile?.description}
                  url={`${path}/${runProfile.id}`}
                  urlOnClick={() =>
                    trackEvent("Config", {
                      view: "Run Profiles",
                      action: "Link Clicked",
                      meta: {
                        type: "Run Profile Detail",
                      },
                    })
                  }
                  urlEdit={`${path}/${runProfile.id}/edit?source=list`}
                  urlEditOnClick={() =>
                    trackEvent("Config", {
                      view: "Run Profiles",
                      action: "Link Clicked",
                      meta: {
                        type: "Run Profile Edit",
                      },
                    })
                  }
                />
              ))
            ) : (
              <Box maxWidth={rem(720)}>
                <Text mt={2} styleName="body-1" hasLink>
                  Use the create run profile button to add your first run
                  profile. Run profiles are used to configure application runs
                  made with the API.{" "}
                  <a
                    href={runProfileTooltipCopy.runProfiles.extraLinkUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read more in Nextmv Docs
                  </a>
                </Text>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default RunProfiles;
