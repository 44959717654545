import React from "react";
import { useTheme } from "@emotion/react";

import { EnsembleRunGroups as EnsembleRunGroupsType } from "../../../../../api/core/controlPlane.types";
import { useUser } from "../../../../../AuthProvider";
import Box from "../../../../../components/Box";
import ControlPanel from "../../../../../components/ControlPanel";
import Flex from "../../../../../components/Flex";
import Tag from "../../../../../components/Tag";
import Text from "../../../../../components/Text";
import { getAccUrl } from "../../../../../utils/navigation";
import { rem } from "../../../../../utils/tools";
import {
  checkIsRunOutputBlockValid,
  getRunOutputBlockTableData,
  getRunOutputBlockTableHeaders,
  renderRunOutputBlock,
} from "../../RunDetails/utils/runOutputBlocks";

type EnsembleRunGroupsProps = {
  appId: string;
  runGroups: EnsembleRunGroupsType;
};

const EnsembleRunGroups = ({ appId, runGroups }: EnsembleRunGroupsProps) => {
  const [{ id: accountId }] = useUser();
  const theme = useTheme();

  return (
    <>
      {runGroups.map((runGroup, index) => {
        const isFirst = index === 0;

        const {
          id: runGroupId,
          instance_id: instanceId,
          options,
          repetitions,
        } = runGroup;

        const isEnsembleOptionsValid = checkIsRunOutputBlockValid(options);

        return (
          <ControlPanel
            key={runGroupId}
            headerTitle={runGroupId}
            isOpen
            hasCustomContentWrapper
            hasNoBorder={index === 0}
            stylesDetails={{
              marginTop: isFirst ? theme.spacing["-s4"] : 0,
              paddingRight: 0,
              paddingLeft: 0,
            }}
          >
            <Box ml={1} pl={7}>
              <Flex alignItems="center" mt={3} pt={3} hasBorderTop>
                <Text
                  styleName="body-2"
                  styles={{ width: rem(100), shrink: 0 }}
                >
                  Instance ID
                </Text>
                <Tag
                  ml={2}
                  type="id"
                  label={instanceId}
                  contentToCopy={instanceId}
                  url={getAccUrl(
                    accountId,
                    `/app/${appId}/instance/${instanceId}`
                  )}
                />
              </Flex>

              <Flex alignItems="center" mt={3} pt={3} hasBorderTop>
                <Text
                  styleName="body-2"
                  styles={{ width: rem(100), shrink: 0 }}
                >
                  Repetitions
                </Text>
                <Text styleName="body-2" ml={2}>
                  {repetitions}
                </Text>
              </Flex>

              {isEnsembleOptionsValid && (
                <Box mt={3} pt={3} hasBorderTop>
                  <Text styleName="body-2">Configuration</Text>

                  {renderRunOutputBlock({
                    tableHeaders: getRunOutputBlockTableHeaders({
                      propertyId: "config-option",
                      propertyName: "Option",
                      theme,
                      valueId: "config-option-value",
                      valueName: "Value",
                    }),
                    tableData: getRunOutputBlockTableData({
                      propertyId: "config-option",
                      valueId: "config-option-value",
                      data: options,
                    }),
                    theme,
                  })}
                </Box>
              )}
            </Box>
          </ControlPanel>
        );
      })}
    </>
  );
};

export default EnsembleRunGroups;
