import React from "react";

import Notification from "../../../components/Notification";

const RunProfileDeprecationNotice = () => {
  return (
    <Notification
      mt={6}
      mb={2}
      type="notice"
      size="small"
      message="Run profiles are no longer the recommended method for running and configuring decision models. Use applications instead."
    />
  );
};

export default RunProfileDeprecationNotice;
