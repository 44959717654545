import React from "react";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../analytics";
import Text from "../../../components/Text";

const TermsAgreement = () => {
  const theme = useTheme();

  return (
    <Text
      mt={4}
      styleName="body-2"
      hasLinkGray
      styles={{
        color: theme.color.gray600,
        a: {
          borderBottomWidth: theme.border.width,
          borderBottomStyle: theme.border.style,
          borderBottomColor: theme.color.gray200,
        },
      }}
    >
      By signing up, you agree to the{" "}
      <a
        href="https://www.nextmv.io/terms-of-use"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          trackEvent("AuthFlow", {
            view: "Sign Up",
            action: "Terms of Use Link Clicked",
          });
        }}
      >
        Terms of Use
      </a>
      .
    </Text>
  );
};

export default TermsAgreement;
