import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Flex from "../../components/Flex";
import { CSSStylesProps } from "../../utils/renderExtraStyles";

import { StyledTagProps } from "./Tag.types";

/*
  Note: the styles prop is run through Emotions `css` function
  which returns it as an object with the original styles processed
  to a `styles` prop (the same key names are a coincidence).
*/
const renderExtraStyles = (styles: CSSStylesProps) => {
  return css(styles).styles;
};

export const StyledTag = styled(Flex)<StyledTagProps>`
  display: inline-flex;
  border-radius: ${({ theme }) => theme.border.radiusSmall};

  /* extra styles */
  ${(props) => props.styles && renderExtraStyles(props.styles)}
`;
