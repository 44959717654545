import styled from "@emotion/styled";

import { rem } from "../../../../utils/tools";

export const ShowAPIControl = styled.a`
  display: flex;
  align-items: center;
  min-width: ${rem(76)};
  cursor: pointer;
`;
