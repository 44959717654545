import { Link } from "react-router-dom";

import { trackEvent } from "../../../../../analytics";
import {
  AppInputsData,
  AppResponse,
} from "../../../../../api/core/controlPlane.types";
import { AvatarAppInput } from "../../../../../avatars";
import Box from "../../../../../components/Box";
import PreviewRow2 from "../../../../../components/PreviewRow2";
import Text from "../../../../../components/Text";
import { getAccUrl } from "../../../../../utils/navigation";
import { rem } from "../../../../../utils/tools";

type RenderAppInputsListParams = {
  app: AppResponse;
  appInputs: AppInputsData;
  canUserCreateAndEditAppInputs: boolean;
  accountId?: string | null;
  isMini?: boolean;
  limit?: number;
  userCanCreateAppInput?: boolean;
};

export const renderAppInputsList = ({
  accountId,
  app,
  appInputs,
  canUserCreateAndEditAppInputs,
  isMini,
  limit,
}: RenderAppInputsListParams) => {
  if (!appInputs) return;

  if (!appInputs.length) {
    return (
      <Text
        testId="app-inputs-not-found-message"
        mt={isMini ? 3 : 5}
        styleName={isMini ? "body-3" : "body-2"}
        hasLink
      >
        No app inputs found.{" "}
        {isMini && canUserCreateAndEditAppInputs && (
          <Link
            to={getAccUrl(accountId, `/app/${app.id}/experiments/inputs/new`)}
          >
            Create new app input
          </Link>
        )}
      </Text>
    );
  }

  const returnedAppInputs = limit ? appInputs.slice(0, limit) : appInputs;

  return returnedAppInputs.map((appInput, index) => {
    return (
      <PreviewRow2
        {...{ isMini }}
        key={`${appInput.id}-${index}`}
        hasNoBorder={index === 0}
        icon={
          <Box mt={rem(1)}>
            <AvatarAppInput size={isMini ? 20 : 24} />
          </Box>
        }
        name={appInput.name}
        id={appInput.id}
        lastUpdated={appInput?.updated_at}
        url={getAccUrl(
          accountId,
          `/app/${app.id}/experiments/input/${appInput.id}`
        )}
        urlOnClick={() =>
          trackEvent("AppInputs", {
            view: "App Inputs",
            action: "Link Clicked",
            meta: {
              type: "App Input Detail",
            },
          })
        }
        {...(!isMini && {
          description: appInput?.description,
        })}
        {...(!isMini &&
          canUserCreateAndEditAppInputs && {
            urlEdit: getAccUrl(
              accountId,
              `/app/${app.id}/experiments/input/${appInput.id}/edit?source=list`
            ),
            urlEditOnClick: () =>
              trackEvent("AppInputs", {
                view: "App Inputs",
                action: "Link Clicked",
                meta: {
                  type: "App Input Edit",
                },
              }),
          })}
      />
    );
  });
};
