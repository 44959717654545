import { useEffect, useMemo, useRef } from "react";
import { parse, stringify } from "lossless-json-no-nullish-coalescing-operator";
import {
  ContextMenuItem,
  JSONEditor,
  JSONEditorPropsOptional,
  Mode,
  RenderMenuContext,
} from "vanilla-jsoneditor";

import { StyledEditor } from "./Editor.styled";

const losslessJSONParser = { parse, stringify };

const editorDefaults: Partial<JSONEditorPropsOptional> = {
  // set default mode
  mode: "text" as Mode,

  // controls to switch mode, expand/collapse, search
  mainMenuBar: true,
  navigationBar: false,

  // line/col number reference in the footer
  statusBar: true,

  // editor style
  indentation: 2,
  tabSize: 2,
  askToFormat: false,

  // custom parser to avoid number corruption
  parser: losslessJSONParser,
};

const Editor = (props: JSONEditorPropsOptional) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const refEditor = useRef<JSONEditor | null>(null);

  const editorProps = useMemo(() => {
    return { ...editorDefaults, ...props };
  }, [props]);

  // create editor
  useEffect(() => {
    refEditor.current = new JSONEditor({
      target: refContainer.current!,
      props: {
        onRenderContextMenu: handleRenderContextMenu,
      },
    });

    // destroy editor
    return () => {
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, []);

  // update props
  useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps(editorProps);
    }
  }, [editorProps]);

  // disable context menu that is displayed when you right
  // click anywhere in the editor while in tree mode
  // contains features that we do not want at this time
  const handleRenderContextMenu = (
    _items: ContextMenuItem[],
    _context: RenderMenuContext
  ): ContextMenuItem[] | undefined => {
    return [];
  };

  return (
    <StyledEditor width="100%" height="100%">
      <div ref={refContainer} />
    </StyledEditor>
  );
};

export default Editor;
