import { DateTime } from "luxon";

import Button2 from "../../../components/Button2";
import Flex from "../../../components/Flex";
import Notification from "../../../components/Notification";
import Text from "../../../components/Text";
import {
  EXPERIMENT_STATUS_CANCELED,
  EXPERIMENT_STATUS_CANCELLED,
  EXPERIMENT_STATUS_COMPLETED,
  EXPERIMENT_STATUS_DRAFT,
  EXPERIMENT_STATUS_STARTED,
  TEST_ACTION_CANCEL,
  TEST_ACTION_COMPLETE,
  TEST_ACTION_START,
} from "../../../config/experiments";
import {
  HideActions,
  RenderActionContentParams,
  RenderResultsNoteParams,
  StartStopModalType,
} from "../types/Experiment.types";

function isActionHidden(
  action: StartStopModalType,
  hidden?: HideActions
): boolean {
  if (hidden === undefined) {
    return false;
  }

  switch (action) {
    case TEST_ACTION_CANCEL:
      return !!hidden.cancel;
    case TEST_ACTION_COMPLETE:
      return !!hidden.complete;
    default:
      return false;
  }
}

export const renderActionContent = ({
  handleStartStopClick,
  status,
  hiddenActions,
}: RenderActionContentParams): React.ReactNode => {
  if (status === EXPERIMENT_STATUS_DRAFT) {
    return (
      <Button2
        label="Start test"
        onClick={() => handleStartStopClick(TEST_ACTION_START)}
      />
    );
  }

  if (status === EXPERIMENT_STATUS_STARTED) {
    return (
      <Flex>
        {!isActionHidden(TEST_ACTION_COMPLETE, hiddenActions) && (
          <Button2
            mr={2}
            label="Complete Test"
            onClick={() => handleStartStopClick(TEST_ACTION_COMPLETE)}
          />
        )}

        {!isActionHidden(TEST_ACTION_CANCEL, hiddenActions) && (
          <Button2
            type="outline"
            label="Cancel Test"
            onClick={() => handleStartStopClick(TEST_ACTION_CANCEL)}
          />
        )}
      </Flex>
    );
  }

  if (
    status === EXPERIMENT_STATUS_COMPLETED ||
    status === EXPERIMENT_STATUS_CANCELLED ||
    status === EXPERIMENT_STATUS_CANCELED
  ) {
    return (
      <Text mt={-1} styleName="body-2">
        Test was {status}.
      </Text>
    );
  }

  return null;
};

const isDatePast = (date: string) => {
  return DateTime.fromISO(date) < DateTime.fromJSDate(new Date());
};

export const renderResultsNote = ({
  hasResults,
  startCriteria,
  status,
  theme,
}: RenderResultsNoteParams): React.ReactNode => {
  if (status === EXPERIMENT_STATUS_DRAFT) {
    return (
      <Text mt={3} styleName="body-2" styles={{ color: theme.color.gray600 }}>
        Shadow test has not started.
      </Text>
    );
  }

  if (status === EXPERIMENT_STATUS_STARTED && !hasResults) {
    return (
      <>
        <Text mt={3} styleName="body-2" styles={{ color: theme.color.gray600 }}>
          Test results will appear here as runs are made.
        </Text>

        {startCriteria && !isDatePast(startCriteria.value) && (
          <Flex>
            <Notification
              mt={4}
              type="notice"
              message="This test will not begin collecting data until the date specified in the start criteria."
            />
          </Flex>
        )}
      </>
    );
  }

  if (
    (status === EXPERIMENT_STATUS_COMPLETED ||
      status === EXPERIMENT_STATUS_CANCELED ||
      status === EXPERIMENT_STATUS_CANCELLED) &&
    !hasResults
  ) {
    return (
      <Text mt={3} styleName="body-2" styles={{ color: theme.color.gray600 }}>
        No results.
      </Text>
    );
  }
};
