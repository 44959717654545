import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { startCase } from "lodash";

import { RunsData } from "../../../api/core/controlPlane.types";
import Button2 from "../../../components/Button2";
import Table2 from "../../../components/Table2";
import {
  HeaderObj,
  Table2HeaderObj,
} from "../../App/subpages/RunHistory/RunHistory.types";
import { getRunHistoryItems } from "../../App/subpages/RunHistory/utils/getRunHistoryItems";
import { getTableLayoutSchemaExperimentRunHistory } from "../../App/subpages/RunHistory/utils/tableLayoutSchemas";

import ExperimentDetailsSection from "./ExperimentDetailsSection";

type ExperimentRunsProps = {
  kind: "batch" | "ensemble" | "shadow" | "switchback";
  experimentRuns: RunsData;
  runHistoryHeaders: Table2HeaderObj[];
  statisticsHeaders?: HeaderObj[];
  displayLoadMore?: boolean;
  isLoading?: boolean;
  loadMoreHandler?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  scenarioConfigOptionsHeaders?: HeaderObj[];
};
const ExperimentRuns = ({
  displayLoadMore = false,
  experimentRuns,
  isLoading,
  kind,
  loadMoreHandler,
  runHistoryHeaders,
  scenarioConfigOptionsHeaders,
  statisticsHeaders,
}: ExperimentRunsProps) => {
  const params = useParams() as { id: string };
  const isBatch = kind === "batch";

  const experimentRunsData = useMemo(() => {
    return getRunHistoryItems({
      hasName: false,
      hasStatistics: true,
      runHistory: experimentRuns,
      scenarioConfigOptionsHeaders,
      statsHeaders: statisticsHeaders,
      showPlanUnitIndex: kind === "switchback",
    });
  }, [experimentRuns, kind, scenarioConfigOptionsHeaders, statisticsHeaders]);

  const getSectionTitle = () => {
    if (kind === "ensemble") return "All child runs";
    if (isBatch) return "Experiment runs";
    return `${startCase(kind)} Test Runs`;
  };

  return (
    <ExperimentDetailsSection title={getSectionTitle()}>
      <Table2
        isWide
        canFilter
        canSort
        useMinWidth
        isStickyColumn
        headers={runHistoryHeaders}
        data={experimentRunsData || []}
        layoutSchema={getTableLayoutSchemaExperimentRunHistory(experimentRuns)}
        emptyMessage="No runs available"
        fileNameCSV={`${isBatch ? "experiment" : kind}_runs_${params.id}`}
        showCSVLink
      />
      {displayLoadMore && loadMoreHandler && (
        <Button2
          mt={2}
          label="Load more"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            loadMoreHandler(e)
          }
          isLoading={!!isLoading}
        />
      )}
    </ExperimentDetailsSection>
  );
};

export default ExperimentRuns;
