import React from "react";
import { useTheme } from "@emotion/react";

import Box from "../../components/Box";
import Details from "../../components/Details";
import Table2 from "../../components/Table2";
import { rem } from "../../utils/tools";
import { TableData } from "../Table2/Table2.types";
import Text from "../Text";

import {
  getOptionSetHeaders,
  getOptionsString,
  optionSetTableLayoutSchema,
} from "./utils/optionSetTable";
import {
  OptionSetDefinitions,
  ScenarioDefinitionsProps,
} from "./ScenarioDefinitions.types";

const ScenarioDefinitions = ({
  scenarioDefinitions,
}: ScenarioDefinitionsProps) => {
  const theme = useTheme();

  if (!scenarioDefinitions) {
    return <></>;
  }

  const optionSetHeaders = getOptionSetHeaders();

  const renderOptionSets = (optionSetDefinitions: OptionSetDefinitions) => {
    const optionSetsTableData: TableData[] = [];

    // fill in table data for scenario option sets
    Object.keys(optionSetDefinitions).forEach((optionSetId) => {
      const { inputSetId, instanceId, options, versionId } =
        optionSetDefinitions[optionSetId];

      optionSetsTableData.push({
        inputSetId: inputSetId || "",
        instanceId,
        options: getOptionsString(options),
        optionSetId,
        versionId,
      });
    });

    return (
      <Table2
        width="100%"
        headers={optionSetHeaders}
        data={optionSetsTableData}
        layoutSchema={optionSetTableLayoutSchema}
      />
    );
  };

  return (
    <Box mt={rem(-2)}>
      {Object.keys(scenarioDefinitions).map((scenarioId, index) => {
        const isFirst = index === 0;
        return (
          <Details
            key={scenarioId}
            hasBorderTop={!isFirst}
            controlPosition="left"
            summary={
              <Text as="h4" styleName="body-2-bold">
                {scenarioId}
              </Text>
            }
            styles={{
              paddingTop: isFirst
                ? `0 !important`
                : `${theme.spacing.s2} !important`,
              paddingBottom: `${theme.spacing.s2} !important`,
            }}
          >
            {renderOptionSets(scenarioDefinitions[scenarioId])}
          </Details>
        );
      })}
    </Box>
  );
};

export default ScenarioDefinitions;
