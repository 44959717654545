import {
  AppInstanceResponseAuto,
  AppResponse,
} from "../../../../../api/core/controlPlane.types";
import { maskUserEmail } from "../../../../../utils/user";

export const getAutoInstance = (app: AppResponse) => {
  const executable = app.active_executable;
  if (executable) {
    const autoInstance: AppInstanceResponseAuto = {
      name: "Latest",
      id: "devint",
      isAuto: true,
      application_id: app.id,
      executable: `${maskUserEmail(executable.user_email)} - ${
        executable.uploaded_at
      }`,
      description:
        "Auto-created instance to manage the latest pushed executable binary.",
      locked: false,
      version_id: "",
      created_at: executable.uploaded_at,
      updated_at: executable.uploaded_at,
    };

    return autoInstance;
  }
};
