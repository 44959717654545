import React from "react";

import Notification from "../Notification";

import { StandardErrorProps } from "./StandardError.types";

const StandardError = ({ errorMessage }: StandardErrorProps) => {
  return (
    <Notification
      mt={6}
      type="error"
      message={errorMessage || ""}
      hasContactExtra
    />
  );
};

export default StandardError;
