import React from "react";
import { useTheme } from "@emotion/react";

import { useUser } from "../../../AuthProvider";
import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import Text from "../../../components/Text";
import { getAccUrl } from "../../../utils/navigation";
import { rem } from "../../../utils/tools";
import {
  ROUTING_LEGACY_APP_ID,
  ROUTING_LEGACY_PARTNER_ID,
} from "../data/marketAppLegacyRouting";
import { MarketplaceAppConstraintTableProps } from "../MarketplaceApp.types";

const MarketplaceAppFeatureTable = ({
  constraintData,
  docsLink,
  subscriptionId,
}: MarketplaceAppConstraintTableProps) => {
  const [{ id: accountId }] = useUser();
  const theme = useTheme();

  return (
    <Flex pt={6} pr={[4, 6, 9]} pl={[2, 6, 7]} flexDirection="column">
      <Flex
        flexDirection={["column", "column", "column", "row"]}
        alignItems={["flex-start", "flex-start", "flex-start", "baseline"]}
      >
        <Text
          as="h2"
          styleName="header-2"
          styles={{ color: theme.color.gray800 }}
        >
          Available constraints
        </Text>

        {docsLink && (
          <Text
            as="span"
            mt={[1, 1, 1, 0]}
            ml={[0, 0, 0, 3]}
            styleName="body-3"
            hasLinkBlack
            styles={{ color: theme.color.gray600 }}
          >
            Complete details in{" "}
            <a href={docsLink} target="_blank" rel="noreferrer">
              Nextmv Docs
            </a>
          </Text>
        )}

        {subscriptionId === "nextmv-nextroute" && (
          <Text
            mt={[3, 3, 3, 0]}
            ml={[0, 0, 0, "auto"]}
            styleName="body-3"
            hasLinkGray
          >
            <a
              href={getAccUrl(
                accountId,
                `/marketplace/app/${ROUTING_LEGACY_PARTNER_ID}/${ROUTING_LEGACY_APP_ID}`
              )}
            >
              Nextmv Routing Legacy App
            </a>
          </Text>
        )}
      </Flex>

      <Box mt={4}>
        {constraintData.map((constraint) => (
          <Flex
            key={constraint.id}
            alignItems="baseline"
            hasBorderTop
            flexDirection={["column", "column", "column", "row"]}
          >
            <Box pt={[3, 3, 3, 2]} pb={[0, 0, 0, 2]} width={rem(312)} noShrink>
              <Text
                styleName="code"
                hasLinkBlack
                styles={{
                  color: theme.color.gray800,
                  textRendering: "auto !important",
                  WebkitFontSmoothing: "subpixel-antialiased !important",
                  a: {
                    borderBottomWidth: "0 !important",
                    "&:hover": {
                      borderBottomWidth: `${theme.border.width} !important`,
                    },
                  },
                }}
              >
                <a href={constraint.docsUrl} target="_blank" rel="noreferrer">
                  {constraint.name}
                </a>
              </Text>
            </Box>

            <Box pt={[0, 0, 0, 2]} pb={[0, 0, 0, 2]} width={rem(192)} noShrink>
              <Text
                as="em"
                styleName="body-2-italic"
                styles={{ color: theme.color.gray600 }}
              >
                {constraint.type}
              </Text>
            </Box>
            <Box pt={[1, 1, 1, 2]} pb={[3, 3, 3, 2]}>
              <Text styleName="body-2">{constraint.summary}</Text>
            </Box>
          </Flex>
        ))}
      </Box>
    </Flex>
  );
};

export default MarketplaceAppFeatureTable;
