import {
  AvatarGoogleMaps,
  AvatarHere,
  AvatarOnfleet,
  AvatarStreets,
} from "../../../avatars";
import { AvailableIntegrationPreview } from "../Marketplace.types";

export const getAvailableIntegrations = (
  accId?: string | null
): AvailableIntegrationPreview[] => {
  return [
    {
      id: "solver-ampl",
      name: "AMPL",
      description:
        "Commercial solver interface that connects to most commercial and open-source solvers and allows you to switch easily between them.",
      tags: ["solver"],
      avatarUrl: "/images/logos/ampl-icon.svg",
      isMoreUrlExternal: true,
      moreLabel: "View docs",
      moreUrl:
        "https://www.nextmv.io/docs/platform/execution-environment/supported-solvers#ampl",
      moreTrackEvent: {
        view: "Marketplace Available Integrations",
        action: "More Details Clicked",
        meta: {
          type: "AMPL",
        },
      },
    },
    {
      id: "solver-gurobi",
      name: "Gurobi",
      description:
        "Commercial solver that can be used to solve large-scale linear (LP) and mixed integer problems (MIP), as well as non-linear problems.",
      tags: ["solver"],
      avatarUrl: "/images/logos/gurobi-icon.svg",
      isMoreUrlExternal: true,
      moreLabel: "View docs",
      moreUrl:
        "https://www.nextmv.io/docs/platform/execution-environment/supported-solvers#gurobi",
      moreTrackEvent: {
        view: "Marketplace Available Integrations",
        action: "More Details Clicked",
        meta: {
          type: "Gurobi",
        },
      },
    },
    {
      id: "solver-highs",
      name: "HiGHS",
      description:
        "Open-source solver specialized for linear programming (LP), mixed-integer programming (MIP) and quadratic programming (QP) models.",
      tags: ["solver"],
      avatarUrl: "/images/logos/highs-icon.png",
      isMoreUrlExternal: true,
      moreLabel: "View docs",
      moreUrl:
        "https://www.nextmv.io/docs/platform/execution-environment/supported-solvers#highs",
      moreTrackEvent: {
        view: "Marketplace Available Integrations",
        action: "More Details Clicked",
        meta: {
          type: "HiGHS",
        },
      },
    },
    {
      id: "solver-pyomo",
      name: "Pyomo",
      description:
        "Python-based, open-source software package to formulate, solve, and analyze optimization models.",
      tags: ["solver"],
      avatarUrl: "/images/logos/pyomo-icon.png",
      isMoreUrlExternal: true,
      moreLabel: "View docs",
      moreUrl:
        "https://www.nextmv.io/docs/platform/execution-environment/supported-solvers#pyomo",
      moreTrackEvent: {
        view: "Marketplace Available Integrations",
        action: "More Details Clicked",
        meta: {
          type: "Pyomo",
        },
      },
    },
    {
      id: "solver-or-tools",
      name: "OR-Tools",
      description:
        "Python-based, open-source solving technology for vehicle routing problems, flows, integer, linear, and constraint programming.",
      tags: ["solver"],
      avatarUrl: "/images/logos/or-tools-icon.svg",
      isMoreUrlExternal: true,
      moreLabel: "View docs",
      moreUrl:
        "https://www.nextmv.io/docs/platform/execution-environment/supported-solvers#or-tools",
      moreTrackEvent: {
        view: "Marketplace Available Integrations",
        action: "More Details Clicked",
        meta: {
          type: "OR-Tools",
        },
      },
    },
    {
      id: "nextmv-maps",
      name: "Nextmv Maps",
      description:
        "Use real map data to calculate the distance and duration it takes to go from one location to another.",
      avatar: <AvatarStreets size={40} />,
      moreLabel: "Contact Nextmv",
      moreUrl: "https://www.nextmv.io/contact",
      moreTrackEvent: {
        view: "Marketplace Available Integrations",
        action: "Contact Nextmv Clicked",
        meta: {
          name: "Nextmv Maps",
        },
      },
      isMoreUrlExternal: true,
    },
    {
      id: "onfleet",
      name: "Onfleet",
      description:
        "Add Nextmv as an optimization layer to Onfleet's dispatch & fleet management UI.",
      avatar: <AvatarOnfleet size={40} />,
      moreLabel: "Contact Nextmv",
      moreUrl: "https://www.nextmv.io/contact",
      moreTrackEvent: {
        view: "Marketplace Available Integrations",
        action: "Contact Nextmv Clicked",
        meta: {
          name: "Onfleet",
        },
      },
      isMoreUrlExternal: true,
    },
    {
      id: "here-technologies",
      name: "HERE Technologies",
      description:
        "Use street routes and real-time or projected traffic conditions with HERE.",
      avatar: <AvatarHere size={40} />,
      moreLabel: "Contact Nextmv",
      moreUrl: "https://www.nextmv.io/contact",
      moreTrackEvent: {
        view: "Marketplace Available Integrations",
        action: "Contact Nextmv Clicked",
        meta: {
          name: "HERE Technologies",
        },
      },
      isMoreUrlExternal: true,
    },
    {
      id: "google-maps-traffic",
      name: "Google Maps Traffic",
      description:
        "Add Nextmv as an optimization layer to Onfleet's dispatch & fleet management UI.",
      avatar: <AvatarGoogleMaps size={40} />,
      moreLabel: "Contact Nextmv",
      moreUrl: "https://www.nextmv.io/contact",
      moreTrackEvent: {
        view: "Marketplace Available Integrations",
        action: "Contact Nextmv Clicked",
        meta: {
          name: "Google Maps Traffic",
        },
      },
      isMoreUrlExternal: true,
    },
  ];
};
