import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { ShadowTestResponse } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import StandardError from "../../../../components/StandardError";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import { getFilteredEntities } from "../../../../utils/entities/getFilteredEntities";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../../App/App.types";
import LoadingListView from "../../../App/components/LoadingListView";
import { experimentsTooltips } from "../../data/microcopy";
import { renderExperimentsList } from "../../utils/renderExperimentsList";

const pageTitle = "Shadow Tests";

const ShadowTests = ({ app }: AppPageProps) => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [filterText, setFilterText] = useState("");

  const { shadowTests, shadowTestsError, loadShadowTests, setShadowTests } =
    useExperiments();

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load shadow tests if not in context already
  useEffect(() => {
    if (!shadowTests && !shadowTestsError) {
      loadShadowTests(app.id);
    }
  }, [app.id, loadShadowTests, shadowTests, shadowTestsError]);

  if (shadowTestsError) {
    return <StandardError errorMessage={shadowTestsError} />;
  }

  const canUserCreateAndEditShadow = userHasAccessToAction(
    roles,
    ActionGroups.ExperimentOperator,
    {}
  );

  const filteredShadowTests = getFilteredEntities(filterText, shadowTests);
  const renderShadowTests = () => {
    return renderExperimentsList({
      accountId,
      app,
      canUserCreateAndEdit: canUserCreateAndEditShadow,
      experiments: filteredShadowTests as ShadowTestResponse[],
      theme,
      kind: "shadow",
      urlOnClickTrackEventCategory: "ShadowTests",
      urlOnClickTrackEventProperties: {
        view: "Shadow Tests",
        action: "Link Clicked",
        meta: {
          type: "Shadow Test Detail",
        },
      },
    });
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: experimentsTooltips.shadow.content,
          tooltipExtraLinkLabel: experimentsTooltips.shadow.extraLinkLabel,
          tooltipExtraLinkUrl: experimentsTooltips.shadow.extraLinkUrl,
        }}
        configActionButton={{
          label: "New shadow test",
          url: `${pathname}/new`,
          onClick: () =>
            trackEvent("ShadowTests", {
              view: "Shadow Tests",
              action: "Create Shadow Test Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditShadow,
        }}
        configFilter={{
          inputText: filterText,
          testId: "filter-shadow-tests",
          setInputText: setFilterText,
        }}
        refreshButton={{
          emptyItems: null,
          itemsLoading: !shadowTests,
          loadItems: () => loadShadowTests(app.id),
          setItems: setShadowTests,
        }}
      />

      <PlanNotice {...{ app }} type="custom-apps" />

      {!shadowTests ? <LoadingListView /> : renderShadowTests()}
    </>
  );
};

export default ShadowTests;
