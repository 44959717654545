import { useCallback, useState } from "react";
import { OnChangeStatus } from "vanilla-jsoneditor";

import { getMapMarkerPoints } from "../../../../../components/Map/utils/getMapMarkerPoints";
import { useDebounceCallback } from "../../../../../hooks/useDebounce";
import {
  FUTUREparseTextAsJson,
  ValidJSON,
} from "../../../../../utils/parseInputOutput";

const INPUT_DEBOUNCE_INTERVAL = 300;

export type UseCustomInputReturnValue = ReturnType<typeof useCustomInput>;

type EditorInput = { json: ValidJSON | undefined; text: string | undefined };
export type EditorOnChange = (
  input: EditorInput,
  _prevInput?: EditorInput,
  inputState?: OnChangeStatus
) => void;

export const defaultEditorJSONInput = {
  json: {},
  text: undefined,
};

// future schema validation, see editor example:
// https://github.com/josdejong/svelte-jsoneditor/blob/main/examples/browser/json_schema_validation.html

export default function useCustomInput() {
  // input editor requires top-level `json` and `text` properties
  // subsequent state updates by editor will keep structure
  const [input, setInput] = useState<EditorInput>(defaultEditorJSONInput);
  const [isInputInError, setIsInputInError] = useState(false);

  const onInputChange: EditorOnChange = useCallback(
    (input, _prevInput, inputState) => {
      setInput(input);

      if (inputState?.contentErrors) {
        setIsInputInError(true);
      } else {
        setIsInputInError(false);
      }
    },
    [setInput]
  );

  const [onInputChangeDebounced] = useDebounceCallback(
    onInputChange,
    INPUT_DEBOUNCE_INTERVAL
  );

  return {
    inputState: {
      input,
      isInputInError,
      markerCoords: getMapMarkerPoints(FUTUREparseTextAsJson(input.text)),
    },
    onInputChange: onInputChangeDebounced,
  };
}
