import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import RowDetail from "../../../components/RowDetail";
import Select from "../../../components/Select";
import { INPUT_SET_CREATE_TYPE_INPUTS } from "../../../config/experiments";
import { rem } from "../../../utils/tools";
import { createOptions } from "../../Experiments/components/utils";
import { InputSetCreateTypeOption } from "../Experiments.types";

interface InputSetsCreateTypeSelectProps {
  pendingCreateType: InputSetCreateTypeOption | undefined;
  onAdd: (option: InputSetCreateTypeOption) => void;
}

const getOptionById = (options: InputSetCreateTypeOption[], id: string) => {
  if (!options || options.length === 0) return null;
  const option = options.find((option) => option.value === id);
  return option || options[0];
};

const InputSetsCreateTypeSelect = (props: InputSetsCreateTypeSelectProps) => {
  const { pendingCreateType, onAdd } = props;

  const addCreateType = (createTypeSelected: InputSetCreateTypeOption) => {
    onAdd(createTypeSelected);
  };

  return (
    <RowDetail
      property="Create Type"
      secondaryLabel="Basis for input set creation"
      render={
        <Box maxWidth={rem(408)}>
          <Box>
            <Flex width="100%">
              <Select
                testId="new-input-set-create-type-select"
                placeholder="Select create type"
                options={createOptions}
                defaultValue={getOptionById(
                  createOptions,
                  INPUT_SET_CREATE_TYPE_INPUTS
                )}
                value={pendingCreateType}
                onChange={(selection: InputSetCreateTypeOption) => {
                  addCreateType(selection);
                }}
              />
            </Flex>
          </Box>
        </Box>
      }
    />
  );
};
export default InputSetsCreateTypeSelect;
