import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { useUser } from "../../../../AuthProvider";
import Box from "../../../../components/Box";
import Flex from "../../../../components/Flex";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import Tag from "../../../../components/Tag";
import Text from "../../../../components/Text";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import useManageEntity from "../../../../hooks/useManageEntity";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { rem } from "../../../../utils/tools";
import { appTooltipCopy } from "../../../Apps/data/microcopy";
import { AppPageProps } from "../../App.types";
import ExecutableDetails from "../../components/ExecutableDetails";
import useReturnPaths from "../../hooks/useReturnPaths";

const VersionDetails = ({ app }: AppPageProps) => {
  const [{ roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();
  const theme = useTheme();
  const { returnPathList } = useReturnPaths();

  const {
    entity: version,
    entityLoadError: versionError,
    loadEntity: loadVersion,
  } = useManageEntity("versions");

  // page display
  useEffect(() => {
    if (version) {
      setMetaTitle(version.name);
    }
  }, [setMetaTitle, version]);

  // load version
  useEffect(() => {
    if (!version && !versionError) {
      loadVersion(app.id, params.id);
    }
  }, [app.id, loadVersion, params.id, version, versionError]);

  if (versionError) {
    return <StandardError errorMessage={versionError} />;
  }
  if (!version) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const canUserCreateAndEditVersions = userHasAccessToAction(
    roles,
    ActionGroups.AppVersionOperator,
    {}
  );

  return (
    <>
      <Header
        configPageTitle={{
          label: version.name,
          parentLabel: "Versions",
          parentUrl: returnPathList,
        }}
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("Apps", {
              view: "App Version",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditVersions,
        }}
        secondaryButton={{
          label: "New version",
          url: `${returnPathList}/new`,
          isActionAllowed: canUserCreateAndEditVersions,
        }}
      />

      <RowDetail
        hasNoBorder
        property="Name"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text styleName="body-2">{version.name}</Text>
          </Box>
        }
      />

      <RowDetail
        property="ID"
        tooltipCopy={appTooltipCopy.versionId}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={version.id}
              contentToCopy={version.id}
            />
          </Flex>
        }
      />

      {version.description && (
        <RowDetail
          property="Description"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">{version.description}</Text>
            </Box>
          }
        />
      )}

      {version.executable && (
        <RowDetail
          property="Executable"
          render={<ExecutableDetails executable={version.executable} />}
        />
      )}
    </>
  );
};

export default VersionDetails;
