import * as React from "react";

import BaseAvatar, { AvatarProps } from "./BaseAvatar";

const AvatarPass = ({ size }: AvatarProps) => {
  return (
    <BaseAvatar {...{ size }} viewBox={[0, 0, 24, 24]}>
      <rect width="24" height="24" rx="12" fill="#F7B100" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.05859C11.3885 6.05859 10.8995 6.56675 10.923 7.17777L11.1692 13.5792C11.1864 14.0256 11.5533 14.3786 12 14.3786C12.4467 14.3786 12.8136 14.0256 12.8307 13.5792L13.0769 7.17777C13.1004 6.56675 12.6115 6.05859 12 6.05859ZM11.9998 17.9615C12.6625 17.9615 13.1998 17.4242 13.1998 16.7615C13.1998 16.0988 12.6625 15.5615 11.9998 15.5615C11.3371 15.5615 10.7998 16.0988 10.7998 16.7615C10.7998 17.4242 11.3371 17.9615 11.9998 17.9615Z"
        fill="white"
      />
    </BaseAvatar>
  );
};

export default AvatarPass;
