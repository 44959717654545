import React from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Auth } from "aws-amplify";

import { useUser } from "../../AuthProvider";
import { IconLogout } from "../../icons";
import { getPathWithoutOrg } from "../../utils/getPathWithoutOrg";
import { checkIsActivePage } from "../../utils/navigation";
import { PageNavigationItem } from "../MainNav/MainNav.types";
import Text from "../Text";

import {
  getProfileMenuAccountPages,
  getProfileMenuOtherPages,
} from "./data/pages";
import {
  StyledAccountArea,
  StyledBox,
  StyledLogoutFlex,
  StyledProfileMenu,
  StyledProfileMenuA,
  StyledProfileMenuItem,
  StyledProfileMenuLink,
  StyledProfileMenuList,
} from "./ProfileMenu.styled";
import { ProfileMenuProps } from "./ProfileMenu.types";

const ProfileMenu = ({ isActive, htmlId }: ProfileMenuProps) => {
  const [{ id }] = useUser();
  const { pathname } = useLocation();
  const theme = useTheme();

  const signOut = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    return Auth.signOut();
  };

  const renderProfileMenuPages = (pages: PageNavigationItem[]) => (
    <StyledProfileMenuList>
      {pages.map((page) => {
        const isActivePage = checkIsActivePage({
          currentPath: getPathWithoutOrg(pathname),
          pageUrl: getPathWithoutOrg(page.url || ""),
        });

        return (
          <StyledProfileMenuItem key={page.id}>
            {page.isExternal ? (
              <StyledProfileMenuA
                href={page.url}
                isactive={isActivePage}
                target="_blank"
                rel="noreferrer"
              >
                {page.label}
              </StyledProfileMenuA>
            ) : (
              <StyledProfileMenuLink
                to={page.url || ""}
                isactive={isActivePage}
              >
                {page.label}
              </StyledProfileMenuLink>
            )}
          </StyledProfileMenuItem>
        );
      })}
    </StyledProfileMenuList>
  );

  return (
    <StyledProfileMenu {...{ isActive }}>
      <StyledAccountArea pt={4} pr={5} pb={4} pl={6} hasBorderBottom>
        <Text as="h3" styleName="label" styles={{ color: theme.color.gray600 }}>
          Account
        </Text>
        {renderProfileMenuPages(getProfileMenuAccountPages(id))}
      </StyledAccountArea>

      <StyledBox pt={2} pr={5} pb={5} pl={6} background={theme.color.white}>
        {renderProfileMenuPages(getProfileMenuOtherPages(id))}

        <StyledLogoutFlex
          mt={3}
          alignItems="center"
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            signOut(e)
          }
          role="link"
          aria-label="Log out"
          title="Log out of Nextmv Console"
        >
          <Text as="span" styleName="body-3" mr={1}>
            Log&nbsp;out
          </Text>

          <IconLogout iconColor={theme.color.gray600} />
        </StyledLogoutFlex>
      </StyledBox>
    </StyledProfileMenu>
  );
};

export default ProfileMenu;
