import { rem } from "../../../utils/tools";
import { GetSelectOptionPaddingYValueParams } from "../Select.types";

export const getSelectOptionPaddingYValue = ({
  size,
  theme,
  type,
}: GetSelectOptionPaddingYValueParams) => {
  if (type && ["first", "last"].includes(type))
    return size === "small" ? theme.spacing.s1 : theme.spacing.s2;
  switch (size) {
    case "small":
      return theme.spacing.s1;
    case "large":
      return theme.spacing.s2;
    default:
      return `calc(${theme.spacing.s2} - ${rem(2)})`;
  }
};
