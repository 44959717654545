import { useCallback, useState } from "react";

import { getEntities } from "../api/core/controlPlane";
import {
  AppEntityEndpoints,
  EntitiesData,
  EntityErrorMessage,
  LoadEntitiesParams,
  RunsDatum,
} from "../api/core/controlPlane.types";
import { useUser } from "../AuthProvider";
import { ENDPOINT_RUNS } from "../config/endpoints";

const useManageEntities = <AppEntityEndpoint extends keyof AppEntityEndpoints>(
  endpoint: AppEntityEndpoint
) => {
  const [{ id: accountId }] = useUser();

  const [entities, setEntities] = useState<
    EntitiesData[AppEntityEndpoint] | null
  >(null);
  const [entitiesLoadError, setEntitiesLoadError] =
    useState<EntityErrorMessage>(null);
  const [entitiesNextPageToken, setEntitiesNextPageToken] =
    useState<string>("");
  const [additionalEntitiesLoading, setAdditionalEntitiesLoading] =
    useState(false);

  // NOTE
  // shouldPaginate is a temporary prop that is used to trigger
  // the paginated return structure (object with items and nextpage
  // property rather than array) for entities that can return both
  // it is required for entities that only have paginated return
  const loadEntities = useCallback(
    async ({
      applicationId,
      limit,
      nextPageToken,
      queryEnd,
      queryStart,
      shouldAppend = false,
      shouldPaginate = false,
      statusFilter,
      type,
    }: LoadEntitiesParams) => {
      try {
        const entitiesRes = await getEntities(accountId || "")({
          applicationId,
          entityEndpoint: endpoint,
          limit,
          nextPageToken,
          queryEnd,
          queryStart,
          shouldPaginate,
          type,
        });

        // runs have different behavior than other list views
        const isRuns = endpoint === ENDPOINT_RUNS;
        const dataKey = isRuns ? "runs" : "items";
        let entityData;
        if (isRuns) {
          entityData = entitiesRes[dataKey];
        } else {
          entityData = shouldPaginate ? entitiesRes[dataKey] : entitiesRes;
        }

        // fence to runs only at the moment
        if (statusFilter && isRuns) {
          entityData = entityData.filter((entity: RunsDatum) =>
            statusFilter.includes(entity.status_v2)
          );
        }

        setEntitiesLoadError(null);
        setEntitiesNextPageToken(entitiesRes?.next_page_token);

        setEntities((prevState) => {
          return [
            ...(shouldAppend && prevState ? prevState : []),
            ...(entityData ? entityData : []),
          ];
        });
        setAdditionalEntitiesLoading(false);
        return entityData;
      } catch (error: any) {
        console.error(error);
        setAdditionalEntitiesLoading(false);
        setEntitiesLoadError(error.toString());
      }
    },
    [accountId, endpoint]
  );

  return {
    additionalEntitiesLoading,
    entities,
    entitiesLoadError,
    entitiesNextPageToken,
    loadEntities,
    setAdditionalEntitiesLoading,
    setEntities,
    setEntitiesLoadError,
  };
};

export default useManageEntities;
