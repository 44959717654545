import React, { useMemo } from "react";
import { useTheme } from "@emotion/react";

import {
  FUTUREparseTextAsJson,
  ValidJSON,
} from "../../../../../utils/parseInputOutput";
import {
  parseSchemaShiftSchedulingRequiredWorkers,
  parseSchemaShiftSchedulingWorkers,
} from "../../../utils/schemaCheckShiftScheduling";
import VisualizationSchedulingInput from "../../RunDetails/components/VisualizationSchedulingInput";
import {
  RequiredWorkerShift,
  WorkerToSchedule,
} from "../../RunDetails/RunDetails.types";
import {
  getStartEndRange,
  getVizHourRange,
  getWorkerColors,
  getWorkersRange,
} from "../../RunDetails/utils/getWorkerData";

type SchedulingVisualizationProps = {
  input: {
    json: ValidJSON | undefined;
    text: string | undefined;
  };
};

const SchedulingVisualization = ({ input }: SchedulingVisualizationProps) => {
  const theme = useTheme();

  const schedulingInput = useMemo(() => {
    return FUTUREparseTextAsJson(input.text) as {
      required_workers: RequiredWorkerShift[];
      workers: WorkerToSchedule[];
    };
  }, [input.text]);

  // filter out bad data before trying to render visualization
  const sanitizedWorkers = parseSchemaShiftSchedulingWorkers(
    schedulingInput.workers
  );
  const sanitizedRequiredWorkerShifts =
    parseSchemaShiftSchedulingRequiredWorkers(schedulingInput.required_workers);

  return (
    <VisualizationSchedulingInput
      workers={sanitizedWorkers}
      workerColors={getWorkerColors({
        theme,
        workers: sanitizedWorkers,
      })}
      requiredWorkerShifts={sanitizedRequiredWorkerShifts}
      visualizeHoursRange={getVizHourRange(
        getWorkersRange(sanitizedWorkers),
        getStartEndRange({
          entities: sanitizedRequiredWorkerShifts,
        })
      )}
    />
  );
};

export default SchedulingVisualization;
