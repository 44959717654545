import React from "react";
import { useTheme } from "@emotion/react";

import { IconCheckEncircled, IconError, IconInfo } from "../../icons";
import { rem } from "../../utils/tools";
import Box from "../Box";
import Text from "../Text";

import {
  getBackgroundColor,
  getBorderColor,
  getIconColor,
} from "./utils/presets";
import { StyledNotificationFlex } from "./Notification.styles";
import { NotificationProps, NotificationType } from "./Notification.types";

const Notification = ({
  hasContactExtra,
  hasContactToUpgrade,
  message,
  size,
  type,
  m,
  mt,
  mr,
  mb,
  ml,
  testId,
  width,
  ...rest
}: NotificationProps) => {
  const theme = useTheme();

  const renderIcon = (type?: NotificationType, isSmall?: boolean) => {
    switch (type) {
      case "success":
        return (
          <IconCheckEncircled
            iconSize={isSmall ? 16 : 20}
            iconColor={getIconColor({ type, theme })}
          />
        );
      case "tip":
        return (
          <IconInfo
            iconSize={isSmall ? 16 : 20}
            iconColor={getIconColor({ type, theme })}
          />
        );
      default:
        return (
          <IconError
            iconSize={isSmall ? 16 : 20}
            iconColor={getIconColor({ type, theme })}
          />
        );
    }
  };

  const isSmall = size && size === "small";

  return (
    <StyledNotificationFlex
      {...rest}
      {...{ m, mt, mr, mb, ml }}
      size={size}
      width={width || "100%"}
      pt={isSmall ? rem(10) : 3}
      pb={isSmall ? rem(10) : 3}
      px={isSmall ? 3 : 4}
      borderColor={getBorderColor({ type, theme })}
      backgroundColor={getBackgroundColor({ type, theme })}
      {...(testId && {
        "data-testid": testId,
      })}
    >
      <Box mt={isSmall ? rem(2) : rem(1)} mr={2} ml={-1}>
        {renderIcon(type, isSmall)}
      </Box>
      <Box>
        {typeof message === "string" ? (
          <Text
            styleName={isSmall ? "body-3" : "body-2"}
            hasLink
            styles={{ whiteSpace: "pre-wrap" }}
          >
            {message}
          </Text>
        ) : (
          message
        )}
        {hasContactExtra && (
          <Text
            mt={isSmall ? 1 : 2}
            styleName={isSmall ? "body-3" : "body-2"}
            hasLink
          >
            If this problem persists, please{" "}
            <a
              href={"https://www.nextmv.io/contact"}
              target="_blank"
              rel="noreferrer"
            >
              contact Nextmv support
            </a>
            .
          </Text>
        )}
        {hasContactToUpgrade && (
          <Text
            mt={isSmall ? 1 : 2}
            styleName={isSmall ? "body-3" : "body-2"}
            hasLink
          >
            To upgrade your account,{" "}
            <a
              href={"https://www.nextmv.io/contact"}
              target="_blank"
              rel="noreferrer"
            >
              contact Nextmv
            </a>
            .
          </Text>
        )}
      </Box>
    </StyledNotificationFlex>
  );
};

export default Notification;
