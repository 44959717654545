import React, { useEffect, useMemo, useState } from "react";
import { Redirect } from "react-router-dom";

import { trackEvent } from "../../../../analytics";
import { startShadowTest } from "../../../../api/core/controlPlane";
import { useUser } from "../../../../AuthProvider";
import { AvatarExperimentShadow } from "../../../../avatars";
import Box from "../../../../components/Box";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import InstanceSelect from "../../../../components/InstanceSelect";
import { useMetaTitle } from "../../../../components/Layout";
import RowDetail from "../../../../components/RowDetail";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { rem } from "../../../../utils/tools";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";

import ShadowTestEndCriteria from "./components/ShadowTestEndCriteria";
import ShadowTestStartCriteria from "./components/ShadowTestStartCriteria";
import { tooltipContentNewShadowTest } from "./data/tooltipContentNewShadowTest";
import useNewShadowTest from "./hooks/useNewShadowTest";

const pageTitle = "Create new test";

const NewShadowTest = ({ app }: AppPageProps) => {
  const [{ id: accountId }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const { returnPath, returnPathList } = useReturnPaths();

  const [isProcessing, setIsProcessing] = useState(false);
  const [isTestStarted, setIsTestStarted] = useState(false);

  const { loadShadowTests } = useExperiments();

  const {
    pendingBaselineInstanceIds,
    pendingCandidateInstanceIds,
    pendingShadowCriteria,
    setPendingBaselineInstanceIds,
    setPendingCandidateInstanceIds,
    setShadowEndCriteriaDurationWeeks,
    setShadowEndCriteriaMaxRuns,
    setShadowEndCriteriaMaxRunsError,
    setShadowStartCriteriaTime,
    setShadowStartCriteriaTimeIsAuto,
    shadowEndCriteriaDurationWeeks,
    shadowEndCriteriaMaxRuns,
    shadowEndCriteriaMaxRunsError,
    shadowStartCriteriaTime,
  } = useNewShadowTest();

  const {
    addEntity: addShadowTest,
    entityAddError: shadowTestAddError,
    isEntityAdded: isShadowTestAdded,
  } = useManageEntity("experiments/shadow");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
  } = useStandardInputs(app, "experiments/shadow");

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // disable loading state if add new shadow error
  useEffect(() => {
    if (shadowTestAddError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, shadowTestAddError]);

  const filteredPendingBaselineInstanceIds = useMemo(() => {
    return pendingBaselineInstanceIds.filter(Boolean);
  }, [pendingBaselineInstanceIds]);

  const filteredPendingCandidateInstanceIds = useMemo(() => {
    return pendingCandidateInstanceIds.filter(Boolean);
  }, [pendingCandidateInstanceIds]);

  const handleShadowTestCreate = async (
    e: {
      preventDefault: any;
      stopPropagation: any;
    },
    shouldStartTest = false
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);
    setIsTestStarted(shouldStartTest);

    const shadowTestInstances = {
      comparison: {
        baseline_instance_id: filteredPendingBaselineInstanceIds[0] || "",
        candidate_instance_id: filteredPendingCandidateInstanceIds[0] || "",
      },
    };

    const shadowTestPayload = Object.assign(
      pendingStandardInputs,
      shadowTestInstances,
      pendingShadowCriteria
    );

    const newShadowTest = await addShadowTest(app.id, shadowTestPayload, true);
    if (newShadowTest && shouldStartTest) {
      await startShadowTest(accountId || "")(app.id, newShadowTest.id);
    }
    return;
  };

  const handleCancel = () => {
    trackEvent("ShadowTests", {
      view: "Create Shadow Test",
      action: "Create Shadow Test Canceled",
    });
    return;
  };

  if (isShadowTestAdded) {
    trackEvent("ShadowTests", {
      view: "Create Shadow Test",
      action: isTestStarted
        ? "New Shadow Test Created and Started"
        : "New Draft Shadow Test Created",
    });

    loadShadowTests(app.id);

    return <Redirect to={returnPathList} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name ||
    !pendingStandardInputs.id ||
    !filteredPendingBaselineInstanceIds.length ||
    !filteredPendingCandidateInstanceIds.length ||
    !!standardInputsErrors.name ||
    !!standardInputsErrors.id ||
    !!shadowEndCriteriaMaxRunsError;

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          ancestorIcon: <AvatarExperimentShadow size={24} />,
          ancestorLabel: "Shadow Tests",
          ancestorUrl: returnPathList,
        }}
      />

      <Box pb={[6, 6, 8]}>
        <form>
          <RowDetail
            hasNoBorder
            property="Name"
            secondaryLabel="For reference only"
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Shadow test name",
                    testId: "new-shadow-name-input",
                    type: "name",
                    trackEventCategory: "ShadowTests",
                    trackEventProperties: {
                      view: "Create Shadow Test",
                      action: "Field Entered",
                      meta: {
                        field: "name",
                      },
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="ID"
            tooltipCopy={tooltipContentNewShadowTest.id.content}
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Shadow test ID",
                    testId: "new-shadow-id-input",
                    type: "id",
                    trackEventCategory: "ShadowTests",
                    trackEventProperties: {
                      view: "Create Shadow Test",
                      action: "Shadow Test ID Changed",
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="Description"
            secondaryLabel="(optional)"
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Shadow test description",
                    testId: "new-shadow-description-input",
                    type: "description",
                    trackEventCategory: "ShadowTests",
                    trackEventProperties: {
                      view: "Create Shadow Test",
                      action: "Field Entered",
                      meta: {
                        field: "description",
                      },
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="Baseline Instance"
            tooltipCopy={tooltipContentNewShadowTest.baselineInstance.content}
            render={
              <Box maxWidth={INPUT_WIDTH_STANDARD}>
                <InstanceSelect
                  {...{ app }}
                  isSingleSelect
                  placeholder="Select baseline instance"
                  excludeInstanceIds={pendingCandidateInstanceIds}
                  pendingInstanceIds={pendingBaselineInstanceIds}
                  setPendingInstanceIds={setPendingBaselineInstanceIds}
                  trackEventCategory="ShadowTests"
                  trackEventProperties={{
                    view: "Create Shadow Test",
                    action: "Baseline Instance Selected",
                  }}
                />
              </Box>
            }
          />

          <RowDetail
            property="Candidate Instance"
            tooltipCopy={tooltipContentNewShadowTest.candidateInstance.content}
            render={
              <Box maxWidth={INPUT_WIDTH_STANDARD}>
                {" "}
                <InstanceSelect
                  {...{ app }}
                  isSingleSelect
                  placeholder="Select candidate instance"
                  excludeInstanceIds={pendingBaselineInstanceIds}
                  pendingInstanceIds={pendingCandidateInstanceIds}
                  setPendingInstanceIds={setPendingCandidateInstanceIds}
                  trackEventCategory="ShadowTests"
                  trackEventProperties={{
                    view: "Create Shadow Test",
                    action: "Candidate Instance Selected",
                  }}
                />
              </Box>
            }
          />

          <RowDetail
            property="End criteria"
            tooltipCopy={tooltipContentNewShadowTest.endCriteria.content}
            render={
              <ShadowTestEndCriteria
                {...{
                  setShadowEndCriteriaDurationWeeks,
                  setShadowEndCriteriaMaxRuns,
                  setShadowEndCriteriaMaxRunsError,
                  shadowEndCriteriaDurationWeeks,
                  shadowEndCriteriaMaxRuns,
                  shadowEndCriteriaMaxRunsError,
                }}
              />
            }
          />

          <RowDetail
            property="Start criteria"
            secondaryLabel="(optional)"
            tooltipCopy={tooltipContentNewShadowTest.startCriteria.content}
            render={
              <ShadowTestStartCriteria
                {...{
                  setShadowStartCriteriaTime,
                  setShadowStartCriteriaTimeIsAuto,
                  shadowStartCriteriaTime,
                }}
              />
            }
          />

          <Footer
            actionButtonLabel="Create & start shadow test"
            actionButtonMinWidth={rem(214)}
            app={app}
            endpoint="experiments/shadow"
            error={shadowTestAddError}
            handleCancel={handleCancel}
            handleMainAction={(e) => handleShadowTestCreate(e, true)}
            handleSecondaryAction={(e) => handleShadowTestCreate(e)}
            isActionButtonDisabled={isActionButtonDisabled}
            isActionButtonLoading={isProcessing && isTestStarted}
            isSecondaryButtonDisabled={isActionButtonDisabled}
            isSecondaryButtonLoading={isProcessing && !isTestStarted}
            returnPath={returnPath}
            returnPathList={returnPathList}
            secondaryButtonLabel="Create draft shadow test"
            secondaryButtonMinWidth={rem(214)}
            view="create"
          />
        </form>
      </Box>
    </>
  );
};

export default NewShadowTest;
