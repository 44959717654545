import { Marker, Popup } from "react-leaflet";
import { useTheme } from "@emotion/react";

import { MainViewType } from "../../../../../../pages/App/subpages/RunDetails/RunDetails.types";
import {
  DepotInfoStop,
  MarkerPoint,
  RouteSetByID,
} from "../../../../Map.types";
import { createMarkerIcon, markerIconUnassigned } from "../../utils/mapMarkers";
import DepotPopupContent from "../DepotPopupContent";
import VehicleAndStopPopupContent from "../VehicleAndStopPopupContent";

export type MarkerIconProps = {
  activeTab: MainViewType;
  isFocusActive: boolean;
  isFocusedRoute: boolean;
  point: MarkerPoint;
  renderPopupDepotList: (
    stopKind: string,
    depotInfo: DepotInfoStop[]
  ) => false | JSX.Element;
  shouldRenderMarker: (p: MarkerPoint) => boolean;
  routesById?: RouteSetByID;
};

export default function MarkerIcon({
  activeTab,
  isFocusActive,
  isFocusedRoute,
  point,
  renderPopupDepotList,
  routesById,
  shouldRenderMarker,
}: MarkerIconProps) {
  const theme = useTheme();

  if (!shouldRenderMarker(point)) return null;

  return (
    <Marker
      position={point.position}
      icon={
        point?.isAssigned === false
          ? markerIconUnassigned
          : createMarkerIcon({ isFocusActive, isFocusedRoute, point, theme })
      }
    >
      <Popup minWidth={90} maxHeight={225}>
        {point?.depotInfo ? (
          <DepotPopupContent
            renderPopupDepotList={renderPopupDepotList}
            point={point}
            activeTab={activeTab}
            routesById={routesById}
          />
        ) : (
          <VehicleAndStopPopupContent
            point={point}
            activeTab={activeTab}
            routesById={routesById}
          />
        )}
      </Popup>
    </Marker>
  );
}
