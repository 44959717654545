import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";

import Box from "../../../components/Box";
import Button2 from "../../../components/Button2";
import Flex from "../../../components/Flex";
import Input from "../../../components/Input";
import RowDetail from "../../../components/RowDetail";
import Select from "../../../components/Select";
import Tabs from "../../../components/Tabs";
import Text from "../../../components/Text";
import Tooltip from "../../../components/Tooltip";
import { IconX } from "../../../icons";
import { rem } from "../../../utils/tools";
import { acceptanceTooltips } from "../data/microcopy";
import {
  AcceptanceMetricSelectSelectProps,
  MetricCompareOption,
  RenderAcceptanceMetrics,
} from "../Experiments.types";
import {
  parseMetricsToString,
  parseStringToMetrics,
} from "../utils/acceptanceTestMetrics";

import { metricOperatorOptions } from "./utils";

const renderAcceptanceMetrics = ({
  acceptanceMetrics,
  handleMetricInputChange,
  handleMetricSelectCompare,
  removeMetric,
}: RenderAcceptanceMetrics) => {
  return acceptanceMetrics.map((acceptanceMetric, index) => {
    return (
      <Box maxWidth={rem(1200)} key={`acceptance-metric-${index}`} mb={2}>
        <Flex
          width="100%"
          flexDirection={["column", "column", "column", "row"]}
        >
          <Box width={rem(408)} minWidth={rem(150)} mr={2}>
            <Input
              name="name"
              htmlType="text"
              placeholder="Path to custom statistics metric"
              value={acceptanceMetric.field}
              onChange={(e: any) => handleMetricInputChange(e, index)}
            />
          </Box>

          <Flex width={rem(300)} alignItems="center">
            <Box width={rem(256)} mt={[1, 1, 1, 0, 0]}>
              <Select
                placeholder="Select compare"
                options={metricOperatorOptions}
                getOptionValue={(
                  metricCompareOptionSelected: MetricCompareOption
                ) => metricCompareOptionSelected.label}
                value={metricOperatorOptions.find(
                  (metric) => metric.value === acceptanceMetric.params.operator
                )}
                onChange={(selection: MetricCompareOption) => {
                  handleMetricSelectCompare(selection, index);
                }}
              />
            </Box>
            {acceptanceMetrics?.length > 1 && (
              <Button2
                type="text-quiet"
                icon={<IconX />}
                onClick={(e: any) => removeMetric(e, index)}
              />
            )}
            {index === 0 && (
              <Tooltip ml={1} direction="left">
                {acceptanceTooltips.metricsCompare.content}
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </Box>
    );
  });
};

const AcceptanceMetricSelect = ({
  addEmptyMetric,
  handleMetricInputChange,
  handleMetricSelectCompare,
  pendingAcceptanceTestMetrics,
  removeMetric,
  setPendingAcceptanceTestMetrics,
}: AcceptanceMetricSelectSelectProps) => {
  const theme = useTheme();

  const [acceptanceMetricsString, setAcceptanceMetricsString] = useState(
    parseMetricsToString(pendingAcceptanceTestMetrics)
  );
  const [activeEntryUI, setActiveEntryUI] = useState("standard");

  useEffect(() => {
    setAcceptanceMetricsString(
      parseMetricsToString(pendingAcceptanceTestMetrics)
    );
  }, [pendingAcceptanceTestMetrics]);

  const switchTab = (
    e: {
      preventDefault: () => void;
      stopPropagation: () => void;
    },
    id: string
  ) => {
    e.preventDefault();
    e.stopPropagation();
    return setActiveEntryUI(id);
  };

  const handleFreeFormMetricsBlur = () => {
    setPendingAcceptanceTestMetrics(
      parseStringToMetrics(acceptanceMetricsString)
    );
  };

  return (
    <RowDetail
      property="Metrics"
      secondaryLabel="Specify at least one metric from your input to test"
      tooltipCopy={acceptanceTooltips.metrics.content}
      render={
        <Box maxWidth={rem(810)}>
          <Tabs
            mt={[2, 2, -1]}
            mb={3}
            ml={2}
            type="panel"
            size="small"
            tabs={[
              {
                id: "standard",
                label: "Standard",
                isActive: activeEntryUI === "standard",
                onClick: (e: {
                  preventDefault: () => void;
                  stopPropagation: () => void;
                }) => switchTab(e, "standard"),
              },
              {
                id: "free-form",
                label: "Free-form",
                isActive: activeEntryUI === "free-form",
                onClick: (e: {
                  preventDefault: () => void;
                  stopPropagation: () => void;
                }) => switchTab(e, "free-form"),
              },
            ]}
          />

          {activeEntryUI === "standard" && (
            <>
              {renderAcceptanceMetrics({
                acceptanceMetrics: pendingAcceptanceTestMetrics,
                handleMetricInputChange,
                handleMetricSelectCompare,
                removeMetric,
              })}

              <Text
                mt={-1}
                styleName="meta-1"
                styles={{
                  color: theme.color.gray600,
                }}
              >
                Example: result.value
              </Text>

              <Button2
                mt={3}
                type="outline-quiet"
                onClick={(e: any) => addEmptyMetric(e)}
                label="Add additional metric"
              />
            </>
          )}
          {activeEntryUI === "free-form" && (
            <Flex>
              <Box width="100%" maxWidth={rem(616)}>
                <Input
                  name="free-form-metrics"
                  htmlType="text"
                  placeholder="Enter metrics"
                  value={acceptanceMetricsString}
                  onChange={(e: { target: { value: string } }) => {
                    return setAcceptanceMetricsString(e.target.value);
                  }}
                  onBlur={handleFreeFormMetricsBlur}
                  isTextArea
                  styles={{
                    fontFamily: theme.ui2Typography.fontFamilyCode,
                    fontSize: theme.ui2Typography.fontSizeBody3,
                    textRendering: "auto",
                    WebkitFontSmoothing: "auto",
                  }}
                />
                <Text
                  mt={2}
                  styleName="meta-2"
                  styles={{
                    color: theme.color.gray600,
                  }}
                >
                  Example
                  <Text
                    as="span"
                    styleName="code"
                    styles={{
                      display: "block",
                      fontSize: theme.ui2Typography.fontSizeMeta2,
                      lineHeight: theme.ui2Typography.lineHeightMeta2,
                      color: theme.color.gray600,
                    }}
                  >
                    result.value: gt
                  </Text>
                  <Text
                    as="span"
                    styleName="code"
                    styles={{
                      display: "block",
                      fontSize: theme.ui2Typography.fontSizeMeta2,
                      lineHeight: theme.ui2Typography.lineHeightMeta2,
                      color: theme.color.gray600,
                    }}
                  >
                    result.custom.activated_vehicles: lt
                  </Text>
                </Text>
              </Box>

              <Tooltip
                mt={1}
                ml={1}
                direction="left"
                extraLinkLabel={
                  acceptanceTooltips.metricsCompareFreeform.extraLinkLabel
                }
                extraLinkUrl={
                  acceptanceTooltips.metricsCompareFreeform.extraLinkUrl
                }
              >
                {acceptanceTooltips.metricsCompareFreeform.content}
              </Tooltip>
            </Flex>
          )}
        </Box>
      }
    />
  );
};
export default AcceptanceMetricSelect;
