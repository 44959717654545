import React from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { useUser } from "../../AuthProvider";
import Box from "../../components/Box";
import Button2 from "../../components/Button2";
import Flex from "../../components/Flex";
import { AppNavSection } from "../../contexts/apps/App.context.types";
import { appNavSections } from "../../pages/App/utils/pages";
import { getPathWithoutOrg } from "../../utils/getPathWithoutOrg";
import { checkIsActivePage } from "../../utils/navigation";
import { rem } from "../../utils/tools";
import { getBannerType } from "../Banner/utils/getBannerType";
import { PageNavigationItem } from "../MainNav/MainNav.types";
import SectionTitle from "../SectionTitle";
import Tag from "../Tag";
import Text from "../Text";

import {
  SectionNavControl,
  SectionNavItem,
  SectionNavLink,
  SectionNavList,
  SectionSubNavList,
  StyledSectionNav,
  StyledSectionNavSidebar,
} from "./SectionNav.styles";
import { SectionNavProps } from "./SectionNav.types";

type RenderPageContentParams = {
  isActivePage: boolean;
  page: PageNavigationItem;
};

const SectionNav = ({
  activeAppNavSections,
  ariaLabel,
  pages,
  sectionIcon,
  sectionTitle,
  setActiveAppNavSections,
}: SectionNavProps) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const [user] = useUser();
  const bannerType = getBannerType(user);

  const renderPageContent = ({
    isActivePage,
    page,
  }: RenderPageContentParams) => {
    if (!page?.url) {
      return (
        <>
          {page.label}
          {(page?.isBeta || page?.tag) && (
            <Tag
              ml={1}
              size="small"
              type={page?.isBeta ? "early-access" : "default"}
              label={page?.tag || "beta"}
            />
          )}
        </>
      );
    }
    if (page?.type === "button") {
      return (
        <Flex mt={4}>
          <Button2
            type="outline"
            label={page.label}
            isDisabled={page.isDisabled}
            to={page.url}
          />
        </Flex>
      );
    }
    return (
      <SectionNavLink
        data-testid={`${page.id}-link`}
        to={page.url}
        isactive={isActivePage.toString()}
        isdisabled={page?.isDisabled ? page?.isDisabled.toString() : undefined}
        disabled={page?.isDisabled ? true : undefined}
        aria-current={isActivePage ? "page" : undefined}
        aria-disabled={page?.isDisabled ? page.isDisabled : undefined}
        onClick={() => {
          // set section active by default when section nav link is clicked
          if (
            setActiveAppNavSections &&
            appNavSections.includes(page.id as AppNavSection)
          ) {
            setActiveAppNavSections((prevState) => {
              if (prevState.includes(page.id as AppNavSection)) {
                return prevState;
              }
              return [...prevState, page.id] as AppNavSection[];
            });
          }
        }}
      >
        {page.label}
        {page.isBeta && (
          <Tag
            ml={1}
            size="small"
            type="early-access"
            label={page?.tag || "beta"}
          />
        )}
      </SectionNavLink>
    );
  };

  const renderPage = (
    page: PageNavigationItem,
    pathname: string,
    hasSubpages?: boolean,
    isSectionActive?: boolean
  ) => {
    const isActivePage: boolean =
      !!page?.url &&
      checkIsActivePage({
        currentPath: getPathWithoutOrg(pathname),
        pageUrl: getPathWithoutOrg(page.url),
      });

    return (
      <SectionNavItem
        key={page.id}
        id={page.id}
        className={`section-page-${page.id}`}
        isActive={isActivePage}
        data-testid={page.id}
        isDisabled={page?.isDisabled}
      >
        {hasSubpages && (
          <SectionNavControl
            isActive={isSectionActive}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setActiveAppNavSections &&
                setActiveAppNavSections((prevState) => {
                  if (prevState.includes(page.id as AppNavSection)) {
                    return prevState.filter((id) => id !== page.id);
                  }
                  return [...prevState, page.id] as AppNavSection[];
                });
            }}
          />
        )}
        {renderPageContent({ isActivePage, page })}
      </SectionNavItem>
    );
  };

  return (
    <StyledSectionNavSidebar hasBanner={!!bannerType}>
      <Flex relative>
        {sectionIcon && (
          <Box mt={rem(-4.5)} mr={2} mb={rem(-9)} ml={-1}>
            {sectionIcon}
          </Box>
        )}
        {sectionTitle && <SectionTitle as="h3">{sectionTitle}</SectionTitle>}
      </Flex>

      <StyledSectionNav aria-label={ariaLabel}>
        <SectionNavList>
          {pages.map((page) => {
            const hasSubpages = !!page?.pages && !!page.pages.length;
            const isSectionActive =
              hasSubpages &&
              !!activeAppNavSections &&
              activeAppNavSections.includes(page.id as AppNavSection);

            if (page?.isSectionLabel) {
              return (
                <Text
                  key={page.id}
                  as="h4"
                  mt={7}
                  mb={3}
                  styleName="label"
                  styles={{ color: theme.color.gray600 }}
                >
                  {page.label}
                </Text>
              );
            }
            if (hasSubpages) {
              return (
                <React.Fragment key={page.id}>
                  {renderPage(page, pathname, hasSubpages, isSectionActive)}

                  <SectionSubNavList isActive={isSectionActive}>
                    {page.pages!.map((page) => {
                      return renderPage(page, pathname);
                    })}
                  </SectionSubNavList>
                </React.Fragment>
              );
            } else {
              return renderPage(page, pathname);
            }
          })}
        </SectionNavList>
      </StyledSectionNav>
    </StyledSectionNavSidebar>
  );
};

export default SectionNav;
