import React from "react";
import { useTheme } from "@emotion/react";

import Button2 from "../../components/Button2";
import Flex from "../../components/Flex";
import { AUTH_FLOW_CONTENT_MAX_WIDTH } from "../../config/public";
import { IconArrowLeft } from "../../icons";
import Text from "../Text";
import Tooltip from "../Tooltip";

import { StyledMetaLinkFlex } from "./HeaderPublic.styled";

type HeaderPublicProps = {
  configPageTitle: {
    label: string;
    tooltipContent?: string;
    tooltipExtraLinkLabel?: string;
    tooltipExtraLinkUrl?: string;
  };
  configMetaLink?: {
    label: string;
    onClick?: (e: any) => void;
    to?: string;
  };
  subTitle?: string;
};

const HeaderPublic = ({
  configMetaLink,
  configPageTitle,
  subTitle,
}: HeaderPublicProps) => {
  const theme = useTheme();

  if (!configPageTitle) {
    return <></>;
  }

  const { label, tooltipContent } = configPageTitle;

  return (
    <>
      {configMetaLink && (
        <StyledMetaLinkFlex ml={-3}>
          <Button2
            type="text-quiet"
            icon={<IconArrowLeft />}
            label={configMetaLink.label}
            onClick={configMetaLink?.onClick}
            to={configMetaLink?.to}
          />
        </StyledMetaLinkFlex>
      )}

      <Flex mt={10} alignItems="center">
        <Text
          as="h1"
          styleName="marketing-header-4"
          styles={{ color: theme.color.gray800 }}
        >
          {label}
        </Text>

        {tooltipContent && <Tooltip ml={1}>{tooltipContent}</Tooltip>}
      </Flex>

      {subTitle && (
        <Text
          mt={2}
          styleName="body-1"
          styles={{ maxWidth: AUTH_FLOW_CONTENT_MAX_WIDTH }}
        >
          {subTitle}
        </Text>
      )}
    </>
  );
};

export default HeaderPublic;
