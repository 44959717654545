import { useCallback, useState } from "react";

import {
  createEntity,
  deleteEntity,
  getEntity,
  getEntityMetadata,
  getEntityRuns,
  updateEntity,
} from "../api/core/controlPlane";
import {
  AppEntityEndpoints,
  CreateEntityPayloads,
  EntityErrorMessage,
  GetEntityMetadataResponses,
  GetEntityResponses,
  RestEditMethod,
  RunsData,
  UpdateEntityPayloads,
} from "../api/core/controlPlane.types";
import { useUser } from "../AuthProvider";
import {
  ENDPOINT_ENSEMBLES,
  ENDPOINT_EXPERIMENTS_ACCEPTANCE,
  ENDPOINT_EXPERIMENTS_BATCH,
  ENDPOINT_EXPERIMENTS_SHADOW,
  ENDPOINT_EXPERIMENTS_SWITCHBACK,
  ENDPOINT_RUNS,
} from "../config/endpoints";
import { FUTUREparseTextAsJson } from "../utils/parseInputOutput";

const useManageEntity = <AppEntityEndpoint extends keyof AppEntityEndpoints>(
  endpoint?: AppEntityEndpoint
) => {
  const [{ id: accountId }] = useUser();

  const [entity, setEntity] = useState<
    GetEntityResponses[AppEntityEndpoint] | null
  >(null);
  const [entityMetadata, setEntityMetadata] = useState<
    GetEntityMetadataResponses[AppEntityEndpoint] | null
  >(null);
  const [entityAsString, setEntityAsString] = useState<string | null>(null);

  const [entityRuns, setEntityRuns] = useState<RunsData>(null);
  const [entityRunsNextPageToken, setEntityRunsNextPageToken] =
    useState<string>("");

  const [isEntityAdded, setIsEntityAdded] = useState(false);
  const [isEntityEdited, setIsEntityEdited] = useState(false);
  const [isEntityRemoved, setIsEntityRemoved] = useState(false);

  const [entityAddError, setEntityAddError] =
    useState<EntityErrorMessage>(null);
  const [entityEditError, setEntityEditError] =
    useState<EntityErrorMessage>(null);
  const [entityLoadError, setEntityLoadError] =
    useState<EntityErrorMessage>(null);

  const [entityLoadMetadataError, setEntityLoadMetadataError] =
    useState<EntityErrorMessage>(null);
  const [entityLoadRunsError, setEntityLoadRunsError] =
    useState<EntityErrorMessage>(null);
  const [entityRemoveError, setEntityRemoveError] =
    useState<EntityErrorMessage>(null);

  const addEntity = useCallback(
    async (
      appId: string = "",
      payload: CreateEntityPayloads[AppEntityEndpoint],
      shouldReturnEntity: boolean = false
    ): Promise<void | GetEntityResponses[AppEntityEndpoint]> => {
      if (!endpoint) {
        return;
      }
      try {
        const entity = await createEntity(accountId || "")(
          endpoint,
          payload,
          shouldReturnEntity,
          appId
        );
        setEntityAddError(null);
        setIsEntityAdded(true);

        return shouldReturnEntity ? entity : undefined;
      } catch (error: any) {
        console.error(error);
        setEntityAddError(error.toString());
      }
    },
    [accountId, endpoint]
  );

  const editEntity = useCallback(
    async (
      appId: string,
      entityId: string = "",
      payload: UpdateEntityPayloads[AppEntityEndpoint]
    ) => {
      if (!endpoint) {
        return;
      }
      const updateMethod: RestEditMethod =
        endpoint === ENDPOINT_ENSEMBLES ||
        endpoint === ENDPOINT_EXPERIMENTS_ACCEPTANCE ||
        endpoint === ENDPOINT_EXPERIMENTS_BATCH ||
        endpoint === ENDPOINT_EXPERIMENTS_SHADOW ||
        endpoint === ENDPOINT_EXPERIMENTS_SWITCHBACK
          ? "PATCH"
          : "PUT";
      try {
        await updateEntity(accountId || "")(
          appId,
          endpoint,
          payload,
          updateMethod,
          entityId
        );
        setEntityEditError(null);
        setIsEntityEdited(true);
        return;
      } catch (error: any) {
        console.error(error);
        setEntityEditError(error.toString());
      }
    },
    [accountId, endpoint]
  );

  // data is returned from control plane as text()
  // and then parsed to JSON for setEntity
  // setEntityAsString is used for lossless parsing
  const loadEntity = useCallback(
    async (appId: string, entityId: string = "", isReturnJSON?: boolean) => {
      if (!endpoint) {
        return;
      }
      try {
        const entityRes = await getEntity(accountId || "")(
          appId,
          endpoint,
          entityId
        );

        setEntityLoadError(null);
        setEntity(
          FUTUREparseTextAsJson(
            entityRes as unknown as string
          ) as GetEntityResponses[AppEntityEndpoint]
        );
        setEntityAsString(entityRes as unknown as string);
        return isReturnJSON
          ? (FUTUREparseTextAsJson(
              entityRes as unknown as string
            ) as GetEntityResponses[AppEntityEndpoint])
          : entityRes;
      } catch (error: any) {
        console.error(error);
        setEntityLoadError(error.toString());
      }
    },
    [accountId, endpoint]
  );

  const loadEntityMetadata = useCallback(
    async (appId: string, entityId: string) => {
      // not every endpoint has metadata counterpart
      if (
        !(
          endpoint === ENDPOINT_EXPERIMENTS_BATCH ||
          endpoint === ENDPOINT_EXPERIMENTS_SHADOW ||
          endpoint === ENDPOINT_EXPERIMENTS_SWITCHBACK ||
          endpoint === ENDPOINT_RUNS
        )
      ) {
        return;
      }

      try {
        const entityRes = await getEntityMetadata(accountId || "")(
          appId,
          endpoint,
          entityId
        );
        setEntityLoadMetadataError(null);
        setEntityMetadata(entityRes);
        return entityRes;
      } catch (error: any) {
        console.error(error);
        setEntityLoadMetadataError(error.toString());
      }
    },
    [accountId, endpoint]
  );

  const loadEntityRuns = useCallback(
    async (
      appId: string,
      entityId?: string,
      nextPageToken?: string,
      shouldAppend = false,
      queryStart?: string,
      queryEnd?: string
    ) => {
      if (
        !(
          endpoint === "experiments/batch" ||
          endpoint === "experiments/shadow" ||
          endpoint === "experiments/switchback"
        )
      ) {
        return;
      }

      try {
        const entityRunsRes = await getEntityRuns(accountId || "")(
          appId,
          endpoint,
          entityId,
          nextPageToken,
          queryStart,
          queryEnd
        );

        const entityRunsData = entityRunsRes.runs;

        setEntityLoadRunsError(null);
        setEntityRunsNextPageToken(entityRunsRes.next_page_token || "");
        setEntityRuns((prevState) => {
          return [
            ...(shouldAppend && prevState ? prevState : []),
            ...(entityRunsData ? entityRunsData : []),
          ];
        });

        return entityRunsData;
      } catch (error: any) {
        console.error(error);
        setEntityLoadRunsError(error.toString());
      }
    },
    [accountId, endpoint]
  );

  const removeEntity = useCallback(
    async (appId: string, entityId: string = "") => {
      if (!endpoint) {
        return;
      }
      try {
        await deleteEntity(accountId || "")(appId, endpoint, entityId);
        setEntityRemoveError(null);
        setIsEntityRemoved(true);
        return;
      } catch (error: any) {
        console.error(error);
        setEntityRemoveError(error.toString());
      }
    },
    [accountId, endpoint]
  );

  return {
    addEntity,
    editEntity,
    entityAsString,
    entity,
    entityAddError,
    entityEditError,
    entityLoadError,
    entityLoadMetadataError,
    entityLoadRunsError,
    entityMetadata,
    entityRemoveError,
    entityRuns,
    entityRunsNextPageToken,
    loadEntity,
    loadEntityMetadata,
    loadEntityRuns,
    isEntityAdded,
    isEntityEdited,
    isEntityRemoved,
    removeEntity,
    setEntityAddError,
  };
};

export default useManageEntity;
