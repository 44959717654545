import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { useUser } from "../../AuthProvider";
import { getAccUrl } from "../../utils/navigation";
import { userHasAccessToView } from "../../utils/rbac_utils";
import AccessDenied from "../AccessDenied";
import ExperimentRoutes from "../Experiments/Experiments.routes";

import AppDetails from "./subpages/AppDetails";
import EditAppDetails from "./subpages/EditAppDetails";
import EditEnsembleDef from "./subpages/EditEnsembleDef";
import EditInstanceDetails from "./subpages/EditInstanceDetails";
import EditRunDetails from "./subpages/EditRunDetails";
import EditVersionDetails from "./subpages/EditVersionDetails";
import EnsembleDef from "./subpages/EnsembleDef";
import RunsEnsembleDefs from "./subpages/EnsembleDefs";
import InstanceDetails from "./subpages/InstanceDetails";
import AppInstances from "./subpages/Instances";
import NewAppRun from "./subpages/NewAppRun";
import NewEnsembleDef from "./subpages/NewEnsembleDef";
import NewInstance from "./subpages/NewInstance";
import NewVersion from "./subpages/NewVersion";
import RunDetails from "./subpages/RunDetails";
import RunHistory from "./subpages/RunHistory";
import RunsQueue from "./subpages/RunsQueue";
import VersionDetails from "./subpages/VersionDetails";
import AppVersions from "./subpages/Versions";
import { AppPageProps } from "./App.types";

const AppRoutes = ({ app, displayPages, setDisplayPages }: AppPageProps) => {
  const [user] = useUser();
  const { id: accountId } = user;
  const { path } = useRouteMatch();
  const location = useLocation();
  const appId = app.id;

  const userCanView = userHasAccessToView(
    user.roles,
    path,
    location.pathname,
    app
  );

  if (!userCanView) {
    return <AccessDenied />;
  }

  return (
    <Switch>
      <Route exact path={path}>
        <AppDetails {...{ app }} />
      </Route>
      <Route exact path={`${path}/edit`}>
        <EditAppDetails {...{ app }} />
      </Route>

      {/* Runs (including Queue and Ensembling) */}
      <Route exact path={`${path}/runs`}>
        <RunHistory {...{ app }} />
      </Route>
      <Route exact path={`${path}/runs/queue`}>
        <RunsQueue {...{ app }} />
      </Route>
      <Route exact path={`${path}/runs/ensembles`}>
        <RunsEnsembleDefs {...{ app }} />
      </Route>
      <Route exact path={`${path}/runs/ensembles/new`}>
        <NewEnsembleDef {...{ app }} />
      </Route>
      <Route exact path={`${path}/runs/ensemble/:id`}>
        <EnsembleDef {...{ app }} />
      </Route>
      <Route exact path={`${path}/runs/ensemble/:id/edit`}>
        <EditEnsembleDef {...{ app }} />
      </Route>
      <Route exact path={`${path}/runs/new`}>
        <NewAppRun {...{ app, setDisplayPages }} />
      </Route>
      <Route exact path={`${path}/run/:id`}>
        <RunDetails {...{ app, displayPages, setDisplayPages }} />
      </Route>
      <Route exact path={`${path}/run/:id/edit`}>
        <EditRunDetails {...{ app, setDisplayPages }} />
      </Route>

      {/* add redirects for old run details pattern */}
      <Route
        exact
        path={`${path}/runs/:id`}
        render={(props) => (
          <Redirect
            to={getAccUrl(
              accountId,
              `/app/${appId}/run/${props.match.params.id}`
            )}
          />
        )}
      />
      <Route
        exact
        path={`${path}/runs/:id/edit`}
        render={(props) => (
          <Redirect
            to={getAccUrl(
              accountId,
              `/app/${appId}/run/${props.match.params.id}/edit`
            )}
          />
        )}
      />

      {/* Versions */}
      <Route exact path={`${path}/versions`}>
        <AppVersions {...{ app }} />
      </Route>
      <Route exact path={`${path}/versions/new`}>
        <NewVersion {...{ app }} />
      </Route>
      <Route exact path={`${path}/version/:id`}>
        <VersionDetails {...{ app }} />
      </Route>
      <Route exact path={`${path}/version/:id/edit`}>
        <EditVersionDetails {...{ app }} />
      </Route>
      <Route exact path={`${path}/version`}>
        <Redirect to={getAccUrl(accountId, `/app/${appId}/versions`)} />
      </Route>

      {/* Instances */}
      <Route exact path={`${path}/instances`}>
        <AppInstances {...{ app }} />
      </Route>
      <Route exact path={`${path}/instances/new`}>
        <NewInstance {...{ app }} />
      </Route>
      <Route exact path={`${path}/instance/:id`}>
        <InstanceDetails {...{ app }} />
      </Route>
      <Route exact path={`${path}/instance/:id/edit`}>
        <EditInstanceDetails {...{ app }} />
      </Route>
      <Route exact path={`${path}/instance`}>
        <Redirect to={getAccUrl(accountId, `/app/${appId}/instances`)} />
      </Route>

      <Route
        exact
        path={`${path}/api-reference`}
        render={() => <Redirect to={getAccUrl(accountId, `/app/${appId}`)} />}
      />

      {/* EXPERIMENTS */}
      <Route exact path={`${path}/experiments`}>
        <Redirect
          to={getAccUrl(accountId, `/app/${appId}/experiments/scenario`)}
        />
      </Route>
      <Route exact path={`${path}/experiment`}>
        <Redirect
          to={getAccUrl(accountId, `/app/${appId}/experiments/scenario`)}
        />
      </Route>

      {/* Pass all other routes to ExperimentRoutes for routing */}
      <Route path={`${path}/experiments`}>
        <ExperimentRoutes
          {...{ app, setDisplayPages }}
          path={`${path}/experiments`}
        />
      </Route>
      <Route path={`${path}/experiment`}>
        <ExperimentRoutes
          {...{ app, setDisplayPages }}
          path={`${path}/experiment`}
        />
      </Route>

      <Redirect to={getAccUrl(accountId, `/apps`)} />
    </Switch>
  );
};

export default AppRoutes;
