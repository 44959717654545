import { startCase } from "lodash";

import Text from "../../../components/Text";
import { PlanSet } from "../Experiments.types";

export const getPendingScenarioConfigOptionsHeaders = (
  scenarios: PlanSet[],
  theme: any
) => {
  const optionLabelSet = new Set<string>();

  const uniqueOptionSet = scenarios.reduce((optionSet, option) => {
    const configOptions = option.configOptions;
    configOptions.forEach((configOption) => {
      const optionLabel = configOption.option;
      if (optionLabel) {
        optionSet.add(optionLabel);
      }
    });
    return optionSet;
  }, optionLabelSet);

  const uniqueOptionLabels = Array.from(uniqueOptionSet);

  return uniqueOptionLabels.map((label) => {
    return {
      id: label,
      accessorKey: label,
      value: startCase(label),
      header: label,
      label: startCase(label),
      cell: (props: any) => {
        return (
          <Text
            as="span"
            styleName="code"
            styles={{
              display: "block",
              fontSize: theme.ui2Typography.fontSizeMeta1,
              textAlign: "right",
              textRendering: "auto",
              WebkitFontSmoothing: "auto",
            }}
          >
            {props.getValue()}
          </Text>
        );
      },
    };
  });
};
