import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import Box from "../../../components/Box";
import Text from "../../../components/Text";
import { StyledNewsItemLink } from "../Dashboard.styled";
import { NextmvBlogRSSItem } from "../Dashboard.types";
import { getNextmvBlogPosts } from "../utils/getNextmvBlogPosts";
import { renderDashboardSectionTitle } from "../utils/renderDashboardSectionTitle";

const BlogFeed = () => {
  const theme = useTheme();
  const [blogPosts, setBlogPosts] = useState<NextmvBlogRSSItem[]>();

  if (!blogPosts) {
    getNextmvBlogPosts(5)
      .then((nextmvBlogPosts) => setBlogPosts(nextmvBlogPosts))
      .catch((_error) => {
        setBlogPosts([]);
      });
  }

  return (
    <Box pb={2}>
      {renderDashboardSectionTitle({
        title: "News & Updates",
        description: "From Nextmv",
        url: "https://www.nextmv.io/blog",
        isExternalUrl: true,
        theme,
      })}

      <Box width="100%">
        {blogPosts &&
          blogPosts.map((blogPost) => (
            <StyledNewsItemLink
              key={blogPost.guid}
              href={blogPost.link}
              target="_blank"
              rel="noreferrer"
              title={blogPost.content}
            >
              <Text
                as="h4"
                mt={4}
                styleName="body-1-bold"
                styles={{
                  lineHeight: theme.ui2Typography.lineHeightBody2,
                  color: theme.color.gray800,
                  // truncate text after two lines
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                }}
              >
                {blogPost.title}
              </Text>

              <Text
                as="time"
                mt={1}
                styleName="body-3"
                styles={{ display: "block", color: theme.color.gray600 }}
                htmlDatetime={blogPost.isoDate}
              >
                {DateTime.fromISO(blogPost.isoDate).toFormat("MMMM d, yyyy")}
              </Text>
            </StyledNewsItemLink>
          ))}
      </Box>
    </Box>
  );
};

export default BlogFeed;
