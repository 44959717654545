import * as React from "react";

import {
  TooltipContainer,
  TooltipItem,
  TooltipLabel,
  TooltipList,
} from "../../../components/Chart/custom/Tooltip";
import Text from "../../../components/Text";

type MonthlyUsageCustomTooltipProps = {
  active?: boolean;
  payload?: any[];
};

// NOTE: active and payload props are passed from parent Chart
// component, see for example:
// https://recharts.org/en-US/examples/CustomContentOfTooltip
const MonthlyUsageCustomTooltip = ({
  active,
  payload,
}: MonthlyUsageCustomTooltipProps) => {
  const tooltipTitle =
    payload && !!payload.length && payload[0].payload.tooltipTitle;

  return !!active ? (
    <TooltipContainer>
      {tooltipTitle &&
        tooltipTitle
          .split(", ")
          .map((tooltipTitleEntity: string, index: number) => {
            return (
              <TooltipLabel key={`tooltip-title-${index}`}>
                {tooltipTitleEntity}
              </TooltipLabel>
            );
          })}

      <TooltipList>
        {!!payload?.length &&
          payload.map((datum: { dataKey: string; value: number }) => {
            return (
              <TooltipItem key={datum.dataKey}>
                <Text styleName="meta-1">
                  Successful API calls:{" "}
                  <Text
                    as="strong"
                    styleName="meta-1-bold"
                    styles={{ fontFeatureSettings: "tnum" }}
                  >
                    {datum.value.toLocaleString()}
                  </Text>
                </Text>
              </TooltipItem>
            );
          })}
      </TooltipList>
    </TooltipContainer>
  ) : (
    <></>
  );
};

export default MonthlyUsageCustomTooltip;
