import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Flex from "../../components/Flex";
import { rem } from "../../utils/tools";

import { StyledBinProps } from "./Bin.types";

type RenderBoxShadowParams = {
  borderColor: string;
  isMini: boolean;
  theme: any;
};
const renderBoxShadow = ({
  borderColor,
  isMini,
  theme,
}: RenderBoxShadowParams) => {
  if (isMini) {
    return `0 ${rem(1)} 0 0 ${theme.color.gray200}`;
  }
  return `
    0 0 0 ${theme.border.widthMediumShadowOnly} ${borderColor},
    0 ${rem(2)} ${rem(24)} ${rem(-4)} ${theme.color.gray200T},
    0 ${rem(4)} ${rem(11)} ${rem(-9)} ${theme.color.gray300T}
  `;
};

export const StyledBin = styled(Flex)<StyledBinProps>`
  border-radius: ${({ theme }) => theme.border.radiusSmall};
  box-shadow: ${(props) =>
    renderBoxShadow({
      borderColor: props.borderColor,
      isMini: props.isMini,
      theme: props.theme,
    })};
  background-color: ${({ theme }) => theme.color.white};

  ${(props) =>
    props.isMini &&
    `
      border-width: ${props.theme.border.width};
      border-style: ${props.theme.border.style};
      border-color: ${props.theme.color.gray200};
      border-radius: ${props.theme.border.radiusLarge};
    `}

  ${(props) =>
    props.styles &&
    `
      ${css(props.styles).styles}
    `}
`;

export const StyledTitleLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;

  h3 {
    color: ${({ theme }) => theme.color.gray800};
  }
  &:hover {
    h3 {
      color: ${({ theme }) => theme.color.orange700};
    }
    svg {
      fill: ${({ theme }) => theme.color.orange600};
    }
  }
  &:active {
    h3 {
      color: ${({ theme }) => theme.color.orange700};
    }
    svg {
      fill: ${({ theme }) => theme.color.orange600};
    }
  }
`;
