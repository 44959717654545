import { useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import { IconCaretY, IconChevronRight } from "../../../icons";
import { rem } from "../../../utils/tools";
import Tag from "../../Tag";
import Text from "../../Text";
import Tooltip from "../../Tooltip";
import {
  StyledPageSwitcherContainer,
  StyledPageSwitcherItem,
  StyledPageSwitcherLink,
  StyledPageSwitcherList,
} from "../Header.styled";
import { PageSwitchOption } from "../Header.types";

type TitleItemProps = {
  label: string;
  icon?: React.ReactNode;
  isEarlyAccess?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  shouldTitleShorten?: boolean;
  switchOptions?: PageSwitchOption[];
  testId?: string;
  tooltipContent?: string;
  tooltipExtraLinkLabel?: string;
  tooltipExtraLinkUrl?: string;
  url?: string;
};

const TitleItem = ({
  icon,
  isEarlyAccess,
  isFirst,
  isLast,
  label,
  shouldTitleShorten,
  switchOptions,
  testId,
  tooltipContent,
  tooltipExtraLinkLabel,
  tooltipExtraLinkUrl,
  url,
}: TitleItemProps) => {
  const theme = useTheme();

  const [isPageSwitcherActive, setIsPageSwitcherActive] = useState(false);

  const handlePageSwitcherDisplay = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    return setIsPageSwitcherActive(!isPageSwitcherActive);
  };

  const renderPageSwitcher = (options: PageSwitchOption[]) => {
    return (
      <StyledPageSwitcherContainer
        onClick={(e) => handlePageSwitcherDisplay(e)}
      >
        <IconCaretY iconSize={20} iconColor={theme.color.gray600} />

        <StyledPageSwitcherList
          id="page-switcher"
          isActive={isPageSwitcherActive}
        >
          {options.map((option) => (
            <StyledPageSwitcherItem key={option.id}>
              <StyledPageSwitcherLink
                to={option.url}
                onClick={() => setIsPageSwitcherActive(!isPageSwitcherActive)}
              >
                {option.label}
              </StyledPageSwitcherLink>
            </StyledPageSwitcherItem>
          ))}
        </StyledPageSwitcherList>
      </StyledPageSwitcherContainer>
    );
  };

  return (
    <Flex mt={[!isFirst ? 2 : 0, !isFirst ? 2 : 0, 0]} alignItems="center">
      {icon && (
        <Flex
          mr={2}
          mt={rem(1)}
          minHeight={theme.spacing.s7}
          alignItems="center"
        >
          {icon}
        </Flex>
      )}

      {switchOptions &&
        !!switchOptions.length &&
        renderPageSwitcher(switchOptions)}

      <Text
        htmlTitle={label}
        testId={testId}
        as={isFirst ? "h1" : "h3"}
        styleName="header-1"
        styles={{
          maxWidth: shouldTitleShorten ? rem(120) : rem(300),
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          color: theme.color.gray800,
          a: { color: theme.color.gray800, textDecoration: "none" },
        }}
      >
        {url ? <Link to={url}>{label}</Link> : label}
      </Text>

      {tooltipContent && (
        <Box ml={1}>
          <Tooltip
            children={tooltipContent}
            extraLinkLabel={tooltipExtraLinkLabel}
            extraLinkUrl={tooltipExtraLinkUrl}
          />
        </Box>
      )}

      {isEarlyAccess && (
        <Tag
          mt={-2}
          ml={[0, 0, 0, 2]}
          order={[0, 0, 0, 1]}
          type="early-access"
        />
      )}

      {!isLast && (
        <Flex alignItems="center" mr={1} ml={1}>
          <IconChevronRight />
        </Flex>
      )}
    </Flex>
  );
};

export default TitleItem;
