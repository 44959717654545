import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import Flex from "../../components/Flex";
import { filterProps } from "../../utils/filterProps";

import { StyledPreviewItemLinkProps } from "./PreviewItem.types";

export const StyledPreviewItemFlex = styled(Flex)`
  .preview-item {
    height: 100%;
    min-height: 100%;
  }
`;

export const StyledPreviewItemInnerFlex = styled(Flex)`
  ${(props) =>
    props.isFeatured &&
    `
      border-color: ${props.theme.color.yellow600};
  `}
`;

export const StyledPreviewItemLink = styled(Link, {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledPreviewItemLinkProps>`
  display: block;
  width: 100%;
  text-decoration: none;
  border-radius: ${({ theme }) => theme.border.radius};
  box-shadow: 0 ${({ theme }) => theme.border.width} 0 0
    ${({ theme }) => theme.color.gray200};

  &:hover,
  &:active {
    .preview-item {
      h2,
      p {
        color: ${({ theme }) => theme.color.black};
      }
      border-color: ${({ theme }) => theme.color.gray300};
      box-shadow: 0 ${({ theme }) => theme.border.width} 0 0
        ${({ theme }) => theme.color.gray300};
    }
  }

  &:focus-visible {
    outline: none;

    .preview-item {
      border-color: ${({ theme }) => theme.color.gray400};
      box-shadow: 0 0 0 ${({ theme }) => theme.border.widthMediumShadowOnly}
        ${({ theme }) => theme.color.gray400};
    }
  }

  ${(props) =>
    props.isFeatured &&
    `
      box-shadow:
        0 ${props.theme.border.width} 0 0 ${props.theme.color.yellow600}
      ;
      &:hover,
      &:active {
        .preview-item {
          border-color: ${props.theme.color.orange500};
          box-shadow:
            0 ${props.theme.border.width} 0 0 ${props.theme.color.orange500}
          ;
        }
        .preview-item-featured-tag {
          background-color: ${props.theme.color.orange500};
        }
      }
  `}
`;

export const StyledTagList = styled(Flex)`
  position: absolute;
  right: ${({ theme }) => theme.spacing.s3};
  top: ${({ theme }) => theme.spacing.s1};
`;
