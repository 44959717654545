import { CSSStylesProps } from "../../../utils/renderExtraStyles";
import { rem } from "../../../utils/tools";
import { GetButtonStylesParams } from "../Button2.types";

export const getButtonLoadingDotColor = ({
  isDisabled,
  type,
  theme,
}: GetButtonStylesParams) => {
  if (isDisabled) {
    switch (type) {
      case "outline-quiet":
      case "text-quiet":
      case "outline":
      case "text":
        return theme.color.gray500;
      default:
        return theme.color.white;
    }
  }

  switch (type) {
    case "outline-quiet":
    case "text-quiet":
      return theme.color.gray600;
    case "outline":
    case "text":
      return theme.color.orange500;
    default:
      return theme.color.white;
  }
};

export const getDisabledButtonStyles = ({
  isDisabled,
  theme,
  type,
}: GetButtonStylesParams) => {
  if (!isDisabled) return;

  switch (type) {
    case "outline":
    case "outline-quiet":
      return `
        color: ${theme.color.gray500};
        box-shadow: inset 0 0 0 ${rem(1.5)} ${theme.color.gray400};
        svg {
          fill: ${theme.color.gray500};
        }
        &:hover, &:focus, &:active {
          color: ${theme.color.gray500};
          box-shadow: inset 0 0 0 ${rem(1.5)} ${theme.color.gray400};
          svg {
            fill: ${theme.color.gray500};
          }
        }
        &:focus-visible {
          outline: none;
          box-shadow: inset 0 0 0 ${rem(1.5)} ${theme.color.gray400} !important;
        }
      `;
    case "text":
    case "text-quiet":
      return `
        color: ${theme.color.gray500};
        svg {
          fill: ${theme.color.gray500};
        }
        &:hover, &:focus, &:active {
          color: ${theme.color.gray500};
          svg {
            fill: ${theme.color.gray500};
          }
        }
        &:focus-visible {
          outline: none;
          box-shadow: inset 0 0 0 ${rem(1.5)} ${theme.color.gray400} !important;
        }
      `;
    default:
      return `
        background-color: ${theme.color.gray500};  
        &:hover {
          background-color: ${theme.color.gray500};
        }
      `;
  }
};

export const getButtonSizeStyles = ({
  hasIcon,
  hasIcon2,
  isMultiSelect,
  size,
  theme,
}: GetButtonStylesParams) => {
  switch (size) {
    case "large":
      return `
        height: ${theme.spacing.s8};
        padding-right: ${
          isMultiSelect ? 0 : hasIcon2 ? theme.spacing.s3 : theme.spacing.s4
        };
        padding-left: ${
          isMultiSelect ? 0 : hasIcon ? theme.spacing.s3 : theme.spacing.s4
        };
        font-family: ${theme.ui2Typography.fontFamilyBody2Bold};
        font-size: ${theme.ui2Typography.fontSizeBody2Bold};
        font-style: ${theme.ui2Typography.fontStyleBody2Bold};
        font-weight: ${theme.ui2Typography.fontWeightBody2Bold};
        line-height: ${theme.ui2Typography.lineHeightBody2Bold};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody2Bold};
        text-transform: ${theme.ui2Typography.textTransformBody2Bold};
      `;
    case "small":
      return `
        height: ${rem(28)};
        padding-right: ${isMultiSelect ? 0 : theme.spacing.s2};
        padding-left: ${isMultiSelect ? 0 : theme.spacing.s2};
        font-family: ${theme.ui2Typography.fontFamilyMeta1Bold};
        font-size: ${theme.ui2Typography.fontSizeMeta1Bold};
        font-style: ${theme.ui2Typography.fontStyleMeta1Bold};
        font-weight: ${theme.ui2Typography.fontWeightMeta1Bold};
        line-height: ${theme.ui2Typography.lineHeightMeta1Bold};
        letter-spacing: ${theme.ui2Typography.letterSpacingMeta1Bold};
        text-transform: ${theme.ui2Typography.textTransformMeta1Bold};
      `;
    default:
      return `
        height: ${theme.spacing.s7};
        padding-right: ${isMultiSelect ? 0 : theme.spacing.s3};
        padding-left: ${isMultiSelect ? 0 : theme.spacing.s3};
        font-family: ${theme.ui2Typography.fontFamilyBody3Bold};
        font-size: ${theme.ui2Typography.fontSizeBody3Bold};
        font-style: ${theme.ui2Typography.fontStyleBody3Bold};
        font-weight: ${theme.ui2Typography.fontWeightBody3Bold};
        line-height: ${theme.ui2Typography.lineHeightBody3Bold};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody3Bold};
        text-transform: ${theme.ui2Typography.textTransformBody3Bold};
      `;
  }
};

export const getButtonTypeStyles = ({
  isMultiSelect,
  theme,
  type,
}: GetButtonStylesParams) => {
  switch (type) {
    case "outline":
      return `
        color: ${theme.color.orange500};
        background-color: transparent;
        box-shadow: inset 0 0 0 ${rem(1.5)} ${theme.color.orange400};

        &:hover {
          color: ${theme.color.orange700};
          box-shadow: inset 0 0 0 ${rem(1.5)} ${
        isMultiSelect ? theme.color.orange400 : theme.color.orange500
      } !important;
      
          svg {
            fill: ${theme.color.orange600};
          }
        }
      
        &:focus-visible {
          outline: none;
          box-shadow: inset 0 0 0 ${rem(1.5)} ${
        isMultiSelect ? theme.color.orange400 : theme.color.orange600
      },
            0 0 0 ${rem(3)} ${theme.color.orange200} !important;

          svg {
            fill: ${theme.color.orange700};
          }
        }
      `;
    case "outline-quiet":
      return `
        color: ${theme.color.gray600};
        background-color: transparent;
        box-shadow: inset 0 0 0 ${rem(1.5)} ${theme.color.gray500};
      
        &:hover {
          color: ${theme.color.gray700};
          box-shadow: inset 0 0 0 ${rem(1.5)} ${theme.color.gray600};
      
          svg {
            fill: ${theme.color.gray600};
          }
        }
        &:focus-visible {
          outline: none;
          box-shadow: inset 0 0 0 ${rem(1.5)} ${theme.color.gray700},
            0 0 0 ${rem(3)} ${theme.color.gray300} !important;

          svg {
            fill: ${theme.color.gray700};
          }
        }
      `;
    case "text":
      return `
        color: ${theme.color.orange500};
        background-color: transparent;
      
        &:hover {
          color: ${theme.color.orange700};
      
          svg {
            fill: ${theme.color.orange600};
          }
        }
        &:focus-visible {
          svg {
            fill: ${theme.color.orange700};
          }
        }
      `;
    case "text-quiet":
      return `
        color: ${theme.color.gray600};
        background-color: transparent;
      
        &:hover {
          color: ${theme.color.gray700};
      
          svg {
            fill: ${theme.color.gray700};
          }
        }
        &:focus-visible {
          svg {
            fill: ${theme.color.gray700};
          }
        }
      `;
    default:
      return `
        background-color: ${theme.color.orange400};  
        color: ${theme.color.white};

        svg {
          fill: ${theme.color.white};
          opacity: 0.9;
        }
        
        &:hover {
          background-color: ${theme.color.orange500};

          svg {
           opacity: 1
          }
        }
        &:focus-visible {
          svg {
            opacity: 1;
          }
        }
      `;
  }
};

export const getMultiSelectControlBackgroundStyles = ({
  theme,
  type,
}: GetButtonStylesParams): CSSStylesProps => {
  if (type === "primary") {
    return {
      backgroundColor: "transparent",
      svg: {
        fill: theme.color.white,
        opacity: 0.8,
      },
      "&:hover, &:focus-visible, &:active": {
        backgroundColor: "transparent",
        svg: {
          fill: theme.color.white,
          opacity: 1,
        },
      },
    };
  }
  return {
    backgroundColor: theme.color.white,
    "&:hover, &:focus-visible, &:active": {
      backgroundColor: theme.color.white,
      svg: {
        fill: theme.color.orange700,
      },
    },
  };
};

export const getMultiSelectOptionBackgroundStyles = ({
  theme,
  type,
}: GetButtonStylesParams): CSSStylesProps => {
  if (type === "primary") {
    return {
      backgroundColor: theme.color.orange400,
    };
  }
  return {
    backgroundColor: theme.color.white,
  };
};
