import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconMainNavTeam = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      iconSize={iconSize || 42}
      viewBox={[0, 0, 42, 42]}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.75 30C28.5449 30 30 28.5449 30 26.75C30 24.9551 28.5449 23.5 26.75 23.5C24.9551 23.5 23.5 24.9551 23.5 26.75C23.5 28.5449 24.9551 30 26.75 30ZM26.75 31.5C29.3734 31.5 31.5 29.3734 31.5 26.75C31.5 24.1266 29.3734 22 26.75 22C24.1266 22 22 24.1266 22 26.75C22 29.3734 24.1266 31.5 26.75 31.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 30C17.0449 30 18.5 28.5449 18.5 26.75C18.5 24.9551 17.0449 23.5 15.25 23.5C13.4551 23.5 12 24.9551 12 26.75C12 28.5449 13.4551 30 15.25 30ZM15.25 31.5C17.8734 31.5 20 29.3734 20 26.75C20 24.1266 17.8734 22 15.25 22C12.6266 22 10.5 24.1266 10.5 26.75C10.5 29.3734 12.6266 31.5 15.25 31.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.75 18.5C28.5449 18.5 30 17.0449 30 15.25C30 13.4551 28.5449 12 26.75 12C24.9551 12 23.5 13.4551 23.5 15.25C23.5 17.0449 24.9551 18.5 26.75 18.5ZM26.75 20C29.3734 20 31.5 17.8734 31.5 15.25C31.5 12.6266 29.3734 10.5 26.75 10.5C24.1266 10.5 22 12.6266 22 15.25C22 17.8734 24.1266 20 26.75 20Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 18.5C17.0449 18.5 18.5 17.0449 18.5 15.25C18.5 13.4551 17.0449 12 15.25 12C13.4551 12 12 13.4551 12 15.25C12 17.0449 13.4551 18.5 15.25 18.5ZM15.25 20C17.8734 20 20 17.8734 20 15.25C20 12.6266 17.8734 10.5 15.25 10.5C12.6266 10.5 10.5 12.6266 10.5 15.25C10.5 17.8734 12.6266 20 15.25 20Z"
      />
    </BaseIcon>
  );
};

export default IconMainNavTeam;
