import React, { useEffect, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { useUser } from "../../../../AuthProvider";
import Box from "../../../../components/Box";
import Button2 from "../../../../components/Button2";
import Flex from "../../../../components/Flex";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import Notification from "../../../../components/Notification";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import useRunDetails from "../../../../contexts/apps/hooks/useRunDetails";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { rem } from "../../../../utils/tools";
import { appTooltipCopy } from "../../../Apps/data/microcopy";
import { AppPageProps } from "../../App.types";
import useReturnPaths from "../../hooks/useReturnPaths";
import { trackRunDetailsFieldChanges } from "../../utils/runDetails";

const EditRunDetails = ({ app, setDisplayPages }: AppPageProps) => {
  const [{ id: accId }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const { search } = useLocation();
  const params = useParams() as { id: string };
  const theme = useTheme();
  const { returnPath } = useReturnPaths();

  const [submitted, setSubmitted] = useState(false);

  const {
    editRunDetails,
    loadRunMetadata,
    runDetailsActionError,
    runDetailsActionLoading,
    runMetadata,
    runMetadataError,
  } = useRunDetails();

  const { loadRuns } = useAppCollection();

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "runs", true);

  // load run metadata
  useEffect(() => {
    if (!runMetadata) {
      loadRunMetadata(app.id, params.id);
    }
  }, [app.id, loadRunMetadata, params.id, runMetadata]);

  // page display
  useEffect(() => {
    if (runMetadata) {
      setMetaTitle(runMetadata.name ? `Edit ${runMetadata.name}` : "Edit Run");
    }
    setDisplayPages && setDisplayPages(true);
  }, [runMetadata, setDisplayPages, setMetaTitle]);

  // pre-fill pending run with original values
  useEffect(() => {
    if (runMetadata && !pendingStandardInputs.id) {
      updateStandardInputs([
        { key: "name", value: runMetadata.name },
        { key: "id", value: runMetadata.id },
        { key: "description", value: runMetadata.description },
      ]);
    }
  }, [pendingStandardInputs.id, runMetadata, updateStandardInputs]);

  const handleRunDetailsSave = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!runMetadata) return null;

    const payload = { ...pendingStandardInputs };

    trackRunDetailsFieldChanges(runMetadata, pendingStandardInputs);

    trackEvent("RunHistory", {
      view: "Edit Run Details",
      action: "Edit Run Details Saved",
    });

    const runDetailsResponse = await editRunDetails(
      app.id,
      runMetadata.id,
      payload
    );
    if (!!runDetailsResponse) {
      loadRunMetadata(app.id, runMetadata.id);
      loadRuns({ applicationId: app.id });
    }
    setSubmitted(true);
  };

  const runError = runMetadataError || runDetailsActionError;

  if (!submitted && runError) {
    return <StandardError errorMessage={runError} />;
  }
  if (!runMetadata || runDetailsActionLoading) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }
  if (submitted && !runDetailsActionError) {
    return <Redirect to={`${returnPath}${search ? search : ""}`} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name || !!standardInputsErrors.name;

  return (
    <>
      <Header
        configPageTitle={{
          label: runMetadata.name ? `Edit ${runMetadata.name}` : "Edit Run",
          parentLabel: "Runs",
          parentUrl: `/acc/${accId}/app/${app.id}/runs`,
        }}
      />

      <form onSubmit={(e) => handleRunDetailsSave(e)}>
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Run name",
                  testId: "edit-run-name-input",
                  type: "name",
                  trackEventCategory: "RunHistory",
                  trackEventProperties: {
                    view: "Edit Run Details",
                    action: "Field Changed",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          secondaryLabel="Read-only"
          tooltipCopy={appTooltipCopy.runIdEdit}
          render={
            <Box flexGrow={1} maxWidth={rem(408)}>
              <Input
                htmlType="text"
                isDisabled
                placeholder="Run ID"
                value={runMetadata.id}
                readOnly
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Run description",
                  testId: "edit-run-description-input",
                  type: "description",
                  trackEventCategory: "RunHistory",
                  trackEventProperties: {
                    view: "Edit Run Details",
                    action: "Field Changed",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <Flex
          hasBorderTop
          hasBorderWidthThick
          pt={4}
          mb={10}
          justifyContent="space-between"
        >
          <Box>
            {runDetailsActionError && (
              <Notification
                data-testid="edit-run-details-error-message"
                mt={1}
                mb={5}
                type="error"
                hasContactExtra
                message={`Error: ${runDetailsActionError}`}
              />
            )}
            <Flex alignItems="center">
              <Button2
                isDisabled={isActionButtonDisabled}
                size="large"
                htmlType="submit"
                label="Save"
              />
            </Flex>
          </Box>

          <Button2
            ml={2}
            type="text"
            size="large"
            label="Cancel"
            htmlType="button"
            to={returnPath}
            onClick={() =>
              trackEvent("RunHistory", {
                view: "Edit Run Details",
                action: "Edit Run Details Canceled",
              })
            }
          />
        </Flex>
      </form>
    </>
  );
};

export default EditRunDetails;
