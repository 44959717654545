import styled from "@emotion/styled";

import { rem } from "../../utils/tools";
import { sharedButtonStyles } from "../Button2/Button2.styled";
import {
  getButtonSizeStyles,
  getButtonTypeStyles,
} from "../Button2/utils/getButtonStyles";
import { inputSharedStyles } from "../Input/Input.styles";
import {
  getInputPaddingLeftStyle,
  getInputPaddingRightStyle,
} from "../Input/utils/getInputPadding";
import { getInputSizeStyle } from "../Input/utils/getInputSizeStyles";

// CSS overrides and additions
export const StyledPivotTable = styled.div`
  /* attribute containers */
  .pvtAxisContainer {
    background-color: ${({ theme }) => theme.color.gray200};
  }
  .pvtUnused {
    border-style: dashed;
    background-color: ${({ theme }) => theme.color.gray100};
  }
  .pvtVals {
    text-align: left;
    background-color: ${({ theme }) => theme.color.gray300};
  }

  .pvtRenderers .pvtDropdown {
    margin-left: ${({ theme }) => theme.spacing["-s1"]};
  }
  .pvtRenderers .pvtDropdown::before {
    content: "Render options";
    display: block;
    padding-bottom: ${({ theme }) => theme.spacing.s1};
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyLabel};
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeLabel};
    font-style: ${({ theme }) => theme.ui2Typography.fontStyleLabel};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightLabel};
    line-height: ${({ theme }) => theme.ui2Typography.lineHeightLabel};
    letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingLabel};
    text-transform: ${({ theme }) => theme.ui2Typography.textTransformLabel};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: ${({ theme }) => theme.color.gray600};
  }

  .pvtRenderers,
  .pvtVals,
  .pvtHorizList {
    vertical-align: top;
  }
  .pvtVals {
    padding-top: 0;
  }

  /*
    @SECTION
    draggable attributes
  */

  .pvtAxisContainer li {
    padding: 0;
    padding-top: ${({ theme }) => theme.spacing.s1};
    padding-top: ${rem(6)};
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody2};
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody2};
    font-style: ${({ theme }) => theme.ui2Typography.fontStyleBody2};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody2};
    line-height: ${({ theme }) => theme.ui2Typography.lineHeightBody2};
    letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingBody2};
    text-transform: ${({ theme }) => theme.ui2Typography.textTransformBody2};
    color: ${({ theme }) => theme.color.gray700};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    &:first-of-type {
      padding-top: 0;
    }
  }
  .pvtHorizList li {
    padding-left: ${({ theme }) => theme.spacing.s1};
  }
  .pvtAxisContainer .pvtAttr {
    display: inline-flex;
    align-items: center;
    padding: 0;
    padding-top: ${({ theme }) => theme.spacing.s1};
    padding-right: ${({ theme }) => theme.spacing.s2};
    padding-bottom: ${({ theme }) => theme.spacing.s1};
    padding-left: ${({ theme }) => theme.spacing.s2};
    border: 0;
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    background-color: ${({ theme }) => theme.color.gray100};
    box-shadow: 0 0 0 ${({ theme }) => theme.border.widthMediumShadowOnly}
      ${({ theme }) => theme.color.gray300T};
  }
  .pvtAxisContainer .pvtFilteredAttribute {
    font-style: ${({ theme }) => theme.ui2Typography.fontStyleBody2Bold};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody2Bold};
    &::before {
      content: "*";
    }
  }

  /* placeholder when dragging attribute */
  .pvtAxisContainer li.pvtPlaceholder {
    min-height: ${({ theme }) => theme.spacing.s4};
    margin-top: ${({ theme }) => theme.spacing.s1};
    padding-top: ${({ theme }) => theme.spacing.s1};
    padding-right: ${({ theme }) => theme.spacing.s2};
    padding-bottom: ${({ theme }) => theme.spacing.s1};
    padding-left: ${({ theme }) => theme.spacing.s2};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    border-color: ${({ theme }) => theme.color.gray400T};
  }

  /*
    @SECTION
    select dropdowns
    (majority styles copied from Select DSC component)
  */

  .pvtDropdown {
    width: ${rem(220)};
    margin: 0;
    margin-top: ${({ theme }) => theme.spacing.s1};
  }
  .pvtDropdownValue {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${({ theme }) => theme.spacing.s7};
    padding-right: ${({ theme }) => theme.spacing.s2};
    padding-left: ${({ theme }) => theme.spacing.s2};
    position: relative;
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody2};
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody2};
    font-style: ${({ theme }) => theme.ui2Typography.fontStyleBody2};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody2};
    line-height: ${({ theme }) => theme.ui2Typography.lineHeightBody2};
    letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingBody2};
    text-transform: ${({ theme }) => theme.ui2Typography.textTransformBody2};
    color: ${({ theme }) => theme.color.gray700};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    outline: 0 !important;
    border: 0;
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: 0 0 0 ${({ theme }) => theme.border.width}
      ${({ theme }) => theme.color.gray300T};
    z-index: 3;

    &:focus-visible {
      boxshadow: 0 0 0 ${({ theme }) => theme.border.width}
        ${({ theme }) => theme.color.gray400T};
    }
  }
  .pvtDropdownIcon {
    order: 1;
  }

  /* dropdown menu items */
  .pvtDropdownMenu {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: 0 0 0 ${({ theme }) => theme.border.width}
      ${({ theme }) => theme.color.gray300T};
    z-index: 4;

    // for downward orientation
    padding-bottom: ${({ theme }) => theme.spacing.s3};
    border-radius: 0;
    border-bottom-right-radius: ${({ theme }) => theme.border.radiusSmall};
    border-bottom-left-radius: ${({ theme }) => theme.border.radiusSmall};

    // "erase" the top border of the menu box
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: ${rem(2)};
      position: absolute;
      left: 0;
      top: ${rem(-2)};
      background-color: ${({ theme }) => theme.color.white};
    }
  }

  .pvtDropdownMenu .pvtDropdownValue {
    box-sizing: border-box;
    width: auto;
    margin: 0;
    margin-right: ${({ theme }) => theme.spacing.s2};
    margin-left: ${({ theme }) => theme.spacing.s2};
    color: ${({ theme }) => theme.color.gray700};
    box-shadow: none;

    &:hover {
      background-color: ${({ theme }) => theme.color.gray100};
    }
  }

  .pvtDropdownMenu .pvtDropdownActiveValue {
    color: ${({ theme }) => theme.color.gray800};
    background-color: ${({ theme }) => theme.color.sky200};
    background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7419 4.01017C12.0308 4.19907 12.1119 4.5864 11.923 4.87531L7.67301 11.3753C7.57285 11.5285 7.41059 11.6301 7.22904 11.6532C7.04749 11.6764 6.86491 11.6189 6.72949 11.4957L3.97949 8.99574C3.72408 8.76355 3.70525 8.36827 3.93744 8.11285C4.16964 7.85744 4.56492 7.83862 4.82033 8.07081L7.02794 10.0777L10.8768 4.19124C11.0657 3.90234 11.453 3.82127 11.7419 4.01017Z' fill='%231e2122'/%3e %3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right ${({ theme }) => theme.spacing.s2} center;
    background-size: ${({ theme }) => theme.spacing.s4};

    &:hover {
      background-color: ${({ theme }) => theme.color.sky200};
    }
  }

  /*
    @SECTION
    filter box
  */

  .pvtFilterBox {
    margin-top: ${({ theme }) => theme.spacing.s1};
    text-align: left;
    border: 0;
    border-radius: ${({ theme }) => theme.border.radiusLarge};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: 0 0 0 ${({ theme }) => theme.border.widthMediumShadowOnly}
      ${({ theme }) => theme.color.gray300T};
  }
  // title/label
  .pvtFilterBox h4 {
    margin: 0;
    margin-top: ${({ theme }) => theme.spacing.s1};
    margin-right: ${({ theme }) => theme.spacing.s7};
    margin-left: ${({ theme }) => theme.spacing.s7};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody2Bold};
    // line-height: ${({ theme }) => theme.ui2Typography.lineHeightBody3};
    word-break: break-all;
    color: ${({ theme }) => theme.color.gray800};
  }
  // filter controls
  .pvtFilterBox p {
    display: flex;
    flex-wrap: wrap;
    margin: ${({ theme }) => theme.spacing.s1};
    margin-right: ${({ theme }) => theme.spacing.s2};
    margin-left: ${({ theme }) => theme.spacing.s2};
  }
  .pvtCheckContainer {
    margin: 0;
  }
  .pvtFilterBox input[type="text"] {
    ${inputSharedStyles}
    ${(props) => getInputSizeStyle({ size: "small", theme: props.theme })}
    ${(props) =>
      getInputPaddingLeftStyle({
        hasIcon: false,
        size: "small",
        theme: props.theme,
      })}
    ${(props) =>
      getInputPaddingRightStyle({
        hasIcon: false,
        size: "small",
        theme: props.theme,
      })}
  }
  .pvtFilterBox br {
    display: none;
  }

  .pvtButton {
    ${sharedButtonStyles}
    ${(props) =>
      getButtonSizeStyles({
        hasIcon: false,
        hasIcon2: false,
        isMultiSelect: false,
        size: "small",
        theme: props.theme,
      })}
    ${(props) =>
      getButtonTypeStyles({
        isMultiSelect: false,
        theme: props.theme,
        type: "outline-quiet",
      })}
    width: ${rem(96)};
    height: ${({ theme }) => theme.spacing.s6};
    margin: 0;
    margin-right: ${({ theme }) => theme.spacing.s1};
  }

  .pvtCheckContainer {
    padding: 0;
    padding-top: ${({ theme }) => theme.spacing.s1};
    padding-bottom: ${({ theme }) => theme.spacing.s3};
    border-top-width: ${({ theme }) => theme.border.width};
    border-top-style: ${({ theme }) => theme.border.style};
    border-top-color: ${({ theme }) => theme.color.gray200T};
    border: 0;
  }
  .pvtCheckContainer p {
    margin-top: ${({ theme }) => theme.spacing.s1};
    margin-right: ${({ theme }) => theme.spacing.s2};
    margin-bottom: 0;
    margin-left: ${({ theme }) => theme.spacing.s2};
    padding: ${({ theme }) => theme.spacing.s1};
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody3};
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody3};
    font-style: ${({ theme }) => theme.ui2Typography.fontStyleBody3};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody3};
    line-height: ${({ theme }) => theme.ui2Typography.lineHeightBody3};
    letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingBody3};
    text-transform: ${({ theme }) => theme.ui2Typography.textTransformBody3};
    cursor: pointer;
    color: ${({ theme }) => theme.color.gray700};
    border-radius: ${({ theme }) => theme.border.radius};
    &:hover {
      background-color: ${({ theme }) => theme.color.gray100};
    }
  }

  .pvtCheckContainer p.selected {
    color: ${({ theme }) => theme.color.gray800};
    background-color: #f2f8ff;
    background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7419 4.01017C12.0308 4.19907 12.1119 4.5864 11.923 4.87531L7.67301 11.3753C7.57285 11.5285 7.41059 11.6301 7.22904 11.6532C7.04749 11.6764 6.86491 11.6189 6.72949 11.4957L3.97949 8.99574C3.72408 8.76355 3.70525 8.36827 3.93744 8.11285C4.16964 7.85744 4.56492 7.83862 4.82033 8.07081L7.02794 10.0777L10.8768 4.19124C11.0657 3.90234 11.453 3.82127 11.7419 4.01017Z' fill='%235785B7'/%3e %3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right ${({ theme }) => theme.spacing.s2} center;
    background-size: ${({ theme }) => theme.spacing.s4};

    &:hover {
      background-color: #f2f8ff;
    }
  }

  // only label that appears on hover (and spacer)
  .pvtCheckContainer p .pvtOnly,
  .pvtCheckContainer p .pvtOnlySpacer {
    box-sizing: border-box;
    width: ${({ theme }) => theme.spacing.s8};
    padding-left: ${({ theme }) => theme.spacing.s1};
  }
  .pvtCheckContainer p .pvtOnly {
    opacity: 0.67;
    &:hover {
      opacity: 1;
    }
  }

  /*
    @SECTION
    pivot table
  */

  .pvtTable {
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody2};
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody2};
    font-style: ${({ theme }) => theme.ui2Typography.fontStyleBody2};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody2};
    line-height: ${({ theme }) => theme.ui2Typography.lineHeightBody2};
    letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingBody2};
    text-transform: ${({ theme }) => theme.ui2Typography.textTransformBody2};
    color: ${({ theme }) => theme.color.gray700};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
  table.pvtTable thead tr th,
  table.pvtTable tbody tr th,
  table.pvtTable thead tr td,
  table.pvtTable tbody tr td {
    padding-top: ${({ theme }) => theme.spacing.s1};
    padding-right: ${({ theme }) => theme.spacing.s2};
    padding-bottom: ${({ theme }) => theme.spacing.s1};
    padding-left: ${({ theme }) => theme.spacing.s2};
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody2};
    border-width: ${({ theme }) => theme.border.width};
    border-style: ${({ theme }) => theme.border.style};
    border-color: #c8ddf3;
    background-color: #f2f8ff;
  }
  table.pvtTable thead tr td,
  table.pvtTable tbody tr td {
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyCode};
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta1};
    font-style: ${({ theme }) => theme.ui2Typography.fontStyleCode};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightCode};
    line-height: ${({ theme }) => theme.ui2Typography.lineHeightCode};
    letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingCode};
    text-transform: ${({ theme }) => theme.ui2Typography.textTransformCode};
    background-color: ${({ theme }) => theme.color.white};
    font-feature-settings: "ss04" 1;
  }

  table.pvtTable tbody tr th.pvtTotalLabel {
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyLabel};
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeLabel};
    font-style: ${({ theme }) => theme.ui2Typography.fontStyleLabel};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightLabel};
    line-height: ${({ theme }) => theme.ui2Typography.lineHeightCode};
    letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingLabel};
    text-transform: ${({ theme }) => theme.ui2Typography.textTransformLabel};
  }
  table.pvtTable tbody tr td.pvtTotal,
  table.pvtTable tbody tr td.pvtGrandTotal {
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightCodeBold};
  }
  table.pvtTable tbody tr td.pvtTotal:last-child {
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightCode};
  }

  /*
    @SECTION
    replace unicode chars with icons
  */

  // base
  .pvtDragHandle,
  .pvtDropdownIcon,
  .pvtTriangle,
  .pvtCloseX {
    display: block;
    width: ${({ theme }) => theme.spacing.s4};
    height: ${({ theme }) => theme.spacing.s4};
    overflow: hidden;
    text-indent: ${({ theme }) => theme.spacing["-s4"]};
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${({ theme }) => theme.spacing.s4};
    opacity: 0.7;

    &:hover,
    &:focus,
    &:active {
      opacity: 1;
      background-color: ${({ theme }) => theme.color.gray200T};
    }
  }

  // ▾
  .pvtTriangle,
  .pvtDropdownIcon {
    margin-left: ${({ theme }) => theme.spacing.s1};
    background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M4.26666 6.40002H11.7333L8 11.2L4.26666 6.40002Z' fill='%231e2122'/%3e %3c/svg%3e");
  }

  // ☰
  .pvtDragHandle {
    left: ${({ theme }) => theme.spacing.s2};
    top: ${({ theme }) => theme.spacing.s2};
    background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M2 3.5H14V5H2V3.5Z' fill='%231F2222'/%3e %3cpath d='M2 7.25H14V8.75H2V7.25Z' fill='%231F2222'/%3e %3cpath d='M2 11H14V12.5H2V11Z' fill='%231e2122'/%3e %3c/svg%3e");
  }

  // ×
  .pvtCloseX {
    right: ${({ theme }) => theme.spacing.s2};
    top: ${({ theme }) => theme.spacing.s2};
    background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.8092 5.13523C12.0689 4.87553 12.0689 4.45447 11.8092 4.19477C11.5495 3.93508 11.1285 3.93508 10.8688 4.19477L8.00199 7.06155L5.13521 4.19477C4.87551 3.93508 4.45446 3.93508 4.19476 4.19477C3.93506 4.45447 3.93506 4.87553 4.19476 5.13523L7.06154 8.002L4.19476 10.8688C3.93506 11.1285 3.93506 11.5495 4.19476 11.8092C4.45446 12.0689 4.87551 12.0689 5.13521 11.8092L8.00199 8.94245L10.8688 11.8092C11.1285 12.0689 11.5495 12.0689 11.8092 11.8092C12.0689 11.5495 12.0689 11.1285 11.8092 10.8688L8.94244 8.002L11.8092 5.13523Z' fill='black'/%3e %3c/svg%3e");
  }
`;
