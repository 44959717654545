import { useCallback, useState } from "react";

import { getWebhookEntities } from "../api/core/controlPlane";
import {
  EntityErrorMessage,
  LoadWebhookEntitiesParams,
  WebhookEntitiesData,
  WebhookEntitiesEndpoints,
} from "../api/core/controlPlane.types";
import { useUser } from "../AuthProvider";

const useManageWebhooks = <
  WebhookEntityEndpoint extends keyof WebhookEntitiesEndpoints
>(
  endpoint: WebhookEntityEndpoint
) => {
  const [{ id: accountId }] = useUser();

  const [webhookEntities, setWebhookEntities] = useState<
    WebhookEntitiesData[WebhookEntityEndpoint] | null
  >(null);
  const [webhookEntitiesLoadError, setWebhookEntitiesLoadError] =
    useState<EntityErrorMessage>(null);
  const [webhookEntitiesNextPageToken, setWebhookEntitiesNextPageToken] =
    useState<string>("");
  const [
    additionalWebhookEntitiesLoading,
    setAdditionalWebhookEntitiesLoading,
  ] = useState(false);

  const loadWebhookEntities = useCallback(
    async ({
      nextPageToken,
      shouldAppend = false,
      shouldPaginate = false,
      webhookId,
    }: LoadWebhookEntitiesParams) => {
      try {
        const webhookEntitiesRes = await getWebhookEntities(accountId || "")({
          entityEndpoint: endpoint,
          nextPageToken,
          shouldPaginate,
          webhookId,
        });

        const dataKey = "items";
        let entityData = shouldPaginate
          ? webhookEntitiesRes[dataKey]
          : webhookEntitiesRes;

        setWebhookEntitiesLoadError(null);
        setWebhookEntitiesNextPageToken(webhookEntitiesRes?.next_page_token);

        setWebhookEntities((prevState) => {
          return [
            ...(shouldAppend && prevState ? prevState : []),
            ...(entityData ? entityData : []),
          ];
        });
        setAdditionalWebhookEntitiesLoading(false);
        return entityData;
      } catch (error: any) {
        console.error(error);
        setAdditionalWebhookEntitiesLoading(false);
        setWebhookEntitiesLoadError(error.toString());
      }
    },
    [accountId, endpoint]
  );

  return {
    additionalWebhookEntitiesLoading,
    webhookEntities,
    webhookEntitiesLoadError,
    webhookEntitiesNextPageToken,
    loadWebhookEntities,
    setAdditionalWebhookEntitiesLoading,
    setWebhookEntities,
    setWebhookEntitiesLoadError,
  };
};

export default useManageWebhooks;
