import React, { useState } from "react";

import {
  ENDPOINT_APPS,
  ENDPOINT_ENSEMBLES,
  ENDPOINT_INPUTS,
  ENDPOINT_INSTANCES,
  ENDPOINT_RUNS,
  ENDPOINT_VERSIONS,
} from "../../config/endpoints";
import useManageEntities from "../../hooks/useManageEntities";
import useManageEntity from "../../hooks/useManageEntity";

import { AppContextProps, AppNavSection } from "./App.context.types";

export const AppContext = React.createContext<AppContextProps>({
  activeAppNavSections: [],
  additionalAppInputsLoading: false,
  additionalEnsembleDefsLoading: false,
  additionalInstancesLoading: false,
  additionalRunsLoading: true,
  additionalVersionsLoading: false,
  app: null,
  appLoadError: null,
  appInputs: [],
  appInputsLoadError: null,
  appInputsNextPageToken: "",
  editApp: () => new Promise((resolve) => resolve({})),
  ensembleDefinitions: [],
  ensembleDefinitionsLoadError: null,
  ensembleDefinitionsNextPageToken: "",
  instances: [],
  instancesError: null,
  instancesNextPageToken: "",
  loadApp: (id) => new Promise((resolve) => resolve({})),
  loadRuns: () => new Promise((resolve) => resolve([])),
  loadAppInputs: () => new Promise((resolve) => resolve([])),
  loadEnsembleDefs: () => new Promise((resolve) => resolve([])),
  loadInstances: () => new Promise((resolve) => resolve([])),
  loadVersions: () => new Promise((resolve) => resolve([])),
  runHistory: [],
  runsLoadError: null,
  runsNextPageToken: "",
  setActiveAppNavSections: () => null,
  setAdditionalAppInputsLoading: () => null,
  setAdditionalInstancesLoading: () => null,
  setAdditionalRunsLoading: () => null,
  setAdditionalVersionsLoading: () => null,
  setAdditionalEnsembleDefsLoading: () => null,
  setAppInputs: () => null,
  setRunHistory: () => null,
  setRunsLoadError: () => null,
  setEnsembleDefs: () => null,
  setVersions: () => null,
  setVersionsError: () => null,
  setInstances: () => null,
  setInstancesError: () => null,
  versions: [],
  versionsError: null,
  versionsNextPageToken: "",
});

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  // manage app navigation sidebar
  const [activeAppNavSections, setActiveAppNavSections] = useState<
    AppNavSection[]
  >([]);

  const {
    editEntity: editApp,
    entity: app,
    entityLoadError: appLoadError,
    loadEntity: loadApp,
  } = useManageEntity(ENDPOINT_APPS);

  const {
    additionalEntitiesLoading: additionalAppInputsLoading,
    entities: appInputs,
    entitiesLoadError: appInputsLoadError,
    entitiesNextPageToken: appInputsNextPageToken,
    loadEntities: loadAppInputs,
    setAdditionalEntitiesLoading: setAdditionalAppInputsLoading,
    setEntities: setAppInputs,
  } = useManageEntities(ENDPOINT_INPUTS);

  const {
    additionalEntitiesLoading: additionalEnsembleDefsLoading,
    entities: ensembleDefinitions,
    entitiesLoadError: ensembleDefinitionsLoadError,
    entitiesNextPageToken: ensembleDefinitionsNextPageToken,
    loadEntities: loadEnsembleDefs,
    setAdditionalEntitiesLoading: setAdditionalEnsembleDefsLoading,
    setEntities: setEnsembleDefs,
  } = useManageEntities(ENDPOINT_ENSEMBLES);

  const {
    additionalEntitiesLoading: additionalRunsLoading,
    entities: runHistory,
    entitiesLoadError: runsLoadError,
    entitiesNextPageToken: runsNextPageToken,
    loadEntities: loadRuns,
    setAdditionalEntitiesLoading: setAdditionalRunsLoading,
    setEntities: setRunHistory,
    setEntitiesLoadError: setRunsLoadError,
  } = useManageEntities(ENDPOINT_RUNS);

  const {
    additionalEntitiesLoading: additionalVersionsLoading,
    entities: versions,
    entitiesLoadError: versionsError,
    entitiesNextPageToken: versionsNextPageToken,
    loadEntities: loadVersions,
    setAdditionalEntitiesLoading: setAdditionalVersionsLoading,
    setEntities: setVersions,
    setEntitiesLoadError: setVersionsError,
  } = useManageEntities(ENDPOINT_VERSIONS);

  const {
    additionalEntitiesLoading: additionalInstancesLoading,
    entities: instances,
    entitiesLoadError: instancesError,
    entitiesNextPageToken: instancesNextPageToken,
    loadEntities: loadInstances,
    setAdditionalEntitiesLoading: setAdditionalInstancesLoading,
    setEntities: setInstances,
    setEntitiesLoadError: setInstancesError,
  } = useManageEntities(ENDPOINT_INSTANCES);

  const value: AppContextProps = {
    activeAppNavSections,
    additionalAppInputsLoading,
    additionalEnsembleDefsLoading,
    additionalInstancesLoading,
    additionalRunsLoading,
    additionalVersionsLoading,
    app,
    appInputs,
    appInputsLoadError,
    appInputsNextPageToken,
    appLoadError,
    editApp,
    ensembleDefinitions,
    ensembleDefinitionsLoadError,
    ensembleDefinitionsNextPageToken,
    instances,
    instancesError,
    instancesNextPageToken,
    loadApp,
    loadAppInputs,
    loadEnsembleDefs,
    loadRuns,
    loadVersions,
    loadInstances,
    runHistory,
    runsLoadError,
    runsNextPageToken,
    setActiveAppNavSections,
    setAdditionalAppInputsLoading,
    setAdditionalInstancesLoading,
    setAdditionalVersionsLoading,
    setAdditionalEnsembleDefsLoading,
    setAppInputs,
    setEnsembleDefs,
    setRunHistory,
    setRunsLoadError,
    setAdditionalRunsLoading,
    setVersions,
    setVersionsError,
    setInstances,
    setInstancesError,
    versions,
    versionsError,
    versionsNextPageToken,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppCollection = () => React.useContext(AppContext);

export default AppProvider;
