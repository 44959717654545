import styled from "@emotion/styled";

import Flex from "../../../../../Flex";

type RouteToggleFlexProps = {
  isDisabled: boolean;
  isMapMenuActive: boolean;
};
export const RouteToggleFlex = styled(Flex)<RouteToggleFlexProps>`
  height: ${({ theme }) => theme.spacing.s7};
  border-radius: ${({ theme }) => theme.border.radiusSmall};
  background-color: ${({ theme }) => theme.color.white};
  ${(props) =>
    props.isMapMenuActive &&
    `
    position: absolute;
    right: ${props.theme.spacing.s7};
  `}
  ${(props) =>
    !props.isMapMenuActive &&
    `
    box-shadow: 0 0 0 ${props.theme.border.widthThick}
    ${
      props.isDisabled ? props.theme.color.gray200T : props.theme.color.gray300T
    };
  `}
`;

type IconRouteToggleButtonProps = {
  isActive?: boolean;
  isDisabled?: boolean;
  isLeft?: boolean;
  isRight?: boolean;
};
export const IconRouteToggleButton = styled.button<IconRouteToggleButtonProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.isLeft ? "flex-end" : props.isRight ? "flex-start" : "center"};
  width: ${({ theme }) => theme.spacing.s7};
  height: ${({ theme }) => theme.spacing.s7};
  padding: 0;
  border: 0;
  background-color: transparent;

  ${(props) =>
    props.isLeft &&
    `
    border-top-left-radius: ${props.theme.border.radiusSmall};
    border-bottom-left-radius: ${props.theme.border.radiusSmall};
  `}

  ${(props) =>
    props.isRight &&
    `
    border-top-right-radius: ${props.theme.border.radiusSmall};
    border-bottom-right-radius: ${props.theme.border.radiusSmall};
  `}

  ${(props) =>
    !props.isDisabled &&
    `
    cursor: pointer;

    &:hover {
      svg {
        fill: ${
          props.isActive ? props.theme.color.teal600 : props.theme.color.gray600
        };
      }
    }
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 ${props.theme.border.widthThick} ${
      props.theme.color.teal500
    };
    }
  `}
`;
