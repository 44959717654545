export const ENDPOINT_APPS = "applications" as const;

export const ENDPOINT_ENSEMBLES = "ensembles" as const;
export const ENDPOINT_EXPERIMENTS_ACCEPTANCE =
  "experiments/acceptance" as const;
export const ENDPOINT_EXPERIMENTS_BATCH = "experiments/batch" as const;
export const ENDPOINT_EXPERIMENTS_INPUTSETS = "experiments/inputsets" as const;
export const ENDPOINT_EXPERIMENTS_SHADOW = "experiments/shadow" as const;
export const ENDPOINT_EXPERIMENTS_SWITCHBACK =
  "experiments/switchback" as const;
export const ENDPOINT_INPUTS = "inputs" as const;
export const ENDPOINT_INSTANCES = "instances" as const;
export const ENDPOINT_RUNS = "runs" as const;
export const ENDPOINT_VERSIONS = "versions" as const;
export const ENDPOINT_WEBHOOKS = "webhooks" as const;
export const ENDPOINT_WEBHOOKS_CONVERSATIONS = "conversations" as const;
export const ENDPOINT_WEBHOOKS_SECRET = "secret" as const;
