import { useEffect, useMemo } from "react";

import { TrackEvents } from "../../../../../analytics/functions";
import {
  AppResponse,
  EntityErrorMessage,
  RunType,
} from "../../../../../api/core/controlPlane.types";
import { useUser } from "../../../../../AuthProvider";
import {
  ConfigOption,
  HandleConfigOptionChangeParams,
} from "../../../../../components/AddConfigOptions/AddConfigOptions.types";
import Box from "../../../../../components/Box";
import Button2 from "../../../../../components/Button2";
import Flex from "../../../../../components/Flex";
import { InputSize } from "../../../../../components/Input/Input.types";
import InputRadio from "../../../../../components/InputRadio";
import { InstanceSelectInstanceIds } from "../../../../../components/InstanceSelect/InstanceSelect.types";
import Notification from "../../../../../components/Notification";
import PlanNotice from "../../../../../components/PlanNotice";
import Tooltip from "../../../../../components/Tooltip";
import { AcceptedFiles } from "../../../../../components/UploadFile/UploadFile.types";
import {
  RUN_TYPE_ENSEMBLE,
  RUN_TYPE_STANDARD,
} from "../../../../../config/apps";
import { ENDPOINT_ENSEMBLES } from "../../../../../config/endpoints";
import useManageEntities from "../../../../../hooks/useManageEntities";
import { StandardInputType } from "../../../../../hooks/useStandardInputs";
import { getIsUserPaid } from "../../../../../utils/authProviderHelpers";
import { ScrollYFlex } from "../../RunDetails/RunDetails.styled";
import { UseCustomInputReturnValue } from "../hooks/useCustomInput";

import EnsembleDefSelection from "./EnsembleDefSelection";
import InstanceSelection from "./InstanceSelection";
import RunInput from "./RunInput";
import RunNameDescription from "./RunNameDescription";
import RunOptions from "./RunOptions";

export type InputControlsProps = {
  addEmptyConfigOption: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  acceptedFiles: AcceptedFiles;
  app: AppResponse;
  fileProcessingError: EntityErrorMessage;
  getStandardInputsProps: <T extends keyof TrackEvents>({
    isDisabled,
    placeholder,
    testId,
    trackEventCategory,
    trackEventProperties,
    size,
    type,
  }: {
    placeholder: string;
    testId: string;
    type: StandardInputType;
    isDisabled?: boolean;
    size?: InputSize;
    trackEventCategory?: T;
    trackEventProperties?: TrackEvents[T];
  }) => any;
  handleConfigOptionChange: ({
    value,
    index,
    pendingConfigOptions,
    type,
  }: HandleConfigOptionChangeParams) => void;
  handleRunPrecheck: (e: any) => void;
  isCloneInputLoading: boolean;
  isPrecheckRunning: boolean;
  isRunning: boolean;
  pendingConfigOptions: ConfigOption[];
  pendingEnsembleDefId: string;
  pendingInstanceIds: InstanceSelectInstanceIds;
  removeConfigOption: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => void;
  runAddError: EntityErrorMessage;
  runDetailsActionError: string;
  pendingRunType: RunType;
  runInputState: UseCustomInputReturnValue;
  setAcceptedFiles: React.Dispatch<React.SetStateAction<AcceptedFiles>>;
  setPendingEnsembleDefId: React.Dispatch<React.SetStateAction<string>>;
  setPendingInstanceIds: React.Dispatch<
    React.SetStateAction<InstanceSelectInstanceIds>
  >;
  setPendingRunType: React.Dispatch<React.SetStateAction<RunType>>;
  cloneId?: string | null;
};

const InputControls = ({
  acceptedFiles,
  addEmptyConfigOption,
  app,
  cloneId,
  getStandardInputsProps,
  handleConfigOptionChange,
  handleRunPrecheck,
  isCloneInputLoading,
  isPrecheckRunning,
  isRunning,
  pendingConfigOptions,
  pendingEnsembleDefId,
  pendingInstanceIds,
  removeConfigOption,
  fileProcessingError,
  runAddError,
  runDetailsActionError,
  runInputState,
  pendingRunType,
  setAcceptedFiles,
  setPendingEnsembleDefId,
  setPendingInstanceIds,
  setPendingRunType,
}: InputControlsProps) => {
  const [user] = useUser();
  const isUserPaid = getIsUserPaid(user);
  const { inputState } = runInputState;

  const filteredPendingInstanceIds = useMemo(() => {
    return pendingInstanceIds.filter(Boolean);
  }, [pendingInstanceIds]);

  const isActionButtonDisabled = useMemo(() => {
    return (
      isRunning ||
      (pendingRunType === RUN_TYPE_STANDARD &&
        !filteredPendingInstanceIds.length) ||
      (!inputState.input && !acceptedFiles) ||
      runInputState.inputState.isInputInError
    );
  }, [
    acceptedFiles,
    filteredPendingInstanceIds.length,
    inputState.input,
    isRunning,
    pendingRunType,
    runInputState.inputState.isInputInError,
  ]);

  const isClone = !!cloneId;

  const createRunError = runAddError || runDetailsActionError;

  const {
    entities: ensembleDefs,
    entitiesLoadError: ensembleDefsLoadError,
    loadEntities: loadEnsembleDefs,
  } = useManageEntities(ENDPOINT_ENSEMBLES);

  // load ensemble definitions, only used for selecting
  // ensemble definitions and main NewAppRun.page is crowded
  useEffect(() => {
    if (!ensembleDefs && !ensembleDefsLoadError) {
      loadEnsembleDefs({ applicationId: app.id, shouldPaginate: true });
    }
  }, [app.id, ensembleDefs, ensembleDefsLoadError, loadEnsembleDefs]);

  return (
    <>
      <Flex
        alignItems="center"
        noShrink
        pt={4}
        pr={4}
        pb={4}
        pl={4}
        hasBorderBottom
      >
        <Button2
          data-testid="run-custom-app-button"
          size="large"
          isLoading={isRunning || isPrecheckRunning || isCloneInputLoading}
          isDisabled={isActionButtonDisabled}
          onClick={handleRunPrecheck}
          label="Start run"
        />

        <Flex ml={6} alignItems="center">
          <InputRadio
            id="create-new-run-type-standard"
            groupName="create-new-run-type"
            label="Standard"
            value={RUN_TYPE_STANDARD}
            checkValue={pendingRunType}
            handleChange={(e: { target: { value: RunType } }) => {
              setPendingRunType(e.target.value);
            }}
          />
          <InputRadio
            ml={3}
            id="create-new-run-type-ensemble"
            groupName="create-new-run-type"
            isDisabled={!isUserPaid}
            label="Ensemble"
            value={RUN_TYPE_ENSEMBLE}
            checkValue={pendingRunType}
            handleChange={(e: { target: { value: RunType } }) => {
              setPendingRunType(e.target.value);
            }}
          />
          {!isUserPaid && (
            <Tooltip
              ml={2}
              extraLinkLabel="What are ensemble runs?"
              extraLinkUrl="https://www.nextmv.io/docs/platform/run-app-remotely/runs-ensembling"
            >
              Ensemble runs are only available with paid plans.
            </Tooltip>
          )}
        </Flex>
      </Flex>

      <ScrollYFlex flexDirection="column">
        <PlanNotice mr={6} ml={6} mb={5} {...{ app }} type="custom-apps" />

        {createRunError && (
          <Box mt={4} mr={3} ml={3}>
            <Notification
              data-testid="new-run-error-message"
              width="100%"
              type="error"
              hasContactExtra
              message={createRunError}
            />
          </Box>
        )}

        {fileProcessingError && (
          <Box mt={4} mr={3} ml={3}>
            <Notification
              width="100%"
              type="error"
              message={fileProcessingError}
            />
          </Box>
        )}

        <RunInput
          app={app}
          acceptedFiles={acceptedFiles}
          isClone={isClone}
          isRunning={isRunning}
          runInputState={runInputState}
          setAcceptedFiles={setAcceptedFiles}
        />

        {pendingRunType === RUN_TYPE_STANDARD && (
          <>
            <InstanceSelection
              {...{
                app,
                filteredPendingInstanceIds,
                pendingInstanceIds,
                setPendingInstanceIds,
              }}
            />

            <RunOptions
              {...{
                addEmptyConfigOption,
                handleConfigOptionChange,
                isClone,
                isRunning,
                pendingConfigOptions,
                removeConfigOption,
              }}
            />
          </>
        )}

        {pendingRunType === RUN_TYPE_ENSEMBLE && (
          <EnsembleDefSelection
            ensembleDefs={ensembleDefs}
            ensembleDefsLoadError={ensembleDefsLoadError}
            pendingEnsembleDefId={pendingEnsembleDefId}
            setPendingEnsembleDefId={setPendingEnsembleDefId}
          />
        )}

        <RunNameDescription
          {...{
            getStandardInputsProps,
            isClone,
            isRunning,
          }}
        />
      </ScrollYFlex>
    </>
  );
};

export default InputControls;
