import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const NEXTROUTE_PARTNER_ID = "nextmv";
export const NEXTROUTE_APP_ID = "nextroute";
export const NEXTROUTE_SUBSCRIPTION_ID = `${NEXTROUTE_PARTNER_ID}-${NEXTROUTE_APP_ID}`;
export const NEXTROUTE_ICON_URL = "/images/logos/nextmv-icon-flush-left.svg";
export const NEXTROUTE_NAME = "Nextmv Routing";
export const NEXTROUTE_TEMPLATE_NAME = "nextroute";
export const NEXTROUTE_SHORT_DESC =
  "Route drivers to stops for delivery, distribution, and sourcing use cases. Capacity, compatibility attributes and many more features.";
export const NEXTROUTE_SUMMARY =
  "Built for flexibility and ease of use, the Nextmv Routing app is a no-code solution for solving the majority of common vehicle routing problems.";
export const NEXTROUTE_IMAGE_URL =
  "/images/illustration-marketplace-app-nextroute.png";
export const NEXTROUTE_IMAGE_ALT =
  "Abstract illustration of carrots in location markers on top of stylized map.";
export const NEXTROUTE_SCREENSHOT_URL =
  "/images/marketplace-app-nextroute-screenshot-1.png";
export const NEXTROUTE_SCREENSHOT_ALT =
  "Collection of screenshots from the Nextmv Routing app demo results: shows a value progression over time, list of vehicle names in a user interface with display toggles, and sample vehicle routes with color-code lines overlaid on a street map.";
export const NEXTROUTE_DOCS_LINK =
  "https://www.nextmv.io/docs/vehicle-routing/get-started";
export const NEXTROUTE_CODE_REPO_LINK =
  "https://github.com/nextmv-io/community-apps/tree/develop/go-nextroute";
export const NEXTROUTE_MODEL_TYPE = ["Routing"];
export const NEXTROUTE_LANGUAGE = ["Go"];
export const NEXTROUTE_PACKAGES = ["SDK"];
export const NEXTROUTE_SOLVER = ["Nextroute"];

export const NEXTROUTE_CONSTRAINTS: MarketplaceAppConstraint[] = [
  {
    id: "compatibility-attributes",
    name: "Compatibility attributes",
    property: "compatibility_attributes",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/compatibility-attributes",
    summary: "Which stops are compatible with which vehicles.",
    type: "array",
  },
  {
    id: "capacity-quantity",
    name: "Capacity / Quantity",
    property: "capacity, quantity",
    docsUrl: "https://www.nextmv.io/docs/vehicle-routing/features/capacity",
    summary:
      "Set capacities for vehicles and quantities (demanded or offered) at stops.",
    type: "object | integer",
  },
  {
    id: "distance-matrix",
    name: "Distance matrix",
    property: "distance_matrix",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/distance-matrix",
    summary:
      "Distance matrix in the input that provides the distance of going from location A to B.",
    type: "array",
  },
  {
    id: "duration-matrix",
    name: "Duration matrix",
    property: "duration_matrix",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/duration-matrix",
    summary:
      "Duration matrix in the input that provides the duration of going from location A to B.",
    type: "array",
  },
  {
    id: "early-arrival-time-penalty",
    name: "Early arrival time penalty",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/early-arrival-time-penalty",
    summary:
      "Penalty added to the objective when arriving before a stop’s target arrival time.",
    type: "float",
  },
  {
    id: "late-arrival-time-penalty",
    name: "Late arrival time penalty",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/late-arrival-time-penalty",
    summary:
      "Penalty added to the objective when arriving after a stop’s target arrival time.",
    type: "float",
  },
  {
    id: "maximum-route-distance",
    name: "Maximum route distance",
    docsUrl: "https://www.nextmv.io/docs/vehicle-routing/features/max-distance",
    summary: "Set the maximum distance a vehicle can travel.",
    type: "integer",
  },
  {
    id: "maximum-route-duration",
    name: "Maximum route duration",
    docsUrl: "https://www.nextmv.io/docs/vehicle-routing/features/max-duration",
    summary: "Set the maximum duration a vehicle can travel for.",
    type: "integer",
  },
  {
    id: "maximum-route-stops",
    name: "Maximum route stops",
    docsUrl: "https://www.nextmv.io/docs/vehicle-routing/features/max-stops",
    summary: "Set the maximum stops a vehicle can visit.",
    type: "integer",
  },
  {
    id: "maximum-wait-time",
    name: "Maximum wait time",
    docsUrl: "https://www.nextmv.io/docs/vehicle-routing/features/max-wait",
    summary:
      "Set the maximum time a vehicle can wait on arrival before the start time window opens.",
    type: "integer",
  },
  {
    id: "precedence",
    name: "Precedence (precedes/succeeds)",
    docsUrl: "https://www.nextmv.io/docs/vehicle-routing/features/precedence",
    summary:
      "Add pickups and deliveries or specify multiple pickups before deliveries and vice versa.",
    type: "array | string",
  },
  {
    id: "stop-duration",
    name: "Stop duration",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/stop-duration",
    summary: "Specify the time it takes to service a stop.",
    type: "integer",
  },
  {
    id: "duration-groups",
    name: "Duration groups",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/duration-groups",
    summary:
      "Define added duration when a stop in the group is visited from a stop not in the group.",
    type: "array",
  },
  {
    id: "stop-groups",
    name: "Stop groups",
    docsUrl: "https://www.nextmv.io/docs/vehicle-routing/features/stop-groups",
    summary: "Stops that must be assigned together on the same route.",
    type: "array",
  },
  {
    id: "stop-time-window",
    name: "Stop time window",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/start-time-window",
    summary: "The time window in which a stop must start service.",
    type: "array",
  },
  {
    id: "stop-unplanned-penalty",
    name: "Stop unplanned penalty",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/unplanned-penalty",
    summary:
      "Penalty added for leaving a stop unplanned when all constraints cannot be fulfilled.",
    type: "integer",
  },
  {
    id: "target-arrival-time",
    name: "Target arrival time",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/late-arrival-time-penalty",
    summary: "Define a specific time a vehicle should service a stop.",
    type: "timestamp",
  },
  {
    id: "vehicle-activation-penalty",
    name: "Vehicle activation penalty",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/vehicle-activation-penalty",
    summary: "Penalty for activating (using) a vehicle.",
    type: "integer",
  },
  {
    id: "vehicle-initial-stops",
    name: "Vehicle initial stops",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/vehicle-initial-stops",
    summary: "Specify initial stops planned on a vehicle.",
    type: "array",
  },
  {
    id: "vehicle-start-end-location",
    name: "Vehicle start/end location",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/vehicle-start-end-location",
    summary: "Set starting and/or ending locations for vehicles.",
    type: "lon / lat",
  },
  {
    id: "vehicle-start-end-time",
    name: "Vehicle start/end time",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/features/vehicle-start-end-time",
    summary: "Specify a start and/or end time for a vehicle.",
    type: "timestamp",
  },
];
