import { parse, stringify } from "lossless-json-no-nullish-coalescing-operator";

export type ValidJSON =
  | string
  | number
  | Record<string, unknown> // object
  | any[]
  | boolean
  | null;

const getJsonAsLosslessString = (json: ValidJSON) => {
  let stringifiedInput = "";

  if (typeof json === "string") {
    stringifiedInput = json;
  } else {
    stringifiedInput = stringify(json) || "";
  }

  return stringifiedInput;
};

export const parseLosslessJsonAsText = (
  input: ValidJSON
): string | undefined => {
  const stringifiedInput = getJsonAsLosslessString(input);
  return stringify(parse(stringifiedInput), null, 2);
};

// TODO:
// Add low-level lossless parsing functions from lossless-json
// library; for now, just use native JSON.parse
export const FUTUREparseTextAsJson = (data?: string): ValidJSON => {
  if (!data) return null;

  let parsedData;

  try {
    parsedData = JSON.parse(data);
  } catch (error) {
    return data;
  }

  return parsedData;
};
