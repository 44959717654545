import { Button2MultiSelectOption } from "../../../../../components/Button2/Button2.types";
import { NEXTROUTE_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppNextroute";
import { ORTOOLS_DEMAND_FORECASTING_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppORToolsDemandForecasting";
import { SHIFT_SCHEDULING_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppShiftScheduling";
import { EditorOnChange } from "../hooks/useCustomInput";

import { loadSampleInput } from "./loadSampleInput";

type GetSampleInputOptionsParams = {
  onInputChange: EditorOnChange;
  subscriptionId: string;
};

const defaultSampleInputLabel = "Load sample input";

export const getSampleInputOptions = ({
  onInputChange,
  subscriptionId,
}: GetSampleInputOptionsParams):
  | Button2MultiSelectOption
  | Button2MultiSelectOption[] => {
  switch (subscriptionId) {
    case NEXTROUTE_SUBSCRIPTION_ID:
      return [
        {
          label: defaultSampleInputLabel,
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "general",
            });
          },
        },
        {
          label: "General simple",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "general",
            });
          },
        },
        {
          label: "General advanced",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "general-advanced",
            });
          },
        },
        {
          label: "Delivery simple",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "delivery",
            });
          },
        },
        {
          label: "Delivery advanced",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "delivery-advanced",
            });
          },
        },
        {
          label: "Sourcing",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "sourcing",
            });
          },
        },
        {
          label: "Sourcing advanced",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "sourcing-advanced",
            });
          },
        },
        {
          label: "Distribution Ex. Day 1",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "distribution-day-1",
            });
          },
        },
        {
          label: "Distribution Ex. Day 2",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "distribution-day-2",
            });
          },
        },
        {
          label: "Distribution Ex. Day 3",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "distribution-day-3",
            });
          },
        },
      ];
    case SHIFT_SCHEDULING_SUBSCRIPTION_ID:
      return [
        {
          label: defaultSampleInputLabel,
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "general",
            });
          },
        },
        {
          label: "General",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "general",
            });
          },
        },
        {
          label: "Multi-day",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "multi-day",
            });
          },
        },
        {
          label: "Multi-day half-shifts",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "multi-day-half-shifts",
            });
          },
        },
        {
          label: "Large example",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "shift-scheduling-large",
            });
          },
        },
      ];
    case ORTOOLS_DEMAND_FORECASTING_SUBSCRIPTION_ID:
      return [
        {
          label: defaultSampleInputLabel,
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "demand-small",
            });
          },
        },
        {
          label: "Demand (small)",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "demand-small",
            });
          },
        },
        {
          label: "Demand (large)",
          onClick: () => {
            return loadSampleInput({
              onInputChange,
              subscriptionId,
              type: "demand-large",
            });
          },
        },
      ];
    default:
      return {
        label: defaultSampleInputLabel,
        onClick: () => {
          return loadSampleInput({
            subscriptionId,
            onInputChange,
          });
        },
      };
  }
};
