import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { CreateBatchExperimentPayload } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import { AvatarExperimentScenario } from "../../../../avatars";
import Box from "../../../../components/Box";
import Button2 from "../../../../components/Button2";
import Flex from "../../../../components/Flex";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import { Table2RowSelection } from "../../../../components/Table2/Table2.types";
import Text from "../../../../components/Text";
import { PAYLOAD_EXPERIMENT_TYPE_SCENARIO } from "../../../../config/experiments";
import {
  INPUT_WIDTH_NARROW,
  INPUT_WIDTH_STANDARD,
} from "../../../../config/general";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import useUpdateBinary from "../../../../contexts/apps/hooks/useUpdateBinary";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import useBatchExperiments, {
  ScenarioTestRunWithRunId,
} from "../../../../contexts/experiments/hooks/useBatchExperiments";
import { getInputCountPerInputSet } from "../../../../contexts/experiments/utils/inputSets";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { LATEST_INSTANCE_ID } from "../../../../utils/constants";
import { getIterativeNumeral } from "../../../../utils/getIterativeNumeral";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { Table2HeaderObj } from "../../../App/subpages/RunHistory/RunHistory.types";
import { getScenarioRunTableHeaders } from "../../../App/subpages/RunHistory/utils/getScenarioRunTableHeaders";
import AddPlanSets from "../../components/AddPlanSets";
import ScenarioRuns from "../../components/ScenarioRuns";
import { scenarioTooltips, sharedTooltipCopy } from "../../data/microcopy";
import { PlanSet } from "../../Experiments.types";
import { getMaxRunRepetitions } from "../../utils/getMaxRunRepetions";
import { getPendingScenarioConfigOptionsHeaders } from "../../utils/getPendingScenarioConfigOptionsHeaders";
import { getSafeCloneName } from "../../utils/getSafeCloneName";

import { cloneScenarios } from "./utils/utils";

const pageTitle = "Create new scenario plan";

const NewScenarioTest = ({ app }: AppPageProps) => {
  const theme = useTheme();
  const [{ features, id: accountId }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const { returnPath, returnPathList } = useReturnPaths();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const cloneId = searchParams.get("cloneId");

  const isSubscriptionApp = app.type === "subscription";

  // scenario test management
  const [isConfirmCreate, setIsConfirmCreate] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [pendingPlanSets, setPendingPlanSets] = useState<PlanSet[]>([]);
  const [pendingRepetitions, setPendingRepetitions] = useState(0);
  const [totalRuns, setTotalRuns] = useState(0);
  const [scenarioRuns, setScenarioRuns] = useState<ScenarioTestRunWithRunId[]>(
    []
  );

  // input set management
  const [isInputSetProcessing, setIsInputSetProcessing] = useState(false);
  const [shouldSubmitInputSet, setShouldSubmitInputSet] = useState(false);
  const [arePendingInputSetsComplete, setArePendingInputSetsComplete] =
    useState(false);
  const [isReviewRuns, setIsReviewRuns] = useState(false);
  const [runSelection, setRunSelection] = useState<Table2RowSelection>({});

  const { loadScenarioTests } = useExperiments();

  const { loadApp, loadInstances, loadVersions } = useAppCollection();

  const { inputSets } = useExperiments();

  const { getScenarioTestPayload } = useBatchExperiments();

  const {
    addEntity: addScenarioTest,
    entity: scenarioTest,
    entityAddError: scenarioTestAddError,
    entityLoadError: scenarioTestLoadError,
    isEntityAdded: isScenarioTestAdded,
    loadEntity: loadScenarioTest,
    setEntityAddError: setScenarioTestAddError,
    entityLoadRunsError: scenarioTestRunsError,
    entityRuns: scenarioTestRuns,
    loadEntityRuns: loadScenarioTestRuns,
  } = useManageEntity("experiments/batch");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "experiments/batch");

  const {
    updateLatestInstanceAndGetVersionId,
    isInstanceAdded,
    isInstanceEdited,
    isVersionAdded,
  } = useUpdateBinary();

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  useEffect(() => {
    if (cloneId && !scenarioTest && !scenarioTestLoadError) {
      loadScenarioTest(app.id, cloneId);
    }
    if (cloneId && !scenarioTestRuns && !scenarioTestRunsError) {
      loadScenarioTestRuns(app.id, cloneId);
    }
  }, [
    app.id,
    cloneId,
    loadScenarioTest,
    loadScenarioTestRuns,
    scenarioTest,
    scenarioTestLoadError,
    scenarioTestRuns,
    scenarioTestRunsError,
  ]);

  useEffect(() => {
    if (cloneId && scenarioTest && !pendingStandardInputs.id) {
      // modify name to prevent duplicate ID error
      const modifiedScenarioTestName = `${getSafeCloneName(
        scenarioTest.name
      )} clone`;

      // ID set automatically by name field
      updateStandardInputs([
        { key: "name", value: modifiedScenarioTestName },
        { key: "description", value: scenarioTest?.description },
      ]);
    }
  }, [
    scenarioTest,
    cloneId,
    pendingStandardInputs.id,
    pendingPlanSets.length,
    updateStandardInputs,
  ]);

  useEffect(() => {
    if (cloneId && scenarioTestRuns) {
      const inputCountPerInputSetObj = getInputCountPerInputSet(inputSets);
      const clonedScenarios = cloneScenarios(
        scenarioTestRuns,
        inputCountPerInputSetObj
      );
      setPendingPlanSets(clonedScenarios);
      setPendingRepetitions(getMaxRunRepetitions(scenarioTestRuns));
    }
  }, [cloneId, scenarioTestRuns, inputSets]);

  // disable loading state if add new scenario test error
  useEffect(() => {
    if (scenarioTestAddError && scenarioTestRunsError && isProcessing) {
      setIsProcessing(false);
    }
  }, [scenarioTestAddError, scenarioTestRunsError, isProcessing]);

  // track input uploading for each plan set
  useEffect(() => {
    if (
      isInputSetProcessing &&
      !scenarioTestAddError &&
      !arePendingInputSetsComplete &&
      pendingPlanSets.every((pendingPlanSet) => pendingPlanSet.inputSetId)
    ) {
      setArePendingInputSetsComplete(true);
    }
  }, [
    arePendingInputSetsComplete,
    isInputSetProcessing,
    pendingPlanSets,
    scenarioTestAddError,
  ]);

  const scenarioConfigOptionsHeaders = useMemo(() => {
    if (!pendingPlanSets) return [];
    return getPendingScenarioConfigOptionsHeaders(pendingPlanSets, theme);
  }, [pendingPlanSets, theme]);

  const scenarioRunHeaders: Table2HeaderObj[] = useMemo(() => {
    if (!scenarioRuns) {
      return [];
    }
    return getScenarioRunTableHeaders({
      accId: accountId,
      appId: app.id,
      isGroupEdit: true,
      scenarioConfigOptionsHeaders: scenarioConfigOptionsHeaders,
      theme,
    });
  }, [accountId, app.id, scenarioConfigOptionsHeaders, scenarioRuns, theme]);

  const handleScenarioTestCreate = useCallback(async () => {
    setIsConfirmCreate(false);
    setIsProcessing(true);

    let payload: CreateBatchExperimentPayload = {
      ...pendingStandardInputs,
      type: PAYLOAD_EXPERIMENT_TYPE_SCENARIO,
    };

    const hasLatestInstance = pendingPlanSets.some((planSet) => {
      return planSet.instances.some(
        (instance) => instance?.instanceId === LATEST_INSTANCE_ID
      );
    });

    let latestVersionId = "";
    if (hasLatestInstance && !isSubscriptionApp) {
      const latestApp = await loadApp(app.id, "", true);

      if (latestApp) {
        latestVersionId = await updateLatestInstanceAndGetVersionId({
          app: latestApp,
        });
      }

      if (!latestVersionId) {
        setScenarioTestAddError("Error verifying the latest instance");
        return;
      }
    }

    const scenarioTestPayload = await getScenarioTestPayload({
      appId: app.id,
      isSubscriptionApp: isSubscriptionApp,
      latestVersionId: latestVersionId,
      planSets: pendingPlanSets,
      repetitions: pendingRepetitions,
    });

    payload["option_sets"] = scenarioTestPayload.option_sets;

    if (isReviewRuns) {
      payload["runs"] = scenarioTestPayload.runs.filter(
        (run) => run.run_number && runSelection[run.run_number]
      );
    } else {
      payload["runs"] = scenarioTestPayload.runs;
    }

    trackEvent("Scenario", {
      view: "Create Scenario Test",
      action: "Scenario Test Submmitted",
      meta: {
        option_sets: Object.keys(scenarioTestPayload.option_sets).length,
        runs: scenarioTestPayload.runs.length,
      },
    });

    await addScenarioTest(app.id, payload);
  }, [
    addScenarioTest,
    app,
    getScenarioTestPayload,
    isReviewRuns,
    isSubscriptionApp,
    loadApp,
    pendingPlanSets,
    pendingRepetitions,
    pendingStandardInputs,
    runSelection,
    setScenarioTestAddError,
    updateLatestInstanceAndGetVersionId,
  ]);

  // finish create process after input set created
  useEffect(() => {
    if (arePendingInputSetsComplete && isInputSetProcessing) {
      setIsInputSetProcessing(false);
      handleScenarioTestCreate();
    }
  }, [
    arePendingInputSetsComplete,
    handleScenarioTestCreate,
    isInputSetProcessing,
  ]);

  // track total run count for confirmation
  useEffect(() => {
    const updatedTotalRuns = pendingPlanSets.reduce(
      (totalCount, currentPlanSet) => {
        return totalCount + currentPlanSet.runCount;
      },
      0
    );
    setTotalRuns(updatedTotalRuns);
    return;
  }, [pendingPlanSets, setTotalRuns]);

  const handleOnChangeRepetitions = (e: { target: { value: any } }) => {
    const { value } = e.target;
    return setPendingRepetitions(parseInt(value));
  };

  const handleScenarioTestPreCreate = (e: {
    preventDefault: any;
    stopPropagation: any;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      pendingPlanSets.some(
        (pendingPlanSet) => pendingPlanSet.shouldUseFilesForInputSet
      )
    ) {
      setIsInputSetProcessing(true);
      setShouldSubmitInputSet(true);
      setScenarioTestAddError(null);
    } else {
      handleScenarioTestCreate();
    }

    return;
  };

  const handleScenarioTestActionButton = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsConfirmCreate(true);
    return;
  };

  const handleReviewRuns = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = await getScenarioTestPayload({
      appId: app.id,
      isSubscriptionApp: isSubscriptionApp,
      isGetDisplayRuns: true,
      planSets: pendingPlanSets,
      repetitions: pendingRepetitions,
    });

    setScenarioRuns(payload.displayRuns);
    setIsReviewRuns((prev) => !prev);
    selectAllRuns(payload.displayRuns);
  };

  const selectAllRuns = (scenarioRuns: ScenarioTestRunWithRunId[]) => {
    const emptyRowSelection: Table2RowSelection = {};
    const allRunSelection = scenarioRuns?.reduce((acc, run) => {
      if (run.run_number) {
        acc[run.run_number] = true;
      }
      return acc;
    }, emptyRowSelection);
    setRunSelection(allRunSelection);
  };

  const cancelReviewRuns = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();
    setIsReviewRuns(false);
    selectAllRuns(scenarioRuns);
    return;
  };

  const handleCancel = (e: any) => {
    if (isReviewRuns) {
      cancelReviewRuns(e);
      return;
    }

    trackEvent("Scenario", {
      view: "Create Scenario Test",
      action: "Create Scenario Test Canceled",
    });

    return;
  };

  const arePendingInputSetsDefined = useMemo(() => {
    return pendingPlanSets.every(
      (planSet) => planSet.inputSetId || planSet.shouldUseFilesForInputSet
    );
  }, [pendingPlanSets]);

  const arePendingInstancesDefined = useMemo(() => {
    return pendingPlanSets.every(
      (planSet) => planSet.instances.filter(Boolean).length
    );
  }, [pendingPlanSets]);

  const numberOfRunsSelected = useMemo(
    () => Object.keys(runSelection).length,
    [runSelection]
  );

  const isNoRunsSelected = isReviewRuns ? !numberOfRunsSelected : false;

  if (
    cloneId &&
    !scenarioTest &&
    !scenarioTestLoadError &&
    !scenarioTestRuns &&
    !scenarioTestRunsError
  ) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  if (isScenarioTestAdded) {
    trackEvent("Scenario", {
      view: "Create Scenario Test",
      action: "New Scenario Test Created",
    });

    loadScenarioTests({
      applicationId: app.id,
      type: PAYLOAD_EXPERIMENT_TYPE_SCENARIO,
    });
    if (isInstanceAdded || isInstanceEdited) {
      loadInstances({ applicationId: app.id, shouldPaginate: true });
    }
    if (isVersionAdded) {
      loadVersions({ applicationId: app.id, shouldPaginate: true });
    }

    return <Redirect to={returnPathList} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name ||
    !pendingStandardInputs.id ||
    !arePendingInputSetsDefined ||
    !arePendingInstancesDefined ||
    !!standardInputsErrors.name ||
    !!standardInputsErrors.id;

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          ancestorIcon: <AvatarExperimentScenario size={24} />,
          ancestorLabel: "Scenario Tests",
          ancestorUrl: returnPathList,
        }}
      />

      <Box pb={[6, 6, 8]}>
        <form>
          <RowDetail
            hasNoBorder
            property="Name"
            secondaryLabel="For reference only"
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    isDisabled: isReviewRuns,
                    placeholder: "Scenario name",
                    testId: "new-scenario-test-name-input",
                    type: "name",
                    trackEventCategory: "Scenario",
                    trackEventProperties: {
                      view: "Create Scenario Test",
                      action: "Field Entered",
                      meta: {
                        field: "name",
                      },
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="ID"
            tooltipCopy={sharedTooltipCopy.id("scenario test").content}
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    isDisabled: isReviewRuns,
                    placeholder: "Scenario ID",
                    testId: "new-scenario-test-id-input",
                    type: "id",
                    trackEventCategory: "Scenario",
                    trackEventProperties: {
                      view: "Create Scenario Test",
                      action: "Scenario Test ID Changed",
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="Description"
            secondaryLabel="(optional)"
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    isDisabled: isReviewRuns,
                    placeholder: "Scenario description",
                    testId: "new-scenario-test-description-input",
                    type: "description",
                    trackEventCategory: "Scenario",
                    trackEventProperties: {
                      view: "Create Scenario Test",
                      action: "Field Entered",
                      meta: {
                        field: "description",
                      },
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="Scenarios"
            tooltipCopy={scenarioTooltips.scenarios.content}
            render={
              <AddPlanSets
                isDisabled={isReviewRuns}
                {...{
                  app,
                  isLoadBlankOptionSet: !cloneId,
                  pendingPlanSets,
                  setPendingPlanSets,
                  setScenarioTestAddError,
                  shouldSubmitInputSet,
                }}
                pendingScenarioId={pendingStandardInputs.id}
              />
            }
          />

          <RowDetail
            property="Repetitions"
            secondaryLabel="(optional)"
            tooltipCopy={scenarioTooltips.repetitions.content}
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_NARROW}>
                <Input
                  isDisabled={isReviewRuns}
                  htmlType="number"
                  placeholder="Enter a number"
                  value={pendingRepetitions}
                  min={0}
                  onChange={handleOnChangeRepetitions}
                />
              </Box>
            }
          />

          {isReviewRuns && scenarioRuns && !!scenarioRuns?.length && (
            <ScenarioRuns
              scenarioRuns={scenarioRuns}
              runSelection={runSelection}
              setRunSelection={setRunSelection}
              scenarioRunHeaders={scenarioRunHeaders}
            />
          )}
          {scenarioTestRunsError && (
            <StandardError errorMessage={scenarioTestRunsError} />
          )}

          <Footer
            actionButtonLabel="Create scenario test"
            app={app}
            {...(isReviewRuns && {
              cancelButtonLabel: "Cancel review runs",
            })}
            endpoint="experiments/batch"
            error={scenarioTestAddError}
            handleCancel={handleCancel}
            handleMainAction={handleScenarioTestActionButton}
            isActionButtonLoading={isProcessing || isInputSetProcessing}
            isActionButtonDisabled={isActionButtonDisabled || isNoRunsSelected}
            isSecondaryButtonDisabled={isActionButtonDisabled}
            returnPath={returnPath}
            returnPathList={returnPathList}
            view="create"
            isConfirm={isConfirmCreate}
            // TODO remove internal feature flag when ready
            {...(!isReviewRuns &&
              features?.internal && {
                handleSecondaryAction: (e) => handleReviewRuns(e),
                secondaryButtonLabel: "Review runs",
              })}
            confirmContent={
              <Box>
                <Text styleName="body-1">
                  This scenario test will create{" "}
                  <Text
                    as="span"
                    styleName="body-1-bold"
                    styles={{ color: theme.color.gray800 }}
                  >
                    {isReviewRuns
                      ? numberOfRunsSelected
                      : totalRuns * (pendingRepetitions + 1)}{" "}
                    runs
                  </Text>
                  .{" "}
                  {!!pendingRepetitions &&
                    !isReviewRuns &&
                    `(${totalRuns} runs repeated 
                  ${getIterativeNumeral(pendingRepetitions)})`}
                </Text>
                <Text styleName="body-1">
                  Are you sure you want to continue?
                </Text>

                <Flex mt={3}>
                  <Button2
                    testId="confirm-create-scenario-test-submit-button"
                    isLoading={isProcessing || isInputSetProcessing}
                    label="Confirm create & run scenario test"
                    size="large"
                    htmlType="submit"
                    onClick={handleScenarioTestPreCreate}
                  />

                  <Button2
                    ml={4}
                    type="text"
                    size="large"
                    label="Cancel"
                    htmlType="button"
                    onClick={(e: {
                      preventDefault: () => void;
                      stopPropagation: () => void;
                    }) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsConfirmCreate(false);
                      return;
                    }}
                  />
                </Flex>
              </Box>
            }
          />
        </form>
      </Box>
    </>
  );
};

export default NewScenarioTest;
