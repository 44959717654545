import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconRouteHaversine = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      viewBox={[0, 0, 28, 28]}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9208 18.334L9.66528 11.0784C10.2629 10.7546 10.7556 10.2618 11.0795 9.66418L18.3351 16.9198C17.7374 17.2436 17.2447 17.7364 16.9208 18.334Z"
      />
      <path d="M10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10C9.10457 10 10 9.10457 10 8Z" />
      <path d="M22 20C22 18.8954 21.1046 18 20 18C18.8954 18 18 18.8954 18 20C18 21.1046 18.8954 22 20 22C21.1046 22 22 21.1046 22 20Z" />
    </BaseIcon>
  );
};

export default IconRouteHaversine;
