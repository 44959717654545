import wilcoxon from "@stdlib/stats-wilcoxon";

import {
  AcceptanceTestMetric,
  AcceptanceTestResponse,
  AppType,
  BatchExperiment,
  BatchExperimentGroupedSummary,
} from "../../../api/core/controlPlane.types";
import Flex from "../../../components/Flex";
import Text from "../../../components/Text";
import Tooltip from "../../../components/Tooltip";
import { isNumber } from "../../../utils/typeCheck";
import { EXP_ACC_METRIC_TYPE } from "../data/constants";
import {
  AcceptanceDistro,
  AcceptanceTestSummaries,
  InputValues,
  SigTestData,
} from "../Experiments.types";

import {
  getFilteredSummaries,
  getGroupedSummaryTables,
  mapAvailableIndicatorsToStats,
} from "./getGroupedSummaryTables";
import { getResolvedVersionId } from "./getResolvedVersionId";
import {
  formatGroupedSummaryTableName,
  formatNumberLocaleString,
} from "./groupedSummaryTable";

const getConclusionText = (
  n: number,
  pValue: number | undefined,
  relativeDiff: number | null
) => {
  if (!pValue) {
    return `There was no change in the sample values between these two tests.`;
  }
  if (n < 6 && pValue <= 0.05) {
    return `This change is statistically significant at the 5% significance level. However, this test uses ${n} input files. We recommended testing with at least 6 input files to interpret significance results.`;
  }
  if (n < 6 && pValue > 0.05) {
    return `This change is not statistically significant at the 5% significance level. However, this test uses ${n} input files. We recommended testing with at least 6 input files to interpret significance results.`;
  }
  if (pValue > 0.05) {
    return "This change is not statistically significant at the 5% significance level.";
  }
  if (relativeDiff !== null && Math.abs(relativeDiff) < 1) {
    return "This change is statistically significant at the 5% significance level. However, the percent change in your results is less than ±1%.";
  }
  return "This change is statistically significant at the 5% significance level.";
};

const isSignificant = (
  n: number,
  pValue: number | undefined,
  relativeDiff: number | null
): boolean => {
  if (n < 6 || !pValue) {
    // we can't really say anything here so we default to it being significant
    return true;
  }
  return relativeDiff !== null
    ? pValue < 0.05 && Math.abs(relativeDiff) >= 1
    : pValue < 0.05;
};

const getSignificanceTableValues = (
  controlMean: number,
  candidateMean: number,
  controlValues: number[],
  candidateValues: number[],
  hasDifferences: boolean
): SigTestData => {
  const diff = candidateMean - controlMean;
  let relativeDiff = null;
  if (controlMean) {
    relativeDiff = (diff / controlMean) * 100;
  }

  let pValue: undefined | number = undefined;
  if (hasDifferences) {
    try {
      pValue = wilcoxon(controlValues, candidateValues, {
        zeroMethod: "pratt",
      }).pValue;
    } catch (e) {
      console.error(e);
    }
  }

  return {
    headers: [
      {
        id: "diff",
        accessorKey: "diff",
        header: () => (
          <Text as="span" styleName="label">
            Difference
          </Text>
        ),
      },
      {
        id: "relativeDiff",
        accessorKey: "relativeDiff",
        header: () => (
          <Text as="span" styleName="label">
            Percent Change
          </Text>
        ),
      },
      {
        id: "pValue",
        accessorKey: "pValue",
        header: () => (
          <Flex width={"100%"} alignItems="flex-end">
            <Text as="span" styleName="label">
              p-value
            </Text>
            <Tooltip mt={-1} ml={1} mr={-1} direction="left">
              p-value calculated with the Wilcoxon signed rank test with
              continuity correction. This value gives an indication of whether
              the change in value is statistically significant, but does not
              account for the intended direction of your test. Consider how this
              might affect your final conclusion. Paired observations were
              excluded where at least one run failed. pValues are not provided
              if there is no difference in the data.
            </Tooltip>
          </Flex>
        ),
      },
    ],
    data: [
      {
        diff: diff.toFixed(3),
        relativeDiff:
          relativeDiff !== null ? `${relativeDiff.toFixed(2)} %` : "n/a",
        pValue: pValue ? pValue.toFixed(4) : "n/a",
      },
    ],
    statisticallySignificant: isSignificant(
      controlValues.length,
      pValue,
      relativeDiff
    ),
    conclusionContent: getConclusionText(
      controlValues.length,
      pValue,
      relativeDiff
    ),
  };
};

export const getAcceptanceTestSummaryData = <
  T extends "indicator_distributions"
>(
  appType: AppType | undefined,
  batchResults: BatchExperiment,
  acceptTestData: AcceptanceTestResponse,
  theme: any
): AcceptanceTestSummaries => {
  const groupDistro = batchResults?.grouped_distributional_summaries || [];
  let controlDistros: BatchExperimentGroupedSummary[T] = {};
  let candidateDistros: BatchExperimentGroupedSummary[T] = {};

  const controlInputDistros: AcceptanceDistro = {};
  const candidateInputDistros: AcceptanceDistro = {};
  let controlDataFound = false;
  let candidateDataFound = false;

  const controlInstance = acceptTestData?.control?.instance_id;
  const controlVersion = getResolvedVersionId(
    appType,
    controlInstance,
    acceptTestData?.control?.version_id,
    batchResults
  );
  if (!controlVersion) {
    return [];
  }
  const candidateInstance = acceptTestData?.candidate?.instance_id;
  const candidateVersion = getResolvedVersionId(
    appType,
    candidateInstance,
    acceptTestData?.candidate?.version_id,
    batchResults
  );
  if (!candidateVersion) {
    return [];
  }

  const filteredSummaries = batchResults
    ? getFilteredSummaries(batchResults.grouped_distributional_summaries!)
    : [];

  const availableStatsByKey = mapAvailableIndicatorsToStats(filteredSummaries);

  for (let i = 0; i < groupDistro.length; i++) {
    const distro = groupDistro[i];
    // match for control
    if (
      isCorrectGroupValues(distro.group_values, controlInstance, controlVersion)
    ) {
      controlDistros = distro.indicator_distributions;
      controlDataFound = true;
      continue;
    }

    if (
      isCorrectGroupValues(
        distro.group_values,
        candidateInstance,
        candidateVersion
      )
    ) {
      candidateDistros = distro.indicator_distributions;
      candidateDataFound = true;
      continue;
    }

    if (
      isCorrectByInputValues(
        distro.group_keys,
        distro.group_values,
        controlInstance,
        controlVersion
      )
    ) {
      controlInputDistros[distro.group_values[0]] =
        distro.indicator_distributions;
      continue;
    }

    if (
      isCorrectByInputValues(
        distro.group_keys,
        distro.group_values,
        candidateInstance,
        candidateVersion
      )
    ) {
      candidateInputDistros[distro.group_values[0]] =
        distro.indicator_distributions;
      continue;
    }
  }

  if (!controlDataFound || !candidateDataFound) {
    return [];
  }

  const metricResults = acceptTestData.metrics.map((metric) => {
    let status = "";
    const metricField = metric.field.trim();
    const metricControlValue = controlDistros[metricField]
      ? traverse(controlDistros[metricField], metric.statistic)
      : undefined;
    const metricCandidateValue = candidateDistros[metricField]
      ? traverse(candidateDistros[metricField], metric.statistic)
      : undefined;
    if (!isNumber(metricCandidateValue) || !isNumber(metricControlValue)) {
      status = "unavailable";
    }

    const groupedSummaryTable = getGroupedSummaryTables(
      theme,
      filteredSummaries,
      availableStatsByKey,
      metricField
    );

    const inputValues = collectInputValues(
      controlInputDistros,
      candidateInputDistros,
      metricField,
      metric.statistic
    );

    // perform stat testing

    // create input arrays
    const controlValues: number[] = [];
    const candidateValues: number[] = [];
    let hasDifferences = false;
    inputValues.forEach((inputObj) => {
      if (!isNaN(inputObj.x) && !isNaN(inputObj.y)) {
        controlValues.push(inputObj.x);
        candidateValues.push(inputObj.y);
        if (inputObj.x - inputObj.y) {
          hasDifferences = true;
        }
      }
    });

    return {
      metricField: metricField,
      name: formatGroupedSummaryTableName(metricField),
      status:
        status ||
        evaluateStatusBasedOnType(
          metric.metric_type,
          metric.params,
          metricControlValue,
          metricCandidateValue
        )
          ? "pass"
          : "fail",
      warning: isWarningLabel(
        metric.params,
        metricControlValue,
        metricCandidateValue
      ),
      sense: getSenseBasedOnType(metric.metric_type, metric.params),
      candidate: {
        // TODO: not sure what goes here for id and name
        value:
          metricCandidateValue &&
          formatNumberLocaleString(metricCandidateValue),
        type: metric.statistic,
        instanceId: candidateInstance,
        versionId: candidateVersion,
      },
      control: {
        value:
          metricControlValue && formatNumberLocaleString(metricControlValue),
        type: metric.statistic,
        instanceId: controlInstance,
        versionId: controlVersion,
      },
      summaryTable: groupedSummaryTable,
      inputValues,
      sigTestData: getSignificanceTableValues(
        metricControlValue,
        metricCandidateValue,
        controlValues,
        candidateValues,
        hasDifferences
      ),
    };
  });

  return metricResults as AcceptanceTestSummaries;
};

const collectInputValues = (
  controlData: AcceptanceDistro,
  candidateData: AcceptanceDistro,
  field: keyof AcceptanceDistro,
  statistic: string
): InputValues => {
  return Object.keys(controlData).map((inputId) => {
    const x =
      controlData[inputId] && controlData[inputId][field]
        ? traverse(controlData[inputId][field], statistic)
        : undefined;
    const y =
      candidateData[inputId] && candidateData[inputId][field]
        ? traverse(candidateData[inputId][field], statistic)
        : undefined;
    return {
      x,
      y,
      inputId,
    };
  });
};

const isWarningLabel = <T extends "params">(
  metricRule: AcceptanceTestMetric[T],
  controlValue: number,
  candidateValue: number
) => {
  if (!metricRule || !isNumber(controlValue) || !isNumber(candidateValue)) {
    return false;
  }
  return false;
};

const evaluateStatusBasedOnType = <T extends "params">(
  metricType: string,
  metricRule: AcceptanceTestMetric[T],
  controlValue: number,
  candidateValue: number
) => {
  switch (metricType) {
    case EXP_ACC_METRIC_TYPE:
      return directComparisionMapping[metricRule.operator](
        controlValue,
        candidateValue
      );
    default:
      return undefined;
  }
};

const getSenseBasedOnType = <T extends "params">(
  metricType: string,
  metricRule: AcceptanceTestMetric[T]
) => {
  switch (metricType) {
    case EXP_ACC_METRIC_TYPE:
      return metricRule.operator;
    default:
      return "";
  }
};

const directComparisionMapping = {
  ge: (controlValue: number, candidateValue: number) =>
    candidateValue >= controlValue,
  gt: (controlValue: number, candidateValue: number) =>
    candidateValue > controlValue,
  lt: (controlValue: number, candidateValue: number) =>
    candidateValue < controlValue,
  le: (controlValue: number, candidateValue: number) =>
    candidateValue <= controlValue,
  eq: (controlValue: number, candidateValue: number) =>
    candidateValue === controlValue,
  ne: (controlValue: number, candidateValue: number) =>
    candidateValue !== controlValue,
};

const isCorrectByInputValues = (
  groupKeys: any,
  groupValues: any,
  instance: string,
  version: string
): boolean => {
  return (
    groupKeys.length === 3 &&
    groupKeys[0] === "inputID" &&
    groupKeys[1] === "instanceID" &&
    groupKeys[2] === "versionID" &&
    groupValues[1] === instance &&
    groupValues[2] === version
  );
};

const isCorrectGroupValues = (
  groupValues: string[],
  instance: string,
  version: string
) => {
  return (
    groupValues.length === 2 &&
    groupValues[0] === instance &&
    groupValues[1] === version
  );
};

const traverse = (obj: any, path: string) => {
  const keys = path.split(".");
  return keys.reduce((acc, curr) => (acc ? acc[curr] : undefined), obj);
};
