import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconInfo = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99985 0.777039C4.01087 0.777039 0.777161 4.01075 0.777161 7.99973C0.777161 11.9887 4.01087 15.2224 7.99985 15.2224C11.9888 15.2224 15.2225 11.9887 15.2225 7.99973C15.2225 4.01075 11.9888 0.777039 7.99985 0.777039ZM2.10716 7.99973C2.10716 4.74529 4.74541 2.10704 7.99985 2.10704C11.2543 2.10704 13.8925 4.74529 13.8925 7.99973C13.8925 11.2541 11.2543 13.8924 7.99985 13.8924C4.74541 13.8924 2.10716 11.2541 2.10716 7.99973ZM9.06165 4.75C9.06165 5.30228 8.61394 5.75 8.06165 5.75C7.50937 5.75 7.06165 5.30228 7.06165 4.75C7.06165 4.19772 7.50937 3.75 8.06165 3.75C8.61394 3.75 9.06165 4.19772 9.06165 4.75ZM6.32999 6.48502H6.99499H8.06165C8.42892 6.48502 8.72665 6.78275 8.72665 7.15002V10.7517H9.12832H9.79332V12.0817H9.12832H8.06165H6.99499H6.32999V10.7517H6.99499H7.39665V7.81502H6.99499H6.32999V6.48502Z"
      />
    </BaseIcon>
  );
};

export default IconInfo;
