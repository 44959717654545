import * as React from "react";

import { StyledInputRadio, StyledInputRadioLabel } from "./InputRadio.styled";
import { InputRadioProps } from "./InputRadio.types";

const InputRadio = ({
  checkValue,
  handleChange,
  id,
  isDisabled,
  isSmall,
  groupName,
  label,
  value,
  // styled-system space props (margin only)
  m,
  mt,
  mr,
  mb,
  ml,
}: InputRadioProps) => {
  return (
    <>
      <StyledInputRadio
        type="radio"
        disabled={isDisabled}
        id={id}
        name={groupName}
        value={value}
        checked={!!(checkValue === value)}
        onChange={handleChange}
      />
      <StyledInputRadioLabel
        {...{ isDisabled, isSmall, m, mt, mr, mb, ml }}
        htmlFor={id}
        isSelected={!!(checkValue === value)}
      >
        {label}
      </StyledInputRadioLabel>
    </>
  );
};

export default InputRadio;
