import { useTheme } from "@emotion/react";

import Box from "../../components/Box";
import Flex from "../../components/Flex";
import { rem } from "../../utils/tools";
import Input from "../Input";
import Text from "../Text";

import TeamOption from "./components/TeamOption";
import {
  StyledInvitesCount,
  StyledInvitesLink,
  StyledOrgSwitcher,
} from "./OrgSwitcher.styled";
import { OrgSwitcherProps } from "./OrgSwitcher.types";

const OrgSwitcher = ({
  configFilter,
  currentTeam,
  invitesCount,
  teams,
  isActive,
  setIsOrgSwitcherActive,
}: OrgSwitcherProps) => {
  const theme = useTheme();
  const isZeroInvites = !invitesCount;

  return (
    <StyledOrgSwitcher {...{ isActive }} data-testid={"styled-org-switcher"}>
      {configFilter && (
        <Flex width={"100%"} pr={1} mb={2} pb={2} pl={2} hasBorderBottom>
          <Box width={["100%", rem(200)]}>
            <Input
              data-testid={
                configFilter.testId ? configFilter.testId : undefined
              }
              htmlType="text"
              size="small"
              placeholder="Filter"
              value={configFilter.inputText}
              onChange={(e: { target: { value: string } }) => {
                return configFilter.setInputText(e.target.value);
              }}
            />
          </Box>
        </Flex>
      )}

      {(teams || [])
        .sort((a, b) => (a.role === "root" ? -1 : 0))
        .map((team) => (
          <TeamOption
            {...{ setIsOrgSwitcherActive, team }}
            key={team.id}
            isCurrentTeam={team.id === currentTeam?.id}
            isYou={team.role === "root"}
          />
        ))}

      <StyledInvitesLink to="/invites" iszero={isZeroInvites.toString()}>
        <Flex
          mt={2}
          pt={3}
          pr={1}
          pb={1}
          pl={2}
          hasBorderTop
          alignItems="center"
        >
          <Text
            as="span"
            styleName="label"
            styles={{
              color: isZeroInvites ? theme.color.gray500 : theme.color.gray700,
            }}
          >
            Team Invites
          </Text>
          <StyledInvitesCount isZero={isZeroInvites}>
            <Text
              styleName="meta-1-bold"
              styles={{
                color: isZeroInvites ? theme.color.gray100 : theme.color.white,
              }}
            >
              {invitesCount}
            </Text>
          </StyledInvitesCount>
        </Flex>
      </StyledInvitesLink>
    </StyledOrgSwitcher>
  );
};

export default OrgSwitcher;
