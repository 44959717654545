import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const KNAPSACK_PARTNER_ID = "nextmv";
export const KNAPSACK_APP_ID = "knapsack.gosdk";
export const KNAPSACK_SUBSCRIPTION_ID = `${KNAPSACK_PARTNER_ID}-${KNAPSACK_APP_ID}`;
export const KNAPSACK_ICON_URL = "/images/logos/highs-icon.png";
export const KNAPSACK_NAME = "Go HiGHS Knapsack";
export const KNAPSACK_TEMPLATE_NAME = "knapsack-gosdk";
export const KNAPSACK_SHORT_DESC =
  "Solves a knapsack Mixed Integer Programming problem using Nextmv Go SDK.";
export const KNAPSACK_SUMMARY =
  "Solve an knapsack problem with the low-code Nextmv SDK knapsack app. Define available items and the weight capacity of the knapsack and then run the app to decide which items to include to maximize knapsack value.";
export const KNAPSACK_DOCS_LINK =
  "https://www.nextmv.io/docs/community-apps/knapsack";
export const KNAPSACK_CODE_REPO_LINK =
  "https://github.com/nextmv-io/community-apps/tree/develop/go-highs-knapsack";
export const KNAPSACK_MODEL_TYPE = ["Packing"];
export const KNAPSACK_LANGUAGE = ["Go"];
export const KNAPSACK_PACKAGES = ["MIP"];
export const KNAPSACK_SOLVER = ["HiGHS"];

export const KNAPSACK_CONSTRAINTS: MarketplaceAppConstraint[] = [
  {
    id: "items",
    name: "items",
    property: "items",
    docsUrl: "https://www.nextmv.io/docs/community-apps/knapsack/#input",
    summary: "Items to select from.",
    type: "items",
  },
  {
    id: "value",
    name: "value",
    property: "value",
    docsUrl: "https://www.nextmv.io/docs/community-apps/knapsack/#item",
    summary: "Value of the item.",
    type: "integer",
  },
  {
    id: "weight",
    name: "weight",
    property: "weight",
    docsUrl: "https://www.nextmv.io/docs/community-apps/knapsack/#item",
    summary: "Weight of the item.",
    type: "integer",
  },
  {
    id: "weight_capacity",
    name: "weight_capacity",
    property: "weight_capacity",
    docsUrl: "https://www.nextmv.io/docs/community-apps/knapsack/#input",
    summary: "Weight capacity of the knapsack.",
    type: "integer",
  },
];
