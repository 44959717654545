import React, { useState } from "react";
import { useTheme } from "@emotion/react";

import { UserOrganizations } from "../../api/core/dataPlane.types";
import { useUser } from "../../AuthProvider";
import Box from "../../components/Box";
import Flex from "../../components/Flex";
import { IconCaretY, IconHeaderProfile } from "../../icons";
import { getFilteredEntities } from "../../utils/entities/getFilteredEntities";
import { formatPlural } from "../../utils/format";
import { rem } from "../../utils/tools";
import { getBannerType } from "../Banner/utils/getBannerType";
import FreeTrialCta from "../FreeTrialCta";
import {
  getRandomIdentifier,
  handleClickOutside,
} from "../Modal2/utils/modalDisplay";
import OrgSwitcher from "../OrgSwitcher";
import ProfileMenu from "../ProfileMenu";
import { StyledScrimTransparent } from "../ProfileMenu/ProfileMenu.styled";
import Text from "../Text";

import LogoIconNextmv from "./components/LogoIconNextmv";
import {
  PlainLink,
  StyledCornerHelper,
  StyledFlex,
  StyledOrgHeader,
  StyledProfileLink,
} from "./OrgHeader.styled";
import { OrgHeaderProps } from "./OrgHeader.types";

const OrgHeader = ({ backgroundColor }: OrgHeaderProps) => {
  const [user] = useUser();
  const theme = useTheme();
  const bannerType = getBannerType(user);

  const currentTeam = (user.organizations || []).find(
    (org) => org.id === user.id
  );
  const invites = (user.organizations || []).filter(
    (org) => org.pending_invite
  );
  const teams = (user.organizations || []).filter((org) => !org.pending_invite);
  const hasTeams = !!teams.length;

  const [isOrgSwitcherActive, setIsOrgSwitcherActive] = useState(false);
  const [isProfileMenuActive, setIsProfileMenuActive] = useState(false);

  const randomIdentifier = getRandomIdentifier();
  const scrimHtmlId = `modal-scrim-${randomIdentifier}`;

  const [filterText, setFilterText] = useState("");

  const filteredTeams = getFilteredEntities(filterText, teams);

  const handleOrgSwitcherDisplay = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    return setIsOrgSwitcherActive(!isOrgSwitcherActive);
  };

  const handleProfileMenuDisplay = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    return setIsProfileMenuActive(!isProfileMenuActive);
  };

  return (
    <StyledOrgHeader {...{ backgroundColor }} hasBanner={!!bannerType}>
      <Flex ml={3} pl={rem(1)}>
        <LogoIconNextmv />
      </Flex>
      {hasTeams && (
        <>
          <StyledFlex
            ml={2}
            height={theme.spacing.s8}
            alignItems="center"
            data-testid={"org-header"}
            onClick={(e: any) => handleOrgSwitcherDisplay(e)}
          >
            <Text styleName="meta-1-bold" styles={{ color: theme.color.white }}>
              {currentTeam?.name || ""}
            </Text>

            <Flex mt={rem(1)} ml={2} alignItems="center">
              <IconCaretY
                iconColor={theme.color.white}
                iconOpacity={isOrgSwitcherActive ? 1 : 0.8}
              />
            </Flex>
          </StyledFlex>

          {!!invites.length && (
            <StyledFlex ml={2} height={theme.spacing.s8} alignItems="center">
              <PlainLink to="/invites" data-testid={"pending-invite"}>
                <Text
                  styleName="meta-2-bold"
                  styles={{
                    paddingTop: theme.spacing.s1,
                    paddingRight: theme.spacing.s2,
                    paddingBottom: theme.spacing.s1,
                    paddingLeft: theme.spacing.s2,
                    color: theme.color.white,
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    borderRadius: theme.border.radiusLarge,
                  }}
                >
                  {invites.length} pending team{" "}
                  {formatPlural("invite", invites.length)}
                </Text>
              </PlainLink>
            </StyledFlex>
          )}

          <OrgSwitcher
            {...{
              currentTeam,
              setIsOrgSwitcherActive,
              teams: filteredTeams as UserOrganizations,
            }}
            invitesCount={invites.length}
            isActive={isOrgSwitcherActive}
            configFilter={{
              inputText: filterText,
              testId: "filter-org-inputs",
              setInputText: setFilterText,
            }}
          />
        </>
      )}

      <StyledCornerHelper position="left" {...{ backgroundColor }} />
      <StyledCornerHelper position="right" {...{ backgroundColor }} />

      <Flex
        ml="auto"
        mr={5}
        height={theme.spacing.s8}
        alignItems="center"
        justifyContent="flex-end"
        relative
      >
        {hasTeams && (
          <Box mr={1}>
            <FreeTrialCta />
          </Box>
        )}

        <StyledProfileLink
          href="#profile-menu"
          onClick={(e: any) => handleProfileMenuDisplay(e)}
        >
          <IconHeaderProfile iconColor={theme.color.white} />
        </StyledProfileLink>
      </Flex>

      <ProfileMenu htmlId="profile-menu" isActive={isProfileMenuActive} />
      {isProfileMenuActive && (
        <StyledScrimTransparent
          {...{
            id: scrimHtmlId,
            hasBlur: true,
            onClick: (e) =>
              handleClickOutside({
                closeAction: handleProfileMenuDisplay,
                isActive: isProfileMenuActive,
                e,
                targetId: scrimHtmlId,
              }),
          }}
        />
      )}
    </StyledOrgHeader>
  );
};

export default OrgHeader;
