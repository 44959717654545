import React, { useEffect, useMemo, useState } from "react";
import { useTheme } from "@emotion/react";

import { AppResponse } from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";
import Box from "../../../components/Box";
import Button2 from "../../../components/Button2";
import Flex from "../../../components/Flex";
import Loading from "../../../components/Loading";
import Notification from "../../../components/Notification";
import Table2 from "../../../components/Table2";
import Text from "../../../components/Text";
import useMarketplacePartners from "../../../contexts/marketplace/hooks/useMarketplacePartners";
import useManageEntities from "../../../hooks/useManageEntities";
import { getAccUrl } from "../../../utils/navigation";
import { userHasAccessToAction } from "../../../utils/rbac_utils";
import { ActionGroups } from "../../../utils/rbac_utils/types";
import { rem } from "../../../utils/tools";
import ExecutableDetails from "../../App/components/ExecutableDetails";
import MarketplaceAppPreview from "../../App/components/MarketplaceAppPreview";
import { renderInstancesList } from "../../App/subpages/Instances/utils/renderInstancesList";
import { getRunHistoryEmptyMessage } from "../../App/subpages/RunHistory/utils/getRunHistoryEmptyMessage";
import { getRunHistoryItems } from "../../App/subpages/RunHistory/utils/getRunHistoryItems";
import { getRunHistoryTableHeaders } from "../../App/subpages/RunHistory/utils/getRunHistoryTableHeaders";
import { getTableLayoutSchemaAppRunHistory } from "../../App/subpages/RunHistory/utils/tableLayoutSchemas";
import { renderVersionsList } from "../../App/subpages/Versions/utils/renderVersionsList";
import { renderDashboardSectionTitle } from "../utils/renderDashboardSectionTitle";

import AppDefaultInstance from "./AppDefaultInstance";

type AppOverviewProps = {
  app: AppResponse;
};

const AppOverview = ({ app }: AppOverviewProps) => {
  const [{ id: accountId, roles = [], features = {} }] = useUser();
  const theme = useTheme();

  const [currentAppId, setCurrentAppId] = useState<string>();
  const [isPartialRunHistory, setIsPartialRunHistory] = useState(true);

  const {
    loadEntities: loadRuns,
    entities: runHistory,
    entitiesLoadError: runsLoadError,
    setEntities: setRunHistory,
    setEntitiesLoadError: setRunsLoadError,
  } = useManageEntities("runs");

  const {
    loadEntities: loadInstances,
    entities: instances,
    entitiesLoadError: instancesError,
    setEntities: setInstances,
    setEntitiesLoadError: setInstancesError,
  } = useManageEntities("instances");

  const {
    loadEntities: loadVersions,
    entities: versions,
    entitiesLoadError: versionsError,
    setEntities: setVersions,
    setEntitiesLoadError: setVersionsError,
  } = useManageEntities("versions");

  const {
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
    setMarketplacePartnerApp,
    setMarketplacePartnerAppError,
  } = useMarketplacePartners();

  // data reset if app selection changes
  useEffect(() => {
    if (currentAppId !== app.id) {
      setCurrentAppId(app.id);
      setInstances(null);
      setInstancesError("");
      setVersions(null);
      setVersionsError("");
      setRunHistory(null);
      setRunsLoadError("");
      setMarketplacePartnerApp(null);
      setMarketplacePartnerAppError(null);
    }
  }, [
    app.id,
    currentAppId,
    setInstances,
    setInstancesError,
    setMarketplacePartnerApp,
    setMarketplacePartnerAppError,
    setRunHistory,
    setRunsLoadError,
    setVersions,
    setVersionsError,
  ]);

  // load instances
  useEffect(() => {
    if (!instances) {
      loadInstances({
        applicationId: app.id,
        limit: 5,
      });
    }
  }, [app.id, instances, loadInstances]);

  // load versions
  useEffect(() => {
    if (app?.type === "custom" && !versions) {
      loadVersions({
        applicationId: app.id,
        limit: 5,
      });
    }
  }, [app.id, app?.type, loadVersions, versions]);

  // load marketplace app (if applicable)
  useEffect(() => {
    if (
      !marketplacePartnerApp &&
      !marketplacePartnerAppError &&
      app?.type === "subscription" &&
      app.subscription_id
    ) {
      loadMarketplacePartnerApp(app.subscription_id);
    }
  }, [
    app.subscription_id,
    app?.type,
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
  ]);

  // load runs
  useEffect(() => {
    if (!runHistory) {
      loadRuns({ applicationId: app.id });
    }
  }, [app.id, loadRuns, runHistory]);

  const appInstancesUrl = getAccUrl(accountId, `app/${app.id}/instances`);
  const appVersionsUrl = getAccUrl(accountId, `app/${app.id}/versions`);
  const appRunsUrl = getAccUrl(accountId, `app/${app.id}/runs`);

  const canUserCreateAndEditInstances = userHasAccessToAction(
    roles,
    ActionGroups.AppInstanceOperator,
    { locked: false }
  );
  const canUserCreateAndEditVersions = userHasAccessToAction(
    roles,
    ActionGroups.AppVersionOperator,
    {}
  );

  const renderInstances = () => {
    if (!instances) {
      return (
        <Box mt={3}>
          <Loading />
        </Box>
      );
    }
    if (instancesError) {
      return <Notification mt={3} type="error" message={instancesError} />;
    }
    return renderInstancesList({
      accountId,
      app,
      canUserCreateAndEditInstances,
      features: features,
      instances,
      isMini: true,
      limit: 5,
      theme,
      userRoles: roles,
    });
  };

  const renderSubscriptionAppNotice = () => {
    return (
      marketplacePartnerApp && (
        <Box mt={4}>
          <MarketplaceAppPreview marketplaceApp={marketplacePartnerApp} />

          <Text mt={4} styleName="body-2" styles={{ maxWidth: rem(480) }}>
            Versions for {app.name} are managed by the marketplace app it is
            subscribed to.
          </Text>
        </Box>
      )
    );
  };

  const renderVersions = () => {
    if (!versions) {
      return (
        <Box mt={3}>
          <Loading />
        </Box>
      );
    }
    if (versionsError) {
      return <Notification mt={3} type="error" message={versionsError} />;
    }
    return renderVersionsList({
      accountId,
      app,
      canUserCreateAndEditVersions,
      isMini: true,
      limit: 5,
      versions,
    });
  };

  const runHistoryHeaders = getRunHistoryTableHeaders({
    accId: accountId,
    appId: app.id,
    runHistory,
    theme,
    showRunName: true,
  });

  const runHistoryData = useMemo(() => {
    if (!runHistory) return [];

    return getRunHistoryItems({
      hasName: true,
      hasStatistics: false,
      runHistory,
    }).slice(0, isPartialRunHistory ? 7 : 25);
  }, [isPartialRunHistory, runHistory]);

  const renderRunHistory = () => {
    if (!runHistory) {
      <Box mt={3}>
        <Loading />
      </Box>;
    }
    if (runsLoadError) {
      return <Notification mt={3} type="error" message={runsLoadError} />;
    }

    return (
      <Box mt={3}>
        <Table2
          isWide
          useMinWidth
          isStickyColumn
          headers={runHistoryHeaders}
          data={runHistoryData}
          layoutSchema={getTableLayoutSchemaAppRunHistory(runHistory)}
          emptyMessage={getRunHistoryEmptyMessage({
            isLoading: !runHistory,
          })}
        />

        {runHistory && !!runHistory.length && (
          <Flex>
            <Button2
              mt={2}
              type="outline-quiet"
              htmlType={isPartialRunHistory ? "button" : undefined}
              to={isPartialRunHistory ? undefined : appRunsUrl}
              label={isPartialRunHistory ? "More" : "Go to runs"}
              {...(isPartialRunHistory && {
                onClick: () => setIsPartialRunHistory(false),
              })}
            />
          </Flex>
        )}
      </Box>
    );
  };

  return (
    <Box mt={4}>
      <Flex
        flexDirection={["column", "column", "row"]}
        flexWrap={["nowrap", "nowrap", "wrap", "nowrap"]}
      >
        <Flex
          width={["100%", "100%", "100%", rem(296)]}
          flexDirection={["column", "row", "row", "column"]}
          flexShrink={0}
        >
          {app?.default_instance && (
            <AppDefaultInstance instanceId={app?.default_instance} />
          )}

          <ExecutableDetails
            executable={app?.active_executable}
            label="Last pushed executable (dev)"
          />
        </Flex>

        <Box
          width={["100%", "100%", "50%", "100%"]}
          mt={[7, 7, 7, -9]}
          pl={[0, 0, 0, 8]}
          pr={[0, 0, 4, 0]}
        >
          {renderDashboardSectionTitle({
            title: "Recent Instances",
            description: "View all",
            url: appInstancesUrl,
            theme,
          })}
          {renderInstances()}
        </Box>

        <Box
          width={["100%", "100%", "50%", "100%"]}
          mt={[6, 7, 7, -9]}
          pl={[0, 0, 4, 8]}
        >
          {renderDashboardSectionTitle({
            title: "Recent Versions",
            theme,
            ...(app?.type === "custom" && {
              description: "View all",
              url: appVersionsUrl,
            }),
          })}
          {app.type === "subscription"
            ? renderSubscriptionAppNotice()
            : renderVersions()}
        </Box>
      </Flex>

      <Box mt={[7, 7, 8]}>
        {renderDashboardSectionTitle({
          title: "Recent Runs",
          description: "View all",
          url: appRunsUrl,
          theme,
        })}
        {renderRunHistory()}
      </Box>
    </Box>
  );
};

export default AppOverview;
