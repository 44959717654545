import styled from "@emotion/styled";

export const StyledSortable = styled.div`
  position: relative;
`;

type StyledDragHandleProps = {
  isInactive: boolean;
};

export const StyledDragHandle = styled.span<StyledDragHandleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.s6};
  height: ${({ theme }) => theme.spacing.s6};
  margin-top: ${({ theme }) => theme.spacing.s6};
  cursor: move;
  border-radius: ${({ theme }) => theme.border.radius};

  svg {
    fill: ${({ theme }) => theme.color.gray500};
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.color.gray200};
    svg {
      fill: ${({ theme }) => theme.color.gray700};
    }
  }

  ${(props) =>
    props.isInactive &&
    `
      opacity: 0.5;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        svg {
          fill: ${props.theme.color.gray500};
        }
      }
    `}
`;
