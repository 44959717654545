import * as React from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Auth } from "aws-amplify";

import { useUser } from "../../AuthProvider";
import Flex from "../../components/Flex";
import {
  IconExternalLink,
  IconMainNavDocs,
  IconMainNavLogout,
} from "../../icons";
import { getPathWithoutOrg } from "../../utils/getPathWithoutOrg";
import {
  checkIsActivePage,
  checkIsActiveSection,
} from "../../utils/navigation";
import { getBannerType } from "../Banner/utils/getBannerType";

import { getMainNavPages } from "./data/pages";
import { renderMainNavIcon } from "./utils/renderMainNavIcon";
import {
  BottomNavLinks,
  MainNavDocsItem,
  MainNavDocsLink,
  MainNavItem,
  MainNavLabel,
  MainNavLink,
  MainNavList,
  MainNavLogoutLink,
  StyledMainNav,
  StyledSidebar,
} from "./MainNav.styles";

const MainNav = () => {
  const [user] = useUser();
  const { pathname } = useLocation();
  const theme = useTheme();
  const bannerType = getBannerType(user);

  const pathWithoutOrg = getPathWithoutOrg(pathname);
  const pages = getMainNavPages(user.id, user.features);

  const signOut = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    return Auth.signOut();
  };

  return (
    <StyledSidebar
      pl={[4, 0]}
      flexDirection={["row", "column"]}
      alignItems="center"
      hasBanner={!!bannerType}
    >
      <Flex
        mt={[0, 3]}
        flexGrow={1}
        flexDirection={["row", "column"]}
        justifyContent="space-between"
        alignItems="center"
        pr={[7, 0]}
      >
        <StyledMainNav aria-label="Main">
          <MainNavList>
            {pages.map((page, index) => {
              if (!page.url) return <></>;

              const isActiveSection = checkIsActiveSection({
                currentPath: pathWithoutOrg,
                pageUrl: page.url,
              });
              const isActivePage = checkIsActivePage({
                currentPath: pathWithoutOrg,
                pageUrl: page.url,
              });

              return (
                <MainNavItem key={page.id} id={`id-page-${page.id}`}>
                  <MainNavLink
                    isactive={isActiveSection.toString()}
                    to={page.url}
                    tabIndex={index + 1}
                    aria-current={isActivePage ? "page" : undefined}
                  >
                    {renderMainNavIcon({
                      id: page.id,
                      isActiveSection,
                      theme,
                    })}
                    <MainNavLabel>{page.label}</MainNavLabel>
                  </MainNavLink>
                </MainNavItem>
              );
            })}
          </MainNavList>
        </StyledMainNav>

        <BottomNavLinks pb={3}>
          <MainNavList>
            <MainNavDocsItem>
              <MainNavDocsLink
                href="https://www.nextmv.io/docs"
                target="_blank"
                rel="noreferrer"
                title="Open Nextmv Docs in a new tab"
              >
                <IconMainNavDocs
                  className="icon-main-nav"
                  iconColor={theme.color.gray500}
                  aria-hidden="true"
                />
                <MainNavLabel>
                  Nextmv&nbsp;Docs
                  <IconExternalLink ml={1} iconColor={theme.color.white} />
                </MainNavLabel>
              </MainNavDocsLink>
            </MainNavDocsItem>

            <MainNavItem>
              <MainNavLogoutLink
                onClick={(e) => signOut(e)}
                title="Log out of your Nextmv Account"
                data-testid={"logout-button"}
                href="#"
              >
                <IconMainNavLogout
                  className="icon-main-nav"
                  iconColor={theme.color.gray500}
                  aria-hidden="true"
                />
                <MainNavLabel>Log&nbsp;out</MainNavLabel>
              </MainNavLogoutLink>
            </MainNavItem>
          </MainNavList>
        </BottomNavLinks>
      </Flex>
    </StyledSidebar>
  );
};

export default MainNav;
