import React, { useRef, useState } from "react";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../analytics";
import Button2 from "../../../components/Button2";
import Flex from "../../../components/Flex";
import Text from "../../../components/Text";
import { IconCheck, IconCopy } from "../../../icons";
import { rem } from "../../../utils/tools";
import { ScriptCommand, ToastCopyMessage } from "../Install.styled";
import { CopyCodeContentParams, CopyCommandProps } from "../Install.types";

const CopyCommand = ({
  buttonAriaLabel,
  command,
  trackEventData,
  type,
}: CopyCommandProps) => {
  const theme = useTheme();
  const refPre = useRef<any>(null);
  const [toastMessage, setToastMessage] = useState("");

  const copyCodeContent = ({ e, ref }: CopyCodeContentParams) => {
    e.preventDefault();

    if (ref?.current) {
      navigator.clipboard.writeText(ref.current.innerText);

      setToastMessage("Copied");
      trackEvent("General", trackEventData);

      // remove message after 3 seconds
      const timer = setTimeout(() => {
        setToastMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      return null;
    }
  };

  return (
    <Flex alignItems="flex-start" relative>
      <ScriptCommand {...{ type }} ref={refPre}>
        {command}
      </ScriptCommand>

      {toastMessage && (
        <ToastCopyMessage>
          <IconCheck iconColor={theme.color.white} />{" "}
          <Text body3 medium textColor={theme.color.white}>
            {toastMessage}
          </Text>
        </ToastCopyMessage>
      )}

      <Button2
        mt={2}
        ml={3}
        icon={
          toastMessage ? (
            <IconCheck iconSize={20} iconColor={theme.color.gray500} />
          ) : (
            <IconCopy iconColor={theme.color.gray500} />
          )
        }
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          copyCodeContent({
            e,
            ref: refPre,
          })
        }
        type="outline-quiet"
        title={buttonAriaLabel}
        aria-label={buttonAriaLabel}
        styles={{
          width: theme.spacing.s7,
          boxShadow: `inset 0 0 0 ${rem(1.5)} ${theme.color.gray400}`,
          "&:hover": {
            boxShadow: `inset 0 0 0 ${rem(1.5)} ${theme.color.gray500}`,
          },
        }}
      />
    </Flex>
  );
};

export default CopyCommand;
