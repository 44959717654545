import * as React from "react";
import styled from "@emotion/styled/macro";
import {
  alignSelf,
  background,
  display,
  flexGrow,
  flexShrink,
  height,
  justifySelf,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  order,
  space,
  verticalAlign,
  width,
} from "styled-system";

import { filterProps } from "../../utils/filterProps";

import { BoxProps, StyledBoxProps } from "./Box.types";

const StyledBox = styled("div", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledBoxProps>`
  box-sizing: border-box;

  ${(props) => !!props.relative && "position: relative;"}
  ${(props) => !!props.noShrink && "flex-shrink: 0;"}
  ${alignSelf}
  ${background}
  ${flexGrow}
  ${flexShrink}
  ${height}
  ${justifySelf}
  ${maxHeight}
  ${minHeight}
  ${maxWidth}
  ${minWidth}
  ${order}
  ${space}
  ${width}
  ${display}
  ${verticalAlign}

  ${(props) =>
    props.hasBorder &&
    `
  border-width: ${
    props.hasBorderWidthThick
      ? props.theme.border.widthThick
      : props.theme.border.width
  };
  border-style: ${props.theme.border.style};
  border-color: ${props.theme.color.gray200};
  `}

  ${(props) =>
    props.hasBorderTop &&
    `
  border-top-width: ${
    props.hasBorderWidthThick
      ? props.theme.border.widthThick
      : props.theme.border.width
  };
  border-top-style: ${props.theme.border.style};
  border-top-color: ${props.theme.color.gray200};
  `}

  ${(props) =>
    props.hasBorderRight &&
    `
  border-right-width: ${
    props.hasBorderWidthThick
      ? props.theme.border.widthThick
      : props.theme.border.width
  };
  border-right-style: ${props.theme.border.style};
  border-right-color: ${props.theme.color.gray200};
  `}

  ${(props) =>
    props.hasBorderBottom &&
    `
  border-bottom-width: ${
    props.hasBorderWidthThick
      ? props.theme.border.widthThick
      : props.theme.border.width
  };
  border-bottom-style: ${props.theme.border.style};
  border-bottom-color: ${props.theme.color.gray200};
  `}

  ${(props) =>
    props.hasBorderLeft &&
    `
  border-left-width: ${
    props.hasBorderWidthThick
      ? props.theme.border.widthThick
      : props.theme.border.width
  };
  border-left-style: ${props.theme.border.style};
  border-left-color: ${props.theme.color.gray200};
  `}

  ${(props) =>
    props.isRounded &&
    `
  border-radius: ${props.theme.border.radius};
  `}
`;

const Box = ({
  alignSelf,
  background,
  className,
  children,
  flexGrow,
  flexShrink,
  hasBorder,
  hasBorderBottom,
  hasBorderLeft,
  hasBorderRight,
  hasBorderTop,
  hasBorderWidthThick,
  height,
  isRounded,
  justifySelf,
  maxHeight,
  minHeight,
  maxWidth,
  minWidth,
  noShrink,
  order,
  relative,
  width,
  display,
  verticalAlign,
  ...rest
}: BoxProps) => (
  <StyledBox
    {...{
      alignSelf,
      background,
      className,
      flexGrow,
      flexShrink,
      hasBorder,
      hasBorderBottom,
      hasBorderLeft,
      hasBorderRight,
      hasBorderTop,
      hasBorderWidthThick,
      height,
      isRounded,
      justifySelf,
      maxHeight,
      minHeight,
      maxWidth,
      minWidth,
      noShrink,
      order,
      relative,
      width,
      display,
      verticalAlign,
      ...rest,
    }}
  >
    {children}
  </StyledBox>
);

export default Box;
