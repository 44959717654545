import { useTheme } from "@emotion/react";

import Box from "../../components/Box";
import Button2 from "../../components/Button2";
import Flex from "../../components/Flex";
import { IconX } from "../../icons";
import { rem } from "../../utils/tools";
import Input from "../Input";
import Tooltip from "../Tooltip";

import { AddConfigOptionsProps } from "./AddConfigOptions.types";

const AddConfigOptions = ({
  actionButtonLabel,
  addEmptyConfigOption,
  handleConfigOptionChange,
  isDisabled,
  isRunning,
  pendingConfigOptions,
  removeConfigOption,
  size = "default",
  sectionTooltip,
}: AddConfigOptionsProps) => {
  const theme = useTheme();

  const interceptRemoveConfigOption = (
    e: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    if (
      pendingConfigOptions.length === 1 &&
      !pendingConfigOptions[0].option &&
      !pendingConfigOptions[0].value
    ) {
      return;
    }

    return removeConfigOption(e, index);
  };

  return (
    <>
      <Box
        mr={-3}
        minHeight={size === "small" ? theme.spacing.s7 : theme.spacing.s8}
      >
        {pendingConfigOptions.map((pendingConfigOption, index) => {
          return (
            <Flex
              key={`config-option-${index}`}
              mt={index === 0 ? 0 : 1}
              alignItems={["flex-start", "center"]}
            >
              <Flex
                width="100%"
                maxWidth={[rem(408), rem(516)]}
                alignItems={["flex-start", "center"]}
                flexDirection={["column", "row"]}
              >
                <Box
                  width={["100%", "50%"]}
                  pr={rem(2)}
                  maxWidth={[rem(408), rem(256)]}
                  flexShrink={0}
                >
                  <Input
                    {...(size === "small" && {
                      size,
                    })}
                    isDisabled={isDisabled || isRunning}
                    htmlType="text"
                    name={`config-option-${index}-option`}
                    placeholder="Config option"
                    data-testid={`new-instance-config-option-${index}-option`}
                    value={pendingConfigOption.option}
                    onChange={(e: {
                      target: { name: string; value: string };
                    }) =>
                      handleConfigOptionChange({
                        value: e.target.value,
                        index,
                        pendingConfigOptions,
                        type: "option",
                      })
                    }
                  />
                </Box>
                <Box
                  width={["100%", "50%"]}
                  maxWidth={[rem(408), rem(256)]}
                  mt={[1, 0]}
                  pl={rem(2)}
                  flexShrink={0}
                >
                  <Input
                    {...(size === "small" && {
                      size,
                    })}
                    isDisabled={isDisabled || isRunning}
                    htmlType="text"
                    name={`config-option-${index}-value`}
                    placeholder="Config value"
                    data-testid={`new-instance-config-option-${index}-value`}
                    value={pendingConfigOption.value}
                    onChange={(e: any) =>
                      handleConfigOptionChange({
                        value: e.target.value,
                        index,
                        pendingConfigOptions,
                        type: "value",
                      })
                    }
                  />
                </Box>
              </Flex>

              <Button2
                isDisabled={isDisabled}
                mt={[1, 0]}
                mr={1}
                htmlType="button"
                type="text-quiet"
                icon={
                  <IconX
                    iconColor={theme.color.gray500}
                    iconColorHover={theme.color.gray600}
                  />
                }
                styles={{
                  flexShrink: 0,
                  width: theme.spacing.s6,
                }}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  interceptRemoveConfigOption(e, index)
                }
              />
            </Flex>
          );
        })}
      </Box>

      <Flex mt={2} alignItems="center">
        <Button2
          isDisabled={isDisabled}
          htmlType="button"
          type="outline-quiet"
          label={actionButtonLabel || "Add config"}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            addEmptyConfigOption(e)
          }
          styles={{
            backgroundColor: theme.color.white,
          }}
        />
        {sectionTooltip && (
          <Tooltip ml={2} hexColorString="#4740cabf">
            {sectionTooltip.content}
          </Tooltip>
        )}
      </Flex>
    </>
  );
};

export default AddConfigOptions;
