import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../analytics";
import Box from "../../../components/Box";
import Button2 from "../../../components/Button2";
import Flex from "../../../components/Flex";
import Header from "../../../components/Header";
import { useMetaTitle } from "../../../components/Layout";
import PreviewItem from "../../../components/PreviewItem";
import Tag from "../../../components/Tag";
import Text from "../../../components/Text";
import { IconExternalLink } from "../../../icons";
import { rem } from "../../../utils/tools";
import { getIntegrationAvatar } from "../helpers/avatars";
import { MoreLink } from "../Integrations.styles";
import {
  AvailableIntegration,
  AvailableIntegrationsProps,
} from "../Integrations.types";

const pageTitle = "Nextmv Routing Integrations";

const AvailableIntegrations = ({
  integrations,
}: AvailableIntegrationsProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const { pathname } = useLocation();
  const theme = useTheme();

  const [filterIntegrations, setFilterIntegrations] = useState("");

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  const filteredIntegrations = filterIntegrations
    ? integrations.filter((integration) =>
        integration.name
          .toLowerCase()
          .includes(filterIntegrations.toLowerCase())
      )
    : integrations;

  const renderIntegrations = () =>
    integrations.length ? (
      <Flex width="100%" flexWrap="wrap" justifyContent="space-between">
        {filteredIntegrations.map((integration: AvailableIntegration) => {
          const buttonUrl = integration?.isFenced
            ? "https://www.nextmv.io/contact"
            : `${pathname}/${integration.type}/new`;

          return (
            <Box
              key={integration.type}
              width={[
                "100%",
                "100%",
                `calc((100% - ${theme.spacing.s4}) / 2)`,
                `calc((100% - ${theme.spacing.s4}) / 2)`,
                `calc((100% - ${theme.spacing.s4} * 2) / 3)`,
              ]}
              mt={4}
            >
              <PreviewItem
                avatar={getIntegrationAvatar(integration.type, 32)}
                name={integration.typeName}
                description={integration.description}
                renderExtra={
                  <>
                    {(integration?.isFenced || integration?.isEarlyAccess) && (
                      <Flex alignItems="center">
                        {integration?.isFenced && (
                          <Tag mr={1} type="premium" hasIconLock />
                        )}
                        {integration.isEarlyAccess && (
                          <Tag
                            type="early-access"
                            hasIconLock={integration?.isFenced}
                          />
                        )}
                      </Flex>
                    )}

                    <Flex mt={2}>
                      <Button2
                        data-testid={`add-new-integration-${integration.type}`}
                        mr={4}
                        label={
                          integration?.isFenced
                            ? "Request Access"
                            : "Add new integration"
                        }
                        to={integration?.isFenced ? undefined : buttonUrl}
                        href={integration?.isFenced ? buttonUrl : undefined}
                        {...(integration?.isFenced && {
                          target: "_blank",
                          rel: "noreferrer",
                        })}
                        onClick={
                          integration?.isFenced
                            ? () =>
                                trackEvent("Integrations", {
                                  view: "Available",
                                  action: "Request Access Button Clicked",
                                  meta: {
                                    type: integration.type,
                                  },
                                })
                            : () =>
                                trackEvent("Integrations", {
                                  view: "Available",
                                  action: "Add Integration Button Clicked",
                                  meta: {
                                    type: integration.type,
                                  },
                                })
                        }
                      />
                    </Flex>

                    {integration.docsUrl && (
                      <MoreLink
                        as="a"
                        href={integration.docsUrl}
                        onClick={() =>
                          trackEvent("Integrations", {
                            view: "Available",
                            action: "Integration Details Clicked (Docs)",
                            meta: {
                              type: integration.type,
                            },
                          })
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        Details
                        <IconExternalLink
                          ml={rem(2)}
                          iconColor={theme.color.gray500}
                        />
                      </MoreLink>
                    )}
                  </>
                }
              />
            </Box>
          );
        })}
      </Flex>
    ) : (
      <Box maxWidth={rem(516)}>
        <Text mt={2} styleName="body-1">
          No integrations available.
        </Text>
      </Box>
    );

  return (
    <>
      <Header
        configPageTitle={{ label: pageTitle }}
        configFilter={{
          inputText: filterIntegrations,
          testId: "filter-available-integrations",
          setInputText: setFilterIntegrations,
        }}
      />

      {renderIntegrations()}
    </>
  );
};

export default AvailableIntegrations;
