import * as React from "react";
import styled from "@emotion/styled";
import { startCase } from "lodash";

import { styleScrollbarY } from "../../../utils/constants";
import { formatTime, rem, roundNumber } from "../../../utils/tools";
import Text from "../../Text";

export const TooltipContainer = styled.div`
  max-height: ${rem(240)};
  overflow-y: scroll;

  ${({ theme }) => styleScrollbarY(theme)}

  padding-top: ${({ theme }) => theme.spacing.s3};
  padding-right: ${({ theme }) => theme.spacing.s4};
  padding-bottom: ${({ theme }) => theme.spacing.s3};
  padding-left: ${({ theme }) => theme.spacing.s4};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.border.radius};
  box-shadow: 0 0 ${rem(2)} 0 ${({ theme }) => theme.color.gray400T},
    0 ${rem(2)} ${rem(16)} 0 ${({ theme }) => theme.color.gray200T};
`;

export const TooltipLabel = styled.h5`
  margin-bottom: ${rem(6)};
  padding-bottom: ${({ theme }) => theme.spacing.s1};
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyMeta1Bold};
  font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta1Bold};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightMeta1Bold};
  line-height: ${({ theme }) => theme.ui2Typography.lineHeightMeta1Bold};
  color: ${({ theme }) => theme.color.gray800};
  border-bottom-width: ${({ theme }) => theme.border.width};
  border-bottom-style: ${({ theme }) => theme.border.style};
  border-bottom-color: ${({ theme }) => theme.color.gray200};
`;

export const TooltipList = styled.ul`
  list-style: none;
`;
export const TooltipItem = styled.li`
  display: flex;
  align-items: center;
  padding-top: ${rem(3)};
`;

interface LegendSwatchProps {
  backgroundColor: string;
}
export const LegendSwatch = styled.span<LegendSwatchProps>`
  display: block;
  width: ${({ theme }) => theme.spacing.s3};
  height: ${({ theme }) => theme.spacing.s3};
  margin-right: ${({ theme }) => theme.spacing.s1};
  border-radius: ${rem(2)};
  background-color: ${(props) => props.backgroundColor};
`;

export const LegendCircle = styled.span<LegendSwatchProps>`
  display: block;
  width: ${({ theme }) => theme.spacing.s2};
  height: ${({ theme }) => theme.spacing.s2};
  margin-right: ${({ theme }) => theme.spacing.s1};
  border-radius: ${({ theme }) => theme.spacing.s2};
  border-width: ${({ theme }) => theme.border.widthThick};
  border-style: ${({ theme }) => theme.border.style};
  border-color: ${({ theme }) => theme.color.white};
  background-color: ${(props) => props.backgroundColor};
`;

// NOTE: active and payload props are passed from parent Chart
// component, see for example:
// https://recharts.org/en-US/examples/CustomContentOfTooltip
type CustomTooltipProps = {
  noTotal: boolean;
  active?: boolean;
  payload?: any[];
  title?: string;
};
const CustomTooltip = ({
  active,
  noTotal,
  payload,
  title,
}: CustomTooltipProps) => {
  // extract solution from payload object in payload array
  // (e.g. payload.payload.solution), odd method, but only way
  // I see to get the current solution being hovered over
  const thisSolution =
    !!payload?.length &&
    payload.reduce(
      (acc, curr) =>
        curr.hasOwnProperty("payload") &&
        curr.payload.hasOwnProperty("solution")
          ? curr.payload.solution
          : acc,
      null
    );

  // this could be abstracted from any item in the payload
  // array if it is included as part of the data, but for
  // now the total value is just the sum of all the
  // individual bar values
  const totalValue =
    !!payload?.length &&
    payload.reduce((acc, curr) => {
      return acc + Math.round(curr.value);
    }, 0);

  const tooltipTitle =
    title || (thisSolution ? `Solution ${thisSolution}` : "");
  return !!active ? (
    <TooltipContainer>
      {tooltipTitle && <TooltipLabel>{tooltipTitle}</TooltipLabel>}
      {!noTotal && (
        <Text as="h5" styleName="meta-1-bold">
          Total: {totalValue.toLocaleString()}
        </Text>
      )}
      <TooltipList>
        {!!payload?.length &&
          payload.reverse().map((datum, index) => (
            <TooltipItem key={`${datum.dataKey}-${index}`}>
              {/* FIXME: this should fail gracefully if somehow a non-color string got passed, but it's not the most robust boundary checking */}
              {datum.hasOwnProperty("fill") && datum.fill && (
                <LegendSwatch backgroundColor={datum.fill} />
              )}
              <Text styleName="meta-1">
                {startCase(datum.name)}:
                <Text
                  as="strong"
                  styleName="meta-1-bold"
                  styles={{ fontFeatureSettings: "tnum" }}
                >
                  {" " +
                    (datum.name === "timeElapsed"
                      ? formatTime(datum.value)
                      : roundNumber(datum.value, 3).toLocaleString("EN-US"))}
                </Text>
              </Text>
            </TooltipItem>
          ))}
      </TooltipList>
    </TooltipContainer>
  ) : (
    <></>
  );
};

export default CustomTooltip;
