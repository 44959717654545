import styled from "@emotion/styled";

import Flex from "../../../../../../components/Flex";
import { rem } from "../../../../../../utils/tools";

type MapMenuProps = {
  isDisabled: boolean;
};
export const MapMenuStyled = styled(Flex)<MapMenuProps>`
  flex-direction: column;
  align-items: flex-end;
  border-radius: ${({ theme }) => theme.border.radiusSmall};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 0 0 ${({ theme }) => theme.border.widthThick}
    ${(props) =>
      props.isDisabled
        ? props.theme.color.gray200T
        : props.theme.color.gray300T};
`;

export const MapMenuControl = styled.button`
  width: ${({ theme }) => theme.spacing.s7};
  height: ${({ theme }) => theme.spacing.s7};
  padding: ${rem(2)} 0 0 0;
  cursor: pointer;
  border: 0;
  background-color: transparent;
`;

export const Notification = styled.span`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ theme }) => theme.spacing.s4};
  height: ${({ theme }) => theme.spacing.s4};
  margin-right: ${rem(2)};
  margin-left: ${rem(10)};
  padding-right: ${({ theme }) => theme.spacing.s1};
  padding-left: ${({ theme }) => theme.spacing.s1};
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyMeta2Bold};
  font-size: ${rem(11)};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightMeta2Bold};
  line-height: ${({ theme }) => theme.ui2Typography.lineHeightMeta2Bold};
  color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.spacing.s4};
  background-color: ${({ theme }) => theme.color.red500};
`;
