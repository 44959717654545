import React from "react";
import { useTheme } from "@emotion/react";

import Flex from "../../../components/Flex";
import { rem } from "../../../utils/tools";
import { StyledMarketplaceAppImage } from "../MarketplaceApp.styled";
import { MarketplaceAppScreenshotsProps } from "../MarketplaceApp.types";

const MarketplaceAppScreenshots = ({
  imageUrl,
  imageAlt,
}: MarketplaceAppScreenshotsProps) => {
  const theme = useTheme();

  return (
    <Flex
      mt={7}
      // TODO: fix responsive
      ml={-6}
      mr={-7}
      pt={2}
      pb={2}
      pr={[5, 7]}
      pl={[5, 7]}
      hasBorderTop
      hasBorderBottom
      background={theme.color.gray100}
    >
      <StyledMarketplaceAppImage
        src={imageUrl}
        alt={imageAlt}
        maxWidth={rem(1160)}
      />
    </Flex>
  );
};

export default MarketplaceAppScreenshots;
