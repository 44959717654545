import { Headers } from "react-csv/lib/core";
import { Table } from "@tanstack/react-table";

import { TableData, TableHeader } from "../Table2.types";

// Due to possibility of pre-validation table data still having
// the potential to include data that could be used in CSV injection attacks
// this will ensure that data starting with identified characters will be
// prepended with a ' in order to prevent a script being run on a users
// machine when downloading the CSV.
const cleanData = <T extends string | number>(value: T): T => {
  if (
    typeof value === "string" &&
    isNaN(+value) && // do not alter strings that can be parsed as negative numbers
    value.length &&
    ["=", "+", "@", "-"].includes(value[0])
  ) {
    return `'${value}` as T;
  }
  return value;
};

export const getFilteredandCleanedTableData = (table: Table<TableData>) => {
  const filteredTableData = table.getRowModel().rows.map((row) => {
    const rowData: TableData = {};
    for (const [key, value] of Object.entries(row.original)) {
      rowData[key] = cleanData(value);
    }
    return rowData;
  });

  return filteredTableData;
};

export const getHeadersCSV = (headers: TableHeader) => {
  let isMissingKey: boolean = false;
  const CSVHeaders: Headers = headers.map((header) => {
    if (!header?.accessorKey) {
      isMissingKey = true;
    }
    return {
      key: header?.accessorKey,
      label: cleanData(header?.label?.toUpperCase() || header.accessorKey),
    };
  });
  return isMissingKey ? undefined : CSVHeaders;
};
