import styled from "@emotion/styled";
import { space } from "styled-system";

import { filterProps } from "../../utils/filterProps";
import { rem } from "../../utils/tools";
import {
  getInputBackgroundColorValue,
  getInputBorderColorValue,
  getInputTextColorValue,
  getPlaceholderTextColorValue,
} from "../Input/utils/getInputColorStyles";
import {
  getInputPaddingLeftValue,
  getInputPaddingRightValue,
} from "../Input/utils/getInputPadding";
import {
  getInputBorderRadiusValue,
  getInputFontStyles,
  getInputHeightValue,
} from "../Input/utils/getInputSizeStyles";

import { getSelectOptionPaddingYValue } from "./utils/getSelectPadding";
import { getSelectTextStyles } from "./utils/getSelectTextStyles";
import {
  SelectProvidedStyles,
  SelectState,
  SelectStylesParams,
  StyledSelectWrapperProps,
} from "./Select.types";

export const selectStyles = ({
  expand,
  orientation,
  size,
  theme,
}: SelectStylesParams) => ({
  // container for clear icon
  clearIndicator: (provided: SelectProvidedStyles, _state: SelectState) => ({
    ...provided,
    cursor: "pointer",
    // background: "yellow",
    transform: `translateX(${theme.spacing["s3"]})`,
  }),
  // outer container for the select entity
  container: (provided: SelectProvidedStyles, _state: SelectState) => ({
    ...provided,
    width: "100%",
  }),
  // main select entity
  control: (_provided: SelectProvidedStyles, state: SelectState) => ({
    boxSizing: "border-box",
    display: "flex",
    width: "100%",
    position: "relative",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    height: getInputHeightValue({ size, theme }),
    cursor: "default",
    outline: "0 !important",
    borderWidth: theme.border.width,
    borderStyle: theme.border.style,
    borderColor: getInputBorderColorValue({ state, theme }),
    borderRadius: getInputBorderRadiusValue({ size, theme }),
    backgroundColor: getInputBackgroundColorValue({ state, theme }),
    "&:hover": {
      borderColor: getInputBorderColorValue({ state, theme }),
    },
    ...(state.isFocused && {
      boxShadow: `0 0 0 ${rem(0.5)} ${getInputBorderColorValue({
        state,
        theme,
      })}`,
    }),
  }),
  // container for dropdown arrow
  dropdownIndicator: (provided: SelectProvidedStyles, _state: SelectState) => ({
    ...provided,
    paddingTop: 0,
    paddingRight: getInputPaddingRightValue({ size, theme }),
    paddingBottom: 0,
    paddingLeft: getInputPaddingLeftValue({ size, theme }),
  }),
  group: (provided: SelectProvidedStyles, _state: SelectState) => ({
    ...provided,
  }),
  groupHeading: (provided: SelectProvidedStyles, _state: SelectState) => ({
    ...provided,
  }),
  // container for dropdown and clear indicator and separator
  indicatorsContainer: (
    provided: SelectProvidedStyles,
    _state: SelectState
  ) => ({
    ...provided,
  }),
  // vertical line between value and rightside icon
  indicatorSeparator: (
    _provided: SelectProvidedStyles,
    _state: SelectState
  ) => ({
    display: "none",
  }),
  // user-entered value input
  input: (provided: SelectProvidedStyles, state: SelectState) => ({
    ...provided,
    ...getSelectTextStyles({ size, theme, type: "input" }),
  }),
  // container for loading icon
  loadingIndicator: (provided: SelectProvidedStyles, _state: SelectState) => ({
    ...provided,
  }),
  // loading message text style
  loadingMessage: (provided: SelectProvidedStyles, _state: SelectState) => ({
    ...provided,
  }),
  // dropdown panel that contains the menu list
  menu: (provided: SelectProvidedStyles, state: SelectState) => ({
    ...provided,
    boxSizing: "border-box",
    width: `calc(100% * ${expand})`,
    margin: 0,
    padding: 0,
    borderWidth: theme.border.width,
    borderStyle: theme.border.style,
    borderColor: getInputBorderColorValue({
      state: { isFocused: true },
      theme,
    }),
    borderRadius: getInputBorderRadiusValue({ size, theme }),
    backgroundColor: getInputBackgroundColorValue({ state, theme }),
    boxShadow: `0 0 0 ${rem(0.5)} ${getInputBorderColorValue({
      state: { isFocused: true },
      theme,
    })}`,
    transform: `translateY(${rem(1)})`,
    zIndex: 1001,
    ...(orientation === "down" && {
      paddingBottom: theme.spacing.s3,
      // add special styles to make the menu overlap the
      // bottom of the select box if the menu width is not
      // expanded past the width of the main select box
      ...(expand === 1 && {
        borderRadius: 0,
        borderBottomRightRadius: getInputBorderRadiusValue({ size, theme }),
        borderBottomLeftRadius: getInputBorderRadiusValue({ size, theme }),
        transform: `translateY(${rem(-2)})`,
        // "erase" the top border of the menu box
        "&::before": {
          content: '""',
          display: "block",
          width: "100%",
          height: rem(2),
          position: "absolute",
          left: 0,
          top: rem(-2),
          backgroundColor: getInputBackgroundColorValue({ state, theme }),
        },
      }),
    }),
    ...(orientation === "up" && {
      paddingTop: theme.spacing.s2,
      top: "auto",
      bottom: getInputHeightValue({ size, theme }),
      // same as above, but different orientation
      ...(expand === 1 && {
        borderRadius: 0,
        borderTopRightRadius: getInputBorderRadiusValue({ size, theme }),
        borderTopLeftRadius: getInputBorderRadiusValue({ size, theme }),
        transform: `translateY(${rem(2)})`,
        // "erase" the top border of the menu box
        "&::before": {
          content: '""',
          display: "block",
          width: "100%",
          height: rem(2),
          position: "absolute",
          left: 0,
          bottom: rem(-2),
          backgroundColor: getInputBackgroundColorValue({ state, theme }),
        },
      }),
    }),
  }),
  // contains list of options in dropdown panel
  menuList: (provided: SelectProvidedStyles, _state: SelectState) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  menuPortal: (provided: SelectProvidedStyles, _state: SelectState) => ({
    ...provided,
  }),
  // container box for multiValueLabel and multiValueRemove
  multiValue: (provided: SelectProvidedStyles, _state: SelectState) => ({
    ...provided,
  }),
  // label style for multi-value
  multiValueLabel: (provided: SelectProvidedStyles, _state: SelectState) => ({
    ...provided,
  }),
  // container for multi-value icon
  multiValueRemove: (provided: SelectProvidedStyles, _state: SelectState) => ({
    ...provided,
  }),
  // no options text style
  noOptionsMessage: (provided: SelectProvidedStyles, state: SelectState) => ({
    ...provided,
    ...getSelectTextStyles({ size, theme, type: "no-options" }),
    color: theme.color.gray600,
  }),
  // individual options in menu list
  option: (_provided: SelectProvidedStyles, state: SelectState) => ({
    ...getSelectTextStyles({ size, theme, type: "option" }),
    boxSizing: "border-box",
    // width: "100%",
    margin: 0,
    marginRight: theme.spacing.s3,
    marginLeft: theme.spacing.s3,
    paddingTop: getSelectOptionPaddingYValue({ size, theme }),
    paddingRight: 0,
    paddingBottom: getSelectOptionPaddingYValue({ size, theme }),
    paddingLeft: 0,
    color: getInputTextColorValue({ state, theme }),
    borderRadius: getInputBorderRadiusValue({ size, theme }),
    "&:hover": {
      marginRight: theme.spacing["s2"],
      marginLeft: theme.spacing["s2"],
      paddingRight: theme.spacing.s2,
      paddingLeft: theme.spacing.s2,
      backgroundColor: theme.color.gray100,
    },
    ...(!!state.isSelected && {
      marginRight: theme.spacing.s2,
      marginLeft: theme.spacing.s2,
      paddingLeft: theme.spacing.s2,
      paddingRight: getInputPaddingRightValue({ size, theme, hasIcon: true }),
      backgroundColor: `${getInputBackgroundColorValue({
        state,
        theme,
      })} !important`,
      backgroundImage: `url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7419 4.01017C12.0308 4.19907 12.1119 4.5864 11.923 4.87531L7.67301 11.3753C7.57285 11.5285 7.41059 11.6301 7.22904 11.6532C7.04749 11.6764 6.86491 11.6189 6.72949 11.4957L3.97949 8.99574C3.72408 8.76355 3.70525 8.36827 3.93744 8.11285C4.16964 7.85744 4.56492 7.83862 4.82033 8.07081L7.02794 10.0777L10.8768 4.19124C11.0657 3.90234 11.453 3.82127 11.7419 4.01017Z' fill='%231e2122'/%3e %3c/svg%3e")`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: `right ${theme.spacing.s2} center`,
      backgroundSize: theme.spacing.s4, // TODO size-based dimension
    }),
  }),
  // placeholder text style
  placeholder: (_provided: SelectProvidedStyles, state: SelectState) => ({
    ...getSelectTextStyles({ size, theme, type: "placeholder" }),
    color: getPlaceholderTextColorValue({ state, theme }),
  }),
  // main value text style
  singleValue: (_provided: SelectProvidedStyles, state: SelectState) => ({
    ...getSelectTextStyles({ size, theme, type: "value" }),
    color: getInputTextColorValue({ state: { isSelected: true }, theme }),
  }),
  // inner space in select that surrounds value
  valueContainer: (provided: SelectProvidedStyles, _state: SelectState) => ({
    ...provided,
    flexWrap: "nowrap",
    margin: 0,
    paddingTop: 0,
    paddingRight: getInputPaddingRightValue({ size, theme }),
    paddingBottom: 0,
    paddingLeft: getInputPaddingLeftValue({ size, theme }),
  }),
});

export const StyledSelectWrapper = styled("div", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledSelectWrapperProps>`
  width: 100%;
  ${space}

  /* override input div for user-entered search text */
  .nextmv-select__input input {
    ${(props) => getInputFontStyles({ size: props.size, theme: props.theme })}
  }
`;
