import { useState } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import WorkerProcessUploadFiles from "worker-loader?filename=dist/[name][hash].js!../webworkers/workerProcessUploadFiles";
// eslint-disable-next-line import/no-webpack-loader-syntax
import WorkerStoreFilesLocal from "worker-loader?filename=dist/[name][hash].js!../webworkers/workerStoreFilesLocal";

import {
  EntityErrorMessage,
  RunUploadMetadataCreateResponse,
} from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";
import { DATA_RENDER_STYLE_LIMIT_BYTES } from "../../../utils/constants";
import {
  AcceptedFiles,
  FileToWebWorker,
  FileUploadMetadata,
  OnWorkerProcessUploadFilesMessageParams,
  OnWorkerStoreFilesLocalMessageParams,
  UseFileInterceptProps,
} from "../UploadFile.types";

// process errors from web workers
const onWorkerError = (
  e: { message: any; lineno: any; filename: any },
  setError: React.Dispatch<React.SetStateAction<EntityErrorMessage>>
) => {
  if (process.env.NODE_ENV === "production") {
    return setError(`Error: ${e.message}`);
  }
  return setError(`Error: ${e.message} (Line: ${e.lineno} in ${e.filename})`);
};

// process messages from main web worker
const onWorkerProcessUploadFilesMessage = ({
  e,
  setAcceptedFiles,
  setError,
  setFileUploadMetadata,
  setIsFilesUploadProcessing,
}: OnWorkerProcessUploadFilesMessageParams) => {
  const {
    error,
    fileArray,
    fileUploadMetadata: webworkerFileUploadMetadata,
    instruction,
    localDirectory,
    processedFileArray,
  } = e.data;

  if (error && setError) {
    setError(error);
  }

  if (instruction === "02-store-files-local") {
    // set up web worker to store the files in OPFS
    const workerStoreFilesLocal = new WorkerStoreFilesLocal();
    workerStoreFilesLocal.addEventListener(
      "error",
      (e) => onWorkerError(e, setError),
      false
    );
    workerStoreFilesLocal.addEventListener(
      "message",
      (e) =>
        onWorkerStoreFilesLocalMessage({
          e,
          setError,
          setIsFilesUploadProcessing,
        }),
      false
    );

    workerStoreFilesLocal.postMessage({
      fileArray,
      localDirectory,
    });
  }

  if (
    instruction === "02b-use-shell-files" &&
    processedFileArray &&
    setAcceptedFiles
  ) {
    setAcceptedFiles((prevState) => {
      // getting duplicate files at times
      // filter out duplicates as a quick fix for now
      if (prevState && prevState.length) {
        const combinedState = [...prevState, ...processedFileArray];
        const uniqueCombinedState = combinedState.filter(
          (fileItem, index, self) => {
            return self.findIndex((v) => v.name === fileItem.name) === index;
          }
        );
        return uniqueCombinedState;
      }
      return processedFileArray;
    });
  }

  if (
    instruction === "05-return-uploaded-file-metadata" &&
    webworkerFileUploadMetadata &&
    setFileUploadMetadata
  ) {
    setFileUploadMetadata(webworkerFileUploadMetadata);
  }

  return;
};

const onWorkerStoreFilesLocalMessage = ({
  e,
  setError,
  setIsFilesUploadProcessing,
}: OnWorkerStoreFilesLocalMessageParams) => {
  const { error, hasStoredFilesToOPFS, instruction } = e.data;

  if (error && setError) {
    setError(error);
  }

  if (
    instruction === "03-files-have-processed" &&
    hasStoredFilesToOPFS &&
    setIsFilesUploadProcessing
  ) {
    setIsFilesUploadProcessing(false);
  }

  return;
};

const useFileIntercept = ({ app, setAcceptedFiles }: UseFileInterceptProps) => {
  const [{ id: accountId }] = useUser();

  const [fileInterceptError, setFileInterceptError] =
    useState<EntityErrorMessage>(null);
  const [isFilesUploadProcessing, setIsFilesUploadProcessing] =
    useState<boolean>(false);
  const [isFileUploadProcessRunning, setIsFileUploadProcessRunning] =
    useState<boolean>(false);
  const [isPrecheckRunning, setIsPrecheckRunning] = useState<boolean>(false);
  const [fileUploadMetadata, setFileUploadMetadata] =
    useState<FileUploadMetadata | null>(null);

  // set up main web worker for file handling
  const workerProcessUploadFiles = new WorkerProcessUploadFiles();
  workerProcessUploadFiles.addEventListener(
    "error",
    (e) => onWorkerError(e, setFileInterceptError),
    false
  );
  workerProcessUploadFiles.addEventListener(
    "message",
    (e) =>
      onWorkerProcessUploadFilesMessage({
        e,
        setAcceptedFiles,
        setError: setFileInterceptError,
        setFileUploadMetadata,
        setIsFilesUploadProcessing,
      }),
    false
  );

  // can be DragEvent or [FileSystemFileHandle]
  const getFilesFromEvent = async (event: any) => {
    if (event && event?.dataTransfer?.files) {
      return event.dataTransfer.files;
    }
    if (event && event?.target?.files) {
      return event.target.files;
    }
    if (typeof event === "object" && Array.isArray(event)) {
      const fileList: File[] = [];
      for (const fileHandle of event) {
        if (fileHandle instanceof File) {
          fileList.push(fileHandle);
        } else {
          const file = await fileHandle.getFile();
          fileList.push(file);
        }
      }
      return fileList;
    }
    return null;
  };

  const interceptFiles = async (event: any, localDirectory: string) => {
    const fileList = await getFilesFromEvent(event);

    if (!fileList) {
      setFileInterceptError("Error: Files could not be read from event.");
      return [];
    }

    const fileManifest: FileToWebWorker[] = [];
    const files: File[] = [];

    // flag to determine whether or not to remove data from main thread
    let isEveryFileBelowRenderThreshold = true;

    // set file upload processing
    setIsFilesUploadProcessing(true);

    // build common array
    let standardizedFileList: File[] = [];
    const isFileListFromDropEvent =
      typeof fileList === "object" && !Array.isArray(fileList);
    const isFileListFromDialog =
      typeof fileList === "object" && Array.isArray(fileList);

    if (isFileListFromDropEvent) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        standardizedFileList.push(file);
      }
    }
    if (isFileListFromDialog) {
      standardizedFileList = [...fileList];
    }

    // prepare and process files for upload and display
    if (standardizedFileList.length) {
      for (const file of standardizedFileList) {
        if (file.size > DATA_RENDER_STYLE_LIMIT_BYTES) {
          isEveryFileBelowRenderThreshold = false;
        }

        // for web worker
        fileManifest.push({
          name: file.name,
          type: file.type,
          data: await new Blob([file]).arrayBuffer(),
        });

        // to return as accepted files if all below render threshold
        files.push(file);
      }

      // if the files are below the render threshold, keep the file data
      // so that the contents can be rendered if there is a visual
      // (web worker process remains the same)
      if (isEveryFileBelowRenderThreshold) {
        workerProcessUploadFiles.postMessage({
          fileManifest,
          isEveryFileBelowRenderThreshold,
          localDirectory,
          instruction: "01-process-files-for-upload",
        });

        return files;
      }

      // second argument array required for transferrable objects
      // (removes data from main client memory)
      workerProcessUploadFiles.postMessage(
        {
          fileManifest,
          isEveryFileBelowRenderThreshold,
          localDirectory,
          instruction: "01-process-files-for-upload",
        },
        fileManifest.map((file) => file.data)
      );

      // send back "empty" (no data) shell file representation for UI
      return [];
    } else {
      return [];
    }
  };

  const uploadFilesViaWebWorker = async (
    acceptedFiles: AcceptedFiles,
    runUploadMetadata: RunUploadMetadataCreateResponse,
    localDirectory: string
  ) => {
    // initiate web worker to upload processed files
    workerProcessUploadFiles.postMessage({
      accountId,
      app,
      fileManifest: acceptedFiles,
      localDirectory,
      runUploadMetadata,
      instruction: "04-upload-file",
    });

    return;
  };

  const clearWebWorkersAndLocalOPFS = async (localDirectory: string) => {
    if (workerProcessUploadFiles) {
      workerProcessUploadFiles.terminate();
    }

    try {
      const opfsRoot = await navigator.storage.getDirectory();
      await opfsRoot.removeEntry(localDirectory, { recursive: true });
    } catch (_error) {}

    return;
  };

  return {
    clearWebWorkersAndLocalOPFS,
    fileInterceptError,
    fileUploadMetadata,
    isFileUploadProcessRunning,
    isPrecheckRunning,
    isFilesUploadProcessing,
    interceptFiles,
    setFileInterceptError,
    setIsFileUploadProcessRunning,
    setIsPrecheckRunning,
    uploadFilesViaWebWorker,
  };
};

export default useFileIntercept;
