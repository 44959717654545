import styled from "@emotion/styled";
import { space } from "styled-system";

import { styleScrollbarX } from "../../utils/constants";
import { filterProps } from "../../utils/filterProps";
import { rem } from "../../utils/tools";
import Flex from "../Flex";

import {
  getBackgroundImageXml,
  getPaddingXValue,
  getStyledTableMinWidth,
  getTableContainerMaxWidth,
} from "./utils/tableStyleHelpers";
import {
  GetTableStylesParams,
  StyledDownloadLinkContainerProps,
  StyledSearchControlProps,
  StyledSortControlProps,
  StyledTableContainerProps,
  StyledTableProps,
  TableLayoutSchema,
} from "./Table2.types";

const getSizeStyles = ({ size, theme, type }: GetTableStylesParams) => {
  switch (size) {
    case "small":
      return `
        td, th {
          padding-top: ${theme.spacing.s1};
          padding-bottom: ${theme.spacing.s1};
          padding-right: ${getPaddingXValue({ size, theme })};
          padding-left: ${getPaddingXValue({ size, theme })};
        }
        td {
          font-family: ${theme.ui2Typography.fontFamilyMeta1};
          font-size: ${theme.ui2Typography.fontSizeMeta1};
          font-style: ${theme.ui2Typography.fontStyleMeta1};
          font-weight: ${theme.ui2Typography.fontWeightMeta1};
          line-height: ${theme.ui2Typography.lineHeightMeta1};
          letter-spacing: ${theme.ui2Typography.letterSpacingMeta1};
          text-transform: ${theme.ui2Typography.textTransformMeta1};
        }
        th {
          font-family: ${theme.ui2Typography.fontFamilyMeta2Bold};
          font-size: ${theme.ui2Typography.fontSizeMeta2Bold};
          font-style: ${theme.ui2Typography.fontStyleMeta2Bold};
          font-weight: ${theme.ui2Typography.fontWeightMeta2Bold};
          line-height: ${theme.ui2Typography.lineHeightMeta2Bold};
          letter-spacing: ${theme.ui2Typography.letterSpacingMeta2Bold};
          text-transform: ${theme.ui2Typography.textTransformMeta2Bold};
        }
      `;
    default:
      return `
        td, th {
          padding-top: ${theme.spacing.s2};
          padding-bottom: ${theme.spacing.s2};
          padding-right: ${getPaddingXValue({ size, theme })};
          padding-left: ${getPaddingXValue({ size, theme })};
        }
        td {
          font-family: ${theme.ui2Typography.fontFamilyBody3};
          font-size: ${theme.ui2Typography.fontSizeBody3};
          font-style: ${theme.ui2Typography.fontStyleBody3};
          font-weight: ${theme.ui2Typography.fontWeightBody3};
          line-height: ${theme.ui2Typography.lineHeightBody3};
          letter-spacing: ${theme.ui2Typography.letterSpacingBody3};
          text-transform: ${theme.ui2Typography.textTransformBody3};
        }
        th {
          font-family: ${theme.ui2Typography.fontFamilyLabel};
          font-size: ${theme.ui2Typography.fontSizeLabel};
          font-style: ${theme.ui2Typography.fontStyleLabel};
          font-weight: ${theme.ui2Typography.fontWeightLabel};
          line-height: ${theme.ui2Typography.lineHeightLabel};
          letter-spacing: ${theme.ui2Typography.letterSpacingLabel};
          text-transform: ${theme.ui2Typography.textTransformLabel};
        }
      `;
  }
};

const renderLayoutSchemaWidthValue = (val: string | number) => {
  return typeof val === "number" ? rem(val) : val;
};

const processLayoutSchema = (
  layoutSchema: TableLayoutSchema[] | undefined,
  useMinWidth?: boolean
) => {
  if (layoutSchema) {
    const cellStyleRules = layoutSchema
      .map((ls, i) => {
        const nthChild = i + 1;
        if (ls?.width) {
          return useMinWidth
            ? `th:nth-of-type(${nthChild}), td:nth-of-type(${nthChild}) {
              min-width: ${renderLayoutSchemaWidthValue(ls.width)};
            }`
            : `th:nth-of-type(${nthChild}), td:nth-of-type(${nthChild}) {
              width: ${renderLayoutSchemaWidthValue(ls.width)};
            }`;
        }
        return "";
      })
      .join("\n");

    return `
      table-layout: fixed;

      ${cellStyleRules}
    `;
  }
  return `
    table-layout: auto;
  `;
};

export const StyledTableContainer = styled(Flex, {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledTableContainerProps>`
  width: 100%;
  max-width: ${(props) =>
    getTableContainerMaxWidth({
      isWide: props.isWide,
      layoutSchema: props.layoutSchema,
      size: props.size,
    })};
  position: relative;
  overflow-x: scroll;
  ${({ theme }) => styleScrollbarX(theme)}

  ${(props) =>
    props.isOverflowVisible &&
    `
      overflow: visible;
    `}
`;

export const StyledTable = styled("table", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledTableProps>`
  ${space}
  ${(props) =>
    getSizeStyles({ size: props.size, theme: props.theme, type: props.type })}
  
  min-width: ${(props) =>
    getStyledTableMinWidth(props.layoutSchema, props.isWide)};
  
  border-collapse: separate;

  td,
  th {
    text-align: left;
    color: ${({ theme }) => theme.color.gray700};
    border-top-width: ${({ theme }) => theme.border.width};
    border-top-style: ${({ theme }) => theme.border.style};
    border-top-color: ${({ theme }) => theme.color.gray300};
    border-left-width: ${({ theme }) => theme.border.width};
    border-left-style: ${({ theme }) => theme.border.style};
    border-left-color: ${({ theme }) => theme.color.gray300};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    &:last-of-type {
      border-right-width: 0;
      border-right-width: ${({ theme }) => theme.border.width};
      border-right-style: ${({ theme }) => theme.border.style};
      border-right-color: ${({ theme }) => theme.color.gray300};
    }
  }
  th {
    vertical-align: bottom;
    color: ${({ theme }) => theme.color.gray600};
    background-color: ${({ theme }) => theme.color.gray200};
    ${(props) => props.hasNoBorderTop && `border-top: 0;`}
  }
  td {
    background-color: ${({ theme }) => theme.color.white};
  }

  tr:last-of-type {
    td {
      border-bottom-width: ${({ theme }) => theme.border.width};
      border-bottom-style: ${({ theme }) => theme.border.style};
      border-bottom-color: ${({ theme }) => theme.color.gray300T};
    }
  }

  tr:hover {
    background-color: ${({ theme }) => theme.color.gray100};
  }

  td a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.gray700};
    border-bottom-width: ${({ theme }) => theme.border.width};
    border-bottom-style: ${({ theme }) => theme.border.style};
    border-bottom-color: transparent;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.color.orange700};
      border-bottom-color: ${({ theme }) => theme.color.gray200};
    }
  }

  ${(props) =>
    props.isStickyColumn &&
    `
      /* header column */
      td:first-of-type,
      th:first-of-type {
        position: sticky;
        left: 0;
        top: 0;
        box-shadow:
          ${rem(1)} 0 0 0 ${props.theme.color.gray300}, 
          ${rem(1.5)} 0 0 0 ${props.theme.color.gray300T}
        ;
      }
      td:first-of-type {
        background-color: ${props.theme.color.white};
      }  
    `}

  ${(props) =>
    props.type === "zebra" &&
    `
      tr:nth-of-type(2n) {
        td {
          background-color: ${props.theme.color.gray100};
        }
      }
      tr:hover {
        background-color: ${props.theme.color.gray200};
        td {
          background-color: ${props.theme.color.gray200};
        }
      }
    `}

  ${(props) =>
    props.hasBorder &&
    `
      border-width: ${props.theme.border.width};
      border-style: ${props.theme.border.style};
      border-color: ${props.theme.color.gray200};
    `}
  
  ${(props) =>
    props.plRow &&
    `
      td, th {
        padding-left: ${props.plRow};
      }
    `}

  ${(props) =>
    props.prRow &&
    `
      td, th {
        padding-right: ${props.prRow};
      }
    `}

  ${(props) =>
    props.hasNoBordersVertical &&
    `
      td, th {
        border-left: 0;
        &:last-of-type {
          border-right: 0;
        }
      }
      tr:last-of-type {
        td, th {
          border-bottom: 0;
        }
      }
    `}

  ${(props) =>
    props.layoutSchema &&
    `
      ${processLayoutSchema(props.layoutSchema, props.useMinWidth)}
    `}
  
  ${(props) =>
    props.width &&
    `
      width: ${props.width};
    `}
  }
`;

export const StyledSortControl = styled.span<StyledSortControlProps>`
  display: block;
  padding-left: ${({ theme }) => theme.spacing.s5};
  cursor: pointer;
  background-color: transparent;
  background-position: left center;
  background-repeat: no-repeat;
  background-image: ${(props) => getBackgroundImageXml(props.sortOrder)};
  background-size: ${({ theme }) => theme.spacing.s4};
`;

export const StyledSearchControl = styled.a<StyledSearchControlProps>`
  display: block;
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.s4};
  height: ${({ theme }) => theme.spacing.s4};
  margin-left: ${({ theme }) => theme.spacing.s2};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.border.radiusSmall};

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 ${({ theme }) => theme.border.widthMediumShadowOnly}
      ${({ theme }) => theme.color.gray600};
  }
`;

export const StyledDownloadLinkContainer = styled.div<StyledDownloadLinkContainerProps>`
  position: absolute;
  right: ${(props) => props.right || 0};
  top: ${(props) => props.top || props.theme.spacing["-s2"]};
  transform: translateY(-100%);
  z-index: 1000;

  ${(props) =>
    props.csvLinkLocation === "bottom" &&
    `
      margin-top: ${props.theme.spacing["-s2"]};
      margin-left: ${props.theme.spacing["-s3"]};
      order: 1;
      position: static;
      transform: none;
      z-index: 1;
    `}
`;
