import * as React from "react";
import { useTheme } from "@emotion/react";

import Box from "../../components/Box";

import {
  LoadingContainer,
  LoadingDots,
  StyledFullScreenContainer,
} from "./Loading.styles";
import { LoadingProps } from "./Loading.types";

const renderLoadingDots = (dotColor: string) => (
  <LoadingDots
    {...{ dotColor }}
    role="alert"
    aria-busy="true"
    data-testid="loading-dots"
  >
    Loading
  </LoadingDots>
);

const renderFullScreen = (dotColor: string) => (
  <StyledFullScreenContainer>
    <Box pb={11}>{renderLoadingDots(dotColor)}</Box>
  </StyledFullScreenContainer>
);

const Loading = ({ dotColor, type = "default" }: LoadingProps) => {
  const theme = useTheme();
  const thisDotColor = dotColor || theme.color.gray500;

  if (type === "no-container") return renderLoadingDots(thisDotColor);
  if (type === "full-screen") return renderFullScreen(thisDotColor);

  return <LoadingContainer>{renderLoadingDots(thisDotColor)}</LoadingContainer>;
};

export default Loading;
