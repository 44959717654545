import React from "react";
import { useTheme } from "@emotion/react";

import { rem } from "../../utils/tools";
import Box from "../Box";
import Flex from "../Flex";
import Text from "../Text";
import Tooltip from "../Tooltip";

type RowDetailProps = {
  render: React.ReactNode;
  isPropertyFaded?: boolean;
  property?: string;
  hasNoBorder?: boolean;
  secondaryLabel?: string;
  tooltipCopy?: string;
  tooltipExtraLinkLabel?: string;
  tooltipExtraLinkUrl?: string;
  testId?: string;
};

const RowDetail = ({
  isPropertyFaded,
  hasNoBorder,
  property,
  secondaryLabel,
  tooltipCopy,
  tooltipExtraLinkLabel,
  tooltipExtraLinkUrl,
  render,
  testId,
}: RowDetailProps) => {
  const theme = useTheme();

  return (
    <Flex
      hasBorderTop={hasNoBorder ? false : true}
      flexGrow={1}
      minHeight={rem(56)}
      pt={4}
      pb={4}
      flexDirection={["column", "column", "row"]}
      alignItems="flex-start"
      {...(testId && {
        "data-testid": testId,
      })}
    >
      {property && (
        <Box
          flexShrink={0}
          width={[1 / 1, 1 / 1, rem(188)]}
          mt={[1, 1, rem(2)]}
          pr={4}
        >
          <Text
            as="h3"
            styleName="label"
            styles={{
              display: "inline",
              ...(isPropertyFaded && { color: theme.color.gray500 }),
            }}
          >
            {property}
          </Text>
          {tooltipCopy && (
            <Box display="inline-block" verticalAlign="middle">
              <Tooltip
                mt={rem(-2)}
                ml={rem(3)}
                extraLinkLabel={tooltipExtraLinkLabel}
                extraLinkUrl={tooltipExtraLinkUrl}
              >
                {tooltipCopy}
              </Tooltip>
            </Box>
          )}
          {secondaryLabel && (
            <Text
              mt={1}
              styleName="meta-2"
              styles={{
                color: theme.color.gray600,
              }}
            >
              {secondaryLabel}
            </Text>
          )}
        </Box>
      )}
      <Box
        flexGrow={1}
        mt={[2, 2, 0]}
        width={["100%", "100%", `calc(100% - ${rem(188)})`]}
      >
        {typeof render === "string" ? (
          <Text
            styleName="body-2"
            {...(testId && {
              "data-testid": `${testId}-value`,
            })}
          >
            {render}
          </Text>
        ) : (
          render
        )}
      </Box>
    </Flex>
  );
};

export default RowDetail;
