import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../analytics";
import { sendInviteResponse } from "../../api/core/controlPlane";
import { useFetchAndUpdateUserAndOrgs } from "../../api/core/dataPlane.hooks";
import {
  UserOrganization,
  UserOrganizations,
} from "../../api/core/dataPlane.types";
import { UserActionKind, useUser } from "../../AuthProvider";
import ActionRow from "../../components/ActionRow";
import Header from "../../components/Header";
import Layout, { useMetaTitle } from "../../components/Layout";
import Loading from "../../components/Loading";
import StandardError from "../../components/StandardError";

import ConfirmDeclineInvite from "./components/ConfirmDeclineInvite";
import ZeroInvites from "./components/ZeroInvites";
import {
  CancelConfirmDeclineInviteParams,
  InviteActionParams,
} from "./Invites.types";

const pageTitle = "Team Invites";

const createUpdatedOrgsForAccepted = (
  orgs: UserOrganizations,
  inviteId: string
) =>
  orgs.reduce((newOrgs: UserOrganizations, current: UserOrganization) => {
    if (current.id === inviteId) {
      newOrgs.push({ ...current, pending_invite: false });
      return newOrgs;
    }
    newOrgs.push(current);
    return newOrgs;
  }, [] as UserOrganizations);

const createUpdatedOrgsForDeclined = (
  orgs: UserOrganizations,
  inviteId: string
) => orgs.filter((org) => org.id !== inviteId);

const InvitesContent = () => {
  const [user, userDispatch] = useUser();
  const history = useHistory();
  const accountId = user.id;
  const [, setMetaTitle] = useMetaTitle();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fetchAndUpdateUserAndOrgs = useFetchAndUpdateUserAndOrgs();

  const teamInvites = (user.organizations || []).filter(
    (org) => org.pending_invite
  );

  const acceptTeamInvite = async ({ e, inviteId }: InviteActionParams) => {
    e.preventDefault();

    trackEvent("General", {
      view: "Invites Page",
      action: "Invite Accepted",
    });

    setError("");
    setLoading(true);
    setConfirmDeclineInviteId("");

    const redirectToBase = teamInvites.length === 1 && !accountId;

    try {
      await sendInviteResponse(accountId || "")(inviteId, true);
      if (!accountId) {
        await fetchAndUpdateUserAndOrgs();
      } else {
        userDispatch({
          type: UserActionKind.UPDATE_ORGS,
          payload: createUpdatedOrgsForAccepted(
            user.organizations || [],
            inviteId
          ),
        });
      }

      // Redirect if there is only one invite and no account id
      if (redirectToBase) {
        // Redirect to base org dashboard
        history.push("/");
        return;
      }
    } catch (e) {
      console.error(e);
      setError((e as Error).message);
    }

    setLoading(false);
    return;
  };

  const declineTeamInvite = async ({ e, inviteId }: InviteActionParams) => {
    e.preventDefault();

    setError("");
    setLoading(true);
    setConfirmDeclineInviteId("");

    try {
      await sendInviteResponse(accountId || "")(inviteId, false);
      userDispatch({
        type: UserActionKind.UPDATE_ORGS,
        payload: createUpdatedOrgsForDeclined(
          user.organizations || [],
          inviteId
        ),
      });
      setLoading(false);
    } catch (e) {
      console.error(e);
      setError((e as Error).message);
    }

    trackEvent("General", {
      view: "Invites Page",
      action: "Invite Declined",
    });

    return;
  };

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  const [confirmDeclineInviteId, setConfirmDeclineInviteId] = useState("");

  const displayConfirmDeclineInvite = ({ e, inviteId }: InviteActionParams) => {
    e.preventDefault();
    setConfirmDeclineInviteId(inviteId);
    return;
  };
  const cancelConfirmDeclineInvite = ({
    e,
  }: CancelConfirmDeclineInviteParams) => {
    e.preventDefault();
    setConfirmDeclineInviteId("");
    return;
  };

  const renderteamInvites = () => {
    return teamInvites.map((teamInvite, index) => (
      <ActionRow
        key={teamInvite.id}
        isDisabled={
          !!confirmDeclineInviteId && confirmDeclineInviteId !== teamInvite.id
        }
        hasNoBorder={index === 0}
        title={teamInvite.name}
        primaryActionLabel="Accept invite"
        primaryActionOnClick={(e: any) =>
          acceptTeamInvite({ e, inviteId: teamInvite.id })
        }
        secondaryActionLabel="Decline invite"
        secondaryActionOnClick={(e: any) =>
          displayConfirmDeclineInvite({ e, inviteId: teamInvite.id })
        }
        confirmSecondaryAction={
          confirmDeclineInviteId && confirmDeclineInviteId === teamInvite.id ? (
            <ConfirmDeclineInvite
              invite={teamInvite}
              primaryActionLabel="Decline invite"
              primaryActionOnClick={(e: any) =>
                declineTeamInvite({ e, inviteId: teamInvite.id })
              }
              secondaryActionLabel="Cancel"
              secondaryActionOnClick={(e) => cancelConfirmDeclineInvite({ e })}
            />
          ) : undefined
        }
      />
    ));
  };

  if (error) return <StandardError errorMessage={error} />;
  if (loading)
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;

  return (
    <>
      <Header configPageTitle={{ label: pageTitle }} />

      {!teamInvites.length ? (
        <ZeroInvites accountId={accountId} />
      ) : (
        renderteamInvites()
      )}
    </>
  );
};

const Invites = () => {
  return (
    <Layout>
      <InvitesContent />
    </Layout>
  );
};

export default Invites;
