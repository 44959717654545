import styled from "@emotion/styled";

import Box from "../../components/Box";
import Flex from "../../components/Flex";
import { styleScrollbarY } from "../../utils/constants";
import { rem } from "../../utils/tools";

import { StyledMarketplaceAppImageProps } from "./MarketplaceApp.types";

export const StyledMarketplaceAppImage = styled.img<StyledMarketplaceAppImageProps>`
  display: block;
  flex-shrink: 0;
  width: 100%;
  border: 0;
  outline: none;

  ${(props) =>
    props.maxWidth &&
    `
      max-width: ${props.maxWidth};
    `}
`;

export const StyledContainer = styled(Flex)`
  border-width: ${({ theme }) => theme.border.width};
  border-style: ${({ theme }) => theme.border.style};
  border-color: ${({ theme }) => theme.color.gray200};
  border-radius: ${rem(7)};
  box-shadow: 0 ${({ theme }) => theme.border.widthMediumShadowOnly} 0 0
    ${({ theme }) => theme.color.gray200T};
`;

export const StyledMarketplaceAppSwagBox = styled(Box)`
  .swagger-ui {
    border-top: 0;
  }
`;

export const StyledNextrouteDemoAnalysisBox = styled(Box)`
  overflow-y: scroll;

  ${(props) => styleScrollbarY(props.theme)}
`;
