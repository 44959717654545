import styled from "@emotion/styled";

import Box from "../../components/Box";
import { rem } from "../../utils/tools";

import { StyledGridProps } from "./Marketplace.types";

export const StyledGrid = styled(Box)<StyledGridProps>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${({ theme }) => theme.spacing.s4};

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    grid-template-columns: repeat(2, 1fr);

    .featured-app {
      grid-column: 1 / 3;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    grid-template-columns: repeat(${(props) => props.columns || 3}, 1fr);

    .featured-app {
      grid-column: auto;
      grid-row: 1 / 3;
    }
  }
`;

export const StyledFeaturedAppImage = styled.img`
  display: block;
  width: 100%;
  max-width: ${rem(480)};
`;
