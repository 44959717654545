import React from "react";
import { useTheme } from "@emotion/react";

import Text from "../Text";

import { getBannerMessage } from "./utils/content";
import { StyledBanner } from "./Banner.styled";
import { BannerProps } from "./Banner.types";

const Banner = ({ type, user }: BannerProps) => {
  const theme = useTheme();

  const message = getBannerMessage({ type, user });

  return (
    <StyledBanner
      {...{ type }}
      alignItems="center"
      justifyContent="center"
      flexDirection={["column", "column", "row"]}
      pr={[4, 4, 0]}
      pl={[4, 4, 0]}
    >
      <Text
        styleName="body-3"
        styles={{
          color:
            type === "completed" ? theme.color.sky800 : theme.color.gray800,
        }}
      >
        {message}
      </Text>
    </StyledBanner>
  );
};

export default Banner;
