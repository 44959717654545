import * as React from "react";

import BaseAvatar, { AvatarProps } from "./BaseAvatar";

const AvatarExperimentSwitchback = ({ size }: AvatarProps) => {
  return (
    <BaseAvatar {...{ size }} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.6052 6.1548C16.3455 5.8951 15.9244 5.8951 15.6647 6.1548C15.405 6.41449 15.405 6.83555 15.6647 7.09525L16.2704 7.70089H14.9169C13.8011 7.70089 12.9651 8.29939 12.318 9.04457C11.7003 9.75582 11.1847 10.6823 10.7098 11.5355L10.6687 11.6095C10.1636 12.5166 9.69859 13.3395 9.1617 13.9435C8.63771 14.5329 8.11488 14.835 7.49996 14.835H5.49996C5.13269 14.835 4.83496 15.1328 4.83496 15.5C4.83496 15.8673 5.13269 16.165 5.49996 16.165H7.49996C8.63504 16.165 9.49058 15.5753 10.1557 14.8271C10.8079 14.0934 11.3428 13.1328 11.8307 12.2564C12.3373 11.3465 12.7962 10.5222 13.3222 9.91662C13.8367 9.32415 14.3378 9.03089 14.9169 9.03089H16.2704L15.6647 9.63653C15.405 9.89623 15.405 10.3173 15.6647 10.577C15.9244 10.8367 16.3455 10.8367 16.6052 10.577L18.3461 8.83612C18.4708 8.71141 18.5408 8.54226 18.5408 8.36589C18.5408 8.18952 18.4708 8.02038 18.3461 7.89567L16.6052 6.1548ZM10.1556 9.17196C10.2524 9.28072 10.3466 9.39447 10.4384 9.5122C10.1614 9.94533 9.91967 10.3784 9.70724 10.769C9.53112 10.5081 9.35099 10.2685 9.16194 10.056C8.63802 9.46702 8.11516 9.16504 7.50004 9.16504H5.50004C5.13277 9.16504 4.83504 8.86731 4.83504 8.50004C4.83504 8.13277 5.13277 7.83504 5.50004 7.83504H7.50004C8.63493 7.83504 9.49044 8.4242 10.1556 9.17196ZM12.0838 14.6658C12.1603 14.7641 12.2384 14.8596 12.3182 14.9514C12.9654 15.6961 13.8013 16.2941 14.917 16.2941H16.2705L15.6648 16.8998C15.4051 17.1595 15.4051 17.5805 15.6648 17.8402C15.9245 18.0999 16.3456 18.0999 16.6053 17.8402L18.3461 16.0993C18.4708 15.9746 18.5409 15.8055 18.5409 15.6291C18.5409 15.4527 18.4708 15.2836 18.3461 15.1589L16.6053 13.418C16.3456 13.1583 15.9245 13.1583 15.6648 13.418C15.4051 13.6777 15.4051 14.0988 15.6648 14.3585L16.2705 14.9641H14.917C14.3377 14.9641 13.8365 14.671 13.3221 14.079C13.1494 13.8803 12.984 13.6581 12.8217 13.417C12.6091 13.8059 12.3657 14.2368 12.0838 14.6658Z"
        fill="#33BB85"
      />
    </BaseAvatar>
  );
};

export default AvatarExperimentSwitchback;
