import React from "react";
import { useTheme } from "@emotion/react";

import { useUser } from "../../../AuthProvider";
import Button2 from "../../../components/Button2";
import Flex from "../../../components/Flex";
import Text from "../../../components/Text";
import { IconExternalLink } from "../../../icons";
import { getAccUrl } from "../../../utils/navigation";
import { userHasAccessToAction } from "../../../utils/rbac_utils";
import { ActionGroups } from "../../../utils/rbac_utils/types";
import { rem } from "../../../utils/tools";

type DashboardHeaderProps = {
  isUserPaidPremium: boolean;
  accountId?: string | null;
};

const DashboardHeader = ({
  accountId,
  isUserPaidPremium,
}: DashboardHeaderProps) => {
  const [{ roles }] = useUser();
  const theme = useTheme();

  const userCanDowloadCli = userHasAccessToAction(
    roles,
    ActionGroups.SecurityOperator,
    {}
  );
  return (
    <>
      <Flex
        width={1 / 1}
        mt={[5, 5, 4]}
        pl={[2, 2, 2]}
        flexDirection={["column", "column", "row"]}
        alignItems={["flex-start", "flex-start", "center"]}
      >
        <Text
          mt={1}
          as="h1"
          data-testid="dashboard-title"
          styleName="marketing-header-3"
          styles={{
            maxWidth: rem(800),
            color: theme.color.gray800,
          }}
        >
          Decision Ops Center
        </Text>

        <Flex ml={[0, 0, "auto"]} mt={[4, 4, 1]} pl={[0, 0, 6]} flexShrink={0}>
          <Button2
            type="outline-quiet"
            size="large"
            styles={{
              color: theme.color.gray700,
              fontWeight: 400,
              boxShadow: `inset 0 0 0 ${theme.border.widthMediumShadowOnly} ${theme.color.gray200}`,
              "&:hover, &:focus, &:active": {
                color: theme.color.gray800,
                boxShadow: `inset 0 0 0 ${theme.border.widthMediumShadowOnly} ${theme.color.gray400}`,
                svg: {
                  fill: theme.color.gray700,
                },
              },
            }}
            {...(isUserPaidPremium && {
              label: "Customer support",
              href: "http://bit.ly/nextmv-support",
              isUrlExternal: true,
              icon2: <IconExternalLink iconColor={theme.color.gray600} />,
            })}
            {...(!isUserPaidPremium && {
              label: "Explore marketplace",
              to: getAccUrl(accountId, "/marketplace"),
            })}
          />

          <Button2
            ml={2}
            type="outline-quiet"
            size="large"
            styles={{
              color: theme.color.gray700,
              fontWeight: 400,
              boxShadow: `inset 0 0 0 ${theme.border.widthMediumShadowOnly} ${theme.color.gray200}`,
              "&:hover, &:focus, &:active": {
                color: theme.color.gray800,
                boxShadow: `inset 0 0 0 ${theme.border.widthMediumShadowOnly} ${theme.color.gray400}`,
              },
            }}
            {...(isUserPaidPremium && {
              to: getAccUrl(accountId, "/team/members"),
              label: "Manage team members",
            })}
            {...(!isUserPaidPremium &&
              userCanDowloadCli && {
                to: getAccUrl(accountId, "/install"),
                label: "Install dev tools",
              })}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default DashboardHeader;
