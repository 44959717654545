import React, { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { useUser } from "../../../../AuthProvider";
import { AvatarWebhook } from "../../../../avatars";
import Box from "../../../../components/Box";
import Button2 from "../../../../components/Button2";
import Flex from "../../../../components/Flex";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import Table2 from "../../../../components/Table2";
import Tag from "../../../../components/Tag";
import Text from "../../../../components/Text";
import {
  ENDPOINT_WEBHOOKS,
  ENDPOINT_WEBHOOKS_CONVERSATIONS,
  ENDPOINT_WEBHOOKS_SECRET,
} from "../../../../config/endpoints";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import useManageWebhook from "../../../../hooks/useManageWebhook";
import useManageWebhooks from "../../../../hooks/useManageWebhooks";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { rem } from "../../../../utils/tools";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import APIKey from "../../../Team/subpages/APIKeys/components/APIKey";
import ConversationDetailsSection from "../../components/ConversationDetailsSection";
import { webhookTooltips } from "../../data/microcopy";
import { getConversationHeaders } from "../../utils/conversationsTable";

const Webhook = () => {
  const [{ id: accId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { returnPathList } = useReturnPaths();
  const { pathname } = useLocation();
  const params = useParams() as {
    id: string;
    field?: string;
  };

  const { loadWebhookEntity: loadSecret, webhookEntity: secret } =
    useManageWebhook(ENDPOINT_WEBHOOKS_SECRET);

  const {
    loadWebhookEntity: loadWebhook,
    webhookEntity: webhook,
    webhookEntityLoadError: webhookError,
  } = useManageWebhook(ENDPOINT_WEBHOOKS);

  const {
    additionalWebhookEntitiesLoading: additionalConversationsLoading,
    loadWebhookEntities: loadConversations,
    setAdditionalWebhookEntitiesLoading: setAdditionalConversationsLoading,
    setWebhookEntities: setConversations,
    webhookEntities: conversations,
    webhookEntitiesLoadError: conversationsError,
    webhookEntitiesNextPageToken: conversationsNextPageToken,
  } = useManageWebhooks(ENDPOINT_WEBHOOKS_CONVERSATIONS);

  // page display
  useEffect(() => {
    if (webhook) {
      setMetaTitle(webhook.id);
    }
  }, [webhook, setMetaTitle]);

  useEffect(() => {
    if (params.id) {
      loadWebhook(params.id);
      loadSecret(params.id);
      loadConversations({ webhookId: params.id, shouldPaginate: true });
    }
    return () => {
      setConversations(null);
    };
  }, [params.id, loadConversations, loadSecret, loadWebhook, setConversations]);

  const conversationHeaders = useMemo(() => {
    if (!webhook || !accId) {
      return [];
    }
    return getConversationHeaders({
      accId,
      webhookId: webhook.id,
      theme,
    });
  }, [accId, theme, webhook]);

  if (webhookError) {
    return <StandardError errorMessage={webhookError} />;
  }
  if (!webhook) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const loadMoreRuns = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();
    setAdditionalConversationsLoading(true);
    loadConversations({
      webhookId: params.id,
      shouldAppend: true,
      shouldPaginate: true,
      nextPageToken: conversationsNextPageToken,
    });
  };

  const canUserCreateAndEditWebhook = userHasAccessToAction(
    roles,
    ActionGroups.WebhooksOperator,
    {}
  );

  return (
    <>
      <Header
        configPageTitle={{
          ancestorIcon: <AvatarWebhook size={24} />,
          ancestorLabel: "Webhooks",
          ancestorUrl: returnPathList,
          label: webhook?.id,
        }}
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("Webhooks", {
              view: "Webhook",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditWebhook,
        }}
      />

      <RowDetail
        property="ID"
        secondaryLabel="Read-only"
        testId="webhook-row-id"
        tooltipCopy={webhookTooltips.id.content}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={webhook.id}
              contentToCopy={webhook.id}
            />
          </Flex>
        }
      />

      {webhook?.description && (
        <RowDetail
          property="Description"
          testId="webhook-row-description"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">{webhook.description}</Text>
            </Box>
          }
        />
      )}
      {webhook?.endpoint_url && (
        <RowDetail
          property="Endpoint Url"
          testId="webhook-row-endpoint-url"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">{webhook.endpoint_url}</Text>
            </Box>
          }
        />
      )}

      {!!webhook.event_types.length && (
        <RowDetail
          property="Event Types"
          testId="webhook-row-event-types"
          render={webhook.event_types.map((eventType) => (
            <Flex key={eventType}>
              <Tag ml={rem(-6)} type="id" label={eventType} />
            </Flex>
          ))}
        />
      )}

      {secret?.secret && (
        <RowDetail
          property="Secret"
          testId="webhook-row-secret"
          tooltipCopy={webhookTooltips.secret.content}
          render={
            <APIKey
              displayOptionsMenu={false}
              handleOptionsMenuState={() => {}}
              isWebhookSecret
              keyData={{
                api_key: secret.secret,
                disabled: false,
                name: "",
              }}
              setDisplayConfirmModal={() => {}}
            />
          }
        />
      )}

      <ConversationDetailsSection title="Conversations">
        {conversationsError ? (
          <StandardError errorMessage={conversationsError} />
        ) : (
          <Table2
            testId="webhook-conversations-table"
            isWide
            canFilter
            canSort
            headers={conversationHeaders}
            isStickyColumn
            mt={3}
            emptyMessage={
              !conversations ? "Loading…" : "No conversations found."
            }
            data={conversations || []}
          />
        )}
        {conversationsNextPageToken && (
          <Button2
            mt={2}
            mb={5}
            label="Load more"
            onClick={loadMoreRuns}
            isLoading={additionalConversationsLoading}
          />
        )}
      </ConversationDetailsSection>
    </>
  );
};

export default Webhook;
