import { useState } from "react";

import {
  AppEntityEndpoints,
  AppResponse,
  BatchExperimentMetadataResponse,
  RunMetadataResponse,
  ShadowTestMetadataResponse,
  SwitchbackTestMetadataResponse,
} from "../api/core/controlPlane.types";
import { RUN_STATUS_V2_QUEUED, RUN_STATUS_V2_RUNNING } from "../config/apps";
import { ENDPOINT_RUNS } from "../config/endpoints";
import { EXPERIMENT_STATUS_STARTED } from "../config/experiments";
import {
  DATA_POLLING_DEFAULT,
  DATA_POLLING_INTERVALS,
} from "../config/general";
import useRunDetails from "../contexts/apps/hooks/useRunDetails";

import useInterval from "./useInterval";
import useManageEntity from "./useManageEntity";

type PollEntityEndpoints = Pick<
  AppEntityEndpoints,
  | "experiments/acceptance"
  | "experiments/batch"
  | "experiments/shadow"
  | "experiments/switchback"
  | "runs"
>;

let intervalIndex = 0;

const getRunTypeStatus = (metadata: RunMetadataResponse) => {
  if (!metadata) return undefined;
  return metadata.metadata.status_v2;
};

const getExperimentTypeStatus = (
  metadata:
    | BatchExperimentMetadataResponse
    | ShadowTestMetadataResponse
    | SwitchbackTestMetadataResponse
) => {
  if (!metadata) return undefined;
  return metadata.status;
};

type PollingOptions = {
  hasRuns?: boolean;
  isEnsembleRun?: boolean;
  shouldLoadEntity?: boolean;
};

const usePolling = <AppEntityEndpoint extends keyof PollEntityEndpoints>(
  app: AppResponse,
  endpoint: AppEntityEndpoint,
  options: PollingOptions = {}
) => {
  const getIsValidPollStatus = (
    endpoint: AppEntityEndpoint,
    entityMetadata:
      | RunMetadataResponse
      | BatchExperimentMetadataResponse
      | ShadowTestMetadataResponse
      | SwitchbackTestMetadataResponse
      | null
  ): boolean => {
    if (!entityMetadata) return false;

    let status;

    if (endpoint === ENDPOINT_RUNS) {
      status = getRunTypeStatus(entityMetadata as RunMetadataResponse);
      return (
        status === RUN_STATUS_V2_QUEUED || status === RUN_STATUS_V2_RUNNING
      );
    }

    status = getExperimentTypeStatus(
      entityMetadata as
        | BatchExperimentMetadataResponse
        | ShadowTestMetadataResponse
        | SwitchbackTestMetadataResponse
    );
    return status === EXPERIMENT_STATUS_STARTED;
  };

  const [pollIntervalIndex, _setPollIntervalIndex] = useState(intervalIndex);
  const setPollIntervalIndex = (index: number) => {
    intervalIndex = index;
    _setPollIntervalIndex(index);
    return;
  };

  const {
    entity,
    entityAsString,
    entityMetadata,
    entityRuns,
    entityLoadError,
    entityLoadMetadataError,
    entityLoadRunsError,
    loadEntity,
    loadEntityMetadata,
    loadEntityRuns,
  } = useManageEntity(endpoint);

  const {
    loadRunEnsembleAnalysis,
    runEnsembleAnalysis,
    runEnsembleAnalysisError,
  } = useRunDetails();

  const shouldPollEntity = () => {
    const isValidEntity = !!entityMetadata && !!entityMetadata.id;
    const isValidPollStatus = getIsValidPollStatus(
      endpoint,
      entityMetadata as
        | RunMetadataResponse
        | BatchExperimentMetadataResponse
        | ShadowTestMetadataResponse
        | SwitchbackTestMetadataResponse
        | null
    );

    return isValidEntity && isValidPollStatus;
  };

  // final callback function to run at end of polling
  const finalEntityDataLoad = () => {
    if (!entityMetadata) return;
    if (options?.shouldLoadEntity === false) {
      return;
    }

    loadEntity(app.id, entityMetadata.id);

    if (options?.hasRuns) {
      loadEntityRuns(app.id, entityMetadata.id);
    }
    if (options?.isEnsembleRun) {
      loadRunEnsembleAnalysis(app.id, entityMetadata.id);
    }
  };

  const pollInterval =
    DATA_POLLING_INTERVALS[pollIntervalIndex] || DATA_POLLING_DEFAULT;

  useInterval(
    async () => {
      setPollIntervalIndex(pollIntervalIndex + 1);

      // entity will exist (not null) because of shouldPollEntity()
      await loadEntityMetadata(app.id, entityMetadata!.id);

      if (options?.hasRuns) {
        await loadEntityRuns(app.id, entityMetadata!.id);
      }
      if (options?.isEnsembleRun) {
        await loadRunEnsembleAnalysis(app.id, entityMetadata!.id);
      }
    },
    shouldPollEntity() ? pollInterval : null,
    finalEntityDataLoad
  );

  return {
    entity,
    entityAsString,
    entityMetadata,
    entityLoadError,
    entityLoadMetadataError,
    entityLoadRunsError,
    entityRuns,
    loadEntity,
    loadEntityMetadata,
    loadEntityRuns,
    loadRunEnsembleAnalysis,
    runEnsembleAnalysis,
    runEnsembleAnalysisError,
  };
};

export default usePolling;
