import React from "react";
import { useTheme } from "@emotion/react";

import Box from "../../../../components/Box";
import Button2 from "../../../../components/Button2";
import Flex from "../../../../components/Flex";
import Text from "../../../../components/Text";
import { rem } from "../../../../utils/tools";

type DeleteActionProps = {
  cancelDeleteItem: (e: { preventDefault: () => void }) => void;
  deleteItem: (e: { preventDefault: () => void }) => void;
  itemName: string;
  itemTypeName: string;
};

const DeleteAction = ({
  cancelDeleteItem,
  deleteItem,
  itemName,
  itemTypeName,
}: DeleteActionProps) => {
  const theme = useTheme();

  return (
    <Flex
      hasBorderTop
      hasBorderWidthThick
      pt={4}
      justifyContent="space-between"
    >
      <Box width="100%">
        <Text styleName="body-1-bold" styles={{ color: theme.color.gray800 }}>
          Are you sure you want to delete the {itemName} {itemTypeName}?
        </Text>
        <Text mt={1} styleName="body-2">
          This action cannot be undone.
        </Text>
        <Button2
          mt={3}
          data-testid="confirm-delete-integration"
          label={`Delete ${itemName}`}
          onClick={(e: { preventDefault: () => void }) => deleteItem(e)}
          styles={{
            backgroundColor: theme.color.red500,
            "&:hover": {
              backgroundColor: theme.color.red600,
            },
            "&:focus, &:active": {
              backgroundColor: theme.color.red700,
            },
            "&:focus-visible": {
              outlineWidth: rem(1.5),
              outlineStyle: "solid",
              outlineColor: theme.color.red900,
            },
          }}
        />
      </Box>

      <Button2
        ml={4}
        label="Cancel"
        onClick={(e: { preventDefault: () => void }) => cancelDeleteItem(e)}
        type="text"
      />
    </Flex>
  );
};

export default DeleteAction;
