import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";

import { IconCaretDown } from "../../../icons";
import { rem } from "../../../utils/tools";
import Button2 from "../Button2.component";
import { StyledMultiSelectOptions } from "../Button2.styled";
import {
  Button2Props,
  ButtonStyleType,
  CommonButtonProps,
} from "../Button2.types";
import { MULTI_SELECT_CONTROL_BUTTON_WIDTH } from "../utils/constants";
import {
  getButtonLoadingDotColor,
  getMultiSelectControlBackgroundStyles,
  getMultiSelectOptionBackgroundStyles,
} from "../utils/getButtonStyles";

export type MultiSelectButtonContentsProps = Pick<
  Button2Props,
  "isLoading" | "multiSelectOptions"
> & {
  buttonProps: CommonButtonProps;
  type: ButtonStyleType;
};

const MultiSelectButtonContents = ({
  buttonProps,
  isLoading,
  multiSelectOptions,
  type,
}: MultiSelectButtonContentsProps) => {
  const theme = useTheme();

  // exclude unneeded props
  const {
    "data-testid": testId,
    hasIcon,
    hasIcon2,
    m,
    mx,
    my,
    mt,
    mr,
    mb,
    ml,
    ...passedButtonProps
  } = buttonProps;

  const specifiedWidth = passedButtonProps?.styles?.width;

  const defaultButtonOption = multiSelectOptions && multiSelectOptions[0];
  const [isMultiSelectOptionsActive, setIsMultiSelectOptionsActive] =
    useState(false);

  const handleControlButtonOnClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!isMultiSelectOptionsActive) {
        return setIsMultiSelectOptionsActive(true);
      } else {
        return setIsMultiSelectOptionsActive(false);
      }
    },
    [isMultiSelectOptionsActive]
  );

  // close button select options with escape key
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isMultiSelectOptionsActive && e.key === "Escape") {
        handleControlButtonOnClick(e);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleControlButtonOnClick, isMultiSelectOptionsActive]);

  // click outside to close button select options
  const styledMultiSelectOptionsRef = useRef(null);
  const controlButton = useRef(null);
  useEffect(() => {
    const closeMultiSelectOptions = (e: { target: any }) => {
      if (
        isMultiSelectOptionsActive &&
        styledMultiSelectOptionsRef.current &&
        controlButton.current &&
        // @ts-ignore
        !styledMultiSelectOptionsRef.current.contains(e.target) &&
        // @ts-ignore
        !controlButton.current.contains(e.target)
      ) {
        handleControlButtonOnClick(e);
      }
    };
    document.addEventListener("mousedown", closeMultiSelectOptions);

    return () => {
      document.removeEventListener("mousedown", closeMultiSelectOptions);
    };
  }, [handleControlButtonOnClick, isMultiSelectOptionsActive]);

  if (!multiSelectOptions || !defaultButtonOption) return <></>;
  return (
    <>
      <Button2
        {...{ isLoading, type }}
        testId={defaultButtonOption.testId}
        label={defaultButtonOption.label}
        onClick={(e) => {
          setIsMultiSelectOptionsActive(false);
          return (
            defaultButtonOption?.onClick && defaultButtonOption?.onClick(e)
          );
        }}
        to={defaultButtonOption.url}
        styles={{
          // TODO: bug when width is specified on multi-select button
          //       need to fix later
          // width:
          //   specifiedWidth ||
          //   `calc(
          //     100% -
          //     ${rem(MULTI_SELECT_CONTROL_BUTTON_WIDTH)} -
          //     ${theme.spacing.s1} -
          //     ${theme.spacing.s3}
          //   )`,
          flexShrink: 0,
          paddingRight: `calc(
            ${rem(MULTI_SELECT_CONTROL_BUTTON_WIDTH)} +
            ${theme.spacing.s1}
          )`,
          textAlign: "left",
          justifyContent: "flex-start",
        }}
      />

      <Button2
        innerRef={controlButton}
        {...(testId && {
          testId: `${testId}-control`,
        })}
        type="text"
        htmlType="button"
        icon={
          <IconCaretDown
            iconSize={20}
            iconColor={getButtonLoadingDotColor({ type, theme })}
          />
        }
        onClick={(e) => handleControlButtonOnClick(e)}
        styles={{
          height: `calc(${theme.spacing.s7} - ${theme.border.widthMediumShadowOnly} * 2)`,
          position: "absolute",
          right: theme.border.widthMediumShadowOnly,
          top: theme.border.widthMediumShadowOnly,
          paddingRight: theme.spacing.s2,
          paddingLeft: theme.spacing.s2,
          zIndex: 2,
          boxShadow: "none !important",
          ...getMultiSelectControlBackgroundStyles({ theme, type }),
        }}
      />

      <StyledMultiSelectOptions
        isActive={isMultiSelectOptionsActive}
        ref={styledMultiSelectOptionsRef}
        type={type}
      >
        {multiSelectOptions &&
          multiSelectOptions.map((multiSelectOption, index) => (
            <Button2
              testId={multiSelectOption.testId}
              key={`button-option-${index}`}
              type={type}
              htmlType="button"
              label={multiSelectOption.label}
              onClick={(e) => {
                setIsMultiSelectOptionsActive(false);
                return (
                  multiSelectOption?.onClick && multiSelectOption.onClick(e)
                );
              }}
              to={multiSelectOption.url}
              styles={{
                flexShrink: 0,
                width:
                  specifiedWidth ||
                  `calc(
                    100% + 
                    ${rem(MULTI_SELECT_CONTROL_BUTTON_WIDTH)} + 
                    ${theme.border.widthMediumShadowOnly}
                  )`,
                textAlign: "left",
                justifyContent: "flex-start",
                ...getMultiSelectOptionBackgroundStyles({ theme, type }),
              }}
            />
          ))}
      </StyledMultiSelectOptions>
    </>
  );
};

export default MultiSelectButtonContents;
