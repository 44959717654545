import { parseLosslessJsonAsText } from "../../../../../utils/parseInputOutput";
import {
  nextrouteDemoInputDelivery,
  nextrouteDemoInputDeliveryAdvanced,
  nextrouteDemoInputGeneral,
  nextrouteDemoInputGeneralAdvanced,
  nextrouteDemoInputSourcing,
  nextrouteDemoInputSourcingAdvanced,
} from "../../../../MarketplaceApp/data/demoNextrouteInput";
import {
  shiftSchedulingDemoInputGeneral,
  shiftSchedulingDemoInputMultiDay,
  shiftSchedulingDemoInputMultiDayHalfShifts,
} from "../../../../MarketplaceApp/data/demoShiftSchedulingInput";
import { AMPL_FACILITY_LOCATION_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppAmplFacilityLocation";
import { AMPL_PRICE_OPTIMIZATION_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppAmplPricing";
import { HIGHS_KNAPSACK_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppHiGHSKnapsack";
import { ROUTING_LEGACY_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppLegacyRouting";
import { KNAPSACK_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppNextmvKnapsack";
import { NEXTROUTE_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppNextroute";
import { ORDER_FULFILLMENT_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppOrderFulfillment";
import { ORTOOLS_DEMAND_FORECASTING_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppORToolsDemandForecasting";
import { ORTOOLS_KNAPSACK_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppORToolsKnapsack";
import { ORTOOLS_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppORToolsShiftAssignment";
import { ORTOOLS_SHIFT_PLANNING_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppORToolsShiftPlanning";
import { ORTOOLS_SIMPLE_ROUTING_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppORToolsSimpleRouting";
import { PYOMO_KNAPSACK_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppPyomoKnapsack";
import { PYOMO_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppPyomoShiftAssignment";
import { PYOMO_SHIFT_PLANNING_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppPyomoShiftPlanning";
import { ROUTING_PYVROOM_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppRoutingPyvroom";
import { SHIFT_SCHEDULING_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppShiftScheduling";
import { sampleInputAmplFacilityLocation } from "../../../../MarketplaceApp/data/sample/inputAmplFacilityLocation";
import { sampleInputAmplPriceOptimization } from "../../../../MarketplaceApp/data/sample/inputAmplPriceOptimization";
import { sampleGoSdkShiftSchedulingLarge } from "../../../../MarketplaceApp/data/sample/inputGoSdkShiftSchedulingLarge";
import { sampleInputHiGHSKnapsack } from "../../../../MarketplaceApp/data/sample/inputHiGHSKnapsack";
import { sampleInputNextmvKnapsack } from "../../../../MarketplaceApp/data/sample/inputNextmvKnapsack";
import { sampleInputNextmvOrderFulfillment } from "../../../../MarketplaceApp/data/sample/inputNextmvOrderFulfillment";
import { sampleInputNextrouteDistributionExampleDay1 } from "../../../../MarketplaceApp/data/sample/inputNextrouteDistributionExampleDay1";
import { sampleInputNextrouteDistributionExampleDay2 } from "../../../../MarketplaceApp/data/sample/inputNextrouteDistributionExampleDay2";
import { sampleInputNextrouteDistributionExampleDay3 } from "../../../../MarketplaceApp/data/sample/inputNextrouteDistributionExampleDay3";
import { sampleInputORToolsDemandForecastingLarge } from "../../../../MarketplaceApp/data/sample/inputORToolsDemandForecastingLarge";
import { sampleInputORToolsDemandForecastingSmall } from "../../../../MarketplaceApp/data/sample/inputORToolsDemandForecastingSmall";
import { sampleInputORToolsKnapsack } from "../../../../MarketplaceApp/data/sample/inputORToolsKnapsack";
import { sampleInputORToolsRouting } from "../../../../MarketplaceApp/data/sample/inputORToolsRouting";
import { sampleInputORToolsShiftAssignment } from "../../../../MarketplaceApp/data/sample/inputORToolsShiftAssignment";
import { sampleInputORToolsShiftPlanning } from "../../../../MarketplaceApp/data/sample/inputORToolsShiftPlanning";
import { sampleInputPyomoKnapsack } from "../../../../MarketplaceApp/data/sample/inputPyomoKnapsack";
import { sampleInputPyomoShiftAssignment } from "../../../../MarketplaceApp/data/sample/inputPyomoShiftAssignment";
import { sampleInputPyomoShiftPlanning } from "../../../../MarketplaceApp/data/sample/inputPyomoShiftPlanning";
import { sampleInputRoutingLegacy } from "../../../../MarketplaceApp/data/sample/inputRoutingLegacy";
import { sampleInputRoutingPyvroom } from "../../../../MarketplaceApp/data/sample/inputRoutingPyvroom";
import { EditorOnChange } from "../hooks/useCustomInput";

type LoadSampleInputParams = {
  onInputChange: EditorOnChange;
  subscriptionId?: string;
  type?: string;
};
export const loadSampleInput = ({
  onInputChange,
  subscriptionId,
  type,
}: LoadSampleInputParams) => {
  switch (subscriptionId) {
    case NEXTROUTE_SUBSCRIPTION_ID:
      switch (type) {
        case "general":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(nextrouteDemoInputGeneral.data),
          });
        case "general-advanced":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(
              nextrouteDemoInputGeneralAdvanced.data
            ),
          });
        case "delivery":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(nextrouteDemoInputDelivery.data),
          });
        case "delivery-advanced":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(
              nextrouteDemoInputDeliveryAdvanced.data
            ),
          });
        case "sourcing":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(nextrouteDemoInputSourcing.data),
          });
        case "sourcing-advanced":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(
              nextrouteDemoInputSourcingAdvanced.data
            ),
          });
        case "distribution-day-1":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(
              sampleInputNextrouteDistributionExampleDay1
            ),
          });
        case "distribution-day-2":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(
              sampleInputNextrouteDistributionExampleDay2
            ),
          });
        case "distribution-day-3":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(
              sampleInputNextrouteDistributionExampleDay3
            ),
          });
      }
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(nextrouteDemoInputGeneral.data),
      });
    case SHIFT_SCHEDULING_SUBSCRIPTION_ID:
      switch (type) {
        case "general":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(shiftSchedulingDemoInputGeneral.data),
          });
        case "multi-day":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(
              shiftSchedulingDemoInputMultiDay.data
            ),
          });
        case "multi-day-half-shifts":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(
              shiftSchedulingDemoInputMultiDayHalfShifts.data
            ),
          });
        case "shift-scheduling-large":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(sampleGoSdkShiftSchedulingLarge),
          });
      }
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(shiftSchedulingDemoInputGeneral.data),
      });
    case ROUTING_LEGACY_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputRoutingLegacy),
      });
    case KNAPSACK_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputNextmvKnapsack),
      });
    case ORDER_FULFILLMENT_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputNextmvOrderFulfillment),
      });
    case ORTOOLS_KNAPSACK_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputORToolsKnapsack),
      });
    case ORTOOLS_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputORToolsShiftAssignment),
      });
    case ORTOOLS_SHIFT_PLANNING_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputORToolsShiftPlanning),
      });
    case ORTOOLS_SIMPLE_ROUTING_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputORToolsRouting),
      });
    case PYOMO_KNAPSACK_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputPyomoKnapsack),
      });
    case PYOMO_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputPyomoShiftAssignment),
      });
    case PYOMO_SHIFT_PLANNING_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputPyomoShiftPlanning),
      });
    case ORTOOLS_DEMAND_FORECASTING_SUBSCRIPTION_ID:
      switch (type) {
        case "demand-small":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(
              sampleInputORToolsDemandForecastingSmall
            ),
          });
        case "demand-large":
          return onInputChange({
            json: undefined,
            text: parseLosslessJsonAsText(
              sampleInputORToolsDemandForecastingLarge
            ),
          });
      }
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputORToolsDemandForecastingSmall),
      });
    case AMPL_FACILITY_LOCATION_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputAmplFacilityLocation),
      });
    case AMPL_PRICE_OPTIMIZATION_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputAmplPriceOptimization),
      });
    case HIGHS_KNAPSACK_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputHiGHSKnapsack),
      });
    case ROUTING_PYVROOM_SUBSCRIPTION_ID:
      return onInputChange({
        json: undefined,
        text: parseLosslessJsonAsText(sampleInputRoutingPyvroom),
      });
    default:
      break;
  }
};
