import React, { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { useUser } from "../../../../AuthProvider";
import Box from "../../../../components/Box";
import Flex from "../../../../components/Flex";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import Tag from "../../../../components/Tag";
import Text from "../../../../components/Text";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import useManageEntity from "../../../../hooks/useManageEntity";
import { getAccUrl } from "../../../../utils/navigation";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { rem } from "../../../../utils/tools";
import { appTooltipCopy } from "../../../Apps/data/microcopy";
import { AppPageProps } from "../../App.types";
import ExecutableDetails from "../../components/ExecutableDetails";
import ExecutionClass from "../../components/ExecutionClass";
import InstanceConfig from "../../components/InstanceConfig";
import useReturnPaths from "../../hooks/useReturnPaths";
import { getAutoInstance } from "../Instances/utils/getAutoInstances";

const InstanceDetails = ({ app }: AppPageProps) => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();
  const { returnPathList } = useReturnPaths();
  const theme = useTheme();

  const {
    entity: instance,
    entityLoadError: instanceError,
    loadEntity: loadInstance,
  } = useManageEntity("instances");

  const isAutoGenerated = params.id === "devint";
  const hasDevIntOption = !!app?.active_executable;

  // page display
  useEffect(() => {
    if (instance) {
      setMetaTitle(instance.name);
    }
  }, [setMetaTitle, instance]);

  // load instance data
  useEffect(() => {
    if (!instance && !instanceError && !isAutoGenerated) {
      loadInstance(app.id, params.id);
    }
  }, [
    app.id,
    instance,
    instanceError,
    loadInstance,
    params.id,
    hasDevIntOption,
    isAutoGenerated,
  ]);

  const instanceToRender = useMemo(
    () => (isAutoGenerated ? getAutoInstance(app) : instance),
    [app, instance, isAutoGenerated]
  );

  if (instanceError) {
    return <StandardError errorMessage={instanceError} />;
  }
  if (!instanceToRender) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const canUserEditInstance =
    userHasAccessToAction(
      roles,
      ActionGroups.AppInstanceOperator,
      instanceToRender
    ) && !isAutoGenerated;

  const canUserCreateInstances =
    userHasAccessToAction(roles, ActionGroups.AppInstanceOperator, {
      locked: false,
    }) && !isAutoGenerated;

  return (
    <>
      <Header
        configPageTitle={{
          label: instanceToRender.name,
          parentLabel: "Instances",
          parentUrl: returnPathList,
        }}
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("Instances", {
              view: "Instance Detail",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: canUserEditInstance,
        }}
        secondaryButton={[
          {
            label: "New instance",
            url: `${returnPathList}/new`,
            onClick: () =>
              trackEvent("Instances", {
                view: "Instance Detail",
                action: "New Instance Button Clicked",
              }),
            isActionAllowed: canUserCreateInstances,
          },
          {
            label: "Clone instance",
            url: `${returnPathList}/new?cloneId=${instanceToRender.id}`,
            testId: "clone-new-instance-button",
            isActionAllowed: canUserCreateInstances,
            onClick: () =>
              trackEvent("Instances", {
                view: "Instance Detail",
                action: "Clone Instance Button Clicked",
              }),
          },
        ]}
      />

      <RowDetail
        hasNoBorder
        property="Name"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text styleName="body-2">{instanceToRender.name}</Text>
          </Box>
        }
      />

      <RowDetail
        property="ID"
        tooltipCopy={appTooltipCopy.instanceId}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={instanceToRender.id}
              contentToCopy={instanceToRender.id}
            />
          </Flex>
        }
      />

      {instanceToRender.description && (
        <RowDetail
          property="Description"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">{instanceToRender.description}</Text>
            </Box>
          }
        />
      )}

      {instanceToRender.version_id && (
        <RowDetail
          property="Version ID"
          secondaryLabel="Version assigned to the instance"
          render={
            <Flex>
              <Tag
                ml={rem(-6)}
                type="id"
                label={instanceToRender.version_id}
                contentToCopy={instanceToRender.version_id}
                {...(app.type &&
                  app.type !== "subscription" && {
                    url: getAccUrl(
                      accountId,
                      `/app/${app.id}/version/${instanceToRender.version_id}`
                    ),
                  })}
              />
            </Flex>
          }
        />
      )}

      {instance?.configuration?.execution_class && (
        <RowDetail
          property="Execution class"
          secondaryLabel="Type of machine used for remote runs"
          tooltipCopy={appTooltipCopy.instanceExecutionClass.content}
          tooltipExtraLinkLabel={
            appTooltipCopy.instanceExecutionClass.docsLinkLabel
          }
          tooltipExtraLinkUrl={appTooltipCopy.instanceExecutionClass.docsLink}
          render={
            <ExecutionClass
              executionClass={instance.configuration.execution_class}
            />
          }
        />
      )}

      {instance?.configuration?.options && (
        <RowDetail
          property="Configuration Options"
          tooltipCopy={appTooltipCopy.instanceConfig.content}
          tooltipExtraLinkLabel={appTooltipCopy.instanceConfig.docsLinkLabel}
          tooltipExtraLinkUrl={appTooltipCopy.instanceConfig.docsLink}
          render={<InstanceConfig options={instance.configuration.options} />}
        />
      )}

      {isAutoGenerated && (
        <RowDetail
          property="Latest Pushed Executable"
          tooltipCopy={appTooltipCopy.appExecutable}
          render={<ExecutableDetails executable={app?.active_executable} />}
        />
      )}
    </>
  );
};

export default InstanceDetails;
