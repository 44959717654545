import React, { useEffect } from "react";

import { trackEvent } from "../../../analytics";
import { TrackEvents } from "../../../analytics/functions";
import { InputSetResponse } from "../../../api/core/controlPlane.types";
import { AvatarInputSet } from "../../../avatars";
import Box from "../../../components/Box";
import Loading from "../../../components/Loading";
import Notification from "../../../components/Notification";
import Select from "../../../components/Select";
import { useExperiments } from "../../../contexts/experiments/Experiments.context";
import { rem } from "../../../utils/tools";
import { InputSetOption } from "../Experiments.types";

type SelectInputSetProps = {
  appId: string;
  onAdd: (inputSetId: string) => void;
  pendingInputSetId: string;
  hasAvatar?: boolean;
  isDisabled?: boolean;
  setInputSetInputsCount?: (count: number) => void;
  testId?: string;
  trackEventCategory?: "Experiments" | "AcceptanceTests" | "Scenario";
  trackEventProperties?:
    | TrackEvents["Experiments"]
    | TrackEvents["AcceptanceTests"]
    | TrackEvents["Scenario"];
};

const getInputSetInputsCount = (inputSet: InputSetResponse): number => {
  if (inputSet?.input_ids.length) {
    return inputSet.input_ids.length;
  }
  if (inputSet?.inputs.length) {
    return inputSet.inputs.length;
  }
  return 0;
};

const SelectInputSet = ({
  appId,
  hasAvatar,
  isDisabled,
  onAdd,
  pendingInputSetId,
  setInputSetInputsCount,
  testId,
  trackEventCategory,
  trackEventProperties,
}: SelectInputSetProps) => {
  const { inputSets, inputSetsError, loadInputSets } = useExperiments();

  const inputSetOptions = React.useMemo(() => {
    return (
      inputSets &&
      inputSets.reduce(
        (options: InputSetOption[], inputSet: InputSetResponse) => {
          options.push({
            id: inputSet.id,
            count: getInputSetInputsCount(inputSet),
            countLabel: "inputs",
            name: inputSet.name,
            ...(hasAvatar && {
              avatar: <AvatarInputSet size={20} />,
            }),
          });
          return options;
        },
        []
      )
    );
  }, [hasAvatar, inputSets]);

  const selectedInputSetOption =
    pendingInputSetId &&
    inputSetOptions &&
    inputSetOptions.find(
      (inputSetOption) => inputSetOption.id === pendingInputSetId
    );

  useEffect(() => {
    if (!inputSets) {
      loadInputSets(appId);
    }
  }, [appId, inputSets, loadInputSets]);

  const addInputSet = (selectedInputSetOption?: InputSetOption) => {
    trackEventCategory &&
      trackEventProperties &&
      trackEvent(trackEventCategory, trackEventProperties);

    if (setInputSetInputsCount) {
      if (selectedInputSetOption) {
        setInputSetInputsCount(selectedInputSetOption.count);
      } else {
        setInputSetInputsCount(0);
      }
    }

    return selectedInputSetOption
      ? onAdd(selectedInputSetOption?.id)
      : onAdd("");
  };

  return inputSetsError ? (
    <Notification type="error" message={inputSetsError} hasContactExtra />
  ) : !inputSets ? (
    <Loading />
  ) : (
    <Box maxWidth={rem(408)}>
      <Select
        {...{ testId }}
        isDisabled={isDisabled}
        type="meta"
        placeholder="Select input set"
        noOptionsMessage={() => "No input sets"}
        options={inputSetOptions}
        getOptionValue={(selectedInputSetOption: InputSetOption) =>
          selectedInputSetOption.id
        }
        value={pendingInputSetId && selectedInputSetOption}
        isClearable
        onChange={(selection: InputSetOption) => {
          addInputSet(selection);
        }}
      />
    </Box>
  );
};
export default SelectInputSet;
