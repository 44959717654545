import React, { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { startCase } from "lodash";
import { DateTime } from "luxon";

import { trackEvent } from "../../../../analytics";
import { useUser } from "../../../../AuthProvider";
import { AvatarExperimentShadow } from "../../../../avatars";
import Box from "../../../../components/Box";
import Flex from "../../../../components/Flex";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import Tag from "../../../../components/Tag";
import Text from "../../../../components/Text";
import {
  TEST_ACTION_CANCEL,
  TEST_ACTION_COMPLETE,
  TEST_ACTION_START,
} from "../../../../config/experiments";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import useManageEntity from "../../../../hooks/useManageEntity";
import { formatDate } from "../../../../utils/format";
import { getAccUrl } from "../../../../utils/navigation";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { rem } from "../../../../utils/tools";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { DEV_INT_ID } from "../../../App/subpages/RunDetails/utils/constants";
import { Table2HeaderObj } from "../../../App/subpages/RunHistory/RunHistory.types";
import { getRunHistoryTableHeaders } from "../../../App/subpages/RunHistory/utils/getRunHistoryTableHeaders";
import { getStatisticsHeaders } from "../../../App/subpages/RunHistory/utils/getStatisticsHeaders";
import ExperimentGroupedSummaries from "../../components/ExperimentGroupedSummaries";
import ExperimentRuns from "../../components/ExperimentRuns";
import { StartStopModalType } from "../../types/Experiment.types";
import { shouldDisplayExperimentActions } from "../../utils/experimentStatus";
import {} from "../../utils/groupedSummaryTable";
import { renderActionContent } from "../../utils/renderActions";
import {
  getShadowTestEndCriteria,
  getShadowTestInstances,
  getShadowTestStartCriteria,
} from "../ShadowTests/utils/shadowTestMeta";

import StartStopShadowConfirmModal from "./components/StartStopShadowConfirmModal";
import { tooltipContentShadow } from "./data/tooltipContentShadow";
import useShadowTest from "./hooks/useShadowTest";

const ShadowTest = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const [{ id: accId, roles }] = useUser();
  const { pathname } = useLocation();
  const { returnPathList } = useReturnPaths();
  const params = useParams() as { id: string; field?: string };
  const theme = useTheme();

  const {
    displayConfirmModal,
    handleShadowTestStart,
    handleShadowTestStop,
    modalActionsError,
    setDisplayConfirmModal,
    setModalActionsError,
  } = useShadowTest(app.id, params.id);

  const {
    entity: shadowTest,
    entityLoadError: shadowTestError,
    entityLoadRunsError: shadowTestRunsError,
    entityRuns: shadowTestRuns,
    entityRunsNextPageToken: shadowTestRunsNextPageToken,
    loadEntity: loadShadowTest,
    loadEntityRuns: loadShadowTestRuns,
  } = useManageEntity("experiments/shadow");

  // page display
  useEffect(() => {
    if (shadowTest) {
      setMetaTitle(shadowTest.name);
    }
  }, [setMetaTitle, shadowTest]);

  // load shadow test and runs
  useEffect(() => {
    if (
      !shadowTest &&
      !shadowTestRuns &&
      !shadowTestError &&
      !shadowTestRunsError
    ) {
      loadShadowTest(app.id, params.id);
      loadShadowTestRuns(app.id, params.id);
    }
  }, [
    app.id,
    loadShadowTest,
    loadShadowTestRuns,
    params.id,
    shadowTest,
    shadowTestError,
    shadowTestRuns,
    shadowTestRunsError,
  ]);

  const loadMoreRuns = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    loadShadowTestRuns(
      app.id,
      shadowTest?.id,
      shadowTestRunsNextPageToken,
      true
    );
  };

  const handleStartStopClick = (actionType: StartStopModalType) => {
    trackEvent("ShadowTests", {
      view: "Shadow Test",
      action: "Action Button Clicked",
      meta: {
        type: actionType,
      },
    });

    setModalActionsError(null);
    setDisplayConfirmModal(actionType);
  };

  const handleStartStopModalActions = async (
    actionType: StartStopModalType
  ) => {
    if (!shadowTest) return;

    if (actionType === TEST_ACTION_START) {
      await handleShadowTestStart();
      await loadShadowTest(app.id, shadowTest.id);
      return;
    }

    if (
      actionType === TEST_ACTION_COMPLETE ||
      actionType === TEST_ACTION_CANCEL
    ) {
      await handleShadowTestStop(actionType);
      await loadShadowTest(app.id, shadowTest.id);
      return;
    }
  };

  const statisticsHeaders = getStatisticsHeaders(shadowTestRuns, theme);

  const runHistoryHeaders: Table2HeaderObj[] = useMemo(() => {
    if (!shadowTestRuns) {
      return [];
    }
    return getRunHistoryTableHeaders({
      accId,
      appId: app.id,
      isLinkTargetBlank: true,
      runHistory: shadowTestRuns,
      statsHeaders: statisticsHeaders,
      theme,
    });
  }, [accId, app.id, shadowTestRuns, statisticsHeaders, theme]);

  if (shadowTestError) {
    return <StandardError errorMessage={shadowTestError} />;
  }
  if (!shadowTest) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const shadowTestInstances = getShadowTestInstances(shadowTest);
  const shadowTestStartCriteria = getShadowTestStartCriteria(shadowTest);
  const shadowTestEndCriteria = getShadowTestEndCriteria(shadowTest);

  const getShadowTestEmptyResultsMessage = () => {
    if (
      shadowTest.start_events?.time &&
      shadowTest.start_events.time > (DateTime.now().toISO() || "")
    ) {
      return "Shadow test has been started, but will not begin collecting data until after the start date.";
    }
    return "Shadow test is in progress. No results yet.";
  };

  const canUserCreateAndEditShadow = userHasAccessToAction(
    roles,
    ActionGroups.ExperimentOperator,
    {}
  );

  return (
    <>
      <Header
        configPageTitle={{
          label: shadowTest.name,
          ancestorIcon: <AvatarExperimentShadow size={24} />,
          ancestorLabel: "Shadow Tests",
          ancestorUrl: returnPathList,
        }}
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("ShadowTests", {
              view: "Shadow Test",
              action: "Link Clicked",
              meta: {
                type: "Shadow Test Edit",
              },
            }),
          isActionAllowed: canUserCreateAndEditShadow,
        }}
        secondaryButton={{
          label: "New shadow test",
          url: `${returnPathList}/new`,
          isActionAllowed: canUserCreateAndEditShadow,
        }}
      />

      <RowDetail
        hasNoBorder
        property="Name"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text styleName="body-2">{shadowTest.name}</Text>
          </Box>
        }
      />

      <RowDetail
        property="Status"
        tooltipCopy={tooltipContentShadow.status.content}
        render={
          <Flex>
            <Text styleName="body-2">{startCase(shadowTest.status)}</Text>
          </Flex>
        }
      />

      <RowDetail
        property="ID"
        tooltipCopy={tooltipContentShadow.id.content}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={shadowTest?.id}
              contentToCopy={shadowTest?.id}
            />
          </Flex>
        }
      />

      {shadowTest?.description && (
        <RowDetail
          property="Description"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">{shadowTest.description}</Text>
            </Box>
          }
        />
      )}

      {shadowTestInstances?.baselineInstances && (
        <RowDetail
          property="Baseline Instance"
          tooltipCopy={tooltipContentShadow.baselineInstance.content}
          render={
            <Flex>
              <Tag
                ml={rem(-6)}
                type="id"
                label={shadowTestInstances.baselineInstances}
                contentToCopy={shadowTestInstances.baselineInstances}
                {...(shadowTestInstances.baselineInstances !== DEV_INT_ID && {
                  url: getAccUrl(
                    accId,
                    `/app/${app.id}/instance/${shadowTestInstances.baselineInstances}`
                  ),
                })}
              />
            </Flex>
          }
        />
      )}

      {shadowTestInstances?.candidateInstances && (
        <RowDetail
          property="Candidate Instance"
          tooltipCopy={tooltipContentShadow.candidateInstance.content}
          render={
            <Flex>
              <Tag
                ml={rem(-6)}
                type="id"
                label={shadowTestInstances.candidateInstances}
                contentToCopy={shadowTestInstances.candidateInstances}
                {...(shadowTestInstances.candidateInstances !== DEV_INT_ID && {
                  url: getAccUrl(
                    accId,
                    `/app/${app.id}/instance/${shadowTestInstances.candidateInstances}`
                  ),
                })}
              />
            </Flex>
          }
        />
      )}

      {shadowTestStartCriteria && (
        <RowDetail
          property="Start Criteria"
          tooltipCopy={tooltipContentShadow.startCriteria.content}
          render={
            <Flex alignItems="baseline">
              <Text styleName="body-2">{shadowTestStartCriteria.type}</Text>
              <Text
                ml={2}
                styleName="body-2-bold"
                styles={{ color: theme.color.gray800 }}
              >
                {shadowTestStartCriteria.formattedValue}
              </Text>
            </Flex>
          }
        />
      )}

      <RowDetail
        property="End Criteria"
        tooltipCopy={tooltipContentShadow.endCriteria.content}
        render={shadowTestEndCriteria.map((endCriterion, index) => (
          <Flex key={endCriterion.id} mt={index === 0 ? 0 : 1}>
            <Text styleName="body-2">{endCriterion.type}</Text>
            <Text
              ml={2}
              styleName="body-2-bold"
              styles={{ color: theme.color.gray800 }}
            >
              {endCriterion.formattedValue}
            </Text>
          </Flex>
        ))}
      />

      <RowDetail
        property="Created"
        render={
          <Text
            as="time"
            styleName="body-2"
            dateTime={shadowTest.created_at}
            title={shadowTest.created_at}
          >
            {formatDate(shadowTest.created_at, true)}
          </Text>
        }
      />

      {shadowTest.completed_at && (
        <RowDetail
          property="Completed"
          render={
            <Text
              as="time"
              styleName="body-2"
              dateTime={shadowTest.completed_at}
              title={shadowTest.completed_at}
            >
              {formatDate(shadowTest.completed_at, true)}
            </Text>
          }
        />
      )}

      {shouldDisplayExperimentActions(shadowTest.status, roles) && (
        <Box
          mt={6}
          pt={7}
          ml={[0, -6]}
          mr={[0, -7]}
          pr={[0, 7]}
          pl={[0, 6]}
          hasBorderTop
        >
          <Text
            as="h2"
            styleName="header-1"
            styles={{ color: theme.color.gray800 }}
          >
            Actions
          </Text>

          <Box mt={4}>
            {renderActionContent({
              handleStartStopClick,
              status: shadowTest.status,
            })}
          </Box>
        </Box>
      )}

      {shadowTest.status === "started" &&
        !shadowTest.grouped_distributional_summaries?.length && (
          <Box
            mt={6}
            ml={[0, -6]}
            mr={[0, -7]}
            pt={6}
            pr={[0, 7]}
            pl={[0, 6]}
            hasBorderTop
          >
            <Text
              styleName="body-2-italic"
              styles={{ color: theme.color.gray600 }}
            >
              {getShadowTestEmptyResultsMessage()}
            </Text>
          </Box>
        )}

      {!!shadowTest.grouped_distributional_summaries?.length && (
        <ExperimentGroupedSummaries kind="shadow" experiment={shadowTest} />
      )}

      {shadowTestRuns && !shadowTestRunsError && !!shadowTestRuns?.length && (
        <ExperimentRuns
          kind="shadow"
          experimentRuns={shadowTestRuns}
          runHistoryHeaders={runHistoryHeaders}
          statisticsHeaders={statisticsHeaders}
          displayLoadMore={!!shadowTestRunsNextPageToken}
          loadMoreHandler={loadMoreRuns}
        />
      )}

      {displayConfirmModal && (
        <StartStopShadowConfirmModal
          error={modalActionsError}
          handleStartStopModalActions={handleStartStopModalActions}
          hasStartCriteria={!!shadowTestStartCriteria}
          modalType={displayConfirmModal}
          setDisplayConfirmModal={setDisplayConfirmModal}
          testName={shadowTest.name}
          testBaselineInstances={shadowTestInstances?.baselineInstances}
          testCandidateInstances={shadowTestInstances?.candidateInstances}
        />
      )}
    </>
  );
};

export default ShadowTest;
