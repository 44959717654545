import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconDragHandle = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3.75C7 4.44036 6.44036 5 5.75 5C5.05964 5 4.5 4.44036 4.5 3.75C4.5 3.05964 5.05964 2.5 5.75 2.5C6.44036 2.5 7 3.05964 7 3.75ZM5.75 9.25C6.44036 9.25 7 8.69036 7 8C7 7.30964 6.44036 6.75 5.75 6.75C5.05964 6.75 4.5 7.30964 4.5 8C4.5 8.69036 5.05964 9.25 5.75 9.25ZM5.75 13.5C6.44036 13.5 7 12.9404 7 12.25C7 11.5596 6.44036 11 5.75 11C5.05964 11 4.5 11.5596 4.5 12.25C4.5 12.9404 5.05964 13.5 5.75 13.5ZM11.5 3.75C11.5 4.44036 10.9404 5 10.25 5C9.55964 5 9 4.44036 9 3.75C9 3.05964 9.55964 2.5 10.25 2.5C10.9404 2.5 11.5 3.05964 11.5 3.75ZM10.25 9.25C10.9404 9.25 11.5 8.69036 11.5 8C11.5 7.30964 10.9404 6.75 10.25 6.75C9.55964 6.75 9 7.30964 9 8C9 8.69036 9.55964 9.25 10.25 9.25ZM10.25 13.5C10.9404 13.5 11.5 12.9404 11.5 12.25C11.5 11.5596 10.9404 11 10.25 11C9.55964 11 9 11.5596 9 12.25C9 12.9404 9.55964 13.5 10.25 13.5Z"
      />
    </BaseIcon>
  );
};

export default IconDragHandle;
