import { Link } from "react-router-dom";

import { trackEvent } from "../../../../../analytics";
import {
  AppResponse,
  AppVersionsData,
} from "../../../../../api/core/controlPlane.types";
import { AvatarAppVersion } from "../../../../../avatars";
import Box from "../../../../../components/Box";
import PreviewRow2 from "../../../../../components/PreviewRow2";
import Text from "../../../../../components/Text";
import { getAccUrl } from "../../../../../utils/navigation";
import { rem } from "../../../../../utils/tools";

type RenderVersionsListParams = {
  app: AppResponse;
  canUserCreateAndEditVersions: boolean;
  versions: AppVersionsData;
  accountId?: string | null;
  isMini?: boolean;
  limit?: number;
};

export const renderVersionsList = ({
  accountId,
  app,
  canUserCreateAndEditVersions,
  isMini,
  limit,
  versions,
}: RenderVersionsListParams) => {
  if (!versions) return;

  if (!versions.length) {
    return (
      <Text
        testId="versions-not-found-message"
        mt={isMini ? 3 : 5}
        styleName={isMini ? "body-3" : "body-2"}
        hasLink
      >
        No versions found.{" "}
        {isMini && canUserCreateAndEditVersions && (
          <Link to={getAccUrl(accountId, `/app/${app.id}/versions/new`)}>
            Create new version
          </Link>
        )}
      </Text>
    );
  }

  const returnedVersions = limit ? versions.slice(0, limit) : versions;

  return returnedVersions.map((version, index) => {
    return (
      <PreviewRow2
        {...{ isMini }}
        key={version.id}
        hasNoBorder={index === 0}
        icon={
          <Box mt={rem(1)} ml={1}>
            <AvatarAppVersion size={isMini ? 20 : 24} />
          </Box>
        }
        name={version.name}
        id={version.id}
        lastUpdated={version?.updated_at}
        url={getAccUrl(accountId, `/app/${app.id}/version/${version.id}`)}
        urlOnClick={() =>
          trackEvent("Apps", {
            view: "App Versions",
            action: "Link Clicked",
            meta: {
              type: "Version Detail",
            },
          })
        }
        {...(!isMini && {
          description: version?.description,
        })}
        {...(!isMini &&
          canUserCreateAndEditVersions && {
            urlEdit: getAccUrl(
              accountId,
              `/app/${app.id}/version/${version.id}/edit?source=list`
            ),
            urlEditOnClick: () =>
              trackEvent("Apps", {
                view: "App Versions",
                action: "Link Clicked",
                meta: {
                  type: "Version Edit",
                },
              }),
          })}
      />
    );
  });
};
