import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { addOrgKey } from "../../../../api/core/controlPlane";
import { useUser } from "../../../../AuthProvider";
import Box from "../../../../components/Box";
import Button2 from "../../../../components/Button2";
import Flex from "../../../../components/Flex";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import Notification from "../../../../components/Notification";
import RowDetail from "../../../../components/RowDetail";
import { rem } from "../../../../utils/tools";

const pageTitle = "New API key";

const NewAPIKey = () => {
  const theme = useTheme();
  const [, setMetaTitle] = useMetaTitle();
  const [{ id, organizations }] = useUser();
  const team = organizations?.find((team) => team.id === id);

  const { pathname } = useLocation();
  const returnPathAPIKeysList = pathname.split("/").slice(0, -1).join("/");

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [apiKeyName, setApiKeyName] = useState("");
  const [apiKeyDesc, setApiKeyDesc] = useState("");
  const [addAPIKeyErrorMessage, setAddAPIKeyErrorMessage] = useState("");

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  const handleInputTrack = <T extends "name" | "description">({
    target: { name, value },
  }: {
    target: { name: T; value: string };
  }) => {
    if (value) {
      if (["name", "description"].includes(name)) {
        trackEvent("Team", {
          view: "New API Key",
          action: "Field Entered",
          meta: {
            field: name,
          },
        });
      }
    }
  };

  const handleAPIKeyCreate = async (
    e: { preventDefault: () => void },
    apiKeyName: string,
    apiKeyDesc: string
  ) => {
    e.preventDefault();
    setAddAPIKeyErrorMessage("");
    setLoading(true);
    try {
      await addOrgKey(team?.id || "", apiKeyName, apiKeyDesc);
      setSubmitted(true);
    } catch (e) {
      setAddAPIKeyErrorMessage((e as Error).message);
      setLoading(false);
    }
  };

  if (submitted && !addAPIKeyErrorMessage) {
    return <Redirect to={returnPathAPIKeysList} />;
  }

  if (loading) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          parentLabel: "API Keys",
          parentUrl: returnPathAPIKeysList,
        }}
      />

      <form
        onSubmit={(e: any) => handleAPIKeyCreate(e, apiKeyName, apiKeyDesc)}
      >
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                name="name"
                htmlType="text"
                maxLength="256"
                placeholder="API key name"
                data-testid="new-api-key-name-input"
                value={apiKeyName}
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => setApiKeyName(e.target.value)}
                onBlur={handleInputTrack}
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                name="description"
                htmlType="text"
                maxLength={256}
                placeholder="API key description"
                data-testid="new-api-key-description-input"
                value={apiKeyDesc}
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => setApiKeyDesc(e.target.value)}
                onBlur={handleInputTrack}
              />
            </Box>
          }
        />

        <Flex
          hasBorderTop
          hasBorderWidthThick
          pt={4}
          justifyContent="space-between"
        >
          <Box>
            {addAPIKeyErrorMessage && (
              <Notification
                data-testid="new-api-key-error-message"
                mt={1}
                mb={5}
                type="error"
                hasContactExtra
                message={`Error: ${addAPIKeyErrorMessage}`}
              />
            )}
            <Button2
              isDisabled={!apiKeyName}
              label="Create new API key"
              data-testid="new-api-key-create-button"
              size="large"
              htmlType="submit"
            />
          </Box>

          <Button2
            ml="auto"
            type="text"
            size="large"
            label="Cancel"
            data-testid="new-api-key-create-cancel"
            to={returnPathAPIKeysList}
            onClick={() =>
              trackEvent("Team", {
                view: "New API Key",
                action: "Create New API Key Canceled",
              })
            }
          />
        </Flex>
      </form>
    </>
  );
};

export default NewAPIKey;
