import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { AvatarExperimentBatch } from "../../../../avatars";
import Box from "../../../../components/Box";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import { PAYLOAD_EXPERIMENT_TYPE_BATCH } from "../../../../config/experiments";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { sharedTooltipCopy } from "../../data/microcopy";

const EditBatchExperiment = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { returnPath, returnPathList } = useReturnPaths();
  const params = useParams() as {
    id: string;
    field?: string;
  };

  const [isProcessing, setIsProcessing] = useState(false);

  const { loadBatchExperiments } = useExperiments();

  const {
    editEntity: editBatchExperiment,
    entity: batchExperiment,
    entityLoadError: batchExperimentLoadError,
    entityEditError: batchExperimentEditError,
    loadEntity: loadBatchExperiment,
    isEntityEdited: isBatchExperimentSaved,
  } = useManageEntity("experiments/batch");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "experiments/batch", true);

  // load batch experiment
  useEffect(() => {
    if (!batchExperiment && !batchExperimentLoadError) {
      loadBatchExperiment(app.id, params.id);
    }
  }, [
    app.id,
    batchExperiment,
    batchExperimentLoadError,
    loadBatchExperiment,
    params.id,
  ]);

  // page display
  useEffect(() => {
    if (batchExperiment) {
      setMetaTitle(`Edit ${batchExperiment.name}`);
    }
  }, [batchExperiment, setMetaTitle]);

  // pre-fill pending batch experiment with original values
  useEffect(() => {
    if (batchExperiment && !pendingStandardInputs.id) {
      updateStandardInputs([
        { key: "name", value: batchExperiment.name },
        { key: "id", value: batchExperiment.id },
        { key: "description", value: batchExperiment.description },
      ]);
    }
  }, [batchExperiment, pendingStandardInputs.id, updateStandardInputs]);

  // disable loading state if edit batch experiment error
  useEffect(() => {
    if (batchExperimentEditError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, batchExperimentEditError]);

  const handleBatchExperimentSave = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);

    const payload = { ...pendingStandardInputs };

    await editBatchExperiment(app.id, batchExperiment!.id, payload);
  };

  const handleCancel = () => {
    trackEvent("Experiments", {
      view: "Edit Batch Experiment",
      action: "Edit Batch Experiment Canceled",
    });
    return;
  };

  const handleBatchExperimentPostDelete = () => {
    trackEvent("Experiments", {
      view: "Edit Batch Experiment",
      action: "Batch Experiment Deleted",
    });

    loadBatchExperiments({
      applicationId: app.id,
      type: PAYLOAD_EXPERIMENT_TYPE_BATCH,
    });
  };

  if (batchExperimentLoadError) {
    return <StandardError errorMessage={batchExperimentLoadError} />;
  }
  if (!pendingStandardInputs.id || !batchExperiment) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }
  if (isBatchExperimentSaved) {
    trackEvent("Experiments", {
      view: "Edit Batch Experiment",
      action: "Batch Experiment Saved",
    });

    loadBatchExperiment(app.id, params.id);
    loadBatchExperiments({
      applicationId: app.id,
      type: PAYLOAD_EXPERIMENT_TYPE_BATCH,
    });

    return <Redirect to={returnPath} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name || !!standardInputsErrors.name;

  return (
    <>
      <Header
        configPageTitle={{
          label: `Edit ${batchExperiment.name}`,
          ancestorIcon: <AvatarExperimentBatch size={24} />,
          ancestorLabel: "Batch Experiments",
          ancestorUrl: returnPathList,
        }}
      />

      <form>
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Batch experiment name",
                  testId: "edit-batch-experiment-name-input",
                  type: "name",
                  trackEventCategory: "Experiments",
                  trackEventProperties: {
                    view: "Edit Batch Experiment",
                    action: "Field Changed",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          testId="batch-experiment-row-id"
          secondaryLabel="Read-only"
          tooltipCopy={sharedTooltipCopy.idEdit("batch experiment").content}
          render={
            <Box flexGrow={1} maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                htmlType="text"
                isDisabled
                placeholder="Batch Experiment ID"
                value={batchExperiment.id}
                readOnly
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Batch experiment description",
                  testId: "edit-batch-experiment-description-input",
                  type: "description",
                  trackEventCategory: "Experiments",
                  trackEventProperties: {
                    view: "Edit Batch Experiment",
                    action: "Field Changed",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <Footer
          app={app}
          endpoint="experiments/batch"
          entityId={batchExperiment.id}
          error={batchExperimentEditError}
          handleCancel={handleCancel}
          handleMainAction={handleBatchExperimentSave}
          handlePostDelete={handleBatchExperimentPostDelete}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="edit"
        />
      </form>
    </>
  );
};

export default EditBatchExperiment;
