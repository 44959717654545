import React, { useCallback } from "react";

import { useDebounceCallback } from "../../../hooks/useDebounce";
import Input from "../../Input";

const DebouncedInput = ({
  value: initialValue,
  htmlId,
  onChange,
  debounce = 500,
  ...props
}: {
  type: string;
  htmlId: string;
  min?: number;
  max?: number;
  placeholder: string;
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
}) => {
  const [value, setValue] = React.useState(initialValue);

  const handleChange = (e: any) => {
    onChange(e.target.value);
  };

  const [handleChangeDebounced] = useDebounceCallback(handleChange, debounce);

  const onInputChange = useCallback(
    (input: any) => {
      setValue(input.target.value);
      handleChangeDebounced(input);
    },
    [setValue, handleChangeDebounced]
  );

  return (
    <Input
      size="small"
      {...{ htmlId }}
      htmlType={props.type}
      {...props}
      value={value}
      onChange={onInputChange}
    />
  );
};

export default DebouncedInput;
