import { formatNumber } from "../../../utils/tools";
import { MonthlyUsageData } from "../Dashboard.types";

const CHAR_WIDTH = 8;
const Y_TICK_MARGIN = 8;

const getMaxUsageValue = (usageData: MonthlyUsageData[]): number => {
  return Math.max(...usageData.map((usageDatum) => usageDatum.value));
};

export const getMonthlyUsageChartYAxisWidth = (
  usageData?: MonthlyUsageData[]
): number => {
  if (!usageData) return 0;

  const maxUsageValue = getMaxUsageValue(usageData);

  const maxValueString = formatNumber(maxUsageValue);
  const maxValueCharCount = maxValueString.toString().length;
  const widthInPx = maxValueCharCount * CHAR_WIDTH + Y_TICK_MARGIN;

  return widthInPx;
};
