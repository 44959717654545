import * as React from "react";
import Select from "react-select";
import { useTheme } from "@emotion/react";

import CustomClearIndicator from "./custom/ClearIndicator";
import CustomDropdownIndicator from "./custom/DropdownIndicator";
import MenuListLoadMore from "./custom/MenuListLoadMore";
import CustomOptionApp from "./custom/OptionApp";
import CustomOptionExecutionClass from "./custom/OptionExecutionClass";
import CustomOptionIntegration from "./custom/OptionIntegration";
import CustomOptionMeta from "./custom/OptionMeta";
import CustomOptionSolution from "./custom/OptionSolution";
import CustomSingleValueApp from "./custom/SingleValueApp";
import CustomSingleValueExecutionClass from "./custom/SingleValueExecutionClass";
import CustomSingleValueIntegration from "./custom/SingleValueIntegration";
import CustomSingleValueMeta from "./custom/SingleValueMeta";
import CustomSingleValueSolution from "./custom/SingleValueSolution";
import { selectStyles, StyledSelectWrapper } from "./Select.styles";
import { SelectProps } from "./Select.types";

const SelectWrapper = ({
  defaultValue,
  expand,
  isDisabled,
  loadMore,
  options,
  orientation,
  placeholder,
  size,
  testId,
  type,
  m,
  mt,
  mr,
  mb,
  ml,
  ...rest
}: SelectProps) => {
  const theme = useTheme();

  return (
    <StyledSelectWrapper
      {...{
        m,
        mt,
        mr,
        mb,
        ml,
        size,
      }}
      {...(testId && {
        "data-testid": testId,
      })}
    >
      <Select
        {...{
          defaultValue,
          isDisabled,
          options,
          placeholder,
          loadMore,
          ...rest,
        }}
        className="nextmv-select"
        classNamePrefix="nextmv-select"
        components={{
          ClearIndicator: CustomClearIndicator,
          DropdownIndicator: CustomDropdownIndicator,
          ...(type &&
            type === "app" && {
              Option: CustomOptionApp,
              SingleValue: CustomSingleValueApp,
            }),
          ...(type &&
            type === "execution-class" && {
              Option: CustomOptionExecutionClass,
              SingleValue: CustomSingleValueExecutionClass,
            }),
          ...(type &&
            type === "solutions" && {
              Option: CustomOptionSolution,
              SingleValue: CustomSingleValueSolution,
            }),
          ...(type &&
            type === "integrations" && {
              Option: CustomOptionIntegration,
              SingleValue: CustomSingleValueIntegration,
            }),
          ...(type &&
            type === "meta" && {
              Option: CustomOptionMeta,
              SingleValue: CustomSingleValueMeta,
              MenuList: MenuListLoadMore,
            }),
        }}
        styles={selectStyles({
          expand: expand || 1,
          orientation: orientation || "down",
          size,
          theme,
        })}
      />
    </StyledSelectWrapper>
  );
};

export default SelectWrapper;
