import {
  AppEntityEndpoints,
  WebhookEntityEndpoints,
} from "../../api/core/controlPlane.types";
import {
  ENDPOINT_ENSEMBLES,
  ENDPOINT_EXPERIMENTS_ACCEPTANCE,
  ENDPOINT_EXPERIMENTS_BATCH,
  ENDPOINT_EXPERIMENTS_INPUTSETS,
  ENDPOINT_EXPERIMENTS_SHADOW,
  ENDPOINT_EXPERIMENTS_SWITCHBACK,
  ENDPOINT_INPUTS,
  ENDPOINT_INSTANCES,
  ENDPOINT_RUNS,
  ENDPOINT_VERSIONS,
  ENDPOINT_WEBHOOKS,
} from "../../config/endpoints";

export const getEntityTypeFromEndpoint = (
  entityEndpoint?: keyof AppEntityEndpoints | keyof WebhookEntityEndpoints,
  isLowerCase: boolean = false
): string => {
  let entityName: string;

  switch (entityEndpoint) {
    case ENDPOINT_ENSEMBLES:
      entityName = "Ensemble definition";
      break;
    case ENDPOINT_EXPERIMENTS_ACCEPTANCE:
      entityName = "Acceptance test";
      break;
    case ENDPOINT_EXPERIMENTS_BATCH:
      entityName = "Batch experiment";
      break;
    case ENDPOINT_EXPERIMENTS_INPUTSETS:
      entityName = "Input set";
      break;
    case ENDPOINT_EXPERIMENTS_SHADOW:
      entityName = "Shadow test";
      break;
    case ENDPOINT_EXPERIMENTS_SWITCHBACK:
      entityName = "Switchback test";
      break;
    case ENDPOINT_INPUTS:
      entityName = "Input";
      break;
    case ENDPOINT_INSTANCES:
      entityName = "Instance";
      break;
    case ENDPOINT_RUNS:
      entityName = "Run";
      break;
    case ENDPOINT_VERSIONS:
      entityName = "Version";
      break;
    case ENDPOINT_WEBHOOKS:
      entityName = "Webhook";
      break;
    default:
      entityName = "App";
  }

  return isLowerCase ? entityName.toLowerCase() : entityName;
};
