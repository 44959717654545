import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { CreateAppInstancePayload } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import AddConfigOptions from "../../../../components/AddConfigOptions";
import Box from "../../../../components/Box";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import useMarketplacePartners from "../../../../contexts/marketplace/hooks/useMarketplacePartners";
import useMarketplaceVersions from "../../../../contexts/marketplace/hooks/useMarketplaceVersions";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { rem } from "../../../../utils/tools";
import { appTooltipCopy } from "../../../Apps/data/microcopy";
import { AppPageProps } from "../../App.types";
import ExecutionClassSelect from "../../components/ExecutionClassSelect";
import VersionsAppSelect from "../../components/VersionSelect";
import useReturnPaths from "../../hooks/useReturnPaths";
import {
  checkIsPremiumExecutionClass,
  trackInstanceFieldChanges,
} from "../../utils/instances";
import useNewInstance from "../NewInstance/hooks/useNewInstance";

const EditInstanceDetails = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const [{ planInfo }] = useUser();
  const params = useParams() as { id: string };
  const theme = useTheme();
  const { returnPath, returnPathList } = useReturnPaths();

  const [isProcessing, setIsProcessing] = useState(false);

  const {
    additionalVersionsLoading,
    loadInstances,
    loadVersions,
    setAdditionalVersionsLoading,
    versions,
    versionsError,
    versionsNextPageToken,
  } = useAppCollection();
  const {
    editEntity: editInstance,
    entity: instance,
    entityLoadError: instanceLoadError,
    entityEditError: instanceEditError,
    loadEntity: loadInstance,
    isEntityEdited: isInstanceSaved,
  } = useManageEntity("instances");

  const {
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
  } = useMarketplacePartners();
  const {
    loadMarketplaceVersions,
    marketplaceVersions,
    marketplaceVersionsError,
  } = useMarketplaceVersions();

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "instances", true);

  const {
    addEmptyConfigOption,
    convertToConfigOptions,
    convertToPendingConfigOptions,
    handleConfigOptionChange,
    pendingConfigOptions,
    pendingExecutionClass,
    pendingVersionId,
    removeConfigOption,
    setPendingConfigOptions,
    setPendingExecutionClass,
    setPendingVersionId,
  } = useNewInstance();

  // load instance data
  useEffect(() => {
    if (!instance && !instanceLoadError) {
      loadInstance(app.id, params.id);
    }
  }, [app.id, instance, instanceLoadError, loadInstance, params.id]);

  // page display
  useEffect(() => {
    if (instance) {
      setMetaTitle(`Edit ${instance.name}`);
    }
  }, [instance, setMetaTitle]);

  // pre-fill pending instance with original values
  useEffect(() => {
    if (instance && !pendingStandardInputs.id) {
      updateStandardInputs([
        { key: "name", value: instance.name },
        { key: "id", value: instance.id },
        { key: "description", value: instance.description },
      ]);
      setPendingVersionId(instance.version_id);
      instance.configuration?.execution_class &&
        setPendingExecutionClass(instance.configuration.execution_class);
      instance?.configuration?.options &&
        setPendingConfigOptions(
          convertToPendingConfigOptions(instance.configuration.options)
        );
    }
  }, [
    convertToPendingConfigOptions,
    instance,
    pendingStandardInputs.id,
    setPendingConfigOptions,
    setPendingExecutionClass,
    setPendingVersionId,
    updateStandardInputs,
  ]);

  // load versions for select options
  useEffect(() => {
    if (!versions && !marketplaceVersions) {
      if (app.type === "subscription" && app.subscription_id) {
        loadMarketplaceVersions(app.subscription_id);
      } else {
        loadVersions({
          applicationId: app.id,
          shouldPaginate: true,
        });
      }
    }
  }, [
    app.id,
    app.subscription_id,
    app.type,
    loadMarketplaceVersions,
    loadVersions,
    marketplaceVersions,
    versions,
  ]);

  // load marketplace app (if applicable)
  useEffect(() => {
    if (
      !marketplacePartnerApp &&
      !marketplacePartnerAppError &&
      app?.type === "subscription" &&
      app.subscription_id
    ) {
      loadMarketplacePartnerApp(app.subscription_id);
    }
  }, [
    app.subscription_id,
    app?.type,
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
  ]);

  useEffect(() => {
    if (instanceEditError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, instanceEditError]);

  const availableExecutionClasses = planInfo?.execution_classes;
  const hasPremiumExecutionClasses =
    !!availableExecutionClasses &&
    availableExecutionClasses.some((ec) =>
      checkIsPremiumExecutionClass(ec.class)
    );

  const handleInstanceSave = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);

    const payload: CreateAppInstancePayload = {
      ...pendingStandardInputs,
      version_id: pendingVersionId,
      configuration: {
        options: convertToConfigOptions(pendingConfigOptions),
        execution_class: pendingExecutionClass,
      },
      application_id: app.id,
    };

    trackInstanceFieldChanges(instance!, payload);

    await editInstance(app.id, instance!.id, payload);
  };

  const handleCancel = () => {
    trackEvent("Instances", {
      view: "Edit Instance",
      action: "Edit Instance Canceled",
    });
    return;
  };

  const handleInstancePostDelete = () => {
    trackEvent("Instances", {
      view: "Edit Instance",
      action: "Instance Deleted",
    });

    loadInstances({ applicationId: app.id, shouldPaginate: true });
  };

  const loadMoreVersions = (
    e: {
      preventDefault: () => void;
      stopPropagation: () => void;
    },
    versionsNextPageToken: string
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setAdditionalVersionsLoading(true);
    loadVersions({
      applicationId: app.id,
      nextPageToken: versionsNextPageToken,
      shouldAppend: true,
      shouldPaginate: true,
    });
  };

  const editInstanceLoadError = instanceLoadError || versionsError;
  const editInstanceLoading =
    !instance ||
    (!versions && !marketplaceVersions) ||
    !pendingStandardInputs.id;

  if (editInstanceLoadError) {
    return <StandardError errorMessage={editInstanceLoadError} />;
  }
  if (editInstanceLoading) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }
  if (isInstanceSaved) {
    trackEvent("Instances", {
      view: "Edit Instance",
      action: "Edit Instance Saved",
    });

    loadInstances({ applicationId: app.id, shouldPaginate: true });
    loadInstance(app.id, params.id);

    return <Redirect to={returnPath} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name ||
    !pendingVersionId ||
    !!standardInputsErrors.name;

  return (
    <>
      <Header
        configPageTitle={{
          label: `Edit ${instance.name}`,
          parentLabel: "Instances",
          parentUrl: returnPathList,
        }}
      />

      <form>
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Instance name",
                  testId: "edit-instance-name-input",
                  type: "name",
                  trackEventCategory: "Instances",
                  trackEventProperties: {
                    view: "Edit Instance",
                    action: "Field Changed",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          secondaryLabel="Read-only"
          tooltipCopy={appTooltipCopy.instanceIdEdit}
          render={
            <Box flexGrow={1} maxWidth={rem(408)}>
              <Input
                htmlType="text"
                isDisabled
                placeholder="Instance ID"
                readOnly
                value={instance.id}
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Instance description",
                  testId: "edit-instance-description-input",
                  type: "description",
                  trackEventCategory: "Instances",
                  trackEventProperties: {
                    view: "Edit Instance",
                    action: "Field Changed",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="Version"
          secondaryLabel="Select instance version"
          render={
            <VersionsAppSelect
              appType={app?.type}
              appId={app.id}
              versionsNextPageToken={
                !additionalVersionsLoading ? versionsNextPageToken : ""
              }
              {...{
                loadMoreVersions,
                marketplacePartnerApp,
                marketplacePartnerAppError,
                marketplaceVersions,
                marketplaceVersionsError,
                pendingVersionId,
                setPendingVersionId,
                versions,
                versionsError,
              }}
            />
          }
        />

        {hasPremiumExecutionClasses && (
          <RowDetail
            property="Execution class"
            secondaryLabel="Select execution class to use for this instance."
            tooltipCopy={appTooltipCopy.instanceExecutionClassCreate.content}
            render={
              <ExecutionClassSelect
                {...{
                  availableExecutionClasses,
                  pendingExecutionClass,
                  setPendingExecutionClass,
                }}
                trackEventCategory="Instances"
                trackEventProperties={{
                  view: "Create Instance",
                  action: "Execution Class Changed",
                }}
              />
            }
          />
        )}

        <RowDetail
          property="Configuration Options"
          secondaryLabel="(optional)"
          tooltipCopy={appTooltipCopy.instanceConfig.content}
          tooltipExtraLinkLabel={appTooltipCopy.instanceConfig.docsLinkLabel}
          tooltipExtraLinkUrl={appTooltipCopy.instanceConfig.docsLink}
          render={
            <AddConfigOptions
              {...{
                addEmptyConfigOption,
                handleConfigOptionChange,
                pendingConfigOptions,
                removeConfigOption,
              }}
            />
          }
        />

        <Footer
          app={app}
          endpoint="instances"
          entityId={instance.id}
          error={instanceEditError}
          handleCancel={handleCancel}
          handleMainAction={handleInstanceSave}
          handlePostDelete={handleInstancePostDelete}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="edit"
        />
      </form>
    </>
  );
};

export default EditInstanceDetails;
