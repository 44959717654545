import { PageNavigationItem } from "../../../components/MainNav/MainNav.types";
import { AppNavSection } from "../../../contexts/apps/App.context.types";
import { getAccUrl } from "../../../utils/navigation";
import { AppPagesProps } from "../App.types";

export const appNavSections: AppNavSection[] = ["app-experiments", "app-runs"];
export const getAppPages = ({
  appId,
  appType,
  accountId,
  canUserRun,
  features,
}: AppPagesProps): PageNavigationItem[] => {
  return [
    {
      id: "app-details",
      label: "Details",
      url: getAccUrl(accountId, `/app/${appId}`),
    },
    {
      id: "app-runs",
      label: "Runs",
      url: getAccUrl(accountId, `/app/${appId}/runs`),
      pages: [
        {
          id: "app-runs-all",
          label: "All",
          url: getAccUrl(accountId, `/app/${appId}/runs`),
        },
        {
          id: "app-runs-queue",
          label: "Queue",
          url: getAccUrl(accountId, `/app/${appId}/runs/queue`),
        },
        {
          id: "app-runs-ensemble-definitions",
          label: "Ensemble Definitions",
          url: getAccUrl(accountId, `/app/${appId}/runs/ensembles`),
        },
      ],
    },
    {
      id: "app-instances",
      label: "Instances",
      url: getAccUrl(accountId, `/app/${appId}/instances`),
    },
    ...(appType === "custom"
      ? [
          {
            id: "app-versions",
            label: "Versions",
            url: getAccUrl(accountId, `/app/${appId}/versions`),
          },
        ]
      : []),
    {
      id: "app-experiments",
      label: "Experiments",
      url: getAccUrl(accountId, `/app/${appId}/experiments`),
      pages: [
        {
          id: "app-experiments-scenario",
          label: "Scenario",
          url: getAccUrl(accountId, `/app/${appId}/experiments/scenario`),
        },
        {
          id: "app-experiments-batch",
          label: "Batch",
          url: getAccUrl(accountId, `/app/${appId}/experiments/batch`),
        },
        {
          id: "app-experiments-acceptance",
          label: "Acceptance",
          url: getAccUrl(accountId, `/app/${appId}/experiments/acceptance`),
        },
        {
          id: "app-experiments-shadow",
          label: "Shadow",
          url: getAccUrl(accountId, `/app/${appId}/experiments/shadow`),
        },
        {
          id: "app-experiments-switchback",
          label: "Switchback",
          url: getAccUrl(accountId, `/app/${appId}/experiments/switchback`),
        },
        {
          id: "app-inputs",
          label: "Inputs",
          url: getAccUrl(accountId, `/app/${appId}/experiments/inputs`),
        },
        {
          id: "app-input-sets",
          label: "Input Sets",
          url: getAccUrl(accountId, `/app/${appId}/experiments/input-sets`),
        },
      ],
    },
    // LEAVING FOR LATER REF
    // {
    //   id: "api-reference",
    //   label: "API Reference",
    //   url: getAccUrl(accountId, `/app/${appId}/api-reference`),
    // },
    // {
    //   id: "settings",
    //   label: "Settings",
    //   url: getAccUrl(accountId, `/app/${appId}/settings`),
    // },
    ...(canUserRun
      ? [
          {
            id: "run-app",
            label: "Run app",
            url: getAccUrl(accountId, `/app/${appId}/runs/new`),
          },
        ]
      : []),
  ] as PageNavigationItem[];
};
