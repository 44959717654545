import styled from "@emotion/styled";

import Box from "../../components/Box";
import Flex from "../../components/Flex";
import { styleHideVisually, styleScrollbarY } from "../../utils/constants";
import { rem } from "../../utils/tools";

type StyledUserQuestionsPageProps = {
  isActive: boolean;
};
type StyledModalContentBoxProps = {
  isSubmitted: boolean;
};

export const StyledUserQuestionsPage = styled(
  Box
)<StyledUserQuestionsPageProps>`
  ${(props) => !props.isActive && styleHideVisually}
`;

export const StyledModalContentBox = styled(Box)<StyledModalContentBoxProps>`
  position: relative;

  ${(props) =>
    !props.isSubmitted &&
    `
      overflow-y: scroll;
      ${styleScrollbarY(props.theme)}
    `}
  ${(props) =>
    props.isSubmitted &&
    `
      overflow: hidden;
    `}
`;

export const StyledProcessingOverlayFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: ${({ theme }) => theme.spacing.s9};
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  backdrop-filter: blur(${rem(1)});
`;
