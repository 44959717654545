import { ValidJSON } from "../../../utils/parseInputOutput";
import { ConfigInput, Coords, MarkerPoint } from "../Map.types";

import getInputVehiclePoints from "./getInputVehiclePoints";

export function getMapMarkerPoints(input: ConfigInput | ValidJSON | undefined) {
  if (
    !input ||
    typeof input !== "object" ||
    Array.isArray(input) ||
    !input?.stops
  ) {
    return [];
  }

  const configInput = input as ConfigInput;
  const points: MarkerPoint[] =
    configInput && configInput.stops?.length
      ? configInput.stops.reduce((locations, l) => {
          if (l.position && l.position.lat && l.position.lon) {
            locations.push({
              position: [l.position.lat, l.position.lon] as Coords,
              name: l.id,
            });
          }
          if (l.location && l.location.lat && l.location.lon) {
            locations.push({
              position: [l.location.lat, l.location.lon] as Coords,
              name: l.id,
            });
          }
          return locations;
        }, [] as MarkerPoint[])
      : ([] as MarkerPoint[]);

  return configInput
    ? points.concat(getInputVehiclePoints(configInput))
    : points;
}
