import styled from "@emotion/styled";

import { rem } from "../../utils/tools";

import { StyledMiniModalActionLinkProps } from "./Team.types";

export const StyledMiniModal = styled.ul`
  box-sizing: border-box;
  width: ${rem(120)};
  padding-top: ${({ theme }) => theme.spacing.s2};
  padding-right: ${({ theme }) => theme.spacing.s4};
  padding-bottom: ${({ theme }) => theme.spacing.s2};
  padding-left: ${({ theme }) => theme.spacing.s4};
  position: absolute;
  right: ${({ theme }) => theme.spacing.s7};
  top: ${({ theme }) => theme.spacing.s1};

  list-style: none;
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 0 0 ${rem(1)} ${({ theme }) => theme.color.gray200T},
    0 ${rem(2)} ${rem(29)} ${rem(-4)} ${({ theme }) => theme.color.gray200T},
    0 ${rem(2)} ${rem(11)} ${rem(-4)} ${({ theme }) => theme.color.gray300T};
`;

export const StyledMiniModalActionItem = styled.li`
  + li {
    margin-top: ${({ theme }) => theme.spacing.s1};
  }
`;

export const StyledMiniModalActionLink = styled.a<StyledMiniModalActionLinkProps>`
  display: block;
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody3};
  font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody3};
  font-style: ${({ theme }) => theme.ui2Typography.fontStyleBody3};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody3};
  line-height: ${({ theme }) => theme.ui2Typography.lineHeightBody3};
  letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingBody3};
  text-transform: ${({ theme }) => theme.ui2Typography.textTransformBody3};
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: ${({ theme }) => theme.color.gray700};

  &:hover,
  &:focus,
  &:active {
    span {
      color: ${(props) => props.textColorHover || props.theme.color.gray900};
    }
  }
`;
