import React from "react";
import { useTheme } from "@emotion/react";

import Box from "../../../components/Box";
import Loading from "../../../components/Loading";

const LoadingListView = () => {
  const theme = useTheme();

  return (
    <Box mt={5}>
      <Loading dotColor={theme.color.orange500} />
    </Box>
  );
};

export default LoadingListView;
