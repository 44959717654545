import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { space } from "styled-system";

import { filterProps } from "../../utils/filterProps";
import { renderExtraStyles } from "../../utils/renderExtraStyles";
import { rem } from "../../utils/tools";

import {
  getInputBackgroundColorValue,
  getInputBorderColorValue,
  getInputTextColorValue,
  getPlaceholderTextColorValue,
} from "./utils/getInputColorStyles";
import {
  getInputPaddingLeftStyle,
  getInputPaddingRightStyle,
} from "./utils/getInputPadding";
import {
  getInputHeightValue,
  getInputSizeStyle,
} from "./utils/getInputSizeStyles";
import {
  InputContainerProps,
  PlaceIconProps,
  StyledInputProps,
} from "./Input.types";

export const inputSharedStyles = (props: any) => css`
  width: 100%;
  box-sizing: border-box;
  display: block;
  color: ${getInputTextColorValue({ theme: props.theme })};
  border-width: ${props.theme.border.width};
  border-style: ${props.theme.border.style};
  border-color: ${getInputBorderColorValue({ theme: props.theme })};
  background-color: ${getInputBackgroundColorValue({ theme: props.theme })};
  outline: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  &:focus-visible {
    border-color: ${getInputBorderColorValue({
      state: { isFocused: true },
      theme: props.theme,
    })};
    box-shadow: 0 0 0 ${rem(0.5)}
      ${getInputBorderColorValue({
        state: { isFocused: true },
        theme: props.theme,
      })};
  }
  &:active,
  &:focus {
    border-color: ${getInputBorderColorValue({
      state: { isFocused: true },
      theme: props.theme,
    })};
    outline: none;
  }
  &::-webkit-placeholder,
  &::placeholder {
    color: ${getPlaceholderTextColorValue({ theme: props.theme })};
  }

  &:disabled,
  &:disabled:focus {
    cursor: not-allowed;
    color: ${getInputTextColorValue({
      state: { isDisabled: true },
      theme: props.theme,
    })};
    border-color: ${getInputBorderColorValue({
      state: { isDisabled: true },
      theme: props.theme,
    })};
    background-color: ${getInputBackgroundColorValue({
      state: { isDisabled: true },
      theme: props.theme,
    })};
    box-shadow: none;
  }
  &:disabled::placeholder {
    color: ${getPlaceholderTextColorValue({
      state: { isDisabled: true },
      theme: props.theme,
    })};
  }
`;

const inputErrorStyles = (props: any) => css`
  color: ${getInputTextColorValue({
    state: { isError: true },
    theme: props.theme,
  })};
  border-color: ${getInputBorderColorValue({
    state: { isError: true },
    theme: props.theme,
  })};
  &:focus-visible {
    border-color: ${getInputBorderColorValue({
      state: { isError: true, isFocused: true },
      theme: props.theme,
    })};
    box-shadow: 0 0 0 ${rem(0.5)}
      ${getInputBorderColorValue({
        state: { isError: true, isFocused: true },
        theme: props.theme,
      })};
  }
  &:active,
  &:focus {
    border-color: ${getInputBorderColorValue({
      state: { isError: true, isFocused: true },
      theme: props.theme,
    })};
  }
  &::-webkit-placeholder,
  &::placeholder {
    color: ${getPlaceholderTextColorValue({
      state: { isError: true },
      theme: props.theme,
    })};
  }
`;

export const StyledInput = styled("input", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledInputProps>`
  ${inputSharedStyles}
  ${(props) => getInputSizeStyle({ size: props.inputSize, theme: props.theme })}
  ${(props) =>
    getInputPaddingLeftStyle({
      hasIcon: props.hasIcon && props.iconPosition === "left",
      size: props.inputSize,
      theme: props.theme,
    })}
  ${(props) =>
    getInputPaddingRightStyle({
      hasIcon: props.hasIcon && props.iconPosition === "right",
      size: props.inputSize,
      theme: props.theme,
    })}
  
  ${(props) => props.isError && inputErrorStyles}

  ${(props) => props.styles && renderExtraStyles({ styles: props.styles })}
`;

export const StyledTextArea = styled("textarea", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledInputProps>`
  ${inputSharedStyles}
  ${(props) => getInputSizeStyle({ size: props.inputSize, theme: props.theme })}
  ${(props) => props.isError && inputErrorStyles}
  ${(props) => props.styles && renderExtraStyles({ styles: props.styles })}

  padding-top: ${({ theme }) => theme.spacing.s3};
  padding-left: ${({ theme }) => theme.spacing.s3};
  padding-right: ${({ theme }) => theme.spacing.s7};
  resize: none;
  height: ${rem(200)};
`;

export const InputContainer = styled("div", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<InputContainerProps>`
  width: 100%;
  position: relative;
  ${space}
`;

export const PlaceIcon = styled("div", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<PlaceIconProps>`
  display: flex;
  align-items: center;
  height: ${(props) =>
    getInputHeightValue({ size: props.size, theme: props.theme })};
  position: absolute;
  right: ${(props) =>
    props.iconPosition === "right" ? props.theme.spacing.s2 : "auto"};
  left: ${(props) =>
    props.iconPosition === "left" ? props.theme.spacing.s2 : "auto"};
  top: 0;
  z-index: 1;
`;
