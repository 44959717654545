import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { useUser } from "../../../../AuthProvider";
import Box from "../../../../components/Box";
import Button2 from "../../../../components/Button2";
import Flex from "../../../../components/Flex";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PreviewItem from "../../../../components/PreviewItem";
import { StyledPreviewItemFlex } from "../../../../components/PreviewItem/PreviewItem.styled";
import Text from "../../../../components/Text";
import { rem } from "../../../../utils/tools";
import { getAvailableIntegrations } from "../../data/availableIntegrations";

const pageTitle = "Integrations";

const AvailableIntegrations = () => {
  const [{ id: accId }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  const availableIntegrations = getAvailableIntegrations(accId);

  return (
    <>
      <Header configPageTitle={{ label: pageTitle }} />

      <Flex flexDirection="column" minHeight={`calc(100% - ${rem(62)})`}>
        <Flex
          width="100%"
          pt={2}
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="stretch"
        >
          {availableIntegrations.map((integration) => (
            <StyledPreviewItemFlex
              key={integration.id}
              width={[
                "100%",
                "100%",
                `calc((100% - ${theme.spacing.s4}) / 2)`,
                `calc((100% - ${theme.spacing.s4}) / 2)`,
                `calc((100% - ${theme.spacing.s4} * 2) / 3)`,
              ]}
              mt={4}
            >
              <PreviewItem
                avatar={
                  integration?.avatar ||
                  (integration?.avatarUrl && (
                    <img
                      src={integration.avatarUrl}
                      width={48}
                      alt={`${integration.name} logo`}
                    />
                  ))
                }
                name={integration.name}
                description={integration.description}
                tags={integration?.tags}
                renderExtra={
                  integration.moreLabel &&
                  integration.moreUrl &&
                  integration.moreTrackEvent && (
                    <Flex>
                      <Button2
                        type="outline-quiet"
                        label={integration.moreLabel}
                        href={integration.moreUrl}
                        onClick={() =>
                          trackEvent("Marketplace", integration.moreTrackEvent)
                        }
                        {...(integration?.isMoreUrlExternal && {
                          href: integration.moreUrl,
                        })}
                        {...(!integration?.isMoreUrlExternal && {
                          to: integration.moreUrl,
                        })}
                        isUrlExternal={!!integration?.isMoreUrlExternal}
                      />
                    </Flex>
                  )
                }
              />
            </StyledPreviewItemFlex>
          ))}
        </Flex>

        <Box mt="auto" pt={8} pb={6}>
          <Text
            as="h3"
            styleName="body-1-bold"
            styles={{ color: theme.color.gray800 }}
          >
            Looking for something else?
          </Text>
          <Text mt={rem(2)} styleName="body-2" hasLink>
            We are continuously adding new integrations. <br />
            <a
              href="https://www.nextmv.io/contact"
              target="_blank"
              rel="noreferrer"
            >
              Let us know
            </a>{" "}
            what you need.
          </Text>
        </Box>
      </Flex>
    </>
  );
};

export default AvailableIntegrations;
