import { DateTime } from "luxon";

import {
  AppInstanceResponse,
  AppResponse,
} from "../../../api/core/controlPlane.types";
import useManageEntity from "../../../hooks/useManageEntity";
import { LATEST_INSTANCE_ID } from "../../../utils/constants";
import { getSafeNameAndIdForEntity } from "../../../utils/entities/getSafeNameIdForEntity";
import { maskUserEmail } from "../../../utils/user";

const useUpdateBinary = () => {
  const {
    addEntity: addVersion,
    isEntityAdded: isVersionAdded,
    loadEntity: loadVersion,
  } = useManageEntity("versions");

  const {
    addEntity: addInstance,
    editEntity: editInstance,
    isEntityAdded: isInstanceAdded,
    isEntityEdited: isInstanceEdited,
    loadEntity: loadInstance,
  } = useManageEntity("instances");

  const createLatestInstance = async ({
    app,
    versionId,
  }: {
    app: AppResponse;
    versionId: string;
  }) => {
    const instancePayload = {
      application_id: app.id,
      id: LATEST_INSTANCE_ID,
      name: "Latest",
      version_id: versionId,
    };
    await addInstance(app.id, instancePayload);
  };

  const createLatestVersion = async ({ app }: { app: AppResponse }) => {
    if (!app.active_executable) {
      return;
    }

    const formattedUploadDate = DateTime.fromISO(
      app.active_executable.uploaded_at
    ).toFormat("yyyy-MM-dd - h:mm:ss a ZZZZ");

    const standardPayload = getSafeNameAndIdForEntity(
      "ver",
      `${maskUserEmail(app.active_executable.user_email)}-${
        app.active_executable.uploaded_at
      }`
    );
    const versionPayload = {
      ...standardPayload,
      description: `Binary uploaded at ${formattedUploadDate}`,
    };
    const newVersion = await addVersion(app.id, versionPayload, true);
    return newVersion;
  };

  const updateLatestInstance = async ({
    appId,
    existingLatestInstance,
    newVersionId,
  }: {
    appId: string;
    existingLatestInstance: AppInstanceResponse;
    newVersionId: string;
  }) => {
    await editInstance(appId, existingLatestInstance.id, {
      ...existingLatestInstance,
      version_id: newVersionId,
    });
  };

  const getLatestInstance = async ({ app }: { app: AppResponse }) => {
    const existingLatestInstance = await loadInstance(
      app.id,
      LATEST_INSTANCE_ID,
      true
    );
    return existingLatestInstance;
  };

  const getLatestVersion = async ({
    app,
    instance,
  }: {
    app: AppResponse;
    instance: AppInstanceResponse;
  }) => {
    const versionId = instance.version_id;
    const currentLatestVersion = await loadVersion(app.id, versionId, true);
    return currentLatestVersion;
  };

  // check to see if latest instance exists, and if so if its
  // current version matches the app’s most recently uploaded
  // executable; if not, create the latest instance/version
  const updateLatestInstanceAndGetVersionId = async ({
    app,
  }: {
    app: AppResponse;
  }) => {
    let versionId = "";
    const appExecutable = app.active_executable;
    if (!appExecutable) {
      return "";
    }
    const existingLatestInstance = await getLatestInstance({ app });

    if (existingLatestInstance) {
      const latestVersion = await getLatestVersion({
        app,
        instance: existingLatestInstance,
      });

      if (!latestVersion || !latestVersion.executable) {
        return "";
      }
      versionId = latestVersion.id;

      const isVersionLatestBinary =
        appExecutable.uploaded_at === latestVersion.executable.uploaded_at &&
        appExecutable.user_email === latestVersion.executable.user_email;

      if (!isVersionLatestBinary) {
        const newVersion = await createLatestVersion({ app });
        if (newVersion) {
          versionId = newVersion.id;
          await updateLatestInstance({
            appId: app.id,
            existingLatestInstance,
            newVersionId: versionId,
          });
        } else {
          return "";
        }
      }
    } else {
      const newVersion = await createLatestVersion({ app });
      if (newVersion) {
        versionId = newVersion.id;
        await createLatestInstance({ app, versionId: versionId });
      } else {
        return "";
      }
    }
    return versionId;
  };

  return {
    updateLatestInstanceAndGetVersionId,
    isVersionAdded,
    isInstanceAdded,
    isInstanceEdited,
  };
};

export default useUpdateBinary;
