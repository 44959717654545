import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconArrowLeft = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.19645 7.5298C1.93675 7.7895 1.93675 8.21055 2.19645 8.47025L6.46311 12.7369C6.72281 12.9966 7.14386 12.9966 7.40356 12.7369C7.66326 12.4772 7.66326 12.0562 7.40356 11.7965L4.27212 8.66503L13.3333 8.66503C13.7006 8.66503 13.9983 8.3673 13.9983 8.00003C13.9983 7.63276 13.7006 7.33503 13.3333 7.33503L4.27212 7.33503L7.40356 4.20359C7.66326 3.94389 7.66326 3.52283 7.40356 3.26313C7.14386 3.00343 6.72281 3.00343 6.46311 3.26313L2.19645 7.5298Z"
      />
    </BaseIcon>
  );
};

export default IconArrowLeft;
