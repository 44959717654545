import { trackEvent } from "../../../analytics";
import { AppType } from "../../../api/core/controlPlane.types";
import { AvatarApp } from "../../../avatars";
import Flex from "../../../components/Flex";
import PreviewRow2 from "../../../components/PreviewRow2";
import { TruncateText } from "../../../components/PreviewRow2/PreviewRow2.styled";
import Tag from "../../../components/Tag";
import Text from "../../../components/Text";
import { userHasAccessToAction } from "../../../utils/rbac_utils";
import { ActionGroups } from "../../../utils/rbac_utils/types";
import { rem } from "../../../utils/tools";
import { getMarketplaceAppName } from "../../MarketplaceApp/utils/getMarketplaceAppDetails";
import { RenderAppsListParams } from "../Apps.types";

type GetAvatarColorParams = Pick<RenderAppsListParams, "theme"> & {
  appType?: AppType;
};
export const getAvatarColor = ({ appType, theme }: GetAvatarColorParams) => {
  if (appType === "subscription") return theme.color.orange600;
  // use default AvatarApp icon color
  return undefined;
};

export const renderAppsList = ({
  canUserCreateAndEditApps,
  features = {},
  filteredApps,
  isSubscribed,
  path,
  roles = [],
  theme,
  userId,
}: RenderAppsListParams) => {
  if (!filteredApps || !filteredApps.length) {
    return (
      <Text mt={4} styleName="body-2" data-testid="messsage-no-apps-found">
        No apps found
      </Text>
    );
  }
  return filteredApps.map((app, index) => {
    const canUserEditApp =
      canUserCreateAndEditApps &&
      userHasAccessToAction(roles, ActionGroups.AppOperator, app);
    const hasDefaultInstance: boolean = !!app?.default_instance;

    return (
      <PreviewRow2
        isApp
        key={app.id}
        hasNoBorder={index === 0}
        testId={`app-${app?.type}-${app.id}`}
        icon={
          <AvatarApp
            size={32}
            avatarColor={getAvatarColor({
              appType: app?.type,
              theme,
            })}
          />
        }
        name={app.name}
        id={app.id}
        description={
          app?.description ? (
            <Text pl={[1, 1, 1, 0]} styleName="body-3">
              <TruncateText>{app.description}</TruncateText>
            </Text>
          ) : undefined
        }
        lastUpdated={app?.updated_at}
        metaSubDescription={
          <Flex
            alignItems="center"
            pl={[1, 1, 1, 0]}
            mt={hasDefaultInstance ? rem(-2) : 0}
          >
            <Text
              styleName="body-3"
              mt={hasDefaultInstance ? rem(-1) : 0}
              styles={{ color: theme.color.gray600 }}
            >
              Default instance
            </Text>
            {app.default_instance ? (
              <Tag mt={rem(1)} ml={1} label={app.default_instance} />
            ) : (
              <Text
                ml={2}
                styleName="body-3"
                styles={{ color: theme.color.gray600 }}
              >
                None
              </Text>
            )}
          </Flex>
        }
        metaExtra1={
          <Flex
            justifyContent={[
              "flex-start",
              "flex-start",
              "flex-start",
              "flex-start",
              "flex-end",
            ]}
            mt={[1, 1, 1, 1, 0]}
            pr={[0, 0, 0, 4]}
            pl={[1, 1, 1, 0]}
          >
            <Tag label={app.type} />
          </Flex>
        }
        {...(app?.subscription_id && {
          metaExtra2: (
            <Flex
              justifyContent={[
                "flex-start",
                "flex-start",
                "flex-start",
                "flex-start",
                "flex-end",
              ]}
              pr={[0, 0, 0, 4]}
            >
              <Text mt={1} ml={1} styleName="meta-1">
                {getMarketplaceAppName(app?.subscription_id)}
              </Text>
            </Flex>
          ),
        })}
        url={`/acc/${userId}/app/${app.id}`}
        urlOnClick={() =>
          trackEvent("Apps", {
            view: "Apps",
            action: "Link Clicked",
            meta: {
              type: isSubscribed ? "Subscribed App Detail" : "App Detail",
            },
          })
        }
        {...(canUserEditApp && {
          urlEdit: isSubscribed
            ? `/acc/${userId}/app/${app.id}/edit?source=list`
            : `${path.slice(0, -1)}/${app.id}/edit?source=list`,
          urlEditOnClick: () =>
            trackEvent("Apps", {
              view: "Apps",
              action: "Link Clicked",
              meta: {
                type: isSubscribed ? "Subscribed App Edit" : "App Edit",
              },
            }),
        })}
      />
    );
  });
};
