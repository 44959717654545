import styled from "@emotion/styled";

import Flex from "../../../../components/Flex";

export const MapControlsFlex = styled(Flex)`
  position: absolute;
  right: ${({ theme }) => theme.spacing.s3};
  top: ${({ theme }) => theme.spacing.s3};
  z-index: 1111;
`;
