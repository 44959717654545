import { Auth } from "aws-amplify";

import { getSSODomainId } from "../../utils/getSSODomainId";

export const getToken = async (): Promise<string> => {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return token;
  } catch (err) {
    if (err !== "No current user") {
      console.error(err);
    }
    return "";
  }
};

// verifySignInTokenOK checks if the user is signed in and if the user is a google user,
// it checks if the user is part of the SSO domain.
// If the user is part of the SSO domain, it returns false.
// If the user is not part of the SSO domain, it returns true.
// If an error occurs, it returns true because we cannot verify.
export const verifySignInTokenOK = async (): Promise<boolean> => {
  try {
    const session = await Auth.currentSession();
    const decodedPayload = session.getIdToken().decodePayload();
    const cUserName = decodedPayload["cognito:username"];
    if (cUserName.startsWith("google_")) {
      const ssoDomain = await getSSODomainId(decodedPayload.email);
      if (ssoDomain !== "") {
        return false;
      }
    }
    return true;
  } catch (err) {
    console.error(err);
    return true;
  }
};
