import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { filterProps } from "../../utils/filterProps";

import { EditLinkProps } from "./PreviewRow2.types";

export const ProfileLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.color.gray800};
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  &:visited {
    color: ${({ theme }) => theme.color.gray800};
  }
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 ${({ theme }) => theme.border.widthThick}
      ${({ theme }) => theme.color.purple500};
  }
  &:hover {
    color: ${({ theme }) => theme.color.gray900};
  }
  &:active {
    color: ${({ theme }) => theme.color.black};
  }
`;

const ActionLinkStyle = ({ theme }: { theme: any }) => css`
  font-family: ${theme.ui2Typography.fontFamilyMeta1};
  font-size: ${theme.ui2Typography.fontSizeMeta1};
  font-style: ${theme.ui2Typography.fontStyleMeta1};
  font-weight: ${theme.ui2Typography.fontWeightMeta1};
  line-height: ${theme.ui2Typography.lineHeightBody3};
  letter-spacing: ${theme.ui2Typography.letterSpacingMeta1};
  text-transform: ${theme.ui2Typography.textTransformMeta1};
  text-decoration: none;
  cursor: pointer;
  color: ${theme.color.gray600};

  &:visited {
    color: ${theme.color.gray600};
  }
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 ${theme.spacing.s1} 0 ${theme.color.teal500};
  }
  &:hover {
    color: ${theme.color.gray800};
  }
  &:active {
    color: ${theme.color.gray900};
  }
`;

export const EditLink = styled(Link)<EditLinkProps>`
  ${ActionLinkStyle}

  ${(props) =>
    props.isdisabled === "true" &&
    `
      cursor: not-allowed;
      color: ${props.theme.color.gray500};

      &:visited, &:hover, &:focus, &:focus-visible, &:active {
        color: ${props.theme.color.gray500};
      }
    `}
`;

export const ActionLink = styled("a", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})`
  ${ActionLinkStyle}
`;

export const TruncateText = styled("span", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})`
  width: 100%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const BreakText = styled("span", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})`
  display: block;
  width: 100%;
  overflow-wrap: break-word;
  word-break: break-word;
`;
