import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { useTheme } from "@emotion/react";
import { map } from "lodash/fp";

import { IconWarning } from "../../icons";
import { MainViewType } from "../../pages/App/subpages/RunDetails/RunDetails.types";
import { rem } from "../../utils/tools";
import Text from "../Text";

import MapControls from "./components/MapControls";
import MapMarkers from "./components/MapMarkers";
import MapRoutes from "./components/MapRoutes";
import { UseMapStateReturnValue } from "./hooks/useMapState";
import { LEAFLET_ZOOM_SNAP } from "./utils/constants";
import {
  MapContainerInnerFrame,
  MapContainerOuterFrame,
  StyledMapMessageFlex,
} from "./Map.styled";
import { MarkerPoints, SolutionRoute } from "./Map.types";

const positions = map("position");
const mapAttribution = `&copy;
  <a href="https://openmaptiles.org/">
    OpenMapTiles
  </a> &copy;
  <a href="http://openstreetmap.org">
    OpenStreetMap
  </a>`;

export type MapProps = {
  activeTab: MainViewType;
  mapState: UseMapStateReturnValue;
  markerCoords: MarkerPoints;
  routeSet: SolutionRoute;
};

export default function Map({
  activeTab,
  mapState,
  markerCoords,
  routeSet,
}: MapProps) {
  const theme = useTheme();
  const {
    displayUnassigned,
    displayVehiclesNoRoutes,
    displayAnnotations,
    visibleVehicleRoutesById,
  } = mapState;

  return (
    <MapContainerOuterFrame>
      <MapContainerInnerFrame>
        <MapControls
          mapState={mapState}
          isViewResult={activeTab === "result"}
          {...{
            routeSet,
            unassignedCount: routeSet.unassignedCount,
          }}
        />

        <MapContainer
          id="map"
          center={[35, -45]}
          zoomSnap={LEAFLET_ZOOM_SNAP}
          zoomControl={false}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <ZoomControl position="bottomright" />
          <TileLayer
            attribution={mapAttribution}
            url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
            crossOrigin
          />
          {activeTab === "result" && (
            <MapRoutes
              {...{ mapState }}
              allStops={positions(markerCoords)}
              routesbyid={routeSet.routesById}
            />
          )}
          {activeTab === "input" && (
            <MapMarkers
              displayAnnotations={false}
              activeTab={activeTab}
              points={markerCoords}
            />
          )}
          {activeTab === "result" && (
            <MapMarkers
              {...{
                activeTab,
                displayAnnotations,
                displayVehiclesNoRoutes,
                visibleVehicleRoutesById,
              }}
              routesById={routeSet.routesById}
              focusedRouteId={mapState.focusedRouteId}
              points={
                displayUnassigned
                  ? routeSet.markerResultPoints
                  : routeSet.assignedPoints
              }
            />
          )}
        </MapContainer>

        {activeTab === "result" && (
          <StyledMapMessageFlex alignItems="flex-start">
            <IconWarning
              mt={rem(3)}
              mr={1}
              iconSize={12}
              iconColor={theme.color.gray600T}
            />
            <Text as="span" ml={1} styleName="meta-2">
              Stop order set by solution, road map for display purposes only.
            </Text>
          </StyledMapMessageFlex>
        )}
      </MapContainerInnerFrame>
    </MapContainerOuterFrame>
  );
}
