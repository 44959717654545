const unauthenticatedBaseRoute = [
  "/login",
  "/signup",
  "/forgot-password",
  "/reset-password",
];

export default function isUnauthenticatedRoute(pathname: string): boolean {
  return unauthenticatedBaseRoute.some((route) => pathname.includes(route));
}
