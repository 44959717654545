import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { UserPlanData } from "../../api/core/dataPlane.types";
import { createSubscription } from "../../api/payment/controlPlane";
import { UserActionKind, useUser } from "../../AuthProvider";
import Box from "../../components/Box";
import Button2 from "../../components/Button2";
import { HandlePlanSignupParams } from "../../pages/Apps/Apps.types";
import { PlanDetails } from "../../pages/Team/Team.types";
import { handleInputChange } from "../../utils/inputHelpers";
import Input from "../Input";
import Notification from "../Notification";
import Text from "../Text";

import SelfCheckoutPlanSummary from "./components/SelfCheckoutPlanSummary";
import { getPlanBlurb } from "./data/getPlanBlurb";
import {
  getStripeStyles,
  StyledCreditCardContainer,
} from "./SelfCheckout.styled";
import { IntentType, SubscriptionPlanError } from "./SelfCheckout.types";

type SelfCheckoutProps = {
  selectedPlan: PlanDetails;
  setIsCheckoutModalActive: (value: boolean) => void;
};

const SelfCheckout = ({
  selectedPlan,
  setIsCheckoutModalActive,
}: SelfCheckoutProps) => {
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const [user] = useUser();
  const [error, setError] = useState<SubscriptionPlanError | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [name, setName] = useState("");
  const [, userDispatch] = useUser();

  const updateUserPlan = useCallback(() => {
    if (selectedPlan?.planType) {
      userDispatch({
        type: UserActionKind.UPDATE_PLAN_INFO,
        payload: {
          planType: selectedPlan.planType,
        } as UserPlanData,
      });
    }
    setIsCheckoutModalActive(false);
  }, [userDispatch, selectedPlan, setIsCheckoutModalActive]);

  const defaultErrorMessage =
    "An error occurred setting up your account. Please contact Nextmv support.";

  useEffect(() => {
    return () => {
      setError(null);
    };
  }, [setError]);

  if (!stripe || !elements) {
    return null;
  }

  const confirmCard = async (
    intentType: IntentType,
    intentSecret: string,
    name: string
  ) => {
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      throw new Error("There was an error processing the payment.");
    }

    const payload = {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: name,
        },
      },
    };

    return intentType === IntentType.PAYMENT
      ? stripe.confirmCardPayment(intentSecret, payload)
      : stripe.confirmCardSetup(intentSecret, payload);
  };

  const handleSubscriptionSucccess = () => {
    updateUserPlan();
    setIsProcessing(false);
  };

  const handleCardError = (errorMessage: string | undefined) => {
    setError({
      message: errorMessage || defaultErrorMessage,
      addContactSupport: true,
    });
    setIsProcessing(false);
  };

  const handlePlanSignup = async ({ e }: HandlePlanSignupParams) => {
    e.preventDefault();
    setError(null);
    setIsProcessing(true);

    try {
      const { paymentIntentSecret, pendingSetupIntentSecret } =
        await createSubscription(
          JSON.stringify({
            planId: selectedPlan?.planId,
            account: user.id,
          })
        );
      if (paymentIntentSecret) {
        const confirmCardResponse = await confirmCard(
          IntentType.PAYMENT,
          paymentIntentSecret,
          name
        );
        if (confirmCardResponse.error) {
          handleCardError(confirmCardResponse.error.message);
          return;
        }
      } else if (pendingSetupIntentSecret) {
        const confirmCardResponse = await confirmCard(
          IntentType.SETUP,
          pendingSetupIntentSecret,
          name
        );
        if (confirmCardResponse.error) {
          handleCardError(confirmCardResponse.error.message);
          return;
        }
      }
      if (!paymentIntentSecret && !pendingSetupIntentSecret) {
        throw new Error(defaultErrorMessage);
      }
      handleSubscriptionSucccess();
    } catch (error) {
      setError({
        message: defaultErrorMessage,
        addContactSupport: true,
      });
      setIsProcessing(false);
    }
  };

  return (
    <Box pr={2} data-testid={`${selectedPlan.id}-modal`}>
      <SelfCheckoutPlanSummary planInfo={selectedPlan} />

      <Box hasBorderTop mt={4}>
        <Text
          mt={3}
          styleName="body-1-bold"
          styles={{ color: theme.color.gray800 }}
        >
          Billing Details
        </Text>
      </Box>

      <Input
        name="name"
        mt={3}
        htmlType="text"
        size="large"
        data-testid="plan-name-input"
        errorMessage="Please enter a name"
        placeholder="Name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange(e, setName)
        }
        value={name}
      />
      <StyledCreditCardContainer mt={1} data-testid="card-element">
        <CardElement id="card-element" options={getStripeStyles(theme)} />
      </StyledCreditCardContainer>

      {error?.message && (
        <Notification
          mt={3}
          data-testid="checkout-error-message"
          message={error?.message}
          size="small"
          type="error"
          hasContactExtra
        />
      )}

      <Button2
        mt={3}
        label="Buy Now"
        data-testid="plan-buy-button"
        size="large"
        onClick={(e: any) => handlePlanSignup({ e })}
        isLoading={isProcessing}
        isDisabled={!name}
      />

      <Text mt={7} styleName="body-2" styles={{ color: theme.color.gray600 }}>
        {getPlanBlurb()}
      </Text>
    </Box>
  );
};

export default SelfCheckout;
