import React from "react";

import { ENDPOINT_WEBHOOKS } from "../../config/endpoints";
import useManageWebhooks from "../../hooks/useManageWebhooks";

import { WebhooksContextProps } from "./Webhooks.context.types";

export const WebhooksContext = React.createContext<WebhooksContextProps>({
  loadWebhooks: () => new Promise((resolve) => resolve(undefined)),
  setWebhooks: () => null,
  setWebhooksError: () => null,
  webhooks: null,
  webhooksError: null,
});

const WebhooksProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    loadWebhookEntities: loadWebhooks,
    setWebhookEntities: setWebhooks,
    setWebhookEntitiesLoadError: setWebhooksError,
    webhookEntities: webhooks,
    webhookEntitiesLoadError: webhooksError,
  } = useManageWebhooks(ENDPOINT_WEBHOOKS);

  const value: WebhooksContextProps = {
    loadWebhooks,
    setWebhooks,
    setWebhooksError,
    webhooks,
    webhooksError,
  };

  return (
    <WebhooksContext.Provider value={value}>
      {children}
    </WebhooksContext.Provider>
  );
};

export const useWebhooks = () => React.useContext(WebhooksContext);

export default WebhooksProvider;
