import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { trackEvent } from "../../../../analytics";
import { EnsembleDefsData } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import StandardError from "../../../../components/StandardError";
import { ENDPOINT_ENSEMBLES } from "../../../../config/endpoints";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import { getFilteredEntities } from "../../../../utils/entities/getFilteredEntities";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../App.types";
import LoadingListView from "../../components/LoadingListView";
import { ensembleDefinitionTooltips } from "../../data/appTooltips";

import { renderEnsembleDefsList } from "./utils/renderEnsembleDefsList";

const pageTitle = "Ensemble Definitions";

const EnsembleDefs = ({ app }: AppPageProps) => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const { pathname } = useLocation();

  const [filterText, setFilterText] = useState("");

  const {
    additionalEnsembleDefsLoading,
    ensembleDefinitions,
    ensembleDefinitionsLoadError,
    ensembleDefinitionsNextPageToken,
    loadEnsembleDefs,
    setAdditionalEnsembleDefsLoading,
    setEnsembleDefs,
  } = useAppCollection();

  // manage page meta display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load ensemble definitions if not in context already
  useEffect(() => {
    if (!ensembleDefinitions && !ensembleDefinitionsLoadError) {
      loadEnsembleDefs({
        applicationId: app.id,
        shouldPaginate: true,
      });
    }
  }, [
    app.id,
    ensembleDefinitions,
    ensembleDefinitionsLoadError,
    loadEnsembleDefs,
  ]);

  if (ensembleDefinitionsLoadError) {
    return <StandardError errorMessage={ensembleDefinitionsLoadError} />;
  }

  const canUserCreateAndEditEnsembleDefs = userHasAccessToAction(
    roles,
    ActionGroups.RunOperator,
    {}
  );

  const filteredEnsembleDefs = getFilteredEntities(
    filterText,
    ensembleDefinitions
  );
  const renderEnsembleDefs = () => {
    return renderEnsembleDefsList({
      accountId,
      app,
      ensembleDefinitions: filteredEnsembleDefs as EnsembleDefsData,
      canUserCreateAndEditEnsembleDefs,
    });
  };

  const handleLoadMore = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setAdditionalEnsembleDefsLoading(true);
    loadEnsembleDefs({
      applicationId: app.id,
      nextPageToken: ensembleDefinitionsNextPageToken,
      shouldAppend: true,
      shouldPaginate: true,
    });
    return;
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: ensembleDefinitionTooltips.list.header.content,
          tooltipExtraLinkLabel:
            ensembleDefinitionTooltips.list.header.extraLinkLabel,
          tooltipExtraLinkUrl:
            ensembleDefinitionTooltips.list.header.extraLinkUrl,
        }}
        configActionButton={{
          label: "New ensemble definition",
          url: `${pathname}/new`,
          onClick: () =>
            trackEvent("EnsembleDefs", {
              view: "Ensemble Definitions",
              action: "Create Ensemble Def Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditEnsembleDefs,
        }}
        configFilter={{
          inputText: filterText,
          testId: "filter-ensemble-definitions",
          setInputText: setFilterText,
        }}
        refreshButton={{
          emptyItems: null,
          itemsLoading: !ensembleDefinitions,
          loadItems: () =>
            loadEnsembleDefs({
              applicationId: app.id,
              nextPageToken: ensembleDefinitionsNextPageToken,
              shouldAppend: false,
              shouldPaginate: true,
            }),
          setItems: setEnsembleDefs,
        }}
      />

      <PlanNotice type="ensembling" />

      {!ensembleDefinitions ? <LoadingListView /> : renderEnsembleDefs()}

      {!!ensembleDefinitions?.length && (
        <Footer
          app={app}
          endpoint={ENDPOINT_ENSEMBLES}
          error={ensembleDefinitionsLoadError}
          handleMainAction={handleLoadMore}
          isActionButtonDisabled={!ensembleDefinitionsNextPageToken}
          isActionButtonLoading={additionalEnsembleDefsLoading}
          view="list"
        />
      )}
    </>
  );
};

export default EnsembleDefs;
