import { RunsData } from "../../../api/core/controlPlane.types";
import { ConfigOption } from "../../../components/AddConfigOptions/AddConfigOptions.types";
import { ScenarioConfigOptions } from "../../../components/ScenarioDefinitions/ScenarioDefinitions.types";

type ScenarioTempOptionKeyValueSet = {
  [key: string]: Set<string>;
};

type ScenarioTempConfigOptions = {
  [key: string]: ScenarioTempOptionKeyValueSet;
};

export const getOptionConfigByScenarioFromRuns = (
  runs: RunsData
): ScenarioConfigOptions => {
  if (!runs) return {};
  let scenarioOptionSets: ScenarioTempConfigOptions = {};

  const uniqueOptionSetIds = new Set<string>();

  runs.forEach((run) => {
    const { option_set, options, scenario_id } = run;

    if (!scenario_id || !option_set) return;

    // each option set only needs to be added once
    if (uniqueOptionSetIds.has(option_set)) {
      return;
    }

    uniqueOptionSetIds.add(option_set);

    // setup empty object for scenario
    if (!scenarioOptionSets[scenario_id]) {
      scenarioOptionSets[scenario_id] = {};
    }

    // set will ensure each option is unique to each scenario
    for (const key in options) {
      if (!(key in scenarioOptionSets[scenario_id])) {
        scenarioOptionSets[scenario_id][key] = new Set<string>();
      }
      scenarioOptionSets[scenario_id][key].add(options[key]);
    }
  });

  const configOptions: ScenarioConfigOptions = {};
  for (const scenarioId in scenarioOptionSets) {
    const optionSets: ConfigOption[] = [];

    const optionObject = scenarioOptionSets[scenarioId];

    if (!optionObject) {
      continue;
    }

    for (const optionKey in optionObject) {
      const configValueString = Array.from(
        scenarioOptionSets[scenarioId][optionKey]
      ).join(", ");

      const configOption: ConfigOption = {
        option: optionKey,
        value: configValueString,
      };
      optionSets.push(configOption);
    }

    configOptions[scenarioId] = !!optionSets.length
      ? optionSets
      : [{ option: "", value: "" }];
  }

  return configOptions;
};
