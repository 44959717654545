import React from "react";
import { useTheme } from "@emotion/react";

import Modal2 from "../../../../../components/Modal2";
import Notification from "../../../../../components/Notification";
import StandardError from "../../../../../components/StandardError";
import Text from "../../../../../components/Text";
import { rem } from "../../../../../utils/tools";
import { StartStopExperimentConfirmModalProps } from "../../../types/Experiment.types";
import { modalContent } from "../data/modalContent";

const StartStopSwitchbackConfirmModal = ({
  error,
  handleStartStopModalActions,
  hasStartCriteria,
  modalType,
  setDisplayConfirmModal,
  testBaselineInstances,
  testCandidateInstances,
  testName,
}: StartStopExperimentConfirmModalProps) => {
  const theme = useTheme();
  const content = modalContent[modalType]({
    testName: testName,
    ...(testBaselineInstances && {
      baselineInstances: testBaselineInstances,
    }),
    ...(testCandidateInstances && {
      candidateInstances: testCandidateInstances,
    }),
  });

  const handlePrimaryAction = async () => {
    await handleStartStopModalActions(modalType);
  };

  const getModalChildren = () => {
    return (
      <>
        {content.extra && (
          <Text
            mt={2}
            styleName="body-2"
            styles={{ color: theme.color.gray600 }}
          >
            {content.extra}
          </Text>
        )}

        {modalType === "start" && hasStartCriteria && (
          <Notification
            mt={3}
            type="notice"
            message="The test can be started manually before the date specified in the start criteria, but it will not begin collecting data until this start date."
          />
        )}

        {error && <StandardError errorMessage={error} />}
      </>
    );
  };

  return (
    <Modal2
      isActive={true}
      width={rem(600)}
      title={content.title}
      summary={content.summary}
      closeActionOnClick={() => setDisplayConfirmModal(null)}
      primaryActionLabel={content.primaryActionLabel}
      primaryActionOnClick={handlePrimaryAction}
      {...(modalType !== "start" && {
        primaryActionStyles: {
          backgroundColor: theme.color.red600,
          "&:hover, &:focus, &:active": {
            backgroundColor: theme.color.red700,
          },
          "&:focus-visible": {
            outlineColor: theme.color.red800,
          },
        },
      })}
      secondaryActionLabel={content.secondaryActionLabel || "Cancel"}
      secondaryActionOnClick={() => setDisplayConfirmModal(null)}
      children={getModalChildren()}
    />
  );
};

export default StartStopSwitchbackConfirmModal;
