import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import Box from "../../../../components/Box";
import Button2 from "../../../../components/Button2";
import Flex from "../../../../components/Flex";
import HeaderPublic from "../../../../components/HeaderPublic";
import Input from "../../../../components/Input";
import { useMetaTitle } from "../../../../components/Layout";
import Notification from "../../../../components/Notification";
import { AUTH_FLOW_CONTENT_MAX_WIDTH } from "../../../../config/public";
import useAuthFlow from "../../../../hooks/useAuthFlow";
import { useLocalStorage } from "../../../../utils";
import { getSSODomainId } from "../../../../utils/getSSODomainId";
import { handleInputChange } from "../../../../utils/inputHelpers";
import { rem } from "../../../../utils/tools";
import AuthFlowCallout from "../../components/AuthFlowCallout";
import GoogleAuthenticate from "../../components/GoogleAuthenticate";
import { LoginPageProps } from "../../LogIn.types";

const pageTitle = "Log In";

const Identify = ({ username }: LoginPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();

  const [isEmailSet, setIsEmailSet] = useState(false);
  const [emailOrSSOError, setEmailOrSSOError] = useState<string | null>(null);
  const [gse] = useLocalStorage("gse", false);

  const {
    state: locationState,
    email,
    error,
    hasEmail,
    isProcessing,
    logInGoogle,
    ssoLogin,
    setEmail,
    setHasEmail,
    setIsProcessing,
  } = useAuthFlow();

  // pre-fill email field if data exists
  useEffect(() => {
    if (username && !isEmailSet) {
      setEmail(username);
      setIsEmailSet(true);
    }
  }, [isEmailSet, setEmail, username]);

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  const handleContinueOnClick = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();
    setEmailOrSSOError(null);

    if (!email) {
      return;
    }
    setIsProcessing(true);

    // handle sso check
    try {
      const domainId = await getSSODomainId(email);
      if (domainId) {
        ssoLogin(domainId);
        return;
      }
    } catch (err) {
      setIsProcessing(false);
      setEmailOrSSOError((err as Error).message);
      return;
    }

    setHasEmail(true);

    trackEvent("AuthFlow", {
      view: "Login Identify",
      action: "Continue Button Clicked",
    });
  };

  if (hasEmail) {
    return (
      <Redirect
        to={{
          pathname: "/login/password",
          search: `?username=${encodeURIComponent(email)}`,
          state: locationState,
        }}
      />
    );
  }

  return (
    <>
      <HeaderPublic configPageTitle={{ label: "Log in to your account" }} />

      <Box mt={6} maxWidth={AUTH_FLOW_CONTENT_MAX_WIDTH}>
        {gse && (
          <Notification
            mt={2}
            mb={2}
            type="error"
            message={
              "Your organization uses SSO for authentication. Please enter your email to continue."
            }
            hasContactExtra
          />
        )}
        <form onSubmit={handleContinueOnClick}>
          <Input
            mt={1}
            isRequired
            testId="login-identify-email-input"
            htmlType="email"
            size="large"
            placeholder="Email address"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, setEmail)
            }
          />

          {error && <Notification mt={2} type="error" message={error} />}
          {emailOrSSOError && (
            <Notification mt={2} type="error" message={emailOrSSOError} />
          )}

          <Flex mt={2}>
            <Button2
              testId="login-identify-button"
              isLoading={isProcessing}
              size="large"
              isRequired
              label="Continue"
              styles={{
                minWidth: rem(240),
                backgroundColor: theme.color.sky600,
                "&:hover, &:focus, &:active": {
                  backgroundColor: theme.color.sky700,
                },
                "&:focus-visible": {
                  backgroundColor: theme.color.sky700,
                  outlineWidth: theme.border.widthMediumShadowOnly,
                  outlineStyle: "solid",
                  outlineColor: theme.color.sky800,
                },
              }}
            />
          </Flex>
        </form>

        <AuthFlowCallout
          content="Don’t have an account?"
          linkLabel="Sign up"
          linkTo={`/signup${
            email ? `?username=${encodeURIComponent(email)}` : ""
          }`}
          linkTrackEventCategory="AuthFlow"
          linkTrackEventProperties={{
            view: "Login Identify",
            action: "Sign Up Link Clicked",
            meta: {
              from: "identify",
            },
          }}
        />

        {!gse && (
          <GoogleAuthenticate
            buttonLabel="Continue with Google"
            buttonName="google-login-button"
            buttonOnClick={logInGoogle}
          />
        )}
      </Box>
    </>
  );
};

export default Identify;
