import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { BatchExperimentsResponse } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import StandardError from "../../../../components/StandardError";
import { PAYLOAD_EXPERIMENT_TYPE_BATCH } from "../../../../config/experiments";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import { getFilteredEntities } from "../../../../utils/entities/getFilteredEntities";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../../App/App.types";
import LoadingListView from "../../../App/components/LoadingListView";
import { experimentsTooltips } from "../../data/microcopy";
import { renderExperimentsList } from "../../utils/renderExperimentsList";

const pageTitle = "Batch Experiments";

const BatchExperiments = ({ app }: AppPageProps) => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [filterText, setFilterText] = useState("");

  const {
    batchExperiments,
    batchExperimentsError,
    loadBatchExperiments,
    setBatchExperiments,
  } = useExperiments();

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load batch experiments if not in context already
  useEffect(() => {
    if (!batchExperiments && !batchExperimentsError) {
      loadBatchExperiments({
        applicationId: app.id,
        type: PAYLOAD_EXPERIMENT_TYPE_BATCH,
      });
    }
  }, [app.id, batchExperiments, batchExperimentsError, loadBatchExperiments]);

  if (batchExperimentsError) {
    return <StandardError errorMessage={batchExperimentsError} />;
  }

  const canUserCreateAndEditBatch = userHasAccessToAction(
    roles,
    ActionGroups.ExperimentOperator,
    {}
  );

  const renderBatchExperiments = () => {
    const filteredBatchExperiments = getFilteredEntities(
      filterText,
      batchExperiments
    );

    return renderExperimentsList({
      accountId,
      app,
      canUserCreateAndEdit: canUserCreateAndEditBatch,
      experiments: filteredBatchExperiments as BatchExperimentsResponse,
      theme,
      kind: "batch",
      urlOnClickTrackEventCategory: "Experiments",
      urlOnClickTrackEventProperties: {
        view: "Batch Experiments",
        action: "Link Clicked",
        meta: {
          type: "Batch Experiment Detail",
        },
      },
    });
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: experimentsTooltips.batch.content,
          tooltipExtraLinkLabel: experimentsTooltips.batch.extraLinkLabel,
          tooltipExtraLinkUrl: experimentsTooltips.batch.extraLinkUrl,
        }}
        configActionButton={[
          {
            label: "New experiment",
            testId: "create-new-batch-experiment-button",
            url: `${pathname}/new`,
            onClick: () =>
              trackEvent("Experiments", {
                view: "Batch Experiments",
                action: "Create Experiment Button Clicked",
              }),
            isActionAllowed: canUserCreateAndEditBatch,
          },
        ]}
        configFilter={{
          inputText: filterText,
          testId: "filter-batch-experiments",
          setInputText: setFilterText,
        }}
        refreshButton={{
          emptyItems: null,
          itemsLoading: !batchExperiments,
          loadItems: () =>
            loadBatchExperiments({
              applicationId: app.id,
              type: PAYLOAD_EXPERIMENT_TYPE_BATCH,
            }),
          setItems: setBatchExperiments,
        }}
      />

      <PlanNotice {...{ app }} type="custom-apps" />

      {!batchExperiments ? <LoadingListView /> : renderBatchExperiments()}
    </>
  );
};

export default BatchExperiments;
