import {
  AppDef,
  AppInputsData,
  AppInstancesData,
  AppVersionsData,
  CreateRunPayload,
  EnsembleDefsData,
  EntityErrorMessage,
  LoadEntitiesParams,
  PutAppPayload,
  PutRunDetailsPayload,
  RunsData,
} from "../../api/core/controlPlane.types";

export enum RunDetailsActionKind {
  ADD_RUN_DETAILS,
  CREATE_TEMPORARY_RUN_UPLOAD_METADATA,
  LOADING_RUN_DETAILS,
  SET_ERROR_RUN_DETAILS,
  UPDATE_ONE_RUN_DETAILS,
  UPLOAD_FILE_FOR_RUN,
}

interface ADD_RUN_DETAILS {
  type: RunDetailsActionKind.ADD_RUN_DETAILS;
  payload: CreateRunPayload;
}

interface CREATE_TEMPORARY_RUN_UPLOAD_METADATA {
  type: RunDetailsActionKind.CREATE_TEMPORARY_RUN_UPLOAD_METADATA;
}

interface LOADING_RUN_DETAILS {
  type: RunDetailsActionKind.LOADING_RUN_DETAILS;
}

interface SET_ERROR_RUN_DETAILS {
  type: RunDetailsActionKind.SET_ERROR_RUN_DETAILS;
  payload: string;
}

interface UPDATE_ONE_RUN_DETAILS {
  type: RunDetailsActionKind.UPDATE_ONE_RUN_DETAILS;
  payload: PutRunDetailsPayload;
}

interface UPLOAD_FILE_FOR_RUN {
  type: RunDetailsActionKind.UPLOAD_FILE_FOR_RUN;
  payload: ArrayBuffer;
}

export type RunDetailsAction =
  | ADD_RUN_DETAILS
  | CREATE_TEMPORARY_RUN_UPLOAD_METADATA
  | LOADING_RUN_DETAILS
  | SET_ERROR_RUN_DETAILS
  | UPDATE_ONE_RUN_DETAILS
  | UPLOAD_FILE_FOR_RUN;

export type RunDetailsState = {
  error: string;
  loading: boolean;
};

export type LoadRunHistoryParams = {
  applicationId: string;
  nextPageToken?: string;
  queryEnd?: string;
  queryStart?: string;
  shouldAppend?: boolean;
};

export type LoadAppInputsParams = {
  applicationId: string;
};

export type AppNavSection = "app-runs" | "app-experiments";

export type AppContextProps = {
  activeAppNavSections: AppNavSection[];
  additionalAppInputsLoading: boolean;
  additionalEnsembleDefsLoading: boolean;
  additionalInstancesLoading: boolean;
  additionalRunsLoading: boolean;
  additionalVersionsLoading: boolean;
  app: AppDef;
  appLoadError: EntityErrorMessage;
  appInputs: AppInputsData;
  appInputsLoadError: EntityErrorMessage;
  appInputsNextPageToken: string;
  editApp: (
    applicationId: string,
    entityId: string,
    payload: PutAppPayload
  ) => void;
  ensembleDefinitions: EnsembleDefsData;
  ensembleDefinitionsLoadError: EntityErrorMessage;
  ensembleDefinitionsNextPageToken: string;
  instances: AppInstancesData;
  instancesError: EntityErrorMessage;
  instancesNextPageToken: string;
  loadApp: (
    id: string,
    entityId?: string,
    isReturnJSON?: boolean
  ) => Promise<any | undefined>;
  loadRuns: ({
    applicationId,
    nextPageToken,
    queryEnd,
    queryStart,
    shouldAppend,
  }: LoadEntitiesParams) => Promise<any | undefined>;
  loadAppInputs: ({
    applicationId,
    nextPageToken,
    shouldAppend,
    shouldPaginate,
  }: LoadEntitiesParams) => Promise<any | undefined>;
  loadEnsembleDefs: ({
    applicationId,
    nextPageToken,
    queryEnd,
    queryStart,
    shouldAppend,
  }: LoadEntitiesParams) => Promise<any | undefined>;
  loadInstances: ({
    applicationId,
    nextPageToken,
    shouldAppend,
    shouldPaginate,
  }: LoadEntitiesParams) => Promise<any | undefined>;
  loadVersions: ({
    applicationId,
    shouldPaginate,
    nextPageToken,
    shouldAppend,
  }: LoadEntitiesParams) => Promise<any | undefined>;
  runHistory: RunsData;
  runsLoadError: EntityErrorMessage;
  runsNextPageToken: string;
  setActiveAppNavSections: React.Dispatch<
    React.SetStateAction<AppNavSection[]>
  >;
  setAdditionalAppInputsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAdditionalInstancesLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAdditionalRunsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAdditionalVersionsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAdditionalEnsembleDefsLoading: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setAppInputs: React.Dispatch<React.SetStateAction<AppInputsData>>;
  setEnsembleDefs: React.Dispatch<React.SetStateAction<EnsembleDefsData>>;
  setRunHistory: React.Dispatch<React.SetStateAction<RunsData>>;
  setRunsLoadError: React.Dispatch<React.SetStateAction<EntityErrorMessage>>;
  setVersions: React.Dispatch<React.SetStateAction<AppVersionsData>>;
  setVersionsError: React.Dispatch<React.SetStateAction<EntityErrorMessage>>;
  setInstances: React.Dispatch<React.SetStateAction<AppInstancesData>>;
  setInstancesError: React.Dispatch<React.SetStateAction<EntityErrorMessage>>;
  versions: AppVersionsData;
  versionsError: EntityErrorMessage;
  versionsNextPageToken: string;
};

export type AnyFunction = (...args: any[]) => any;
