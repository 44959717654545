import React from "react";
import { useTheme } from "@emotion/react";

import Box from "../../../../../../components/Box";
import Flex from "../../../../../../components/Flex";
import InputCheckbox from "../../../../../../components/InputCheckbox";
import { IconMenuDots } from "../../../../../../icons";
import { rem } from "../../../../../../utils/tools";
import { UseMapStateReturnValue } from "../../../../hooks/useMapState";

import { MapMenuControl, MapMenuStyled, Notification } from "./MapMenu.styled";

export type MapMenuProps = {
  mapState: UseMapStateReturnValue;
  isMapMenuActive: boolean;
  setIsMapMenuActive: (isMapMenuActive: boolean) => void;
  isMapControlDisabled: boolean;
  unassignedCount: number;
};

export default function MapMenu({
  mapState,
  isMapMenuActive,
  setIsMapMenuActive,
  isMapControlDisabled,
  unassignedCount,
}: MapMenuProps) {
  const {
    displayUnassigned,
    setDisplayUnassigned,
    displayDirectionalArrows,
    setDisplayDirectionalArrows,
    displayAnnotations,
    setDisplayAnnotations,
  } = mapState;

  const theme = useTheme();

  const handleMapMenuControlClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    setIsMapMenuActive(!isMapMenuActive);
  };

  const handleUnassignedChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayUnassigned(target.checked);
  };

  const handleDirectionalArrowsChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayDirectionalArrows(target.checked);
  };

  const handleAnnotationsChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayAnnotations(target.checked);
  };

  const renderNotification = () => {
    if (
      !isMapControlDisabled &&
      !isMapMenuActive &&
      !displayUnassigned &&
      !!unassignedCount
    )
      return (
        <Notification data-testid="unassigned-notification">
          {unassignedCount}
        </Notification>
      );
    return null;
  };

  const renderUnassignedLabel = () => {
    return unassignedCount > 0 ? (
      <>
        Unassigned <span style={{ fontWeight: "normal" }}>(</span>
        {unassignedCount}
        <span style={{ fontWeight: "normal" }}>)</span>
      </>
    ) : (
      "Unassigned"
    );
  };

  return (
    <MapMenuStyled isDisabled={isMapControlDisabled}>
      <Flex alignItems="center">
        {renderNotification()}
        <MapMenuControl
          data-testid="map-controls-toggle"
          onClick={handleMapMenuControlClick}
        >
          <IconMenuDots
            iconSize={24}
            iconColor={
              isMapControlDisabled ? theme.color.gray400 : theme.color.gray700
            }
          />
        </MapMenuControl>
      </Flex>

      {isMapMenuActive && (
        <Box data-testid="map-menu-contents" minWidth={rem(160)} mt={1} p={2}>
          <InputCheckbox
            mb={1}
            isSmall
            isDisabled={isMapControlDisabled}
            checked={displayDirectionalArrows}
            handleChange={handleDirectionalArrowsChange}
            id="display-directional-arrows"
            label="Directional arrows"
            value="displayDirectionalArrows"
            data-testid="display-arrows-checkbox"
          />
          <InputCheckbox
            mb={1}
            isSmall
            isDisabled={isMapControlDisabled}
            checked={displayAnnotations}
            handleChange={handleAnnotationsChange}
            id="display-annotations"
            label="Stop annotations"
            value="displayAnnotations"
            data-testid="display-annotations-checkbox"
          />
          <InputCheckbox
            isSmall
            isDisabled={unassignedCount === 0}
            checked={unassignedCount > 0 && displayUnassigned}
            handleChange={handleUnassignedChange}
            id="display-unassigned"
            label={renderUnassignedLabel()}
            value="displayUnassigned"
            data-testid="display-unassigned"
          />
        </Box>
      )}
    </MapMenuStyled>
  );
}
