import { InputSets } from "../../../api/core/controlPlane.types";

export type InputsPerInputSetObj = {
  [key: string]: number;
};

export const getInputCountPerInputSet = (inputSets: InputSets) => {
  const baseInputSetsCountObj: InputsPerInputSetObj = {};
  if (!inputSets) {
    return baseInputSetsCountObj;
  }

  return inputSets?.reduce((acc, inputSet) => {
    const numberOfInputs =
      inputSet?.input_ids.length || inputSet?.inputs.length || 0;

    acc[inputSet.id] = numberOfInputs;
    return acc;
  }, baseInputSetsCountObj);
};
