import {
  AppInstancesData,
  AppResponse,
} from "../../../../../api/core/controlPlane.types";

import { getAutoInstance } from "./getAutoInstances";

export const addAutoInstance = (
  app: AppResponse,
  instances: AppInstancesData
) => {
  const autoInstance = getAutoInstance(app);
  if (autoInstance) {
    return [...(instances || []), ...[autoInstance]];
  }
  return instances;
};
