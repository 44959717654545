import { Theme, useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { SwitchbackTestPlan } from "../../../../../api/core/controlPlane.types";
import Box from "../../../../../components/Box";
import Details from "../../../../../components/Details";
import Flex from "../../../../../components/Flex";
import Table2 from "../../../../../components/Table2";
import {
  TableData,
  TableHeader,
} from "../../../../../components/Table2/Table2.types";
import Text from "../../../../../components/Text";
import Tooltip from "../../../../../components/Tooltip";
import {
  planSummaryLayout,
  planSummaryLayoutWithStartTime,
} from "../data/planSummaryLayout";

type PlanSumnmaryTableProps = {
  plan: SwitchbackTestPlan;
};

const getPlanSummaryTableHeaders = (
  theme: Theme,
  hasStartTime?: boolean
): TableHeader => {
  const h: TableHeader = [
    {
      id: "unit-index",
      accessorKey: "index",
      label: "Unit Index Header",
      header: "Unit Index",
    },
    {
      id: "unit-instance",
      accessorKey: "instance_id",
      label: "Instance ID Header",
      header: "Unit Instance",
    },
    {
      id: "unit-duration",
      accessorKey: "duration_minutes",
      label: "Unit Duration Header",
      header: "Unit Duration (Minutes)",
    },
  ];
  if (hasStartTime) {
    h.push(
      {
        id: "unit-start",
        accessorKey: "start",
        label: "Unit Start Time Header",
        header: () => {
          return (
            <Flex width="100%">
              <Text
                as="span"
                styleName="label"
                styles={{ color: theme.color.gray600 }}
              >
                Unit Start Time
              </Text>
              <Tooltip ml={1} direction="left">
                The plan unit duration begins exactly at the start time.
              </Tooltip>
            </Flex>
          );
        },
        cell: (props) => {
          return (
            <time
              dateTime={props.getValue()}
              title={DateTime.fromISO(props.getValue()).toFormat(
                "yyyy-MM-dd 'T'HH:mm:ssZZZ (ZZZZ)"
              )}
            >
              {DateTime.fromISO(props.getValue())
                .toFormat("yyyy-MM-dd · h:mm:ss a")
                .toLowerCase()}
            </time>
          );
        },
      },
      {
        id: "unit-stop",
        accessorKey: "stop",
        label: "Unit Stop Time Header",
        header: () => {
          return (
            <Flex width="100%">
              <Text
                as="span"
                styleName="label"
                styles={{ color: theme.color.gray600 }}
              >
                Unit Stop Time
              </Text>
              <Tooltip ml={1} direction="left">
                The plan unit stop time marks the end of the plan unit duration
                but is not itself included in the duration.
              </Tooltip>
            </Flex>
          );
        },
        cell: (props) => {
          return (
            <time
              dateTime={props.getValue()}
              title={DateTime.fromISO(props.getValue()).toFormat(
                "yyyy-MM-dd 'T'HH:mm:ssZZZ (ZZZZ)"
              )}
            >
              {DateTime.fromISO(props.getValue())
                .toFormat("yyyy-MM-dd · h:mm:ss a")
                .toLowerCase()}
            </time>
          );
        },
      }
    );
  }
  return h;
};

const getPlanSummaryRows = (plan: SwitchbackTestPlan): TableData[] => {
  const startTime = new Date(plan.start ?? "");
  const data: TableData[] = [];
  plan.units
    .sort((a, b) => a.index - b.index)
    .forEach((unit) => {
      const row: TableData = {};
      row["index"] = unit.index;
      row["instance_id"] = unit.instance_id;
      row["duration_minutes"] = unit.duration_minutes;
      if (plan.start) {
        const unitStart = new Date(startTime);
        unitStart.setMinutes(
          unitStart.getMinutes() + unit.duration_minutes * unit.index
        );
        row["start"] = unitStart.toISOString();

        const unitStop = new Date(startTime);
        unitStop.setMinutes(
          unitStop.getMinutes() + unit.duration_minutes * (unit.index + 1)
        );
        row["stop"] = unitStop.toISOString();
      }
      data.push(row);
    });
  return data;
};

const PlanSummaryTable = ({ plan }: PlanSumnmaryTableProps) => {
  const theme = useTheme();

  return (
    <Box
      mt={6}
      ml={[0, -6]}
      mr={[0, -7]}
      mb={-2}
      pt={4}
      pr={[0, 7]}
      pl={[0, 6]}
      hasBorderTop
    >
      <Details
        key="plan-summary"
        htmlId="plan-summary"
        size="large"
        controlPosition="left"
        isOpen={false}
        summary={
          <Flex alignItems="center">
            <Text
              as="h3"
              styleName="header-2"
              styles={{ color: theme.color.gray800 }}
            >
              Plan Summary
            </Text>
          </Flex>
        }
      >
        <Box ml={7}>
          <Table2
            mt={2}
            // ml={7}
            // width="100%"
            isWide
            useMinWidth
            headers={getPlanSummaryTableHeaders(theme, !!plan.start)}
            data={getPlanSummaryRows(plan)}
            layoutSchema={
              plan.start ? planSummaryLayoutWithStartTime : planSummaryLayout
            }
          />
        </Box>
      </Details>
    </Box>
  );
};

export default PlanSummaryTable;
