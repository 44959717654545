import React from "react";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import Flex from "../../../../../components/Flex";
import Text from "../../../../../components/Text";
import { ScrollYFlex, StyledHourPanelsFlex } from "../RunDetails.styled";
import {
  SchedulingSolutionAssignedShift,
  WorkerToSchedule,
} from "../RunDetails.types";
import { getPanelHeight, VizHourRange } from "../utils/getWorkerData";
import {
  renderAssignedWorkerPeriods,
  renderHoursPanels,
} from "../utils/renderWorkerData";

type VisualizationSchedulingOutputProps = {
  assignedWorkerShifts: SchedulingSolutionAssignedShift[];
  visualizeHoursRange?: VizHourRange;
  workerColors?: { [key: string]: string };
  workers?: WorkerToSchedule[];
};

const VisualizationSchedulingOutput = ({
  assignedWorkerShifts,
  visualizeHoursRange,
  workerColors,
  workers,
}: VisualizationSchedulingOutputProps) => {
  const theme = useTheme();

  return (
    <ScrollYFlex flexDirection="row" alignItems="stretch">
      <StyledHourPanelsFlex
        pt={3}
        pr={6}
        pb={5}
        pl={6}
        panelHeight={
          assignedWorkerShifts ? getPanelHeight(assignedWorkerShifts.length) : 0
        }
      >
        <Text
          ml={2}
          styleName="body-2-bold"
          styles={{
            color: theme.color.gray800,
          }}
        >
          Assigned workers{" "}
          <Text
            as="span"
            styleName="meta-1"
            styles={{ color: theme.color.gray600 }}
          >
            ({DateTime.now().toFormat("ZZZZZ")})
          </Text>
        </Text>

        <Flex pt={3} flexGrow={1} relative>
          {renderHoursPanels({ hourRange: visualizeHoursRange, theme })}
          <div>
            {assignedWorkerShifts &&
              renderAssignedWorkerPeriods({
                assignedWorkerShifts,
                hourRange: visualizeHoursRange,
                theme,
                workerColors,
                workers,
              })}
          </div>
        </Flex>
      </StyledHourPanelsFlex>
    </ScrollYFlex>
  );
};

export default VisualizationSchedulingOutput;
