// unused function, but leaving for potential future use
export const validateDateRFC3339 = (str?: string): boolean => {
  const regex =
    /^[1-9]\d{3}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(Z|(-|\+)\d{2}:[0|3|4][0|5])$/g;
  return !!str && regex.test(str.trim());
};

export const isInRange = (val: number, min: number, max: number): boolean => {
  if (isNaN(val)) return false;
  return val >= min && val <= max;
};
