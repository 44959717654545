import styled from "@emotion/styled";

import Box from "../../../../components/Box";

export type StyledPlanOptionProps = {
  isCurrentPlan: boolean;
};

export const StyledPlanOption = styled(Box)<StyledPlanOptionProps>`
  position: relative;
  border-width: ${({ theme }) => theme.border.width};
  border-style: ${({ theme }) => theme.border.style};
  border-radius: ${({ theme }) => theme.border.radiusLarge};
  border-color: ${(props) =>
    props.isCurrentPlan
      ? props.theme.color.green500
      : props.theme.color.gray200};
`;

export const StyledPlanFootnotes = styled.ul`
  padding-top: ${({ theme }) => theme.spacing.s2};
  list-style: none;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
`;
export const StyledPlanFootnote = styled.li`
  margin-top: ${({ theme }) => theme.spacing.s1};
`;
