import { parse } from "lossless-json-no-nullish-coalescing-operator";

// lossless text to JSON parsing
export const getParsedRunInput = (data: string) => {
  let parsedData;

  try {
    parsedData = parse(data);
  } catch (error) {
    return data;
  }

  return parsedData;
};
