import React, { useEffect, useMemo } from "react";
import { useTheme } from "@emotion/react";

import {
  EnsembleDefsData,
  EntityErrorMessage,
} from "../../../../../api/core/controlPlane.types";
import ControlPanel from "../../../../../components/ControlPanel";
import Flex from "../../../../../components/Flex";
import Notification from "../../../../../components/Notification";
import Select from "../../../../../components/Select";
import { SelectOption } from "../../../../../components/Select/Select.types";
import Tag from "../../../../../components/Tag";
import Text from "../../../../../components/Text";
import { META_LABEL_WIDTH } from "../../../../../config/general";
import { rem } from "../../../../../utils/tools";
import InstanceConfig from "../../../components/InstanceConfig";
import { appTooltips } from "../../../data/appTooltips";

interface EnsembleDefSelectionProps {
  ensembleDefs: EnsembleDefsData;
  ensembleDefsLoadError: EntityErrorMessage;
  pendingEnsembleDefId: string;
  setPendingEnsembleDefId: React.Dispatch<React.SetStateAction<string>>;
}

const EnsembleDefSelection = ({
  ensembleDefs,
  ensembleDefsLoadError,
  pendingEnsembleDefId,
  setPendingEnsembleDefId,
}: EnsembleDefSelectionProps) => {
  const theme = useTheme();

  const ensembleDefsOptions = useMemo(() => {
    if (!ensembleDefs) return [];
    return ensembleDefs.map((ensembleDef) => {
      return {
        label: ensembleDef.name,
        value: ensembleDef.id,
        runGroups: ensembleDef.run_groups,
        rules: ensembleDef.rules,
      };
    });
  }, [ensembleDefs]);

  const defaultSelectedEnsembleDefOption = ensembleDefsOptions[0];

  // set default ensemble definition if definitions exist
  useEffect(() => {
    if (
      ensembleDefsOptions.length &&
      defaultSelectedEnsembleDefOption &&
      !pendingEnsembleDefId
    ) {
      setPendingEnsembleDefId(defaultSelectedEnsembleDefOption.value);
    }
  }, [
    defaultSelectedEnsembleDefOption,
    ensembleDefsOptions.length,
    pendingEnsembleDefId,
    setPendingEnsembleDefId,
  ]);

  const getSelectedEnsembleDef = (id: string) => {
    return ensembleDefsOptions.find(
      (ensembleDefsOption) => ensembleDefsOption.value === id
    );
  };

  const selectedEnsembleDefRunGroups =
    getSelectedEnsembleDef(pendingEnsembleDefId)?.runGroups;
  const selectedEnsembleDefRules =
    getSelectedEnsembleDef(pendingEnsembleDefId)?.rules;

  return (
    <ControlPanel
      testId="create-new-run-ensemble-def"
      headerTitle="Ensemble Definition"
      headerTooltipContent={appTooltips.newRunEnsembleDef.content}
      isOpen
    >
      {ensembleDefsLoadError && (
        <Notification
          data-testid="create-new-run-ensemble-def-error-message"
          width="100%"
          type="error"
          hasContactExtra
          message={ensembleDefsLoadError}
        />
      )}
      <Select
        placeholder="Select ensemble definition"
        options={ensembleDefsOptions}
        defaultValue={defaultSelectedEnsembleDefOption}
        value={getSelectedEnsembleDef(pendingEnsembleDefId)}
        onChange={(selected: SelectOption) =>
          setPendingEnsembleDefId(selected.value)
        }
      />

      {selectedEnsembleDefRunGroups &&
        selectedEnsembleDefRunGroups.map((runGroup, index) => {
          const isFirst = index === 0;
          return (
            <Flex
              flexDirection="column"
              key={runGroup.id}
              mt={isFirst ? 3 : 2}
              pt={isFirst ? 0 : 3}
              hasBorderTop={isFirst ? false : true}
            >
              <Text
                styleName="body-3-bold"
                styles={{ color: theme.color.gray800 }}
              >
                {runGroup.id}
              </Text>

              <Flex mt={1}>
                <Text
                  styleName="body-3"
                  mt={rem(1)}
                  styles={{ width: META_LABEL_WIDTH }}
                >
                  Instance ID
                </Text>
                <Tag
                  ml={rem(-6)}
                  type="id"
                  size="small"
                  label={runGroup.instance_id}
                />
              </Flex>
              <Flex mt={1}>
                <Text styleName="body-3" styles={{ width: META_LABEL_WIDTH }}>
                  Repetitions
                </Text>
                <Text styleName="body-3">{runGroup.repetitions}</Text>
              </Flex>

              <Flex mt={2} pb={2} width={"100%"}>
                <Text
                  styleName="body-3"
                  mt={rem(9)}
                  styles={{ width: META_LABEL_WIDTH, flexShrink: 0 }}
                >
                  Configuration
                </Text>

                <Flex
                  hasBorderTop
                  flexDirection="column"
                  pt={1}
                  width={`calc(100% - ${META_LABEL_WIDTH})`}
                  flexShrink={0}
                >
                  {!runGroup?.options ? (
                    <Text
                      styleName="body-3"
                      styles={{
                        color: theme.color.gray600,
                      }}
                    >
                      None
                    </Text>
                  ) : (
                    <InstanceConfig isAbbreviated options={runGroup.options} />
                  )}
                </Flex>
              </Flex>
            </Flex>
          );
        })}

      {selectedEnsembleDefRules && (
        <Flex flexDirection="column">
          <Text
            as="h4"
            mt={3}
            styleName="body-2-bold"
            styles={{ color: theme.color.gray800 }}
          >
            Rules
          </Text>

          {selectedEnsembleDefRules.map((rule, index) => {
            const isFirst = index === 0;
            return (
              <Flex
                flexDirection="column"
                key={`selected-ensemble-def-rule-${index}`}
                mt={isFirst ? 2 : 3}
                pt={2}
                hasBorderTop={true}
              >
                <Flex mt={1}>
                  <Text styleName="body-3" styles={{ width: META_LABEL_WIDTH }}>
                    Statistics path
                  </Text>
                  <Text
                    styleName="code"
                    styles={{
                      fontSize: theme.ui2Typography.fontSizeMeta1,
                      lineHeight: theme.ui2Typography.lineHeightMeta1,
                      wordBreak: "break-all",
                    }}
                  >
                    {rule.statistics_path}
                  </Text>
                </Flex>
                <Flex mt={1}>
                  <Text styleName="body-3" styles={{ width: META_LABEL_WIDTH }}>
                    Objective
                  </Text>
                  <Text
                    styleName="code"
                    styles={{
                      fontSize: theme.ui2Typography.fontSizeMeta1,
                      lineHeight: theme.ui2Typography.lineHeightMeta1,
                    }}
                  >
                    {rule.objective}
                  </Text>
                </Flex>
                <Flex mt={1}>
                  <Text styleName="body-3" styles={{ width: META_LABEL_WIDTH }}>
                    Tolerance
                  </Text>
                  <Text
                    styleName="code"
                    styles={{
                      fontSize: theme.ui2Typography.fontSizeMeta1,
                      lineHeight: theme.ui2Typography.lineHeightMeta1,
                    }}
                  >
                    {rule.tolerance.type}{" "}
                    <Text
                      as="span"
                      ml={2}
                      styleName="code"
                      styles={{
                        fontSize: theme.ui2Typography.fontSizeMeta1,
                        lineHeight: theme.ui2Typography.lineHeightMeta1,
                      }}
                    >
                      {rule.tolerance.value}
                    </Text>
                  </Text>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      )}
    </ControlPanel>
  );
};
export default EnsembleDefSelection;
