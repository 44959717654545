const nextmvBlogRSSFeedItems = [
  `<item><title>Simulate “what if” questions for decision models with scenario testing and Nextmv</title><link>https://www.nextmv.io/blog/simulate-what-if-questions-for-decision-models-with-scenario-testing-and-nextmv</link><guid>https://www.nextmv.io/blog/simulate-what-if-questions-for-decision-models-with-scenario-testing-and-nextmv</guid><description>What if order volume increases 4x? What if I changed shift length? What’s the best model formulation? Efficiently play out different scenarios under realistic conditions before commiting to a plan using Nextmv’s scenario testing capabilities.</description><pubDate>Tue, 20 Aug 2024 12:00:00 GMT</pubDate></item>`,
  `<item><title>The sushi is ready. How do I deliver it? A look at the behind-the-scenes logistics.</title><link>https://www.nextmv.io/blog/the-sushi-is-ready-how-do-i-deliver-it-a-look-at-the-behind-the-scenes-logistics</link><guid>https://www.nextmv.io/blog/the-sushi-is-ready-how-do-i-deliver-it-a-look-at-the-behind-the-scenes-logistics</guid><description>We examine the value of treating decision models as engineered software components and how to approach decision modeling with an adaptable process.</description><pubDate>Thu, 15 Aug 2024 15:00:00 GMT</pubDate></item>`,
  `<item><title>Bring your custom Python decision model to Nextmv: Build, test, deploy</title><link>https://www.nextmv.io/blog/bring-your-custom-python-decision-model-to-nextmv-build-test-deploy</link><guid>https://www.nextmv.io/blog/bring-your-custom-python-decision-model-to-nextmv-build-test-deploy</guid><description>Accelerate development of your Python decision models — from completely custom models to those built using popular modeling tools — with features for testing, deploying, managing, and collaborating.</description><pubDate>Thu, 08 Aug 2024 15:00:00 GMT</pubDate></item>`,
  `<item><title>What is DecisionOps and why does it matter?</title><link>https://www.nextmv.io/blog/what-is-decisionops-and-why-does-it-matter</link><guid>https://www.nextmv.io/blog/what-is-decisionops-and-why-does-it-matter</guid><description>The ops-ification of disciplines such as software development, machine learning, and security aims to increase efficiency and reduce risk. For decision science and operations research — a discipline built on efficiency — it’s no different. </description><pubDate>Wed, 31 Jul 2024 14:07:00 GMT</pubDate></item>`,
  `<item><title>Nextmv HiGHSpy decision app: Build, test, and deploy Python MIP models faster</title><link>https://www.nextmv.io/blog/nextmv-highspy-decision-app-build-test-and-deploy-mip-models-faster</link><guid>https://www.nextmv.io/blog/nextmv-highspy-decision-app-build-test-and-deploy-mip-models-faster</guid><description>If you’re solving mixed-integer programming problems in Python, the latest Nextmv app will accelerate your development. Deploy, test, manage, and collaborate on your HiGHSpy model with our DecisionOps platform.</description><pubDate>Mon, 29 Jul 2024 13:08:00 GMT</pubDate></item>`,
];

const nextmvBlogRSSFeedHeader = `<?xml version="1.0" encoding="utf-8"?><rss version="2.0" xmlns:atom="http://www.w3.org/2005/Atom" xmlns:media="http://search.yahoo.com/mrss/"><channel><title>Nextmv Blog</title><link>https://www.nextmv.io/blog</link><description>The latest and greatest about decision optimization from Nextmv.</description><pubDate>Tue, 20 Aug 2024 12:00:00 GMT</pubDate><atom:link href="https://www.nextmv.io/blog/rss.xml" rel="self" type="application/rss+xml"/>`;
const nextmvBlogRSSFeedFooter = `</channel></rss>`;

export const nextmvBlogPosts = `${nextmvBlogRSSFeedHeader}${nextmvBlogRSSFeedItems.join(
  ""
)}${nextmvBlogRSSFeedFooter}`;
