import styled from "@emotion/styled";

import { filterProps } from "../../utils/filterProps";

type CustomChartStyleRulesProps = {
  yAxisWidth: number;
};

export const CustomChartStyleRules = styled("div", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<CustomChartStyleRulesProps>`
  /*
    removes the extra top and bottom horizontal line of the
    cartesian grid that does not correspond with any value,
    which is added when you add a top/bottom padding to the
    YAxis component
  */
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-of-type,
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-of-type {
    stroke-opacity: 0;
  }
  /*
    Note:
    recharts is inserting an extra line in the stacked bar
    chart which is removed by the style rule below. However,
    that rule also then removes the top y-axis value line for
    the line chart; so it is guarded with a chart-specific
    classname on this styled component.
  */
  &.chart-style-solution-value-function,
  &.chart-style-solution-value-fleet {
    .recharts-wrapper
      .recharts-cartesian-grid-horizontal
      line:nth-last-of-type(-n + 2) {
      stroke-opacity: 0;
    }
  }

  /*
    sets the default bar opacity to 0.75 and 1.0 on hover

  .recharts-bar-rectangle path {
    opacity: 0.75;

    &:hover, &:focus {
      opacity: 1.0;
    }
  }*/

  /* enable overflow scroll on custom tooltips */
  .recharts-tooltip-wrapper {
    pointer-events: auto !important;
  }
`;

export const ChartPlaceholder = styled("div", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})`
  width: 100%;
  padding-bottom: 40%;
  position: relative;
  background-color: ${({ theme }) => theme.color.gray200};
`;
