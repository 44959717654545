import * as React from "react";

import BaseAvatar, { AvatarProps } from "./BaseAvatar";

const AvatarAppVersion = ({ size }: AvatarProps) => {
  return (
    <BaseAvatar {...{ size }} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.4C14.4301 16.4 16.4 14.4301 16.4 12C16.4 9.56995 14.4301 7.6 12 7.6C9.56995 7.6 7.6 9.56995 7.6 12C7.6 14.4301 9.56995 16.4 12 16.4ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z"
        fill="#F7B100"
      />
    </BaseAvatar>
  );
};

export default AvatarAppVersion;
