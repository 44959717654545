import React from "react";
import { useTheme } from "@emotion/react";

import Flex from "../../components/Flex";
import Text from "../Text";
import Tooltip from "../Tooltip";

import { StyledPanel } from "./Panel.styled";
import { PanelProps, PanelStylePresets } from "./Panel.types";

const Panel = ({
  rows,
  preset,
  sectionTitle,
  m,
  mt,
  mr,
  mb,
  ml,
}: PanelProps) => {
  const theme = useTheme();

  const getTooltipColor = (preset?: PanelStylePresets) => {
    switch (preset) {
      case "config":
        return "4740cabf";
      case "instance":
        return "#bd8800bf";
      case "rules":
        return "#60696bbf";
      default:
        return theme.color.gray600;
    }
  };

  return (
    <StyledPanel {...{ m, mt, mr, mb, ml }} preset={preset}>
      {sectionTitle && (
        <Text
          as="h3"
          mt={-1}
          mb={2}
          styleName="meta-1-bold"
          styles={{ color: theme.color.gray800 }}
        >
          {sectionTitle}
        </Text>
      )}

      {rows &&
        rows.map((row, index) => {
          const { render, tooltipContent } = row;
          return (
            <Flex
              key={`panel-row-${index}`}
              mt={index === 0 ? 0 : 1}
              width="100%"
              alignItems="center"
            >
              {render}
              {tooltipContent && (
                <Tooltip ml={2} hexColorString={getTooltipColor(preset)}>
                  {tooltipContent}
                </Tooltip>
              )}
            </Flex>
          );
        })}
    </StyledPanel>
  );
};

export default Panel;
