import React from "react";
import { useTheme } from "@emotion/react";

import { EnsembleRules as EnsembleRulesType } from "../../../../../api/core/controlPlane.types";
import Box from "../../../../../components/Box";
import ControlPanel from "../../../../../components/ControlPanel";
import { renderEnsembleRuleDatum } from "../utils/renderEnsembleRuleDatum";

type EnsembleRulesProps = {
  rules: EnsembleRulesType;
  isExpanded?: boolean;
  sectionTitle?: string;
};

const EnsembleRules = ({ rules }: EnsembleRulesProps) => {
  const theme = useTheme();

  return (
    <>
      {rules.map((ensembleRule, index) => {
        const isFirst = index === 0;

        const {
          id: ruleId,
          index: ruleIndex,
          statistics_path: statisticsPath,
          objective,
          tolerance,
        } = ensembleRule;

        return (
          <ControlPanel
            key={ruleId}
            headerTitle={ruleId}
            isOpen
            hasCustomContentWrapper
            hasNoBorder={index === 0}
            stylesDetails={{
              marginTop: isFirst ? theme.spacing["-s4"] : 0,
              paddingRight: 0,
              paddingLeft: 0,
            }}
          >
            <Box ml={1} pl={7}>
              {renderEnsembleRuleDatum("Statistics path", statisticsPath, true)}
              {renderEnsembleRuleDatum("Objective", objective)}
              {renderEnsembleRuleDatum(
                "Tolerance",
                `${tolerance.type}, ${tolerance.value}`
              )}
              {renderEnsembleRuleDatum("Index", `${ruleIndex}`)}
            </Box>
          </ControlPanel>
        );
      })}
    </>
  );
};

export default EnsembleRules;
