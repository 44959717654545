import React, { useMemo } from "react";

import { EditorOnChange } from "../../pages/App/subpages/NewAppRun/hooks/useCustomInput";
import { getDataStructuredForEditor } from "../../utils/inputHelpers";
import Editor from "../Editor/Editor.component";

type InputEditorProps = {
  input: any;
  isReadOnly?: boolean;
  onInputChange?: EditorOnChange;
};

const InputEditor = ({
  input,
  isReadOnly,
  onInputChange,
}: InputEditorProps) => {
  const formattedInput = useMemo(() => {
    return getDataStructuredForEditor(input);
  }, [input]);

  return (
    <Editor
      content={formattedInput}
      readOnly={isReadOnly}
      // @ts-ignore
      onChange={onInputChange}
    />
  );
};

export default React.memo(InputEditor);
