import React from "react";

import Button2 from "../../components/Button2";
import Flex from "../../components/Flex";
import { rem } from "../../utils/tools";

const GoogleIcon = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.64 19.2042C27.64 18.566 27.5827 17.9524 27.4764 17.3633H19V20.8446H23.8436C23.635 21.9696 23.0009 22.9228 22.0477 23.561V25.8192H24.9564C26.6582 24.2524 27.64 21.9451 27.64 19.2042Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 28C21.43 28 23.4673 27.1941 24.9564 25.8195L22.0477 23.5613C21.2418 24.1013 20.2109 24.4204 19 24.4204C16.6559 24.4204 14.6718 22.8372 13.9641 20.71H10.9573V23.0418C12.4382 25.9831 15.4818 28 19 28Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9641 20.7098C13.7841 20.1698 13.6818 19.593 13.6818 18.9998C13.6818 18.4066 13.7841 17.8298 13.9641 17.2898V14.958H10.9573C10.3477 16.173 10 17.5476 10 18.9998C10 20.4521 10.3477 21.8266 10.9573 23.0416L13.9641 20.7098Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 13.5795C20.3214 13.5795 21.5077 14.0336 22.4405 14.9255L25.0218 12.3441C23.4632 10.8918 21.4259 10 19 10C15.4818 10 12.4382 12.0168 10.9573 14.9582L13.9641 17.29C14.6718 15.1627 16.6559 13.5795 19 13.5795Z"
        fill="#EA4335"
      />
    </svg>
  );
};

type GoogleButtonProps = {
  label?: string;
  name?: string;
  onClick: any;
};

const GoogleButton = ({ label, name, onClick }: GoogleButtonProps) => {
  return (
    <Button2
      testId={name}
      size="large"
      type="outline-quiet"
      icon={
        <Flex ml={-1} mr={-1}>
          <GoogleIcon />
        </Flex>
      }
      name={name}
      onClick={onClick}
      label={label}
      styles={{
        minHeight: rem(44),
      }}
    />
  );
};

export default GoogleButton;
