import {
  AppEntityEndpoints,
  WebhookEntityEndpoints,
} from "../../../api/core/controlPlane.types";
import {
  ENDPOINT_EXPERIMENTS_INPUTSETS,
  ENDPOINT_EXPERIMENTS_SHADOW,
  ENDPOINT_EXPERIMENTS_SWITCHBACK,
  ENDPOINT_INPUTS,
  ENDPOINT_INSTANCES,
  ENDPOINT_RUNS,
  ENDPOINT_VERSIONS,
} from "../../../config/endpoints";

export const getIsDeleteSupported = (
  endpoint: keyof AppEntityEndpoints | keyof WebhookEntityEndpoints
): boolean => {
  if (
    endpoint === ENDPOINT_INSTANCES ||
    endpoint === ENDPOINT_VERSIONS ||
    endpoint === ENDPOINT_INPUTS ||
    endpoint === ENDPOINT_RUNS ||
    endpoint === ENDPOINT_EXPERIMENTS_INPUTSETS ||
    endpoint === ENDPOINT_EXPERIMENTS_SHADOW ||
    endpoint === ENDPOINT_EXPERIMENTS_SWITCHBACK
  ) {
    return false;
  }
  return true;
};
