import * as React from "react";
import { ThemeProvider } from "@emotion/react";

import theme from "../theme";

const CustomThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const thisTheme = theme();
  return <ThemeProvider theme={thisTheme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
