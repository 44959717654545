import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconError = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99985 0.777039C4.01087 0.777039 0.777161 4.01075 0.777161 7.99973C0.777161 11.9887 4.01087 15.2224 7.99985 15.2224C11.9888 15.2224 15.2225 11.9887 15.2225 7.99973C15.2225 4.01075 11.9888 0.777039 7.99985 0.777039ZM2.10716 7.99973C2.10716 4.74529 4.74541 2.10704 7.99985 2.10704C11.2543 2.10704 13.8925 4.74529 13.8925 7.99973C13.8925 11.2541 11.2543 13.8924 7.99985 13.8924C4.74541 13.8924 2.10716 11.2541 2.10716 7.99973ZM7.13212 4.50186C7.11319 4.00947 7.50723 3.59998 7.99998 3.59998C8.49274 3.59998 8.88678 4.00947 8.86784 4.50186L8.7037 8.76948C8.68916 9.14761 8.37839 9.44665 7.99998 9.44665C7.62157 9.44665 7.31081 9.14761 7.29626 8.76948L7.13212 4.50186ZM7.04987 11.3C7.04987 10.7753 7.47519 10.35 7.99987 10.35C8.52454 10.35 8.94987 10.7753 8.94987 11.3C8.94987 11.8246 8.52454 12.25 7.99987 12.25C7.47519 12.25 7.04987 11.8246 7.04987 11.3Z"
      />
    </BaseIcon>
  );
};

export default IconError;
