import React from "react";

import { UseMapStateReturnValue } from "../../../../../../components/Map/hooks/useMapState";
import {
  SolutionRoute,
  VisibleVehicleRouteIds,
} from "../../../../../../components/Map/Map.types";
import Notification from "../../../../../../components/Notification";

import VehicleDetails from "./components/VehicleDetails";
import { getAllTogglePosition } from "./utils/vehicleControls";
import {
  StyledVehicleControlsInnerScroll,
  StyledVehicleDetails,
} from "./VehicleControls.styled";

type VehicleControlsProps = {
  mapState: UseMapStateReturnValue;
  errorMessageFailedRoutes: string;
  routeSet: SolutionRoute;
};

const VehicleControls = ({
  mapState,
  errorMessageFailedRoutes,
  routeSet,
}: VehicleControlsProps) => {
  const {
    displayVehiclesNoRoutes,
    focusedRouteId,
    setFocusedRouteId,
    visibleVehicleRoutesById,
    setVisibleVehicleRoutesById,
  } = mapState;

  const [isAllToggled, setIsAllToggled] = React.useState(true);

  const routesById = routeSet?.routesById;

  const updateAllToggle = (
    newVisibleVehicleRoutesById: VisibleVehicleRouteIds
  ) => {
    setIsAllToggled(
      getAllTogglePosition(newVisibleVehicleRoutesById, isAllToggled)
    );
  };

  const handleSetVisibleVehicleRoutesById = (
    newVisibleVehicleRoutesById: VisibleVehicleRouteIds
  ) => {
    updateAllToggle(newVisibleVehicleRoutesById);
    setVisibleVehicleRoutesById(newVisibleVehicleRoutesById);
  };

  return (
    <StyledVehicleDetails data-testid="results-vehicle-details" pb={6} pl={6}>
      {errorMessageFailedRoutes && (
        <Notification
          type="error"
          mr={4}
          mb={4}
          message={errorMessageFailedRoutes}
        />
      )}

      <StyledVehicleControlsInnerScroll>
        <VehicleDetails
          routesById={routesById}
          routeSet={routeSet}
          focusedRouteId={focusedRouteId}
          setFocusedRouteId={setFocusedRouteId}
          displayVehiclesNoRoutes={displayVehiclesNoRoutes}
          visibleVehicleRoutesById={visibleVehicleRoutesById}
          setVisibleVehicleRoutesById={handleSetVisibleVehicleRoutesById}
        />
      </StyledVehicleControlsInnerScroll>
    </StyledVehicleDetails>
  );
};

export default VehicleControls;
