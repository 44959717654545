import { useMemo, useState } from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";

import { RunsDatum } from "../../api/core/controlPlane.types";
import { HeaderObj } from "../../pages/App/subpages/RunHistory/RunHistory.types";
import { roundNumber } from "../../utils/tools";
import { StyledTableContainer } from "../Table2/Table2.styled";

import { StyledPivotTable } from "./PivotTableRuns.styled";
import { PivotTableRunsProps } from "./PivotTableRuns.types";

import "react-pivottable/pivottable.css";

const PlotlyRenderers = createPlotlyRenderers(Plot);

const categoricalKeys = [
  "scenario_id",
  "option_set",
  "repetition",
  "status_v2",
  "application_id",
  "application_instance_id",
  "application_version_id",
  "execution_class",
];

const getIdsFromHeaders = (headers: HeaderObj[]) => {
  return headers.map((header) => header.id);
};

const PivotTableRuns = ({
  runs,
  scenarioConfigOptionsHeaders,
  statisticsHeaders,
}: PivotTableRunsProps) => {
  const [tableProps, setTableProps] = useState<undefined | {}>();

  const scenarioConfigOptionsIds = getIdsFromHeaders(
    scenarioConfigOptionsHeaders
  );
  const statIndicatorsIds = getIdsFromHeaders(statisticsHeaders);

  const pivotTableData: any[][] = useMemo(() => {
    if (!runs || !runs.length) {
      return [];
    }

    const numericalKeys = [...scenarioConfigOptionsIds, ...statIndicatorsIds];

    return runs.reduce((acc: any[][], run, index) => {
      // the first row (array) of the pivot data array is the header row
      if (index === 0) {
        acc.push([...categoricalKeys, ...numericalKeys]);
      }

      // then for every run add the values to match headers in first row
      acc.push([
        // Nextmv-fixed categories are added first (e.g. version ID)
        ...categoricalKeys.map((ck) => {
          if (run[ck as keyof RunsDatum] === undefined) {
            return "";
          }
          return run[ck as keyof RunsDatum];
        }),
        // configuration options are added next
        ...scenarioConfigOptionsIds.map((configOptionId) => {
          const configOptionValue = run?.options && run.options[configOptionId];
          return configOptionValue || "";
        }),
        // run statistics are added at the end
        ...(run.statistics?.indicators
          ? statIndicatorsIds.map((statIndicatorId) => {
              if (statIndicatorId === "statistics-status") {
                return run.statistics?.status || "";
              }
              const foundIndicator = run.statistics?.indicators?.find(
                (indicator) => indicator.name === statIndicatorId
              );

              if (foundIndicator) {
                return roundNumber(foundIndicator.value, 2);
              }
              return "";
            })
          : []),
      ]);

      return acc;
    }, []);
  }, [runs, scenarioConfigOptionsIds, statIndicatorsIds]);

  return (
    <StyledPivotTable>
      <StyledTableContainer>
        <PivotTableUI
          data={pivotTableData}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          onChange={(props) => setTableProps(props)}
          cols={scenarioConfigOptionsIds}
          rows={["scenario_id", "option_set"]}
          aggregatorName="Average"
          vals={["result.value"]}
          {...(tableProps &&
            typeof tableProps === "object" &&
            !Array.isArray(tableProps) && { ...tableProps })}
        />
      </StyledTableContainer>
    </StyledPivotTable>
  );
};

export default PivotTableRuns;
