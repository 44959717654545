import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";

import { getOrgRunCount } from "../../../../api/core/controlPlane";
import { OrganizationRunCount } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Box from "../../../../components/Box";
import Flex from "../../../../components/Flex";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import Select from "../../../../components/Select";
import StandardError from "../../../../components/StandardError";
import Text from "../../../../components/Text";
import Tooltip from "../../../../components/Tooltip";
import NotFoundError from "../../../../customErrors/NotFoundError";
import { formatDate } from "../../../../utils/format";
import { rem } from "../../../../utils/tools";

import {
  emptyRunCount,
  getRunCountSelectOptions,
  isEmptyRunCount,
} from "./utils/runCounts";
import {
  TeamRunCountMonthOption,
  TeamRunCountSelectOptions,
} from "./Usage.types";

const pageTitle = "Usage";

const Usage = () => {
  const [{ id: teamId }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();

  const [teamRunCount, setTeamRunCount] = useState<OrganizationRunCount>();
  const [teamRunCountSelectOptions, setTeamRunCountSelectOptions] =
    useState<TeamRunCountSelectOptions>();
  const [selectedMonthOption, setSelectedMonthOption] =
    useState<TeamRunCountMonthOption>();
  const [error, setError] = useState("");
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setMetaTitle(pageTitle);

    const loadTeamRunCount = async () => {
      setError("");
      try {
        const resp = await getOrgRunCount(teamId || "");
        setTeamRunCount(resp);
        setTeamRunCountSelectOptions(getRunCountSelectOptions(resp?.metrics));
        setPageLoading(false);
      } catch (e) {
        setPageLoading(false);

        // set empty run response, 404 equivalent to 0 runs for Org.
        if (e instanceof NotFoundError) {
          setTeamRunCount(emptyRunCount);
          setTeamRunCountSelectOptions(undefined);
          setSelectedMonthOption(undefined);
          return;
        }
        console.error(e);
        setError((e as Error).message);
      }
    };

    if (!selectedMonthOption && teamRunCountSelectOptions) {
      setSelectedMonthOption(teamRunCountSelectOptions[0]);
    }

    !teamRunCount && loadTeamRunCount();
  }, [
    selectedMonthOption,
    setMetaTitle,
    teamId,
    teamRunCount,
    teamRunCountSelectOptions,
  ]);

  if (error) return <StandardError errorMessage={error} />;
  if (pageLoading)
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
        }}
      />

      <Box width="100%" mt={3} pb={6}>
        {!isEmptyRunCount(teamRunCount) ? (
          teamRunCount &&
          teamRunCountSelectOptions &&
          selectedMonthOption && (
            <>
              <Box maxWidth={rem(240)}>
                <Select
                  mt={2}
                  placeholder="Select a usage monitored month"
                  options={teamRunCountSelectOptions}
                  value={selectedMonthOption}
                  onChange={(selection: TeamRunCountMonthOption) => {
                    setSelectedMonthOption(selection);
                  }}
                />
              </Box>

              <Flex mt={3} alignItems="center">
                <Text styleName="body-2">
                  Run count:{" "}
                  <Text
                    as="strong"
                    styleName="body-2-bold"
                    styles={{ color: theme.color.gray800 }}
                  >
                    {teamRunCount.metrics[selectedMonthOption.value].count}
                  </Text>
                </Text>

                <Tooltip ml={1}>
                  Run count is the number of successful API calls.
                </Tooltip>
              </Flex>

              <Text
                mt={rem(2)}
                styleName="body-3"
                styles={{ color: theme.color.gray600 }}
              >
                Last updated: {formatDate(teamRunCount.updated_at)}
              </Text>
            </>
          )
        ) : (
          <Text
            pt={1}
            styleName="body-2"
            styles={{ color: theme.color.gray600 }}
          >
            No runs have been made.
          </Text>
        )}
      </Box>
    </>
  );
};

export default Usage;
