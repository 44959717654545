import React from "react";
import { useTheme } from "@emotion/react";

import { ExecutionClassType } from "../../../api/core/dataPlane.types";
import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import Tag from "../../../components/Tag";
import Text from "../../../components/Text";
import { EC_DEFAULT } from "../../../config/apps";
import { rem } from "../../../utils/tools";
import { getExecutionClassDescription } from "../utils/instances";

type ExecutionClassProps = {
  executionClass: ExecutionClassType;
  isAbbreviated?: boolean;
  isSmall?: boolean;
};

const ExecutionClass = ({
  executionClass,
  isAbbreviated,
  isSmall,
}: ExecutionClassProps) => {
  const theme = useTheme();

  const isDefaultExecutionClass = executionClass === EC_DEFAULT;

  return (
    <Box>
      <Flex>
        <Tag
          mb={-1}
          ml={isSmall ? -1 : rem(-6)}
          type="id"
          size={isSmall ? "small" : "default"}
          label={executionClass}
          textColor={
            isDefaultExecutionClass
              ? isAbbreviated
                ? theme.color.gray700
                : theme.color.gray800
              : theme.color.green800
          }
          backgroundColor={
            isDefaultExecutionClass ? theme.color.gray100 : theme.color.green100
          }
          title={getExecutionClassDescription(executionClass)}
        />
      </Flex>
      {!isAbbreviated && (
        <Text styleName="body-3" mt={1}>
          {getExecutionClassDescription(executionClass)}
        </Text>
      )}
    </Box>
  );
};

export default ExecutionClass;
