import * as React from "react";

import BaseAvatar, { AvatarProps } from "./BaseAvatar";

const AvatarApp = ({ avatarColor, size }: AvatarProps) => {
  return (
    <BaseAvatar {...{ size }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.75 24C45.75 36.0122 36.0122 45.75 24 45.75C11.9878 45.75 2.25 36.0122 2.25 24C2.25 11.9878 11.9878 2.25 24 2.25C36.0122 2.25 45.75 11.9878 45.75 24ZM48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM36.4757 16.0806L24.4757 10.4806C24.1742 10.3398 23.8258 10.3398 23.5243 10.4806L11.5243 16.0806C11.1282 16.2654 10.875 16.6629 10.875 17.1V30.9C10.875 31.3371 11.1282 31.7346 11.5243 31.9195L23.5243 37.5195C23.8258 37.6602 24.1742 37.6602 24.4757 37.5195L36.4757 31.9195C36.8718 31.7346 37.125 31.3371 37.125 30.9V17.1C37.125 16.6629 36.8718 16.2654 36.4757 16.0806ZM22.875 22.9443L13.125 18.8005V30.1836L22.875 34.7336V22.9443ZM34.875 30.1836L25.125 34.7336V22.9443L34.875 18.8005V30.1836ZM33.2368 17.052L24 20.9776L14.7632 17.052L24 12.7415L33.2368 17.052Z"
        fill={avatarColor || "#F7B100"}
      />
    </BaseAvatar>
  );
};

export default AvatarApp;
