import React from "react";

import Box from "../../../components/Box";
import Text from "../../../components/Text";
import { rem } from "../../../utils/tools";

const ZeroInvites = ({
  accountId,
}: {
  accountId: string | null | undefined;
}) => {
  return (
    <Box mt={4} maxWidth={rem(540)}>
      <Text styleName="body-1">{`You have no open invites.${
        !accountId
          ? " Please contact your system administrator for assistance."
          : " You can navigate between teams by using the dropdown in the top right."
      }`}</Text>
    </Box>
  );
};

export default ZeroInvites;
