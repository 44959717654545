import styled from "@emotion/styled";

import Flex from "../../components/Flex";

import { StyledActionRowFlexProps } from "./ActionRow.types";

export const StyledActionRowFlex = styled(Flex)<StyledActionRowFlexProps>`
  position: relative;
  ${(props) =>
    props.isDisabled &&
    `
      h3 {
        color: ${props.theme.color.gray600};
      }
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: ${props.theme.color.white};
        opacity: 0.5;
      }
    `}
`;
