import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { useUser } from "../../AuthProvider";
import Box from "../../components/Box";
import Flex from "../../components/Flex";
import Header from "../../components/Header";
import Layout, { useMetaTitle } from "../../components/Layout";
import Swag from "../../components/Swag";
import Text from "../../components/Text";
import spec from "../../spec/open-api-spec.json";
import { userHasAccessToAction } from "../../utils/rbac_utils";
import { ActionGroups } from "../../utils/rbac_utils/types";
import { rem } from "../../utils/tools";
import AccessDenied from "../AccessDenied";

const BreakWord = styled.span`
  word-break: break-all;
`;

const pageTitle = "API Explorer";

const APIExplorerContent = () => {
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  return (
    <>
      <Header configPageTitle={{ label: pageTitle }} />

      <Flex
        width="100%"
        flexDirection={["column", "column", "column", "row"]}
        alignItems="flex-start"
        pt={5}
      >
        <Box
          width={[1 / 1, 1 / 1, 1 / 1, 1 / 2]}
          maxWidth={rem(720)}
          mr={[0, 0, 0, 7]}
        >
          <Text
            as="h1"
            styleName="header-2"
            styles={{ color: theme.color.gray800 }}
          >
            Explore Nextmv Cloud&#8217;s routing API
          </Text>

          <Box mt={4}>
            <Text styleName="body-1" hasLink>
              You will need your <Link to="/account/api-keys">API key</Link>{" "}
              before using the interactive API documentation below. Once you
              have your API key, click the green &#8220;Authorize&#8221; button
              in the Swagger section below and then in the modal, paste your API
              key in the value field, click &#8220;Authorize&#8221; and then
              &#8220;Close&#8221;.
            </Text>
            <Text mt={3} styleName="body-1">
              You can then explore the endpoints below by using the &#8220;Try
              it out&#8221; and &#8220;Execute&#8221; buttons in each section
              (expand each row to see the available actions). Make sure to note
              any return data from API calls that may be needed for subsequent
              endpoints (e.g.{" "}
              <Text
                as="code"
                styleName="code-bold"
                styles={{
                  paddingRight: theme.spacing.s1,
                  paddingLeft: theme.spacing.s1,
                  color: theme.color.purple800,
                  backgroundColor: theme.color.purple100,
                }}
              >
                runId
              </Text>
              ).
            </Text>
          </Box>

          <Box mt={[5, 5, 6]}>
            <Text
              as="h3"
              styleName="header-2"
              styles={{ color: theme.color.gray800 }}
            >
              API Endpoint Base URL
            </Text>

            <Text
              as="code"
              styleName="code-bold"
              styles={{
                display: "inline-block",
                fontSize: rem(15),
                lineHeight: rem(18),
                color: theme.color.purple800,
                borderRadius: theme.border.radiusSmall,
                backgroundColor: theme.color.purple100,
              }}
              mt={1}
              ml={-1}
              pt={1}
              pr={2}
              pb={1}
              pl={2}
            >
              <BreakWord>https://api.cloud.nextmv.io/</BreakWord>
            </Text>
          </Box>
        </Box>

        <Box
          hasBorder
          hasBorderWidthThick
          isRounded
          width={[1 / 1, 1 / 1, 1 / 1, 1 / 2]}
          mt={[7, 7, 7, 0]}
          maxWidth={rem(480)}
          p={6}
          pt={5}
        >
          <Text
            as="h3"
            styleName="header-2"
            styles={{ color: theme.color.gray800 }}
          >
            Looking for your API key?
          </Text>
          <Text mt={2} styleName="body-1" hasLink>
            Visit the <Link to="/account/api-keys">API key page</Link> in the
            Account section to find your API key.
          </Text>
        </Box>
      </Flex>

      <Box mt={8}>
        <Swag apiSpec={spec} apiKey="" />
      </Box>
    </>
  );
};

const APIExplorer = () => {
  const [{ roles }] = useUser();

  // Users should not be able to see this if they don't have
  // access to create API keys
  const userCanView = userHasAccessToAction(
    roles,
    ActionGroups.SecurityOperator,
    {}
  );

  if (!userCanView) {
    return <AccessDenied />;
  }
  return (
    <Layout configPageTitle={{ label: pageTitle }}>
      <APIExplorerContent />
    </Layout>
  );
};

export default APIExplorer;
