import React, { useEffect } from "react";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useTheme } from "@emotion/react";
import { nanoid } from "nanoid";

import {
  EnsembleRuleObjective,
  EnsembleRuleToleranceType,
} from "../../../api/core/controlPlane.types";
import Box from "../../../components/Box";
import Button2 from "../../../components/Button2";
import ControlPanel from "../../../components/ControlPanel";
import Flex from "../../../components/Flex";
import Input from "../../../components/Input";
import Panel from "../../../components/Panel";
import Select from "../../../components/Select";
import { SelectOption } from "../../../components/Select/Select.types";
import Sortable from "../../../components/Sortable";
import {
  DEFAULT_ENSEMBLE_RULE_ID_LABEL,
  DEFAULT_ENSEMBLE_RULE_OBJECTIVE,
  DEFAULT_ENSEMBLE_RULE_TOLERANCE_TYPE,
  DEFAULT_ENSEMBLE_RULE_TOLERANCE_VALUE,
  ENSEMBLE_RULE_OBJECTIVE_MAXIMIZE,
  ENSEMBLE_RULE_OBJECTIVE_MINIMIZE,
  ENSEMBLE_RULE_TOLERANCE_TYPE_ABSOLUTE,
  ENSEMBLE_RULE_TOLERANCE_TYPE_RELATIVE,
} from "../../../config/apps";
import useStandardInputs from "../../../hooks/useStandardInputs";
import { IconX } from "../../../icons";
import { capitalizeFirstLetter } from "../../../utils/format";
import { parseInputTypeNumberDisplay } from "../../../utils/inputHelpers";
import { checkIdForError, getIdSlug } from "../../../utils/systemIds";
import { rem } from "../../../utils/tools";
import { PendingEnsembleRule } from "../App.types";
import { ensembleDefinitionTooltips } from "../data/appTooltips";

type AddEnsembleRulesProps = {
  pendingRules: PendingEnsembleRule[];
  setPendingRules: React.Dispatch<React.SetStateAction<PendingEnsembleRule[]>>;
};

const getEmptyRule = (pendingRulesLength: number): PendingEnsembleRule => {
  const randomId = nanoid();
  return {
    id: `rule-${randomId}`,
    ruleId: `${DEFAULT_ENSEMBLE_RULE_ID_LABEL}-${pendingRulesLength + 1}`,
    ruleIdError: null,
    objective: DEFAULT_ENSEMBLE_RULE_OBJECTIVE,
    statisticsPath: "",
    tolerance: {
      type: DEFAULT_ENSEMBLE_RULE_TOLERANCE_TYPE,
      value: DEFAULT_ENSEMBLE_RULE_TOLERANCE_VALUE,
    },
  };
};

const ruleObjectiveOptions = [
  {
    label: capitalizeFirstLetter(ENSEMBLE_RULE_OBJECTIVE_MINIMIZE),
    value: ENSEMBLE_RULE_OBJECTIVE_MINIMIZE,
  },
  {
    label: capitalizeFirstLetter(ENSEMBLE_RULE_OBJECTIVE_MAXIMIZE),
    value: ENSEMBLE_RULE_OBJECTIVE_MAXIMIZE,
  },
];

const ruleToleranceTypeOptions = [
  {
    label: capitalizeFirstLetter(ENSEMBLE_RULE_TOLERANCE_TYPE_ABSOLUTE),
    value: ENSEMBLE_RULE_TOLERANCE_TYPE_ABSOLUTE,
  },
  {
    label: capitalizeFirstLetter(ENSEMBLE_RULE_TOLERANCE_TYPE_RELATIVE),
    value: ENSEMBLE_RULE_TOLERANCE_TYPE_RELATIVE,
  },
];

const AddEnsembleRules = ({
  pendingRules,
  setPendingRules,
}: AddEnsembleRulesProps) => {
  const theme = useTheme();
  const { getMaxLength } = useStandardInputs();

  // load initial blank option set
  useEffect(() => {
    if (!pendingRules.length) {
      setPendingRules([getEmptyRule(0)]);
    }
  }, [pendingRules.length, setPendingRules]);

  const addEmptyRule = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setPendingRules((prevState) => {
      return [...prevState, getEmptyRule(prevState.length)];
    });

    return;
  };

  const removePendingRule = (
    e: {
      preventDefault: () => void;
      stopPropagation: () => void;
    },
    index: number
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setPendingRules((prevState) => {
      prevState.splice(index, 1);
      return [...prevState];
    });

    return;
  };

  const handlePendingStatisticsPathChange = (
    e: { target: { value: string } },
    index: number
  ) => {
    const pendingRule = pendingRules[index];
    pendingRule.statisticsPath = e?.target?.value;

    setPendingRules((prevState) => {
      prevState[index] = pendingRule;
      return [...prevState];
    });

    return;
  };

  const setPendingRuleObjective = (
    pendingRuleObjective: EnsembleRuleObjective,
    index: number
  ) => {
    const pendingRule = pendingRules[index];
    pendingRule.objective = pendingRuleObjective;

    setPendingRules((prevState) => {
      prevState[index] = pendingRule;
      return [...prevState];
    });

    return;
  };

  const setPendingRuleToleranceType = (
    type: EnsembleRuleToleranceType,
    index: number
  ) => {
    const pendingRule = pendingRules[index];
    pendingRule.tolerance.type = type;

    setPendingRules((prevState) => {
      prevState[index] = pendingRule;
      return [...prevState];
    });

    return;
  };
  const setPendingRuleToleranceValue = (value: number, index: number) => {
    const pendingRule = pendingRules[index];
    pendingRule.tolerance.value = Number(value);

    setPendingRules((prevState) => {
      prevState[index] = pendingRule;
      return [...prevState];
    });

    return;
  };

  const handlePendingRuleIdChange = (
    e: { target: { value: string } },
    index: number
  ) => {
    const pendingRule = pendingRules[index];
    if (!pendingRule) return;
    const pendingRuleId = getIdSlug(e?.target?.value);
    pendingRule.ruleId = pendingRuleId;
    pendingRule.ruleIdError = checkIdForError(pendingRuleId);

    setPendingRules((prevState) => {
      prevState[index] = pendingRule;
      return [...prevState];
    });

    return;
  };
  const handlePendingRuleIdOnBlur = (
    e: { target: { value: string } },
    index: number
  ) => {
    const pendingRule = pendingRules[index];
    if (!pendingRule) return;
    const pendingRuleId = getIdSlug(e?.target?.value);
    pendingRule.ruleId = pendingRuleId;
    pendingRule.ruleIdError = checkIdForError(pendingRuleId, true);

    setPendingRules((prevState) => {
      prevState[index] = pendingRule;
      return [...prevState];
    });

    return;
  };

  const renderPendingRules = () => {
    const pendingRulesLength = pendingRules.length;

    return pendingRules.map((pendingRule, pendingRuleIndex) => {
      const {
        id: immutableSortableRuleId,
        ruleId: pendingRuleId,
        ruleIdError: pendingRuleIdError,
        objective: pendingRuleObjective,
        statisticsPath: pendingStatisticsPath,
        tolerance: pendingRuleTolerance,
      } = pendingRule;

      const inputIdMaxLength = getMaxLength("id");

      return (
        <Sortable
          key={immutableSortableRuleId}
          id={immutableSortableRuleId}
          itemsLength={pendingRulesLength}
        >
          <ControlPanel
            isOpen
            hasNoBorder
            useNonNativeElements
            summaryNode={
              <Flex
                width="100%"
                ml={1}
                maxWidth={`calc(${rem(480)} + ${theme.spacing.s7})`}
                alignItems="flex-start"
                flexShrink={0}
              >
                <Box width="100%">
                  <Input
                    htmlType="text"
                    name={`${pendingRuleId}-id`}
                    testId={`${pendingRuleId}-id-input`}
                    placeholder="Rule ID"
                    maxLength={inputIdMaxLength}
                    value={pendingRuleId}
                    onChange={(e: { target: { value: string } }) => {
                      handlePendingRuleIdChange(e, pendingRuleIndex);
                      return;
                    }}
                    onBlur={(e: { target: { value: string } }) =>
                      handlePendingRuleIdOnBlur(e, pendingRuleIndex)
                    }
                    errorMessage={pendingRuleIdError}
                    errorMessageTestId={`${pendingRuleId}-id-input-error`}
                    isError={!!pendingRuleIdError}
                  />
                </Box>

                <Button2
                  mt={1}
                  isDisabled={pendingRules.length < 2}
                  type="text-quiet"
                  icon={
                    <IconX
                      iconColor={theme.color.gray500}
                      iconColorHover={theme.color.gray600}
                    />
                  }
                  onClick={(e: {
                    preventDefault: () => void;
                    stopPropagation: () => void;
                  }) => removePendingRule(e, pendingRuleIndex)}
                  styles={{
                    width: `${theme.spacing.s7} !important`,
                    flexShrink: 0,
                    position: "relative",
                    zIndex: 10,
                  }}
                />
              </Flex>
            }
            stylesDetails={{
              width: "100%",
              // max width calculation is:
              // left control icon
              // extra margin
              // standard width
              // button width
              maxWidth: `calc(
                ${theme.spacing.s7} +
                ${theme.spacing.s1} +
                ${rem(480)} +
                ${theme.spacing.s7}
              )`,
              paddingBottom: `0 !important`,
              paddingLeft: 0,
              paddingRight: 0,
              ".summary .details-control-icon": {
                top: rem(5),
                transform: "none",
              },
            }}
          >
            <Box mt={-1} width="100%" maxWidth={rem(480)}>
              <Panel
                preset="rules"
                rows={[
                  {
                    render: (
                      <Input
                        htmlType="text"
                        name={`${pendingRuleId}-statistics-path`}
                        testId={`${pendingRuleId}-statistics-path-input`}
                        placeholder="Statistics path"
                        maxLength={getMaxLength("id")}
                        value={pendingStatisticsPath}
                        onChange={(e: { target: { value: string } }) =>
                          handlePendingStatisticsPathChange(e, pendingRuleIndex)
                        }
                      />
                    ),
                    tooltipContent:
                      ensembleDefinitionTooltips.create.ruleStatisticsPath
                        .content,
                  },
                  {
                    render: (
                      <Select
                        testId="select-ensemble-rules-objective"
                        placeholder="Select objective"
                        options={ruleObjectiveOptions}
                        value={ruleObjectiveOptions.find(
                          (opt) => opt.value === pendingRuleObjective
                        )}
                        onChange={(selection: SelectOption) => {
                          setPendingRuleObjective(
                            selection.value as EnsembleRuleObjective,
                            pendingRuleIndex
                          );
                        }}
                      />
                    ),
                    tooltipContent:
                      ensembleDefinitionTooltips.create.ruleObjective.content,
                  },
                  {
                    render: (
                      <>
                        <Box width={[1 / 1, 1 / 2]} pr={rem(2)}>
                          <Select
                            testId="select-ensemble-rules-tolerance-type"
                            placeholder="Select tolerance type"
                            options={ruleToleranceTypeOptions}
                            value={ruleToleranceTypeOptions.find(
                              (opt) => opt.value === pendingRuleTolerance.type
                            )}
                            onChange={(selection: SelectOption) => {
                              setPendingRuleToleranceType(
                                selection.value as EnsembleRuleToleranceType,
                                pendingRuleIndex
                              );
                            }}
                          />
                        </Box>
                        <Box width={[1 / 1, 1 / 2]} pl={rem(2)}>
                          <Input
                            htmlType="number"
                            placeholder="Enter a number"
                            value={parseInputTypeNumberDisplay(
                              pendingRuleTolerance.value
                            )}
                            min={0}
                            onChange={(e: { target: { value: number } }) =>
                              setPendingRuleToleranceValue(
                                e.target.value,
                                pendingRuleIndex
                              )
                            }
                          />
                        </Box>
                      </>
                    ),
                    tooltipContent:
                      ensembleDefinitionTooltips.create.ruleTolerance.content,
                  },
                ]}
              />
            </Box>
          </ControlPanel>
        </Sortable>
      );
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const handleDragEnd = (e: { active: any; over: any }) => {
    const { active, over } = e;

    if (active.id !== over.id) {
      setPendingRules((items: PendingEnsembleRule[]) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
      modifiers={[restrictToParentElement]}
    >
      <SortableContext
        items={pendingRules}
        strategy={verticalListSortingStrategy}
      >
        {renderPendingRules()}

        <Box mt={5}>
          <Button2
            htmlType="button"
            type="outline"
            label="Add rule"
            onClick={(e: {
              preventDefault: () => void;
              stopPropagation: () => void;
            }) => addEmptyRule(e)}
          />
        </Box>
      </SortableContext>
    </DndContext>
  );
};

export default AddEnsembleRules;
