import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconLightningFill = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path d="M8.44402 6.64121L9.52395 -0.00012207L2.66681 9.35131H8.32666L7.23824 15.9999L14.0954 6.64121H8.44402Z" />
    </BaseIcon>
  );
};

export default IconLightningFill;
