import { useCallback, useState } from "react";

import {
  createWebhookEntity,
  deleteWebhookEntity,
  getWebhookEntity,
  updateWebhookEntity,
} from "../api/core/controlPlane";
import {
  CreateWebhookPayload,
  CreateWebhookPayloads,
  EntityErrorMessage,
  GetWebhookResponses,
  PutWebhookPayload,
  RestEditMethod,
  UpdateWebhookPayloads,
  WebhookEntityEndpoints,
} from "../api/core/controlPlane.types";
import { useUser } from "../AuthProvider";

const useManageWebhook = <
  WebhookEntityEndpoint extends keyof WebhookEntityEndpoints
>(
  endpoint?: WebhookEntityEndpoint
) => {
  const [{ id: accountId }] = useUser();

  const [webhookEntity, setWebhookEntity] = useState<
    GetWebhookResponses[WebhookEntityEndpoint] | null
  >(null);

  const [isWebhookEntityAdded, setIsWebhookEntityAdded] = useState(false);
  const [isWebhookEntityEdited, setIsWebhookEntityEdited] = useState(false);
  const [isWebhookEntityRemoved, setIsWebhookEntityRemoved] = useState(false);

  const [webhookEntityAddError, setWebhookEntityAddError] =
    useState<EntityErrorMessage>(null);
  const [webhookEntityEditError, setWebhookEntityEditError] =
    useState<EntityErrorMessage>(null);
  const [webhookEntityLoadError, setWebhookEntityLoadError] =
    useState<EntityErrorMessage>(null);
  const [webhookEntityRemoveError, setWebhookEntityRemoveError] =
    useState<EntityErrorMessage>(null);

  const addWebhookEntity = useCallback(
    async (
      webhookId: string = "",
      payload: CreateWebhookPayload,
      shouldReturnEntity: boolean = false
    ): Promise<void | GetWebhookResponses[WebhookEntityEndpoint]> => {
      if (!endpoint) {
        return;
      }
      try {
        const entity = await createWebhookEntity(accountId || "")(
          endpoint as keyof CreateWebhookPayloads,
          payload,
          shouldReturnEntity,
          webhookId
        );
        setWebhookEntityAddError(null);
        setIsWebhookEntityAdded(true);

        return shouldReturnEntity ? entity : undefined;
      } catch (error: any) {
        console.error(error);
        setWebhookEntityAddError(error.toString());
      }
    },
    [accountId, endpoint]
  );

  const editWebhookEntity = useCallback(
    async (
      webhookId: string,
      entityId: string = "",
      payload: PutWebhookPayload
    ) => {
      if (!endpoint) {
        return;
      }
      const updateMethod: RestEditMethod = "PUT";
      try {
        await updateWebhookEntity(accountId || "")(
          webhookId,
          endpoint as keyof UpdateWebhookPayloads,
          payload,
          updateMethod,
          entityId
        );
        setWebhookEntityEditError(null);
        setIsWebhookEntityEdited(true);
        return;
      } catch (error: any) {
        console.error(error);
        setWebhookEntityEditError(error.toString());
      }
    },
    [accountId, endpoint]
  );

  const loadWebhookEntity = useCallback(
    async (webhookId: string, entityId: string = "") => {
      if (!endpoint) {
        return;
      }
      try {
        const entityRes = await getWebhookEntity(accountId || "")(
          webhookId,
          endpoint,
          entityId
        );
        setWebhookEntityLoadError(null);
        setWebhookEntity(entityRes);
        return entityRes;
      } catch (error: any) {
        console.error(error);
        setWebhookEntityLoadError(error.toString());
      }
    },
    [accountId, endpoint]
  );

  const removeWebhookEntity = useCallback(
    async (webhookId: string, entityId: string = "") => {
      if (!endpoint) {
        return;
      }
      try {
        await deleteWebhookEntity(accountId || "")(
          webhookId,
          endpoint as keyof Omit<WebhookEntityEndpoints, "secret">,
          entityId
        );
        setWebhookEntityRemoveError(null);
        setIsWebhookEntityRemoved(true);
        return;
      } catch (error: any) {
        console.error(error);
        setWebhookEntityRemoveError(error.toString());
      }
    },
    [accountId, endpoint]
  );

  return {
    addWebhookEntity,
    editWebhookEntity,
    removeWebhookEntity,
    webhookEntityRemoveError,
    webhookEntity,
    webhookEntityAddError,
    webhookEntityEditError,
    webhookEntityLoadError,
    loadWebhookEntity,
    isWebhookEntityAdded,
    isWebhookEntityEdited,
    isWebhookEntityRemoved,
  };
};

export default useManageWebhook;
