import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { WebhooksListData } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import StandardError from "../../../../components/StandardError";
import { useWebhooks } from "../../../../contexts/webhooks/Webhooks.context";
import { getFilteredEntities } from "../../../../utils/entities/getFilteredEntities";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import LoadingListView from "../../../App/components/LoadingListView";
import { webhooksTooltips } from "../../data/microcopy";
import { renderWebhooksList } from "../../utils/renderWebhooksList";

const pageTitle = "Webhooks";

const Webhooks = () => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [filterText, setFilterText] = useState("");

  const { webhooks, webhooksError, loadWebhooks, setWebhooks } = useWebhooks();

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle, loadWebhooks]);

  // load webhooks if not in context already
  useEffect(() => {
    if (!webhooks && !webhooksError) {
      loadWebhooks({
        shouldPaginate: true,
      });
    }
  }, [webhooks, webhooksError, loadWebhooks]);

  if (webhooksError) {
    return <StandardError errorMessage={webhooksError} />;
  }

  const canUserCreateAndEditWebhook = userHasAccessToAction(
    roles,
    ActionGroups.WebhooksOperator,
    {}
  );

  const renderWebhooks = () => {
    const filteredWebhooks = getFilteredEntities(filterText, webhooks);
    return renderWebhooksList({
      accountId,
      canUserCreateAndEdit: canUserCreateAndEditWebhook,
      webhooks: filteredWebhooks as WebhooksListData,
      theme,
      urlOnClickTrackEventCategory: "Webhooks",
      urlOnClickTrackEventProperties: {
        view: "Webhooks",
        action: "Link Clicked",
        meta: {
          type: "Webhook Detail",
        },
      },
    });
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: webhooksTooltips.webhook.content,
          tooltipExtraLinkLabel: webhooksTooltips.webhook.extraLinkLabel,
          tooltipExtraLinkUrl: webhooksTooltips.webhook.extraLinkUrl,
        }}
        configActionButton={[
          {
            label: "New webhook",
            testId: "create-new-webhook-button",
            url: `${pathname}/new`,
            onClick: () =>
              trackEvent("Webhooks", {
                view: "Webhooks",
                action: "Create Webhook Button Clicked",
              }),
            isActionAllowed: canUserCreateAndEditWebhook,
          },
        ]}
        configFilter={{
          inputText: filterText,
          testId: "filter-webhooks",
          setInputText: setFilterText,
        }}
        refreshButton={{
          emptyItems: null,
          itemsLoading: !webhooks,
          loadItems: () =>
            loadWebhooks({
              shouldPaginate: true,
            }),
          setItems: setWebhooks,
        }}
      />

      <PlanNotice type="webhooks" />

      {!webhooks ? <LoadingListView /> : renderWebhooks()}
    </>
  );
};

export default Webhooks;
