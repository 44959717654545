import { DateTime } from "luxon";

import { User } from "../api/core/dataPlane.types";
import { roundNumber } from "../utils/tools";

const FREE_TRIAL_DECISION_COUNT = 50000;
export const DEFAULT_DECISION_COUNT = 1000;

type GetDecisionsUsedPercentageParams = {
  decisionsUsed?: number;
  decisionsAvailable: number;
};

export const getDecisionsUsedPercentage = ({
  decisionsUsed,
  decisionsAvailable,
}: GetDecisionsUsedPercentageParams): number => {
  if (decisionsUsed && decisionsAvailable) {
    if (decisionsUsed > decisionsAvailable) {
      return 100;
    } else if (decisionsUsed < 0) {
      return 0;
    } else {
      return roundNumber((decisionsUsed / decisionsAvailable) * 100, 0);
    }
  } else {
    return 0;
  }
};

export const doesTrialEndInCurrentMonth = (user: User) => {
  if (!user.planInfo) return false;
  const localizedTrialEndDate = DateTime.fromISO(user.planInfo.trialEndDate);
  return DateTime.now()
    .startOf("month")
    .equals(localizedTrialEndDate.startOf("month"));
};

export const getUserDecisionsAvailable = (user: User) => {
  return user.planInfo?.decisionsPerMonth || DEFAULT_DECISION_COUNT;
};

export const getDecisionsAvailable = (user: User) => {
  const availableDecisions = getUserDecisionsAvailable(user);

  if (user?.planInfo?.trialStatus === "in-progress") {
    return FREE_TRIAL_DECISION_COUNT;
  } else if (
    user?.planInfo?.trialStatus === "completed" &&
    doesTrialEndInCurrentMonth(user)
  ) {
    return user.planInfo.trialDecisions + availableDecisions;
  } else {
    return availableDecisions;
  }
};

export const getDecisionsUsed = (user: User) => {
  return user?.planInfo?.trialStatus === "in-progress"
    ? user?.planInfo?.trialDecisions
    : user?.decisionCount?.current;
};

export const getDecisionTooltipText = (user: User) => {
  return doesTrialEndInCurrentMonth(user)
    ? "A decision is the base unit of work completed with every run on your Nextmv app. For routing, the number of decisions counted for each run is the number of stops processed for each run. If you make a run with 10 stops that will count as 10 decisions. Note that the decisions made during your premium trial have been credited to your available decisions for this month."
    : "A decision is the base unit of work completed with every run on your Nextmv app. For routing, the number of decisions counted for each run is the number of stops processed for each run. If you make a run with 10 stops that will count as 10 decisions.";
};
