import React, { useState } from "react";
import { useTheme } from "@emotion/react";

import { ConfigOptions } from "../../../api/core/controlPlane.types";
import Box from "../../../components/Box";
import Text from "../../../components/Text";
import { rem } from "../../../utils/tools";

type InstanceConfigProps = {
  options: ConfigOptions;
  isAbbreviated?: boolean;
};

const getTopSpaceValue = (
  index: number,
  isAbbreviated: undefined | boolean
) => {
  if (isAbbreviated) return rem(2);
  if (index === 0) return 0;
  return 1;
};

const INSTANCE_OPTIONS_ABBREVIATED_DISPLAY_LIMIT = 2;

const InstanceConfig = ({ isAbbreviated, options }: InstanceConfigProps) => {
  const theme = useTheme();

  const [optionKeysLimit, setOptionKeysLimit] = useState(
    isAbbreviated ? INSTANCE_OPTIONS_ABBREVIATED_DISPLAY_LIMIT : undefined
  );
  const isOptionsLimited =
    isAbbreviated &&
    Object.keys(options).length > INSTANCE_OPTIONS_ABBREVIATED_DISPLAY_LIMIT;

  const expandOptionsOnClick = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    // toggle display limit
    return setOptionKeysLimit((prevState) => {
      return prevState ? undefined : INSTANCE_OPTIONS_ABBREVIATED_DISPLAY_LIMIT;
    });
  };

  const optionKeys = Object.keys(options).slice(0, optionKeysLimit);

  return (
    <Box>
      {optionKeys.map((configOptionKey, index) => {
        return (
          <Text
            key={configOptionKey}
            mt={getTopSpaceValue(index, isAbbreviated)}
            styleName="code"
            {...(isAbbreviated && {
              styles: {
                fontSize: theme.ui2Typography.fontSizeMeta1,
                lineHeight: theme.ui2Typography.lineHeightMeta2,
                overflowWrap: "break-word",
              },
            })}
          >
            {configOptionKey}{" "}
            <Text
              as="strong"
              styleName="code-bold"
              styles={{
                fontSize: isAbbreviated
                  ? theme.ui2Typography.fontSizeMeta1
                  : theme.ui2Typography.fontSizeCodeBold,
                overflowWrap: "break-word",
                color: theme.color.gray800,
              }}
            >
              {options[configOptionKey]}
            </Text>
          </Text>
        );
      })}
      {isOptionsLimited && (
        <Text
          as="a"
          mt={1}
          styleName="meta-2"
          isLinkGray
          styles={{ display: "inline-block" }}
          onClick={(e: {
            preventDefault: () => void;
            stopPropagation: () => void;
          }) => expandOptionsOnClick(e)}
        >
          {optionKeysLimit ? "View all…" : "Hide"}
        </Text>
      )}
    </Box>
  );
};

export default InstanceConfig;
