import { keyframes } from "@emotion/react";
import styled from "@emotion/styled/macro";

import { filterProps } from "../../utils/filterProps";
import { rem } from "../../utils/tools";
import Flex from "../Flex";

import { LoadingDotsProps } from "./Loading.types";

const LOADING_DOT_LEFT_POSITION = 9989;
const LOADING_DOT_MIDDLE_POSITION = 9999;
const LOADING_DOT_RIGHT_POSITION = 10007;

const animateFunction = (dist: number, color: string) => `
  0% {
    box-shadow: ${rem(dist)} 0 0 ${rem(-5)} ${color};
  }
  30% {
    box-shadow: ${rem(dist)} 0 0 ${rem(2)} ${color};
  }
  60%,
  100% {
    box-shadow: ${rem(dist)} 0 0 ${rem(-5)} ${color};
  }
`;
const loadingDot = (color: string) => keyframes`
  ${animateFunction(LOADING_DOT_MIDDLE_POSITION, color)}
`;
const loadingDotBefore = (color: string) => keyframes`
  ${animateFunction(LOADING_DOT_LEFT_POSITION, color)}
`;
// some weird visual thing going on here...
const loadingDotAfter = (color: string) => keyframes`
  ${animateFunction(LOADING_DOT_RIGHT_POSITION, color)}
`;

export const LoadingDots = styled("span", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<LoadingDotsProps>`
  width: ${rem(6)};
  height: ${rem(6)};
  position: absolute;
  left: calc(${rem(LOADING_DOT_MIDDLE_POSITION * -1)} + 50% - ${rem(3)});
  color: ${(props) => props.dotColor};
  border-radius: ${rem(3)};
  background-color: ${(props) => props.dotColor};
  box-shadow: ${rem(LOADING_DOT_MIDDLE_POSITION)} 0 0 ${rem(-4)}
    ${(props) => props.dotColor};
  animation: ${(props) => loadingDot(props.dotColor)} 1.5s infinite linear;
  animation-delay: 0.25s;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: ${rem(6)};
    height: ${rem(6)};
    color: ${(props) => props.dotColor};
    border-radius: ${rem(3)};
    background-color: ${(props) => props.dotColor};
  }

  &::before {
    box-shadow: ${rem(LOADING_DOT_LEFT_POSITION)} 0 0 ${rem(-5)}
      ${(props) => props.dotColor};
    animation: ${(props) => loadingDotBefore(props.dotColor)} 1.5s infinite
      linear;
    animation-delay: 0s;
  }
  &::after {
    left: ${rem(1)};
    box-shadow: ${rem(LOADING_DOT_RIGHT_POSITION)} 0 0 ${rem(-5)}
      ${(props) => props.dotColor};
    animation: ${(props) => loadingDotAfter(props.dotColor)} 1.5s infinite
      linear;
    animation-delay: 0.5s;
  }
`;

export const LoadingContainer = styled(Flex)`
  box-sizing: content-box;
  position: relative;
  width: ${rem(6)};
  height: ${rem(6)};
  margin: ${rem(-3)} 0;
  padding: ${rem(3)} ${rem(14)} ${rem(3)} ${rem(14)};
`;

export const StyledFullScreenContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
