import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconMenuDots = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      viewBox={[0, 0, 24, 20]}
      {...rest}
    >
      <circle cx="5.5" cy="10" r="1.75" />
      <circle cx="12" cy="10" r="1.75" />
      <circle cx="18.5" cy="10" r="1.75" />
    </BaseIcon>
  );
};

export default IconMenuDots;
