import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const HIGHS_KNAPSACK_PARTNER_ID = "nextmv";
export const HIGHS_KNAPSACK_APP_ID = "knapsack.highs";
export const HIGHS_KNAPSACK_SUBSCRIPTION_ID = `${HIGHS_KNAPSACK_PARTNER_ID}-${HIGHS_KNAPSACK_APP_ID}`;
export const HIGHS_KNAPSACK_ICON_URL = "/images/logos/highs-icon.png";
export const HIGHS_KNAPSACK_NAME = "Python HiGHS Knapsack";
export const HIGHS_KNAPSACK_TEMPLATE_NAME = "knapsack-highs";
export const HIGHS_KNAPSACK_SHORT_DESC =
  "Solves a knapsack Mixed Integer Programming problem using HiGHS via Python.";
export const HIGHS_KNAPSACK_SUMMARY =
  "Solve a knapsack problem with the low-code HiGHS knapsack app. Define available items and the weight capacity of the knapsack and then run the app to decide which items to include to maximize knapsack value.";
export const HIGHS_KNAPSACK_DOCS_LINK =
  "https://www.nextmv.io/docs/community-apps/knapsack";
export const HIGHS_KNAPSACK_CODE_REPO_LINK =
  "https://github.com/nextmv-io/community-apps/tree/develop/python-highs-knapsack";
export const HIGHS_KNAPSACK_MODEL_TYPE = ["Packing"];
export const HIGHS_KNAPSACK_LANGUAGE = ["Python"];
export const HIGHS_KNAPSACK_PACKAGES = ["highspy"];
export const HIGHS_KNAPSACK_SOLVER = ["HiGHS"];

export const HIGHS_KNAPSACK_CONSTRAINTS: MarketplaceAppConstraint[] = [
  {
    id: "items",
    name: "items",
    property: "items",
    docsUrl: "https://www.nextmv.io/docs/community-apps/knapsack/#input",
    summary: "Items to select from.",
    type: "items",
  },
  {
    id: "value",
    name: "value",
    property: "value",
    docsUrl: "https://www.nextmv.io/docs/community-apps/knapsack/#item",
    summary: "Value of the item.",
    type: "integer",
  },
  {
    id: "weight",
    name: "weight",
    property: "weight",
    docsUrl: "https://www.nextmv.io/docs/community-apps/knapsack/#item",
    summary: "Weight of the item.",
    type: "integer",
  },
  {
    id: "weight_capacity",
    name: "weight_capacity",
    property: "weight_capacity",
    docsUrl: "https://www.nextmv.io/docs/community-apps/knapsack/#input",
    summary: "Weight capacity of the knapsack.",
    type: "integer",
  },
];
