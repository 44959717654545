import { EntityErrorMessage } from "../api/core/controlPlane.types";
import { getEmptyEntityError } from "../hooks/useStandardInputs";

import {
  ENTITY_ID_CHAR_COUNT_MAX,
  ENTITY_ID_CHAR_COUNT_MIN,
  ENTITY_NAME_CHAR_COUNT_MAX,
  ENTITY_NAME_CHAR_COUNT_MIN,
} from "./constants";

export const CONTENT_ID_RULES = `The ID can include Latin alphabet letters, numbers, periods or hyphens, must be between ${ENTITY_ID_CHAR_COUNT_MIN} and ${ENTITY_ID_CHAR_COUNT_MAX} characters, and cannot start or end with a hyphen or period.`;

export const CONTENT_NAME_RULES = `Name must not include html tags, not begin with a hyphen, not include +, =, @, single or double quotes, and has a min length of ${ENTITY_NAME_CHAR_COUNT_MIN} and max length of ${ENTITY_NAME_CHAR_COUNT_MAX}.`;

export const checkIdForError = (
  value: string,
  blurCheck: boolean = false
): EntityErrorMessage => {
  if (blurCheck && !value) {
    return getEmptyEntityError("id");
  }

  if (
    value.length > ENTITY_ID_CHAR_COUNT_MAX ||
    (blurCheck && value.length < ENTITY_ID_CHAR_COUNT_MIN)
  ) {
    return CONTENT_ID_RULES;
  }

  return null;
};

export const getIdSlug = (id: string) => {
  return id.trimStart().replace(" ", "-");
};
