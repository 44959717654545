import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";

import { useCreateFreeTrial } from "../../api/core/dataPlane.hooks";
import { getSetupIntent } from "../../api/payment/paymentApi";
import { useUser } from "../../AuthProvider";
import Box from "../../components/Box";
import Button2 from "../../components/Button2";
import { HandleFreeTrialSignupParams } from "../../pages/Apps/Apps.types";
import Notification from "../Notification";
import Text from "../Text";

import {
  getStripeStyles,
  StyledCreditCardContainer,
} from "./FreeTrialSignup.styled";
import { FreeTrialError } from "./FreeTrialSignup.types";

const FreeTrialSignup = () => {
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const [user] = useUser();
  const startFreeTrial = useCreateFreeTrial();
  const [error, setError] = useState<FreeTrialError | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  if (!stripe || !elements) {
    return null;
  }

  const handleFreeTrialSignup = async ({ e }: HandleFreeTrialSignupParams) => {
    e.preventDefault();

    setError(null);
    setIsProcessing(true);

    if (user.id) {
      let clientSecret = "";
      try {
        const resp = await getSetupIntent(user.id);
        clientSecret = resp.clientSecret;
      } catch (e) {
        setIsProcessing(false);
        setError({
          message: "Error: unable to set up payment method",
          addContactSupport: true,
        });
        console.error(e);
        return;
      }

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        console.error("Card element was not accessible");
        setIsProcessing(false);
        setError({
          message: "There was an error with processing the payment.",
          addContactSupport: true,
        });
        return;
      }

      const payload = {
        payment_method: {
          card: cardElement as StripeCardElement,
        },
      };

      try {
        const result = await stripe.confirmCardSetup(clientSecret, payload);
        if (result.error) {
          setIsProcessing(false);
          setError({
            message: result.error.message || "",
            addContactSupport: false,
          });
          return;
        }
      } catch (e) {
        setIsProcessing(false);
        setError({ message: (e as Error).message, addContactSupport: true });
        console.error(e);
        return;
      }

      try {
        await startFreeTrial();
      } catch (e) {
        setIsProcessing(false);
        setError({ message: (e as Error).message, addContactSupport: true });
        console.error(e);
        return;
      }
    } else {
      // This should never happen.
      // Internal error: No user id in context.
      setError({ message: "An error occurred.", addContactSupport: true });
      setIsProcessing(false);
    }
  };

  return (
    <Box>
      <StyledCreditCardContainer mt={4}>
        <CardElement id="card-element" options={getStripeStyles(theme)} />
      </StyledCreditCardContainer>

      {error && (
        <Notification
          mt={3}
          message={error.message}
          size="small"
          type="error"
          hasContactExtra={error.addContactSupport}
        />
      )}

      <Button2
        mt={3}
        label="Start free trial"
        size="large"
        onClick={(e: any) => handleFreeTrialSignup({ e })}
        isLoading={isProcessing}
      />

      <Box mt={6} pt={4} hasBorderTop>
        <Text styleName="body-1">
          <Text
            as="strong"
            styleName="body-1-bold"
            styles={{ color: theme.color.gray800 }}
          >
            OR
          </Text>{" "}
          for manual access,{" "}
          <Text
            as="a"
            styleName="body-1-bold"
            isLink
            href="https://www.nextmv.io/contact"
            target="_blank"
            rel="noreferrer"
          >
            schedule a call
          </Text>{" "}
          with the Nextmv team.
        </Text>
      </Box>
    </Box>
  );
};

export default FreeTrialSignup;
