export const webhooksTooltips = {
  webhook: {
    content:
      "Webhooks exist at the account level and operate on events across all apps. So a single webhook operating on the run.status event type will be triggered for any run on any app.",
    extraLinkLabel: "More",
    extraLinkUrl:
      "https://www.nextmv.io/docs/platform/run-app-remotely/webhooks",
  },
};

export const webhookTooltips = {
  id: {
    content:
      "The ID is the unique identifier for your webhook. Once the webhook has been created, the ID cannot be changed.",
  },
  idEdit: {
    content: `Once created, the webhook ID cannot be changed.`,
  },
  idCreate: {
    content: `The ID is the unique identifier for your webhook.`,
  },
  secret: {
    content:
      "The webhook signing secret is used to recompute the Hash-based Message Authentication Code (HMAC) signature of the request to verify its authenticity.",
  },
};

export const conversationTooltips = {
  id: {
    content: "The ID is the unique identifier for your conversation.",
  },
};
