import * as React from "react";

import BaseAvatar, { AvatarProps } from "./BaseAvatar";

const AvatarInputSet = ({ size }: AvatarProps) => {
  return (
    <BaseAvatar {...{ size }} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM6.02502 7.27502C6.02502 6.58467 6.58467 6.02502 7.27503 6.02502H16.725C17.4154 6.02502 17.975 6.58467 17.975 7.27503V16.725C17.975 17.4154 17.4154 17.975 16.725 17.975H7.27502C6.58467 17.975 6.02502 17.4154 6.02502 16.725V7.27502ZM7.40003 7.40002L7.40002 9.51253H16.6V7.40003L7.40003 7.40002ZM7.40002 10.8875V13.1125L16.6 13.1125V10.8875H7.40002ZM7.40002 14.4875L7.40002 16.6H16.6V14.4875L7.40002 14.4875Z"
        fill="#F28E2C"
      />
    </BaseAvatar>
  );
};

export default AvatarInputSet;
