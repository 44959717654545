import React, { useState } from "react";

import Box from "../Box";

import { StyledDetails, StyledSummary } from "./Details.styled";
import { DetailsProps } from "./Details.types";

const Details = ({
  children,
  controlPosition,
  hasBorderTop,
  hasNoChildrenWrapper,
  htmlId,
  isFocusedFade,
  isOpen,
  onClick,
  onMouseOut,
  onMouseOver,
  m,
  mt,
  mr,
  mb,
  ml,
  size,
  styles,
  summary,
  testId,
  useNonNativeElements,
  usePointerEventsNone,
}: DetailsProps) => {
  const thisSize = size || "default";
  const thisControlPosition = controlPosition || "right";

  const [localIsOpen, setLocalIsOpen] = useState(!!isOpen);

  const renderDetailsContent = () => {
    return hasNoChildrenWrapper ? children : <Box mt={3}>{children}</Box>;
  };

  return (
    <StyledDetails
      as={useNonNativeElements ? "div" : "details"}
      className="details"
      {...{
        hasBorderTop,
        isFocusedFade,
        localIsOpen,
        m,
        mt,
        mr,
        mb,
        ml,
        onMouseOut,
        onMouseOver,
        styles,
        useNonNativeElements,
        usePointerEventsNone,
      }}
      id={htmlId}
      size={thisSize}
      controlPosition={thisControlPosition}
      {...(isOpen &&
        !useNonNativeElements && {
          open: true,
        })}
      {...(testId && {
        "data-testid": testId,
      })}
    >
      <StyledSummary
        as={useNonNativeElements ? "div" : "summary"}
        className="summary"
        onClick={onClick}
        size={thisSize}
        controlPosition={thisControlPosition}
        localIsOpen={localIsOpen}
        useNonNativeElements={useNonNativeElements}
      >
        {useNonNativeElements && (
          <button
            type="button"
            className="details-control-icon"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setLocalIsOpen(!localIsOpen);
            }}
          />
        )}
        {summary}
      </StyledSummary>

      {useNonNativeElements ? (
        <div className="details-content">{renderDetailsContent()}</div>
      ) : (
        renderDetailsContent()
      )}
    </StyledDetails>
  );
};

export default Details;
