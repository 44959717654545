import React from "react";
import { useTheme } from "@emotion/react";

import Button2 from "../../../components/Button2";
import Text from "../../../components/Text";
import {
  CircleNumber,
  StepDetails,
  StepDetailsSummary,
} from "../Install.styled";
import { InstallStepProps } from "../Install.types";
import { goToNextStep, handleDetailsToggle } from "../utils/manageSteps";

const InstallStep = ({
  content,
  isActive,
  setIsActiveCurrent,
  setIsActiveNext,
  stepNumber,
  stepTitle,
}: InstallStepProps) => {
  const theme = useTheme();

  return (
    <StepDetails {...{ isActive }} open={isActive ? true : undefined}>
      <StepDetailsSummary
        onClick={
          setIsActiveCurrent
            ? (e) => handleDetailsToggle({ e, isActive, setIsActiveCurrent })
            : undefined
        }
      >
        <Text
          as="h3"
          pl={[9, 9, 9, 0]}
          styleName="header-1"
          styles={{
            color: theme.color.gray800,
            textRendering: "optimizeLegibility",
            WebkitFontSmoothing: "antialiased",
          }}
        >
          {stepNumber && <CircleNumber>{stepNumber}</CircleNumber>}
          {stepTitle}
        </Text>
      </StepDetailsSummary>

      {content}

      {setIsActiveNext && setIsActiveCurrent && (
        <Button2
          mt={4}
          label="Next"
          onClick={() => goToNextStep({ setIsActiveCurrent, setIsActiveNext })}
        />
      )}
    </StepDetails>
  );
};

export default InstallStep;
