import React, { useEffect, useMemo } from "react";
import { useTheme } from "@emotion/react";

import { trackEvent, TrackEvents } from "../../analytics/functions";
import { AppResponse } from "../../api/core/controlPlane.types";
import { AvatarAppInstance } from "../../avatars";
import Box from "../../components/Box";
import Button2 from "../../components/Button2";
import Flex from "../../components/Flex";
import Loading from "../../components/Loading";
import { useAppCollection } from "../../contexts/apps/App.context";
import { IconX } from "../../icons";
import { InstanceOption } from "../../pages/App/subpages/Instances/Instances.types";
import { getInstanceName } from "../../pages/Experiments/components/utils";
import { BATCH_MAX_INSTANCE_LIMIT } from "../../pages/Experiments/data/constants";
import { LATEST_INSTANCE_ID } from "../../utils/constants";
import { rem } from "../../utils/tools";
import Notification from "../Notification";
import Select from "../Select";
import Text from "../Text";

import {
  InstanceSelectInstanceIds,
  PendingInstances,
} from "./InstanceSelect.types";

const InstanceSelect = <T extends keyof TrackEvents>({
  app,
  excludeInstanceIds,
  hasAvatar,
  hasDevIntOption = false,
  isAlwaysShowDevInt,
  isDisabled,
  isLatestReplaceDevInt = false,
  isSingleSelect,
  isSmall,
  pendingInstanceIds,
  pendingInstances,
  placeholder,
  setPendingInstanceIds,
  setPendingInstances,
  testId,
  trackEventCategory,
  trackEventProperties,
}: {
  app: AppResponse;
  excludeInstanceIds?: InstanceSelectInstanceIds;
  hasAvatar?: boolean;
  isDisabled?: boolean;
  hasDevIntOption?: boolean;
  isAlwaysShowDevInt?: boolean;
  isLatestReplaceDevInt?: boolean;
  isSingleSelect?: boolean;
  isSmall?: boolean;
  placeholder?: string;
  testId?: string;
  trackEventCategory?: T;
  trackEventProperties?: TrackEvents[T];
  // DEPRECATED
  // an array of instance IDs as the primary data exchange for
  // this component has been deprecated in favor of an array of
  // instances (instance ID and version ID at the moment, but
  // extensible for the future), however, instanceIds is used
  // in more than a few components so it is not being removed yet
  pendingInstanceIds?: InstanceSelectInstanceIds;
  setPendingInstanceIds?: React.Dispatch<
    React.SetStateAction<InstanceSelectInstanceIds>
  >;
  // NOTE: once the two deprecated props above have been removed,
  // the two below should be marked as required props
  pendingInstances?: PendingInstances;
  setPendingInstances?: (instances: PendingInstances) => void;
}) => {
  const theme = useTheme();

  const defaultInstanceId = app?.default_instance;

  const { instances, instancesError, loadInstances } = useAppCollection();

  useEffect(() => {
    if (!instances) {
      loadInstances({ applicationId: app.id });
    }
  }, [app.id, instances, loadInstances]);

  const hasLatestInstance = instances?.some(
    (instance) => instance.id === LATEST_INSTANCE_ID
  );

  const hasInstances = !!instances?.length;

  const instanceOptionDevInt = useMemo(() => {
    return {
      id: "devint",
      name: isLatestReplaceDevInt ? "Latest" : "devint",
      version: "",
      ...(hasAvatar && {
        avatar: <AvatarAppInstance size={20} />,
      }),
    };
  }, [hasAvatar, isLatestReplaceDevInt]);

  const instanceOptions = useMemo(() => {
    const isShowDevInt = !hasInstances || isAlwaysShowDevInt;
    return (
      instances &&
      instances.reduce(
        (instanceOptions: InstanceOption[], instance) => {
          // prevent duplicate instance selection
          // if (pendingInstanceIds.includes(instance.id)) return instanceOptions;

          // exclude instances if specified
          if (excludeInstanceIds) {
            if (excludeInstanceIds.includes(instance.id))
              return instanceOptions;
          }

          instanceOptions.push({
            id: instance.id,
            name: getInstanceName(instance, defaultInstanceId),
            version: instance.version_id,
            ...(hasAvatar && {
              avatar: <AvatarAppInstance size={20} />,
            }),
          });
          return instanceOptions;
        },
        [
          ...(app.type === "custom" && hasDevIntOption && isShowDevInt
            ? [instanceOptionDevInt]
            : []),
        ]
      )
    );
  }, [
    app.type,
    defaultInstanceId,
    excludeInstanceIds,
    hasAvatar,
    hasDevIntOption,
    hasInstances,
    instanceOptionDevInt,
    instances,
    isAlwaysShowDevInt,
  ]);

  const getInstanceOptionFromId = (
    instanceId: string
  ): InstanceOption | undefined => {
    const isLatestInstanceFrontEndOnly =
      !hasLatestInstance && instanceId === LATEST_INSTANCE_ID;
    if (instanceId === "devint" || isLatestInstanceFrontEndOnly) {
      return instanceOptionDevInt;
    }
    if (!instanceOptions) return;

    return instanceOptions?.find((instanceOption) => {
      return instanceOption.id === instanceId;
    });
  };

  const updateInstanceSelection = (
    instanceSelected: InstanceOption,
    index: number
  ) => {
    if (pendingInstanceIds && setPendingInstanceIds) {
      const modifiedPendingInstanceIds = pendingInstanceIds.map(
        (pendingInstanceId, i) => {
          if (i === index) {
            return instanceSelected
              ? isLatestReplaceDevInt && instanceSelected.id === "devint"
                ? LATEST_INSTANCE_ID
                : instanceSelected.id
              : "";
          }
          return pendingInstanceId;
        }
      );
      setPendingInstanceIds(modifiedPendingInstanceIds);
    }

    if (pendingInstances && setPendingInstances) {
      const modifiedPendingInstances = pendingInstances.map(
        (pendingInstance, i) => {
          if (i === index) {
            return instanceSelected
              ? {
                  instanceId:
                    // The "devint" pending instance id is replaced with "latest" for experiments
                    // but still displays as "devint" in the dropdown UI
                    isLatestReplaceDevInt && instanceSelected.id === "devint"
                      ? LATEST_INSTANCE_ID
                      : instanceSelected.id,
                  versionId: instanceSelected.version,
                }
              : undefined;
          }
          return pendingInstance;
        }
      );
      setPendingInstances(modifiedPendingInstances);
    }
    return;
  };

  const addEmptyInstance = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (setPendingInstanceIds) {
      setPendingInstanceIds((prevState: InstanceSelectInstanceIds) => [
        ...prevState,
        "",
      ]);
    }
    if (setPendingInstances) {
      setPendingInstances((prevState: PendingInstances) => [
        ...prevState,
        undefined,
      ]);
    }
  };

  const removeInstanceSelection = (
    e: { preventDefault: () => void; stopPropagation: () => void },
    index: number
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (pendingInstanceIds && setPendingInstanceIds) {
      const updatedPendingInstanceIds = pendingInstanceIds.filter(
        (_pendingInstanceId, i) => {
          return i !== index;
        }
      );
      setPendingInstanceIds(updatedPendingInstanceIds);
    }

    if (pendingInstances && setPendingInstances) {
      const updatedPendingInstances = pendingInstances.filter(
        (_pendingInstance, i) => {
          return i !== index;
        }
      );
      setPendingInstances(updatedPendingInstances);
    }

    return;
  };

  if (instancesError) {
    return (
      <Notification type="error" message={instancesError} hasContactExtra />
    );
  }
  if (!instances) {
    return <Loading />;
  }
  if (!instances.length && !hasDevIntOption) {
    return (
      <Flex minHeight={theme.spacing.s7} alignItems="center">
        <Text styleName="body-2" styles={{ color: theme.color.gray500 }}>
          No instances available
        </Text>
      </Flex>
    );
  }

  // TEMP (manage both dual patterns, see deprecation notice above)
  let instanceIds: (string | undefined)[] = [];
  if (pendingInstanceIds) {
    instanceIds = [...pendingInstanceIds];
  }
  if (pendingInstances) {
    instanceIds = pendingInstances.map(
      (pendingInstance) => pendingInstance?.instanceId
    );
  }

  return (
    <Box width="100%" maxWidth={rem(480)}>
      {instanceIds.map((pendingInstanceId, index) => {
        return (
          <Flex
            key={`${pendingInstanceId}-${index}`}
            mt={index !== 0 ? 1 : 0}
            mr={-8}
            alignItems="center"
            pr={index === 0 ? 8 : 0}
          >
            <Select
              isDisabled={isDisabled}
              testId={testId || `select-instance-${index}`}
              type="meta"
              placeholder={placeholder || "Select instance"}
              noOptionsMessage={() => "No instances"}
              isClearable
              options={instanceOptions}
              getOptionValue={(selectedInstance: InstanceOption) =>
                selectedInstance.id
              }
              value={
                pendingInstanceId && getInstanceOptionFromId(pendingInstanceId)
              }
              onChange={(selection: InstanceOption) => {
                trackEventCategory &&
                  trackEventProperties &&
                  trackEvent(trackEventCategory, trackEventProperties);
                updateInstanceSelection(selection, index);
              }}
              {...(isSmall && {
                size: "small",
              })}
            />
            {index > 0 && !isSingleSelect && (
              <Button2
                type="text-quiet"
                icon={<IconX />}
                onClick={(e: any) => removeInstanceSelection(e, index)}
              />
            )}
          </Flex>
        );
      })}

      {!isSingleSelect && instanceIds.length < BATCH_MAX_INSTANCE_LIMIT && (
        <Button2
          mt={2}
          testId="add-new-select-instance-button"
          type="outline-quiet"
          htmlType="button"
          onClick={(e: { preventDefault: () => void }) => addEmptyInstance(e)}
          label="Add instance"
        />
      )}
    </Box>
  );
};
export default InstanceSelect;
