import React from "react";
import { useTheme } from "@emotion/react";
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { getYAxisWidth } from "../../../../../components/Chart/Chart.component";
import { CustomChartStyleRules } from "../../../../../components/Chart/Chart.styled";
import CustomLabel from "../../../../../components/Chart/custom/Label";
import CustomPoint from "../../../../../components/Chart/custom/Point";
import CustomTooltip from "../../../../../components/Chart/custom/Tooltip";
import { getTickLabelStyle } from "../../../../../components/Chart/utils/chartStyles";
import { formatTime, roundNumber, toK } from "../../../../../utils/tools";
import { ParsedSeriesDatum } from "../RunDetails.types";

type ChartCustomSeriesProps = {
  data: ParsedSeriesDatum[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  xDataKey?: string;
  yDataKey?: string;
  chartColor?: string;
  offsetYMin?: number;
};

const ChartCustomSeries = ({
  data,
  chartColor,
  offsetYMin,
  xAxisLabel,
  yAxisLabel,
  xDataKey,
  yDataKey,
}: ChartCustomSeriesProps) => {
  const theme = useTheme();
  const yAxisWidth = getYAxisWidth({
    key: yDataKey || "y",
    data: data,
    extraPadding: 40,
  });
  const tickLabelStyle = getTickLabelStyle(theme);
  const thisChartColor = chartColor || theme.color.orange400;

  return (
    <CustomChartStyleRules
      className="chart-custom-series-data"
      yAxisWidth={yAxisWidth || 0}
    >
      <ResponsiveContainer width="100%" height={340}>
        <ScatterChart margin={{ bottom: 24, left: 24 }}>
          <CartesianGrid
            stroke={theme.color.gray200T}
            vertical={false}
            style={{
              backgroundColor: theme.color.gray100,
            }}
          />

          <Scatter
            data={data}
            line={{
              stroke: thisChartColor,
              strokeWidth: 3,
            }}
            lineType="joint"
            shape={({ cx, cy }) => (
              <CustomPoint {...{ cx, cy }} fillColor={thisChartColor} />
            )}
          />

          <Tooltip
            content={<CustomTooltip noTotal />}
            isAnimationActive={false}
            cursor={{ stroke: theme.color.gray300, strokeWidth: 1 }}
          />

          <XAxis
            dataKey={xDataKey || "x"}
            padding={{ left: 24, right: 24 }}
            stroke={theme.color.gray300}
            style={tickLabelStyle}
            tickMargin={8}
            tickSize={0}
            tickFormatter={(value) =>
              xDataKey === "timeElapsed"
                ? formatTime(value)
                : roundNumber(value, 3).toLocaleString("EN-US")
            }
            type="number"
            {...(xAxisLabel && {
              label: <CustomLabel isXAxis>{xAxisLabel}</CustomLabel>,
            })}
          />

          <YAxis
            dataKey={yDataKey || "y"}
            padding={{ top: 16 }}
            interval="preserveStart"
            stroke={theme.color.gray300}
            style={tickLabelStyle}
            tickMargin={4}
            tickSize={0}
            tickFormatter={(value) =>
              yDataKey === "value"
                ? toK(value)
                : roundNumber(value, 3).toLocaleString("EN-US")
            }
            width={yAxisWidth}
            {...(yAxisLabel && {
              label: <CustomLabel isYAxis>{yAxisLabel}</CustomLabel>,
            })}
            {...(offsetYMin && {
              domain: [`dataMin - ${offsetYMin}`, "auto"],
            })}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </CustomChartStyleRules>
  );
};

export default ChartCustomSeries;
