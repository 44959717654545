import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import Box from "../../../../components/Box";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import StandardError from "../../../../components/StandardError";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { appTooltipCopy } from "../../../Apps/data/microcopy";
import { AppPageProps } from "../../App.types";
import ExecutableDetails from "../../components/ExecutableDetails";
import useReturnPaths from "../../hooks/useReturnPaths";

const EditVersionDetails = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const params = useParams() as { id: string };
  const theme = useTheme();
  const { returnPath, returnPathList } = useReturnPaths();

  const [isProcessing, setIsProcessing] = useState(false);

  const { loadVersions } = useAppCollection();

  const {
    editEntity: editVersion,
    entity: version,
    entityLoadError: versionLoadError,
    entityEditError: versionEditError,
    loadEntity: loadVersion,
    isEntityEdited: isVersionSaved,
  } = useManageEntity("versions");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "versions", true);

  // load version data
  useEffect(() => {
    if (!version && !versionLoadError) {
      loadVersion(app.id, params.id);
    }
  }, [app.id, loadVersion, params.id, version, versionLoadError]);

  // page display
  useEffect(() => {
    if (version) {
      setMetaTitle(`Edit ${version.name}`);
    }
  }, [setMetaTitle, version]);

  // pre-fill pending version with original values
  useEffect(() => {
    if (version && !pendingStandardInputs.id) {
      updateStandardInputs([
        { key: "name", value: version.name },
        { key: "id", value: version.id },
        { key: "description", value: version.description },
      ]);
    }
  }, [pendingStandardInputs.id, updateStandardInputs, version]);

  // disable loading state if edit version error
  useEffect(() => {
    if (versionEditError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, versionEditError]);

  const handleVersionSave = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);

    const payload = { ...pendingStandardInputs };

    await editVersion(app.id, version!.id, payload);
  };

  const handleCancel = () => {
    trackEvent("Versions", {
      view: "Edit Version",
      action: "Edit Version Canceled",
    });
    return;
  };

  const handleVersionPostDelete = () => {
    trackEvent("Versions", {
      view: "Edit Version",
      action: "Version Deleted",
    });

    loadVersions({ applicationId: app.id, shouldPaginate: true });
  };

  if (versionLoadError) {
    return <StandardError errorMessage={versionLoadError} />;
  }
  if (!pendingStandardInputs.id || !version) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }
  if (isVersionSaved) {
    trackEvent("Versions", {
      view: "Edit Version",
      action: "Edit Version Saved",
    });

    loadVersion(app.id, params.id);
    loadVersions({ applicationId: app.id, shouldPaginate: true });

    return <Redirect to={returnPath} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name || !!standardInputsErrors.name;

  return (
    <>
      <Header
        configPageTitle={{
          label: `Edit ${version.name}`,
          parentLabel: "Versions",
          parentUrl: returnPathList,
        }}
      />

      <form>
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Version name",
                  testId: "edit-version-name-input",
                  type: "name",
                  trackEventCategory: "Versions",
                  trackEventProperties: {
                    view: "Edit Version",
                    action: "Field Changed",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          secondaryLabel="Read-only"
          tooltipCopy={appTooltipCopy.versionIdEdit}
          render={
            <Box flexGrow={1} maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                htmlType="text"
                isDisabled
                placeholder="Version ID"
                value={version.id}
                readOnly
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Version description",
                  testId: "edit-version-description-input",
                  type: "description",
                  trackEventCategory: "Versions",
                  trackEventProperties: {
                    view: "Edit Version",
                    action: "Field Changed",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        {version.executable && (
          <RowDetail
            property="Executable"
            secondaryLabel="Read-only"
            tooltipCopy={appTooltipCopy.versionExecutableEdit}
            render={<ExecutableDetails executable={version.executable} />}
          />
        )}

        <Footer
          app={app}
          endpoint="versions"
          entityId={version.id}
          error={versionEditError}
          handleCancel={handleCancel}
          handleMainAction={handleVersionSave}
          handlePostDelete={handleVersionPostDelete}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="edit"
        />
      </form>
    </>
  );
};

export default EditVersionDetails;
