import { getSSODomain } from "../api/core/dataPlane";
import ExpectedSSOError from "../customErrors/ExpectedSSOError";

import { getEmailDomain } from "./getEmailDomain";

export const getSSODomainId = async (email: string): Promise<string> => {
  try {
    const domain = getEmailDomain(email);
    const resp = await getSSODomain(domain);
    if (resp.enabled) {
      return resp.domain_identifier;
    }
  } catch (err) {
    if (!(err instanceof ExpectedSSOError)) {
      throw err;
    }
  }
  return "";
};
