import { kebabCase, startCase } from "lodash";
import { nanoid } from "nanoid";

import { ENTITY_ID_CHAR_COUNT_MAX } from "../constants";

// make sure name string is no longer than ID char count max
// minus 3 characters so multiple entities can be added at once
// e.g. when adding multiple input sets for scenarios we only
// have one scenario ID to work with
const INDEX_TAG_CHAR_COUNT = 3;

export const getSafeNameAndIdForEntity = (
  prefix: string,
  id: string
): { id: string; name: string } => {
  if (!id || !prefix) return { id: "", name: "" };

  // create safe id by forcing kebab-case
  const safeUserDefinedId = kebabCase(id);

  const randomEightCharSlug = nanoid(8);

  // add 1 to randomEightCharSlug length for preceding "-"
  const safeIdMax =
    ENTITY_ID_CHAR_COUNT_MAX -
    INDEX_TAG_CHAR_COUNT -
    prefix.length -
    (randomEightCharSlug.length + 1);

  let safeId = prefix;

  safeUserDefinedId.split("-").forEach((word) => {
    const wordCharCount = word.length;
    let safeSlug = "";

    if (wordCharCount > safeIdMax) {
      safeSlug = word.substring(0, safeIdMax - 1);
    } else {
      safeSlug = word;
    }

    const totalIdCharCount = safeId.length + safeSlug.length;
    if (totalIdCharCount >= safeIdMax) {
      return;
    } else {
      safeId = `${safeId}${safeId.length > 0 ? "-" : ""}${safeSlug}`;
    }
  });

  // add unique slug
  safeId += `-${randomEightCharSlug}`;

  // get name based on ID
  const safeName = startCase(safeId);

  return { id: safeId, name: safeName };
};
