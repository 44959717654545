import React, { useEffect, useMemo, useState } from "react";
import { Redirect } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { trackEvent } from "../../../../analytics";
import { startSwitchbackTest } from "../../../../api/core/controlPlane";
import { useUser } from "../../../../AuthProvider";
import { AvatarExperimentSwitchback } from "../../../../avatars";
import Box from "../../../../components/Box";
import DatePicker from "../../../../components/DatePicker";
import Flex from "../../../../components/Flex";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import InstanceSelect from "../../../../components/InstanceSelect";
import { useMetaTitle } from "../../../../components/Layout";
import RowDetail from "../../../../components/RowDetail";
import Text from "../../../../components/Text";
import Tooltip from "../../../../components/Tooltip";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import {
  SWITCHBACK_TEST_MAX_PLAN_UNIT,
  SWITCHBACK_TEST_MAX_PLAN_UNIT_DURATION_MINUTES,
  SWITCHBACK_TEST_MIN_PLAN_UNIT,
  SWITCHBACK_TEST_MIN_PLAN_UNIT_DURATION_MINUTES,
} from "../../../../utils/constants";
import { rem } from "../../../../utils/tools";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";

import { tooltipContentNewSwitchbackTest } from "./data/tooltipContentNewSwitchbackTest";
import useNewSwitchbackTest from "./hooks/useNewSwitchbackTest";

const pageTitle = "Create new test";

const NewSwitchbackTest = ({ app }: AppPageProps) => {
  const [{ id: accountId }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const { returnPath, returnPathList } = useReturnPaths();
  const theme = useTheme();

  const [isProcessing, setIsProcessing] = useState(false);
  const [isTestStarted, setIsTestStarted] = useState(false);

  const { loadSwitchbackTests } = useExperiments();

  const {
    handleSwitchbackStartTimeChange,
    handleSwitchbackStartTimeClear,
    handleSwitchbackStartTimeClose,
    handleSwitchbackStartTimeSelect,
    handleSwitchbackStartTimeFocus,
    handleUnitInputsChange,
    isSwitchbackStartTimeDatePickerActive,
    pendingBaselineInstanceIds,
    pendingCandidateInstanceIds,
    setPendingBaselineInstanceIds,
    setPendingCandidateInstanceIds,
    setSwitchbackStartTime,
    switchbackStartTime,
    unitInputs,
  } = useNewSwitchbackTest(app);

  const {
    addEntity: addSwitchbackTest,
    entityAddError: switchbackTestAddError,
    isEntityAdded: isSwitchbackTestAdded,
  } = useManageEntity("experiments/switchback");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
  } = useStandardInputs(app, "experiments/switchback");

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // disable loading state if add new switchback error
  useEffect(() => {
    if (switchbackTestAddError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, switchbackTestAddError]);

  const filteredPendingBaselineInstanceIds = useMemo(() => {
    return pendingBaselineInstanceIds.filter(Boolean);
  }, [pendingBaselineInstanceIds]);

  const filteredPendingCandidateInstanceIds = useMemo(() => {
    return pendingCandidateInstanceIds.filter(Boolean);
  }, [pendingCandidateInstanceIds]);

  const handleSwitchbackTestCreate = async (
    e: {
      preventDefault: any;
      stopPropagation: any;
    },
    shouldStartTest = false
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);
    setIsTestStarted(shouldStartTest);

    const switchbackTestPayload = {
      id: pendingStandardInputs.id,
      name: pendingStandardInputs.name,
      description: pendingStandardInputs.description,
      comparison: {
        baseline_instance_id: filteredPendingBaselineInstanceIds[0] || "",
        candidate_instance_id: filteredPendingCandidateInstanceIds[0] || "",
      },
      generate_random_plan: {
        start: switchbackStartTime,
        units: unitInputs.units || 0,
        unit_duration_minutes: unitInputs.unit_duration_minutes || 0,
      },
    };

    const newSwitchbackTest = await addSwitchbackTest(
      app.id,
      switchbackTestPayload,
      true
    );

    if (newSwitchbackTest && shouldStartTest) {
      await startSwitchbackTest(accountId || "")(app.id, newSwitchbackTest.id);
    }
    return;
  };

  const handleCancel = () => {
    trackEvent("SwitchbackTests", {
      view: "Create Switchback Test",
      action: "Create Switchback Test Canceled",
    });
    return;
  };

  if (isSwitchbackTestAdded && !isProcessing) {
    trackEvent("SwitchbackTests", {
      view: "Create Switchback Test",
      action: isTestStarted
        ? "New Switchback Test Created and Started"
        : "New Draft Switchback Test Created",
    });

    loadSwitchbackTests(app.id);

    return <Redirect to={returnPathList} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name ||
    !pendingStandardInputs.id ||
    !filteredPendingBaselineInstanceIds.length ||
    !filteredPendingCandidateInstanceIds.length ||
    !!standardInputsErrors.name ||
    !!standardInputsErrors.id;

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          ancestorIcon: <AvatarExperimentSwitchback size={24} />,
          ancestorLabel: "Switchback Tests",
          ancestorUrl: returnPathList,
        }}
      />

      <Box pb={[6, 6, 8]}>
        <form>
          <RowDetail
            hasNoBorder
            property="Name"
            secondaryLabel="For reference only"
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Switchback test name",
                    testId: "new-switchback-name-input",
                    type: "name",
                    trackEventCategory: "SwitchbackTests",
                    trackEventProperties: {
                      view: "Create Switchback Test",
                      action: "Field Entered",
                      meta: {
                        field: "name",
                      },
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="ID"
            tooltipCopy={tooltipContentNewSwitchbackTest.id.content}
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Switchback test ID",
                    testId: "new-switchback-id-input",
                    type: "id",
                    trackEventCategory: "SwitchbackTests",
                    trackEventProperties: {
                      view: "Create Switchback Test",
                      action: "Switchback Test ID Changed",
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="Description"
            secondaryLabel="(optional)"
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Switchback test description",
                    testId: "new-switchback-description-input",
                    type: "description",
                    trackEventCategory: "SwitchbackTests",
                    trackEventProperties: {
                      view: "Create Switchback Test",
                      action: "Field Entered",
                      meta: {
                        field: "description",
                      },
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="Baseline Instance"
            tooltipCopy={
              tooltipContentNewSwitchbackTest.baselineInstance.content
            }
            render={
              <Box maxWidth={INPUT_WIDTH_STANDARD}>
                <InstanceSelect
                  {...{ app }}
                  isSingleSelect
                  placeholder="Select baseline instance"
                  excludeInstanceIds={pendingCandidateInstanceIds}
                  pendingInstanceIds={pendingBaselineInstanceIds}
                  setPendingInstanceIds={setPendingBaselineInstanceIds}
                  trackEventCategory="SwitchbackTests"
                  trackEventProperties={{
                    view: "Create Switchback Test",
                    action: "Baseline Instance Selected",
                  }}
                />
              </Box>
            }
          />

          <RowDetail
            property="Candidate Instance"
            tooltipCopy={
              tooltipContentNewSwitchbackTest.candidateInstance.content
            }
            render={
              <Box maxWidth={INPUT_WIDTH_STANDARD}>
                <InstanceSelect
                  {...{ app }}
                  isSingleSelect
                  placeholder="Select candidate instance"
                  excludeInstanceIds={pendingBaselineInstanceIds}
                  pendingInstanceIds={pendingCandidateInstanceIds}
                  setPendingInstanceIds={setPendingCandidateInstanceIds}
                  trackEventCategory="SwitchbackTests"
                  trackEventProperties={{
                    view: "Create Switchback Test",
                    action: "Candidate Instance Selected",
                  }}
                />
              </Box>
            }
          />

          <RowDetail
            property="Random Plan"
            tooltipCopy={tooltipContentNewSwitchbackTest.randomPlan.content}
            render={
              <Box width="100%">
                <Flex alignItems="center" width="100%">
                  <Flex width="100%" maxWidth={rem(408)} alignItems="center">
                    <Text
                      styleName="body-3"
                      styles={{ width: rem(100), flexShrink: 0 }}
                    >
                      Plan units
                    </Text>
                    <Input
                      ml={2}
                      name="units"
                      htmlType="number"
                      max={SWITCHBACK_TEST_MAX_PLAN_UNIT}
                      min={SWITCHBACK_TEST_MIN_PLAN_UNIT}
                      value={unitInputs.units}
                      placeholder={`Plan units (max ${SWITCHBACK_TEST_MAX_PLAN_UNIT.toLocaleString()})`}
                      onChange={handleUnitInputsChange}
                    />
                  </Flex>
                  <Tooltip ml={1}>
                    {tooltipContentNewSwitchbackTest.units.content}
                  </Tooltip>
                </Flex>

                <Flex mt={2} alignItems="center" width="100%">
                  <Flex width="100%" maxWidth={rem(408)} alignItems="center">
                    <Text
                      styleName="body-3"
                      styles={{ width: rem(100), flexShrink: 0 }}
                    >
                      Unit duration{" "}
                      <Text
                        as="span"
                        styleName="meta-2"
                        styles={{
                          display: "block",
                          color: theme.color.gray600,
                        }}
                      >
                        (minutes)
                      </Text>
                    </Text>
                    <Input
                      ml={2}
                      name="unit_duration_minutes"
                      htmlType="number"
                      max={SWITCHBACK_TEST_MAX_PLAN_UNIT_DURATION_MINUTES}
                      min={SWITCHBACK_TEST_MIN_PLAN_UNIT_DURATION_MINUTES}
                      placeholder={`Unit duration in minutes (max ${SWITCHBACK_TEST_MAX_PLAN_UNIT_DURATION_MINUTES.toLocaleString()})`}
                      value={unitInputs.unit_duration_minutes}
                      onChange={handleUnitInputsChange}
                    />
                  </Flex>
                  <Tooltip ml={1}>
                    {tooltipContentNewSwitchbackTest.unitDuration.content}
                  </Tooltip>
                </Flex>

                <Flex mt={2} alignItems="center" width="100%">
                  <Flex width="100%" maxWidth={rem(408)} alignItems="center">
                    <Text
                      styleName="body-3"
                      styles={{ width: rem(100), flexShrink: 0 }}
                    >
                      Start time
                      <Text
                        as="span"
                        styleName="meta-2"
                        styles={{
                          display: "block",
                          color: theme.color.gray600,
                        }}
                      >
                        (optional)
                      </Text>
                    </Text>

                    <Input
                      ml={2}
                      datePicker={
                        <DatePicker
                          orientation="bottom"
                          clearAction={handleSwitchbackStartTimeClear}
                          closeAction={handleSwitchbackStartTimeClose}
                          isActive={isSwitchbackStartTimeDatePickerActive}
                          offsetY={`calc(${theme.spacing.s8} + ${theme.spacing.s1})`}
                          primaryAction={handleSwitchbackStartTimeSelect}
                          setDateStart={setSwitchbackStartTime}
                        />
                      }
                      name="start-criteria-time"
                      htmlType="text"
                      placeholder="Date/time to start the test"
                      value={
                        switchbackStartTime
                          ? DateTime.fromISO(switchbackStartTime).toFormat(
                              "LLL dd, yyyy @ hh:mm a ZZZZ"
                            )
                          : switchbackStartTime
                      }
                      onFocus={handleSwitchbackStartTimeFocus}
                      onChange={handleSwitchbackStartTimeChange}
                    />
                  </Flex>
                  <Tooltip ml={1}>
                    {tooltipContentNewSwitchbackTest.startCriteria.content}
                  </Tooltip>
                </Flex>
              </Box>
            }
          />

          <Footer
            actionButtonLabel="Create & start switchback test"
            actionButtonMinWidth={rem(240)}
            app={app}
            endpoint="experiments/switchback"
            error={switchbackTestAddError}
            handleCancel={handleCancel}
            handleMainAction={(e) => handleSwitchbackTestCreate(e, true)}
            handleSecondaryAction={(e) => handleSwitchbackTestCreate(e)}
            isActionButtonLoading={isProcessing && isTestStarted}
            isActionButtonDisabled={isActionButtonDisabled}
            isSecondaryButtonDisabled={isActionButtonDisabled}
            isSecondaryButtonLoading={isProcessing && !isTestStarted}
            returnPath={returnPath}
            returnPathList={returnPathList}
            secondaryButtonLabel="Create draft switchback test"
            secondaryButtonMinWidth={rem(226)}
            view="create"
          />
        </form>
      </Box>
    </>
  );
};

export default NewSwitchbackTest;
