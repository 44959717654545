import styled from "@emotion/styled";

import Box from "../../../../../../components/Box";
import { styleScrollbarY } from "../../../../../../utils/constants";
import { rem } from "../../../../../../utils/tools";

export const StyledVehicleDetails = styled(Box)`
  width: 100%;
  max-height: ${rem(480)};

  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    max-height: none;
    height 100%;
  }
`;

export const StyledVehicleControlsInnerScroll = styled(Box)`
  /* manage vehicle toggle first border */
  .vehicle-route-details:first-of-type details {
    border-top: 0;
  }

  overflow-y: scroll;
  ${({ theme }) => styleScrollbarY(theme)}
`;
