import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { BatchExperimentsResponse } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import StandardError from "../../../../components/StandardError";
import { PAYLOAD_EXPERIMENT_TYPE_SCENARIO } from "../../../../config/experiments";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import { getFilteredEntities } from "../../../../utils/entities/getFilteredEntities";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../../App/App.types";
import LoadingListView from "../../../App/components/LoadingListView";
import { experimentsTooltips } from "../../data/microcopy";
import { renderExperimentsList } from "../../utils/renderExperimentsList";

const pageTitle = "Scenario Tests";

const ScenarioTests = ({ app }: AppPageProps) => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [filterText, setFilterText] = useState("");

  const {
    loadScenarioTests,
    scenarioTests,
    scenarioTestsError,
    setScenarioTests,
  } = useExperiments();

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load scenario tests if not in context already
  useEffect(() => {
    if (!scenarioTests && !scenarioTestsError) {
      loadScenarioTests({
        applicationId: app.id,
        type: PAYLOAD_EXPERIMENT_TYPE_SCENARIO,
      });
    }
  }, [app.id, loadScenarioTests, scenarioTests, scenarioTestsError]);

  if (scenarioTestsError) {
    return <StandardError errorMessage={scenarioTestsError} />;
  }

  const canUserCreateAndEditScenario = userHasAccessToAction(
    roles,
    ActionGroups.ExperimentOperator,
    {}
  );

  const filteredScenarioTests = getFilteredEntities(filterText, scenarioTests);
  const renderScenarioTests = () => {
    return renderExperimentsList({
      accountId,
      app,
      canUserCreateAndEdit: canUserCreateAndEditScenario,
      experiments: filteredScenarioTests as BatchExperimentsResponse,
      theme,
      kind: "scenario",
      urlOnClickTrackEventCategory: "Scenario",
      urlOnClickTrackEventProperties: {
        view: "Scenario Tests",
        action: "Link Clicked",
        meta: {
          type: "Scenario Test Detail",
        },
      },
    });
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: experimentsTooltips.scenario.content,
          tooltipExtraLinkLabel: experimentsTooltips.scenario.extraLinkLabel,
          tooltipExtraLinkUrl: experimentsTooltips.scenario.extraLinkUrl,
        }}
        configActionButton={{
          label: "New scenario",
          testId: "create-new-scenario-button",
          url: `${pathname}/new`,
          onClick: () =>
            trackEvent("Scenario", {
              view: "Scenario Tests",
              action: "Create Scenario Test Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditScenario,
        }}
        configFilter={{
          inputText: filterText,
          testId: "filter-scenario-tests",
          setInputText: setFilterText,
        }}
        refreshButton={{
          emptyItems: null,
          itemsLoading: !scenarioTests,
          loadItems: () =>
            loadScenarioTests({
              applicationId: app.id,
              type: PAYLOAD_EXPERIMENT_TYPE_SCENARIO,
            }),
          setItems: setScenarioTests,
        }}
      />

      <PlanNotice {...{ app }} type="custom-apps" />

      {!scenarioTests ? <LoadingListView /> : renderScenarioTests()}
    </>
  );
};

export default ScenarioTests;
