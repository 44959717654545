import React, { useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import {
  CreateEnsembleDefPayload,
  EnsembleRules,
  EnsembleRunGroups,
} from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import { AvatarEnsembleDef } from "../../../../avatars";
import Box from "../../../../components/Box";
import ControlPanel from "../../../../components/ControlPanel";
import Flex from "../../../../components/Flex";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import Text from "../../../../components/Text";
import Tooltip from "../../../../components/Tooltip";
import { ENDPOINT_ENSEMBLES } from "../../../../config/endpoints";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { getIsUserPaid } from "../../../../utils/authProviderHelpers";
import { rem } from "../../../../utils/tools";
import {
  AppPageProps,
  PendingEnsembleRule,
  PendingEnsembleRunGroup,
} from "../../App.types";
import AddEnsembleRules from "../../components/AddEnsembleRules";
import AddEnsembleRunGroups from "../../components/AddEnsembleRunGroups";
import { ensembleDefinitionTooltips } from "../../data/appTooltips";
import useReturnPaths from "../../hooks/useReturnPaths";
import { parseEnsembleRuleStatsPath } from "../../utils/parseEnsembleRuleStatsPath";
import useNewInstance from "../NewInstance/hooks/useNewInstance";

const pageTitle = "New ensemble definition";

const NewEnsembleDef = ({ app }: AppPageProps) => {
  const [user] = useUser();
  const theme = useTheme();
  const [, setMetaTitle] = useMetaTitle();
  const { returnPath, returnPathList } = useReturnPaths();

  const isUserPaid = getIsUserPaid(user);

  const [isProcessing, setIsProcessing] = useState(false);
  const [pendingRunGroups, setPendingRunGroups] = useState<
    PendingEnsembleRunGroup[]
  >([]);
  const [pendingRules, setPendingRules] = useState<PendingEnsembleRule[]>([]);

  const { loadEnsembleDefs } = useAppCollection();

  const {
    addEntity: addEnsembleDef,
    isEntityAdded: isEnsembleDefAdded,
    entityAddError: ensembleDefinitionAddError,
  } = useManageEntity(ENDPOINT_ENSEMBLES);

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
  } = useStandardInputs();

  const { convertToConfigOptions } = useNewInstance();

  // page meta display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // turn off process if error returned
  useEffect(() => {
    if (isProcessing && ensembleDefinitionAddError) {
      setIsProcessing(false);
    }
  }, [ensembleDefinitionAddError, isProcessing]);

  const transformPendingRules = (
    pendingRules: PendingEnsembleRule[]
  ): EnsembleRules => {
    return pendingRules.map((pendingRule, index) => {
      const { ruleId, objective, statisticsPath, tolerance } = pendingRule;
      return {
        id: ruleId,
        index,
        objective,
        statistics_path: parseEnsembleRuleStatsPath(statisticsPath),
        tolerance,
      };
    });
  };
  const transformPendingRunGroups = useCallback(
    (pendingRunGroups: PendingEnsembleRunGroup[]): EnsembleRunGroups => {
      return pendingRunGroups.map((pendingRunGroup) => {
        const { id, instances, configOptions, repetitions } = pendingRunGroup;
        return {
          id,
          instance_id: instances[0]?.instanceId || "",
          options: convertToConfigOptions(configOptions),
          repetitions: repetitions,
        };
      });
    },
    [convertToConfigOptions]
  );

  const handleEnsembleDefCreate = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      setIsProcessing(true);

      let payload: CreateEnsembleDefPayload = {
        ...pendingStandardInputs,
        rules: transformPendingRules(pendingRules),
        run_groups: transformPendingRunGroups(pendingRunGroups),
      };

      await addEnsembleDef(app.id, payload);
    },
    [
      addEnsembleDef,
      app.id,
      pendingRules,
      pendingRunGroups,
      pendingStandardInputs,
      transformPendingRunGroups,
    ]
  );

  const handleCancel = () => {
    trackEvent("AppInputs", {
      view: "Create App Input",
      action: "Create App Input Canceled",
    });
    return;
  };

  if (isEnsembleDefAdded) {
    trackEvent("AppInputs", {
      view: "Create App Input",
      action: "New App Input Created",
    });

    loadEnsembleDefs({ applicationId: app.id, shouldPaginate: true });
    return <Redirect to={returnPathList} />;
  }

  const isActionButtonDisabled =
    !isUserPaid ||
    !pendingStandardInputs.name ||
    !pendingStandardInputs.id ||
    !!standardInputsErrors.name ||
    !!standardInputsErrors.id;

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          ancestorIcon: <AvatarEnsembleDef />,
          ancestorLabel: "Ensembles",
          ancestorUrl: returnPathList,
        }}
      />

      <PlanNotice type="ensembling" />

      <form>
        <ControlPanel
          headerTitle="Details"
          hasNoBorder
          isOpen
          stylesDetails={{
            paddingLeft: 0,
            paddingBottom: `0 !important`,
          }}
        >
          <Box width="100%" maxWidth={rem(480)} pb={4}>
            <Input
              autoFocus={true}
              {...getStandardInputsProps({
                placeholder: "Ensemble definition name",
                testId: "new-ensemble-def-name-input",
                type: "name",
                trackEventCategory: "EnsembleDefs",
                trackEventProperties: {
                  view: "Create Ensemble Def",
                  action: "Field Entered",
                  meta: {
                    field: "name",
                  },
                },
              })}
            />
            <Input
              mt={1}
              {...getStandardInputsProps({
                placeholder: "Description (optional)",
                testId: "new-ensemble-def-description-input",
                type: "description",
                trackEventCategory: "EnsembleDefs",
                trackEventProperties: {
                  view: "Create Ensemble Def",
                  action: "Field Entered",
                  meta: {
                    field: "description",
                  },
                },
              })}
            />
            <Input
              mt={1}
              {...getStandardInputsProps({
                placeholder: "ID",
                testId: "new-ensemble-def-id-input",
                type: "id",
                trackEventCategory: "EnsembleDefs",
                trackEventProperties: {
                  view: "Create Ensemble Def",
                  action: "Ensemble Def ID Changed",
                },
              })}
            />
            <Text
              mt={1}
              styleName="meta-2"
              styles={{ color: theme.color.gray500 }}
            >
              The ID is the unique identifier for your ensemble definition
            </Text>
          </Box>
        </ControlPanel>

        <Flex mt={4} pt={5} alignItems="center" hasBorderTop>
          <Text
            as="h3"
            styleName="header-2"
            styles={{ color: theme.color.gray800 }}
          >
            Run groups
          </Text>
          <Tooltip
            ml={1}
            extraLinkLabel={
              ensembleDefinitionTooltips.create.runGroups.extraLinkLabel
            }
            extraLinkUrl={
              ensembleDefinitionTooltips.create.runGroups.extraLinkUrl
            }
          >
            {ensembleDefinitionTooltips.create.runGroups.content}
          </Tooltip>
        </Flex>

        <Box>
          <AddEnsembleRunGroups
            app={app}
            pendingRunGroups={pendingRunGroups}
            setPendingRunGroups={setPendingRunGroups}
          />
        </Box>

        <Flex mt={7} pt={5} alignItems="center" hasBorderTop>
          <Text
            as="h3"
            styleName="header-2"
            styles={{ color: theme.color.gray800 }}
          >
            Rules
          </Text>

          <Tooltip
            ml={1}
            extraLinkLabel={
              ensembleDefinitionTooltips.create.rules.extraLinkLabel
            }
            extraLinkUrl={ensembleDefinitionTooltips.create.rules.extraLinkUrl}
          >
            {ensembleDefinitionTooltips.create.rules.content}
          </Tooltip>

          <Text
            mt={rem(2)}
            ml={2}
            styleName="meta-1"
            styles={{ color: theme.color.gray600 }}
          >
            Order determines priority, highest at the top
          </Text>
        </Flex>

        <Box pb={6}>
          <AddEnsembleRules
            pendingRules={pendingRules}
            setPendingRules={setPendingRules}
          />
        </Box>

        <Footer
          actionButtonLabel="Create ensemble definition"
          app={app}
          endpoint={ENDPOINT_ENSEMBLES}
          error={ensembleDefinitionAddError}
          handleCancel={handleCancel}
          handleMainAction={handleEnsembleDefCreate}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="create"
        />
      </form>
    </>
  );
};

export default NewEnsembleDef;
