import {
  RunLogsLive,
  RunMetadata,
} from "../../../../../api/core/controlPlane.types";
import {
  RUN_FORMAT_CSV_ARCHIVE,
  RUN_FORMAT_JSON,
  RUN_STATUS_V2_CANCELED,
  RUN_STATUS_V2_FAILED,
  RUN_STATUS_V2_SUCCEEDED,
} from "../../../../../config/apps";
import { DATA_RENDER_STYLE_LIMIT_BYTES } from "../../../../../utils/constants";
import { AppRunGenre } from "../../../App.types";

const checkIsSizeBelowRenderThreshold = (size: number) => {
  return size < DATA_RENDER_STYLE_LIMIT_BYTES;
};

export const checkIsInputBelowRenderThreshold = (
  runMetadata: RunMetadata
): boolean => {
  if (!runMetadata) return true;
  return checkIsSizeBelowRenderThreshold(runMetadata.metadata.input_size);
};
export const checkIsOutputBelowRenderThreshold = (
  runMetadata: RunMetadata
): boolean => {
  if (!runMetadata) return true;
  return checkIsSizeBelowRenderThreshold(runMetadata.metadata.output_size);
};

export const checkShouldInputFileBeRendered = (size: number) => {
  return size < DATA_RENDER_STYLE_LIMIT_BYTES;
};

export const checkShouldReduceVisuals = (
  runMetadata: RunMetadata,
  type?: "input" | "output"
): boolean => {
  if (!runMetadata) return false;
  if (type === "input") {
    return runMetadata.metadata.output_size > DATA_RENDER_STYLE_LIMIT_BYTES;
  }
  return runMetadata.metadata.output_size > DATA_RENDER_STYLE_LIMIT_BYTES;
};

export const checkIsRunInputFormatJson = (
  runMetadata: RunMetadata
): boolean => {
  if (!runMetadata) return false;
  // assume JSON if no format reported
  if (!runMetadata.metadata?.format) return true;
  return runMetadata.metadata.format.input.type === RUN_FORMAT_JSON;
};

export const checkIsRunOutputFormatJson = (
  runMetadata: RunMetadata
): boolean => {
  if (!runMetadata) return false;
  // assume JSON if no format reported
  if (!runMetadata.metadata?.format) return true;
  return runMetadata.metadata.format.output.type === RUN_FORMAT_JSON;
};

export const getIsRunSucceeded = (runMetadata: RunMetadata): boolean => {
  if (!runMetadata) return false;
  return runMetadata.metadata.status_v2 === RUN_STATUS_V2_SUCCEEDED;
};

export const getIsRunResolved = (runMetadata: RunMetadata): boolean => {
  if (!runMetadata) return false;

  const status = runMetadata.metadata.status_v2;
  return (
    status === RUN_STATUS_V2_SUCCEEDED ||
    status === RUN_STATUS_V2_CANCELED ||
    status === RUN_STATUS_V2_FAILED
  );
};

export const isLogsLiveResolved = (logs: RunLogsLive): boolean => {
  if (!logs) return false;

  const status = logs.status_v2;
  return (
    status === RUN_STATUS_V2_SUCCEEDED ||
    status === RUN_STATUS_V2_CANCELED ||
    status === RUN_STATUS_V2_FAILED
  );
};

export const isRunGenreRouting = (runType: AppRunGenre): boolean => {
  if (!runType) return false;
  return ["nextroute", "router", "routing-legacy", "customer-hsd"].includes(
    runType
  );
};

export const isRunGenreScheduling = (runType: AppRunGenre): boolean => {
  if (!runType) return false;
  return ["shift-scheduling"].includes(runType);
};

export const checkIsRunInputFormatGZIP = (
  runMetadata: RunMetadata
): boolean => {
  if (!runMetadata) return false;
  // assume JSON if no format reported and NOT CSV_ARCHIVE
  if (!runMetadata.metadata?.format) return false;
  return runMetadata.metadata.format.input.type === RUN_FORMAT_CSV_ARCHIVE;
};

export const checkIsRunOutputFormatGZIP = (
  runMetadata: RunMetadata
): boolean => {
  if (!runMetadata) return false;
  // assume JSON if no format reported and NOT CSV_ARCHIVE
  if (!runMetadata.metadata?.format) return false;
  return runMetadata.metadata.format.output.type === RUN_FORMAT_CSV_ARCHIVE;
};

export const isFileTypeJson = (type: string): boolean => {
  return ["json", "application/json"].includes(type);
};

export const checkIsEveryFileBelowRenderThreshold = (files: File[]) => {
  return files.every((file) => file.size < DATA_RENDER_STYLE_LIMIT_BYTES);
};
