import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { useTheme } from "@emotion/react";

import Flex from "../../components/Flex";
import { IconDragHandle } from "../../icons";

import { StyledDragHandle, StyledSortable } from "./Sortable.styled";

type SortableProps = {
  children: React.ReactNode;
  id: string;
  itemsLength: number;
};
const Sortable = ({ id, itemsLength, children }: SortableProps) => {
  const theme = useTheme();

  const {
    attributes,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition,
      }
    : undefined;

  return (
    <StyledSortable ref={setNodeRef} style={style} {...attributes}>
      <Flex width="100%" alignItems="flex-start">
        {children}

        <StyledDragHandle
          ref={setActivatorNodeRef}
          {...listeners}
          isInactive={itemsLength < 2}
        >
          <IconDragHandle iconColor={theme.color.gray600} />
        </StyledDragHandle>
      </Flex>
    </StyledSortable>
  );
};

export default Sortable;
