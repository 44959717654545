import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { RunType } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import { AvatarApp } from "../../../../avatars";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import StandardError from "../../../../components/StandardError";
import Tabs from "../../../../components/Tabs";
import {
  RUN_DETAILS_TAB_ANALYSIS,
  RUN_DETAILS_TAB_DETAILS,
  RUN_DETAILS_TAB_ENSEMBLE,
  RUN_DETAILS_TAB_INPUT,
  RUN_DETAILS_TAB_LOGS,
  RUN_DETAILS_TAB_RESULT,
  RUN_TYPE_ENSEMBLE,
  RUN_TYPE_STANDARD,
} from "../../../../config/apps";
import { ENDPOINT_RUNS } from "../../../../config/endpoints";
import useRunDetails from "../../../../contexts/apps/hooks/useRunDetails";
import useRunInput from "../../../../contexts/apps/hooks/useRunInput";
import usePolling from "../../../../hooks/usePolling";
import { getAccUrl } from "../../../../utils/navigation";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { rem } from "../../../../utils/tools";
import { getAvatarColor } from "../../../Apps/utils/renderAppsList";
import { AppPageProps, AppRunGenre } from "../../App.types";

import RunDetailsAnalysis from "./components/RunDetailsAnalysis";
import RunDetailsCustomView from "./components/RunDetailsCustomView";
import RunDetailsDetail from "./components/RunDetailsDetail";
import RunDetailsDownloadView from "./components/RunDetailsDownloadView";
import RunDetailsEnsemble from "./components/RunDetailsEnsemble";
import RunDetailsRoutingInputAndResult from "./components/RunDetailsRoutingInputAndResult";
import RunDetailsSchedulingInput from "./components/RunDetailsSchedulingInput";
import RunDetailsSchedulingOutput from "./components/RunDetailsSchedulingOutput";
import RunLogs from "./components/RunLogs";
import { getMainViewTabs } from "./utils/getMainViewTabs";
import { getRunGenre } from "./utils/getRunGenre";
import {
  checkIsInputBelowRenderThreshold,
  checkIsOutputBelowRenderThreshold,
  checkIsRunInputFormatJson,
  checkIsRunOutputFormatJson,
  checkShouldReduceVisuals,
  getIsRunResolved,
  getIsRunSucceeded,
  isRunGenreRouting,
  isRunGenreScheduling,
} from "./utils/runDataChecks";
import { MainViewType } from "./RunDetails.types";

const RunDetails = ({ app, displayPages, setDisplayPages }: AppPageProps) => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const params = useParams() as { id: string };
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const childOf = searchParams.get("child_of");
  const childOfName = searchParams.get("child_of_name");

  const [isProcessing, setIsProcessing] = useState(false);
  const [isOutputFormatJson, setIsOutputFormatJson] = useState(false);
  const [isOutputBelowRenderThreshold, setIsOutputBelowRenderThreshold] =
    useState(false);
  const [mainView, setMainView] = useState<MainViewType>(
    RUN_DETAILS_TAB_DETAILS
  );
  const [runGenre, setRunGenre] = useState<AppRunGenre>(undefined);
  const [runType, setRunType] = useState<RunType | undefined>(undefined);

  const { cancelRunInQueue, isRunCanceled, setIsRunCanceled, runCancelError } =
    useRunDetails();

  const { loadRunInput, runInput, runInputAsString, runInputError } =
    useRunInput();

  const {
    entity: run,
    entityAsString: runAsString,
    entityMetadata: runMetadata,
    entityLoadError: runLoadError,
    entityLoadMetadataError: runMetadataLoadError,
    loadEntity: loadRun,
    loadEntityMetadata: loadRunMetadata,
    // special ensemble run-only props
    loadRunEnsembleAnalysis,
    runEnsembleAnalysis,
    runEnsembleAnalysisError,
  } = usePolling(app, ENDPOINT_RUNS, {
    isEnsembleRun: runType === RUN_TYPE_ENSEMBLE,
    shouldLoadEntity: isOutputFormatJson && isOutputBelowRenderThreshold,
  });

  const isRunResolved = getIsRunResolved(runMetadata);

  const isResultTabDisabled = !getIsRunSucceeded(runMetadata);
  const isInputTabDisabled = !isRunResolved;

  const isRouting = isRunGenreRouting(runGenre);
  const isScheduling = isRunGenreScheduling(runGenre);

  const isInputBelowRenderThreshold =
    isRunResolved && checkIsInputBelowRenderThreshold(runMetadata);
  const isInputFormatJson = checkIsRunInputFormatJson(runMetadata);

  // reduce visual styles for output that is below threshold but
  // large for visual displays (used only for CSS style rules)
  const shouldReduceVisuals = checkShouldReduceVisuals(runMetadata);

  const isWaitingForInput =
    !runInput &&
    !runInputAsString &&
    isInputBelowRenderThreshold &&
    isInputFormatJson;
  const isWaitingForOutput =
    !run && !runAsString && isOutputBelowRenderThreshold && isOutputFormatJson;

  // initial run metadata load
  useEffect(() => {
    if (params.id && !runMetadata && !runMetadataLoadError) {
      loadRunMetadata(app.id, params.id);
    }
  }, [app.id, loadRunMetadata, params.id, runMetadata, runMetadataLoadError]);

  // set run type for polling/ensemble run analysis data loading
  useEffect(() => {
    if (!runType && runMetadata) {
      setRunType(runMetadata.metadata?.run_type?.type || RUN_TYPE_STANDARD);
    }
  }, [runMetadata, runType]);

  // initial ensemble run analysis load (if run type ensemble)
  useEffect(() => {
    if (
      runType === RUN_TYPE_ENSEMBLE &&
      runMetadata &&
      !runEnsembleAnalysis &&
      !runEnsembleAnalysisError
    ) {
      loadRunEnsembleAnalysis(app.id, runMetadata.id);
    }
  }, [
    app.id,
    loadRunEnsembleAnalysis,
    runEnsembleAnalysis,
    runEnsembleAnalysisError,
    runMetadata,
    runType,
  ]);

  // manage page display
  useEffect(() => {
    if (runMetadata && !runMetadataLoadError) {
      setMetaTitle(runMetadata.name || runMetadata.id || "Run");
    }
  }, [runMetadataLoadError, runMetadata, setMetaTitle]);

  useEffect(() => {
    setIsOutputFormatJson(checkIsRunOutputFormatJson(runMetadata));
  }, [runMetadata]);

  useEffect(() => {
    if (isRunResolved) {
      setIsOutputBelowRenderThreshold(
        checkIsOutputBelowRenderThreshold(runMetadata)
      );
    }
  }, [isRunResolved, runMetadata]);

  // manage input data loading pattern
  useEffect(() => {
    const shouldLoadInput = (): boolean => {
      if (runInput) return false;
      return isInputFormatJson && isInputBelowRenderThreshold;
    };

    if (params.id && runMetadata && isWaitingForInput) {
      if (shouldLoadInput()) {
        loadRunInput(app.id, params.id);
      }
    }
  }, [
    app.id,
    isInputBelowRenderThreshold,
    isInputFormatJson,
    isWaitingForInput,
    loadRunInput,
    params.id,
    runInput,
    runMetadata,
  ]);

  // manage output data loading pattern
  useEffect(() => {
    const shouldLoadOutput = (): boolean => {
      if (run) return false;
      return isOutputFormatJson && isOutputBelowRenderThreshold;
    };

    if (params.id && runMetadata && isWaitingForOutput) {
      if (shouldLoadOutput()) {
        loadRun(app.id, params.id);
      } else {
        setRunGenre("custom");
      }
    }
  }, [
    app.id,
    isOutputBelowRenderThreshold,
    isOutputFormatJson,
    isWaitingForOutput,
    loadRun,
    params.id,
    run,
    runMetadata,
  ]);

  // set run type for layout/visualization if we have runDetails
  useEffect(() => {
    if (!runGenre && isRunResolved && !isWaitingForOutput) {
      if (isOutputFormatJson && isOutputBelowRenderThreshold) {
        setRunGenre(getRunGenre(run));
      }
    }
  }, [
    isOutputBelowRenderThreshold,
    isOutputFormatJson,
    isRunResolved,
    isWaitingForOutput,
    run,
    runGenre,
  ]);

  // manage sidebar display (hide side nav for runs with visualization)
  useEffect(() => {
    (isRouting || isScheduling) &&
      displayPages &&
      setDisplayPages &&
      setDisplayPages(false);
  }, [displayPages, isRouting, isScheduling, setDisplayPages]);

  // reset sidebar display on exit
  useEffect(() => {
    return () => {
      setDisplayPages && setDisplayPages(true);
    };
  }, [setDisplayPages]);

  // RunLogs has different polling intervals than RunDetails
  // prevent RunDetails state updates from resetting polling in RunLogs
  const RunLogsComponent = useMemo(() => {
    if (!runMetadata?.id) return null;
    return (
      <RunLogs
        appId={app.id}
        isExpanded={isRouting || isScheduling}
        runId={runMetadata.id}
        isRunResolved={isRunResolved}
      />
    );
  }, [app.id, isRouting, isRunResolved, isScheduling, runMetadata?.id]);

  if (isRunCanceled) {
    const runPostCancelActions = async () => {
      await loadRunMetadata(app.id, params.id);
      setIsProcessing(false);
      setIsRunCanceled(false);
    };

    runPostCancelActions();
  }

  const dataError = runMetadataLoadError || runLoadError || runInputError;

  if (!params.id) {
    return <Redirect to={getAccUrl(accountId, `/app/${app.id}/runs`)} />;
  }
  if (dataError) {
    return <StandardError errorMessage={dataError} />;
  }
  if (!runMetadata) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const seriesData = run && run.output?.statistics?.series_data;
  const mainViewTabs = getMainViewTabs({
    mainView,
    hasSeriesData: !!seriesData,
    isResultDisabled: isResultTabDisabled,
    isInputDisabled: isInputTabDisabled,
    isEnsembleRun: runMetadata.metadata.run_type.type === RUN_TYPE_ENSEMBLE,
    setMainView,
  });

  const getRunResultView = () => {
    if (!isOutputBelowRenderThreshold || !isOutputFormatJson) {
      return (
        <RunDetailsDownloadView
          appId={app.id}
          runMetadata={runMetadata}
          type="output"
        />
      );
    }

    // for typescript mainly, but also a fail safe
    if (!run) return null;

    if (isScheduling) {
      return (
        <RunDetailsSchedulingOutput
          appId={app.id}
          run={run}
          runAsString={runAsString}
          runInput={runInput}
          runMetadata={runMetadata}
        />
      );
    }

    return (
      <RunDetailsCustomView
        appId={app.id}
        runAsString={runAsString}
        runMetadata={runMetadata}
        type="result"
      />
    );
  };

  const getRunInputView = () => {
    if (!isInputBelowRenderThreshold || !isInputFormatJson) {
      return (
        <RunDetailsDownloadView
          appId={app.id}
          runMetadata={runMetadata}
          type="input"
        />
      );
    }

    if (isScheduling) {
      return (
        <RunDetailsSchedulingInput
          runInput={runInput}
          runInputAsString={runInputAsString}
          runMetadata={runMetadata}
          appId={app.id}
        />
      );
    }

    return (
      <RunDetailsCustomView
        runInputAsString={runInputAsString}
        runMetadata={runMetadata}
        appId={app.id}
        type="input"
      />
    );
  };

  const canCreateAndEditRun = userHasAccessToAction(
    roles,
    ActionGroups.RunOperator,
    {}
  );

  const renderView = (view: MainViewType) => {
    if (view === RUN_DETAILS_TAB_DETAILS) {
      return (
        <RunDetailsDetail
          {...{
            cancelRunInQueue,
            isOutputBelowRenderThreshold,
            isProcessing,
            runCancelError,
            runDetails: run,
            runMetadata,
            setIsProcessing,
          }}
          isExpanded={isRouting || isScheduling}
          isSubscriptionApp={!!(app.type && app.type === "subscription")}
        />
      );
    }
    // special case --
    // routing visualization is complicated and it's better for
    // the rendering experience to not separate these components
    // this will be refactored and fixed when the old cloud fleet
    // demo view is removed
    if (
      isRouting &&
      (view === RUN_DETAILS_TAB_INPUT || view === RUN_DETAILS_TAB_RESULT) &&
      run
    ) {
      return (
        <RunDetailsRoutingInputAndResult
          appId={app.id}
          runGenre={runGenre}
          isInputBelowRenderThreshold={isInputBelowRenderThreshold}
          isOutputBelowRenderThreshold={isOutputBelowRenderThreshold}
          shouldReduceVisuals={shouldReduceVisuals}
          mainView={mainView}
          run={run}
          runAsString={runAsString}
          runInput={runInput}
          runInputAsString={runInputAsString}
          runMetadata={runMetadata}
        />
      );
    }
    if (view === RUN_DETAILS_TAB_INPUT) {
      return getRunInputView();
    }
    if (view === RUN_DETAILS_TAB_RESULT) {
      return getRunResultView();
    }
    if (view === RUN_DETAILS_TAB_LOGS) {
      return RunLogsComponent;
    }
    if (view === RUN_DETAILS_TAB_ENSEMBLE) {
      return (
        <RunDetailsEnsemble
          accountId={accountId}
          app={app}
          isExpanded={isRouting || isScheduling}
          runMetadata={runMetadata}
          runEnsembleAnalysis={runEnsembleAnalysis}
          runEnsembleAnalysisError={runEnsembleAnalysisError}
        />
      );
    }
    if (view === RUN_DETAILS_TAB_ANALYSIS) {
      return (
        <RunDetailsAnalysis
          {...{ seriesData }}
          isExpanded={isRouting || isScheduling}
          hasHeaderSpace={isRouting || isScheduling}
        />
      );
    }
  };

  return (
    <>
      <Header
        isExpanded={isRouting || isScheduling}
        configPageTitle={{
          label: runMetadata.name || params.id,
          grandParentLabel: "Runs",
          grandParentUrl: getAccUrl(accountId, `/app/${app.id}/runs`),
          ...(childOf && {
            parentLabel: childOfName || childOf,
            parentUrl: getAccUrl(accountId, `/app/${app.id}/runs/${childOf}`),
          }),
          ...((isRouting || isScheduling) && {
            ancestorIcon: (
              <AvatarApp
                size={32}
                avatarColor={getAvatarColor({
                  appType: app?.type,
                  theme,
                })}
              />
            ),
            ancestorLabel: app.name,
            ancestorUrl: getAccUrl(accountId, `/app/${app.id}`),
          }),
        }}
        configActionButton={{
          label: "Edit",
          url: getAccUrl(
            accountId,
            `/app/${app.id}/run/${runMetadata.id}/edit${search ? search : ""}`
          ),
          onClick: () =>
            trackEvent("RunHistory", {
              view: "Run Details",
              action: "Edit Button Clicked",
              meta: {
                type: "Run Details Edit",
              },
            }),
          isActionAllowed: canCreateAndEditRun,
        }}
        secondaryButton={[
          {
            isActionAllowed: canCreateAndEditRun,
            label: "New run",
            url: getAccUrl(accountId, `/app/${app.id}/runs/new`),
            testId: "create-new-run-button",
          },
          {
            isActionAllowed: canCreateAndEditRun,
            label: "Clone run",
            url: getAccUrl(
              accountId,
              `/app/${app.id}/runs/new?cloneId=${runMetadata.id}`
            ),
            testId: "clone-new-run-button",
          },
        ]}
        topNavExtra={<Tabs mt={rem(3)} tabs={mainViewTabs} />}
      />

      {renderView(mainView)}
    </>
  );
};

export default RunDetails;
