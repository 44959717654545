import React, { useMemo } from "react";
import { useTheme } from "@emotion/react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  RectangleProps,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { getYAxisWidth } from "../../../components/Chart/Chart.component";
import { CustomChartStyleRules } from "../../../components/Chart/Chart.styled";
import CustomLabel from "../../../components/Chart/custom/Label";
import { getTickLabelStyle } from "../../../components/Chart/utils/chartStyles";
import { GroupedSummaryChartBoxPlotData } from "../Experiments.types";
import { formatIndicatorValue } from "../utils/groupedSummaryTable";

import CustomTooltipBoxPlot from "./CustomTooltipBoxPlot";
import { getRange } from "./utils";

type ChartBoxPlotData = GroupedSummaryChartBoxPlotData | null;
type ChartBoxPlotProps = {
  data: ChartBoxPlotData[];
  indicator: string;
  yAxisLabel: string;
};

/*
  NOTE
  Recharts the library we use for plots and graphs does not support
  box plots (or whisker plots) by default. So they are created using
  a hack of sorts.
*/
const ChartBoxPlot = ({ data, indicator, yAxisLabel }: ChartBoxPlotProps) => {
  const theme = useTheme();
  const yAxisWidth = getYAxisWidth({
    key: "max",
    data: data,
    extraPadding: 32,
  });
  const tickLabelStyle = getTickLabelStyle(theme);

  const HorizonBar = (props: RectangleProps & { payload: any }) => {
    const { x, y, width, height, payload } = props;

    if (
      !payload.shouldRender ||
      x == null ||
      y == null ||
      width == null ||
      height == null
    ) {
      return null;
    }

    return (
      <line
        x1={x + width / 4}
        y1={y}
        x2={x + width / 4 + width / 2}
        y2={y}
        stroke={theme.color.gray600}
        strokeWidth={1.5}
      />
    );
  };

  const MiddleBar = (props: RectangleProps & { payload: any }) => {
    const { x, y, width, height, payload } = props;

    if (
      !payload.shouldRender ||
      x == null ||
      y == null ||
      width == null ||
      height == null
    ) {
      return null;
    }

    return (
      <line
        x1={x}
        y1={y}
        x2={x + width}
        y2={y}
        stroke={theme.color.gray600}
        strokeWidth={1}
        z={10}
        style={{ zIndex: 100 }}
      />
    );
  };

  const VerticalBar = (props: RectangleProps & { payload: any }) => {
    const { x, y, width, height, payload } = props;

    if (
      !payload.shouldRender ||
      x == null ||
      y == null ||
      width == null ||
      height == null
    ) {
      return null;
    }

    return (
      <line
        x1={x + width / 2}
        y1={y + height}
        x2={x + width / 2}
        y2={y}
        stroke={theme.color.gray600}
        strokeWidth={2}
      />
    );
  };

  const getTooltipTitle = (
    chartData: ChartBoxPlotData,
    addKey: boolean
  ): string => {
    const potentialGroupKeys =
      chartData?.groupKeys &&
      !!chartData?.groupKeys.length &&
      chartData.groupKeys;
    const potentialGroupValues =
      chartData?.groupValues &&
      !!chartData?.groupValues.length &&
      chartData.groupValues;

    if (
      !potentialGroupKeys ||
      !potentialGroupKeys.length ||
      !potentialGroupValues ||
      !potentialGroupValues.length
    ) {
      return "";
    }

    const tooltipContent = potentialGroupValues
      .map((groupValue, index) => {
        if (!groupValue) return "";
        return addKey
          ? `${potentialGroupKeys[index]}: ${groupValue}`
          : groupValue;
      })
      .filter(Boolean);

    return tooltipContent.join(", ");
  };

  const dataPointsExistAndAreNumbers = (chart: ChartBoxPlotData) => {
    const statsToCheck: (keyof Omit<
      GroupedSummaryChartBoxPlotData,
      "groupKeys" | "groupValues"
    >)[] = [
      "average",
      "min",
      "percentile25",
      "median",
      "percentile75",
      "max",
      "median",
    ];
    return (
      !!chart &&
      statsToCheck.every((stat) => {
        const chartValue = chart[stat];
        return chartValue !== undefined && !isNaN(chartValue);
      })
    );
  };

  const chartData = useMemo(
    () =>
      data.map((chart) => {
        const shouldRender = dataPointsExistAndAreNumbers(chart);
        return (
          chart && {
            shouldRender: shouldRender,
            // value:   minimum
            // use:     draw transparent bar to lift the box plot to minimum value
            // display: minimum value
            min: !shouldRender ? null : chart.min,
            // value:   difference between 25 percentile and the minimum
            // use:     draw vertical line from min value to 25 percentile value
            // display: 25 percentile
            "25th-percentile": !shouldRender
              ? null
              : chart.percentile25! - chart.min!,
            // value:   difference between median and 25 percentile
            // use:     draw a box from 25 percentile to median
            // display: median
            median: !shouldRender ? null : chart.median! - chart.percentile25!,
            // value:   difference between 75 percentile and median
            // use:     draw a box from median to 75 percentile
            // display: 75 percentile
            "75th-percentile": !shouldRender
              ? null
              : chart.percentile75! - chart.median!,
            // value:   difference between max and 75 percentile
            // use:     draw a vertical line from 75 percentile to max
            // display: max
            max: !shouldRender ? null : chart.max! - chart.percentile75!,
            mean: !shouldRender ? null : chart.average!,
            size: 150,
            // values used for the tooltip display (see notes above)
            // keys correspond to top-level chart data keys above
            display: {
              min: chart.min,
              "25th-percentile": chart.percentile25,
              median: chart.median,
              "75th-percentile": chart.percentile75,
              max: chart.max,
              mean: chart.average,
            },
            xAxisLabel: getTooltipTitle(chart, false),
            tooltipTitle: getTooltipTitle(chart, true),
          }
        );
      }),
    [data]
  );

  const yRange = getRange(
    data
      .map((d: ChartBoxPlotData) => d?.min || 0)
      .concat(data.map((d: ChartBoxPlotData) => d?.max || 0))
  );

  return (
    <CustomChartStyleRules
      className={`chart-style-grouped-summary-box-plot`}
      yAxisWidth={yAxisWidth || 0}
      style={{ width: "100%", position: "relative" }}
    >
      <ResponsiveContainer minHeight={300}>
        <ComposedChart data={chartData}>
          <CartesianGrid
            stroke={theme.color.gray200T}
            vertical={false}
            style={{
              backgroundColor: theme.color.gray100,
            }}
          />

          <Bar
            barSize={64}
            stackId={"a"}
            dataKey={"min"}
            fill="transparent"
            isAnimationActive={false}
          />
          <Bar
            barSize={64}
            stackId={"a"}
            dataKey={"bar"}
            shape={<HorizonBar />}
            isAnimationActive={false}
          />
          <Bar
            barSize={64}
            stackId={"a"}
            dataKey={"25th-percentile"}
            shape={<VerticalBar />}
            isAnimationActive={false}
          />
          <Bar
            barSize={64}
            stackId={"a"}
            dataKey={"median"}
            fill="transparent"
            stroke={theme.color.gray600}
            strokeWidth={1.5}
            isAnimationActive={false}
          />
          <Bar
            barSize={64}
            stackId={"a"}
            dataKey={"bar"}
            stroke={theme.color.red600}
            strokeWidth={1.5}
            shape={<MiddleBar />}
            isAnimationActive={false}
          />
          <Bar
            barSize={64}
            stackId={"a"}
            dataKey={"75th-percentile"}
            fill="transparent"
            stroke={theme.color.gray600}
            strokeWidth={1.5}
            isAnimationActive={false}
          />
          <Bar
            barSize={64}
            stackId={"a"}
            dataKey={"max"}
            shape={<VerticalBar />}
            isAnimationActive={false}
          />
          <Bar
            barSize={64}
            stackId={"a"}
            dataKey={"bar"}
            stroke={theme.color.red600}
            strokeWidth={1.5}
            shape={<HorizonBar />}
            isAnimationActive={false}
          />
          <Scatter
            dataKey="mean"
            fill={theme.color.chartRed}
            stroke={theme.color.white}
            strokeWidth={2}
            isAnimationActive={false}
          />

          <Tooltip
            content={<CustomTooltipBoxPlot {...{ indicator }} />}
            isAnimationActive={false}
            cursor={{ stroke: theme.color.gray300, strokeWidth: 1 }}
          />

          <XAxis
            dataKey="xAxisLabel"
            domain={["dataMin", "dataMax"]}
            padding={{ left: 24, right: 24 }}
            stroke={theme.color.gray300}
            style={tickLabelStyle}
            tickMargin={8}
            tickSize={0}
          />

          <YAxis
            label={<CustomLabel isYAxis>{yAxisLabel}</CustomLabel>}
            padding={{ top: 16 }}
            interval="preserveStart"
            stroke={theme.color.gray300}
            style={tickLabelStyle}
            tickCount={5}
            tickMargin={4}
            tickSize={0}
            tickFormatter={(value) =>
              formatIndicatorValue({
                indicator,
                type: "chart-value",
                value,
              })?.toString() || ""
            }
            width={yAxisWidth}
            domain={[yRange.min, yRange.max]}
            allowDataOverflow={true}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </CustomChartStyleRules>
  );
};

export default ChartBoxPlot;
