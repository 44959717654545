import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { useUser } from "../../AuthProvider";
import { getAccUrl } from "../../utils/navigation";

import Conversation from "./subpages/Conversation";
import EditWebhook from "./subpages/EditWebhook/EditWebhook";
import Webhook from "./subpages/Webhook";
import { getPathWithoutConversation } from "./utils/getPathWithoutConversation";

type WebhookRoutesProps = {
  path: string;
};

const WebhooksRoutes = ({ path }: WebhookRoutesProps) => {
  const [user] = useUser();
  const { pathname } = useLocation();
  const { id: accountId } = user;

  return (
    <Switch>
      <Route exact path={`${path}/:webhookId/conversation`}>
        <Redirect to={getPathWithoutConversation(pathname)} />
      </Route>

      <Route exact path={`${path}/:webhookId/conversation/:id`}>
        <Conversation />
      </Route>

      <Route exact path={`${path}/:id/edit`}>
        <EditWebhook />
      </Route>

      <Route exact path={`${path}/:id`}>
        <Webhook />
      </Route>

      <Redirect to={getAccUrl(accountId, `/apps`)} />
    </Switch>
  );
};

export default WebhooksRoutes;
