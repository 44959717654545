import { chartColors } from "../../../tokens/color";
import { ConfigInput, Coords, MarkerPoint, VehicleType } from "../Map.types";

const getLabelFromPosition = (position: Coords): string => {
  return `${position[0]}-${position[1]}`;
};

function extractDepots(vehicleMarkerArray: MarkerPoint[]): MarkerPoint[] {
  // May need to round coordinates (may be different coming from vehicles
  // or defaults).
  const checkedCoords: string[] = [];
  const depotMap: { [key: string]: MarkerPoint } = {};
  const nonDepotMarkers: MarkerPoint[] = [];

  const depotCoords: string[] = vehicleMarkerArray.reduce(
    (prevDepotCoords, vehicleMarker) => {
      const labelFromPosition = getLabelFromPosition(vehicleMarker.position);
      if (checkedCoords.includes(labelFromPosition)) {
        checkedCoords.push(labelFromPosition);
        prevDepotCoords.push(labelFromPosition);
        return prevDepotCoords;
      }
      checkedCoords.push(labelFromPosition);
      return prevDepotCoords;
    },
    [] as string[]
  );

  vehicleMarkerArray.forEach((vehicleMarker) => {
    const labelFromPosition = getLabelFromPosition(vehicleMarker.position);
    if (
      depotCoords.includes(labelFromPosition) &&
      labelFromPosition in depotMap
    ) {
      // update existing depot with new vehicle info
      depotMap[labelFromPosition].depotInfo?.push({
        vehicleId: vehicleMarker.vehicleId,
        vehicleKind: vehicleMarker.kind,
        vehicleTextColor: vehicleMarker.color,
      });
    } else if (depotCoords.includes(labelFromPosition)) {
      // create a new depot and add it to the map
      depotMap[labelFromPosition] = {
        kind: "depot",
        depotId: `depot-${vehicleMarker.position[0]}-${vehicleMarker.position[1]}`,
        name: `depot-${vehicleMarker.vehicleId}`,
        position: vehicleMarker.position,
        depotInfo: [
          {
            vehicleId: vehicleMarker.vehicleId,
            vehicleKind: vehicleMarker.kind,
            vehicleTextColor: vehicleMarker.color,
          },
        ],
      };
    } else {
      // just a vehicle not at a depot - leave untouched
      nonDepotMarkers.push(vehicleMarker);
    }
  });

  // adding depots to vehicle markers
  return nonDepotMarkers.concat(Object.values(depotMap));
}

export default function getInputVehiclePoints(input: ConfigInput) {
  const {
    start: vehicleDefaultStart,
    end: vehicleDefaultEnd,
    start_location: nextrouteVehicleDefaultStart,
    end_location: nextrouteVehicleDefaultEnd,
  } = input.defaults?.vehicles || ({} as VehicleType);

  const vehicles = Array.isArray(input.vehicles) ? input.vehicles : [];

  const vehiclePoints: MarkerPoint[] = vehicles.reduce(
    (previousVehiclePoints, vehicle, i) => {
      const {
        start: vehicleStart,
        end: vehicleEnd,
        start_location: nextrouteVehicleStart,
        end_location: nextrouteVehicleEnd,
      } = vehicle;

      const startPoint =
        nextrouteVehicleStart ||
        vehicleStart ||
        nextrouteVehicleDefaultStart ||
        vehicleDefaultStart;
      const endPoint =
        nextrouteVehicleEnd ||
        vehicleEnd ||
        nextrouteVehicleDefaultEnd ||
        vehicleDefaultEnd;
      const color = chartColors[i % chartColors.length];

      // setting start point marker
      if (
        startPoint &&
        typeof startPoint.lat === "number" &&
        typeof startPoint.lon === "number"
      ) {
        previousVehiclePoints.push({
          position: [startPoint.lat, startPoint.lon] as Coords,
          vehicleId: vehicle.id,
          kind: "start",
          name: `${vehicle.id}-start`,
          color,
        });
      }

      // setting end point marker
      if (
        endPoint &&
        typeof endPoint.lat === "number" &&
        typeof endPoint.lon === "number"
      ) {
        previousVehiclePoints.push({
          position: [endPoint.lat, endPoint.lon] as Coords,
          vehicleId: vehicle.id,
          kind: "end",
          name: `${vehicle.id}-end`,
          color,
        });
      }

      return previousVehiclePoints;
    },
    [] as MarkerPoint[]
  );

  return extractDepots(vehiclePoints);
}
