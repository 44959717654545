import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";

import Box from "../../../../components/Box";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import StandardError from "../../../../components/StandardError";
import { useMarketplaceCollection } from "../../../../contexts/marketplace/Marketplace.context";
import CustomConstraintCta from "../../components/CustomConstraintCta";
import MarketplaceAppFeatureTable from "../../components/MarketplaceAppFeatureTable";
import MarketplaceAppHeader from "../../components/MarketplaceAppHeader";
import MarketplaceAppScreenshots from "../../components/MarketplaceAppScreenshots";
import { MarketplaceAppPageProps } from "../../MarketplaceApp.types";

const marketplaceAppNotFoundMessage = "Marketplace App Not Found";

const MarketplaceDetails = ({
  marketplacePartnerApp,
  marketplacePartnerAppError,
  setDisplayPages,
}: MarketplaceAppPageProps) => {
  const theme = useTheme();
  const [, setMetaTitle] = useMetaTitle();
  const subscriptionId =
    marketplacePartnerApp &&
    `${marketplacePartnerApp.partner_id}-${marketplacePartnerApp.id}`;

  const { appSubscriptions, appSubscriptionsLoading, loadAppSubscriptions } =
    useMarketplaceCollection();

  // manage page display
  useEffect(() => {
    setMetaTitle(
      marketplacePartnerApp
        ? marketplacePartnerApp.name
        : marketplaceAppNotFoundMessage
    );
    setDisplayPages && setDisplayPages(true);
  }, [marketplacePartnerApp, setDisplayPages, setMetaTitle]);

  // load app subscriptions into context if not already present
  // keep in context so we don't have to reload them each time
  // a marketplace app is viewed
  useEffect(() => {
    if (!appSubscriptions.length && appSubscriptionsLoading) {
      loadAppSubscriptions();
    }
  }, [appSubscriptions.length, appSubscriptionsLoading, loadAppSubscriptions]);

  if (
    appSubscriptionsLoading ||
    (!marketplacePartnerApp && !marketplacePartnerAppError)
  ) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }
  if (marketplacePartnerAppError) {
    return <StandardError errorMessage={marketplacePartnerAppError} />;
  }

  return (
    <Box>
      {marketplacePartnerApp ? (
        <MarketplaceAppHeader
          marketplaceAppId={marketplacePartnerApp.id}
          partnerId={marketplacePartnerApp.partner_id}
          codeRepoLink={marketplacePartnerApp.codeRepoLink}
          docsLink={marketplacePartnerApp.docsLink}
          image={marketplacePartnerApp.image}
          summary={marketplacePartnerApp.summary}
          tags={marketplacePartnerApp.tags}
          title={marketplacePartnerApp.name}
        />
      ) : (
        <MarketplaceAppHeader
          title={marketplaceAppNotFoundMessage}
          summary="Try starting from the Marketplace landing page."
        />
      )}

      {marketplacePartnerApp?.screenshot && (
        <MarketplaceAppScreenshots
          imageUrl={marketplacePartnerApp.screenshot.url}
          imageAlt={marketplacePartnerApp.screenshot.alt}
        />
      )}

      {subscriptionId && marketplacePartnerApp?.features_v2 && (
        <Box mb={subscriptionId === "nextmv-routing.legacy" ? 10 : 8}>
          <MarketplaceAppFeatureTable
            {...{ subscriptionId }}
            constraintData={marketplacePartnerApp.features_v2}
            docsLink={marketplacePartnerApp?.docsLink}
          />
        </Box>
      )}

      {subscriptionId !== "nextmv-routing.legacy" && (
        <Box mb={10} ml={[2, 6, 7]} mr={[3, 6, 9]}>
          <CustomConstraintCta
            marketplaceAppId={marketplacePartnerApp?.id}
            marketAppName={marketplacePartnerApp?.name}
            docsLink={marketplacePartnerApp?.docsLink}
          />
        </Box>
      )}
    </Box>
  );
};

export default MarketplaceDetails;
