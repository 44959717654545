import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";

import { IconCloseLarge } from "../../icons";
import Button2 from "../Button2";
import Flex from "../Flex";
import Text from "../Text";

import { getRandomIdentifier, handleClickOutside } from "./utils/modalDisplay";
import { StyledCloseLink, StyledModal, StyledScrim } from "./Modal2.styled";
import { Modal2Props } from "./Modal2.types";

const Modal2 = ({
  children,
  closeActionOnClick,
  footerExtra,
  headerExtra,
  htmlId,
  isActive,
  maxHeight,
  primaryActionIcon,
  primaryActionIcon2,
  primaryActionLabel,
  primaryActionOnClick,
  primaryActionStyles,
  primaryIsDisabled,
  primaryIsLoading,
  secondaryActionLabel,
  secondaryActionOnClick,
  secondaryActionStyles,
  summary,
  title,
  width,
}: Modal2Props) => {
  const theme = useTheme();
  const randomIdentifier = getRandomIdentifier();
  const scrimHtmlId = `modal-scrim-${randomIdentifier}`;

  const handleKeyDown = (e: KeyboardEvent) => {
    if (isActive && e.key === "Escape") {
      closeActionOnClick(e);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  const hasPrimaryAction = !!primaryActionLabel && !!primaryActionOnClick;
  const hasSecondaryAction = !!secondaryActionLabel && !!secondaryActionOnClick;

  return (
    <StyledScrim
      {...{
        id: scrimHtmlId,
        isActive,
        hasBlur: true,
        onClick: (e) =>
          handleClickOutside({
            closeAction: closeActionOnClick,
            isActive,
            e,
            targetId: scrimHtmlId,
          }),
      }}
    >
      <StyledModal
        id={htmlId}
        {...{ isActive, maxHeight, width }}
        mt={[0, -8]}
        pt={[5, 7]}
        pr={[5, 7]}
        pb={[6, 8]}
        pl={[6, 8]}
      >
        <StyledCloseLink
          onClick={closeActionOnClick}
          right={theme.spacing.s6}
          top={theme.spacing.s6}
        >
          <IconCloseLarge iconColor={theme.color.gray800} />
        </StyledCloseLink>

        {title &&
          (typeof title === "string" ? (
            <Text
              as="h3"
              styleName="header-1"
              styles={{ color: theme.color.gray800 }}
            >
              {title}
            </Text>
          ) : (
            title
          ))}
        {summary &&
          (typeof summary === "string" ? (
            <Text mt={title ? 2 : 0} styleName="body-1">
              {summary}
            </Text>
          ) : (
            summary
          ))}

        {headerExtra}

        {children}

        {footerExtra}

        {(hasPrimaryAction || hasSecondaryAction) && (
          <Flex mt={6} alignItems="center">
            {hasPrimaryAction && (
              <Button2
                testId="modal-primary-button"
                size="large"
                label={primaryActionLabel}
                onClick={primaryActionOnClick}
                isLoading={primaryIsLoading}
                isDisabled={primaryIsDisabled}
                styles={primaryActionStyles}
                {...(primaryActionIcon && {
                  icon: primaryActionIcon,
                })}
                {...(primaryActionIcon2 && {
                  icon2: primaryActionIcon2,
                })}
              />
            )}
            {hasSecondaryAction && (
              <Button2
                ml={hasPrimaryAction ? "auto" : 0}
                type="text"
                label={secondaryActionLabel}
                onClick={secondaryActionOnClick}
                styles={secondaryActionStyles}
              />
            )}
          </Flex>
        )}
      </StyledModal>
    </StyledScrim>
  );
};

export default Modal2;
