import { rem } from "../utils/tools";

// font styles
const body1FontSize: string = rem(16);
const body1LineHeight: string = rem(24);
const body1PlusFontSize: string = rem(17);
const body1PlusLineHeight: string = rem(25);
const body2FontSize: string = rem(15);
const body2LineHeight: string = rem(22);
const body3FontSize: string = rem(14);
const body3LineHeight: string = rem(20);
const codeFontSize: string = rem(14);
const codeLineHeight: string = rem(24);
const header1FontSize: string = rem(18);
const header1LineHeight: string = rem(22);
const header2FontSize: string = rem(17);
const header2LineHeight: string = rem(21);
const labelFontSize: string = rem(12);
const labelLineHeight: string = rem(16);
const labelLetterSpacing: string = "0.125em"; // 1.5 / 12
const marketingHeader3FontSize: string = rem(24);
const marketingHeader3LineHeight: string = rem(31);
const marketingHeader4FontSize: string = rem(21);
const marketingHeader4LineHeight: string = rem(25);
const meta1FontSize: string = rem(13);
const meta1LineHeight: string = rem(18);
const meta2FontSize: string = rem(12);
const meta2LineHeight: string = rem(16);
const meta2LetterSpacing: string = "0.021em"; // .25 / 12

// generic font properties
const ui2FontFamilyBase: string = `"L10-Web", "system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "sans-serif", "L10-Web"`;
const ui2FontFamilyHeadline: string = `"SplineSans-Web", "system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "sans-serif", "SplineSans-Web"`;
const ui2FontFamilyMono: string = `"IBMPlexMonoWeb", "SFMono-Regular", "Consolas", "Liberation Mono", "Menlo", "monospace", "IBMPlexMonoWeb"`;
const fontStyleNormal: string = "normal";
const fontStyleItalic: string = "italic";
const fontWeightNormal: number = 400;
const fontWeightMedium: number = 500;
const fontWeightSemibold: number = 600;
const letterSpacingNone: number = 0;
const textTransformNone: string = "none";
const textTransformUppercase: string = "uppercase";

export const ui2 = {
  // font-family
  fontFamilyBody1: ui2FontFamilyBase,
  fontFamilyBody1Bold: ui2FontFamilyBase,
  fontFamilyBody1Italic: ui2FontFamilyBase,
  fontFamilyBody1Plus: ui2FontFamilyBase,
  fontFamilyBody1PlusBold: ui2FontFamilyBase,
  fontFamilyBody2: ui2FontFamilyBase,
  fontFamilyBody2Italic: ui2FontFamilyBase,
  fontFamilyBody2Bold: ui2FontFamilyBase,
  fontFamilyBody3: ui2FontFamilyBase,
  fontFamilyBody3Italic: ui2FontFamilyBase,
  fontFamilyBody3Bold: ui2FontFamilyBase,
  fontFamilyCode: ui2FontFamilyMono,
  fontFamilyCodeBold: ui2FontFamilyMono,
  fontFamilyHeader1: ui2FontFamilyBase,
  fontFamilyHeader2: ui2FontFamilyBase,
  fontFamilyLabel: ui2FontFamilyBase,
  fontFamilyMarketingHeader3: ui2FontFamilyHeadline,
  fontFamilyMarketingHeader4: ui2FontFamilyHeadline,
  fontFamilyMeta1: ui2FontFamilyBase,
  fontFamilyMeta1Bold: ui2FontFamilyBase,
  fontFamilyMeta2: ui2FontFamilyBase,
  fontFamilyMeta2Bold: ui2FontFamilyBase,
  // font-size
  fontSizeBody1: body1FontSize,
  fontSizeBody1Bold: body1FontSize,
  fontSizeBody1Italic: body1FontSize,
  fontSizeBody1Plus: body1PlusFontSize,
  fontSizeBody1PlusBold: body1PlusFontSize,
  fontSizeBody2: body2FontSize,
  fontSizeBody2Italic: body2FontSize,
  fontSizeBody2Bold: body2FontSize,
  fontSizeBody3: body3FontSize,
  fontSizeBody3Italic: body3FontSize,
  fontSizeBody3Bold: body3FontSize,
  fontSizeCode: codeFontSize,
  fontSizeCodeBold: codeFontSize,
  fontSizeHeader1: header1FontSize,
  fontSizeHeader2: header2FontSize,
  fontSizeLabel: labelFontSize,
  fontSizeMarketingHeader3: marketingHeader3FontSize,
  fontSizeMarketingHeader4: marketingHeader4FontSize,
  fontSizeMeta1: meta1FontSize,
  fontSizeMeta1Bold: meta1FontSize,
  fontSizeMeta2: meta2FontSize,
  fontSizeMeta2Bold: meta2FontSize,
  // font-style
  fontStyleBody1: fontStyleNormal,
  fontStyleBody1Bold: fontStyleNormal,
  fontStyleBody1Italic: fontStyleItalic,
  fontStyleBody1Plus: fontStyleNormal,
  fontStyleBody1PlusBold: fontStyleNormal,
  fontStyleBody2: fontStyleNormal,
  fontStyleBody2Italic: fontStyleItalic,
  fontStyleBody2Bold: fontStyleNormal,
  fontStyleBody3: fontStyleNormal,
  fontStyleBody3Italic: fontStyleItalic,
  fontStyleBody3Bold: fontStyleNormal,
  fontStyleCode: fontStyleNormal,
  fontStyleCodeBold: fontStyleNormal,
  fontStyleHeader1: fontStyleNormal,
  fontStyleHeader2: fontStyleNormal,
  fontStyleLabel: fontStyleNormal,
  fontStyleMarketingHeader3: fontStyleNormal,
  fontStyleMarketingHeader4: fontStyleNormal,
  fontStyleMeta1: fontStyleNormal,
  fontStyleMeta1Bold: fontStyleNormal,
  fontStyleMeta2: fontStyleNormal,
  fontStyleMeta2Bold: fontStyleNormal,
  // font-weight
  fontWeightBody1: fontWeightNormal,
  fontWeightBody1Bold: fontWeightMedium,
  fontWeightBody1Italic: fontWeightNormal,
  fontWeightBody1Plus: fontWeightNormal,
  fontWeightBody1PlusBold: fontWeightMedium,
  fontWeightBody2: fontWeightNormal,
  fontWeightBody2Italic: fontWeightNormal,
  fontWeightBody2Bold: fontWeightMedium,
  fontWeightBody3: fontWeightNormal,
  fontWeightBody3Italic: fontWeightNormal,
  fontWeightBody3Bold: fontWeightMedium,
  fontWeightCode: fontWeightNormal,
  fontWeightCodeBold: fontWeightSemibold,
  fontWeightHeader1: fontWeightMedium,
  fontWeightHeader2: fontWeightMedium,
  fontWeightLabel: fontWeightMedium,
  fontWeightMarketingHeader3: fontWeightMedium,
  fontWeightMarketingHeader4: fontWeightMedium,
  fontWeightMeta1: fontWeightNormal,
  fontWeightMeta1Bold: fontWeightMedium,
  fontWeightMeta2: fontWeightNormal,
  fontWeightMeta2Bold: fontWeightMedium,
  // line-height
  lineHeightBody1: body1LineHeight,
  lineHeightBody1Bold: body1LineHeight,
  lineHeightBody1Italic: body1LineHeight,
  lineHeightBody1Plus: body1PlusLineHeight,
  lineHeightBody1PlusBold: body1PlusLineHeight,
  lineHeightBody2: body2LineHeight,
  lineHeightBody2Italic: body2LineHeight,
  lineHeightBody2Bold: body2LineHeight,
  lineHeightBody3: body3LineHeight,
  lineHeightBody3Italic: body3LineHeight,
  lineHeightBody3Bold: body3LineHeight,
  lineHeightCode: codeLineHeight,
  lineHeightCodeBold: codeLineHeight,
  lineHeightHeader1: header1LineHeight,
  lineHeightHeader2: header2LineHeight,
  lineHeightLabel: labelLineHeight,
  lineHeightMarketingHeader3: marketingHeader3LineHeight,
  lineHeightMarketingHeader4: marketingHeader4LineHeight,
  lineHeightMeta1: meta1LineHeight,
  lineHeightMeta1Bold: meta1LineHeight,
  lineHeightMeta2: meta2LineHeight,
  lineHeightMeta2Bold: meta2LineHeight,
  // letter-spacing
  letterSpacingBody1: letterSpacingNone,
  letterSpacingBody1Bold: letterSpacingNone,
  letterSpacingBody1Italic: letterSpacingNone,
  letterSpacingBody1Plus: letterSpacingNone,
  letterSpacingBody1PlusBold: letterSpacingNone,
  letterSpacingBody2: letterSpacingNone,
  letterSpacingBody2Italic: letterSpacingNone,
  letterSpacingBody2Bold: letterSpacingNone,
  letterSpacingBody3: letterSpacingNone,
  letterSpacingBody3Italic: letterSpacingNone,
  letterSpacingBody3Bold: letterSpacingNone,
  letterSpacingCode: letterSpacingNone,
  letterSpacingCodeBold: letterSpacingNone,
  letterSpacingLabel: labelLetterSpacing,
  letterSpacingMarketingHeader3: letterSpacingNone,
  letterSpacingMarketingHeader4: letterSpacingNone,
  letterSpacingMeta1: letterSpacingNone,
  letterSpacingMeta1Bold: letterSpacingNone,
  letterSpacingMeta2: meta2LetterSpacing,
  letterSpacingMeta2Bold: meta2LetterSpacing,
  letterSpacingHeader1: letterSpacingNone,
  letterSpacingHeader2: letterSpacingNone,
  // text-transform
  textTransformBody1: textTransformNone,
  textTransformBody1Bold: textTransformNone,
  textTransformBody1Italic: textTransformNone,
  textTransformBody1Plus: textTransformNone,
  textTransformBody1PlusBold: textTransformNone,
  textTransformBody2: textTransformNone,
  textTransformBody2Italic: textTransformNone,
  textTransformBody2Bold: textTransformNone,
  textTransformBody3: textTransformNone,
  textTransformBody3Italic: textTransformNone,
  textTransformBody3Bold: textTransformNone,
  textTransformCode: textTransformNone,
  textTransformCodeBold: textTransformNone,
  textTransformHeader1: textTransformNone,
  textTransformHeader2: textTransformNone,
  textTransformLabel: textTransformUppercase,
  textTransformMarketingHeader3: textTransformNone,
  textTransformMarketingHeader4: textTransformNone,
  textTransformMeta1: textTransformNone,
  textTransformMeta1Bold: textTransformNone,
  textTransformMeta2: textTransformNone,
  textTransformMeta2Bold: textTransformNone,
};
