import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import {
  CreateAcceptanceTestPayload,
  CreateBatchExperimentPayload,
} from "../../../../api/core/controlPlane.types";
import { AvatarExperimentAcceptance } from "../../../../avatars";
import Box from "../../../../components/Box";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import InstanceSelect from "../../../../components/InstanceSelect";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import RowDetail from "../../../../components/RowDetail";
import { PAYLOAD_EXPERIMENT_TYPE_BATCH } from "../../../../config/experiments";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import AcceptanceMetricSelect from "../../components/AcceptanceMetricsSelect";
import SelectInputSet from "../../components/SelectInputSet";
import { acceptanceTooltips, sharedTooltipCopy } from "../../data/microcopy";
import { getSafeCloneName } from "../../utils/getSafeCloneName";

import useNewAcceptanceTest from "./hooks/useNewAcceptanceTest";

const pageTitle = "Create new test";

const NewAcceptanceTest = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const { returnPath, returnPathList } = useReturnPaths();
  const theme = useTheme();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const cloneId = searchParams.get("cloneId");

  const [isProcessing, setIsProcessing] = useState(false);
  const [pendingInputSetId, setPendingInputSetId] = useState<string>("");
  const [pendingBaselineInstanceIds, setPendingBaselineInstanceIds] = useState<
    (string | undefined)[]
  >([]);
  const [pendingCandidateInstanceIds, setPendingCandidateInstanceIds] =
    useState<(string | undefined)[]>([]);

  const { loadBatchExperiments, loadAcceptanceTests } = useExperiments();

  const {
    addEmptyMetric,
    handleMetricInputChange,
    handleMetricSelectCompare,
    pendingAcceptanceTestMetrics,
    removeMetric,
    setPendingAcceptanceTestMetrics,
  } = useNewAcceptanceTest();

  const {
    addEntity: addAcceptanceTest,
    entity: acceptanceTest,
    entityAddError: acceptanceTestAddError,
    entityLoadError: acceptanceTestLoadError,
    isEntityAdded: isAcceptanceTestAdded,
    loadEntity: loadAcceptanceTest,
  } = useManageEntity("experiments/acceptance");
  const {
    addEntity: addExperiment,
    entity: batchExperiment,
    entityAddError: batchExperimentAddError,
    entityLoadError: batchExperimentLoadError,
    loadEntity: loadBatchExperiment,
  } = useManageEntity("experiments/batch");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    updateStandardInputs,
    standardInputsErrors,
  } = useStandardInputs(app, "experiments/acceptance");

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // add empty id for initial instance selection
  useEffect(() => {
    if (!pendingBaselineInstanceIds.length) {
      setPendingBaselineInstanceIds([""]);
    }
    if (!pendingCandidateInstanceIds.length) {
      setPendingCandidateInstanceIds([""]);
    }
  }, [pendingBaselineInstanceIds.length, pendingCandidateInstanceIds.length]);

  // get acceptance data for cloning if cloneId present
  useEffect(() => {
    if (cloneId && !acceptanceTest && !acceptanceTestLoadError) {
      loadAcceptanceTest(app.id, cloneId);
    }
  }, [
    acceptanceTest,
    acceptanceTestLoadError,
    app.id,
    cloneId,
    loadAcceptanceTest,
  ]);

  // get batch data for cloning if cloneId present
  useEffect(() => {
    if (cloneId && !batchExperiment && !batchExperimentLoadError) {
      loadBatchExperiment(app.id, cloneId);
    }
  }, [
    app.id,
    batchExperiment,
    batchExperimentLoadError,
    cloneId,
    loadBatchExperiment,
  ]);

  // if clone data, prefill new acceptance test fields
  useEffect(() => {
    if (
      cloneId &&
      acceptanceTest &&
      batchExperiment &&
      !pendingStandardInputs.id
    ) {
      // modify name to prevent duplicate ID error
      const modifiedAcceptanceTestName = `${getSafeCloneName(
        acceptanceTest.name
      )} clone`;

      // ID set automatically by name field
      updateStandardInputs([
        { key: "name", value: modifiedAcceptanceTestName },
        { key: "description", value: acceptanceTest?.description },
      ]);

      setPendingInputSetId(batchExperiment.input_set_id);
      setPendingBaselineInstanceIds([acceptanceTest.control.instance_id]);
      setPendingCandidateInstanceIds([acceptanceTest.candidate.instance_id]);
      setPendingAcceptanceTestMetrics(acceptanceTest.metrics);
    }
  }, [
    acceptanceTest,
    batchExperiment,
    cloneId,
    pendingStandardInputs.id,
    setPendingAcceptanceTestMetrics,
    updateStandardInputs,
  ]);

  // disable loading state if add new error
  useEffect(() => {
    if ((acceptanceTestAddError || batchExperimentAddError) && isProcessing) {
      setIsProcessing(false);
    }
  }, [acceptanceTestAddError, batchExperimentAddError, isProcessing]);

  const filteredPendingBaselineInstanceIds =
    pendingBaselineInstanceIds.filter(Boolean);
  const filteredPendingCandidateInstanceIds =
    pendingCandidateInstanceIds.filter(Boolean);

  const handleAcceptanceTestCreate = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    if (filteredPendingBaselineInstanceIds.length) {
      trackEvent("AcceptanceTests", {
        view: "Create Acceptance Test",
        action: "Baseline Instance Added",
      });
    }

    if (filteredPendingCandidateInstanceIds.length) {
      trackEvent("AcceptanceTests", {
        view: "Create Acceptance Test",
        action: "Candidate Instance Added",
      });
    }

    let batchExperimentPayload: CreateBatchExperimentPayload = {
      ...pendingStandardInputs,
      type: PAYLOAD_EXPERIMENT_TYPE_BATCH,
      input_set_id: pendingInputSetId,
      instance_ids: [
        ...filteredPendingBaselineInstanceIds,
        ...filteredPendingCandidateInstanceIds,
      ],
    };

    const newBatchExperiment = await addExperiment(
      app.id,
      batchExperimentPayload,
      true
    );

    if (newBatchExperiment) {
      const createAcceptanceTestPayload: CreateAcceptanceTestPayload = {
        ...pendingStandardInputs,
        experiment_id: newBatchExperiment.id,
        control: {
          instance_id: filteredPendingBaselineInstanceIds.join(","),
        },
        candidate: {
          instance_id: filteredPendingCandidateInstanceIds.join(","),
        },
        metrics: [...pendingAcceptanceTestMetrics].filter((metric) => {
          return !!metric.field;
        }),
      };

      await addAcceptanceTest(app.id, createAcceptanceTestPayload);
    }
  };

  const handleCancel = () => {
    trackEvent("AcceptanceTests", {
      view: "Create Acceptance Test",
      action: "Create Acceptance Test Canceled",
    });
    return;
  };

  if (
    cloneId &&
    !acceptanceTest &&
    !acceptanceTestLoadError &&
    !batchExperiment &&
    !batchExperimentLoadError
  ) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  if (isAcceptanceTestAdded) {
    trackEvent("AcceptanceTests", {
      view: "Create Acceptance Test",
      action: "New Acceptance Test Created",
    });

    loadBatchExperiments({
      applicationId: app.id,
      type: PAYLOAD_EXPERIMENT_TYPE_BATCH,
    });
    loadAcceptanceTests(app.id);

    return <Redirect to={returnPathList} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name ||
    !pendingStandardInputs.id ||
    !pendingInputSetId ||
    !filteredPendingBaselineInstanceIds.length ||
    !filteredPendingCandidateInstanceIds.length ||
    !!standardInputsErrors.name ||
    !!standardInputsErrors.id;

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          ancestorIcon: <AvatarExperimentAcceptance size={24} />,
          ancestorLabel: "Acceptance Tests",
          ancestorUrl: returnPathList,
        }}
      />

      <Box pb={[6, 6, 8]}>
        <form>
          <RowDetail
            hasNoBorder
            property="Name"
            secondaryLabel="For reference only"
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Acceptance test name",
                    testId: "new-acceptance-name-input",
                    type: "name",
                    trackEventCategory: "AcceptanceTests",
                    trackEventProperties: {
                      view: "Create Acceptance Test",
                      action: "Field Entered",
                      meta: {
                        field: "name",
                      },
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="ID"
            tooltipCopy={sharedTooltipCopy.id("acceptance test").content}
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Acceptance test ID",
                    testId: "new-acceptance-id-input",
                    type: "id",
                    trackEventCategory: "AcceptanceTests",
                    trackEventProperties: {
                      view: "Create Acceptance Test",
                      action: "Acceptance Test ID Changed",
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="Description"
            secondaryLabel="(optional)"
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Acceptance test description",
                    testId: "new-acceptance-description-input",
                    type: "description",
                    trackEventCategory: "AcceptanceTests",
                    trackEventProperties: {
                      view: "Create Acceptance Test",
                      action: "Field Entered",
                      meta: {
                        field: "description",
                      },
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="Baseline Instance"
            tooltipCopy={acceptanceTooltips.controlInstance.content}
            render={
              <Box maxWidth={INPUT_WIDTH_STANDARD}>
                <InstanceSelect
                  {...{ app }}
                  isSingleSelect
                  excludeInstanceIds={pendingCandidateInstanceIds}
                  pendingInstanceIds={pendingBaselineInstanceIds}
                  setPendingInstanceIds={setPendingBaselineInstanceIds}
                />
              </Box>
            }
          />

          <RowDetail
            property="Candidate Instance"
            tooltipCopy={acceptanceTooltips.candidateInstance.content}
            render={
              <Box maxWidth={INPUT_WIDTH_STANDARD}>
                <InstanceSelect
                  {...{ app }}
                  isSingleSelect
                  excludeInstanceIds={pendingBaselineInstanceIds}
                  pendingInstanceIds={pendingCandidateInstanceIds}
                  setPendingInstanceIds={setPendingCandidateInstanceIds}
                />
              </Box>
            }
          />

          <RowDetail
            property="Input Set"
            tooltipCopy={sharedTooltipCopy.inputSet("test").content}
            render={
              <SelectInputSet
                {...{ pendingInputSetId }}
                appId={app.id}
                onAdd={setPendingInputSetId}
                trackEventCategory="AcceptanceTests"
                trackEventProperties={{
                  view: "Create Acceptance Test",
                  action: "Input Set Selected",
                }}
              />
            }
          />

          <AcceptanceMetricSelect
            {...{
              addEmptyMetric,
              handleMetricInputChange,
              handleMetricSelectCompare,
              pendingAcceptanceTestMetrics,
              removeMetric,
              setPendingAcceptanceTestMetrics,
            }}
          />

          <Footer
            actionButtonLabel="Create & run acceptance test"
            app={app}
            endpoint="experiments/acceptance"
            error={acceptanceTestAddError || batchExperimentAddError}
            handleCancel={handleCancel}
            handleMainAction={handleAcceptanceTestCreate}
            isActionButtonLoading={isProcessing}
            isActionButtonDisabled={isActionButtonDisabled}
            returnPath={returnPath}
            returnPathList={returnPathList}
            view="create"
          />
        </form>
      </Box>
    </>
  );
};

export default NewAcceptanceTest;
