import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconCloseLarge = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9521 3.28547C14.2938 2.94376 14.2938 2.38974 13.9521 2.04803C13.6104 1.70632 13.0564 1.70632 12.7147 2.04803L8.00008 6.76264L3.28547 2.04803C2.94376 1.70632 2.38974 1.70632 2.04803 2.04803C1.70632 2.38974 1.70632 2.94376 2.04803 3.28547L6.76264 8.00008L2.04803 12.7147C1.70632 13.0564 1.70632 13.6104 2.04803 13.9521C2.38974 14.2938 2.94376 14.2938 3.28547 13.9521L8.00008 9.23752L12.7147 13.9521C13.0564 14.2938 13.6104 14.2938 13.9521 13.9521C14.2938 13.6104 14.2938 13.0564 13.9521 12.7147L9.23752 8.00008L13.9521 3.28547Z"
      />
    </BaseIcon>
  );
};

export default IconCloseLarge;
