import { trackEvent } from "../../../../../analytics";
import {
  RUN_DETAILS_TAB_ANALYSIS,
  RUN_DETAILS_TAB_DETAILS,
  RUN_DETAILS_TAB_ENSEMBLE,
  RUN_DETAILS_TAB_INPUT,
  RUN_DETAILS_TAB_LOGS,
  RUN_DETAILS_TAB_RESULT,
} from "../../../../../config/apps";
import { MainViewType } from "../RunDetails.types";

type GetMainViewTabsParams = {
  mainView: MainViewType;
  setMainView: React.Dispatch<React.SetStateAction<MainViewType>>;
  hasSeriesData?: boolean;
  isDetailsDisabled?: boolean;
  isEnsembleRun?: boolean;
  isInputDisabled?: boolean;
  isLogsDisabled?: boolean;
  isResultDisabled?: boolean;
};

type TabOnClickProps = {
  preventDefault: () => void;
  stopPropagation: () => void;
};

export const getMainViewTabs = ({
  mainView,
  hasSeriesData,
  isDetailsDisabled,
  isEnsembleRun,
  isInputDisabled,
  isLogsDisabled,
  isResultDisabled,
  setMainView,
}: GetMainViewTabsParams) => {
  const mainViewTabs = [
    {
      id: `tab-${RUN_DETAILS_TAB_DETAILS}`,
      label: "Details",
      isActive: mainView === RUN_DETAILS_TAB_DETAILS,
      isDisabled: isDetailsDisabled,
      onClick: (e: TabOnClickProps) => {
        e.preventDefault();
        e.stopPropagation();
        trackEvent("RunHistory", {
          view: "Run Details",
          action: "Details Tab Clicked",
        });
        return setMainView(RUN_DETAILS_TAB_DETAILS);
      },
    },
    {
      id: `tab-${RUN_DETAILS_TAB_INPUT}`,
      label: "Input",
      isActive: mainView === RUN_DETAILS_TAB_INPUT,
      isDisabled: isInputDisabled,
      onClick: (e: TabOnClickProps) => {
        e.preventDefault();
        e.stopPropagation();
        trackEvent("RunHistory", {
          view: "Run Details",
          action: "Input Tab Clicked",
        });
        return setMainView(RUN_DETAILS_TAB_INPUT);
      },
    },
    {
      id: `tab-${RUN_DETAILS_TAB_RESULT}`,
      label: "Result",
      isActive: mainView === RUN_DETAILS_TAB_RESULT,
      isDisabled: isResultDisabled,
      onClick: (e: TabOnClickProps) => {
        e.preventDefault();
        e.stopPropagation();
        trackEvent("RunHistory", {
          view: "Run Details",
          action: "Result Tab Clicked",
        });
        return setMainView(RUN_DETAILS_TAB_RESULT);
      },
    },
    {
      id: `tab-${RUN_DETAILS_TAB_LOGS}`,
      label: "Logs",
      isActive: mainView === RUN_DETAILS_TAB_LOGS,
      isDisabled: isLogsDisabled,
      onClick: (e: TabOnClickProps) => {
        e.preventDefault();
        e.stopPropagation();
        trackEvent("RunHistory", {
          view: "Run Details",
          action: "Logs Tab Clicked",
        });
        return setMainView(RUN_DETAILS_TAB_LOGS);
      },
    },
    ...(isEnsembleRun
      ? [
          {
            id: `tab-${RUN_DETAILS_TAB_ENSEMBLE}`,
            label: "Ensemble",
            isActive: mainView === RUN_DETAILS_TAB_ENSEMBLE,
            onClick: (e: TabOnClickProps) => {
              e.preventDefault();
              e.stopPropagation();
              trackEvent("RunHistory", {
                view: "Run Details",
                action: "Ensemble Tab Clicked",
              });
              return setMainView(RUN_DETAILS_TAB_ENSEMBLE);
            },
          },
        ]
      : []),
    ...(hasSeriesData
      ? [
          {
            id: `tab-${RUN_DETAILS_TAB_ANALYSIS}`,
            label: "Analysis",
            isActive: mainView === RUN_DETAILS_TAB_ANALYSIS,
            isDisabled: isResultDisabled,
            onClick: (e: TabOnClickProps) => {
              e.preventDefault();
              e.stopPropagation();
              return setMainView(RUN_DETAILS_TAB_ANALYSIS);
            },
            tooltipContent:
              "The analysis view is available if the run result contains a series_data block in the statistics block of the output.",
            tooltipExtraLinkLabel: "More",
            tooltipExtraLinkUrl:
              "https://www.nextmv.io/docs/vehicle-routing/reference/input-output-schema#statistics",
          },
        ]
      : []),
  ];

  return mainViewTabs;
};
