import * as React from "react";

import BaseAvatar, { AvatarProps } from "./BaseAvatar";

const AvatarExperimentBatch = ({ size }: AvatarProps) => {
  return (
    <BaseAvatar {...{ size }} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13.9375 5.125H15.5V18.125H13.9375V5.125ZM16.75 8.125H18.3125V18.125H16.75V8.125ZM11.125 8.875H12.6875V18.125H11.125V8.875ZM5.5 9.625H7.0625V18.125H5.5V9.625ZM8.3125 11.875H9.875V18.125H8.3125V11.875Z"
        fill="#FC6262"
      />
    </BaseAvatar>
  );
};

export default AvatarExperimentBatch;
