import styled from "@emotion/styled";

import Box from "../../components/Box";
import Flex from "../../components/Flex";
import { styleScrollbarX } from "../../utils/constants";
import { rem } from "../../utils/tools";

export const FlexInline = styled(Flex)`
  display: inline-flex;
  position: relative;
  top: ${rem(3)};
`;

export const StyledOverflowXBox = styled(Box)`
  overflow-x: scroll;

  ${({ theme }) => styleScrollbarX(theme)}
`;
