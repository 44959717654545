import * as React from "react";
import { components } from "react-select";

import Box from "../../Box";
import Button2 from "../../Button2";

// get MenuList base component from library
const { MenuList } = components;

const MenuListLoadMore = (props: any) => {
  const loadMore = props?.selectProps?.loadMore;

  const handleLoadMore = (e: any) => {
    if (loadMore) {
      loadMore(e);
    }
  };

  const renderMenuListLoadMore = () => {
    return (
      <Box>
        {props.children}

        {loadMore && (
          <Button2
            testId="load-more-entity-button"
            htmlType="button"
            type="text"
            label="Load more"
            onClick={handleLoadMore}
          />
        )}
      </Box>
    );
  };

  return <MenuList {...props}>{renderMenuListLoadMore()}</MenuList>;
};

export default MenuListLoadMore;
