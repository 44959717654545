import React, { useEffect, useMemo } from "react";
import { useTheme } from "@emotion/react";

import { trackEvent, TrackEvents } from "../../analytics/functions";
import { AppResponse } from "../../api/core/controlPlane.types";
import Box from "../../components/Box";
import Button2 from "../../components/Button2";
import Flex from "../../components/Flex";
import Loading from "../../components/Loading";
import { useAppCollection } from "../../contexts/apps/App.context";
import { IconX } from "../../icons";
import { rem } from "../../utils/tools";
import Notification from "../Notification";
import Select from "../Select";
import Text from "../Text";

import { AppInputOption, AppInputSelectInputIds } from "./SelectAppInput.types";

const SelectAppInput = <T extends keyof TrackEvents>({
  app,
  excludeInputIds,
  isSingleSelect,
  isSmall,
  maxLimit,
  pendingInputIds,
  placeholder,
  setReferenceAppInputOptions,
  setPendingInputIds,
  testId,
  trackEventCategory,
  trackEventProperties,
}: {
  app: AppResponse;
  maxLimit: number;
  pendingInputIds: AppInputSelectInputIds;
  setPendingInputIds: React.Dispatch<
    React.SetStateAction<AppInputSelectInputIds>
  >;
  excludeInputIds?: AppInputSelectInputIds;
  isSingleSelect?: boolean;
  isSmall?: boolean;
  placeholder?: string;
  setReferenceAppInputOptions?: React.Dispatch<
    React.SetStateAction<AppInputOption[] | null>
  >;
  testId?: string;
  trackEventCategory?: T;
  trackEventProperties?: TrackEvents[T];
}) => {
  const theme = useTheme();

  const {
    appInputs,
    appInputsLoadError,
    appInputsNextPageToken,
    additionalAppInputsLoading,
    loadAppInputs,
    setAdditionalAppInputsLoading,
  } = useAppCollection();

  const appInputsOptionsReference = useMemo(() => {
    return (
      appInputs &&
      appInputs.reduce((options: AppInputOption[], appInput) => {
        options.push({
          id: appInput.id,
          name: appInput.name,
        });
        return options;
      }, [])
    );
  }, [appInputs]);

  const appInputsOptions = useMemo(() => {
    return (
      appInputsOptionsReference &&
      appInputsOptionsReference.filter((appInputOption) => {
        return (
          !pendingInputIds.includes(appInputOption.id) &&
          !excludeInputIds?.includes(appInputOption.id)
        );
      })
    );
  }, [appInputsOptionsReference, excludeInputIds, pendingInputIds]);

  useEffect(() => {
    if (!appInputs) {
      loadAppInputs({ applicationId: app.id, shouldPaginate: true });
    }
  }, [app.id, appInputs, loadAppInputs]);

  useEffect(() => {
    if (setReferenceAppInputOptions) {
      setReferenceAppInputOptions(appInputsOptionsReference);
    }
  }, [appInputsOptionsReference, setReferenceAppInputOptions]);

  const getAppInputOptionFromId = (
    appInputId: string
  ): AppInputOption | undefined => {
    if (!appInputs) return;

    const appInput = appInputs.find((appInput) => appInput.id === appInputId);
    if (!appInput) return;

    return {
      id: appInput.id,
      name: appInput.name,
    };
  };

  const updateAppInputSelection = (
    appInputSelected: AppInputOption,
    index: number
  ) => {
    const modifiedPendingAppInputIds = pendingInputIds.map(
      (pendingAppInputId, i) => {
        if (i === index) {
          return appInputSelected ? appInputSelected.id : "";
        }
        return pendingAppInputId;
      }
    );
    setPendingInputIds(modifiedPendingAppInputIds);
    return;
  };

  const addEmptyAppInput = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setPendingInputIds((prevState) => [...prevState, ""]);
  };

  const removeAppInputSelection = (
    e: { preventDefault: () => void },
    index: number
  ) => {
    e.preventDefault();
    const updatedPendingAppInputIds = pendingInputIds.filter(
      (_pendingInputId, i) => {
        return i !== index;
      }
    );
    setPendingInputIds(updatedPendingAppInputIds);
    return;
  };

  const loadMoreAppInputs = (
    e: {
      preventDefault: () => void;
      stopPropagation: () => void;
    },
    appInputsNextPageToken: string
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setAdditionalAppInputsLoading(true);
    loadAppInputs({
      applicationId: app.id,
      nextPageToken: appInputsNextPageToken,
      shouldAppend: true,
      shouldPaginate: true,
    });
  };

  if (appInputsLoadError) {
    return (
      <Notification type="error" message={appInputsLoadError} hasContactExtra />
    );
  }
  if (!appInputs) {
    return <Loading />;
  }
  if (!appInputs.length) {
    return (
      <Flex minHeight={theme.spacing.s7} alignItems="center">
        <Text styleName="body-2" styles={{ color: theme.color.gray500 }}>
          No inputs available
        </Text>
      </Flex>
    );
  }

  const isShowLoadMore = appInputsNextPageToken && !additionalAppInputsLoading;

  return (
    <Box width="100%" maxWidth={rem(408)}>
      {pendingInputIds.map((pendingAppInputId, index) => {
        return (
          <Flex
            key={`${pendingAppInputId}-${index}`}
            mt={index !== 0 ? 1 : 0}
            mr={-8}
            alignItems="center"
            pr={index === 0 ? 8 : 0}
          >
            <Select
              testId={testId || `select-app-input-${index}`}
              type="meta"
              placeholder={placeholder || "Select input"}
              noOptionsMessage={() => "No inputs"}
              isClearable
              options={appInputsOptions || undefined}
              {...(isShowLoadMore && {
                loadMore: (e: any) =>
                  loadMoreAppInputs(e, appInputsNextPageToken),
              })}
              getOptionValue={(selectedAppInput: AppInputOption) =>
                selectedAppInput.id
              }
              value={
                pendingAppInputId && getAppInputOptionFromId(pendingAppInputId)
              }
              onChange={(selection: AppInputOption) => {
                trackEventCategory &&
                  trackEventProperties &&
                  trackEvent(trackEventCategory, trackEventProperties);
                updateAppInputSelection(selection, index);
              }}
              {...(isSmall && {
                size: "small",
              })}
            />
            {index > 0 && !isSingleSelect && (
              <Button2
                type="text-quiet"
                icon={<IconX />}
                onClick={(e: any) => removeAppInputSelection(e, index)}
              />
            )}
          </Flex>
        );
      })}

      {!isSingleSelect && pendingInputIds.length < maxLimit && (
        <Button2
          mt={2}
          testId="add-new-select-app-input-button"
          type="outline-quiet"
          htmlType="button"
          onClick={(e: { preventDefault: () => void }) => addEmptyAppInput(e)}
          label="Add additional input"
        />
      )}
    </Box>
  );
};
export default SelectAppInput;
