import React from "react";

import Modal2 from "../../../../../components/Modal2";
import { rem } from "../../../../../utils/tools";
import { ConfirmLeaveTeamModalProps } from "../Details.types";
import { getModalContentLeaveTeam } from "../utils/modalContent";

const ConfirmLeaveTeamModal = ({
  exitModal,
  leaveTeamProcessing,
  leaveTeam,
  team,
}: ConfirmLeaveTeamModalProps) => {
  const modalContent = getModalContentLeaveTeam(team);

  return (
    <Modal2
      isActive={!!team}
      width={rem(600)}
      title={modalContent.title}
      summary={modalContent.summary}
      closeActionOnClick={(e: any) => exitModal({ e })}
      primaryActionLabel={modalContent.primaryActionLabel}
      primaryActionOnClick={(e: any) => leaveTeam({ e, team })}
      primaryIsDisabled={leaveTeamProcessing}
      primaryIsLoading={leaveTeamProcessing}
      secondaryActionLabel={modalContent.secondaryActionLabel}
      secondaryActionOnClick={(e: any) => exitModal({ e })}
    />
  );
};

export default ConfirmLeaveTeamModal;
