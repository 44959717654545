import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { trackEvent } from "../../../../analytics";
import { CreateAppInstancePayload } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import AddConfigOptions from "../../../../components/AddConfigOptions";
import Box from "../../../../components/Box";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import { useMetaTitle } from "../../../../components/Layout";
import RowDetail from "../../../../components/RowDetail";
import { EC_DEFAULT } from "../../../../config/apps";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import useMarketplacePartners from "../../../../contexts/marketplace/hooks/useMarketplacePartners";
import useMarketplaceVersions from "../../../../contexts/marketplace/hooks/useMarketplaceVersions";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { rem } from "../../../../utils/tools";
import { appTooltipCopy } from "../../../Apps/data/microcopy";
import { getSafeCloneName } from "../../../Experiments/utils/getSafeCloneName";
import { AppPageProps } from "../../App.types";
import ExecutionClassSelect from "../../components/ExecutionClassSelect";
import VersionSelect from "../../components/VersionSelect";
import useReturnPaths from "../../hooks/useReturnPaths";
import { checkIsPremiumExecutionClass } from "../../utils/instances";

import useNewInstance from "./hooks/useNewInstance";

const pageTitle = "New instance";

const NewInstance = ({ app }: AppPageProps) => {
  const [{ planInfo }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const { returnPath, returnPathList } = useReturnPaths();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const cloneId = searchParams.get("cloneId");

  const [isProcessing, setIsProcessing] = useState(false);

  const {
    additionalVersionsLoading,
    loadInstances,
    loadVersions,
    setAdditionalVersionsLoading,
    versions,
    versionsError,
    versionsNextPageToken,
  } = useAppCollection();
  const {
    addEntity: addInstance,
    entity: instance,
    entityLoadError: instanceLoadError,
    isEntityAdded: isInstanceAdded,
    entityAddError: instanceAddError,
    loadEntity: loadInstance,
  } = useManageEntity("instances");

  const {
    loadMarketplaceVersions,
    marketplaceVersions,
    marketplaceVersionsError,
  } = useMarketplaceVersions();
  const {
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
  } = useMarketplacePartners();

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "instances");

  const {
    addEmptyConfigOption,
    convertToConfigOptions,
    handleConfigOptionChange,
    pendingConfigOptions,
    pendingExecutionClass,
    pendingVersionId,
    removeConfigOption,
    setPendingConfigOptions,
    setPendingExecutionClass,
    setPendingVersionId,
  } = useNewInstance();

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // get instance data for cloning if cloneId present
  useEffect(() => {
    if (cloneId && !instance && !instanceLoadError) {
      loadInstance(app.id, cloneId);
    }
  }, [instance, instanceLoadError, app.id, cloneId, loadInstance]);

  // if clone data, prefill new instance fields
  useEffect(() => {
    if (cloneId && instance && !pendingStandardInputs.id) {
      // modify name to prevent duplicate ID error
      const modifiedInstanceName = `${getSafeCloneName(instance.name)} clone`;

      // ID set automatically by name field
      updateStandardInputs([
        { key: "name", value: modifiedInstanceName },
        { key: "description", value: instance?.description },
      ]);
      setPendingVersionId(instance.version_id);
      setPendingExecutionClass(
        instance.configuration?.execution_class || EC_DEFAULT
      );
      setPendingConfigOptions(
        Object.entries(instance.configuration?.options || {}).map(
          ([key, value]) => ({
            option: key,
            value: value,
          })
        )
      );
    }
  }, [
    instance,
    cloneId,
    pendingStandardInputs.id,
    setPendingVersionId,
    setPendingExecutionClass,
    setPendingConfigOptions,
    updateStandardInputs,
  ]);

  // load versions for select options
  useEffect(() => {
    if (!versions && !marketplaceVersions) {
      if (app?.type === "subscription" && app.subscription_id) {
        loadMarketplaceVersions(app.subscription_id);
      } else {
        loadVersions({ applicationId: app.id, shouldPaginate: true });
      }
    }
  }, [
    app.id,
    app.subscription_id,
    app?.type,
    loadMarketplaceVersions,
    loadVersions,
    marketplaceVersions,
    versions,
  ]);

  // load marketplace app (if applicable)
  useEffect(() => {
    if (
      !marketplacePartnerApp &&
      !marketplacePartnerAppError &&
      app?.type === "subscription" &&
      app.subscription_id
    ) {
      loadMarketplacePartnerApp(app.subscription_id);
    }
  }, [
    app.subscription_id,
    app?.type,
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
  ]);

  useEffect(() => {
    if (instanceAddError && isProcessing) {
      setIsProcessing(false);
    }
  }, [instanceAddError, isProcessing]);

  const loadMoreVersions = (
    e: {
      preventDefault: () => void;
      stopPropagation: () => void;
    },
    versionsNextPageToken: string
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setAdditionalVersionsLoading(true);
    loadVersions({
      applicationId: app.id,
      nextPageToken: versionsNextPageToken,
      shouldAppend: true,
      shouldPaginate: true,
    });
  };

  const availableExecutionClasses = planInfo?.execution_classes;
  const hasPremiumExecutionClasses =
    !!availableExecutionClasses &&
    availableExecutionClasses.some((ec) =>
      checkIsPremiumExecutionClass(ec.class)
    );

  const handleInstanceCreate = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);

    let payload: CreateAppInstancePayload = {
      ...pendingStandardInputs,
      version_id: pendingVersionId,
      configuration: {
        options: convertToConfigOptions(pendingConfigOptions),
        execution_class: pendingExecutionClass,
      },
      application_id: app.id,
    };

    await addInstance(app.id, payload);
  };

  const handleCancel = () => {
    trackEvent("Instances", {
      view: "Create Instance",
      action: "Create Instance Canceled",
    });
    return;
  };

  if (isInstanceAdded) {
    trackEvent("Instances", {
      view: "Create Instance",
      action: "New Instance Created",
    });

    loadInstances({ applicationId: app.id, shouldPaginate: true });
    return <Redirect to={returnPathList} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name ||
    !pendingStandardInputs.id ||
    !pendingVersionId ||
    !!standardInputsErrors.name ||
    !!standardInputsErrors.id;

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          parentLabel: "Instances",
          parentUrl: returnPathList,
        }}
      />

      <form>
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Instance name",
                  testId: "new-instance-name-input",
                  type: "name",
                  trackEventCategory: "Instances",
                  trackEventProperties: {
                    view: "Create Instance",
                    action: "Field Entered",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          tooltipCopy={appTooltipCopy.instanceIdCreate}
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Instance ID",
                  testId: "new-instance-id-input",
                  type: "id",
                  trackEventCategory: "Instances",
                  trackEventProperties: {
                    view: "Create Instance",
                    action: "Instance ID Changed",
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Instance description",
                  testId: "new-instance-description-input",
                  type: "description",
                  trackEventCategory: "Instances",
                  trackEventProperties: {
                    view: "Create Instance",
                    action: "Field Entered",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="Version"
          secondaryLabel="Select instance version"
          render={
            <VersionSelect
              appType={app?.type}
              appId={app.id}
              versionsNextPageToken={
                !additionalVersionsLoading ? versionsNextPageToken : ""
              }
              {...{
                loadMoreVersions,
                marketplacePartnerApp,
                marketplaceVersions,
                marketplaceVersionsError,
                pendingVersionId,
                setPendingVersionId,
                versions,
                versionsError,
              }}
            />
          }
        />

        {hasPremiumExecutionClasses && (
          <RowDetail
            property="Execution class"
            secondaryLabel="Select execution class to use for this instance."
            tooltipCopy={appTooltipCopy.instanceExecutionClassCreate.content}
            render={
              <ExecutionClassSelect
                {...{
                  availableExecutionClasses,
                  pendingExecutionClass,
                  setPendingExecutionClass,
                }}
                trackEventCategory="Instances"
                trackEventProperties={{
                  view: "Create Instance",
                  action: "Execution Class Changed",
                }}
              />
            }
          />
        )}

        <RowDetail
          property="Configuration Options"
          secondaryLabel="(optional)"
          tooltipCopy={appTooltipCopy.instanceConfig.content}
          tooltipExtraLinkLabel={appTooltipCopy.instanceConfig.docsLinkLabel}
          tooltipExtraLinkUrl={appTooltipCopy.instanceConfig.docsLink}
          render={
            <AddConfigOptions
              {...{
                addEmptyConfigOption,
                handleConfigOptionChange,
                pendingConfigOptions,
                removeConfigOption,
              }}
            />
          }
        />

        <Footer
          actionButtonLabel="Create instance"
          app={app}
          endpoint="instances"
          error={instanceAddError}
          handleCancel={handleCancel}
          handleMainAction={handleInstanceCreate}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="create"
        />
      </form>
    </>
  );
};

export default NewInstance;
