import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { trackEvent } from "../../../../analytics";
import { AppVersionsData } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import StandardError from "../../../../components/StandardError";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import { getFilteredEntities } from "../../../../utils/entities/getFilteredEntities";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../App.types";
import LoadingListView from "../../components/LoadingListView";
import { appTooltips } from "../../data/appTooltips";

import { renderVersionsList } from "./utils/renderVersionsList";

const pageTitle = "Versions";

const Versions = ({ app }: AppPageProps) => {
  const [{ id: accountId, roles = [] }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const { pathname } = useLocation();

  const [filterText, setFilterText] = useState("");

  const {
    additionalVersionsLoading,
    loadVersions,
    setAdditionalVersionsLoading,
    setVersions,
    versions,
    versionsError,
    versionsNextPageToken,
  } = useAppCollection();

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load versions if not in context already
  useEffect(() => {
    if (!versions && !versionsError) {
      loadVersions({
        applicationId: app.id,
        shouldPaginate: true,
      });
    }
  }, [app.id, loadVersions, versions, versionsError]);

  if (versionsError) {
    return <StandardError errorMessage={versionsError} />;
  }

  const canUserCreateAndEditVersions = userHasAccessToAction(
    roles,
    ActionGroups.AppVersionOperator,
    {}
  );

  const filteredVersions = getFilteredEntities(filterText, versions);
  const renderVersions = () => {
    return renderVersionsList({
      accountId,
      app,
      canUserCreateAndEditVersions,
      versions: filteredVersions as AppVersionsData,
    });
  };

  const handleLoadMore = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setAdditionalVersionsLoading(true);
    loadVersions({
      applicationId: app.id,
      nextPageToken: versionsNextPageToken,
      shouldAppend: true,
      shouldPaginate: true,
    });
    return;
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: appTooltips.versions.content,
          tooltipExtraLinkLabel: appTooltips.versions.extraLinkLabel,
          tooltipExtraLinkUrl: appTooltips.versions.extraLinkUrl,
        }}
        configActionButton={{
          label: "New version",
          url: `${pathname}/new`,
          onClick: () =>
            trackEvent("Apps", {
              view: "App Versions",
              action: "New App Version Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditVersions,
        }}
        configFilter={{
          inputText: filterText,
          testId: "filter-versions",
          setInputText: setFilterText,
        }}
        refreshButton={{
          emptyItems: null,
          itemsLoading: !versions,
          loadItems: () =>
            loadVersions({
              applicationId: app.id,
              nextPageToken: versionsNextPageToken,
              shouldAppend: false,
              shouldPaginate: true,
            }),
          setItems: setVersions,
        }}
      />

      <PlanNotice {...{ app }} type="custom-apps" />

      {!versions ? <LoadingListView /> : renderVersions()}

      {!!versions?.length && (
        <Footer
          app={app}
          endpoint="versions"
          error={versionsError}
          handleMainAction={handleLoadMore}
          isActionButtonDisabled={!versionsNextPageToken}
          isActionButtonLoading={additionalVersionsLoading}
          view="list"
        />
      )}
    </>
  );
};

export default Versions;
