import * as React from "react";

import BaseAvatar, { AvatarProps } from "./BaseAvatar";

const AvatarPass = ({ size }: AvatarProps) => {
  return (
    <BaseAvatar {...{ size }} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM9.82609 17.2402C10.1037 17.4922 10.4777 17.6101 10.8496 17.5626C11.2218 17.5151 11.5544 17.3068 11.7598 16.9928L17.0723 8.86781C17.4595 8.27556 17.2933 7.48152 16.701 7.09428C16.1088 6.70704 15.3148 6.87324 14.9275 7.46549L10.4364 14.3342L7.93994 12.0775C7.41633 11.6021 6.60585 11.6403 6.13002 12.1637C5.65403 12.6873 5.69262 13.4976 6.21621 13.9736L9.82609 17.2402Z"
        fill="#01D181"
      />
    </BaseAvatar>
  );
};

export default AvatarPass;
