import {
  InputSet,
  ScenarioTestOptionSets,
  ScenarioTestRun,
} from "../../../api/core/controlPlane.types";
import { DEFAULT_PLAN_SET_ID_LABEL } from "../../../config/experiments";
import useManageEntity from "../../../hooks/useManageEntity";
import { PlanSet } from "../../../pages/Experiments/Experiments.types";
import { LATEST_INSTANCE_ID } from "../../../utils/constants";
import {
  getFilteredPlanSetConfigOptions,
  getPlanSetConfigOptionValueSets,
} from "../utils/planSets";

export type ScenarioTestRunWithRunId = ScenarioTestRun & {
  run_number?: string;
};

type GetPlanPayloadReturn = {
  option_sets: ScenarioTestOptionSets;
  runs: ScenarioTestRunWithRunId[];
  displayRuns: ScenarioTestRunWithRunId[];
};
type GetScenarioTestPayloadParams = {
  appId: string;
  isSubscriptionApp: boolean;
  planSets: PlanSet[];
  repetitions: number;
  isGetDisplayRuns?: boolean;
  isIncludeConfigInRunPayload?: boolean;
  latestVersionId?: string;
};

const useBatchExperiments = () => {
  const { loadEntity: loadInputSet } = useManageEntity("experiments/inputsets");

  const getRepeatedRuns = (
    scenarioRuns: ScenarioTestRunWithRunId[],
    repetitions: number
  ) => {
    return new Array(repetitions).fill(undefined).flatMap((_empty, index) => {
      return scenarioRuns.map((scenarioRun) => {
        return {
          ...scenarioRun,
          repetition: index + 1,
        };
      });
    });
  };

  const getScenarioTestPayload = async ({
    appId,
    isSubscriptionApp,
    isGetDisplayRuns,
    latestVersionId,
    planSets,
    repetitions,
  }: GetScenarioTestPayloadParams): Promise<GetPlanPayloadReturn> => {
    const finalPayload: GetPlanPayloadReturn = {
      option_sets: {},
      runs: [],
      displayRuns: [],
    };

    let planSetRuns: ScenarioTestRunWithRunId[] = [];
    let planSetDisplayRuns: ScenarioTestRunWithRunId[] = [];

    for (const planSet of planSets) {
      const isPlanSetUsingInputFileUpload = planSet.shouldUseFilesForInputSet;

      // set up base data
      const scenarioId =
        planSet.id || `${DEFAULT_PLAN_SET_ID_LABEL}-${Date.now()}`;

      const configOptions = getFilteredPlanSetConfigOptions(
        planSet.configOptions
      );
      const inputSetId = planSet.inputSetId;
      const instance = planSet.instances.filter(Boolean)[0];
      const instanceId = instance?.instanceId;

      // ensure latest version id is used for latest instance
      let versionId =
        !isGetDisplayRuns &&
        !isSubscriptionApp &&
        instanceId === LATEST_INSTANCE_ID
          ? latestVersionId
          : instance?.versionId;

      // instance and versions are required for payload
      if (!isGetDisplayRuns && (!instanceId || !versionId)) {
        continue;
      }

      // instance is required for the table display
      if (isGetDisplayRuns && !instanceId) {
        continue;
      }

      // skip plan set if no input set specified and no file uploads
      if (!inputSetId && !isPlanSetUsingInputFileUpload) {
        continue;
      }

      // get input set inputs

      let inputSetInputIds: string[] | undefined;
      let fileInputSetInputIds: string[] | undefined;

      let inputSet: InputSet | undefined;

      if (inputSetId) {
        inputSet = await loadInputSet(appId, inputSetId, true);
      }

      if (inputSet?.input_ids && inputSet.input_ids.length) {
        inputSetInputIds = inputSet?.input_ids;
      }
      if (inputSet?.inputs && inputSet.inputs.length) {
        inputSetInputIds = inputSet?.inputs.map((input) => input.id);
      }

      // file names are used as temporary input IDs for file uploads
      if (isPlanSetUsingInputFileUpload) {
        fileInputSetInputIds = planSet.fileInputs;
      }

      // skip plan set if no input IDs in input set (backend error)
      if (!inputSetInputIds?.length && !isPlanSetUsingInputFileUpload) {
        continue;
      }

      if (!fileInputSetInputIds?.length && isPlanSetUsingInputFileUpload) {
        continue;
      }

      // get cartesian cross product of parsed config values
      // (if comma-delimited values, spread into unique option sets)
      const cartesianConfigOptionValueSets =
        getPlanSetConfigOptionValueSets(configOptions);

      // get config options
      const configOptionsOptions = configOptions.map(
        (configOption) => configOption.option
      );

      // create payload for option_sets and initial runs
      const numberOfPlanSets = cartesianConfigOptionValueSets.length;

      for (let index = 0; index < numberOfPlanSets; index++) {
        const subPlanSetId = `${scenarioId}${index ? `_${index}` : ""}`;
        const valueSet = cartesianConfigOptionValueSets[index];

        finalPayload["option_sets"][subPlanSetId] = configOptionsOptions.reduce(
          (configOptionsObj, configOption, index) => {
            configOptionsObj[configOption] = valueSet[index];
            return configOptionsObj;
          },
          {} as {
            [key: string]: string;
          }
        );

        const runCommonData = {
          instance_id: instanceId,
          option_set: subPlanSetId,
          scenario_id: scenarioId,
          repetition: 0,
          version_id: versionId,
        };

        // add runs to runs array for scenario payload
        if (inputSetInputIds?.length) {
          planSetRuns = [
            ...planSetRuns,
            ...inputSetInputIds.map((inputId) => {
              return {
                input_id: inputId,
                input_set_id: inputSet?.id,
                ...runCommonData,
              };
            }),
          ];
        }

        // add table display runs to displayRuns array
        if (inputSetInputIds?.length && !isPlanSetUsingInputFileUpload) {
          planSetDisplayRuns = [
            ...planSetDisplayRuns,
            ...inputSetInputIds.map((inputId) => {
              return {
                input_id: inputId,
                input_set_id: inputSet?.id,
                ...runCommonData,
                ...finalPayload["option_sets"][subPlanSetId],
              };
            }),
          ];
          // file names are used as temporary input IDs for file uploads
        } else if (
          fileInputSetInputIds?.length &&
          isPlanSetUsingInputFileUpload
        ) {
          planSetDisplayRuns = [
            ...planSetDisplayRuns,
            ...fileInputSetInputIds.map((fileInputId) => {
              return {
                input_id: fileInputId,
                input_set_id: undefined,
                ...runCommonData,
                ...finalPayload["option_sets"][subPlanSetId],
              };
            }),
          ];
        }
      }
    }

    // repeat runs as necessary
    const repeatedPlanSetRuns = getRepeatedRuns(planSetRuns, repetitions);

    const repeatedPlanSetDisplayRuns = getRepeatedRuns(
      planSetDisplayRuns,
      repetitions
    );

    finalPayload["runs"] = [...planSetRuns, ...repeatedPlanSetRuns];

    finalPayload["displayRuns"] = [
      ...planSetDisplayRuns,
      ...repeatedPlanSetDisplayRuns,
    ];

    finalPayload["runs"].forEach((run, index) => {
      run.run_number = `${index + 1}`;
    });

    finalPayload["displayRuns"].forEach((run, index) => {
      run.run_number = `${index + 1}`;
    });

    return finalPayload;
  };

  return {
    getScenarioTestPayload,
  };
};

export default useBatchExperiments;
