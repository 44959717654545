import Table2 from "../../../components/Table2";
import {
  Table2RowSelection,
  TableData,
} from "../../../components/Table2/Table2.types";
import { ScenarioTestRunWithRunId } from "../../../contexts/experiments/hooks/useBatchExperiments";
import { Table2HeaderObj } from "../../App/subpages/RunHistory/RunHistory.types";

import ExperimentDetailsSection from "./ExperimentDetailsSection";

const knownScenarioRunKeyIds = [
  "group_edit_id",
  "id",
  "input-id",
  "input_set_id",
  "application_instance_id",
  "application_version_id",
  "option_set",
  "repetition",
  "scenario_id",
];
const getLayoutSchemaScenarioRuns = (scenarioRunHeaders: Table2HeaderObj[]) => {
  const baseLayoutSchema = [
    { width: 20 }, // checkbox
    { width: 80 }, // run #
    { width: 200 }, // scenario id
    { width: 200 }, // option set
    // option key/value pairs here
    { width: 240 }, // input ID
    { width: 200 }, // input set ID
    { width: 200 }, // instance ID
    { width: 200 }, // version id
    { width: 60 }, // repetition
  ];

  // filter out known entitities, remaining items are dynamically
  // generated config key/value pairs (single columns)
  const totalConfigOptionCols = scenarioRunHeaders.filter(
    (header) => !knownScenarioRunKeyIds.includes(header.id || "")
  ).length;

  // insert equal number of layout entites for config key/value pairs
  baseLayoutSchema.splice(
    4,
    0,
    ...Array(totalConfigOptionCols).fill({ width: 220 })
  );

  return baseLayoutSchema;
};

type ScenarioRunsProps = {
  scenarioRuns: ScenarioTestRunWithRunId[];
  scenarioRunHeaders: Table2HeaderObj[];
  runSelection?: Table2RowSelection;
  setRunSelection?: React.Dispatch<React.SetStateAction<Table2RowSelection>>;
};
const ScenarioRuns = ({
  scenarioRuns,
  scenarioRunHeaders,
  runSelection,
  setRunSelection,
}: ScenarioRunsProps) => {
  const layoutSchemaScenarioRuns =
    getLayoutSchemaScenarioRuns(scenarioRunHeaders);

  return (
    <ExperimentDetailsSection title={"Select Runs"}>
      <Table2
        testId="app-runs-table"
        useMinWidth
        isWide
        canFilter
        canSort
        headers={scenarioRunHeaders}
        isStickyColumn
        mt={3}
        mb={4}
        emptyMessage="No runs found"
        rowSelection={runSelection}
        setRowSelection={setRunSelection}
        data={scenarioRuns}
        layoutSchema={layoutSchemaScenarioRuns}
        getRowId={(row: TableData) => `${row.run_number}`}
      />
    </ExperimentDetailsSection>
  );
};

export default ScenarioRuns;
