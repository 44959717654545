import styled from "@emotion/styled";

import Box from "../../components/Box";
import { rem } from "../../utils/tools";

export const CustomSwaggerStyles = styled(Box)`
  .swagger-ui {
    border-top-width: ${({ theme }) => theme.border.widthThick};
    border-top-style: ${({ theme }) => theme.border.style};
    border-top-color: ${({ theme }) => theme.color.gray200};

    .wrapper {
      max-width: none;
      margin: 0;
      padding: 0;
    }

    /* hide authorize button because we now pre-authorize users */
    .scheme-container .schemes .auth-wrapper {
      display: none;
    }

    /* hide irrelevant data */
    .info hgroup.main,
    .info .description,
    .info__tos,
    .info__contact,
    .info__license,
    .info__extdocs,
    .information-container,
    .scheme-container {
      display: none;
    }

    /* section header */
    .opblock-tag-section {
      padding-top: ${({ theme }) => theme.spacing.s4};
      padding-bottom: ${({ theme }) => theme.spacing.s2};

      .opblock-tag {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        margin-top: 0;
        margin-bottom: ${({ theme }) => theme.spacing.s4};
        padding-top: ${({ theme }) => theme.spacing.s2};
        padding-bottom: ${({ theme }) => theme.spacing.s2};
        padding: 0;
        font-family: ${({ theme }) => theme.ui2Typography.fontFamilyHeader2};
        font-size: ${({ theme }) => theme.ui2Typography.fontSizeHeader2};
        font-weight: ${({ theme }) => theme.ui2Typography.fontWeightHeader2};
        line-height: ${({ theme }) => theme.ui2Typography.lineHeightHeader2};
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        color: ${({ theme }) => theme.color.gray800};
        border-bottom-color: ${({ theme }) => theme.color.gray200};

        p {
          margin-top: ${({ theme }) => theme.spacing.s8};
          margin-bottom: 0;
        }

        small {
          display: block;
          margin-left: ${({ theme }) => theme.spacing.s2};
          padding: 0 !important;
          position: relative;
          top: ${rem(0.5)};
          font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody2};
          font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody2};
          font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody2};
          line-height: ${({ theme }) => theme.ui2Typography.lineHeightBody2};
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;

          p {
            margin-top: ${({ theme }) => theme.spacing.s3};
            margin-bottom: ${({ theme }) => theme.spacing.s3};
          }
        }
      }
    }

    /* expand/collapse control button for headers */
    .opblock-tag-section,
    .models {
      .models-control,
      .expand-operation {
        width: ${({ theme }) => theme.spacing.s5} !important;
        height: ${({ theme }) => theme.spacing.s5} !important;
        margin-right: ${rem(10)} !important;
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.52743 10.7774L12.5857 6.35138L11.598 5.22252L7.52743 8.78425L3.45687 5.22252L2.46912 6.35138L7.52743 10.7774Z' fill='%23454c4d'/%3e %3c/svg%3e");
        background-position: center;
        background-repeat: no-repeat;
        background-size: ${({ theme }) => theme.spacing.s5};

        svg {
          display: none;
        }
      }
    }
    .opblock-tag-section.is-open,
    .models.is-open {
      .models-control,
      .expand-operation {
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.21908 5.22252L3.16077 9.64854L4.14852 10.7774L8.21908 7.21567L12.2896 10.7774L13.2774 9.64854L8.21908 5.22252Z' fill='%23454c4d'/%3e %3c/svg%3e");
      }
    }
    /* adjust position for models header */
    .models {
      .models-control {
        background-position: right ${({ theme }) => theme.spacing.s1} center;
      }
    }

    /* section description */
    .opblock-body {
      .opblock-description p {
        font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody2};
        font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody2};
        font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody2};
        line-height: ${({ theme }) => theme.ui2Typography.lineHeightBody2};
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
      }
    }

    /*
      the colorful row that includes the GET/POST tag, the endpoint,
      the description and the control and lock icons.
    */
    .operation-tag-content {
      .opblock {
        margin-top: ${({ theme }) => theme.spacing["-s1"]};
        margin-bottom: 0;
      }
    }
    /* target all besides first child */
    .operation-tag-content span + span {
      .opblock {
        margin-top: ${({ theme }) => theme.spacing.s3};
      }
    }
    .opblock-summary {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      /* a button element, includes all elements of row except lock icon */
      .opblock-summary-control {
        width: 100%;

        svg {
          display: none;
        }

        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.52743 10.7774L12.5857 6.35138L11.598 5.22252L7.52743 8.78425L3.45687 5.22252L2.46912 6.35138L7.52743 10.7774Z' fill='%23454c4d'/%3e %3c/svg%3e");
        background-position: right ${({ theme }) => theme.spacing.s1} center;
        background-repeat: no-repeat;
        background-size: ${({ theme }) => theme.spacing.s5};
      }
      .opblock-summary-control[aria-expanded="true"] {
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.21908 5.22252L3.16077 9.64854L4.14852 10.7774L8.21908 7.21567L12.2896 10.7774L13.2774 9.64854L8.21908 5.22252Z' fill='%23454c4d'/%3e %3c/svg%3e");
      }
      .opblock-summary-method {
        padding-top: ${({ theme }) => theme.spacing.s2};
        padding-bottom: ${({ theme }) => theme.spacing.s2};
        font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody3};
        font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody3};
        letter-spacing: 0.05em;
        text-transform: uppercase;
        text-shadow: none;
      }
      .opblock-summary-path {
        max-width: 100%;
        margin: ${({ theme }) => theme.spacing.s2} 0;
        padding-left: 0;
        font-family: ${({ theme }) => theme.ui2Typography.fontFamilyCode};
        font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody3};
      }
      .opblock-summary-description {
        margin: ${({ theme }) => theme.spacing.s2} 0;
        font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody3};
        font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody3};
        color: ${({ theme }) => theme.color.gray600T};
      }
      .arrow {
        position: absolute;
        right: ${({ theme }) => theme.spacing.s7};
        top: ${({ theme }) => theme.spacing.s1};
      }

      /* lock/unlock icon */
      .authorization__btn {
        width: ${({ theme }) => theme.spacing.s4} !important;
        height: ${({ theme }) => theme.spacing.s4} !important;
        margin-right: ${({ theme }) => theme.spacing.s1};
        position: absolute;
        right: 0;
        top: 7px;
        cursor: default;
        svg {
          display: none;
        }
      }
      .authorization__btn.unlocked {
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.66496 4.63597C9.66496 3.78995 9.89854 3.18639 10.2618 2.79908C10.6193 2.41796 11.1784 2.16496 12.0014 2.16496C12.9112 2.16496 13.4922 2.45663 13.8418 2.90743C14.1447 3.29818 14.335 3.86961 14.335 4.63321H15.665C15.665 3.65586 15.4208 2.77342 14.8928 2.09246C14.2305 1.23824 13.2115 0.834961 12.0014 0.834961C10.8941 0.834961 9.95245 1.18485 9.2918 1.88918C8.63696 2.58731 8.33496 3.55176 8.33496 4.63597V6.83496H1.99996C1.35655 6.83496 0.834961 7.35655 0.834961 7.99996V14C0.834961 14.6434 1.35655 15.165 1.99996 15.165H11C11.6434 15.165 12.165 14.6434 12.165 14V7.99996C12.165 7.35655 11.6434 6.83496 11 6.83496H9.66496V4.63597ZM2.16496 13.835V8.16496H10.835V13.835H2.16496Z' fill='%2360696b'/%3e %3c/svg%3e");
        opacity: 1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: ${rem(15)};
      }
      .authorization__btn.locked {
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M8 2.66665C9.09848 2.66665 10 3.56817 10 4.66665V6.66665H6V4.66665C6 3.56817 6.90153 2.66665 8 2.66665ZM11.3333 6.66665V4.66665C11.3333 2.83179 9.83486 1.33331 8 1.33331C6.16515 1.33331 4.66667 2.83179 4.66667 4.66665V6.66665H4.00001C3.26363 6.66665 2.66667 7.2636 2.66667 7.99998V13.3333C2.66667 14.0697 3.26363 14.6666 4.00001 14.6666H12C12.7364 14.6666 13.3333 14.0697 13.3333 13.3333V7.99998C13.3333 7.2636 12.7364 6.66665 12 6.66665H11.3333ZM4.00001 7.99998H12V13.3333H4.00001V7.99998Z' fill='%2360696b'/%3e %3c/svg%3e");
        background-position: center;
        background-repeat: no-repeat;
        background-size: ${({ theme }) => theme.spacing.s4};
      }
    }

    @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
      .opblock-tag-section {
        .opblock-tag {
          display: flex;
          flex-direction: row;
          align-items: center;

          p {
            margin-top: 15px;
            margin-bottom: ${({ theme }) => theme.spacing.s4};
          }
          small {
            padding: 0 ${({ theme }) => theme.spacing.s2};
          }
          .arrow {
            position: static;
          }
        }
      }
      .opblock-summary {
        flex-direction: row;
        align-items: center;

        .opblock-summary-control {
          width: auto;
        }
        .opblock-summary-path {
          /* reset to swagger default css */
          max-width: calc(100% - 110px - 15rem);
          margin: 0;
          padding-left: ${({ theme }) => theme.spacing.s2};
        }
        .opblock-summary-description {
          margin: 0;
        }
        .arrow,
        .authorization__btn {
          position: static;
        }
      }
    }

    .models {
      border-color: ${({ theme }) => theme.color.gray200};

      h4 {
        font-family: ${({ theme }) => theme.ui2Typography.fontFamilyHeader2};
        font-size: ${({ theme }) => theme.ui2Typography.fontSizeHeader2};
        font-weight: ${({ theme }) => theme.ui2Typography.fontWeightHeader2};
        line-height: ${({ theme }) => theme.ui2Typography.lineHeightHeader2};
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        color: ${({ theme }) => theme.color.gray800};
        border-bottom-color: ${({ theme }) => theme.color.gray200} !important;
      }

      .model-container {
        margin-top: ${({ theme }) => theme.spacing.s4} !important;
        margin-right: ${({ theme }) => theme.spacing.s4} !important;
        margin-bottom: ${({ theme }) => theme.spacing.s4} !important;
        margin-left: ${({ theme }) => theme.spacing.s4} !important;
      }

      .model-box {
        .model-title {
          font-family: ${({ theme }) => theme.ui2Typography.fontFamilyCodeBold};
          font-size: ${({ theme }) => theme.ui2Typography.fontSizeCodeBold};
          font-weight: ${({ theme }) => theme.ui2Typography.fontWeightCodeBold};
          line-height: ${({ theme }) => theme.ui2Typography.lineHeightCodeBold};
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
        }

        .inner-object {
          display: block;
          margin-top: ${({ theme }) => theme.spacing.s4};
        }
      }
      .model-box-control .model-box {
        padding: ${({ theme }) => theme.spacing.s2};
      }
      .model-box > .model-box {
        margin-top: ${rem(-3)};
        padding: ${({ theme }) => theme.spacing.s2};
      }

      button {
        margin-bottom: 0;
      }

      &:hover,
      &:focus {
        button,
        span {
          background-color: transparent;
        }
      }
    }
  }
`;
