import React from "react";
import { useTheme } from "@emotion/react";

import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import Text from "../../../components/Text";
import {
  IconSocialDiscuss,
  IconSocialGitHub,
  IconSocialYouTube,
} from "../../../icons";
import {
  IllustrationBunniesBrainstorming,
  IllustrationBunnyLaptopCoffee,
  IllustrationCalendar,
} from "../../../illustrations";
import { rem } from "../../../utils/tools";
import {
  StyledCalloutLink,
  StyledCommunityList,
  StyledCommunityListItem,
  StyledCommunityListLink,
} from "../Dashboard.styled";

const DashboardFooter = () => {
  const theme = useTheme();
  return (
    <Flex
      hasBorderTop
      mt={[6, 6, 8]}
      ml={[-5, -6]}
      mr={[-5, -7]}
      pt={6}
      pb={[9, 10, 11]}
      pr={[5, 7]}
      pl={[5, 7]}
      justifyContent="space-between"
      alignItems={["flex-start", "flex-start", "flex-start", "stretch"]}
      flexDirection={["column", "column", "row"]}
      background={theme.color.gray100}
    >
      <Flex
        width={["100%", "100%", `calc((100% - ${theme.spacing.s6} * 1) / 2)`]}
        flexDirection={["column", "column", "column", "row"]}
      >
        <Box width={rem(286)}>
          <IllustrationBunniesBrainstorming />
        </Box>

        <Box mt={6} ml={6} maxWidth={rem(408)}>
          <Text
            as="h3"
            styleName="marketing-header-4"
            styles={{ color: theme.color.gray800 }}
          >
            Nextmv Community
          </Text>
          <Text mt={2} styleName="body-2">
            Connect with the Nextmv community to learn about decision
            optimization, get support and more.
          </Text>

          <StyledCommunityList>
            <StyledCommunityListItem>
              <StyledCommunityListLink
                href="https://www.youtube.com/channel/UCM9PRPJwP9w-lPS_uaJh17g"
                target="_blank"
                rel="noreferrer"
              >
                <IconSocialYouTube iconColor="#ff0000" />
                <Text as="span" ml={rem(6)} styleName="body-2">
                  YouTube
                </Text>
              </StyledCommunityListLink>
            </StyledCommunityListItem>
            <StyledCommunityListItem>
              <StyledCommunityListLink
                href="https://discuss.nextmv.io"
                target="_blank"
                rel="noreferrer"
              >
                <IconSocialDiscuss iconColor="#32566A" />
                <Text as="span" ml={rem(6)} styleName="body-2">
                  Forum
                </Text>
              </StyledCommunityListLink>
            </StyledCommunityListItem>
            <StyledCommunityListItem>
              <StyledCommunityListLink
                href="https://github.com/nextmv-io"
                target="_blank"
                rel="noreferrer"
              >
                <IconSocialGitHub iconColor="#000000" />
                <Flex alignItems="baseline">
                  <Text as="span" ml={rem(6)} styleName="body-2">
                    GitHub
                  </Text>
                  <Text
                    as="span"
                    className="social-link-github-username"
                    ml={1}
                    styleName="meta-1-bold"
                    styles={{ color: theme.color.gray600 }}
                  >
                    @nextmv-io
                  </Text>
                </Flex>
              </StyledCommunityListLink>
            </StyledCommunityListItem>
          </StyledCommunityList>
        </Box>
      </Flex>

      <Flex
        width={["100%", "100%", `calc((100% - ${theme.spacing.s6} * 1) / 2)`]}
        justifyContent="flex-end"
        alignItems="flex-start"
        flexDirection={["column", "column", "column", "row"]}
        mt={[6, 6, 0]}
        pl={[6, 6, 0]}
        pr={[6, 6, 0]}
      >
        <Flex
          width={[
            "100%",
            "100%",
            "100%",
            `calc((100% - ${theme.spacing.s2}) / 2)`,
          ]}
          maxWidth={["none", rem(420), rem(340)]}
          minHeight={[0, 0, 0, rem(170)]}
          ml={[0, 0, 0, 3]}
          mt={[3, 3, 3, 0]}
        >
          <StyledCalloutLink
            href="https://www.nextmv.io/videos"
            target="_blank"
            rel="noreferrer"
          >
            <Flex pb={[2, 2, 2, 0]}>
              <Box
                width={rem(108)}
                mr={-2}
                ml={1}
                pt={[2, 2, 2, 4]}
                flexShrink={0}
              >
                <IllustrationCalendar />
              </Box>
              <Box ml={2}>
                <Text as="h4" mt={[1, 1, 1, 3]} styleName="body-1-plus">
                  Tech talks
                </Text>
                <Text
                  mt={1}
                  styleName="body-3-bold"
                  styles={{ color: theme.color.chartBrick }}
                >
                  On-demand &amp; upcoming demos
                </Text>
              </Box>
            </Flex>
          </StyledCalloutLink>
        </Flex>

        <Flex
          width={[
            "100%",
            "100%",
            "100%",
            `calc((100% - ${theme.spacing.s2}) / 2)`,
          ]}
          maxWidth={["none", rem(420), rem(340)]}
          minHeight={[0, 0, 0, rem(170)]}
          ml={[0, 0, 0, 3]}
          mt={[3, 3, 3, 0]}
        >
          <StyledCalloutLink
            href="https://www.nextmv.io/docs"
            target="_blank"
            rel="noreferrer"
          >
            <Flex>
              <Box width={rem(108)} ml={-1} flexShrink={0}>
                <IllustrationBunnyLaptopCoffee illustrationWidth={108} />
              </Box>
              <Box ml={2}>
                <Text as="h4" mt={3} styleName="body-1-plus">
                  Nextmv Docs
                </Text>
                <Text
                  mt={1}
                  styleName="body-3-bold"
                  styles={{ color: theme.color.chartBrick }}
                >
                  References &amp; new features
                </Text>
              </Box>
            </Flex>
          </StyledCalloutLink>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DashboardFooter;
